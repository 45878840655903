import { action, thunk, persist } from 'easy-peasy';
import { httpGetOptions } from 'services/options'

const model = {
  loading: false,
  options: [],
  setOptions: action((state, payload) => {
    state.options = [
      ...payload,
    ]
  }),
  addOption: action((state, payload) => {
    state.options = [
      ...state.options,
      payload,
      // loggedIn: true,
    ]
  }),
  getOptions: thunk(async (actions, payload) => {
    console.log("options-model.getOptions()", payload);
    actions.setLoading(true)
    httpGetOptions(payload).then(res => {
      console.log("getOptions:res",res);
      console.log("getOptions:actions",actions);
      actions.setOptions(res?.data?.results);
    }, error => {
      console.log("login:error",error);
    }).finally(() => {
      actions.setLoading(false)
    })
    
  }),
  count: 0,
  setCount: action((state, payload) => {
    state.count = payload
  }),
  setLoading: action((state, payload) => {
    state.loading = payload
  }),
};

export default model;