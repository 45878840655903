import React, { useState, useCallback, useEffect } from "react";
import moment from "moment";
import { generatePath } from "react-router";
import { Route, Switch, Redirect } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import { Row, Col } from "stories/layout";
import { Card, Typography, Button, Link, IconButton, BreadcrumbsDynamic } from "stories/components";
import { PageWrapper, PageHeader } from "components/Page"
import PageTabs from "components/PageTabs/PageTabs"
import { PageContainer } from "components/Page"

import OptionsList from "views/app/OptionsList/OptionsList"
import PrivateStocksList from "views/app/PrivateStocksList/PrivateStocksList"

const Options = ({match, location, history}) => {
  
  const { t } = useTranslation();
  const tabs = [
    {
      key: "options_list",
      label: t("options_title", "Johdannaiset"),
      
    },
    // {
    //     key: "purchases",
    //     label: t("something_title", "Muuta"),
      
    //   },
    //   {
    //       key: "example1",
    //       label: t("other_title", "Third bird"),
        
    //     }
      ]
      
      
    useEffect(() => {
      if  (String(location?.pathname) === match.path){

        history.push(generatePath(match.path + "/:tab", {
          tab: tabs[0]?.key
        }))
      }
    }, [match])


  const getCrumbLinks = () => {
      const crumbLinks = [
        {name: t("web_options_frontpage","Etusivu"), url: "/dashboard/"},
        {name: t("routes_dashboard_derivatives", "Johdannaiset"), url: "/dashboard/options/options_list"},
      ]
  
      //if location state include crumbLinks return them
      if (location?.state && location?.state?.crumbLinks) {
        //made new const and deapcopy location.state.crumbLinks into it
        const crumbLinks = [...location.state.crumbLinks]
  
  
  
        crumbLinks.push({name:  t("routes_dashboard_derivatives", "Johdannaiset"), url: "/dashboard/options/options_list"})
        return crumbLinks
      }
      //else return default links
      return crumbLinks
  }


  return (
    <>
      <div className="mb-2">
        <BreadcrumbsDynamic links={getCrumbLinks()} />
      </div>
      <PageTabs match={match} location={location} tabs={tabs}  />
      
      <PageContainer>
        <Switch>
          <Route
            path={
              generatePath(match.path + "/:tab", {
                tab: "options_list"
              })
             
            }
            
            render={props => <OptionsList {...props} productId={match?.params?.productId} />}
          />
          <Route
          path={
            generatePath(match.path + "/:tab", {
              tab: "purchases"
            })
          }
          exact
          render={props => <PrivateStocksList {...props} productId={match?.params?.productId} />}
        />
        <Route
          path={
            generatePath(match.path + "/:tab", {
              tab: "example1"
            })
          }
          exact
          render={props => <PrivateStocksList {...props} productId={match?.params?.productId} />}
        />
        </Switch>
      </PageContainer>
      
    </>
  );
}

export default Options;
