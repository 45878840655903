import React, { useCallback, useState } from 'react';
import * as XLSX from 'xlsx';
import ImportColumnSelect from "components/Forms/ImportColumnSelect/ImportColumnSelect"
import {  httpUploadTransactionsExcelImportOld } from "services/transactions"
import { Row, Col, Card,CardBody, Container } from "stories/layout";
import { Input, DatePicker, Checkbox } from "stories/forms";
import { useTranslation } from 'react-i18next';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { PageHeader, PageContainer } from "components/Page"

import ImportNoBasicDataTable from 'views/app/ImportNoBasicDataTable/ImportNoBasicDataTable'
import ImportFoundedBasicData from 'views/app/ImportFoundedBasicData/ImportFoundedBasicData'
import ImportCheckTransactions from 'views/app/ImportCheckTransactions/ImportCheckTransactions'
import InvestmentTypeSelectSub from "components/Forms/InvestmentTypeSelectSub/InvestmentTypeSelectSub"





import { Typography, Button, Link, IconButton, Modal, Popover, PopoverInfo, StepProgressBar, Spinner } from "stories/components";
import { set } from 'date-fns';


const UploadExcelForm = () => {

    const { t } = useTranslation();


    const [file, setFile] = useState(null);
    const [fileSummary, setFileSummary] = useState(null);
    const [columns, setColumns] = useState([]);
    const [mapping, setMapping] = useState({});
    const [mappings, setMappings] = useState([]);
    const [errorText, setErrorText] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [headers, setHeaders] = useState([]);
    const [headerRowIndex, setHeaderRowIndex] = useState(0);
    const [oldInvestimeImport, setoldInvestimeImport] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [nextStepBtn, setNextStepBtn] = useState(null);
    const [nextStepHandle, setNextStepHandle] = useState(1);
    const [basicDataRows, setBasicDataRows] = useState(null);
    const [foundedBasicDataRows, setFoundedBasicDataRows] = useState(null);
    const [checkTransactions, setCheckTransactions] = useState(null);

    const [loadingSendExcel, setLoadingSendExcel] = useState(false);
    
    const portfolio = useStoreState((state) => state.portfolio?.portfolio);

    const initialOptions = [
        {key:'type', value:'Tyyppi', optional:false}, 
        {key:'quantity', value:t("Määrä"), optional:false}, 
        {key:'price', value:t("Hinta /kpl"), optional:false}, 
        {key:'commission_total', value:t("Kulut yht."), optional:false}, 
        {key:'value_total', value:t("Yhteensä"), optional:false}, 
        {key:'date_transaction', value:'Päivä', optional:false},  
        {key:'name', value:'Osakkeen nimi', optional:false}, 
        {key:'price_total', value:t("Hinta yht."), optional:true}, 
        {key:'export_id', value:'ID', optional:true}, 
        {key:'ticker_symbol', value:'Osakkeen symbol', optional:true}, 
        {key:'inv1_batch_id', value:'Erän id', optional:true},
        {key:'notes', value:'Kommentti', optional:true},
        {key:'sell_commission', value:'Myynti kulut', optional:true},
        {key:'buy_commission', value:'Osto kulut', optional:true},


    ] 
    const [availableOptions, setAvailableOptions] = useState(initialOptions);
    const handleFileChange = (event) => {
      const selectedFile = event.target.files[0];
      setFile(selectedFile);
      setMapping({}); // Reset the mapping when a new file is uploaded
  
      // Parse the Excel file and extract column names
      const reader = new FileReader();
      reader.onload = (e) => {
        
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
  
        // Assuming the first sheet of the Excel file contains the data
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        let headerRow = null;
        for (let i = 0; i < jsonData.length; i++) {
          const row = jsonData[i];
          if (row.some((cell) => cell !== null && cell !== "")) {
            headerRow = row;
            console.log("headerRow", headerRow)
            console.log("headerRowIndex", i)
            break;
          }
        }

        
      
        if (headerRow !== null) {
          // Extract column names from the header row
          const header = headerRow;
      
          // Add Excel column letters to the header
          const headerWithLetters = header.map((column, index) => {
             const columnLetter = getExcelColumnLetter(index );
            return `${column}`;
          });
      
          setColumns(headerWithLetters);
          setMappings(header.map((column) => { return { key: column, value: "" } }));
        }
        // if (jsonData.length > 0) {
        //   // Extract column names from the first row

        //   const header = jsonData[0];

        //     // Add Excel column letters to the header
        //     const headerWithLetters = header.map((column, index) => {
        //         const columnLetter = String.fromCharCode(65 + index); // ASCII code of 'A' is 65
        //         return `${columnLetter} ${column}`;
        //     });
                
        //   setColumns(headerWithLetters);
        //   setMappings(header.map((header) => {return {key: header, value: ""}}));
        // }
      };
      // Function to calculate Excel column letter
        function getExcelColumnLetter(columnNumber) {
            let columnName = "";
            while (columnNumber >= 0) {
            let remainder = columnNumber % 26;
            columnName = String.fromCharCode(65 + remainder) + columnName;
            columnNumber = Math.floor(columnNumber / 26) - 1;
            }
            return columnName;
        }
  
      reader.readAsArrayBuffer(selectedFile);
    };

    const handleFileChangeSummary = (event) => {
      const selectedFile = event.target.files[0];
      setFileSummary(selectedFile);
  
    };

  
   
  
    const handleOptionChange = (event, index, value) => {

        const selectedValue = event.target.value;
        if (selectedValue || true) {
            // if selected value is in available options, remove it from available options
            const removeAvailableOptions = availableOptions.filter(option => option.key !== selectedValue)
            // setAvailableOptions(removeAvailableOptions)
            //add earlier selected value back to available options (initialOptions where option.key == value)
            const addAvailableOptions = [ ...initialOptions.filter(option => option.key === value?.value), ...removeAvailableOptions] 
            //filter initial options based on addAvailableOptions, use map
            const newAvailableOptions = initialOptions.filter(option => addAvailableOptions.map(option => option.key).includes(option.key))

            setAvailableOptions(newAvailableOptions)
            
            let updatedMappings = [...mappings];
            updatedMappings[index] = {key: updatedMappings[index]?.key, value: selectedValue};
            setMappings(updatedMappings);
            
            //add all options into available options except those what are in mappings
            // // let addAvailableOptions = initialOptions.filter(option => !mappings.includes(option.key))
            // //remove selected value from available options
            // addAvailableOptions = addAvailableOptions.filter(option => option.key !== selectedValue)
            // setAvailableOptions(addAvailableOptions)
            setErrorText(null)
            console.log('handleChange', selectedValue, index, value)
        }
    };

    const getSelectedOptionsValue = (key) => {
        return initialOptions.filter(option => option.key === key?.value)?.[0]?.value
    }
  
    const handleFileUpload = (transaction_save, chect_transactions) => {
        if (availableOptions.length > 0) {
            if (availableOptions.filter(option => option.optional === false).length > 0) {
                setErrorText("Kaikkia pakollisia Investimen sarakkeita * ei ole valinnalla yhdistetty")
                return
            }

        }
        setErrorText(null)

      // Perform the file upload to your Django backend here
      // Send the file and the mapping to the API endpoint
      // Example: Using the Fetch API
      const formData = new FormData();
      formData.append('file', file);
      formData.append('fileSummary', fileSummary);
      formData.append('column_mapping', JSON.stringify(mappings));
      formData.append('header_row_index', headerRowIndex);
      formData.append('transaction_save', transaction_save);
      formData.append('check_transactions', chect_transactions);
      formData.append('basicDataRows', JSON.stringify(basicDataRows));

      formData.append('portfolio', portfolio.id);
      setLoadingSendExcel(true)
      httpUploadTransactionsExcelImportOld(formData).then(res => {
        let b_data = []
        if (res?.data?.data.length > 0){
          b_data = res?.data?.data.map((item)=>{
            return {
              ...item,
              size: 0,
              currency_label: "EUR",
              security_type: "private_stock",
            }
          })
        }
        console.log(res?.data?.data)

        setBasicDataRows(b_data)
        setFoundedBasicDataRows(res?.data?.find_basic_data)
        setCheckTransactions(res?.data?.data)
        setNextStepHandle((prevStep) => prevStep + 1)
        setLoadingSendExcel(false)

        // setExcelFile(null)
        // fileInputRef.current.value = "";
        // notify({ title:t("Excel tuotu onnistuneesti"), type: "success", icon: "fas fa-check"})
        // getTransactions()
      }, error => {
        setBasicDataRows([])
        setCheckTransactions(null)
        setFoundedBasicDataRows([])
        setErrorText("Tapahtumien tuonti epäonnistui")
        if(error?.data?.message) {
          setErrorMessage(t(error?.data?.message))
        }
        if(error?.data?.general) {
          //replace : with . to avoid rendering issues
          const general = error?.data?.general?.toString()
          const formattedError = general.replace(/:/g, '.')
          setErrorMessage(t(formattedError))
        }
        setNextStepHandle((prevStep) => prevStep + 1)

      }).finally(() => {
        setLoadingSendExcel(false)

        // setLoadingImport(false)
      })

    
    };

    const handleFileUploadWithBasicData= () => {
      if (basicDataRows.length > 0) {
        let pluralBasicDataSymbol = {}

        for (const security of basicDataRows) {
          if (pluralBasicDataSymbol[security?.ticker_symbol]) {
            pluralBasicDataSymbol[security?.ticker_symbol]++;
          } else {
            pluralBasicDataSymbol[security?.ticker_symbol] = 1;
          }

        }
        let emptyRows = basicDataRows.filter(row => 'security_type' in row === false || 'size' in row === false ||'currency_label' in row === false || row?.security_type === null || row?.security_type == "" || row?.currency_label === null ||row?.currency_label == "" || row?.size === null || row?.size ==="" )
        console.log(emptyRows)

        // if (emptyRows.length > 0){
        //   for (i in emptyRows){

        //   }
        // }
        let not_valid = false
        emptyRows && emptyRows.forEach(row => {
          console.log(pluralBasicDataSymbol[row?.ticker_symbol])
          if (pluralBasicDataSymbol[row?.ticker_symbol] == 1){
            setErrorText('Tarkista ja täytä kaikki arvopaperin tiedot. Mikäli useampi arvopaperi samalla symbolilla vähintään yhdestä on luotava arvopaperi.')
            not_valid = true
            
          }
          if (pluralBasicDataSymbol[row?.ticker_symbol] > 1){
            pluralBasicDataSymbol[row?.ticker_symbol]--;
          }
        });
        if (not_valid){
          return
        }
        
        // if (basicDataRows.filter(row => 'security_type' in row === false || 'size' in row === false ||'currency_label' in row === false || row?.security_type === null || row?.security_type == "" || row?.currency_label === null ||row?.currency_label == "" || row?.size === null || row?.size ==="" ).length > 0) {
            
        //   setErrorText("Täytä kaikki kentät")
        //     // return
        // }
      }
      setErrorText(null)

      const formData = new FormData();
      formData.append('file', file);
      formData.append('fileSummary', fileSummary);

      formData.append('column_mapping', JSON.stringify(mappings));
      formData.append('header_row_index', headerRowIndex);
      formData.append('portfolio', portfolio.id);
      formData.append('basicDataRows', JSON.stringify(basicDataRows));
      formData.append('check_transactions', true);

      formData.append('basicDataImport', true)
      setLoadingSendExcel(true)
      httpUploadTransactionsExcelImportOld(formData).then(res => {

        setBasicDataRows(res?.data?.data)
        setFoundedBasicDataRows(res?.data?.find_basic_data)
        setCheckTransactions(res?.data?.data)

        setNextStepHandle((prevStep) => prevStep + 1)
        setLoadingSendExcel(false)

      // setExcelFile(null)
      // fileInputRef.current.value = "";
      // notify({ title:t("Excel tuotu onnistuneesti"), type: "success", icon: "fas fa-check"})
      // getTransactions()
    }, error => {
      setBasicDataRows([])
      setCheckTransactions(null)
      setFoundedBasicDataRows([])
      setErrorText("Tapahtumien tuonti epäonnistui")
      if(error?.data?.message) {
        setErrorMessage(t(error?.data?.message))
      }
      if(error?.data?.general) {
        //replace : with . to avoid rendering issues
        const general = error?.data?.general?.toString()
        const formattedError = general.replace(/:/g, '.')
        setErrorMessage(t(formattedError))
      }
      setNextStepHandle((prevStep) => prevStep + 1)
      
    }).finally(() => {
      setLoadingSendExcel(false)

      // setLoadingImport(false)
    })

  };

    const handleCheckboxChange = (isChecked) => {
      setoldInvestimeImport(!oldInvestimeImport)
      if(!oldInvestimeImport){
        setMappings([ 
          { key: 'Hankittu', value: "date_transaction" } ,
          { key: 'Erä', value: "inv1_batch_id" } ,
          { key: 'TN', value: "type" } ,
          { key: 'Nimi', value: "name" } ,
          { key: 'Kpl', value: "quantity" } ,
          { key: 'A-hinta', value: "price" } ,
          { key: 'Myynti-  ', value: "sell_commission" } ,
          { key: 'Osto- ', value: "buy_commission" } ,
          { key: 'Kulut  ', value: "commission_total" } ,
          { key: 'Hankinta- /  ', value: "price_total" } ,
          { key: 'Yhteensä', value: "value_total" } ,
        ]);
        setAvailableOptions([])
      }
      else{
        setMappings(columns.map((column) => { return { key: column, value: "" } }));
        setAvailableOptions(initialOptions)
      }
    };

    const handleSelect = (index, selectedValue) => {
        // Update the mapping for the selected column at the given index
        const updatedMappings = [...mappings];
        updatedMappings[index] = selectedValue;
        setMappings(updatedMappings);
      };

      const getColumns1 = useCallback(() => {
        return (
          <div>
            {initialOptions.map((option) => (
              <Row className="mb-2" key={option.key}>
                <Col sm={12} lg={4} className="d-flex align-items-center">
                  <Typography
                    variant="h4"
                    style={{ transition: 'padding-left 0.5s,  color 0.5s' }}
                    className={`mb-1 pr-1 header ${
                      mappings.some((mapping) => mapping.key === option.key) ? 'pl-3 transitioning text-warning' : 'text-dark'
                    }`}
                  >
                    {option.value}
                  </Typography>
                </Col>
                <Col sm={12} lg={8}>
              
                    <ImportColumnSelect
                      initialOptions={columns.map((column) => ({ key: column, value: column }))}
                      selectedOptions={mappings}
                      onChange={(e) => handleOptionChange(e, option.key, mappings[0])}
                      value={mappings.find((mapping) => mapping.key === option.key)?.value || ''}
                      label=" "
                      placeholder="Valitse"
                      name={option.key}
                      onSelect={(selectedValue) => handleSelect(option.key, selectedValue)}
                    />
   
                </Col>
              </Row>
            ))}
          </div>
        );
      }, [columns, mappings, initialOptions]);

    const getColumns= useCallback(() => {
        return(
            <Container fluid>
            <div className="row justify-content-start">
            <div className="ml-3 mb-3">
            <Checkbox
            id="unique_checkbox_id"
            name='oldInvestimeImport'
            checked={oldInvestimeImport}
            label={t("import_old_investime", "Tuonti vanhasta Investimesta")}
            onChange={(e) => {handleCheckboxChange(e.target.checked)}}
          />
            </div>
          </div>

            <Row className="mb-3">
            <Col sm={12} lg={4} className="d-flex align-items-center">
              <label className="mb-1 pr-1 header-bold">{t("import_excel_column", "Excelin sareke")}</label>

            </Col>
            <Col sm={12} lg={8} >
              <label className="mb-1 pr-1 header-bold">{t("import_investime_column", "Investime sarake")}</label>

            
            </Col>
          </Row>
           
            {columns.map((column, index) => (
                <div key={index}>

                <Row className="mb-3">
                <Col sm={12} lg={4} className="d-flex align-items-center">
                  <Typography variant="h4" style={{transition: 'padding-left 0.5s' }} className={`mb-1 pr-1 header ${mappings[index]?.value !== "" && mappings[index]?.value !== " " ? ' pl-3 transitioning text-warning' : 'text-dark'}`}>{column}</Typography>
                </Col>
                <Col sm={12} lg={8} >
                <ImportColumnSelect
                    initialOptions={initialOptions}
                    selectedOptions={mappings}
                    availableOptions={availableOptions}
                    onChange={(e) => handleOptionChange( e, index, mappings[index])}
                    value={getSelectedOptionsValue(mappings[index])}
                    label =" "
                    placeholder="Valitse"
                    name={column+index}
                    onSelect={(selectedValue) => handleSelect(index, selectedValue, )}
                    className={mappings[index]?.value !== "" && mappings[index]?.value !== " " ? 'text-warning' : 'text-dark'}
                />
                </Col>
              </Row>
                

                </div>
                ))}
            </Container>
        )
    }, [columns, mappings, availableOptions, initialOptions, oldInvestimeImport]);
  
    return (
      <div>
      <Row className="justify-content-center">
        <Col xl="12" >
          <StepProgressBar steps={5} showNextButton={false} takeNextBtnState={setNextStepBtn} givenActiveStep={nextStepHandle} />
        </Col>
      </Row>

        {nextStepHandle === 1 && (
          <>
          <div>
          <Typography variant="h4" className="my-1 pr-1 text-dark">{t("import_excel", "1. Salkun yhteenveto")}</Typography>

          <input className="mb-5" type="file" onChange={handleFileChangeSummary} />
          </div>
          <div>
          <Typography variant="h4" className="my-1 pr-1 text-dark">{t("import_excel", "2. Kirjaukset")}</Typography>
          <input className="mb-5" type="file" label="Kirjaukset" onChange={handleFileChange} />
          </div>
          <Button  className=" mt-2 mr-auto ml-auto" color="secondary" onClick={() =>  setNextStepHandle((prevStep) => prevStep + 1)}>
          {t("Jatka")}
          </Button>
       
          
          
          </>
        )}
        
        {columns.length > 0 && nextStepHandle === 2 && (
            <div className="mt-3">
             <PageHeader className="text-dark mb-2" title={t("Yhdistä sarakkeet")}></PageHeader>

            {getColumns()}
            <Button  className=" mt-2 mr-auto ml-auto" color="secondary" onClick={() => handleFileUpload(false,false)}>
              {loadingSendExcel?(<><Spinner animation="border" size="xs" className="spinner-height-15 mx-5"/></>): t("Lähetä")}
              </Button>
            {errorText && <Typography variant="h5" className="my-1 pr-1 text-danger">{errorText}</Typography>}
          </div>
        )}
        {nextStepHandle >= 3 && basicDataRows.length > 0 ? (
          <>
          <ImportNoBasicDataTable basicDataRows={basicDataRows} setBasicDataRows={setBasicDataRows}/>


          <ImportFoundedBasicData basicDataRows={foundedBasicDataRows} setBasicDataRows={setFoundedBasicDataRows}/>

          <Button  className=" mt-2 mr-auto ml-auto" color="secondary" onClick={handleFileUploadWithBasicData}>
          {loadingSendExcel?(<><Spinner animation="border" size="xs" className="spinner-height-15 mx-5"/></>): t("Luo arvopaperit ja jatka")}
          </Button>
          {errorText && <Typography variant="h5" className="my-1 pr-1 text-danger">{errorText}</Typography>}

          </>
         ): nextStepHandle ==3 && (
          <>
          
          <div>
            <label className="mb-1 pr-1 header-bold">{t("import_investime_all_found", "Kaikille arvopapereille löytyi vastine")}</label>

          </div>
          <ImportFoundedBasicData basicDataRows={foundedBasicDataRows} setBasicDataRows={setFoundedBasicDataRows}/>
          <Row className="justify-content-center">
            <Col>
              <Button  className="mt-2 m-auto" color="secondary" onClick={() => handleFileUpload(false,true)}>
              {loadingSendExcel?(<><Spinner animation="border" size="xs" className="spinner-height-15 mx-5"/></>): t("Jatka")}
              </Button>
            </Col>
          </Row>

          </>
        )}
        {nextStepHandle === 4 && (
          <>
          <div>
            <label className="mb-5 pr-1 header-bold">{t("import_investime_all_found", "Kirjausten oikeellisuus")}</label>

          </div>
            {!errorText && <ImportCheckTransactions checkTransactions={checkTransactions} setCheckTransactions={setCheckTransactions}/>}
          <Row className="justify-content-center mt-3">
            <Col>
              <Button  className="mt-2 m-auto" color="secondary" onClick={() => handleFileUpload(true,true )}>
              {loadingSendExcel?(<><Spinner animation="border" size="xs" className="spinner-height-15 mx-5"/></>): t("Jatka")}
              </Button>
            </Col>
          </Row>
          {errorText && <Typography variant="h5" className="my-1 pr-1 text-danger">{errorText}</Typography>}
          {errorMessage && <Typography variant="h5" className="my-1 pr-1 text-danger">{errorMessage}</Typography>}
          </>
          )
        }
        {nextStepHandle === 5 && (
          <>
          <div>
          {!errorMessage && !errorText ?(
            <label className="mb-1 pr-1 header-bold">{t("import_investime_end_success", "Kirjausten tuonti onnistui")}</label>
          ):(
            <label className="mb-1 pr-1 header-bold">{t("import_investime_end_fail", "Kirjausten tuonnissa jotain ongelmia")}</label>
          ) 
          }
          </div>
          <Row className="justify-content-center">
            <Col>

            </Col>
          </Row>
          {errorText && <Typography variant="h5" className="my-1 pr-1 text-danger">{errorText}</Typography>}
          {errorMessage && <Typography variant="h5" className="my-1 pr-1 text-danger">{errorMessage}</Typography>}
          </>
          )
        }

      </div>
    );
  };
  
  export default UploadExcelForm;


// const UploadExcelForm = () => {
//   const [file, setFile] = useState(null);
//   const [columns, setColumns] = useState([]);
//   const [mapping, setMapping] = useState({});

//   const handleFileChange = (event) => {
//     const selectedFile = event.target.files[0];
//     setFile(selectedFile);
//     setMapping({}); // Reset the mapping when a new file is uploaded

//     // Parse the Excel file and extract column names
//     const reader = new FileReader();
//     reader.onload = (e) => {
//       const data = new Uint8Array(e.target.result);
//       const workbook = XLSX.read(data, { type: 'array' });

//       // Assuming the first sheet of the Excel file contains the data
//       const firstSheetName = workbook.SheetNames[0];
//       const worksheet = workbook.Sheets[firstSheetName];
//       const header = XLSX.utils.sheet_to_json(worksheet, { header: 1 })[0];

//       // Extract column names from the first row
//       setColumns(header);
//     };

//     reader.readAsArrayBuffer(selectedFile);
//   };

//   const handleFileUpload = () => {
//     // Perform the file upload to your Django backend here
//     // Send the file and the mapping to the API endpoint
//     // Example: Using the Fetch API
//     const formData = new FormData();
//     formData.append('file', file);
//     formData.append('column_mapping', JSON.stringify(mapping));

//     fetch('/api/upload_excel/', {
//       method: 'POST',
//       body: formData,
//     })
//       .then((response) => response.json())
//       .then((data) => {
//         console.log(data);
//         // Handle the response from the backend here
//       })
//       .catch((error) => {
//         console.error('Error uploading file:', error);
//         // Handle the error here
//       });
//   };

//   return (
//     <div>
//       <input type="file" onChange={handleFileChange} />
//       <button onClick={handleFileUpload}>Upload</button>

//       {columns.length > 0 && (
//         <div>
//           <h3>Column Mapping:</h3>
//           {columns.map((column, index) => (
//             <div key={index}>
//               <span>{column}</span>:
//               <input
//                 type="text"
//                 value={mapping[column] || ''}
//                 onChange={(e) => setMapping({ ...mapping, [column]: e.target.value })}
//               />
//             </div>
//           ))}
//         </div>
//       )}
//     </div>
//   );
// };

// export default UploadExcelForm;