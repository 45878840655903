import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import { Input } from "stories/forms";

const SecuritySelect = ({ label, name="type", onChange, options, value, ...rest }) => {
  const { t } = useTranslation();
  
  const getInputOptions = useCallback(() => {
    return options && options.map(obj => {
      const value = `${obj.related_model_type}-${obj.related_model_id}`;
      return (
        <option value={value}>{obj.value}</option>
      )
    })
  }, [options])
  
  return (
    <Input label={label ? label : t("security_select_label", "Arvopaperi")} type="select" name={name} onChange={onChange} value={value} {...rest}>
      <option value="" label={t("Kaikki")}></option>
      {getInputOptions()}
    </Input>
  )
  
  
}

export default SecuritySelect;
