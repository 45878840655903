import React, { useState } from 'react';
import { Navbar, NavbarBrand, Button, NavItem, Collapse, Nav } from 'reactstrap';
import './sidebarRight.css'; // Add your styling as needed
import { Container, Row, Col, FormContainer, Card, CardBody } from "stories/layout";
import TradingViewWidget from "components/TradingViewWidget/TradingViewWidget"
import TradingViewWidgetMini from "components/TradingViewWidget/TradingViewWidgetMini"
import TradingViewWidgetTechnicalAnalysis from "components/TradingViewWidget/TradingViewWidgetTechnicalAnalysis"
import PerfectScrollbar from "react-perfect-scrollbar";
import { useTranslation } from 'react-i18next';
import { IconButton } from "stories/components";


import { Typography } from "stories/components";

const SidebarRight = ({stock_market, symbol, name, open, setOpen}) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(open);

  const toggleSidebar = () => {
    setOpen(false);
  };
  if (stock_market == "XHEL") {
    stock_market = "OMXHEX"
    }
    if (stock_market == "XSTO") {
        stock_market = "OMXSTO"
    }
    if (stock_market == "XNAS") {
        stock_market = "NASDAQ"
    }
    if (stock_market == "XNYS") {
      stock_market = "NYSE"
    }
    if (stock_market == "XCSE") {
      stock_market = "OMXCOP"
    }

    //if symbol include space replace with _ for tradingview
    if (symbol?.includes(" ")) {
        symbol = symbol?.replace(" ", "_")
    }

  return (
    <Navbar className={`example sidebarRight ${open  ? 'open' : ''}`}>
      <div className="scrollbar-inner">
      <div className="sidenav-header d-flex align-items-center">
          <NavbarBrand >
            <div className="d-flex justify-content-start">
   



   

          <Button  onClick={toggleSidebar}  size="sm" variant="light">
          <i className="fas fa-times" />
        </Button>
            {/* <Typography variant="h3" className="text-dark p-1 text-start">{`${symbol}`}</Typography> */}
            </div>
          </NavbarBrand>
        
      </div>

        <div className="navbar-inner">
            <div className="mb-2">
            <Typography variant="h3" className="text-dark p-1 text-start">{t("arvo-kehitys","Arvokehitys")}</Typography>

            <TradingViewWidgetMini symbol= {`${stock_market}:${symbol}`} style={{height:"300px"}} className="mb-2"/>
            </div>
            <Typography variant="h3" className="text-dark p-1 text-start">{t("buy_and_sell_signals","Osto- ja myyntisignaali")}</Typography>
            
            <TradingViewWidgetTechnicalAnalysis symbol= {`${stock_market}:${symbol}`} />

          
      </div>
    </div>

      

    </Navbar>
  );
};

export default SidebarRight;