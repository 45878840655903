import React, { useEffect, useState, useCallback } from "react";
import moment from "moment";
import { useTranslation } from 'react-i18next';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useLocation } from "react-router-dom";
import queryString from 'query-string'
import { generatePath } from "react-router";
import { Route, Switch, Redirect } from "react-router-dom";



// Stories
import { Row, Col } from "stories/layout";
import { Input } from "stories/forms";
import { Card, Typography, Button, Link, IconButton, Modal, Spinner, BreadcrumbsDynamic } from "stories/components";
import { PageHeader, PageContainer } from "components/Page"
import { TableAuto } from "stories/tables"
import { round, roundScaleDecimals, thousandFormat } from "services/helpers"

import PageTabs from "components/PageTabs/PageTabs"
import {  Pagination } from "stories/tables";

import CurrencySelect from "components/Forms/CurrencySelect/CurrencySelect" 


// App
// import PrivateInvestmentFundSelect from "components/Forms/PrivateInvestmentFundSelect/PrivateInvestmentFundSelect"
import InvestmentFundSelect from "components/Forms/InvestmentFundSelect/InvestmentFundSelect"
// import { httpGetPrivateInvestimentFundDatas } from "services/private_investment_funds"
import { httpGetInvestmentFundData } from "services/investment_fund"
import { getDate } from "services/datetime"
import PrivateInvestmentFundRateEdit from "views/app/PrivateInvestmentFundRateEdit/PrivateInvestmentFundRateEdit"
import PrivateInvestmentFundRatesChart from "views/app/PrivateInvestmentFundRatesChart/PrivateInvestmentFundRatesChart"

const PrivateInvestmentFundRatesList = ({match, history}, props) => {
  
  const { t } = useTranslation();
  const location = useLocation();


  const getBasicDataObjects = useStoreActions((actions) => actions.stocks.getObjects);
  const stocks = useStoreState((state) => state.stocks?.objects);



  const [loading, setLoading] = useState(false)
  const [objects, setObjects] = useState()
  const [count, setCount] = useState()
  const [locationState, setLocationState] = useState()
  const [filters, setFilters] = useState({
    limit: 30,
    offset:0,
    ordering: "-date",
  })

  //create deap copy of location.state
  useEffect(() => {
    setLocationState({...location?.state})
  }, [])


  useEffect(() => {
    if (location.state?.fundId){
      setFilters(s => ({
        ...s,
        investment_fund: location?.state?.fundId || props?.fundId,
        investment_fund_name: location?.state?.fundName || props?.fundName,
        currency:location?.state?.currency
      }))
      // console.log(location?.state)
      // setFieldValue("private_investment_fund", location?.state?.fundId )
      // setFieldValue("investment_fund_name", location?.state?.fundName )
      //set private investment fund 
    }
    else if (stocks?.[0]?.id > 0 && !filters?.investment_fund) {
      setFilters(s => ({
        ...s,
        investment_fund: stocks?.[0]?.id,
        investment_fund_name:stocks?.[0]?.name,
      }))
    }
  }, [stocks])

  
  const handleChange = useCallback( ({target}, child) => {
    setFilters(s => ({
      ...s,
      [target?.name]: target.value
    }))
  }, [])
  
  const getRates = () => {
    let params = {
      ...filters,
    }
    if (!filters?.investment_fund || filters?.investment_fund == "") {
      return;
    }
    
    httpGetInvestmentFundData(params).then(res => {
      setObjects(res?.data?.results)
      setCount(res?.data?.count)
    })
  }

  const getCrumbLinks = () => {
    const crumbLinks = [
      {name: t("Etusivu"), url: "/dashboard/"},
      {name: t("Rahastot"), url: "/dashboard/"},

      // {name: t("PrivateInvestmentFundsList_title", "Yksityiset rahastot"), url: "/dashboard/private_investment_funds"},

      {name: t("routes_dashboard_investment_fund_rates", "Kurssit"), url: "/dashboard/private_investment_funds_rates", optional: true},
      {name: filters.investment_fund_name, url: "/dashboard/private_investment_funds", optional: true},

    ]

    //if location state include crumbLinks return them
    if (location?.state && location?.state?.crumbLinks) {
      const crumbLinks = [...location.state.crumbLinks]
      crumbLinks.push({name: t("routes_dashboard_investment_fund_rates", "Kurssit"), url: "/dashboard/private_investment_funds_rates", optional: true})
      crumbLinks.push({name: filters.investment_fund_name, url: "/dashboard/private_investment_funds", optional: true},)
      return crumbLinks
    }
    //else return default links
    return crumbLinks
  }
  
  useEffect(() => {
    const parsed = queryString.parse(location.search);
    if (parsed?.pif) {
      setFilters(s => ({
        ...s,
        investment_fund: { value: parsed?.pif },
        investment_fund_name: "Hmm"
      }))
    }
  }, [location])
  
  useEffect(() => {
    getBasicDataObjects({related_model_type:40})
  }, [])
  
  useEffect(() => {
    getRates()
  }, [filters])
  
  
  const headers = [
    { label: t("PrivateInvestmentFundRatesList_name", "Rahasto"), key: "name"},
    { label: t("PrivateInvestmentFundRatesList_stock_date", "Päiväys"), key: "date"},
    { label: t("PrivateInvestmentFundRatesList_ending_price", "Päätös hinta"), key: "updated_price"},
    { label: t("PrivateInvestmentFundRatesList_actions", " "), key: "actions"},

  ];
  
  const paginationPrevious = () => {
    let offset = filters?.offset - filters?.limit;
    if (offset < 0) {
      offset = 0;
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  const paginationNext = () => {
    let offset = filters?.offset + filters?.limit;
    if (offset > filters?.count) {
      return
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  const getTableRows = useCallback(() => {
    const name = filters?.investment_fund_name;
    return objects && objects.map((row, index) => {
      return {
        ...row,
        name: name,
        date: getDate(row?.date),
        // updated_price: twoDigits(row?.updated_price),
        updated_price: row?.updated_price && {
          content: thousandFormat(roundScaleDecimals(row?.updated_price,2)),
          className: "text-right"
        },

        // actions: (
        //   <div className="d-flex justify-content-end">
        //     <Button className="btn-sm" color="secondary" onClick={() => openModalEdit(row?.id)}>
        //       {t("Muokkaa")}
        //     </Button>
        //   </div >
        // ),
      }
    })
  }, [objects])
  
  const setFieldValue = (name, value) => {
    
    if (typeof value === 'object') {
      setFilters(s => ({
        ...s,
        [name]: value?.id > 0 ? value?.id : undefined,
        offset: 0,
      }))
    }
    else {
      setFilters(s => ({
        ...s,
        [name]: value,
        offset: 0,
      }))
    }
  }
  
  const resolvePrivateInvestmentSelect = (params) => {
    if (params?.name) {
      setFieldValue("investment_fund_name", params?.name)
    }

  }
  
  const getFormikInputProps = useCallback(
    name => ({
      name,
      value: filters[name],
      onChange: handleChange
    }),
    [filters, handleChange]
  );

  const tabs = [
    {
      key: "rate_list",
      label: t("rate_list", "Kurssit"),
      
    },
    {
      key: "rate_chart",
      label: t("rate_chart", "Kaavio"),
      
    },

    ]

    useEffect(() => {
      if  (String(location?.pathname) === match.path){
  
        history.push(generatePath(match.path + "/:tab", {
          tab: tabs[0]?.key
        }))
      }
    }, [match])
  
  // Modal: New/Edit
  
  const [modalEditOpen, setModalEditOpen] = useState(false)
  const [modalEditId, setModalEditId] = useState()
  
  const toggleModalEdit = () => {
    setModalEditOpen(s => !s);
  }
  
  const openModalEdit = (id) => {
    setModalEditOpen(true)
    setModalEditId(id);
  }
  
  const modalEditResolve = () => {
    setModalEditOpen(false);
    setModalEditId(null);
    getRates()
  }
  
  const modalEditReject = () => {
    setModalEditOpen(false);
    setModalEditId(null);
  }

  const listView = (props) => {
    return(
      <>


        <TableAuto
        showRowNumber={true}
        showId={true}
        checkboxes={false}
        headers={headers}
        headerColor={"light"}
        rows={getTableRows()}
        loading={loading}
        pagination={true}
        paginationPrevious={paginationPrevious}
        paginationNext={paginationNext}
        paginationOffset={filters?.offset}
        paginationLimit={filters?.limit}
        paginationCount={count}
        setFilters={setFilters}
        />

        <Modal
          title={t("Private investment fund")}
          isOpen={modalEditOpen}
          toggleModal={toggleModalEdit}
        >
        <PrivateInvestmentFundRateEdit
          privateInvestmentFund={filters?.private_investment_fund}
          privateInvestmentFundName={filters?.investment_fund_name}
          objectId={modalEditId}
          handleResolve={modalEditResolve}
          handleReject={modalEditReject}
        />
        </Modal>
      </>
    )
  }
  

  const getDefaultValues = useCallback(() => {
    // if (location?.state?.fundId) {
    //   return {value: location.state?.fundId, label: location.state?.fundName}
    //   return {value: location.state?.fundId, label: location.state?.fundName}
    // }

    return {value: filters?.investment_fund, label: filters?.investment_fund_name }
  }, [filters, stocks])

  const twoDigits = (number) => {
    number = Number(number)
    return number && number.toFixed(2)
  }


  const getChartHeader = useCallback(() => {
    //sort objects by date
    const sortedObjects = objects?.sort((a, b) => {
      return new Date(a?.date) - new Date(b?.date);
    })
    return (
      <Typography bold className="p-2">
        {twoDigits(sortedObjects?.[sortedObjects.length -1]?.updated_price)} {filters?.currency}
      </Typography>
    )
  }, [objects])



  return (
    <>
      <PageHeader title={filters?.investment_fund_name || t("InvestmentFundRates_title", "Rahastokurssit")} />
      <BreadcrumbsDynamic links={getCrumbLinks()} />
      <div className="d-flex">
        <PageTabs match={match} location={location} tabs={tabs} />

      </div>
      <PageContainer>
      <div className="d-flex flex-row align-items-center mb-3">
      <div className="p-2 pr-1">
        <h4 className="mb-0">{t("PrivateInvestmentFundRatesList_private_investment_fund", "Rahasto")}</h4>
        
      </div>
      <div className="p-2">
        <InvestmentFundSelect 
          {...getFormikInputProps("investment_fund")}
          value={getDefaultValues()}
          setFieldValue={setFieldValue}
          handleResolve={resolvePrivateInvestmentSelect}
          defaultValues={ getDefaultValues() }
          setFilters={setFilters}
          ratesView={true}
        />
      </div>
      {  !loading && (
          <div className="d-flex align-items-center ml-auto">
            {location.pathname.includes("rate_list") && (
              <>
              <div className="mx-2" variant="h4">{t("PrivateInvestmentFundsList_count", "Näytä")}</div>
              <Pagination 
              paginationOffset={filters?.offset}
              paginationLimit={filters?.limit}
              paginationCount={count}
              setFilters={setFilters}
              showPaginationCurrentPage={false}
              showPaginationNextAndPrevious={false}
              showVerticalBorders={false}
              disabled={false}
              />
              <div className="mx-2" variant="h4">{t("investementFund_rows","riviä")}</div>
              <div className="ml-4">
              <CurrencySelect 
                label={""}
                disabled={true}
                value={filters?.currency?filters?.currency:""}
                noDefault={true}
              />
              </div>  
              </>
              
            )}
          </div>
      )}
      <div className="ml-2">
      {location.pathname.includes("rate_list") ? (
        <>
        {/* <Button size="normal" color="secondary" onClick={() => openModalEdit(null)}>{t("PrivateInvestmentFundRatesList_create_price","Uusi kirjaus")}</Button>*/}
        </>
      ):(
        <>
       {getChartHeader()}
        </>
      )
      }
      </div>
    </div>
      
      <Switch>
        <Route
          path={
            generatePath(match.path + "/:tab", {
              tab: "rate_list"
            })
          }
          render={props => listView(props)}

        />
        <Route
        path={
          generatePath(match.path + "/:tab", {
            tab: "rate_chart"
          })
        }
        render={props => <PrivateInvestmentFundRatesChart {...props} objects={objects} />}
      />

      </Switch>
    </PageContainer>

    </>
  );
}

export default PrivateInvestmentFundRatesList;


// <div className="d-flex flex-row align-items-end">
// <div className="p-2 pr-1">
//   <h4>{t("PrivateInvestmentFundRatesList_private_investment_fund", "Rahasto")}</h4>
  
// </div>

// <div className="p-2">
//   <PrivateInvestmentFundSelect 
//     {...getFormikInputProps("private_investment_fund")}
//     setFieldValue={setFieldValue}
//     handleResolve={resolvePrivateInvestmentSelect}
//     defaultValues={ {value: location.state?.fundId, label: location.state?.fundName}}
//   />
// </div>
// <div className="pb-2 ml-auto">
//   <Button size="normal" color="secondary" onClick={() => openModalEdit(null)}>{t("PrivateInvestmentFundRatesList_create_price","Uusi kirjaus")}</Button>
// </div>
// </div>

// <TableAuto
// showRowNumber={true}
// showId={true}
// checkboxes={false}
// headers={headers}
// headerColor={"light"}
// rows={getTableRows()}
// loading={loading}
// pagination={true}
// paginationPrevious={paginationPrevious}
// paginationNext={paginationNext}
// paginationOffset={filters?.offset}
// paginationLimit={filters?.limit}
// paginationCount={count}
// setFilters={setFilters}
// />

// <Modal
//   title={t("Private investment fund")}
//   isOpen={modalEditOpen}
//   toggleModal={toggleModalEdit}
// >
// <PrivateInvestmentFundRateEdit
//   privateInvestmentFund={filters?.private_investment_fund}
//   privateInvestmentFundName={filters?.investment_fund_name}
//   objectId={modalEditId}
//   handleResolve={modalEditResolve}
//   handleReject={modalEditReject}
// />
// </Modal>