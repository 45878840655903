import React from 'react';
import PropTypes from 'prop-types';
import './THead.scss';

export const THead = ({ className, color, children, ...rest }) => {
  
  let opts = {};
  
  return (
    <thead
      className={`thead-${color} ${className ? className : ""}`}
      {...opts}
      {...rest}
    >
    {children}
    </thead>
  )
};

THead.propTypes = {
  color: PropTypes.oneOf(['light','dark']),
};

THead.defaultProps = {
  color: "light",
};

