import { action, thunk, persist } from 'easy-peasy';

const model = {
  objects: [
    {
      stock: 1,
      dates: [
        {date: "1.1.2021", value: 1000},
        {date: "1.2.2021", value: 900},
        {date: "1.3.2021", value: 1000},
        {date: "1.4.2021", value: 1100},
        {date: "1.5.2021", value: 1300},
      ]
    },
    {
      stock: 2,
      dates: [
        {date: "1.1.2021", value: 1050},
        {date: "1.2.2021", value: 950},
        {date: "1.3.2021", value: 1150},
        {date: "1.4.2021", value: 850},
        {date: "1.5.2021", value: 825},
      ]
    },
    {
      stock: 3,
      dates: [
        {date: "1.1.2021", value: 1500},
        {date: "1.2.2021", value: 1300},
        {date: "1.3.2021", value: 1100},
        {date: "1.4.2021", value: 900},
        {date: "1.5.2021", value: 700},
      ]
    }
  ],
  setObjects: action((state, payload) => {
    state.objects = [
      ...payload,
    ]
  }),
  addObject: action((state, payload) => {
    state.objects = [
      ...state.options,
      payload,
      // loggedIn: true,
    ]
  }),
  /*
  login: thunk(async (actions, payload) => {
    const { username, password } = payload;
    httpLogin(username, password).then(res => {
      console.log("login:res",res);
      console.log("login:actions",actions);
      actions.saveLogin(res);
    }, error => {
      console.log("login:error",error);
    })
    
  }),
  */
  count: 0,
  setCount: action((state, payload) => {
    state.count = payload
  }),
};

export default model;