import { action, thunk, persist } from 'easy-peasy';
import { httpGetSupportedStockMarkets } from "services/stocks";


import { httpGetHelptexts } from "services/helptexts"

const model = {
  loading: false,
  setLoading: action((state, payload) => {
    state.loading = payload
  }),
  stockMarkets: [],
  setStockMarkets: action((state, payload) => {
    state.stockMarkets = payload
  }),
  count: 0,
  setCount: action((state, payload) => {
    state.count = payload
  }),
  getStockMarkets: thunk(async (actions, payload) => {
    actions.setLoading(true)
    httpGetSupportedStockMarkets().then(response => {
      if (response?.data) {
        //convert to object
        // let obj = {}
        // response.data?.stockMarkets.forEach(element => {
        //   obj[element.key] = element
        // });
        actions.setStockMarkets(response?.data)
        // actions.setStockMarkets(response?.data)
      }
    }, error => {
      console.log(error)
    }).finally(() => {
      actions.setLoading(false)
    })
    
  }),
};

export default model;