import React from "react";
import { Container} from "stories/layout";

const PageWrapper = (props) => {
  const { children, className } = props;
  
  return (
    <Container fluid className={`page page_wrapper ${className}`}>
    {children}
    </Container>
  )
}

export default PageWrapper;