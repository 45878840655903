import React from "react";
import moment from "moment";
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";


import { Container, Row, Col, Card, CardHeader, CardBody, List } from "stories/layout";
import { Typography, Button, Link } from "stories/components";
import { PageWrapper, PageHeader } from "components/Page"

const Info = (props) => {
  const handleCancel = () => {
    props.handleReject()
    history.push('/dashboard/portfolios-list')
    }

  const history = useHistory();    
  const { t } = useTranslation();
  return (
    <>
      <Container className="text-center">
        <Row className="mb-3">
          <Col>
      
            <span className="text-success" style={{fontSize: "48px"}}>
              <i className="fas fa-thumbs-up fa-2xl"></i>
            </span>
          </Col>
        </Row>
        <Row>
          <Col>
            <PageHeader title={t("info_title_1", "Olet ottanut Investime käyttöön onnistuneesti.")}></PageHeader>
            <PageHeader title={t("info_crate_portfolio","Aloita palvelun käyttö luomalla vielä Salkku.")}></PageHeader>
          </Col>
        </Row>
        <Row className="mt-3 mb-5">
          <Col>
            <Typography variant="h3" className="text-center">
              {t("info_information_1","Palvelun käyttöön liittyviä ohjeita voit katsoa")} <strong> {t("info_information_2","Ohjeet")} </strong> {t("info_information_3", "näkymästä.")}  <strong> {t("info_information_2", "Ohjeet")} </strong>  {t("info_information_4", "näkymä löytyy sivuvalikosta.")}
            </Typography>

          </Col>
        </Row>
      
      
      <Button variant="secondary" disabled={false}  onClick={() => handleCancel() }>{t("info_create_portfolio_button",'Luo salkku')}</Button>
      </Container>
      
    </>
  );
}

export default Info;
