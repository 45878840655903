import Axios from 'axios'
import appConfig from "./config"
import history from "services/history"
import jwt_decode from "jwt-decode";
import { logoutWhenTokenExp } from "services/logoutTokenExp";


let baseUrl = appConfig.apiUrl;
let privateApiUrl = appConfig.privateApiUrl;

let axios = Axios.create({
  withCredentials: false,
  baseURL: privateApiUrl,
})



/*
//functions to make api calls
const api = {
  signup: (body) => {
    return axios.post(`${baseUrl}/auth/signup`, body);
  },
  login: (body) => {
    return axios.post(`${baseUrl}/auth/login`, body);
  },
  refreshToken: (body) => {
    return axios.post(`${baseUrl}/auth/refresh_token`, body);
  },
  logout: (body) => {
    return axios.delete(`${baseUrl}/auth/logout`, body);
  },
  getProtected: () => {
    return axios.get(`${baseUrl}/protected_resource`);
  },
};
*/

export default axios;