import React, { useEffect, useState, useCallback } from "react";
import moment from "moment";
import { useTranslation } from 'react-i18next';
import { useStoreState, useStoreActions } from 'easy-peasy';


import { Row, Col } from "stories/layout";
import { Input } from "stories/forms";
import { Card, Typography, Button, Link, IconButton, Modal, Spinner, BreadcrumbsDynamic } from "stories/components";
import { PageHeader, PageContainer } from "components/Page"
import { TableAuto } from "stories/tables"
import { useAppContext } from "contexts/AppContext"
import { round, roundScaleDecimals } from "services/helpers"

import PortfolioEdit from "views/app/PortfolioEdit/PortfolioEdit"
import SplitTransactionEdit from "views/app/SplitTransactionEdit/SplitTransactionEdit"
import SplitTransactionGroupCreate from "views/app/SplitTransactionGroupCreate/SplitTransactionGroupCreate"

const UnmadeSplitTransactionsList = (props) => {
  
  const { t } = useTranslation();
  const portfolios = useStoreState((state) => state.portfolio?.portfolios);
  const portfolio = useStoreState((state) => state.portfolio?.portfolio);
  const account = useStoreState((state) => state.account?.account);
  const unmadeSplitTransactions = useStoreState((state) => state.unmadeSplitTransactions?.unmadeSplitTransactions);
  const getUnmadeSplitTransactions = useStoreActions((actions) => actions.unmadeSplitTransactions.getUnmadeSplitTransactions);

  const { getPortfolioName, getUserTypeName, getTransactionType } = useAppContext()
  
  const setPortfolio = useStoreActions((actions) => actions.portfolio.setPortfolio);

  const loading = useStoreState((state) => state.portfolio?.loading);
  const count = useStoreState((state) => state.portfolio?.count);
  const getPortfolios = useStoreActions((actions) => actions.portfolio.getPortfolios);


  
  const [filters, setFilters] = useState({
    limit: 30,
    offset:0,
  })
  
  const handleChange = ({target}) => {
    setFilters({
      ...filters,
      [target.name]: target.value,
    })
  }
  
  const paginationPrevious = () => {
    let offset = filters?.offset - filters?.limit;
    if (offset < 0) {
      offset = 0;
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  const paginationNext = () => {
    let offset = filters?.offset + filters?.limit;
    if (offset > filters?.count) {
      return
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  const headers = [

      // { label: t("unmade_split_transactions_list_batch", "Erä id"), key: "batch"},
      { label: t("unmade_split_transactions_list_split_id", "Split id"), key: "split_id"},
      { label: t("unmade_split_transactions_list_instrument_name", "Instrumentti"), key: "instrument_name"},
      // { label: t("unmade_split_transactions_list_batch_purchase_date", "Hankintapäivä"), key: "batch_purchase_date"},
      // { label: t("unmade_split_transactions_list_batch_balance", "erän koko ennen splittiä"), key: "batch_balance"},
      { label: t("unmade_split_transactions_list_split_dividend", "Kerroin"), key: "dividend"},
      { label: t("unmade_split_transactions_list_split_date_effective", "SPLIT Päivä"), key: "date_effective"},
      // { label: t("unmade_split_transactions_list_portfolio", "Salkku"), key: "portfolio"},

    { label: t("portfolios_list_actions", " "), key: "actions"},
  ];

  const passPortfolio  = (id) => {
    // get query params id
      console.log("id", id)
      const filtered = portfolios.filter(o => o.id == id)
      if (filtered && filtered.length > 0) {
        setPortfolio(filtered[0])
      }
  }


  useEffect(() => {
    // getUnmadeSplitTransactions()
    }, [])
  
  
  
  
  const getTableRows = useCallback(() => {

    let data = unmadeSplitTransactions;
    if (account.split_handling_only_active_portfolio == true){
      data = unmadeSplitTransactions.filter(o => o.batch.portfolio == portfolio.id)
    }

    const organizedData = {};

    // Iterate over the original data crouping by the split id
    for (const item of data) {
      const splitId = item.splits.id;
      
      if (organizedData[splitId]) {
        organizedData[splitId].push(item);
      } else {
        organizedData[splitId] = [item];
      }
    }

    const organizedDataArray = Object.values(organizedData);

    return unmadeSplitTransactions && organizedDataArray.map((row, index) => {
      let batch_balance = Number(row[0]?.batch?.batch_balance)
      return {
        ...row,
        split_id: row[0]?.splits?.id,
        instrument_name: <Typography variant="h5" className="text-dark">{row[0]?.batch?.related_model?.name}</Typography>,
        date_effective: moment(row[0]?.splits?.date_effective).format('DD.MM.YYYY'),
        dividend: row[0]?.splits?.dividend,
        actions: (
          <div className="d-flex justify-content-end">

          <Button name="process_split_transactions_button" className="btn-sm " color="danger" onClick={() => openModalEdit(row)}>{t("unmade_split_transactions_list_process_button","Käsittele")}</Button>
          </div>
        ),
      }
    })

    

    // return unmadeSplitTransactions && unmadeSplitTransactions.map((row, index) => {
    //   let batch_balance = Number(row?.batch?.batch_balance)
    //   return {
    //     ...row,
    //     batch: row?.batch?.batch_id? row?.batch?.batch_id: row?.batch?.id,
    //     portfolio: getPortfolioName(row?.batch?.portfolio),
    //     instrument_name: <Typography variant="h5" className="text-dark">{row?.batch?.related_model?.name}</Typography>,
    //     batch_balance: batch_balance,
    //     dividend: row?.splits?.dividend,
    //     split_id: row?.splits?.id,
    //     date_effective: moment(row?.splits?.date_effective).format('DD.MM.YYYY'),
    //     batch_purchase_date: moment(row?.batch?.batch_purchase_date).format('DD.MM.YYYY'),
    //     actions: (
    //       <div className="d-flex justify-content-end">

    //         <Button className="btn-sm " color="danger" onClick={() => openModalEdit(row)}>{t("Käsittele")}</Button>
    //       </div>
    //     ),
    //   }
    // })
  }, [unmadeSplitTransactions])
  
  // Modal: New/Edit portfolio
  
  const [modalEditOpen, setModalEditOpen] = useState(false)
  const [modalEditBatch, setModalEditBatch] = useState()
  
  const toggleModalEdit = () => {
    setModalEditOpen(s => !s);
  }
  
  const openModalEdit = (batch) => {
    setModalEditOpen(true)
    setModalEditBatch(batch);
  }
  
  const modalEditResolve = () => {
    setModalEditOpen(false);
    // setModalEditBatch(null);
    getUnmadeSplitTransactions()
    props.getTransactions()
    // props.setParentFilters({
    //   ...filters,
    //   update: props.parentfilters.update? true:false
    // })

  }
  
  const modalEditReject = () => {
    setModalEditOpen(false);
    setModalEditBatch(null);
  }

  return (
    <>
       
        <div className="d-flex justify-content-start mb-2">
   
        <i className="fas fa-exclamation-triangle text-danger fa-2x mr-3" />
        <PageHeader title={t("unmade_split_transactions_list_title","Käsittele saapuneet splitit")} />
        </div>
      
        <div className="d-flex justify-content-between mb-2">
          <div className="py-2">
           {/*
           <Button color="secondary" onClick={() => getUnmadeSplitTransactions()}>{t("Päivitä")}</Button>
           */}  
          </div>
          
          <div className="p-2">
            
          </div>
        </div>
        <div className="d-flex justify-content-end mb-2">
          <Typography variant="h5" className="text-warning">{t("unmade_split_transactions_list_process_order","Vanhimmat ensin")}</Typography>
        </div>

        
        <TableAuto
        showRowNumber={false}
        showId={false}
        checkboxes={false}
        headers={headers}
        headerColor={"light"}
        rows={getTableRows()}
        loading={loading}
        pagination={false}
        paginationPrevious={paginationPrevious}
        paginationNext={paginationNext}
        paginationOffset={filters?.offset}
        paginationLimit={filters?.limit}
        paginationCount={count}
        setFilters={setFilters}
        name={"unmadeSplitTransactions"}
        />
        
        <Modal
          title={t("portfolios_list_portfolio_title","Salkku")}
          showHeader={false}
          isOpen={modalEditOpen}
          toggleModal={toggleModalEdit}
          size="modal-xl"
        >
          <SplitTransactionGroupCreate
            object={modalEditBatch}
            handleResolve={modalEditResolve}
            handleReject={modalEditReject}
          />
        </Modal>

    </>
  );
}

export default UnmadeSplitTransactionsList;
