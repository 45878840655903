import { action, thunk, persist } from 'easy-peasy';
import { httpGetBasicData } from "services/stocks";
import { httpGetPrivateStocks } from "services/private_stocks";
import { httpGetPrivateInvestmentFunds } from "services/private_investment_funds";
import { httpGetInvestmentFunds } from "services/investment_fund";
import { httpGetAllSecuritiesAllTransactions } from "services/transactions";


const model = {
  loading: false,
  setLoading: action((state, payload) => {
    state.loading = payload
  }),
  objects: [
    /*
    {id: 1, name: "Osake A"},
    {id: 2, name: "Osake B"},
    {id: 3, name: "Osake C"},
    */
  ],
  setObjects: action((state, payload) => {
    state.objects = [
      ...payload,
    ]
  }),
  addObject: action((state, payload) => {
    state.objects = [
      ...state.objects,
      payload,
      // loggedIn: true,
    ]
  }),
  getObjects: thunk(async (actions, payload) => {

    actions.setLoading(true)



    //Put all related_model_types securities to same list
    if (payload?.all_related_model_types === true) {
      // let all_securities = []
      // let all_count = 0

      const params = {
        ordering: 'name',
        ...payload,
      }

      httpGetAllSecuritiesAllTransactions(params).then(response => {
        // actions.setLoading(false)
        actions.setObjects(response?.data)
        if (response?.data.length > 0) {
          actions.setCount(response?.data.length)
        }
      }, error => {
        
      }).finally(() => {
        actions.setLoading(false)
      })


    }
    // Select only one related_model_type securities and use other params (like stock_market or type...)
    else {
      if (payload?.related_model_type === "20" || payload?.related_model_type === 20) {
        const params = {
          ordering: 'name',
          ...payload,
        }
        httpGetPrivateStocks(params).then(response => {
          // actions.setLoading(false)
          actions.setObjects(response?.data)
          if (response?.data.length > 0) {
            actions.setCount(response?.data.length)
          }
        }, error => {
          
        }).finally(() => {
          actions.setLoading(false)
        })
      }
      else if (payload?.related_model_type === "30" || payload?.related_model_type === 30) {
        const params = {
          ordering: 'name',
          ...payload,
        }
        httpGetPrivateInvestmentFunds(params).then(response => {
          // actions.setLoading(false)
          actions.setObjects(response?.data)
          if (response?.data.length > 0) {
            actions.setCount(response?.data.length)
          }
        }, error => {
          
        }).finally(() => {
          actions.setLoading(false)
        })
      }
      else if (payload?.related_model_type === "40" || payload?.related_model_type === 40) {
        const params = {
          ordering: 'name',
          ...payload,
        }
        httpGetInvestmentFunds(params).then(response => {
          // actions.setLoading(false)
          actions.setObjects(response?.data)
          if (response?.data.length > 0) {
            actions.setCount(response?.data.length)
          }
        }, error => {
          
        }).finally(() => {
          actions.setLoading(false)
        })
      }
      else {  
        const params = {
          stock_market: 'XHEL',
          ordering: 'name',
          ...payload,
        }
        httpGetBasicData(params).then(response => {
          // actions.setLoading(false)
          actions.setObjects(response?.data)
          if (response?.data.length > 0) {
            actions.setCount(response?.data.length)
          }
        }, error => {
          
        }).finally(() => {
          actions.setLoading(false)
        })
      }
    
    }



  }),
  count: 0,
  setCount: action((state, payload) => {
    state.count = payload
  }),
  
};

export default model;