import { action, thunk, persist } from 'easy-peasy';
import { httpGetAllPortfoliosSummary} from "services/transactions"


const model = {
  loading: false,
  setLoading: action((state, payload) => {
    state.loading = payload
  }),
  count: 0,
  setCount: action((state, payload) => {
    state.count = payload
  }),
  // My portfolios list
  portfoliosSummary: [],
  portfolios_total_purchase_cost: 0,
  setPortfoliosSummary: action((state, payload) => {
    if (payload) {
      state.portfoliosSummary = payload
    }
  }),
  setTotal_cost: action((state, payload) => {
    if (payload) {
      state.portfolios_total_purchase_cost = payload
    }
  }),
  getPortfoliosSummary: thunk(async (actions, payload) => {
    actions.setLoading(true)
    httpGetAllPortfoliosSummary().then(response => {
      if (response.status === 200 && response?.data) {
        actions.setLoading(false)
        actions.setPortfoliosSummary(response.data?.portfolios_market_value)
        actions.setTotal_cost(response.data?.total_purchase_cost)
        if (response?.data.length > 0) {
          actions.setCount(response?.data.length)
        }
        else {
          actions.setCount(0)
        }
      }
    }, error => {
      
    }).finally(() => {
      actions.setLoading(false)
    })
    
  }),
  // Selected portfolio

};

export default model;