import React from 'react';
import PropTypes from 'prop-types';
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import fi from 'date-fns/locale/fi';
import './DatePicker.css';

import ReactDatePicker from "react-datepicker";

export const DatePicker = ({ children, label, error, errorText,placeholderText, required, ...rest }) => {
  registerLocale('fi', fi)
  return (
    <>
      { label ? (
        <label className="mb-1 pr-1 header-bold">{label}{required ? "*" : ""}</label>
      ) : null }
      
      <ReactDatePicker
        locale="fi"
        
        placeholderText={placeholderText}
        {...rest}
      >
      {children}
      </ReactDatePicker>
      
      { error ? (
        <p className="mb-0 text-danger">{errorText}</p>
      ) : null }
    </>
  )
};

DatePicker.propTypes = {
  
};


DatePicker.defaultProps = {
  
};

