import React from 'react';
import PropTypes from 'prop-types';
import { Table as BootstrapTable } from "reactstrap";
import './Table.css';

export const Table = ({ 
  hover = true,
  responsive = true,
  className,
  children,
  showVerticalBorders = false,
  ...rest
}) => {
  
  let opts = {};
  if (hover) {
    opts["hover"]=true;
  }
  if (responsive) {
    opts["responsive"]=true;
  }
  
  return (
    <BootstrapTable
      responsive
      className={`align-items-center ${className ? className : ""} ${showVerticalBorders ? "vertical_borders" : ""}`}
      {...opts}
      {...rest}
    >
    {children}
    </BootstrapTable>
  )
};

Table.propTypes = {
  hover: PropTypes.bool,
  responsive: PropTypes.bool,
  showVerticalBorders: PropTypes.bool,
};

