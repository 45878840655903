import React, { useEffect, useState, useCallback } from "react";

import { useTranslation } from 'react-i18next';

// Stories
import { PageHeader, PageContainer } from "components/Page"

import { Col, Container, Row } from "stories/layout"

import {Link, Breadcrumb, BreadcrumbItem, Button, BreadcrumbsDynamic} from 'stories/components';
import { useAuthContext } from "contexts/AuthContext";
import { Markup } from 'interweave';
import IncomeReportSummary from "views/app/IncomeReportSummary/IncomeReportSummary"
import TaxForm9A from "views/app/TaxForm9A/TaxForm9A"



//
const TaxReport = (props) => {
  const { t } = useTranslation();
  const { myUser } = useAuthContext();

    //Export button from taxForm9A
    const [exportButton, setExportButton] = useState(null);


  //get crumb links from location state or return default links
  const getCrumbLinks = () => {
    const crumbLinks = [
      {
        name: t("tax_report","Veroilmoitus"),
          url: "/dashboard",  
        },
    ]
    //if location state include crumbLinks return them
    if (props.location?.state && props.location?.state?.crumbLinks) {
      props.location.state.crumbLinks.push({name: t("tax_report","Veroilmoitus"), url: "/dashboard"})
      return props.location.state.crumbLinks
    }
    //else return default links
    return crumbLinks
  }

  
  return (
    <>

    <Row className="d-flex  flex-wrap  ">
        <Col>
          <PageHeader title={t("tax_report", "Veroilmoitus")} />
          <BreadcrumbsDynamic links={getCrumbLinks()} />
        </Col>
        <Col md="6" xs="12" className="d-flex flex-column justify-content-end  mr-xs-auto align-self-end mb-3 " style={{minWidth: "50px"}}>
          <div className="ml-auto">
            {exportButton}
          </div>
        </Col>
    </Row>

    <TaxForm9A   setExportButton={setExportButton}/>
    {/*<IncomeReportSummary/ > */}

    </>
  );
}

export default TaxReport;