import React, { useState, useCallback, useEffect } from "react";
import moment from "moment";
import { generatePath } from "react-router";
import { Route, Switch, Redirect } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useStoreState, useStoreActions } from 'easy-peasy';


import { Row, Col } from "stories/layout";
import { Card, Typography, Button, Link, IconButton, BreadcrumbsDynamic } from "stories/components";
import { PageHeader, PageContainer } from "components/Page"
import PageTabs from "components/PageTabs/PageTabs"

import TransactionsList from "views/app/TransactionsList/TransactionsList"

const Example = () => {
  return (
    <>Example</>
  )
}

const TransactionsView = ({match, location}) => {
  const unmadeSplitTransactions = useStoreState((state) => state.unmadeSplitTransactions?.unmadeSplitTransactions);
  const { t } = useTranslation();
  //Export button from transactionsList
  const [exportButton, setExportButton] = useState(null);

  const getCrumbLinks = () => {
    const crumbLinks = [
      {name: t("Etusivu"), url: "/dashboard/"},
      {name: t("Ostot ja myynnit"), url: "/dashboard/transactions"},
    ]

    //if location state include crumbLinks return them
    if (location?.state && location?.state?.crumbLinks) {
      location.state.crumbLinks.push({name: t("Ostot ja myynnit"), url: "/dashboard/transactions"})
      return location.state.crumbLinks
    }
    //else return default links
    return crumbLinks
  }
  
  const tabs = [
    {
      key: "purchases",
      label: t("purchases_list_title", "Ostot ja myynnit"),
    },
    /*{
      key: "sales",
      label: t("sales_list_title", "Myynnit"),
    },
    {
      key: "dividends",
      label: t("dividends_list_title", "Osingot"),
    }
    */
  ]
  
  return (
    <>
    <div className="row">
    <div className="col-md-3">
      <PageHeader title={t("Ostot ja myynnit")} />
      <BreadcrumbsDynamic links={getCrumbLinks()} />
    </div>
    <div className="col text-center  justify-content-end">
      {/*unmadeSplitTransactions.length > 0 && <Typography variant="h3" className="mt-4 text-danger">{t("Split-kirjauksia tekemättä! Tiedot eivät välttämättä näy oikein!")}</Typography>*/}
      <div className="ml-auto">
            {exportButton}
          </div>
    </div>
  </div>


      <PageContainer>
        <TransactionsList productId={match?.params?.productId} setExportButton={setExportButton}   />
      </PageContainer>

      <Typography  variant="small" className="mt-5 text-gray">
      * {t("transactions_views_batch_original_balance","Erän alkuperäinen määrä on splitattu ja se noudattaa samaa suhdetta kuin mikä hyväksyttiin split-kirjauksen yhteydessä. Tietoa tarvitaan erilaisissa laskennoissa.")}
    </Typography>

    <Typography  variant="small" className="mt-2 text-gray">
      ** {t("transactions_views_batch_original_purchasedate","Vaihdetun erän alkuperäinen hankintapäivämäärä")}
    </Typography>
    </>
  )
  
  /*
  return (
    <>
      <PageTabs match={match} tabs={tabs} />
      
      <PageContainer className="page_container">
        <Switch>
          <Route
            path={
              generatePath(match.path, {
                tab: "purchases"
              })
            }
            exact
            render={props => <PurchasesList {...props} productId={match?.params?.productId} />}
          />
          <Route
            path={
              generatePath(match.path, {
                tab: "sales"
              })
            }
            exact
            render={props => <Example {...props} />}
          />
        </Switch>
      </PageContainer>


      
    </>
  );
  */
}

export default TransactionsView;
