import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import { Input } from "stories/forms";
import { useStoreState  } from 'easy-peasy';
import { SelectMulti } from "stories/forms";
import { useAppContext }from "contexts/AppContext"
import Select from 'react-select';
import { sub } from "date-fns";

// import { on } from "gulp";


// const InvestmentTypeCateSelect = () => {
const InvestmentTypeCateSelect = ({ label, name,multiple=false,placeholder,disabled,ratesView=true, showAll=false,onChange,setFieldValueReturnId=false, handleResolve, setFieldValue,validateForm, showAllOption, value, ...rest}) => {
  const { t } = useTranslation();
  const { enums, getSecurityType } = useAppContext()

  const myAccount = useStoreState((state) => state.account?.account);
  const sectors = useStoreState((state) => state.sectors?.sectors);

  const getOptions = useCallback(() => {

    return sectors && sectors.map(obj => {
      return {value: obj.id, label: obj.name}
    })
  }, [sectors])
  
  const getInputOptions = useCallback(() => {
    let investmentTypes = enums?.transactions?.transaction?.related_model_type
    // const BasicDataSubTypes = [{key: "basicData_stock", value: "stocks"}, {key:"crypto",value:"crypto"}]

    const mainCategory = ["stock","fund", "derivative", "loan", "index", "other","crypto" ]  

    const subTypes = enums?.private_investment_funds?.private_investment_fund?.type
    const always_supported_keys = [10, 40, 30, 'private_stock',"all", 'private_investment_fund','private_investment_fund_unlisted', 'derivative', 'loans_listed', 'loans',"own_loans", 'other','index','listed_stock']
    let investmentTypesList = []


    if (investmentTypes && subTypes) {
      investmentTypes = investmentTypes?.filter(obj =>  obj?.key !== 20)
      investmentTypes = [...investmentTypes, ...subTypes]
      if (showAllOption === true) {
        investmentTypesList = [{key: "all", value:t("Kaikki"), id:"all", name:t("Kaikki"), label:t("Kaikki")}]
      }
      
       
      
      for (let i = 0; i < mainCategory.length; i++) {
        const element = mainCategory[i];
        // console.log("element", element)


        if (element === "stock") {
            //loop investmentTypes and find 10 and all where name include stock
            const stockTypes = investmentTypes?.filter(obj => obj?.key === 10 || String(obj?.key)?.includes("stock"))
            // console.log("stockTypes10", stockTypes)
            investmentTypesList = [...investmentTypesList, ...stockTypes]
        }
        else if (element === "fund") {
            //loop investmentTypes and find 40 and all where name include fund
            const stockTypes = investmentTypes?.filter(obj => obj?.key === 40 || String(obj?.key)?.includes("fund"))
            // console.log("stockTypes40", stockTypes)

            investmentTypesList = [...investmentTypesList, ...stockTypes]
            // console.log("investmentTypesList", investmentTypesList)
        }
        else{
            const stockTypes = investmentTypes?.filter(obj => String(obj?.key)?.includes(element))
            if (stockTypes.length > 0){
                // console.log("stockTypes", stockTypes)
                investmentTypesList = [...investmentTypesList, ...stockTypes]
            }
        }
        
        // investmentTypes.push(obj)
      }


    } else {
      investmentTypes = investmentTypes?.filter(obj => obj?.key !== 20)
    }

    // Remove elements that don't meet the condition
    investmentTypesList = investmentTypesList.filter(obj => {
      return always_supported_keys.includes(obj.key) || myAccount?.license?.features.some(feature => feature.name === obj?.key);
    });

    //add "all" to the list
    investmentTypesList = [{key: "all", value:t("Kaikki"), id:"all", name:t("Kaikki"), label:t("Kaikki")}, ...investmentTypesList]

    return investmentTypesList && investmentTypesList.map(obj => {
      // if (!always_supported_keys.includes(obj.key) && !myAccount?.license?.features.some(feature => feature.name === obj?.key)) {
      //   return null
      // }
      if (obj.key !== 10 && obj.key !== 40 && obj.key !== 30){
        return (
          // <option style={value ==obj.key? { paddingLeft:'20px' }: {paddingLeft:"0px"}} className="" value={obj.key}>&nbsp;&nbsp;   {obj.value} </option>
        // <option key={obj.key} value={obj.key}>{t(obj.key,obj.value)} </option>

          {value:obj.key, key:obj.key, name:t(obj.key,obj.value),  label:t(obj.key,obj.value)}
          )
      }
      // let option_label = tobj.value
      return (
        // <option  value={obj.key}> {obj.value}  </option>
        // console.log("obj.key......", obj),
        // <option key={obj.key} value={obj.key}>{t(obj.key,obj.value)} </option>
        {value:obj.key, key:obj.key, name:t(obj.key,obj.value),label:t(obj.key,obj.value)}
      )
    })
  }, [enums, value])

  const setLocalFieldValue = (name, obj) => {
    // setFieldValue(name, obj?.id)
    // handleResolve({ id: obj?.id, name: obj?.label ? obj?.label : obj?.name })

    if (setFieldValueReturnId === true) {
      setFieldValue(name, obj?.id)
      setFieldValue("stockCurrency", obj?.stockCurrency)
    }
    else {
      setFieldValue(name, obj)
    }
    
    if (handleResolve !== undefined) {
      handleResolve({ id: obj?.id, name: obj?.label ? obj?.label : obj?.name,  })
    }

  }


  const getSelectedOptions1a = useCallback(() => {
    if (multiple === false) {
      return [value];
    }
    return value && value.map(obj => {
      return {value: obj.id,key:obj.id, label: obj.name, name: obj.name, id: obj.id}
    })
  }, [value])


  const getSelectedOptions = useCallback(() => {
    if (multiple === false) {
      return [value];
    }
    return value && value.map(obj => {
      return {value: obj.id, label: obj.value}
    })
  }, [value,])

  // const getOptions = useCallback(() => {

  //   return portfolios && portfolios.map(obj => {
  //     return {value: obj.id, label: obj.name}
  //   })
  // }, [portfolios])



  return (
    <>
    {/* {JSON.stringify(value)} */}
    {/* {JSON.stringify(getInputOptions())} */}
     <SelectMulti
            isMulti={multiple}
            label={label ? label : t("investmentType_select_label", "Arvopaperi")}
            name={name}
            value={value}
            setFieldValue={setFieldValue}
            defaultValue={getSelectedOptions()}
            options={getInputOptions()}
            // options={getOptions()}
            showAllOption={showAllOption} 
            validateForm={validateForm}
            placeholder={placeholder}
            // ratesView={ratesView}
            disabled={disabled}
            multiple={multiple}
            {...rest}
          />

        {/* <Select
                name="Test"
                options={getInputOptions()}
                isMulti={true}
        /> */}
    </>

  )


  
  
}

export default InvestmentTypeCateSelect;