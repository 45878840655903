import React from 'react';
import PropTypes from 'prop-types';

// https://demos.creative-tim.com/argon-dashboard-pro-react/#/documentation/react-sweet-alert
// https://www.npmjs.com/package/react-bootstrap-sweetalert
import ReactBSAlert from "react-bootstrap-sweetalert";

export const AlertModal = ({ cancelBtnText, confirmBtnText, title, onConfirm, onCancel, showCancel, showCloseButton, children, ...rest }) => {
  
  return (
    <ReactBSAlert
      cancelBtnText={cancelBtnText}
      confirmBtnText={confirmBtnText}
      onConfirm={onConfirm}
      onCancel={onCancel}
      btnSize=""
      showCancel={showCancel}
      style={{ display: "block", marginTop: "-100px" }}
      title={title}
    >
      {children}
    </ReactBSAlert>
  )
};

AlertModal.propTypes = {
  cancelBtnText: PropTypes.string,
  confirmBtnText: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  title: PropTypes.string,
  showCancel: PropTypes.bool,
  showCloseButton: PropTypes.bool,
};

AlertModal.defaultProps = {
  cancelBtnText: "Cancel",
  confirmBtnText: "OK",
  showCancel: false,
  showCloseButton: false,
};

/*
Example

import AlertModal from "stories/components"
const [infoAlert, setInfoAlert] = useState();
  
const handleModalConfirm = () => {
  setInfoAlert(null);
}

setInfoAlert(
  <AlertModal
    title="Lähetetty"
    onConfirm={() => handleModalConfirm()}
    onCancel={() => handleModalConfirm()}
  >
    Kutsu lähetetty onnistuneesti
  </AlertModal>
)

return (
  <>
  OTHER CONTENT
  {infoAlert}
  </>
)
*/