import React, { useEffect, useCallback, useState } from "react";
import { withFormik } from 'formik';
import * as Yup from "yup";
import queryString from 'query-string'
import { useTranslation } from 'react-i18next';
import Flags from "components/Icons/Flags"

// core components
import { Checkbox, Input } from "stories/forms";
import { Button, Typography, Link } from "stories/components"
import { Card, CardBody, CardHeader, Col, Container, Row } from "stories/layout"
import { useNotification } from "stories/components/Notification"
import { useAuthContext } from "contexts/AuthContext";
import { httpChangePassword, httpActivateInvatedUser } from "services/users";
import logo from "assets/images/logo_nav.png"
import { parseDragMeta } from "@fullcalendar/core";

import { logoutWhenTokenExp } from "services/logoutTokenExp";


const Invitation = (props) => {

  const {
     values,
     touched,
     errors,
     handleChange,
     handleBlur,
     handleSubmit,
     isValid,
     isSubmitting,
     setPreSetValues,
     setFieldValue,
     validateForm,
     setErrors,
     setTouched,
   } = props;
   
   const {
      history,
      t
   } = props;
   
   console.log(values)
   
   const getFormikInputProps = useCallback(
    name => ({
      name,
      value: values[name],
      error: Boolean(touched[name] && errors[name]),
      errorText: touched[name] ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange
    }),
    [values, errors, touched, handleBlur, handleChange]
  );
  
  const handleCancel = () => {
    history.push("/");
  }

  const validateFormBeforeSubmit = (submitFunction) => {
    if (!submitFunction) {
      return;
    }
    validateForm().then((res) => { 
      console.log(res)
      if (Object.keys(res).length === 0) { 
        submitFunction();
      } else { 
        setTouched(res);
        setErrors(res);
      } 
    })
  }
  
  return (
    <>

      <div className="p-2">
      </div>
      <Container className="mt-4 pb-5">

        
        <Row className="justify-content-center">
          <Col lg="6" md="7" className="">
            <Card variant="secondary">
              <CardHeader className="bg-transparent pt-5 pb-1">
                
                <div className="text-center text-muted mb-4">
                  <Typography variant="h2" className="mb-2">{t('invitation_set_new_password','Aseta uusi salasana')}</Typography>
                  <Typography variant="p"></Typography>
                </div>
              
              </CardHeader>
              <CardBody className="px-lg-5 py-lg-5">
                <Row>
                  <Col className="mb-3">
                    <Input
                      {...getFormikInputProps("first_name")}
                      label={t("first_name_input", "Etunimi")}
                      type="text"
                      icon="fas fa-user"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="mb-3">
                    <Input
                      {...getFormikInputProps("last_name")}
                      label={t("last_name_input","Sukunimi")}
                      type="text"
                      icon="fas fa-user"
                    />
                  </Col>
                </Row>  
                <Row>
                  <Col className="mb-3">
                    <Input
                      {...getFormikInputProps("email")}
                      label={t("email_input","Sähköposti")}
                      disabled={true}
                      type="email"
                    />
                  </Col>
                </Row>                              
                <Row>
                  <Col className="mb-3">
                    <Input
                      {...getFormikInputProps("new_password")}
                      label={t('new_password_input','Uusi salasana')}
                      type="password"
                    />
                  </Col>
                </Row>
                
                <Row>
                  <Col className="mb-3">
                    <Input
                      {...getFormikInputProps("check_password")}
                      label={t('password_again_input','Salasana uudelleen')}
                      type="password"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="mb-3">
                    <Input
                      {...getFormikInputProps("company_name")}
                      label={t("company_name_input","Yrityksen nimi")}
                      type="text"
                      icon="fas fa-building"
                      //set disabled to true
                      disabled={true}
                    />
                  </Col>
                </Row> 
                <Row>
                  <Col className="mb-3">
                    <Input
                      {...getFormikInputProps("business_identity_code")}
                      label={t("company_business_id_input","Y-tunnus")}
                      icon="fas fa-building"
                      type="text"
                      disabled={true}
                    />
                  </Col>
                </Row>     
                
                <div className="row justify-content-start">
                   <div className="ml-3 mb-3">
                    <Checkbox 
                      id="business"
                      name="agree_terms"
                      checked={values?.agree_terms}
                      onChange={(e) => {setFieldValue("agree_terms",e.target.checked)}}
                      value="agree_terms"
                      required
                      errorText={touched["agree_terms"] ? errors["agree_terms"] : ""}
                      label={<>{t('Accept','Hyväksy')} <Link href="http://investime.fi/legal" target="_blank">{t('käyttöehdot')}</Link></>}
                    />
                  </div>
                </div>
                
                <div className="text-center text-muted mb-4">
                  <Button variant="secondary" disabled={isSubmitting} onClick={() => validateFormBeforeSubmit(handleSubmit)}>{t("invitation_submit",'Rekisteröidy ja ota palvelu käyttöön')}</Button>
                  { Boolean(errors["general"]) && <Typography className="text-danger">{errors["general"]}</Typography> }
                </div>
                
              <Flags />
              </CardBody>
            </Card>

          </Col>

        </Row>
      </Container>
    </>
  );
}

const defaultValues = {
  id: null,
  first_name: "",
  last_name: "",
  new_password: "",
  check_password: "",
  company_name: "",
  business_identity_code: "asd",
  email: "",
}

 const InvitationFormik = withFormik({
    
    validateOnMount: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        console.log(preSetValues)
        return preSetValues;
      } else {
        return defaultValues
      }
    },
    validationSchema: props => {
      const {t} = props;
      const required = t("required_field","Kenttä on pakollinen");
      
      return Yup.object().shape({
        first_name: Yup.string().max(200).required(required),
        last_name: Yup.string().max(200).required(required),
       
        new_password: Yup.string().required(required)
        .min(8, t('Password must be 8 characters long'))
        .matches(/^\S*$/, t('Whitespace is not allowed'))
        .matches(/[0-9]/, t('Password requires a number'))
        .matches(/[a-z]/, t('Password requires a lowercase letter'))
        .matches(/[A-Z]/, t('Password requires an uppercase letter'))
        .matches(/[^\w]/, t('Password requires a symbol')),
        check_password: Yup.string().required(required).oneOf([Yup.ref('new_password'), null], t("register_password_not_match",'Salasanat eivät täsmää')),
        email: Yup.string(),
        agree_terms: Yup.bool().test(
          'consent',
          t('Kenttä on pakollinen'),
          value => value === true
        )
      });
    },
    handleSubmit: (values, { setSubmitting, setErrors, props }) => {
      const {t, token} = props;
      let data = {
        data: {

          new_password: values?.new_password,
          check_password: values?.check_password,
        }
      };
      
      httpChangePassword("me", data).then(res => {
        setSubmitting(false);
        if (res?.status === 200) {
          // if (res?.data) {
          //   let { access, refresh } = res.data;
          //   localStorage.setItem("accessToken", access);
          //   localStorage.setItem("refreshToken", refresh);

          // }

          data= {
            first_name: values.first_name,
            last_name: values.last_name,
            token: token
          }
          httpActivateInvatedUser(data).then(res => {
            if (res?.status !== 200) {
              setErrors({general: t("invitation_error_activate_user","Käyttäjän aktivointi epäonnistui")});
              props.history.push("/auth/login")

            }
            else{
              if (res?.data?.access && res?.data?.refresh) {
                let { access, refresh } = res.data;

                localStorage.removeItem("accessToken");
                localStorage.removeItem("refreshToken");

                localStorage.setItem("accessToken", access);
                localStorage.setItem("refreshToken", refresh);

                logoutWhenTokenExp();
              }
              window.location.href = "/dashboard/frontpage"
              // props.history.push("/dashboard/frontpage")

            }

          })

          props.notify({ title:t("invitation_password_title","Salasana"), message:t("invitation_password_update_ok","Päivitetty onnistuneesti")})
          // window.location.href = "/auth/login"
          // props.history.push("/auth/login")
        }
        else {
          setErrors({
            general: JSON.stringify(res?.data),
          })
        }
        
        
      }, error => {
        setSubmitting(false);
        if (error?.data?.detail) {
          setErrors({
            general: error?.data?.detail,
          })
        }
        else {
          setErrors({
            general: t("unknown_error","Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen.")
          })
        }
      })
    },
    displayName: "BasicForm"
  
  })(Invitation)
    
    
const InvitationView = ({location, match, history}) => {
  // API requests here
  
  const { t } = useTranslation();
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  const { setLanguage, getMyUser } = useAuthContext();
  
  let preSetValues = { }
  const [loading, setLoading] = useState(false);
  
  // useEffect(() => {
  //   setLanguage("fi");
  // }, [])
  
  useEffect(() => {
    
    const parsed = queryString.parse(location.search);
    if (parsed?.token) {
      // console.log(parsed.token)
      localStorage.removeItem("refreshToken");
      localStorage.setItem("accessToken", parsed?.token);
    }
  },[location])
  
  // useEffect(() => {
    //   getMyUser();
    // },[])
    
    
    

    //how to get all params from url





 
    const parsed = queryString.parse(location.search);

    //loop all params and if key start with "amp;" remove it
    for (const [key, value] of Object.entries(parsed)) {
      if (key.startsWith("amp;")) {
        const newKey = key.replace("amp;", "");
        parsed[newKey] = value;
        delete parsed[key];
      }
    }


    let account_name = "";
    let business_identity_code = "";
    let email = "";

    if (parsed?.account_name) {
      account_name = parsed?.account_name;
    }
    if (parsed?.business_identity_code) {
      business_identity_code = parsed?.business_identity_code;
    } 
    if (parsed?.email) { 
      email = parsed?.email;
    }

    preSetValues = {
      ...defaultValues,
      email: email,
      company_name: account_name,
      business_identity_code: business_identity_code,
    };



  if (loading) {
    return (
      <Container fluid>

      </Container>
    )
  }
  return (
    <>
      <InvitationFormik history={history} t={t} notify={notify} preSetValues={preSetValues} token={parsed?.token} />
    </>
  )
}
  

export default InvitationView;
