import React from 'react';
import PropTypes from 'prop-types';
import {
  Breadcrumb as BootstrapBreadcrumb,
  BreadcrumbItem as BootstrapBreadcrumbItem,
} from "reactstrap";

import {Link } from 'stories/components';

export const Breadcrumb = ({ children, ...rest }) => {
  return (
    <BootstrapBreadcrumb {...rest}>
    {children}
    </BootstrapBreadcrumb>
  )
}

export const BreadcrumbItem = ({ children, ...rest }) => {
  return (
    <BootstrapBreadcrumbItem {...rest}>
    {children}
    </BootstrapBreadcrumbItem>
  )
}


export const BreadcrumbsDynamic = (props) => {
  // 

  return (
      <Breadcrumb listClassName="breadcrumb-links underline-text mb-3" >

       {props.links.map((link, index) => {

          if (index === props.links.length - 1 || link?.optional) {
              return (
                  <BreadcrumbItem key={index} active>
                      {link.name}
                  </BreadcrumbItem>
              )
          }

          return (
              <BreadcrumbItem key={index}>
                  <Link to={link.url}>
                      {link.name}
                  </Link>
              </BreadcrumbItem>
          )
          })}
      </Breadcrumb>
  )

}




/*

<Breadcrumb listClassName="breadcrumb-links">
  <BreadcrumbItem>
    <a href="#pablo" onClick={e => e.preventDefault()}>
      Tavaratilaukset
    </a>
  </BreadcrumbItem>
  <BreadcrumbItem>
    <a aria-current="page" className="active">
      {getTabName()}
    </a>
  </BreadcrumbItem>
  <BreadcrumbItem>
    <Link to={`/dashboard/purchase_orders/list/${getTabKey()}`}>
      {getTabName()}
    </Link>
  </BreadcrumbItem>
  
</Breadcrumb>
      
*/