import React, { useState, useCallback, useEffect } from "react";
import { withFormik } from 'formik';
import * as Yup from "yup";
import moment from "moment"
import { useTranslation } from 'react-i18next';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { round ,roundScaleDecimals} from "services/helpers"

import { Button, Typography, Spinner } from "stories/components";
import { Container, Row, Col, FormContainer, Card, CardBody } from "stories/layout";
import { Input, SelectMulti, Toggle, Radio, DatePicker } from "stories/forms";
import { useNotification } from "stories/components/Notification"
import { httpGetSplits } from "services/splits"



import { 
  httpGetPrivateStockData, 
  httpCreatePrivateStockData, 
  httpUpdatePrivateStockData, 
  httpDeletePrivateStockData 
} from "services/private_stocks";

import CurrencySelect from "components/Forms/CurrencySelect/CurrencySelect"

const defaultValues = {
  id: null,
  name: "",
}

const PrivateStockRateEdit = (props) => {
  
  const {
     values,
     touched,
     setTouched,
     errors,
     setErrors,
     handleChange,
     handleBlur,
     handleSubmit,
     setFieldValue,
     isValid,
     isTouched,
     isSubmitting,
     validateForm,
     preSetValues
   } = props;
   
   const {
      t,
      history,
      objectId,
      privateStockName,
      splits
   } = props;
   
   const getFormikInputProps = useCallback(
    name => ({
      name,
      value: values[name],
      error: Boolean(touched[name] && errors[name]),
      errorText: touched[name] ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange
    }),
    [values, errors, touched, handleBlur, handleChange]
  );
  
  const onSubmit = (e) => {
    e.preventDefault();
    validateForm().then(res => {
      if (Object.keys(res).length === 0) {
        handleSubmit()
      }
      else {
        setTouched(res);
        setErrors({
          ...res,
          general: t("Please check the form errors", "Tarkista lomakkeen virheet")
        })
      }
    })
  }

  useEffect(() => {
    let factor = 1
    if (splits.length > 0){
      const filteredSplits = splits.filter((split) => {
        const splitDate = new Date(split.date_effective).setHours(0, 0, 0, 0);
        const stockDate = new Date(values?.stock_date).setHours(0, 0, 0, 0);
        if (splitDate > stockDate){
          factor =  factor * Number(split.dividend)
        }
        return splitDate > stockDate;
      });
    }
    setFieldValue("factor", factor)

  }, [values?.stock_date])



  const handlePriceTodayPerspectiveChange = ({target}) => {
    setFieldValue("price_today_perspective", target.value, false)

    // let factor = 1
    // const filteredSplits = splits.filter((split) => {
    //   const splitDate = new Date(split.date_effective);
    //   const stockDate = new Date(values?.stock_date);
    //   if (splitDate > stockDate){
    //     factor *= Number(split.dividend)
    //   }
    //   return splitDate > stockDate;
    // });
    
    setFieldValue("price", roundScaleDecimals((target.value * values?.factor), 4))
    // setFieldValue("factor", factor)

  }
  
  const handleCancel = () => {
    props.handleReject()
  }
  
  const handleDelete = () => {
    httpDeletePrivateStockData(objectId).then(() => {
      props.handleResolve()
    })
  }
  
  return (
    <>
      <Row>
        <Col className="mb-3">
        { privateStockName }
        </Col>
      </Row>
      <Row>
        <Col className="mb-1">
          <DatePicker
            {...getFormikInputProps("stock_date")}
            label={t("private_stock_rate_edit_stock_date", "Kurssi päivä")}
            className="form-control"
            selected={values?.stock_date}
            name="stock_date"
            onChange={(date) => setFieldValue("stock_date", date)}
            showYearDropdown
            dateFormat="d.M.yyyy"
            value={values?.stock_date}
          />
        </Col>
      </Row>
      { values?.stock_date && splits.length > 0 && values?.factor != 1 &&
        <Row className="row justify-content-end">
        <Col  className="mb-3">
          <Typography variant="small">{ t("Kurssipäivän jälkeisten splittien kokonais suhdeluku on ")} {values?.factor}</Typography>
        </Col>
      </Row>
      }
      {
        splits.length > 0 && values?.factor != 1 &&
        <>
          <Row className="mt-3">
            <Col className="mb-1">
              <Input
                {...getFormikInputProps("price_today_perspective")}
                label={t("private_stock_rate_edit_price", "Splitattu hinta")}
                onChange={handlePriceTodayPerspectiveChange}
                type="number"
                min={0}
                step={"0.0001" }
                onWheel={(e) => e.target.blur()}
                placeholder={t("0.50")}
              />
            </Col>

          </Row>
          <Row className="row justify-content-end">
            <Col  className="mb-3">
              <Typography variant="small">{ t("Mikäli kurssi päivän jälkeen on tullut splittejä ja olet käynyt hakemassa tänään kurssin joltain sivustolta, on se jo todennäköisesti splitattu. Syötä sivustolta hakemasi kurssi tähän ja järjestelmä peruuttaa splittauksen tallentamiesi splittien mukaan alla olevaan hinta-kenttään.")}</Typography>
            </Col>
          </Row>
        </>
      }

      <Row>
        <Col className="mb-1">
          <Input
            {...getFormikInputProps("price")}
            label={t("private_stock_rate_edit_price", "Hinta")}
            type="number"
            min={0}
            step={"0.0001" }
            onWheel={(e) => e.target.blur()}
            placeholder={t("1.00")}
          />
        </Col>
      </Row>
      <Row className="row justify-content-end">
        <Col  className="mb-3">
          <Typography variant="small">{ t("Tähän tulee antaa hinta joka osakkeella oli todellisuudessa ko päivänä. Esim jos meillä olisi sanomalehti tuolta ajalta ja siellä palsta osakkeiden päivän hinnoista.")}</Typography>
        </Col>
      </Row>

      <Row>
        <Col className="mb-3">
          <Typography className="text-warning" variant="medium">
            {t("Huom! Mikäli lisäät kirjauksia historiaan varmista onko kurssi päivän jälkeen tullut splittejä ja onko käyttämäsi kurssin hinta jo splitattu. Todennäköisesti on. Hinta kenttään tulee antaa hinta, joka osakkeella oli ko päivänä, eli sitä ei saa olla splitattu päiväyksen jälkeisellä splitin suhdeluvulla.")}
          </Typography>
        </Col>
      </Row>
      <Row>
        <Col className="mb-3">
          <Typography className="text-warning" variant="medium">
            {t("Lisää mahdolliset uudet splitit järjestelmään ensin ja vasta sen jälkeen lisää kurssikirjaukset splittiä edeltävään aikaan.")}
          </Typography>
        </Col>
      </Row>

      <Row>
        <Col className="mb-3">
          <Button onClick={handleCancel} variant="default">{t("Close","Sulje")}</Button>
        </Col>
        { values?.id ? (
        <Col className="mb-3 text-center">
          <Button onClick={handleDelete} variant="danger">{t("Delete","Poista")}</Button>
        </Col>
        ) : null }
        <Col className="mb-3 text-right">

            <Button variant="secondary" disabled={isSubmitting} onClick={onSubmit}>
              {t("Save","Tallenna")}
            </Button>
          
          { Boolean(errors["general"]) && <Typography className="text-danger">{errors["general"]}</Typography> }
        </Col>
      </Row>
        
    </>
  );
}

const PrivateStockRateEditFormik = withFormik({
    enableReinitialize: true,
    validateOnMount: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return {
          ...defaultValues
        }
      }
    },
    validationSchema: props => {
      const {t} = props;
      const required = t("The field is required");
      return Yup.object().shape({
        stock_date: Yup.string().required(required).nullable(),
      });
    },
    handleSubmit: (values, { setSubmitting, setErrors, props }) => {
      const {t, privateStock} = props;
      
      const values_price = values?.price ? parseFloat(values?.price.toString().replace(",",".")) : 0;
      const values_updated_price = values?.price_today_perspective ? parseFloat(values?.price_today_perspective.toString().replace(",",".")) : null;
      let data = {
        ...values,
        price: values_price,
        private_stock: privateStock,
        updated_price: values_updated_price
      };
      
      if (values?.id) {
        httpUpdatePrivateStockData(values.id, data).then(res => {
          setSubmitting(false);
          props.notify({ title:t("Kurssi"), message:t("Updated")})
          props.handleResolve()
          
        }, error => {
          setSubmitting(false);
          if (error?.status === 400) {
            setErrors(error?.data)
          }
          else if (error?.data?.detail) {
            setErrors({
              general: error?.data?.detail,
            })
          }
          else {
            setErrors({
              general: t("Unknown error. Please try again later or contact support.")
            })
          }
        })
      }
      else {
        const oldDate = data.stock_date;
        const newDate = new Date(oldDate.getFullYear(), oldDate.getMonth(), oldDate.getDate(), 12, 0, 0);
        const formattedDate = newDate.toISOString().split('T')[0] +" 12:00:00Z";;
        data.stock_date = formattedDate;
        httpCreatePrivateStockData(data).then(res => {
          setSubmitting(false);
          props.notify({ title:t("Kurssi"), message:t("Created")})
          props.handleResolve()
          
        }, error => {
          setSubmitting(false);
          if (error?.status === 400) {
            setErrors(error?.data)
          }
          else if (error?.data?.detail) {
            setErrors({
              general: error?.data?.detail,
            })
          }
          else {
            setErrors({
              general: t("Unknown error. Please try again later or contact support.")
            })
          }
        })
      }
      
      
    },
    displayName: "BasicForm"
  
})(PrivateStockRateEdit)
    
    
const PrivateStockRateEditView = ({history, objectId,splits, modalOpen, privateStockName="", ...rest}) => {
  // API requests here
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [preSetValues, setPreSetValues] = useState();
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  const account = useStoreState((state) => state.account?.account);
  
  const getObject = (id) => {
    
    setLoading(true)
    
    httpGetPrivateStockData(id).then(res => {
      
      setPreSetValues({
        ...defaultValues,
        ...res?.data,
        stock_date: res?.data?.stock_date ? moment(res?.data?.stock_date).toDate() : null,
        price_today_perspective: res?.data?.updated_price

      });
    
    }).finally(response => {
      setLoading(false);
    })
    
  }
  

  
  useEffect(() => {
    if (objectId && parseInt(objectId) > -1) {
      getObject(objectId)
    }
  }, [objectId, modalOpen])
  
  
  if (loading) {
    return (
      <Container fluid>
        <Spinner />
      </Container>
    )
  }

  return (
    <PrivateStockRateEditFormik 
      t={t} 
      history={history} 
      preSetValues={preSetValues} 
      notify={notify} 
      objectId={objectId}
      account={account}
      privateStockName={privateStockName}
      splits= {splits}
      {...rest} 
    />
  )
  
}

export default PrivateStockRateEditView;
