import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import { Input } from "stories/forms";
import { useAppContext }from "contexts/AppContext"
import Select from 'react-select';
import { tr } from "date-fns/locale";
import { TAGS } from "interweave";
import { Typography } from "stories/components"

import {
  FormGroup,
} from "reactstrap";

const TransactionTypeSelect = ({ label,isMulti,showAll,placeholder,setFilters, onChange, value,pass_exchange_transactions=false, name="type", ...rest }) => {
  const { t } = useTranslation();
  const { enums } = useAppContext()
  
  const getInputOptions = useCallback(() => {
    // const transactionTypes = enums?.transactions?.transaction?.type
    //filter and save only key 2,3,20,41
    const transactionTypes = enums?.transactions?.transaction?.type.filter(obj => obj.key === 2 || obj.key === 3 || obj.key === 20 || obj.key === 41 || obj.key === 30 || obj.key === 32 )
    return transactionTypes && transactionTypes.map(obj => {
      return (
        <option key={obj.key} value={obj.key}>{t(`transactionTypeValue_${obj.key}`, obj.value)}</option>
      )
    })
  }, [enums])


  const [files, setFiles] = useState([]);


  const handleSelectChange = (selectedOption) => {
      // setFilters(selectedOption)
      setFilters(s => ({
        ...s,
        type: selectedOption
      }))
      // if (setFileSelectValue) {
      //     console.log("selectedOption", selectedOption)
      // setFileSelectValue(selectedOption); // Set the selected value in the parent component

      // //append the selected value to the array
      // setFileSelectValue([...fileSelectValue, selectedOption]);
      // }
  };



  const getInputOptionsMulti = useCallback(() => {
      //if show all is true add all option
    const transactionTypes = enums?.transactions?.transaction?.type.filter(obj => obj.key === 2 || obj.key === 3 || obj.key === 20 || obj.key === 41 || obj.key === 30 || obj.key === 32 )

      let inputoptions = []
      if (showAll) {
          inputoptions = [{key: " ", value: t("Kaikki")}]
      }
      if (pass_exchange_transactions){
          inputoptions = [...inputoptions, {key: "no_exchange", value: t("EI VAIHTOJA")}]
      }
      if (transactionTypes){

        inputoptions = [...inputoptions, ...transactionTypes]
      }

      return transactionTypes && inputoptions && inputoptions.map(obj => {
          return (
          {value:obj.key, label: t(`transactionTypeValue_${obj.key}`, obj.value)}
          )
      })
  }, [enums])
  
  return (
    <>

    {isMulti ?
      (
        <>
          <Typography bold>{label}</Typography>
        <Select 
        label={label || label === "" ? label : t("transaction_type_select_label", "Tyyppi")}
        name={name} 
        options={getInputOptionsMulti()} 
        onChange={handleSelectChange}
        placeholder={placeholder?placeholder:t("transaction_type_select_label", "Tyyppi")}
        value={value}
        isMulti={isMulti}
        
        styles={{
          container: (provided) => ({
            ...provided,
            width: '100%'  // Make the select component take the full width of the container
          })
        }}
        // styles={{
          //     menuPortal: base => ({
            //       ...base,
            //       zIndex: 9999 // Set the desired z-index for the dropdown menu
            //     })
            //   }}
            {...rest} 
            />
        </>
      
      ):(
        
        <Input label={label ? label : t("transaction_type_select_label", "Tyyppi")} type="select" name={name} onChange={onChange} value={value} {...rest}>
        <option key="null" value="" label={t("Kaikki")}></option>
        {getInputOptions()}
        </Input>
      )
      
    }
    </>
  )
  
  
}

export default TransactionTypeSelect;








// const selectedColumn = columns[index];
// const selectedColumnIndex = columns.indexOf(selectedColumn);
// const selectedColumnValues = [];


// const reader = new FileReader();
// reader.onload = (e) => {
//   const data = new Uint8Array(e.target.result);
//   const workbook = XLSX.read(data, { type: 'array' });

//   // Assuming the first sheet of the Excel file contains the data
//   const firstSheetName = workbook.SheetNames[0];
//   const worksheet = workbook.Sheets[firstSheetName];
//   const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

//   let headerRow = null;
//   let columnIndexToRead = 2; // Change this to the index of the column you want to read

//   for (let i = 0; i < jsonData.length; i++) {
//     const row = jsonData[i];

//     // Assuming the columnIndexToRead is within the row's length
//     const cellValue = row[columnIndexToRead];
//     if (cellValue !== null && cellValue !== "") {
//         selectedColumnValues.push(cellValue);
//     }
//     const uniqueColumnValues = [...new Set(selectedColumnValues)];
//     setTransactionTypesExcel(uniqueColumnValues)


//     console.log(`Row ${i + 1}, Column ${columnIndexToRead + 1}: ${cellValue}`);
//   }
// };