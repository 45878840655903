import React, { useCallback, useState } from "react";
import { withFormik } from 'formik';
import * as Yup from "yup";
import { useTranslation } from 'react-i18next';
import jwt_decode from "jwt-decode";

// core components
import { Input } from "stories/forms";
import { Button, Link, Typography } from "stories/components"
import { Card, CardBody, CardHeader, Col, Container, Row } from "stories/layout"
import { useNotification } from "stories/components/Notification"
import Flags from "components/Icons/Flags"
import AcceptTransactionsPractices from "components/Info/AcceptTransactionspractices"

import { httpLogin } from "services/auth"
import { useStoreActions } from 'easy-peasy';
import { useAuthContext } from "contexts/AuthContext";
import { httpSendActivationEmail } from "services/users"
import logo from "assets/images/logoiv.png";
import FiImage from "assets/img/icons/flags/FI.png";
import SweImage from "assets/img/icons/flags/SWE.png";
import GbImage from "assets/img/icons/flags/GB2.png";

import { logoutWhenTokenExp } from "services/logoutTokenExp";

const Login = (props) => {

  const {
     values,
     touched,
     errors,
     handleChange,
     handleBlur,
     handleSubmit,
     isValid,
     isSubmitting,
     setErrors,
     setSubmitting,
     validateForm,
   } = props;
   
   const {
      history,
      t,
      notify
   } = props;
   
   
   
   const getFormikInputProps = useCallback(
    name => ({
      name,
      value: values[name],
      error: Boolean(touched[name] && errors[name]),
      errorText: touched[name] ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange
    }),
    [values, errors, touched, handleBlur, handleChange]
  );
  
  const handleResendActivationEmail = (emailAdress) => {
    setSubmitting(true)
    httpSendActivationEmail(emailAdress).then(res => {
      notify({ title:t('Activation'), message:t('Pin-code sent successfully to your email')})
      history.push({
        pathname: '/auth/activation',
        state: { email: emailAdress}
      })
      setErrors({})
      setSubmitting(false)
    })
  }
  
  const onSubmit = (e) => {
    e.preventDefault();
    validateForm().then(() => handleSubmit())
  }
  return (
    <>
      <div className="p-2">

      </div>
      <Container className="mt-4 pb-5">
        <Row className="justify-content-center">
          <Col lg="6" md="8" className="">
            <Card variant="secondary">
              <CardHeader className="bg-transparent px-lg-5 pt-3 pb-1">
                <div className="text-start text-muted mb-2 d-flex flex-column">
                  <div className="justify-self-center mx-auto mb-4" ><img src={logo} style={{width: "200px"}} alt="logo_svg"/></div>
                  <Typography variant="h2" className="mb-2">{t('login_title','Login')}</Typography>
                    {/* <Typography variant="p">{t('Tervetuloa takaisin')}</Typography> */}
                </div>
              
              </CardHeader>
              <CardBody className="px-lg-5 py-lg-5">
                <form onSubmit={onSubmit}>
                  <Row>
                    <Col className="mb-3">
                      <Input
                        {...getFormikInputProps("email")}
                      placeholder={t('login_email_label', 'Email')}
                      type="email"
                      required
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mb-4">
                      <Input
                        {...getFormikInputProps("password")}
                        placeholder={t('login_password_label', 'Password')}
                        type="password"
                        required
                      />
                    </Col>
                  </Row>
                  
                  <Row>
                   <Col className="mb-4 text-left">
                      {/* <Typography variant="small">{t('login_forgot_password', 'Forgot password?')}</Typography> */}
                      <Typography  variant="small"> <Link className="underline-text text-dark" to="/auth/password/forgot">{t('login_forgot_password', 'Forgot password?')}</Link></Typography>
                    </Col>
                    <Col className="mb-4 ">

                    </Col>
    
                  </Row>
                  
                  <div className="text-center text-muted mb-0 mt-2">
                
                  <Row>

                    <Col className="mb-0 text-left d-flex align-items-center">
                    {/* <Typography variant="small">{t('No account yet')}?</Typography> */}
                      <Typography variant="small" className=""> <Link className="underline-text text-dark " to="/auth/register">{t('No account yet? Subscribe here')}</Link></Typography>
                    </Col>
                    <Col className="mb-0 text-right">
                      <Button name="login_button"  className="text-dark" variant="secondary" disabled={isSubmitting} type="submit">{t('login_login_button', 'Login')}</Button>
                    </Col>
                  </Row>

                  </div>
                  
                    { Boolean(errors["general"]) && (
                      <div className="text-center text-muted mb-2">
                        <Typography className="text-danger">{errors["general"]}</Typography>
                      </div>
                    )}
                
                  { errors?.['USER_NOT_ACTIVATED'] ? (
                    <div className="text-center mb-4">
                    
                      <Button  disabled={isSubmitting} variant="secondary" onClick={() => handleResendActivationEmail(values?.email)}>{t('login_resend_activation_email_button', 'Lähetä aktivointi uudestaan')}</Button>
                    </div>
                  ) : null}
                  
                  <div className="mb-2"></div>
                </form>
                <Flags />
              </CardBody>
            </Card>

          </Col>
        </Row>
      </Container>
    </>
  );
}

const defaultValues = {
  email: '',
  password: '',
}

 const LoginFormik = withFormik({
    
    validateOnMount: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return defaultValues
      }
    },
    validationSchema: props => {
      const {t} = props;
      const required = t('required_field','Kenttä on pakollinen');
      
      return Yup.object().shape({
        email: Yup.string().email(t('login_validation_email','Tarkista sähköposti')).required(required),
        password: Yup.string().required(required),
      });
    },
    handleSubmit: (values, { setSubmitting, setErrors,errors, props }) => {
      const {t} = props;
      httpLogin(values?.email, values?.password).then(res => {
        setSubmitting(false);
        if (res?.status === 200 && res?.data) {
          let { access, refresh } = res.data;
          localStorage.setItem("accessToken", access);
          localStorage.setItem("refreshToken", refresh);

          logoutWhenTokenExp();
          
          props.handleResolve()
        }
        else {
          if (res?.data?.errorCode && res?.data?.errorCode[0] === "USER_NOT_ACTIVATED") {
            setErrors({
              detail: t('login_validation_user_not_activated','Käyttäjä ei ole aktivoitu'),
            })
            console.log(errors)
          }
          else {
            setErrors({
              detail: t('login_validation_wrong_username_password','Väärä käyttäjätunnus tai salasana'),
              ...res?.data,
            })
          }
        }
      }, error => {
        console.log(error.status)
        setSubmitting(false);
        
        //All keys in error.data
        const errorDataKey = Object.keys(error?.data);
        //default
        let errorCodeKey = errorDataKey?.[0];
        //default errorCode string to translator
        let errorCodeData = error?.data?.[errorCodeKey];
        //if error.data include errorCode then use that instead of default (errorCodeKey, errorCodeData). Also exclude errorCode key from errorDataKey list
        if (errorDataKey.length >= 2 && errorDataKey.includes('errorCode') ) {
          const errorCodeIndex = errorDataKey.indexOf('errorCode');
          errorCodeKey = errorDataKey[errorCodeIndex];
          errorCodeData = error?.data?.[errorCodeKey];
          //Exclude errorCode key from errorDataKey list
          errorDataKey.splice(errorDataKey.indexOf('errorCode'), 1);
          //For special cases, if render depends on errorCode
          setErrors({[errorCodeData]: t(errorCodeData)});
      
        }
        console.log(errorDataKey)
        console.log(errorCodeKey)
        console.log(errorCodeData)
        let errorData = error?.data[errorDataKey?.[0]];
        const valuesKeys = Object.keys(values);
        const usualErrorkeys = ['detail', 'message', 'non_field_errors'];


        if (Array.isArray(errorData)) {
          errorData = errorData[0];
        }

        if (!errorData || errorData === '') {
          setErrors({general: t('general_error','Virhe. Ota tarvittaessa yhteyttä asiakaspalveluun'),[errorCodeData]: t(errorCodeData)})
        }
        else if (errorDataKey.length > 0 && valuesKeys.includes(errorDataKey?.[0])) {
          //set new setErrors with include previous errors
        
          setErrors({[errorDataKey?.[0]]: t(errorCodeData,errorData),[errorCodeData]: t(errorCodeData)});
          

        }
        else if (errorDataKey.length > 0 && usualErrorkeys.includes(errorDataKey?.[0])) {
          setErrors({general: t(errorCodeData,errorData),[errorCodeData]: t(errorCodeData)});

        } 
        // else if dataKey is exist set general error
        else if (errorDataKey.length > 0) {
          setErrors({general: t(errorCodeData,errorData),[errorCodeData]: t(errorCodeData)});
        }
        else {
            setErrors({
              general: `${t('general_error','Virhe. Ota tarvittaessa yhteys ylläpitoon.')} ${JSON.stringify(error)}`,[errorCodeData]: t(errorCodeData),
          })
        }
      })
    },
    displayName: "BasicForm"
  
  })(Login)
    
    
const LoginView = ({match, history}) => {
  // API requests here
  
  const { t } = useTranslation();
  const { getMyUser } = useAuthContext();
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  const [infoAlert, setInfoAlert] = useState();
  // const login = useStoreActions((actions) => actions.auth.login);
  
  const handleModalConfirm = () => {
    setInfoAlert(null);
    getMyUser();
    // history.push("/dashboard/frontpage");
  }
  
  const handleResolve = () => {
    notify({ title:t('User','Käyttäjä'), message:t('login_successful','Kirjautuminen onnistui')})
    handleModalConfirm();
    /*
    setInfoAlert(
      <ReactBSAlert
        style={{ display: "block", marginTop: "-100px" }}
        title="Lähetetty"
        onConfirm={() => handleModalConfirm()}
        onCancel={() => handleModalConfirm()}
        btnSize=""
      >
        Kutsu lähetetty onnistuneesti
      </ReactBSAlert>
    )
    */
  }
  
  return (
    <>
      <LoginFormik 
        history={history} 
        t={t} 
        handleResolve={handleResolve} 
        notify={notify}
      />
      {infoAlert}
      
    </>
  )
}
  

export default LoginView;
