// import React, { useEffect } from 'react';
// import './TradingViewWidget.css';

// const TradingViewWidget2 = (props) => {
//   useEffect(() => {
//     const script = document.createElement('script');
//     // script.src = 'https://s3.tradingview.com/tv.js';
//     script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-technical-analysis.js';
//     script.async = true;

//     script.onload = () => {
//       new window.TradingView.widgets({ //whatis this? explain this line
//         "autosize": true,
//         "symbol": props.symbol,
//         // "symbol": "OMXHEX:NOKIA",
//         "timezone": "Etc/UTC",
//         "theme": "dark",
//         "style": "1",
//         "locale": "en",
//         "enable_publishing": false,
//         "withdateranges": true,
//         "range": "YTD",
//         "hide_side_toolbar": false,
//         "allow_symbol_change": true,
//         "details": true,
//         "hotlist": true,
//         "calendar": true,
//         container_id: 'tradingview-widget-container-technical'
//       });
//     };

//     // document.body.appendChild(script);
//     document.getElementById('tradingview-widget-container-technical').appendChild(script);

//     return () => {
//       document.body.removeChild(script);
//     };
//   }, []);

//   return (
//     <div style={{ minHeight: '100px' }}>
//     <div className="tradingview-widget-container" id="tradingview-widget-container-technical" style={{ height: '100%', width: '100%', minHeight: '100px' }}>
//       <div className="tradingview-widget-container__widget" style={{ height: 'calc(100% - 32px)', width: '100%', minHeight: '100px' }}></div>
//       <div className="tradingview-widget-copyright">
//         <a href="https://www.tradingview.com/" rel="noopener nofollow" target="_blank">
//           <span className="blue-text">Track all markets on TradingView</span>
//         </a>
//       </div>
//     </div>
//     </div>
//   );
// };

// export default TradingViewWidget2;






// import React, { useEffect } from 'react';

// const TradingViewWidget = (props) => {
//         useEffect(() => {
//             const script = document.createElement('script');
//             script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-technical-analysis.js';
//             // script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-mini-symbol-overview.js';
            
//             script.async = true;


//             script.innerHTML = `
//                 {
//                     "interval": "1m",
//                     "width": "100%",
//                     "isTransparent": false,
//                     "height": "100%",
//                     "symbol": "OMXHEX:NOKIA",
//                     "showIntervalTabs": true,
//                     "hide_side_toolbar": true,
//                     "displayMode": "single",
//                     "locale": "en",
//                     "colorTheme": "dark",
//                     "container_id": 'tradingview-widget-container2'
//                 }
//             `;
            
//             document.getElementById('tradingview-widget-container2').appendChild(script);

//     }, []);
  
//     return (
//       <div className="tradingview-widget-container">
//         <div className="tradingview-widget-container__widget" id="tradingview-widget-container2"></div>
//         <div className="tradingview-widget-copyright">
//           <a href="https://www.tradingview.com/" rel="noopener nofollow" target="_blank">
//             <span className="blue-text">Track all markets on TradingView</span>
//           </a>
//         </div>
//       </div>
//     );
//   };
  
//   export default TradingViewWidget;





  import React, { useCallback, useEffect } from 'react';

const TradingViewWidget = ({symbol, width="300", height="400", color="light"}) => {
  useEffect(() => {
      //try to remove script and see if it works

      const container = document.getElementById('tradingview-widget-container123');


    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-technical-analysis.js';
    script.async = true;
    script.onload = () => {
      // Script has loaded, now you can access elements
      // and perform any necessary actions
    };
    script.innerHTML = `
      {
        "interval": "5m",
        "width": "${width}",
        "isTransparent": false,
        "height": "${height}",
        "symbol": "${symbol}",
        "showIntervalTabs": true,
        "displayMode": "single",
        "locale": "fi",
        "colorTheme": "${color}"
      }
    `;



    container.appendChild(script);


    // return () => {
    //   // Cleanup script when component is unmounted
    //   container.removeChild(script);
    // };
  }, []); // Empty dependency array ensures useEffect runs only once during component mount

//   const getWidget = useCallback(() => {
//     const script = document.createElement('script');
//     script.type = 'text/javascript';
//     script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-technical-analysis.js';
//     script.async = true;
//     script.innerHTML = `
//       {
//         "interval": "1m",
//         "width": "300",
//         "isTransparent": false,
//         "height": "200",
//         "symbol": "${symbol}",
//         "showIntervalTabs": true,
//         "displayMode": "single",
//         "locale": "fi",
//         "colorTheme": "light"
//       }
//     `;

//     const container = document.getElementById('tradingview-widget-container');
//     container.appendChild(script);

//     // return () => {
//     //   // Cleanup script when component is unmounted
//     //   container.removeChild(script);
//     // };
//   }, [symbol]);

  return (
    <>
    {/* {getWidget()} */}
    <div id="tradingview-widget-container123">
      <div className="tradingview-widget-container__widget" ></div>

    </div>
          <div className="tradingview-widget-copyright">
          <a href="https://www.tradingview.com/" rel="noopener nofollow" target="_blank">
          </a>
        </div>
    </>
  );
};

export default TradingViewWidget;