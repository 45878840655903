import React, { useState, useCallback, useEffect } from "react";
import { withFormik } from 'formik';
import * as Yup from "yup";
import moment from "moment"
import { useTranslation } from 'react-i18next';
import { useStoreState, useStoreActions } from 'easy-peasy';

import { Button, Typography, Spinner } from "stories/components";
import { Container, Row, Col, FormContainer, Card, CardBody } from "stories/layout";
import { Input, SelectMulti, Toggle, Radio } from "stories/forms";
import { useNotification } from "stories/components/Notification"

import { httpGetPortfolio, httpCreatePortfolio, httpUpdatePortfolio, httpDeletePortfolio } from "services/portfolios"
import { httpCreateUserAndInvitate } from "services/users"

import UserTypeSelect from "components/Forms/UserTypeSelect/UserTypeSelect"
import PortfolioSelect from "components/Forms/PortfolioSelect/PortfolioSelect"

const defaultValues = {
  id: null,
  username: "",
  account_user_portfolios: [],
  user_type: "",
  user_invitation: true
}

const UserInvitate = (props) => {
  
  const {
     values,
     touched,
     setTouched,
     errors,
     setErrors,
     handleChange,
     handleBlur,
     handleSubmit,
     setFieldValue,
     isValid,
     isTouched,
     isSubmitting,
     validateForm,
     preSetValues
   } = props;
   
   const {
      t,
      history,
      objectId
   } = props;

  useEffect (() => {
    validateForm()
  },[values]) 

  const portfolios = useStoreState((state) => state.portfolio?.portfolios);

   const getFormikInputProps = useCallback(
    name => ({
      name,
      value: values[name],
      error: Boolean(touched[name] && errors[name]),
      errorText: touched[name] ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange
    }),
    [values, errors, touched, handleBlur, handleChange]
  );
  
  const onSubmit = (e) => {
    e.preventDefault();
    //check user type and if it is admin, automatically add all portfolios to account_user_portfolios
    // if (values.user_type === "admin") {
 
    //     setFieldValue("account_user_portfolios", [...portfolios.map((portfolio) => {return portfolio.id})])
 
    // }
    validateForm().then(res => {
      if (Object.keys(res).length === 0) {
        handleSubmit()
      }
      else {
        setTouched(res);
        setErrors({
          ...res,
          general: t("Please check the form errors", "Tarkista lomakkeen virheet")
        })
      }
    })
  }
  
  const handleCancel = () => {
    props.handleReject()
  }
  
  const handleDelete = () => {
    httpDeletePortfolio(objectId).then(() => {
      props.handleResolve()
    })
  }
  
  return (
    <>
  {/*JSON.stringify(errors)*/}
      <Row className="mb-3">
        <Col className="mb-3">
          <Input
            {...getFormikInputProps("username")}
            label={t("Sähköposti")}
            type="text"
            icon="fas fa-user"
            required
          />
        </Col>
      </Row>
      
      <Row className="mb-3">
        <Col className="mb-3">
          <UserTypeSelect   
            {...getFormikInputProps("user_type")} 
            label={t("user_type", "Rooli")}
            required
            setFieldValue={setFieldValue}
            validateForm={validateForm}
          />
          <Typography className="">{t("userinvitation_info_text_1","Peruskäyttäjä voi käyttää palvelua vain hänelle liitetetyn salkun alla. Admin-käyttäjä näkee kaikki tiedot palvelussa. Peruskäyttäjä ei voi lisätä myöskään uusia käyttäjiä tai muuttaa heidän rooleja.")}</Typography>
        </Col>
      </Row>
      { values?.user_type !== "admin" ? (
        <Row className="mb-5">
        <Col className="mb-3">
        <PortfolioSelect 
        {...getFormikInputProps("account_user_portfolios")} 
        label={t("Salkku")}
        required
        setFieldValue={setFieldValue}
        multiple={true}
        placeholder={t("select_portfolio", "Valitse salkku")}
        />
        </Col>
        </Row> ) :(
          <Row className="mb-5">
          <Col className="mb-3">
          <Typography  variant="h3" className="text-dark">{t("Kaikki salkut valittu")} *</Typography>
          </Col>
          </Row> )
      }
      
      
      <Row className="mb-3">
        <Col className="mb-3">
          <Button onClick={handleCancel} >{t("user_invitation_cancel","Peruuta")}</Button>
        </Col>
        { values?.id ? (
        <Col className="mb-3 text-center">
          <Button onClick={handleDelete} variant="danger">{t("Delete","Poista")}</Button>
        </Col>
        ) : null }
        <Col className="mb-3 text-right">

            <Button variant="secondary" disabled={isSubmitting} onClick={onSubmit}>
              {t("Lähetä kutsu")}
            </Button>
          
          { Boolean(errors["general"]) && <Typography className="text-danger">{errors["general"]}</Typography> }
        </Col>
      </Row>
        
    </>
  );
}

const UserInvitateFormik = withFormik({
    enableReinitialize: true,
    validateOnMount: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return {
          ...defaultValues
        }
      }
    },
    validationSchema: props => {
      const {t} = props;
      const required = t("The field is required");
      return Yup.object().shape({
        username: Yup.string().email(t('email_check','Tarkista sähköpostiosoitteen muoto')).required(required),
        user_type: Yup.string().required(required),
        account_user_portfolios: Yup.array().min(1, t('Select at least one portfolio')).required(required),
        // account_user_portfolios: Yup.array().of(Yup.object().shape({
        //  portfolio: Yup.string().required(required)
        // })).required(required)
        // account_user_portfolios: Yup.array().of(Yup.string().required(required)).required(required)
        //array containing at least one item



      
      });
    },
    handleSubmit: (values, { setSubmitting, setErrors, props }) => {
      const {t, portfolio, account} = props;
      console.log("account", account);
      console.log("values", values);
      let account_user_portfolios = [];
      if (values.account_user_portfolios) {
        account_user_portfolios = values.account_user_portfolios.map(item => {
            console.log(item)
            return {
                portfolio: item?.id ? item?.id: item
            }
      })  
      }
      console.log(account_user_portfolios)
      let data = {
        ...values,
        username: values.username,
        email: values.username,
        user_invitation: true,
        account_user_portfolios: account_user_portfolios,
        password: "12345678",
        language: "fi"
      };
        httpCreateUserAndInvitate(data).then(res => {
            //props.handleResolve()
            setSubmitting(false);
            props.notify({ title:t("User"), message:t("User invitation sent")})
            props.handleResolve()
            //
          }).catch(error => {
            console.log(error.status)
            setSubmitting(false);
            
            //All keys in error.data
            const errorDataKey = Object.keys(error?.data);
            //default
            let errorCodeKey = errorDataKey?.[0];
            //default errorCode string to translator
            let errorCodeData = error?.data?.[errorCodeKey];
            //if error.data include errorCode then use that instead of default (errorCodeKey, errorCodeData). Also exclude errorCode key from errorDataKey list
            if (errorDataKey.length >= 2 && errorDataKey.includes('errorCode') ) {
              const errorCodeIndex = errorDataKey.indexOf('errorCode');
              errorCodeKey = errorDataKey[errorCodeIndex];
              errorCodeData = error?.data?.[errorCodeKey];
              //Exclude errorCode key from errorDataKey list
              errorDataKey.splice(errorDataKey.indexOf('errorCode'), 1);
              //For special cases, if render depends on errorCode
              setErrors({[errorCodeData]: t(errorCodeData)});
          
            }
            console.log(errorDataKey)
            console.log(errorCodeKey)
            console.log(errorCodeData)
            let errorData = error?.data[errorDataKey?.[0]];
            const valuesKeys = Object.keys(values);
            const usualErrorkeys = ['detail', 'message', 'non_field_errors'];
    
    
            if (Array.isArray(errorData)) {
              errorData = errorData[0];
            }
    
            if (!errorData || errorData === '') {
              setErrors({general: t('general_error','Virhe. Ota tarvittaessa yhteyttä asiakaspalveluun'),[errorCodeData]: t(errorCodeData)})
            }
            else if (errorDataKey.length > 0 && valuesKeys.includes(errorDataKey?.[0])) {
              //set new setErrors with include previous errors
            
              setErrors({[errorDataKey?.[0]]: t(errorCodeData,errorData),[errorCodeData]: t(errorCodeData)});
              
    
            }
            else if (errorDataKey.length > 0 && usualErrorkeys.includes(errorDataKey?.[0])) {
              setErrors({general: t(errorCodeData,errorData),[errorCodeData]: t(errorCodeData)});
    
            } 
            // else if dataKey is exist set general error
            else if (errorDataKey.length > 0) {
              setErrors({general: t(errorCodeData,errorData),[errorCodeData]: t(errorCodeData)});
            }
            else {
                setErrors({
                  general: `${t('general_error','Virhe. Ota tarvittaessa yhteys ylläpitoon.')} ${JSON.stringify(error)}`,[errorCodeData]: t(errorCodeData),
              })
            }            
            }
          )


      


      
      
    },
    displayName: "BasicForm"
  
})(UserInvitate)


// let account_user_portfolios = [];
// if (values.account_user_portfolios) {
//   account_user_portfolios = values.account_user_portfolios.map(item => {
//       return {
//           portfolio: item
//       }
//   })
    
    
const UserInvitateView = ({history, objectId, modalOpen, ...rest}) => {
  // API requests here
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [preSetValues, setPreSetValues] = useState();
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  const account = useStoreState((state) => state.account?.account);
  const portfolios = useStoreState((state) => state.portfolio?.portolios);
  
  const getObject = (id) => {
    
    setLoading(true)
    
    httpGetPortfolio(id).then(res => {
      
      setPreSetValues({
        ...defaultValues,
        ...res?.data,
      });
    
    }).finally(response => {
      setLoading(false);
    })
    
  }
  
  
  useEffect(() => {
    if (objectId && parseInt(objectId) > -1) {
     // getObject(objectId)
    }
  }, [objectId, modalOpen])
  
  
  if (loading) {
    return (
      <Container fluid>
        <Spinner />
      </Container>
    )
  }

  return (
    <UserInvitateFormik 
      t={t} 
      history={history} 
      preSetValues={preSetValues} 
      notify={notify} 
      objectId={objectId}
      account={account}
      {...rest} 
    />
  )
  
}

export default UserInvitateView;
