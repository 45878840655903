import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import { Input } from "stories/forms";
import { useAppContext }from "contexts/AppContext"

import RealEstateNewEdit from "views/app/PrivateInvestmentFundEdit/RealEstateNewEdit"
import RealEstatePurchase from "views/app/RealEstateRentNew/RealEstatePurchase"


const RealEstateRentNew = ({ label,handleReject,handleResolve, ownLoan, ...rest }) => {
  const { t } = useTranslation();
  
//   const [stock, setStock] = useState({
//     account: 132,
//     created: "2024-08-15T06:46:16.102805Z",
//     currency_label: "EUR",
//     id: 1768,
//     name: "testing",
//     size: "0.000000",
//     updated: "2024-08-15T06:46:16.102831Z",
//     latest_data: [],
//     datas_count: 0,
//     type: "other",
//     symbol: "dsff-f",
//     sector: null,
//     stock_market: null,
//     private_sector: null,
//     public_sector: null,
//     dimensio: null
// })

  const [stock, setStock] = useState(null)    

  
  return (
    <>
        {!stock &&
            <div className="">
                
                <RealEstateNewEdit 
                setId={setStock} 
                handleResolve={handleResolve}
                handleReject={handleReject}
                ownLoan={ownLoan}
                {...rest} />
            </div>
        }
        {stock &&
        <RealEstatePurchase 
            stock={stock} 
            handleResolve={handleResolve}
            handleReject={handleReject}
            {...rest}
        />
        }

        {/* <button onClick={handleResolve}>resolve</button> */}


    </>
  )
  
  
}

export default RealEstateRentNew;
