import http from './api'

const url = '/currencies'

export const httpGetCurrencies = (params) => {
  return http({
    url: `${url}/`,
    method: 'GET',
    params,
  })
}

export const httpGetCurrencyRate = (params) => {
  return http({
    url: `${url}/get_currency_rate_by_date/`,
    method: 'GET',
    params,
  })
}