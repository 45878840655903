import { action, thunk, persist } from 'easy-peasy';
import { httpGetMyAccount, httpGetMyAccounts } from "services/accounts"

const model = {
  loading: false,
  setLoading: action((state, payload) => {
    state.loading = payload
  }),
  account: {},
  setAccount: action((state, payload) => {
    state.account = payload
  }),
  accoutns: [],
  setAccounts: action((state, payload) => {
    state.accounts = payload
  }),
  getAccount: thunk(async (actions, payload) => {
    actions.setLoading(true)
    httpGetMyAccounts().then(response => {
      if (response?.data) {
        //filter the main account based on is_active
        const filtered = response?.data.filter(o => o.is_active)
        if (filtered && filtered.length > 0) {
          actions.setAccount(filtered[0])
        }
        actions.setAccounts(response?.data)
      }
    }, error => {
      
    }).finally(() => {
      actions.setLoading(false)
    })
    
  }),
};

export default model;