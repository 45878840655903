import React from 'react';
import PropTypes from 'prop-types';
import {
  CardHeader as BootstrapCardHeader,
} from "reactstrap";
import './Container.css';

export const CardHeader = ({ children, ...rest }) => {
  
  return (
    <BootstrapCardHeader
      {...rest}
    >
    {children}
    </BootstrapCardHeader>
  )
};

CardHeader.propTypes = {
  
};


CardHeader.defaultProps = {
  
};

