import http from './api'

const url = '/token/'

export const httpLogin = (username, password) => {
  console.log("httpLogin");
  return http({
    url: url,
    method: 'POST',
    data: {
      username,
      password,
    },
  })
}
