import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import { Input } from "stories/forms";
import { useAppContext }from "contexts/AppContext"
import { useStoreState, useStoreActions } from 'easy-peasy';



const StockMarketSelect = ({ label, name="stock_market", onChange, value,disabled, showPrivate=false, showAll=true, icon}) => {
  const { t } = useTranslation();
  const { enums } = useAppContext()

  const stock_markets = enums?.stock?.basic_data?.stock_market
  const supported_stock_markets = useStoreState((state) => state.stockMarkets?.stockMarkets);
  const myAccount = useStoreState((state) => state.account?.account);

  const loading = useStoreState((state) => state.stock_markets?.loading);



  const inputOptions = [
    {id: "XHEL", name: t("Helsinki")},
    {id: "XSTO", name: t("Tukholma")},
    {id: "XCSE", name: t("Kööpenhamina")},
    {id: "XICE", name: t("Islanti")},
    // {id: "PRIVATE", name: "Yksityinen"},
  ]

  //loop through stock_markets and create list where key is id and value is name
  const getInputOptions = useCallback(() => {
    const stock_markets = enums?.stock?.basic_data?.stock_market

    //filter out stock_markets if key is not in supported_stock_markets list
    // const stock_markets_filtered = stock_markets

    let stock_markets_filtered = stock_markets.filter(obj => supported_stock_markets.includes(obj.key));

    if (myAccount && myAccount?.license?.name == "Lite"){
      stock_markets_filtered = stock_markets_filtered.filter(obj => obj.key == "XHEL" || obj.key == "XSTO" || obj.key == "XCSE" || obj.key == "XICE")
    }

    //sort by value
    stock_markets_filtered.sort((a, b) => (a.value > b.value) ? 1 : -1)

    return stock_markets && stock_markets_filtered.map(obj => {

      return (
        <option value={obj.key} >{obj.value} {obj.key}</option>
      )
    })
  }, [enums, supported_stock_markets, loading])

  
  // const getInputOptions = useCallback(() => {

  //   return inputOptions && inputOptions.map(obj => {
  //     return (
  //       <option value={obj.id}>{obj.name}</option>
  //     )
  //   })
  // }, [inputOptions])
  return (
    <>
    <Input label={label || label === "" ? label : t("stock_market_select_label", "Pörssi")} type="select" name={name} disabled={disabled} onChange={onChange} value={value} icon={icon}>
      {showAll && 
        <option value="" label={t("Kaikki")}></option>
      }
      {getInputOptions()}
      { showPrivate ? ( 
        <option value="PRIVATE">{t("Yksityinen")}</option>
        ) : null }
    </Input>
    </>
  )
  
  
}

export default StockMarketSelect;
