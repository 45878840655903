import http from './api'

const url = '/splits'

export const httpGetSplits = (params) => {
  return http({
    url: `${url}/` ,
    method: 'GET',
    params
  })
}

export const httpGetSplit = (id) => {
  return http({
    url: `${url}/${id}/` ,
    method: 'GET',
  })
}

export const httpCreateSplit = (data) => {
  return http({
    url: `${url}/` ,
    method: 'POST',
    data
  })
}

export const httpUpdateSplit = (id, data) => {
  return http({
    url: `${url}/${id}/` ,
    method: 'PATCH',
    data
  })
}

export const httpDeleteSplit = (id, params) => {
  return http({
    url: `${url}/${id}/` ,
    method: 'DELETE',
    params
  })
}