import {React, useState, useEffect } from "react";
import moment from "moment";
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";

import { useStoreState, useStoreActions } from 'easy-peasy';

import { Container, Row, Col, Card, CardHeader, CardBody, List } from "stories/layout";
import { Typography, Button, Link } from "stories/components";
import { PageWrapper, PageHeader } from "components/Page"
import { httpAcceptTransactionPractice } from "services/users"
import { useNotification } from "stories/components/Notification"
import { useAuthContext } from "contexts/AuthContext"







const Info = (props) => {
  const handleCancel = () => {
    props.handleReject()
    }

  const [disabled, setDisabled] = useState(true);
  const [countdown, setCountdown] = useState(10);
  const [errors, setErrors] = useState({})
  const { notify } = useNotification(); 

  const { myUser, getMyUser } = useAuthContext()
  const portfolios = useStoreState((state) => state.portfolio?.portfolios);



  useEffect(() => {
    if (countdown > 0) {
      const timer = setInterval(() => {
        
        countdown > 0 && setCountdown((countdown) => countdown - 1);
      }, 1000);

      return () => clearInterval(timer);
    } else {
      setDisabled(false)
    }
  }, [countdown]);

  const handleClick = () => {
    // Perform the action here
    console.log('Button clicked');
    httpAcceptTransactionPractice(myUser.id).then(() => {
      // getMyUser()
      props.handleResolve()



    }, error => {
      notify({ title:t("User"), message:t("Päivitys epäonnistui.")})
      props.handleResolve()

      if (error?.data?.detail || error?.data?.general) {
        setErrors({
          general: error?.data?.detail? error?.data?.detail: error?.data?.general 
        })
      }
      else {
        setErrors({
          general: t("Unknown error. Please try again later or contact support.")
        })
      }
      })
  };

  const history = useHistory();    
  const { t } = useTranslation();
  return (
    <>
     

          <Card className="">
          <CardHeader className="text-bold   bg-danger">
            <div className="d-flex justify-content start align-items-center  bg-danger   ">
            <i className="fas fa-exclamation-triangle text-white fa-3x mr-3" />
            <div className="d-flex  align-items-center bg-danger">
                <Typography  variant="medium" className="text-white d-flex inline">
                  {t("accept_transaction_practices_text_1",`Olet lisäämässä ensimmäistä kirjausta Investime järjestelmään. Toimivan lopputuloksen perustana on salkun sisällä samaa osaketta koskevien kirjausten syöttäminen`) }  {t("accept_transaction_practices_text_2","kronologisessa")} {t("accept_transaction_practices_text_3",`järjestyksessä`) }
                </Typography>
  
                </div>   
            </div>
          </CardHeader>
          <CardBody>
          <Row>
            <Col className="text-center">
              <Typography bold variant="h3 text-dark"> {t("accept_transaction_practices_example_order","Esimerkki kirjausten syöttämisestä päivämäärä järjestyksessä")}</Typography>
            </Col>
          </Row>
          <Row>
            <Col className="my-2 text-white">
                <div className=" p-2 bg-success">1.2.2000 { t("OSTO")}</div>
            </Col>
            <Col className="my-2 text-white">
              <div className=" p-2 bg-success">5.12.2000 {t("OSTO")}</div>
            </Col>
            <Col className="my-2 text-white">
              <div className=" p-2 bg-danger">3.3.2001 {t("MYYNTI")}</div>
            </Col>
            <Col className="my-2 text-white">
              <div className=" p-2 bg-success">5.6.2001 {t("OSTO")}</div>
            </Col>
            
          </Row>
          <Row>
            <Col className="text-center pb-5">
              <Typography  variant="medium text-dark">{t("accept_transaction_practices_text_4", "Järjestelmä lisää Splitit käsiteltäviksi päivämäärien mukaisesti!")}</Typography>
            </Col>
          </Row>
          <Row className="mt-5">
          <Col className="my-3 text-left">
            {/*<Button onClick={handleCancel} variant="default">{t("Close","Sulje")}</Button>*/}
          </Col>
  
          <Col className="mb-3 text-right">
  
              <Button variant="secondary"onClick={handleClick} disabled={disabled} >
                {`${t("accept_transaction_practices_understand_accept", "Ymmärrän, aloita käyttö  (")} ${countdown} ${t(")")}` }
              </Button>
              { Boolean(errors["general"]) && <Typography className="text-danger">{errors["general"]}</Typography> }
          </Col>
        </Row>
          </CardBody>
  
          </Card>


      
      

      
    </>
  );
}

export default Info;