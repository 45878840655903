import React, { useState, useCallback, useEffect } from "react";
import { withFormik } from 'formik';
import * as Yup from "yup";
import moment from "moment"
import { useTranslation } from 'react-i18next';
import { useStoreState, useStoreActions } from 'easy-peasy';

import { Button, Typography, Spinner, Modal, Link } from "stories/components";
import { Container, Row, Col, FormContainer, Card, CardBody } from "stories/layout";
import { Input, SelectMulti, Toggle, Radio } from "stories/forms";
import { useNotification } from "stories/components/Notification"
import CurrencySelect from "components/Forms/CurrencySelect/CurrencySelect"
import SectorSelect from "components/Forms/SectorSelect/SectorSelect"
import SectorEdit from "views/app/SectorEdit/SectorEdit"
import AllSecuritySelect from "components/Forms/AllSecuritySelect/AllSecuritySelect"


import InvestmentTypeSelectSub from "components/Forms/InvestmentTypeSelectSub/InvestmentTypeSelectSub"

import { useAppContext } from "contexts/AppContext"
import { httpGetAllSecurities } from "services/private_investment_funds"


import { 
  httpGetPrivateInvestmentFund, 
  httpCreatePrivateInvestmentFund, 
  httpUpdatePrivateInvestmentFund, 
  httpDeletePrivateInvestmentFund 
} from "services/private_investment_funds"
import { set, setISODay } from "date-fns";

const defaultValues = {
  id: null,
  name: "",
}

const PrivateInvestmentFundEdit = (props) => {

  
  const {
     values,
     setValues,
     touched,
     setTouched,
     errors,
     setErrors,
     handleChange,
     handleBlur,
     handleSubmit,
     setFieldValue,
     isTouched,
     isSubmitting,
     validateForm,
     preSetValues
   } = props;
   
   const {
      t,
      history,
      objectId,
      selectedSector,
      setSelectedSector,
      ownLoan,   
   } = props;

   
   const getFormikInputProps = useCallback(
    name => ({
      name,
      value: values[name],
      error: Boolean(touched[name] && errors[name]),
      errorText: touched[name] ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange
    }),
    [values, errors, touched, handleBlur, handleChange]
  );
  
  const account = useStoreState((state) => state.account?.account);
  const getSectors = useStoreActions((actions) => actions.sectors.getSectors);

  const [securityOptions, setSecurityOptions] = useState([]);
  const [loadingOptions, setLoadingOptions] = useState(false);

  const [selectedParentFund, setSelectedParentFund] = useState(null);
  const [selectedParentFundId, setSelectedParentFundId] = useState(null);



    // Modal: New/Edit Sector

    const [modalEditOpen, setModalEditOpen] = useState(false)
    const [modalEditId, setModalEditId] = useState()
    
    const toggleModalEdit = () => {
      setModalEditOpen(s => !s);
    }
    
    const openModalEdit = (id) => {
      setModalEditOpen(true)
      setModalEditId(id);
    }
    
    const modalEditResolve = () => {
      setModalEditOpen(false);
      setModalEditId(null);
      getSectors()
    }
    
    const modalEditReject = () => {
      setModalEditOpen(false);
      setModalEditId(null);
    }

  


  const onSubmit = (e) => {
    e.preventDefault();
    validateForm().then(res => {
      if (Object.keys(res).length === 0) {
        handleSubmit()
      }
      else {
        setTouched(res);
        setErrors({
          ...res,
          general: t("Please check the form errors", "Tarkista lomakkeen virheet")
        })
      }
    })
  }
  
  const handleCancel = () => {
    props.handleReject()
  }
  
  const handleDelete = () => {
    const result = window.confirm(t("are_you_sure_window_confirm","Oletko varma?"));
    if (result) {
      httpDeletePrivateInvestmentFund(objectId).then(() => {
        props.handleResolve()
      })
    }
  }


  const handleSectorChange = (name, value) => {
    setSelectedSector(s => ({
      ...s,
      [name]: value
    }))
  }

  const handleChangeSecurity = (name, value) => {
    console.log("name", name, "value", value)
    let value_id = value?.value
    //separate related_model_type and related_model_id from value using "," first part is related_model_type and second is related_model_id
    //check if value is string
    setSelectedParentFund(value)

    if (typeof value_id === "string" && value_id !== "select") {
      const related_model_type = value_id?.split(",")[0]
      const type = value_id?.split(",")[1]
      const related_model_id = Number(value_id?.split(",")[2])

      let inv_key = related_model_type
      if (inv_key == 30) {
        inv_key = type
      }
      setSelectedParentFundId(related_model_id)
      setFieldValue(name, related_model_id)

    
    }
    else {

      setSelectedParentFundId(null)
      setFieldValue(name, null)
    }
  }
  

  const getAllSecuritiesOptions = () => {
    let params = {
      id: null,
      extend_data: false,
      custom_limit: null,
      offset: null,
      page: null,
      related_model_type: 30
    }
    if (true) {
    setLoadingOptions(true)
    
    httpGetAllSecurities(params).then(response => {
      setSecurityOptions(response?.data?.results)
      // setCount(response?.data?.count)
      setLoadingOptions(false)

    }, error => {
      setLoadingOptions(false)

    })}
  }

  useEffect(() => {
    getAllSecuritiesOptions()
  }, [])

  const getAllSecuritySelect = useCallback(() => {
    return (
      <AllSecuritySelect 
      label={t("real_estate_all_security_select","Linkitä")} 
      async={false} 
      style={{minWidth: '350px!important'}} 
      name="parent_private_investment_fund"  
      onChange={handleChangeSecurity} 
      setFieldValue={handleChangeSecurity} 
      value={selectedParentFund} 
      showAllOption={true} 
      options={securityOptions} 
      loadingOptions={loadingOptions}
      required
      />
    )
  }, [securityOptions, loadingOptions, selectedParentFund])


  const getSectorSelect = useCallback(() => {
    return (
      <SectorSelect
        {...getFormikInputProps("sector_select")}
        label={`${t("PrivateInvestmentFundEdit_sector", "Kategoria")} *` }
        multiple={false}
        onChange={handleSectorChange}
        value={selectedSector?.sector_select}
        setFieldValue={handleSectorChange}
      />
    )
  }, [selectedSector])

  return (
    <>
     <div className="px-3 pt-2">
      {/* {JSON.stringify(props)} */}
      <Row className="mt-2 mb-2 border-bottom">
        <Col xs={12} md={6} className="ml-auto pb-3 pl-3 d-flex align-items-center">

            <div className="d-inline-flex">
                {ownLoan ?
              <Typography variant="h2" className="">{t("real_estate_new_edit_title_1", "Lisää lainan perustiedot")}:  </Typography>
              : 
                <Typography variant="h2" className="">{t("PrivateInvestmentFundEdit_title_1", "Lisää perustiedot")}:  </Typography>
                }

            </div>
          
        </Col>
        <Col xs={12} md={6} className="ml-auto pb-3">
        {/*  <PortfolioSelectStore icon="fas fa-suitcase" iconColor="text-secondary" disabled={true}/> */}
        </Col>

      </Row>
      
  
          {values?.type =="index" ?
            <>
            <Row>
              <Col className="mb-3">
                <Input
                  {...getFormikInputProps("name")}
                  label={t("PrivateInvestmentFundEdit_name", "Osoite")}
                  required
                  />
              </Col>
            </Row>
            <Row>
              <Col className="mb-3">
                <Input
                  {...getFormikInputProps("symbol")}
                  label={` ${t("PrivateInvestmentFundEdit_symbol", "Postinumero")} *`}
                  />
              </Col>
            </Row>
            <Row>
              <Col className="mb-3">
                <Input
                  {...getFormikInputProps("stock_market")}
                  label={` ${t("PrivateInvestmentFundEdit_stock_market", "Kaupunki")} *` }
                  />
              </Col>
            </Row>
            </>
            : <>
            <Row>
              <Col className="mb-3">
                <Input
                  {...getFormikInputProps("name")}
                  label={t("PrivateInvestmentFundEdit_name", "Nimi")}
                  required
                  />
              </Col>
            </Row>
            <Row>
              <Col className="mb-3">
                <Input
                  {...getFormikInputProps("symbol")}
                  label={` ${t("PrivateInvestmentFundEdit_symbol", "Tunnus / rekisterin.")} *`}
                  />
              </Col>
            </Row>
            </>
          }

          {
            !ownLoan &&
            <Row>
              <Col className="mb-3">
                <InvestmentTypeSelectSub 
                {...getFormikInputProps("type")}
                label={t("PrivateInvestmentFundEdit_type", "Tyyppi *")}
                filter = {["own_loans"]}
                required
                />

              </Col>
            </Row>
          }

          {ownLoan &&

            <Row>
              <Col className="mb-3">
                {/* {JSON.stringify(selectedParentFund)}
                {JSON.stringify(selectedParentFundId)} */}
                {getAllSecuritySelect()}
              </Col>
            </Row>
          }
          <Row>
            <Col className="mb-1">
              {getSectorSelect()}
              {/* {JSON.stringify(selectedSector)} */}
              {/* <SectorSelect
                {...getFormikInputProps("sector_select")}
                label={`${t("PrivateInvestmentFundEdit_sector", "Sektori")} *` }
                multiple={false}
                onChange={handleSectorChange}
                value={selectedSector?.sector_select}
                setFieldValue={handleSectorChange}
              /> */}
            </Col>
          </Row>
          <Row className="mb-3">
        
              <Col>
                <div  calssName="text-blue" onClick={() => openModalEdit()} style={{ cursor: 'pointer' }}>
                <Typography variant="small">  {t("sector_create_here","Luo kategoria tästä")}</Typography>
                </div>
              </Col>
            </Row>

          {/* <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("size")}
                label={t("PrivateInvestmentFundEdit_size", "Arvopaperin koko")}
                type="number"
              />
            </Col>
          </Row> */}

            
            
        <Row>
          <Col className="mb-3">
            <CurrencySelect 
              {...getFormikInputProps("currency_label")} 
              label={t("PrivateInvestmentFundEdit_currency", "Valuutta")}
              required
              disabled={objectId? true: false}

            />
            { !objectId &&
              <Typography variant="small">{t("Valuutta tietoa ei voi muokata myöhemmin")} </Typography>
            }
            
          </Col>
        </Row>
    

    {  account?.show_relation_to_security &&
      <>
          {/* <Row className="mb-3">

              <Col >
              <Input
                  {...getFormikInputProps("dimensio")} 
                  label={t("rivateInvestmentFundEdit_dimensio", "Dimensio")}
                  />
              </Col>
          </Row> */}
          {/* <Row className="mb-3">

                <Col >
                <Input
                    {...getFormikInputProps("custom_symbol")} 
                    label={t("rivateInvestmentFundEdit_custom_symbol", "Oma tunnus")}
                    />
                </Col>
          </Row> */}
      </>
    }

    <Row className="bg-lighter pt-3  rounded-bottom">
      <Col className="mb-3">
        <Button onClick={handleCancel} variant="default">{t("Close","Sulje")}</Button>
      </Col>
      { values?.id ? (
      <Col className="mb-3 text-center">
        {/*<Button onClick={handleDelete} variant="danger">{t("Delete","Poista")}</Button>*/}
      </Col>
      ) : null }
      <Col className="mb-3 text-right">

          <Button variant="secondary" disabled={isSubmitting} onClick={onSubmit}>
            {t("Save","Tallenna ja jatka")}
          </Button>
        
        { Boolean(errors["general"]) && <Typography className="text-danger">{errors["general"]}</Typography> }
      </Col>
    </Row>

    {/* <Row className="bg-lighter pt-3  rounded-bottom">
        <Col className="mb-3">
          <Button name="purchaseEdit_cancel" onClick={handleCancel} variant="primary">{t("Sulje")}</Button>
        </Col>
        { values?.id > 0 ? (
        <Col className="mb-3 text-center">
          <Button name="transactionEdit_delete" onClick={handleDelete} variant="danger">{t("Poista")}</Button>
        </Col>
        ) : null}
        <Col className="mb-3 text-right">
          { !preSetValues?.state_delivery && (
            <Button name="purchaseEdit_submit" variant="secondary" disabled={isSubmitting} onClick={onSubmit}>
              { t("Kirjaa ja tallenna") }
            </Button>
          )}
          
          { Boolean(errors["general"]) && <Typography className="text-danger">{errors["general"]}</Typography> }
        </Col>
      </Row> */}

    <Modal
      title={t("sector_title","Toimiala")}
      isOpen={modalEditOpen}
      toggleModal={toggleModalEdit}
      
    >
      <SectorEdit
        objectId={modalEditId}
        handleResolve={modalEditResolve}
        handleReject={modalEditReject}
        extraMargin={true}
      />
    </Modal>
    </div>
    </>
  );
}

const PrivateInvestmentFundEditFormik = withFormik({
    enableReinitialize: true,
    validateOnMount: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return {
          ...defaultValues
        }
      }
    },
    validationSchema: props => {
      const {t} = props;
      const required = t("The field is required");
      return Yup.object().shape({
        name: Yup.string().required(required).nullable(),
        // type: Yup.string().required(required).nullable(),
        symbol: Yup.string().required(required).nullable(),
        // sector: Yup.string().required(required).nullable(),
        // stock_market: Yup.string().required(required).nullable(),
      });
    },
    handleSubmit: (values, { setSubmitting, setErrors, props }) => {
      const {t, portfolio, account, selectedSector, setId, ownLoan} = props;
      
      const values_size = values?.size ? parseFloat(values?.size.toString().replace(",",".")) : 0;

      let type = null
      if (ownLoan){
        type = "own_loans"
      }
      else {
        type = values?.type === "index" ? "index" : "other";
      }

      
      let data = {
        ...values,
        account: account?.id,
        size: values_size,
        general_sector: selectedSector?.sector_select?.id,
        private_sector: null,
        public_sector: null,
        type: type,
      };
      
      if (values?.id) {
        httpUpdatePrivateInvestmentFund(values.id, data).then(res => {
          setSubmitting(false);
          props.notify({ title:t("Private investment fund", "Arvopapri"), message:t("Updated")})
          props.handleResolve()
          
          
        }, error => {
          setSubmitting(false);
          if (error?.data?.detail) {
            setErrors({
              general: error?.data?.detail,
            })
          }
          else if (error?.data) {
            setErrors({
              general: t(error?.data),
            })
          }
          else {
            setErrors({
              general: t("Unknown error. Please try again later or contact support.")
            })
          }
        })
      }
      else {
        httpCreatePrivateInvestmentFund(data).then(res => {
          setSubmitting(false);
          props.notify({ title:t("Real Estate", "Kohde"), message:t("Created")})
        //   props.handleResolve()
          setId(res?.data)
          
        }, error => {
          setSubmitting(false);
          if (error?.data?.detail) {
            setErrors({
              general: error?.data?.detail,
            })
          }
          else if (error?.data) {
            setErrors({
              general: t(error?.data),
            })
          }
          else {
            setErrors({
              general: t("Unknown error. Please try again later or contact support.")
            })
          }
        })
      }
      
      
    },
    displayName: "BasicForm"
  
})(PrivateInvestmentFundEdit)
    
    
const PrivateInvestmentFundEditView = ({history, objectId,ownLoan, modalOpen,setId, ...rest}) => {
  // API requests here
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [preSetValues, setPreSetValues] = useState();
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  const account = useStoreState((state) => state.account?.account);
  const [selectedSector, setSelectedSector] = useState({sector_select: []});
  const { getSectorName } = useAppContext()

  
  const getObject = (id) => {
    
    
    setLoading(true)
    
    httpGetPrivateInvestmentFund(id).then(res => {
      
      setPreSetValues({
        ...defaultValues,
        ...res?.data,
      });
      let sector = null
      if (res?.data?.private_sector){
        //add 1_ to private_sector id like 1_2322
        sector =`2_${res?.data?.private_sector}`
      }
      if (res?.data?.public_sector){
        sector =`1_${res?.data?.public_sector}`
      }

      setSelectedSector({
        sector_select: sector ? [{id: sector, label: getSectorName(sector), name:getSectorName(sector) }] : []
      })
      console.log("sector", sector, getSectorName(sector))

    
    }).finally(response => {
      setLoading(false);
    })
    
    
  }
  
  
  useEffect(() => {
    if (objectId && parseInt(objectId) > -1) {
      getObject(objectId)
    }
  }, [objectId, modalOpen])
  
  
  if (loading) {
    return (
      <Container fluid>
        <Spinner />
      </Container>
    )
  }

  return (
    <PrivateInvestmentFundEditFormik 
      t={t} 
      history={history} 
      preSetValues={preSetValues} 
      notify={notify} 
      objectId={objectId}
      account={account}
      selectedSector={selectedSector}
      setSelectedSector={setSelectedSector}
      setId={setId}
      ownLoan={ownLoan}
      {...rest} 
    />
  )
  
}

export default PrivateInvestmentFundEditView;
