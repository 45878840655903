import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import { Input } from "stories/forms";
import { useAppContext }from "contexts/AppContext"
import { useStoreState, useStoreActions } from 'easy-peasy';






const LanguageSelect = ({ label, name="currency", onChange, value, setFieldValue,validateForm, ...rest }) => {
  const { t } = useTranslation();
  const { enums } = useAppContext()

  const languages = enums?.users?.my_user?.language
//   const portfolios = useStoreState((state) => state.portfolio?.portfolios);s

  
  useEffect(() => {
    if (value === undefined || value === "") {
        setFieldValue("language", languages?.[0].key)
        }
    }, [enums])




  const getInputOptions = useCallback(() => {
    return languages && languages.map(obj => {
      return (
        <option value={obj.key}>{obj.value}</option>
      )
    })
  }, [enums])
  
  return (
    <Input 
      label={label ? label : t("language", "Kieli")} 
      type="select" 
      name={name} 
      onChange={onChange} 
      value={value} 
      {...rest}
    >
      {/*<option value=""></option>*/}
      {getInputOptions()}
    </Input>
  )
  
  
}

export default LanguageSelect;