import http from './api'

const url = '/accounts'

export const httpGetMyAccount = () => {
  return http({
    url: `${url}/me/` ,
    method: 'GET',
  })
}

export const httpUpdateAccount  = (id, data) => {
  return http({
    url: `${url}/${id}/` ,
    method: 'PATCH',
    data
  })
}

export const httpSubscribeAccountLicense = (data) => {
  return http({
    url: `${url}/me/subscribe_license/` ,
    method: 'POST',
    data
  })
}

export const httpCreateAccountSecurityRelation  = (id, data) => {
  
  return http({
    url: `${url}/${id}/create_relation_account_security/` ,
    method: 'POST',
    data
  })
}

export const httpUpdateAccountSecurityRelation  = (id, data) => {
  return http({
    url: `${url}/${id}/create_relation_account_security/` ,
    method: 'PATCH',
    data
  })
}

export const httpGetAccountSecurityRelation  = (id, params) => {
  return http({
    url: `${url}/${id}/get_relations_account_security/` ,
    method: 'GET',
    params
  })
}

export const httpGetMyAccounts = () => {
  return http({
    url: `${url}/my_accounts/` ,
    method: 'GET',
  })
}

export const httpGetMyAccountPayments = () => {
  return http({
    url: `${url}/my_account_payments/` ,
    method: 'GET',
  })
}

export const httpDownloadReceiptPdf = (id, params) => {
  console.log("httpDownloadFile");
  return http({
    url: `${url}/download_receipt_pdf/`,
    method: 'GET',
    responseType: 'blob',
    params
  })
}