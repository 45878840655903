import React, { useState, useCallback, useEffect } from "react";
import { withFormik } from 'formik';
import * as Yup from "yup";
import moment from "moment"
import { useTranslation } from 'react-i18next';
import { useStoreState, useStoreActions } from 'easy-peasy';

import { Button, Typography, Spinner } from "stories/components";
import { Container, Row, Col, FormContainer, Card, CardBody } from "stories/layout";
import { Input, SelectMulti, Toggle, Radio } from "stories/forms";
import { useNotification } from "stories/components/Notification"
import { httpChangePassword, httpActivateInvatedUser } from "services/users";


import CurrencySelect from "components/Forms/CurrencySelect/CurrencySelect"

const defaultValues = {
  id: null,
  name: "",
}

const ChangePassword = (props) => {
  
  const {
     values,
     touched,
     setTouched,
     errors,
     setErrors,
     handleChange,
     handleBlur,
     handleSubmit,
     setFieldValue,
     isValid,
     isTouched,
     isSubmitting,
     validateForm,
     preSetValues
   } = props;
   
   const {
      t,
      history,
      objectId
   } = props;
   
   const getFormikInputProps = useCallback(
    name => ({
      name,
      value: values[name],
      error: Boolean(touched[name] && errors[name]),
      errorText: touched[name] ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange
    }),
    [values, errors, touched, handleBlur, handleChange]
  );
  
  const onSubmit = (e) => {
    e.preventDefault();
    validateForm().then(res => {
      if (Object.keys(res).length === 0) {
        handleSubmit()
      }
      else {
        setTouched(res);
        setErrors({
          ...res,
          general: t("Please check the form errors", "Tarkista lomakkeen virheet")
        })
      }
    })
  }
  
  const handleCancel = () => {
    props.handleReject()
  }
  
  const handleDelete = () => {
    // httpDeletePortfolio(objectId).then(() => {
    //   props.handleResolve()
    // })
  }
  
  return (
    <>
      <Row>
        <Col className="mb-3">
            <Input
            {...getFormikInputProps("current_password")}
            type="password"
            placeholder={t("Current password","Nykyinen salasana")}
            />
        </Col>
      </Row>
      <Row className="mt-5">
        <Col className="mb-3">
            <Input
            {...getFormikInputProps("new_password")}
            type="password"
            placeholder={t("New password","Uusi salasana")}
            />
        </Col>
        </Row>
        
        <Row>
        <Col className="mb-3">
            <Input
            {...getFormikInputProps("check_password")}
            type="password"
            placeholder={t("Check password","Vahvista salasana")}
            />
        </Col>
        </Row>
      
      
      <Row className="mt-5">
        <Col className="mb-3">
          <Button onClick={handleCancel} variant="default">{t("Close","Sulje")}</Button>
        </Col>

        <Col className="mb-3 text-right">

            <Button variant="secondary" disabled={isSubmitting} onClick={onSubmit}>
              {t("Save","Tallenna")}
            </Button>
          
          { Boolean(errors["general"]) && <Typography className="text-danger">{errors["general"]}</Typography> }
        </Col>
      </Row>
        
    </>
  );
}

const ChangePasswordFormik = withFormik({
    enableReinitialize: true,
    validateOnMount: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return {
          ...defaultValues
        }
      }
    },
    validationSchema: props => {
      const {t} = props;
      const required = t("The field is required");
      return Yup.object().shape({
        current_password: Yup.string().required(required),
        new_password: Yup.string().required(required)
        .min(8, t('Password must be 8 characters long'))
        .matches(/^\S*$/, t('Whitespace is not allowed'))
        .matches(/[0-9]/, t('Password requires a number'))
        .matches(/[a-z]/, t('Password requires a lowercase letter'))
        .matches(/[A-Z]/, t('Password requires an uppercase letter'))
        .matches(/[^\w]/, t('Password requires a symbol')),
        check_password: Yup.string().required(required).oneOf([Yup.ref('new_password'), null], t('Salasanat eivät täsmää')),
      });
    },
    handleSubmit: (values, { setSubmitting, setErrors, props }) => {
      const {t, portfolio, account} = props;
      console.log("account", account);
      let data = {
        data: {
          current_password: values.current_password,
          new_password: values.new_password,
          check_password: values.check_password
        }
          
      };
      
      
        httpChangePassword("me", data).then(res => {
          setSubmitting(false);
          if (res?.status === 200) {
            props.notify({ title:t("password_title","Salasana"), message:t("password_update_ok","Päivitetty onnistuneesti")})
            // window.location.href = "/auth/login"
            // props.history.push("/auth/login")
            props.handleResolve()
          }
        }, error => {
          console.log(error.status)
          setSubmitting(false);
          
          //All keys in error.data
          const errorDataKey = Object.keys(error?.data);
          //default
          let errorCodeKey = errorDataKey?.[0];
          //default errorCode string to translator
          let errorCodeData = error?.data?.[errorCodeKey];
          //if error.data include errorCode then use that instead of default (errorCodeKey, errorCodeData). Also exclude errorCode key from errorDataKey list
          if (errorDataKey.length >= 2 && errorDataKey.includes('errorCode') ) {
            const errorCodeIndex = errorDataKey.indexOf('errorCode');
            errorCodeKey = errorDataKey[errorCodeIndex];
            errorCodeData = error?.data?.[errorCodeKey];
            //Exclude errorCode key from errorDataKey list
            errorDataKey.splice(errorDataKey.indexOf('errorCode'), 1);
            //For special cases, if render depends on errorCode
            setErrors({[errorCodeData]: t(errorCodeData)});
        
          }
          console.log(errorDataKey)
          console.log(errorCodeKey)
          console.log(errorCodeData)
          let errorData = error?.data[errorDataKey?.[0]];
          const valuesKeys = Object.keys(values);
          const usualErrorkeys = ['detail', 'message', 'non_field_errors'];
  
  
          if (Array.isArray(errorData)) {
            errorData = errorData[0];
          }
  
          if (!errorData || errorData === '') {
            setErrors({general: t('general_error','Virhe. Ota tarvittaessa yhteyttä asiakaspalveluun'),[errorCodeData]: t(errorCodeData)})
          }
          else if (errorDataKey.length > 0 && valuesKeys.includes(errorDataKey?.[0])) {
            //set new setErrors with include previous errors
          
            setErrors({[errorDataKey?.[0]]: t(errorCodeData,errorData),[errorCodeData]: t(errorCodeData)});
            
  
          }
          else if (errorDataKey.length > 0 && usualErrorkeys.includes(errorDataKey?.[0])) {
            setErrors({general: t(errorCodeData,errorData),[errorCodeData]: t(errorCodeData)});
  
          } 
          // else if dataKey is exist set general error
          else if (errorDataKey.length > 0) {
            setErrors({general: t(errorCodeData,errorData),[errorCodeData]: t(errorCodeData)});
          }
          else {
              setErrors({
                general: `${t('general_error','Virhe. Ota tarvittaessa yhteys ylläpitoon.')} ${JSON.stringify(error)}`,[errorCodeData]: t(errorCodeData),
            })
          }
        })

      
      
    },
    displayName: "BasicForm"
  
})(ChangePassword)
    
    
const ChangePasswordView = ({history, objectId, modalOpen, ...rest}) => {
  // API requests here
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [preSetValues, setPreSetValues] = useState();
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  const account = useStoreState((state) => state.account?.account);
  
  const getObject = (id) => {
    

    
  }
  
  
  useEffect(() => {
    if (objectId && parseInt(objectId) > -1) {
      getObject(objectId)
    }
  }, [objectId, modalOpen])
  
  
  if (loading) {
    return (
      <Container fluid>
        <Spinner />
      </Container>
    )
  }

  return (
    <ChangePasswordFormik 
      t={t} 
      history={history} 
      preSetValues={preSetValues} 
      notify={notify} 
      objectId={objectId}
      account={account}
      {...rest} 
    />
  )
  
}

export default ChangePasswordView;
