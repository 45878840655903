import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import { SelectMulti } from "stories/forms";
import { useStoreState, useStoreActions } from 'easy-peasy';

/*
const stocks = [
  {id: 1, name: "YksYritys A osake"},
  {id: 2, name: "YksYritys B osake"},
  {id: 3, name: "YksYritys C osake"},
]
*/

const StockPrivateSelect = ({ label, name="private_stock",ratesView, setValueToSelect,isMulti=false, setFieldValueReturnId, setFieldValue, value, handleResolve, ...rest}) => {
  const { t } = useTranslation();
  const stocks = useStoreState((state) => state.privateStocks?.objects);
  const loading = useStoreState((state) => state.stocks?.loading);



  const setLocalFieldValue = (name, obj) => {
    // setFieldValue(name, obj?.id)
    // handleResolve({ id: obj?.id, name: obj?.label ? obj?.label : obj?.name })

    if (setFieldValueReturnId) {
      setFieldValue(name, obj?.id)
    }
    else {
      setFieldValue(name, obj)
      setFieldValue("stockCurrency", obj?.stockCurrency)
    }
    
    if (handleResolve !== undefined) {
      handleResolve({ id: obj?.id, name: obj?.label ? obj?.label : obj?.name })
    }

  }

  const getOptions = useCallback(() => {
    return stocks && stocks.map(stock => {
      return {value: stock?.id, label: stock?.name, stockCurrency: stock?.currency_label}
    })
  }, [stocks])
  
  const getOptionLabel = useCallback(() => {
    return stocks && stocks.reduce((result, stock) => {
      if (stock?.id === value) {
        result = stock?.name
      }
      return result
    }, null)
  }, [stocks])
  
  // useEffect(() => {
  //   if (stocks && stocks.length > 0 && (value === undefined || value === "")) {
  //     setLocalFieldValue(name, stocks[0])
  //   }
  // }, [value, stocks])

  useEffect(() => {
    if (ratesView === true) {
    if (stocks && stocks.length > 0 && (value === undefined || value.value === undefined || value.value === "" || value === "")) {
      if (loading === false) {
      setLocalFieldValue(name, stocks[0])
      }

    }
    }
  }, [value, stocks, loading])

  const getSelectedOptions = useCallback(() => {
    if (isMulti === false) {
      return [value];

    }
  
    return value && value.map(stock => {
      return {id:stock?.id, value: stock.id, label: stock.name}
    })
  }, [value])
  

  return (
    <SelectMulti
      isMulti={false} 
      label={label ? label : t("stock_select_label", "Osake (private)")} 
      name={name} 
      value={value}
      setFieldValue={setLocalFieldValue} 
      // defaultValues={[{id: value, value: value, label: getOptionLabel()}]}
      defaultValues={getSelectedOptions()}
      options={getOptions()} 
      ratesView={ratesView}
      setValueToSelect={setValueToSelect}
      {...rest}
    />
  )

}

export default StockPrivateSelect;
