import React, { useCallback, useEffect, useState} from "react";
import moment from "moment";
import { useTranslation } from 'react-i18next';
import queryString from 'query-string'

import { Typography, Link, Button  } from "stories/components";
import { Card, CardBody, CardHeader, Col, Container, Row } from "stories/layout"

import logo from "assets/images/logoiv.png";

import backgroundImage from "assets/images/auth_background.jpg";


const PaymentSuccess = ({history, match, location}) => {
  
  const { t } = useTranslation()
  /*
  const [values, setValues] = useState({})
  
  useEffect(() => {
    
    if (values?.object_type === "Subscription" && values?.object_id) {
      
    }
  }, [values])
  
  useEffect(() => {
    const keys = queryString.parse(location?.search)
    setValues(keys)
  }, [location]);
  */
  
  // values?.object_type === "Subscription"
  
  return (
    <>
      <div 
        className="" 
        style={{
          background: "url(" + backgroundImage + ")",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          height: "100vh",
          width: "100vw",
          position: "fixed",
          zIndex: -1


        }}>   
      </div>

      
        
      <div 
        className="h-100"     
        style={{
          display: "flex",
          justifyContent: "center", 
          alignItems: "center",     
          minHeight: "100vh",      
        }}
      >
     <div className="justify-self-center mx-auto mb-4" style={{ position: "absolute", top: 0, left: 0 }} ><img src={logo} style={{width: "400px"}} alt="logo_svg"/></div>
        

        <Container className="h-100">
          <Row className="justify-content-center align-items-center  h-100">
            <Col lg="6" md="8" className="">
              <Card variant="secondary" className="mt-5">


                <CardBody className="px-lg-5 py-lg-5">
                  
                  <div className="d-flex justify-content-center align-items-center flex-column my-0">
                    <div className="mb-4">
                      <i className="pr-2 fas fa-check-circle fa-4x text-success"></i>
                    </div>
                    <div className="mb-3">
                      <Typography variant="h2" color="dark">{t("payment_success_title","Maksu maksettu onnistuneesti")}!</Typography>
                    </div>
                    <div className="mb-5">
                      <Typography variant="small" color="dark">{t("payment_success_email_sended", "Kuitti lähetetty sähköpostiin")}.</Typography>
                    </div>
                    <div>
                      <Link to={`/dashboard/frontpage`}>
                        <Button variant="secondary">
                          {t("payment_success_move_to_servise","Siirry tästä takaisin palveluun")}

                        </Button>
                        </Link>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>


      </div>
    </>

  );
}

export default PaymentSuccess;
