import http from './api'

export const httpGetInvestmentFunds = (params) => {
  console.log("httpGetInvestmentFunds");
  return http({
    url: `/investment_funds/` ,
    method: 'GET',
    params,
  })
}

// export const httpGetExtendBasicData = (params) => {
//   console.log("httpGetExtendBasicData");
//   return http({
//     url: `/extend_basic_data/` ,
//     method: 'GET',
//     params,
//   })
// }

export const httpGetInvestmentFundData = (params) => {
  console.log("httpGetInvestmentFundData");
  return http({
    url: `/investment_fund_datas/` ,
    method: 'GET',
    params,
  })
}

// export const httpUpdateStockData = (id, data) => {
//   console.log("httpUpdateStockData");
//   return http({
//     url: `/stock_data/${id}/` ,
//     method: 'PATCH',
//     data
//   })
// }