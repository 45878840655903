import React, { useState, useCallback, useEffect } from "react";
import { withFormik } from 'formik';
import * as Yup from "yup";
import moment from "moment"
import { useTranslation } from 'react-i18next';
import { useStoreState, useStoreActions } from 'easy-peasy';


import { Button, Typography, Link, Spinner, Modal} from "stories/components";
import { Container, Row, Col, FormContainer, Card, CardBody } from "stories/layout";
import { Input, SelectMulti, Toggle, Radio, Checkbox } from "stories/forms";
import { useNotification } from "stories/components/Notification"

import { httpGetPortfolio, httpCreatePortfolio, httpUpdatePortfolio, httpDeletePortfolio } from "services/portfolios"

import CurrencySelect from "components/Forms/CurrencySelect/CurrencySelect"
import SellProfitCalculationMethodSelect from "components/Forms/SellProfitCalculationMethodSelect/SellProfitCalculationMethodSelect"
import CompanySelect from "components/Forms/CompanySelect/CompanySelect"
import CompanyEdit from "views/dashboard/CompanyEdit/CompanyEdit"

const defaultValues = {
  id: null,
  name: "",
}

const PortfolioEdit = (props) => {
  

  const {
     values,
     touched,
     setTouched,
     errors,
     setErrors,
     handleChange,
     handleBlur,
     handleSubmit,
     setFieldValue,
     isValid,
     isTouched,
     isSubmitting,
     validateForm,
     preSetValues
   } = props;
   
   const {
      t,
      history,
      objectId,
      companies,
      account,
      showExtraField,
      setShowExtraField
   } = props;
   
   const getFormikInputProps = useCallback(
    name => ({
      name,
      value: values[name],
      error: Boolean(touched[name] && errors[name]),
      errorText: touched[name] ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange
    }),
    [values, errors, touched, handleBlur, handleChange]
  );

  const getCompanies = useStoreActions((actions) => actions.companies?.getCompanies)
  
  const onSubmit = (e) => {
    e.preventDefault();
    validateForm().then(res => {
      if (Object.keys(res).length === 0) {
        handleSubmit()
      }
      else {
        setTouched(res);
        setErrors({
          ...res,
          general: t("Please check the form errors", "Tarkista lomakkeen virheet")
        })
      }
    })
  }
  
  const handleCancel = () => {
    props.handleReject()
  }
  
  const handleDelete = () => {
    httpDeletePortfolio(objectId).then(() => {
      props.handleResolve()
    })
  }


    // Modal: New/Edit company
  
    const [modalEditOpen, setModalEditOpen] = useState(false)
    const [modalEditId, setModalEditId] = useState()
    
    const toggleModalEdit = () => {
      setModalEditOpen(s => !s);
    }
    
    const openModalEdit = (id) => {
      setModalEditOpen(true)
      setModalEditId(id);
    }
    
    const modalEditResolve = () => {
      setModalEditOpen(false);
      setModalEditId(null);
      getCompanies()
    }
    
    const modalEditReject = () => {
      setModalEditOpen(false);
      setModalEditId(null);
    }

  const handleExtraFieldChange = (event) => {
    setShowExtraField(event.target.checked)
  }

  
  return (
    <>
      {/* {JSON.stringify(values)} */}
      <Row>
        <Col className="mb-3">
          <Input
            {...getFormikInputProps("name")}
            label={t("portfolio_edit_name", "Salkun Nimi")}
          />
        </Col>
      </Row>

      <Row>
        <Col sm={12} lg={4} className="d-flex align-items-center">
          <label className="mb-1 pr-1 header">{t("portfolio_edit_currency_label", "Valuutta")}</label>
        </Col>
        <Col   className="mb-3">
          <CurrencySelect 
            {...getFormikInputProps("currency_label")} 
            label=" "
            disabled={objectId ? true : false}
          />
        </Col>
      </Row>

      <Row>
        <Col sm={12} lg={4} className="d-flex align-items-center">
          <label className="mb-1 pr-1 header">{t("portfolio_edit_calculation_method", "Luovutusvoiton laskenta")}</label>
        </Col>
        <Col   className="mb-3">
          <SellProfitCalculationMethodSelect 
            {...getFormikInputProps("sell_profit_calculation_method")} 
            label=" "
            disabled={objectId?true:false}
            // disabled={objectId ? true : false}
          />
        </Col>
      </Row>


      {account.type==="business" && (
        <>
      <Row>
        <Col sm={12} lg={4} className="d-flex align-items-center">
          <label className="mb-1 pr-1 header">{t("portfolio_edit_company", "Yritys")}</label>
        </Col>
        <Col className="mb-2">
          <CompanySelect 
            {...getFormikInputProps("company")} 
            label= " "
           
          />

        </Col>
      </Row>
      
        <Row className="mb-4">
          <Col sm={12} lg={4} className="d-flex align-items-center">
          </Col>
          <Col>
            <Link onClick={() => openModalEdit()}>
            <Typography variant="small">  {t("portfolio_edit_crate_company","Luo yritys tästä")}</Typography>
            </Link>
          </Col>
        </Row>
        </>
      )}
      {account.show_external_system_settings && (
        <>
              <div>
        <div className=" mb-3">
            <Checkbox 
              id={"showExtraField"}
              name="showExtraField"
              checked={showExtraField}
              onChange={(event) => handleExtraFieldChange(event)}
              value="showExtraField"
              className="zIndexZero"
              required
              label={<>{t("portfolio_edit_extra_info", 'Lisätietoja')}</>}
            />
          </div>
      </div>
        <>
        { (showExtraField ) && 
          <>
          <Row className="mb-0">
              <Col sm={12} lg={4} className="d-flex align-items-center">
              <label className="mb-1 pr-1 header">{t("portfolio_edit_external_system_name_1", "Ulk. järj. nimi")}</label>
              </Col>
              <Col>
              <Input
                  name="increase_value"
                  {...getFormikInputProps("external_first_system_name")} 
                  label=""
              />
              </Col>
          </Row>


          <Row className="mb-1 mt-2">
              <Col sm={12} lg={4} className="d-flex align-items-center">
              <label className="mb-1 pr-1 header">{t("portfolio_edit_external_system_id_1", "Ulk. järj. tunnus")}</label>
              </Col>
              <Col >
              <Input
                  {...getFormikInputProps("external_first_system_portfolio_id")} 
                  label=""
                  type="number"
                  step="0.01"
                  min={0}
                  onWheel={(e) => e.target.blur()}
                  placeholder={t("0")}
                  // addonTypeClass="append"
                  // inputText={values?.external_first_system_name ? values?.external_first_system_name : ""}
                  // iconColor={"bg-lighter"}
                
              />
         
              </Col>
          </Row>
          <Row className="mt-3">
              <Col sm={12} lg={4} className="d-flex align-items-center">
              <label className="mb-1 pr-1 header">{t("portfolio_edit_external_system_1_export", "Vie tiedot")}</label>
              </Col>
              <Col>
                <Checkbox 
                  id={"export_external_first_system"}
                  {...getFormikInputProps("export_external_first_system")}
                  value= {values?.export_external_first_system}
                  checked={values?.export_external_first_system}
                  className="zIndexZero"
                  required
                  // label={<>{t("portfolio_edit_extra_info", '')}</>}
                />
                </Col>
          </Row>
          <Row className="row justify-content-end">
            <Col lg={8} md={12} className="mb-2">
              {/* <Typography variant="small">{t("portfolio_edit_external_system_name_1_info","Ulkoisen järjestelmän nimi ja id")}</Typography> */}
            </Col>
          </Row>
          </>
        }
        </>
        </>
        )
      }

      
      <Row>
        <Col className="mb-3">
          <Button onClick={handleCancel} variant="default">{t("Close","Sulje")}</Button>
        </Col>
        { values?.id ? (
        <Col className="mb-3 text-center">
         {/*  <Button onClick={handleDelete} disabled={true} variant="danger">{t("Delete","Poista")}</Button>*/}
        </Col>
        ) : null }
        <Col className="mb-3 text-right">

            <Button variant="secondary" disabled={isSubmitting} onClick={onSubmit}>
              {t("Save","Tallenna")}
            </Button>
          
          { Boolean(errors["general"]) && <Typography className="text-danger">{errors["general"]}</Typography> }
        </Col>
      </Row>

      <Modal
      title={t("companies_list_company_title","Yritys")}
      isOpen={modalEditOpen}
      toggleModal={toggleModalEdit}
      
    >
      <CompanyEdit
        objectId={modalEditId}
        handleResolve={modalEditResolve}
        handleReject={modalEditReject}
        extraMargin={true}
      />
    </Modal>
        
    </>
  );
}

const PortfolioEditFormik = withFormik({
    enableReinitialize: true,
    validateOnMount: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return {
          ...defaultValues
        }
      }
    },
    validationSchema: props => {
      const {t, account} = props;
      const required = t("The field is required");
      return Yup.object().shape({
        name: Yup.string().required(required).nullable(),
        // company: Yup.number().required(required).min(1, t("Valitse jokin yritys tai luo uusi")).nullable(),
        company: Yup.string().when('account_type', {
          is: (val) => val !== "private",
          then:  Yup.string().required(required).min(1, t("portfolio_edit_choose_company","Valitse jokin yritys tai luo uusi")).nullable(),
        }),


      });
    },
    handleSubmit: (values, { setSubmitting, setErrors, props }) => {
      const {t, portfolio, account} = props;
      console.log("account", account);
      let data = {
        ...values,
        account: account?.id,
      };
      
      if (values?.id) {
        httpUpdatePortfolio(values.id, data).then(res => {
          setSubmitting(false);
          props.notify({ title:t("Portfolio"), message:t("Updated")})
          props.handleResolve()
          
        }, error => {
          setSubmitting(false);
          if (error?.data?.detail) {
            setErrors({
              general: error?.data?.detail,
            })
          }
          else {
            setErrors({
              general: t("Unknown error. Please try again later or contact support.")
            })
          }
        })
      }
      else {
        httpCreatePortfolio(data).then(res => {
          setSubmitting(false);
          props.notify({ title:t("Portfolio"), message:t("Created")})
          props.handleResolve()
          
        }, error => {
          setSubmitting(false);
          if (error?.data?.detail) {
            setErrors({
              general: error?.data?.detail,
            })
          }
          else if (JSON.stringify(error?.data).includes("PORTFOLIO_LICENSE_LIMIT")) {
            setErrors({
              general: t("PORTFOLIO_LICENSE_LIMIT")
            })
          }
          else {
            setErrors({
              general: t("Unknown error. Please try again later or contact support.")
            })
          }
        })
      }
      
      
    },
    displayName: "BasicForm"
  
})(PortfolioEdit)
    
    
const PortfolioEditView = ({history, objectId, modalOpen, ...rest}) => {
  // API requests here
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [preSetValues, setPreSetValues] = useState();
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  const account = useStoreState((state) => state.account?.account);
  const companies = useStoreState((state) => state.companies?.companies)
  const [showExtraField, setShowExtraField] = useState(false);
  
  const getObject = (id) => {
    
    setLoading(true)
    
    httpGetPortfolio(id).then(res => {
      
      setPreSetValues({
        ...defaultValues,
        ...res?.data,
      });
    
    }).finally(response => {
      setLoading(false);
    })
    
  }
  
  
  useEffect(() => {
    if (objectId && parseInt(objectId) > -1) {
      getObject(objectId)
    }
    else {
      setPreSetValues({
        ...defaultValues,
        account_type: account?.type
      });
    }
  }, [objectId, modalOpen])
  
  
  if (loading) {
    return (
      <Container fluid>
        <Spinner />
      </Container>
    )
  }

  return (
    <PortfolioEditFormik 
      t={t} 
      history={history} 
      preSetValues={preSetValues} 
      notify={notify} 
      objectId={objectId}
      account={account}
      companies={companies}
      showExtraField={showExtraField}
      setShowExtraField={setShowExtraField}
      {...rest} 
    />
  )
  
}

export default PortfolioEditView;
