import {React, useState} from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import history from "services/history"

import DashboardLayout from "layouts/Dashboard.js";
import AuthLayout from "layouts/Auth.js";
import PaymentSuccess from "views/vismapay/PaymentSuccess/PaymentSuccess"
import PaymentCancelled from "views/vismapay/PaymentCancelled/PaymentCancelled"
import { useAuthContext } from "contexts/AuthContext";

import { Container, Row, Col } from "stories/layout";
import { Spinner } from "stories/components"

const App = () => {
  const { isAuthenticated, loadingMyUser } = useAuthContext();
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  if (loadingMyUser ) {
    return (
      <Container className="d-flex align-items-center justify-content-center" style={{ height: "100vh" }}>
        <Spinner animation="border" role="status" className="text-secondary"/>
      </Container>
    )
  }
  
  return (
    <>
      { isAuthenticated === true  ? (
        <Switch>
          <Route path="/dashboard" render={(props) => <DashboardLayout {...props} breadcrumbs={breadcrumbs} setBreadcrumbs={setBreadcrumbs} />} />
          <Route path="/payment/success" render={(props) => <PaymentSuccess {...props} />} />
          <Route path="/payment/cancelled" render={(props) => <PaymentCancelled {...props} />} />
          <Redirect from="*" to="/dashboard/frontpage" />
        </Switch>
      ) : (
        <Switch>
          <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
          <Redirect from="*" to="/auth/login" />
        </Switch>
      )}
      
    </>
  )
}

export default App;