import React, { useState, useEffect } from 'react';
import { Row, Col, Card,CardBody } from "stories/layout";
import { Button, Link } from "stories/components";

import './StepProgressBar.css'; // Create a CSS file for styling

export const StepProgressBar = ({ steps=3,givenActiveStep, setGivenActiveStep, ColorOnlyActiveStep=false, takeNextBtnState,backToOtherComp=false, backToOtherCompLink="#", showLine=true, takeHandleNext, showNextButton=true, nextBtnText='Next' }) => {
    const [activeStep, setActiveStep] = useState(1);
  
    const handleNext = () => {
      if (activeStep < steps) {
        setActiveStep((prevStep) => prevStep + 1);
      }
      if (setGivenActiveStep) {
        setGivenActiveStep(activeStep + 1);
      }
    };

    const handleBack = () => {
        if(activeStep > 1){
          setActiveStep((prevStep) => prevStep - 1);
        }
        if (setGivenActiveStep && activeStep > 1) {
          setGivenActiveStep(activeStep - 1);
        }
      };

    //Set in parent component state ans give that setState function to this component as prop name takeNextBtnState
    useEffect(() => {
        if (takeNextBtnState) {
            
            takeNextBtnState(
                <div>
                { activeStep < steps && (

                <button className="next-button" onClick={handleNext}>
                {nextBtnText}
                </button>
                )}

                </div>
            )
        }
    }, []);

    useEffect(() => {
        if (givenActiveStep) {
            setActiveStep(givenActiveStep);
        }
    }, [givenActiveStep]);
  
    return (
        <>
        <Row className="step-progress-bar" style={{minWidth:"200px"}}>
            <Col>
                <div className=" align-items-center">
                  <div className="d-flex justify-content-around "> 
                    <div className="container-custom">
                     {/* {backToOtherComp && <Link to={backToOtherCompLink} className={` step `}><i className="fas fa-chevron-left text-white"></i></Link>} */}
                     {backToOtherComp && <div onClick={handleBack} className={` step `}><i className="fas fa-chevron-left text-white"></i></div>}
                        {Array.from({ length: steps }).map((_, index) => (
                            <>
                                {
                                    ColorOnlyActiveStep ? (
                                        <div>
                                        <div  className={` step ${ index +1== activeStep ? 'completed' : ''} ${ 1 === activeStep ? '' : ''} `}>{index + 1}</div>
                                        {/* {showLine && index < steps - 1 && <div className={` line ${ index + 1 < activeStep ? 'completed' : ''} ${ 1 === activeStep ? '' : ''} `}></div>
                                        } */}
                                        </div>
                                    ):(
                                        <div>
                                        <div  className={` step ${ index < activeStep ? 'completed' : ''} ${ 1 === activeStep ? '' : ''} `}>{index + 1}</div>
                                        {showLine && index < steps - 1 && <div className={`  ${ index + 1 < activeStep ? 'completed' : ''} ${ 1 === activeStep ? '' : ''} `}></div>
                                        }
                                        </div>
                                    )
                                }

                            </>
                        ))}
                    </div>
                  </div>
                </div>
            </Col>
        </Row>
        {showNextButton && activeStep < steps && (
        <Row className="my-3">
            <Col>
  
                    <button className="next-button" onClick={handleNext}>
                        {nextBtnText}
                    </button>
            </Col>
        </Row>
                )}
  
      </>
    );
  };
  

