import React, { useState, useCallback, useEffect } from "react";
import { withFormik } from 'formik';
import * as Yup from "yup";
import moment from "moment"
import { useTranslation } from 'react-i18next';
import { useStoreState, useStoreActions } from 'easy-peasy';

import { Button, Typography, Spinner } from "stories/components";
import { Container, Row, Col, FormContainer, Card, CardBody } from "stories/layout";
import { Input, SelectMulti, Toggle, Radio } from "stories/forms";
import { useNotification } from "stories/components/Notification"

import { httpGetPortfolio, httpCreatePortfolio, httpUpdatePortfolio, httpDeletePortfolio } from "services/portfolios"
import { httpGetAccountUser, httpUpdateAccountUser, httpDestroyAccountUser } from "services/account_users"

import CurrencySelect from "components/Forms/CurrencySelect/CurrencySelect"

import { useAppContext } from "contexts/AppContext"


import UserTypeSelect from "components/Forms/UserTypeSelect/UserTypeSelect"
import PortfolioSelect from "components/Forms/PortfolioSelect/PortfolioSelect"

const defaultValues = {
  id: null,
  username: "",
  portfolios: [],
}

const UserEdit = (props) => {
  
  const {
     values,
     touched,
     setTouched,
     errors,
     setErrors,
     handleChange,
     handleBlur,
     handleSubmit,
     setFieldValue,
     isValid,
     isTouched,
     isSubmitting,
     validateForm,
     preSetValues,
     invitated_again
   } = props;
   
   const {
      t,
      history,
      objectId
   } = props;



   console.log(values)
   const getFormikInputProps = useCallback(
    name => ({
      name,
      value: values[name],
      error: Boolean(touched[name] && errors[name]),
      errorText: touched[name] ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange
    }),
    [values, errors, touched, handleBlur, handleChange]
  );
  
  const onSubmit = (e) => {
    e.preventDefault();
    validateForm().then(res => {
      if (Object.keys(res).length === 0) {
        handleSubmit()
      }
      else {
        setTouched(res);
        setErrors({
          ...res,
          general: t("Please check the form errors", "Tarkista lomakkeen virheet")
        })
      }
    })
  }
  
  const handleCancel = () => {
    props.handleReject()
  }
  
  const handleDelete = () => {
    httpDestroyAccountUser(objectId).then(() => {
    props.handleResolve()
    })
  }
  
  return (
    <>
      <Row>
        <Col className="mb-3">
          <Input
            {...getFormikInputProps("username")}
            label={t("username_input", "Käyttäjätunnus")}
            type="text"
            icon="fas fa-user"
            required
            disabled={true}
          />
        </Col>
      </Row>
      
      <Row className="mb-3">
        <Col className="mb-3">
          <UserTypeSelect 
            {...getFormikInputProps("user_type")} 
            label={t("user_type", "Rooli")}
            required
            setFieldValue={setFieldValue}
            validateForm={validateForm}
          />
          <Typography className="">{t("userinvitation_info_text_1","Peruskäyttäjä voi käyttää palvelua vain hänelle liitetetyn salkun alla. Admin-käyttäjä näkee kaikki tiedot palvelussa. Peruskäyttäjä ei voi lisätä myöskään uusia käyttäjiä tai muuttaa heidän rooleja.")}</Typography>
          </Col>
      </Row>
      { values?.user_type !== "admin" ? (
        <Row className="mb-5">
        <Col className="mb-3">
        <PortfolioSelect 
        {...getFormikInputProps("account_user_portfolios")} 
        label={t("portfolios", "Salkku")}
        required
        setFieldValue={setFieldValue}
        multiple={true}
        placeholder={t("Select portfolio", "Valitse salkku")}
        validateForm={validateForm}
        />
        </Col>
        </Row> ) :(
          <Row className="mb-5">
          <Col className="mb-3">
          <Typography  variant="h3" className="text-dark">{t("Kaikki salkut valittu")} *</Typography>
          </Col>
          </Row> )
      }
      
      
      
      <Row>
        <Col className="mb-3">
          <Button onClick={handleCancel} variant="default">{t("Close","Sulje")}</Button>
        </Col>
        { values?.id ? (
        <Col className="mb-3 text-center">
          <Button onClick={handleDelete} variant="danger">{t("Delete","Poista")}</Button>
        </Col>
        ) : null }
        <Col className="mb-3 text-right">

            <Button variant="secondary" disabled={isSubmitting} onClick={onSubmit}>
              {invitated_again === "invitated_again" ? t("invitate_again","Kutsu"):t("Save","Tallenna")}
            </Button>
          
          { Boolean(errors["general"]) && <Typography className="text-danger">{errors["general"]}</Typography> }
        </Col>
      </Row>
        
    </>
  );
}

const UserEditFormik = withFormik({
    enableReinitialize: true,
    validateOnMount: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return {
          ...defaultValues
        }
      }
    },
    validationSchema: props => {
      const {t} = props;
      const required = t("The field is required");
      return Yup.object().shape({
        username: Yup.string().email(t('email_check','Tarkista sähköpostiosoitteen muoto')).required(required),
        user_type: Yup.string().required(required),
        account_user_portfolios: Yup.array().min(1, t('Select at least one portfolio')).required(required),
      });
    },
    handleSubmit: (values, { setSubmitting, setErrors, props }) => {
      const {t, portfolio, account} = props;
      console.log("account", account);
      let account_user_portfolios = [];
      if (values.account_user_portfolios) {
        account_user_portfolios = values.account_user_portfolios.map(item => {
            return {
                portfolio: item? item?.id: item
            }
      })  
      }
      let data = {
        ...values,
        account: account?.id,
        account_user_portfolios: account_user_portfolios,
      };
      
      if (values?.id) {
        httpUpdateAccountUser(values.id, data).then(res => {
          setSubmitting(false);
          
          props.notify({ title:t("Portfolio"), message:t("Updated")})
          props.handleResolve()
          
        }, error => {
          setSubmitting(false);
          if (error?.data?.detail) {
            setErrors({
              general: error?.data?.detail,
            })
          }
          else {
            setErrors({
              general: t("Unknown error. Please try again later or contact support.")
            })
          }
        })
      }
    //   else {
    //     httpCreatePortfolio(data).then(res => {
    //       setSubmitting(false);
    //       props.notify({ title:t("Portfolio"), message:t("Created")})
    //       props.handleResolve()
          
    //     }, error => {
    //       setSubmitting(false);
    //       if (error?.data?.detail) {
    //         setErrors({
    //           general: error?.data?.detail,
    //         })
    //       }
    //       else {
    //         setErrors({
    //           general: t("Unknown error. Please try again later or contact support.")
    //         })
    //       }
    //     })
    //   }
      
      
    },
    displayName: "BasicForm"
  
})(UserEdit)
    
    
const UserEditView = ({history, objectId, modalOpen, ...rest}) => {
  // API requests here
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [preSetValues, setPreSetValues] = useState();
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  const account = useStoreState((state) => state.account?.account);
  const { getPortfolioName, getUserTypeName } = useAppContext()

  
  const getObject = (id) => {
    
    setLoading(true)
    
    httpGetAccountUser(id).then(res => {
      setPreSetValues({
        ...defaultValues,
        id: res?.data?.id,
        username: res?.data?.user?.username,
        //return account_user_portfolios as array of keys and values
        account_user_portfolios: res?.data?.account_user_portfolios?.map((item) => {
            return {
                id: item?.portfolio,
                value: getPortfolioName(item?.portfolio),
            }
        }),
        user_type: res?.data?.user_type,
        // ...res?.data,
      });
    
    }).finally(response => {
      setLoading(false);
    })
    
  }
  
  
  useEffect(() => {
    if (objectId && parseInt(objectId) > -1) {
      getObject(objectId)
    }
  }, [objectId, modalOpen])
  
  
  if (loading) {
    return (
      <Container fluid>
        <Spinner />
      </Container>
    )
  }

  return (
    <UserEditFormik 
      t={t} 
      history={history} 
      preSetValues={preSetValues} 
      notify={notify} 
      objectId={objectId}
      account={account}

      {...rest} 
    />
  )
  
}

export default UserEditView;
