export const cleanEmail = (email) => {
  return email.toLowerCase().replace(" ", "");
}

export const listToString = (list) => {
  if (list == null || list.length == 0) {
    return "";
  }
  const size = list.length;
  return list && list.map((elem, i) => {
    let txt = elem.name;
    if (i < size-1) {
      txt += ","
    }
    return txt;
  })
}

/*
export const round = (value, precision) => {
  let multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}
*/

export const round = (num, scale) => {
  if(!("" + num).includes("e")) {
    
    return +(Math.round(num + "e+" + scale)  + "e-" + scale);
  } else {
    var arr = ("" + num).split("e");
    var sig = ""
    if(+arr[1] + scale > 0) {
      sig = "+";
    }
    return +(Math.round(+arr[0] + "e" + sig + (+arr[1] + scale)) + "e-" + scale);
  }
}

export const roundScaleDecimals = (num, scale) => {
  if(!("" + num).includes("e")) {
    
    let number = +(Math.round(num + "e+" + scale)  + "e-" + scale) 
    if (number.toString().indexOf([",", "."]) === -1) {
      // add scale number of zeros after comma
      number = number.toFixed(scale)
      return number
    }
    return +(Math.round(num + "e+" + scale)  + "e-" + scale);
  } else {
    var arr = ("" + num).split("e");
    var sig = ""
    if(+arr[1] + scale > 0) {
      sig = "+";
    }
    let number = +(Math.round(+arr[0] + "e" + sig + (+arr[1] + scale)) + "e-" + scale)
    if (number.toString().indexOf([",", "."]) === -1) {
      // add scale number of zeros after comma
      number = number.toFixed(scale)
      return number
    }

    return +(Math.round(+arr[0] + "e" + sig + (+arr[1] + scale)) + "e-" + scale);
  }
}

// export const round = (num, scale) => {
//   const fixed = num.toFixed(scale);
//   const diff = scale - fixed.replace(/^-?\d*\.?|0+$/g, '').length;
//   return +(fixed + '0'.repeat(diff));
// }


export const thousandFormat = (num) => {
    const n = String(num),
          p = n.indexOf('.')
    return n.replace(
        /\d(?=(?:\d{3})+(?:\.|$))/g,
        (m, i) => p < 0 || i < p ? `${m} ` : m
    )
}
