import React, { useState, useCallback, useEffect } from "react";
import { withFormik } from 'formik';
import * as Yup from "yup";
import moment from "moment"
import { useTranslation } from 'react-i18next';
import { useStoreState, useStoreActions } from 'easy-peasy';

import { Button, Typography, Spinner, Modal } from "stories/components";
import { Container, Row, Col, FormContainer, Card, CardBody } from "stories/layout";

import ChangePassword from "views/dashboard/ChangePassword/ChangePassword"
import ChangeUsername from "views/dashboard/ChangeUsername/ChangeUsername"


import { Input, SelectMulti, Toggle, Radio } from "stories/forms";
import { useNotification } from "stories/components/Notification"
import { PageWrapper, PageHeader } from "components/Page"


import { httpGetPortfolio, httpCreatePortfolio, httpUpdatePortfolio, httpDeletePortfolio } from "services/portfolios"
import { httpGetAccountUser, httpUpdateAccountUser, httpDestroyAccountUser } from "services/account_users"
import { httpGetMyUser, httpUpdateUser } from "services/users"

import CurrencySelect from "components/Forms/CurrencySelect/CurrencySelect"

import { useAppContext } from "contexts/AppContext"
import { useAuthContext } from "contexts/AuthContext"


import UserTypeSelect from "components/Forms/UserTypeSelect/UserTypeSelect"
import LanguageSelect from "components/Forms/LanguageSelect/LanguageSelect"
import PortfolioSelect from "components/Forms/PortfolioSelect/PortfolioSelect"


require('yup-phone');

const defaultValues = {
  id: null,
  username: "",
  first_name: "",
  last_name: "",
  phone: "",
}

const AccountSettings = (props) => {
  
  const {
     values,
     touched,
     setTouched,
     errors,
     setErrors,
     handleChange,
     handleBlur,
     handleSubmit,
     setFieldValue,
     isValid,
     isTouched,
     isSubmitting,
     validateForm,
     preSetValues,
     invitated_again,
     openModalEdit
   } = props;
   
   const {
      t,
      history,
      objectId
   } = props;



   const getFormikInputProps = useCallback(
    name => ({
      name,
      value: values[name],
      error: Boolean(touched[name] && errors[name]),
      errorText: touched[name] ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange
    }),
    [values, errors, touched, handleBlur, handleChange]
  );
  
  const onSubmit = (e) => {
    e.preventDefault();
    validateForm().then(res => {
      if (Object.keys(res).length === 0) {
        handleSubmit()
      }
      else {
        setTouched(res);
        setErrors({
          ...res,
          general: t("Please check the form errors", "Tarkista lomakkeen virheet")
        })
      }
    })
  }
  
  const handleCancel = () => {
    props.handleReject()
  }
  
  const handleDelete = () => {
    httpDestroyAccountUser(objectId).then(() => {
    props.handleResolve()
    })
  }
  
  return (
    <>
      <Row className="mt-4">
      <Col xl-4 lg-8 >
        
        <Row>
          <Col className="mb-3">
            <Input
              {...getFormikInputProps("capital")}
              placeholder={t("Pääomanlisäys")}
              label={t("increase_capital", "Pääomanlisäys")}
              type="select"
              disabled={true}            
              required
            />
          </Col>
        </Row>
        
        <Row>
          <Col className="mb-3">
            <Input
              {...getFormikInputProps("notifications")}
              placeholder={t("email_notifications", "Sähköposti-ilmoitukset")}
              label={t("email_notifications", "Sähköposti-ilmoitukset")}
              type="select"
              icon="fas fa-user"
              disabled={true}
              required
            />
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <LanguageSelect
              {...getFormikInputProps("language")}
              label={t("language", "Kieli")}
              type="select"
              icon="fas fa-phone"
              required
              setFieldValue={setFieldValue}
            />
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <Input
              {...getFormikInputProps("two_auth")}
              label={t("two_auth", "Kaksivaiheinen tunnistautuminen")}
              type="select"
              required
              disabled={true}
            />
          </Col>
        </Row>


        <Row>
          <Col className="mb-3">
            <Button variant="secondary" disabled={isSubmitting} onClick={onSubmit}>
              {t("Save","Tallenna")}
            </Button>
        
           { Boolean(errors["general"]) && <Typography className="text-danger">{errors["general"]}</Typography> }
          </Col>

          <Col className="mb-3 text-right">


          </Col>
        </Row>
      </Col>
      <Col >
      
          
      </Col>
      </Row>
    </>
  );
}

const AccountSettingsFormik = withFormik({
    enableReinitialize: true,
    validateOnMount: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return {
          ...defaultValues
        }
      }
    },
    validationSchema: props => {
      const {t} = props;
      const required = t("The field is required");
      return Yup.object().shape({
        language: Yup.string().required(required),
        

      });
    },
    handleSubmit: (values,{ setSubmitting, setErrors, props }) => {
      const { t, setLanguage } = props;

      let data = {
        language: values.language,
      };
      
      if (values?.id) {
        httpUpdateUser(values.id, data).then(res => {
          setSubmitting(false);
          
          setLanguage(values.language);
          //reload page
          window.location.reload();
          //props.notify({ title:t("Settings", "Asetukset"), message:t("Updated", "Päivitetty")})
        }, error => {
          console.log(error.status)
          setSubmitting(false);
          
          //All keys in error.data
          const errorDataKey = Object.keys(error?.data);
          //default
          let errorCodeKey = errorDataKey?.[0];
          //default errorCode string to translator
          let errorCodeData = error?.data?.[errorCodeKey];
          //if error.data include errorCode then use that instead of default (errorCodeKey, errorCodeData). Also exclude errorCode key from errorDataKey list
          if (errorDataKey.length >= 2 && errorDataKey.includes('errorCode') ) {
            const errorCodeIndex = errorDataKey.indexOf('errorCode');
            errorCodeKey = errorDataKey[errorCodeIndex];
            errorCodeData = error?.data?.[errorCodeKey];
            //Exclude errorCode key from errorDataKey list
            errorDataKey.splice(errorDataKey.indexOf('errorCode'), 1);
            //For special cases, if render depends on errorCode
            setErrors({[errorCodeData]: t(errorCodeData)});
        
          }
          console.log(errorDataKey)
          console.log(errorCodeKey)
          console.log(errorCodeData)
          let errorData = error?.data[errorDataKey?.[0]];
          const valuesKeys = Object.keys(values);
          const usualErrorkeys = ['detail', 'message', 'non_field_errors'];
  
  
          if (Array.isArray(errorData)) {
            errorData = errorData[0];
          }
  
          if (!errorData || errorData === '') {
            setErrors({general: t('general_error','Virhe. Ota tarvittaessa yhteyttä asiakaspalveluun'),[errorCodeData]: t(errorCodeData)})
          }
          else if (errorDataKey.length > 0 && valuesKeys.includes(errorDataKey?.[0])) {
            //set new setErrors with include previous errors
          
            setErrors({[errorDataKey?.[0]]: t(errorCodeData,errorData),[errorCodeData]: t(errorCodeData)});
            
  
          }
          else if (errorDataKey.length > 0 && usualErrorkeys.includes(errorDataKey?.[0])) {
            setErrors({general: t(errorCodeData,errorData),[errorCodeData]: t(errorCodeData)});
  
          } 
          // else if dataKey is exist set general error
          else if (errorDataKey.length > 0) {
            setErrors({general: t(errorCodeData,errorData),[errorCodeData]: t(errorCodeData)});
          }
          else {
              setErrors({
                general: `${t('general_error','Virhe. Ota tarvittaessa yhteys ylläpitoon.')} ${JSON.stringify(error)}`,[errorCodeData]: t(errorCodeData),
            })
          }
        })
      }

      
      
    },
    displayName: "BasicForm"
  
})(AccountSettings)
    
    
const AccountSettingsView = ({history,setCrumbLinks, objectId, modalOpen, ...rest}) => {
  // API requests here
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [preSetValues, setPreSetValues] = useState();
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  const account = useStoreState((state) => state.account?.account);
  const { getPortfolioName, getUserTypeName } = useAppContext()
  const { getMyUser, setLanguage } = useAuthContext()

  const [modalEditOpen, setModalEditOpen] = useState(false)
  const [modalEditId, setModalEditId] = useState()
  const [modalRenderComponent, setModalRenderComponent] = useState()

  useEffect(() => {
    setCrumbLinks([
      {
        name: t("Etusivu"),
        url: "/dashboard",  
        },
      {name: t("Other"), url: "/dashboard/other/default_settings"},
      {name: t("default_settings", "Perusasetukset"), url: "/dashboard/other/default_settings"},
    ])
    }, [])
  
  const toggleModalEdit = () => {
    setModalEditOpen(s => !s);
  }
  
  const openModalEdit = (id, component) => {

    setModalRenderComponent(component)
 
    setModalEditOpen(true)
    setModalEditId(id);
  }
  
  const modalEditResolve = () => {
    setModalEditOpen(false);
    setModalEditId(null);
    // getPortfolios()
    getObject()
    
  }
  
  const modalEditReject = () => {
    setModalEditOpen(false);
    setModalEditId(null);
  }
  
  const getObject = () => {
    
    setLoading(true)
    
    httpGetMyUser().then(res => {
      setPreSetValues({
        ...defaultValues,
        id: res?.data?.id,
        username: res?.data?.username,
        language: res?.data?.language,

      });
    
    }).finally(response => {
      setLoading(false);
    })
    
  }
  
  
  useEffect(() => {
      getObject()
  }, [])
  
  
  if (loading) {
    return (
      <Container fluid>
        <Spinner />
      </Container>
    )
  }

  return (
    <>
      <AccountSettingsFormik 
      t={t}   
      history={history} 
      preSetValues={preSetValues} 
      notify={notify} 
      objectId={objectId}
      account={account}
      openModalEdit={openModalEdit}
      setLanguage={setLanguage}
      {...rest} 
      />

      <Modal
      title={modalRenderComponent=== "change_password" ?t("change_password","Vaihda Salasana"): t("change_username","Vaihda Käyttäjätunnus")}
      isOpen={modalEditOpen}
      toggleModal={toggleModalEdit}
    >
      {modalRenderComponent === "change_username"
      ? <ChangeUsername
        objectId={modalEditId}
        handleResolve={modalEditResolve}
        handleReject={modalEditReject}
        />
      : <ChangePassword
        objectId={modalEditId}
        handleResolve={modalEditResolve}
        handleReject={modalEditReject}
        invitated_again={modalRenderComponent}
        />
    }
    </Modal>

    </>
      )
      
}

export default AccountSettingsView;
