import {React, useState, useEffect, } from 'react';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import {Chart} from 'chart.js'

import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useAppContext } from "contexts/AppContext"


const PortfolioChart = ({width, height, ...props}) => {
  const chartData = props.data
  const { t } = useTranslation();
  
  const { getPortfolioName, getUserTypeName, getTransactionType } = useAppContext()

  const [duration, setDuration] = useState(1000);

  useEffect(() => {
    setDuration(0);
  }, []);


const portfolioIds = Object.keys(chartData);
const summaryTypes = Object.keys(chartData[portfolioIds[0]]);

const labels = portfolioIds.map((portfolioId) => {
  if (portfolioId === 'summary') {
    return t('Summary', 'Yhteenveto');
  }
  return getPortfolioName(Number(portfolioId)) ;
});

const datasets = summaryTypes.map((summaryType, index) => {
  const data = portfolioIds.map((portfolioId) => chartData[portfolioId][summaryType]);


  const backgroundColors = [
    '#C74018',   // blue for summary_purchase_cost
    '#18C718 ',      // black for summary_sales_profit
    '#606BAA',   // red for summary_market_value
    '#161640',      // black for summary_sales_profit
  ];

  return {
        label: t(summaryType,"-"),
        data: data,
        backgroundColor: backgroundColors[index],
        borderRadius: 10, // Set the bar border radius
    };
  });

  const options = {
      // animation: {
      //   duration: duration, // Set the animation duration to 0
      // },
      layout: {
        padding: {
          bottom: 0,
          left: 0,
          right: 0,
          top: 20
        }
      },
      scales: {
        y: {
          beginAtZero: true,
          grid: {
            drawOnChartArea: false, // Hide grid lines except the 0 line
          },
          ticks: {
            suggestedMin: 0, // Start the chart from 0
          },

        },
        x: {
          ticks: {
            display: false, // Hide the x-axis labels
          },
        },
      },

      plugins: {

        title: {
          display: false,
          text: t('Portfolio Summary', 'Salkun yhteenveto'),
        },
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            boxWidth: 20,
            fontColor: 'black',
          },
        },
        datalabels: {
          display: true, // Display the data labels on the bars
          color: 'rgba(0, 1, 1, 0.6)', // Set the color of the data labels
          anchor: 'end', // Position the labels at the end of the bars
          align: 'end', // Align the labels to the end of the bars
          formatter: (value) => value.toFixed(2), // Format the value to display with two decimal places
          labels:{
            value: {
              
              font: {
                weight: 'bold' // Set the font weight to bold
              }, 
            },
          }
        },
      }
      ,
      animation: {
          duration: duration,

      }
      
    };

  const data = {
    labels: labels,
    datasets: datasets,
  };
    
  
    return (
      <div >
        <Bar  data={data} options={options} plugins={[ChartDataLabels]} width={width} height={height} />
      </div>
    );
  };
  
  export default PortfolioChart;