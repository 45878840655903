import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { Table, THead, Pagination } from "stories/tables";
import { Checkbox } from "stories/forms";
import { Spinner } from "stories/components"
import { useTranslation } from 'react-i18next';


import './TableAuto.css';

export const TableAuto = ({ 
    checkboxes = false,
    checked = [],
    setChecked,
    filters,
    headers,
    headerColor = "dark",
    headerTextColor, 
    rows,
    className,
    loading,
    pagination = false,
    paginationPrevious,
    paginationNext,
    paginationOffset,
    paginationLimit,
    paginationCount,
    setFilters,
    showRowNumber = false,
    showId = false,
    tableSmall = true,
    tableStriped = true,
    showPaginationLimitSelect = true,
    showVerticalBorders = true,
    sortBackend = true,
    sorting,
    setSorting,
    showHeader,
    name,
    paginationLimitName="limit",
    customOnClick = null,
    ...rest 
  }) => {
  const [checkedAll, setCheckedAll] = useState(false)
  const { t } = useTranslation();

  
  
    // Ordering
  const getCurrentOrdering = () => {
    if (sortBackend === true) {
      let orderingField = "";
      let directionAsc = true;
      let ordering = filters?.ordering;
      
      if (ordering) {
        if (ordering.includes("-")) {
          directionAsc = false;
        }
        orderingField = ordering.replace("-","")
      }
      
      return {
        field: orderingField,
        directionAsc: directionAsc,
      }
    }
    else {
      return {
        field: sorting?.sortingKey,
        directionAsc: !sorting?.sortingReverse,
      }
    }
  }
  
  const getSortingStatus = (headerObject) => {
    if (headerObject?.sortable === true) {
      const currentOrdering = getCurrentOrdering();
      if (currentOrdering.field === headerObject?.sortingKey) {
        if (currentOrdering.directionAsc) {
          return (
            <i className="fas fa-angle-up"></i>
          )
        }
        else {
          return (
            <i className="fas fa-angle-down"></i>
          )
        }
      }
    }
    return ""
  }
  
  const handleSorting = (header) => {
    let directionAsc = true;
    const currentOrdering = getCurrentOrdering();
    if (currentOrdering?.field === header.sortingKey) {
      directionAsc = !currentOrdering?.directionAsc
    }
    
    if (sortBackend === true) {
      let orderingFilter = header?.sortingKey;
      if (directionAsc === false) {
        orderingFilter = "-"+orderingFilter;
      }
      
      setFilters(s => ({
        ...s,
        ordering: orderingFilter,
      }))
    }
    else {
      setSorting({
        ...header,
        sortingKey: header?.sortingKey,
        sortingReverse: !directionAsc,
      })
    }
  }
  
  // Ordering END
  
  // Checkboxes
  
  const handleChecked = (id) => (e) => {
    
    let _checked = checked;
    const index = _checked.indexOf(id);
    
    if (index > -1) {
      _checked.splice(index, 1);
    }
    else {
      _checked.push(id)
    }
    
    setChecked([
      ..._checked
    ])
    
  }
  
  const handleToggleCheckAll = () => (e) => {
    
    setCheckedAll(s => !s)
    
    if (e?.target?.checked === true) {
      let _checked = []
      rows && rows.map((row, index) => {
        const checkId = row?.checkId ? row?.checkId : null;
        if (checkId) {
          _checked.push(checkId)
        }
        
      })
      
      setChecked([
        ..._checked
      ])
    }
    else {
      setChecked([])
    }
  }
  
  const isChecked = (id) => {
    return checked.reduce((result, item) => {
      if (item === id) { result = true }
      return result
    }, false)
    
  }
  
  // Checkboxes END
  
  const renderHeader = () => {
    return headers && headers.filter(item => {return item?.visible!==false}).map((header, index) => {
      if (header?.sortable) {
        return (
          <th key={index} className={showHeader}><a className={`table_header_link table_header_link_sortable ${showHeader}`} onClick={() => handleSorting(header)}>{header?.label} {getSortingStatus(header)}</a></th>
        )
      }
      else {
        return (
          <th key={index}>{header?.label}</th>
        )
      }
      
    })
  }
  
  const renderRows = () => {
    if (!rows || rows.length === 0) {
      return (
        <tr><td colSpan={2+headers.length}>No results</td></tr>
      )
    }
    
    return rows && rows.map((row, index) => {
      /*
      className={`${tableStriped && index % 2 === 1 && "bg-secondary"}`}
      */
      
      const checkId = row?.checkId ? row?.checkId : index;
      const checked = isChecked(checkId);
      if (row?.subtitle) {
        return (
          <tr key={index} className={row?.className}>
            <td>
            
            </td>
          </tr>
        )
      }
      else {
        return (
          <tr key={index} className={row?.className}>
            {showRowNumber && (
              <td>{index+1+ Number(paginationOffset)}</td>
            )}
            { showId && (
              <td>{row?.id}</td>
            )}
            { checkboxes && (
              <td>
                {row?.isSubtitle === true ? (
                  <>&nbsp;</>
                ) : (
                  <Checkbox 
                    id={`id_checkbox_${index}`}
                    name={`checkbox_${index}`}
                    checked={checked}
                    value={`checkbox_${index}`}
                    onChange={handleChecked(checkId)}
                  />
                )}
              </td>
            )}
            {headers && headers.filter(item => {return item?.visible!==false}).map((header, j) => (
              <td key={j} onClick={() => customOnClick && customOnClick(row?.id, row?.related_model_type, row?.related_model?.ticker_symbol, row?.typeOriginal)} style={customOnClick && {cursor:'pointer'}} className={`${row[header?.key]?.className ? row[header?.key]?.className : ""}`}>{row[header?.key]?.content ? row[header?.key]?.content : row[header?.key]}</td>
            ))}
          </tr>
        )
      }
    })
  }

  return (
    <>
      <Table
        className={`table_auto ${tableSmall ? "table-sm" : ""} ${tableStriped ? "table-striped" : ""} ${className ? className : ""}`}
        showVerticalBorders={showVerticalBorders}
        name={name}
      >
        <THead color={headerColor}>
          <tr>
            { showRowNumber && (
              <th>
              #
              </th>
            )}
            { showId && (
              <th>
              ID
              </th>
            )}
            { checkboxes && (
              <th>
                <Checkbox 
                  id="checkbox_all"
                  name="checkbox_all"
                  value="checkbox_all"
                  checked={checkedAll} 
                  onChange={handleToggleCheckAll()} 
                />
              </th>
            )}
            { renderHeader()}
          </tr>
        </THead>
        <tbody>
        { loading ? (
          <tr><td colSpan={headers && headers.length +1} className="text-center"><Spinner name="loading_spinner" className="mt-2"/></td> </tr>
        ) : 
          renderRows()
        }
        </tbody>
      </Table>
      { pagination && !loading && (
        <Pagination 
          paginationPrevious={paginationPrevious}
          paginationNext={paginationNext}
          paginationOffset={paginationOffset}
          paginationLimit={paginationLimit}
          paginationCount={paginationCount}
          setFilters={setFilters}
          name={paginationLimitName}
        />
      )}
    </>
  )
};

TableAuto.propTypes = {
  headers: PropTypes.array,
  headerColor: PropTypes.oneOf(['light','dark']),
  rows: PropTypes.array,
  checkboxes: PropTypes.bool,
  checked: PropTypes.array,
  pagination: PropTypes.bool,
  showId: PropTypes.bool,
  showPaginationLimitSelect: PropTypes.bool,
  showRowNumber: PropTypes.bool,
  showVerticalBorders: PropTypes.bool,
  tableSmall: PropTypes.bool,
  tableStriped: PropTypes.bool,
};

