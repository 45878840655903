import React, { useEffect, useCallback, useState } from "react";
import { withFormik } from 'formik';
import * as Yup from "yup";
import queryString from 'query-string'
import { useTranslation } from 'react-i18next';

// core components
import { Input } from "stories/forms";
import { Button, Typography } from "stories/components"
import { Card, CardBody, CardHeader, Col, Container, Row } from "stories/layout"
import { useNotification } from "stories/components/Notification"
import { httpChangePassword } from "services/users";

const PasswordReset = (props) => {

  const {
     values,
     touched,
     errors,
     handleChange,
     handleBlur,
     handleSubmit,
     isValid,
     isSubmitting,
     validateForm,
   } = props;
   
   const {
      history,
      t
   } = props;
   
   const getFormikInputProps = useCallback(
    name => ({
      name,
      value: values[name],
      error: Boolean(touched[name] && errors[name]),
      errorText: touched[name] ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange
    }),
    [values, errors, touched, handleBlur, handleChange]
  );
  
  const handleCancel = () => {
    history.push("/");
  }
  
  return (
    <>
      <Container className="mt-4 pb-5">
        <Row className="justify-content-center">
          <Col lg="6" md="7" className="">
            <Card>
              <CardHeader className="bg-transparent pt-5 pb-1">
                
                <div className="text-center text-muted mb-4">
                  <Typography variant="h2" className="mb-2">{t("Aseta uusi salasana")}</Typography>
                  <Typography variant="p"></Typography>
                </div>
              
              </CardHeader>
              <CardBody className="bg-transparent px-lg-5 py-lg-5">
                
                <Row>
                  <Col className="mb-3">
                    <Input
                      {...getFormikInputProps("password")}
                      label={t("Uusi salasana")}
                      type="password"
                      required
                    />
                  </Col>
                </Row>
                
                <Row>
                  <Col className="mb-3">
                    <Input
                      {...getFormikInputProps("password2")}
                      label={t("Uusi salasana uudelleen")}
                      type="password"
                      required
                    />
                  </Col>
                </Row>
                
                <div className="text-center text-muted mb-4 mt-4">
                  <Button variant="secondary" disabled={isSubmitting} onClick={() => validateForm().then(() => handleSubmit())}>{t("Tallenna")}</Button>
                  { Boolean(errors["general"]) && <Typography className="text-danger">{errors["general"]}</Typography> }
                </div>
                
              </CardBody>
            </Card>

          </Col>
        </Row>
      </Container>
    </>
  );
}

const defaultValues = {
  password: '',
  password2: '',
}

 const PasswordResetFormik = withFormik({
    
    validateOnMount: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return defaultValues
      }
    },
    validationSchema: props => {
      const {t} = props;
      const required = t("Kenttä on pakollinen");
      
      return Yup.object().shape({
        password: Yup.string().required(required),
        password2: Yup.string().required(required),
      });
    },
    validate: (values, props) => {
      const {t} = props;
      const errors = {};
      
      if (values?.password && values?.password2 && values?.password != values?.password2) {
        errors.password = t("Passwords does not match")
      }
     
       return errors;
    },
    handleSubmit: (values, { setSubmitting, setErrors, props }) => {
      const {t} = props;
      let data = {
        data: {
          new_password: values?.password,
          check_password: values?.password2,
        }
      };
      
      httpChangePassword("me", data).then(res => {
        setSubmitting(false);
        if (res?.status === 200) {
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("accessToken");
          props.notify({ title:t("Salasana"), message:t("Päivitetty onnistuneesti")})
          props.history.push("/auth/login")
        }
        else {
          if (res?.data?.User) {
            setErrors({
              general: t(res?.data?.User)
            })
          }
          else {
            setErrors({
              general: JSON.stringify(res?.data)
            })
          }
          
        }
      }, error => {
        setSubmitting(false);
        if (error?.data?.detail) {
          setErrors({
            general: error?.data?.detail,
          })
        }
        else {
          setErrors({
            general: t("Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen.")
          })
        }
      })
    },
    displayName: "BasicForm"
  
  })(PasswordReset)
    
    
const PasswordResetView = ({location, match, history}) => {
  
  const { t } = useTranslation();
  const { notify } = useNotification();
  const [infoAlert, setInfoAlert] = useState();
  
  const handleModalConfirm = () => {
    setInfoAlert(null);
    // history.push("/dashboard/users");
  }
  
  const handleResolve = () => {
    notify({ title:t("Käyttäjä"), message:t("Uusi salasana asetettu. Voit nyt kirjautua sisään")})
    handleModalConfirm();
  }
  
  useEffect(() => {
    
    const parsed = queryString.parse(location.search);
    if (parsed?.token) {
      localStorage.removeItem("refreshToken");
      localStorage.setItem("accessToken", parsed?.token);
    }
    
  },[location])
  
  return (
    <>
      <PasswordResetFormik history={history} t={t} handleResolve={handleResolve} notify={notify} />
      {infoAlert}
    </>
  )
}
  

export default PasswordResetView;
