import { React, useEffect, useState } from 'react';

//import easypeasy
import { useStoreState } from 'easy-peasy';
import { Markup } from 'interweave';
import { useTranslation } from 'react-i18next';
import moment from "moment";



import { Typography, Button, Link, IconButton, Modal, Spinner, BreadcrumbsDynamic } from "stories/components";
import { PageHeader, PageContainer } from "components/Page"
import { Container, Row, Col, FormContainer, Card, CardBody } from "stories/layout";
import { httpUpdateUser } from "services/users";
import { useNotification } from "stories/components/Notification"
import { useStoreActions} from 'easy-peasy';
import { Table, THead, Pagination } from "stories/tables";



// use autContext
import { useAuthContext } from "contexts/AuthContext";
import { differenceInSecondsWithOptions } from 'date-fns/fp';
import { httpGetMyUser } from 'services/users';
import { httpGetNewFeatures } from 'services/new_features';




import "./NewFeatures.css"


const NewFeatures = ({ location }) => {

    const { t } = useTranslation();
    const { myUser, getMyUser, loading } = useAuthContext();
    const myUserLoading = loading
    const newFeatures = useStoreState((state) => state.newFeatures?.newFeatures);
    const newFeaturesLoading = useStoreState((state) => state.newFeatures?.loading);
    const count = useStoreState((state) => state.newFeatures?.count);

    const [allReaded, setAllReaded] = useState(false)
    const getNewFeatures = useStoreActions((actions) => actions.newFeatures.getNewFeatures);


    const { notify } = useNotification(); 
    
    const [filters, setFilters] = useState({
      limit: 30,
      offset:0,
    })
    
    
    const paginationPrevious = () => {
      let offset = filters?.offset - filters?.limit;
      if (offset < 0) {
        offset = 0;
      }
      setFilters(f => ({
        ...f,
        offset: offset,
      }))
    }
    
    const paginationNext = () => {
      let offset = filters?.offset + filters?.limit;
      if (offset > filters?.count) {
        return
      }
      setFilters(f => ({
        ...f,
        offset: offset,
      }))
    }

    const filterFeatures = (newFeatures) => {
      //return newFeatures using filters.offset and filters.limit using map
      return newFeatures?.slice(filters.offset, filters.offset + filters.limit) 
    }


    

    const getCrumbLinks = () => {
        const crumbLinks = [
          {name: t("Etusivu"), url: "/dashboard/"},
          {name: t("routes_dashboard_new_features", "Uutta"), url: "/dashboard/new_features"},
        ]
    
        //if location state include crumbLinks return them
        if (location?.state && location?.state?.crumbLinks) {
          //made new const and deapcopy location.state.crumbLinks into it
          const crumbLinks = [...location.state.crumbLinks]
    
    
    
          crumbLinks.push({name:  t("routes_dashboard_new_features", "Uutta"), url: "/dashboard/new_features"})
          return crumbLinks
        }
        //else return default links
        return crumbLinks
      }
    
    const isReaded = (feature) => {
      const feature_created = feature.created
      const feature_updated = feature.updated

      //if feature is created after myUser.new_features_readed return new
      if ((new Date(feature_created) > new Date(myUser.new_features_readed)) || myUser.new_features_readed === null) {
        return <div><Typography variant="h6"  className="py-1 px-2 bg-success text-white rounded">Uusi</Typography></div>
      }
      //if feature is updated after myUser.new_features_readed return päivitetty
      else if ((new Date(feature_updated) > new Date(myUser.new_features_readed))  || myUser.new_features_readed === null) {
        return  <div><Typography variant="h6" className="py-1 px-2 bg-success text-white rounded">Päivitetty {moment(feature_updated).format('DD.MM.YYYY')}</Typography></div>
      }
      return null
    }



    const isUnreadedFeatures = (newFeatures) => {
      
      // if (allReaded) return false

      //map all newFeatures and check if any of them is unreaded

      const unreadedFeatures = newFeatures.map((feature) => {
        //if feature is created after myUser.new_features_readed return true
        if ((new Date(feature.created) > new Date(myUser.new_features_readed)) || myUser.new_features_readed === null) {
          return true
        }
        //if feature is updated after myUser.new_features_readed return true
        else if ((new Date(feature.updated) > new Date(myUser.new_features_readed)) || myUser.new_features_readed === null) {
          return true
        }
        return false
      })
      console.log(unreadedFeatures)
      return unreadedFeatures.includes(true)
    } 

    const saveAllReaded = () => {
      

      httpUpdateUser(myUser.id, {new_features_readed: new Date()}).then(
        (response) => {
          // notify({message: "!kdf", type: "error"})
          setAllReaded(true)
          getMyUser()
          getNewFeatures()
        }, error => {
          // notify({message: error.message, type: "error"})
          }
        )


    }


    return (
      <>
        <PageHeader title={t("routes_dashboard_new_features", "Uutta")} />
        <BreadcrumbsDynamic links={getCrumbLinks()} />
        
        <PageContainer className="pt-3">
          {}
          {newFeaturesLoading || myUserLoading ? (
              <div>Loading...</div>
            ):(
              <div>
              <Row className="mb-4">
                <Col>
                  {isUnreadedFeatures(newFeatures?.new_features) && allReaded===false && <Button variant="success" className="" onClick={() => saveAllReaded()}> {t('Merkitse kaikki luetuksi')}</Button>}
                  {allReaded}
                  </Col>
              </Row>
              {filterFeatures(newFeatures?.new_features).map((newFeature) => (
              <Row key={newFeature.id} className="mb-3">
                <Col>
                  <Row>
                    <Col>
                      <h2>{newFeature.title } {moment(newFeature.created).format('DD.MM.YYYY')} </h2>
                    </Col>   
                    <Col xs="12 flex-row" md="3 flex-row-reverse"  className="d-flex  px-4">

                      {isReaded(newFeature) }
                    </Col>     
                  </Row >
                  <div className="new-features-markup-div">
                     <Markup content={newFeature.description} />
                  </div>
                    
                </Col>
              </Row>
              ))}
              </div>
            )
          }
          { true && (
            <Pagination 
              paginationPrevious={paginationPrevious}
              paginationNext={paginationNext}
              paginationOffset={filters?.offset}
              paginationLimit={filters?.limit}
              paginationCount={count}
              setFilters={setFilters}
            />
          )}
        </PageContainer>
        
          
      </>

    );
};

export default NewFeatures;


