








// import React, { useEffect, useRef } from 'react';

// let tvScriptLoadingPromise;

// export default function TradingViewWidget() {
// const onLoadScriptRef = useRef();

// useEffect(
// () => {
//     onLoadScriptRef.current = createWidget;

//     if (!tvScriptLoadingPromise) {
//     tvScriptLoadingPromise = new Promise((resolve) => {
//         const script = document.createElement('script');
//         script.id = 'tradingview-widget-loading-script';
//         script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js';
//         script.type = 'text/javascript';
//         script.onload = resolve;

//         document.head.appendChild(script);
//     });
//     }

//     tvScriptLoadingPromise.then(() => onLoadScriptRef.current && onLoadScriptRef.current());

//     return () => onLoadScriptRef.current = null;

//     function createWidget() {
//     if (document.getElementById('technical-analysis-chart-demo1') && 'TradingView' in window) {
//         new window.TradingView.widget({
//         container_id: "technical-analysis-chart-demo1",
//         width: "100%",
//         height: "100%",
//         autosize: true,
//         symbol: "AAPL",
//         interval: "D",
//         timezone: "exchange",
//         theme: "light",
//         style: "1",
//         withdateranges: true,
//         hide_side_toolbar: false,
//         allow_symbol_change: true,
//         save_image: false,
//         studies: ["ROC@tv-basicstudies","StochasticRSI@tv-basicstudies","MASimple@tv-basicstudies"],
//         show_popup_button: true,
//         popup_width: "1000",
//         popup_height: "650",
//         support_host: "https://www.tradingview.com",
//         locale: "en"
//         });
//     }
//     }
// },
// []
// );

// return (
// <div className='tradingview-widget-container' style={{ height: "100%", width: "100%" }}>
//     <div id='technical-analysis-chart-demo1' style={{ height: "calc(100% - 32px)", width: "100%" }} />
//     <div className="tradingview-widget-copyright">
//     <a href="https://www.tradingview.com/" rel="noopener nofollow" target="_blank"><span className="blue-text">Track all markets on TradingView</span></a>
//     </div>
// </div>
// );
// }







import React, { useEffect, useState } from 'react';
import './TradingViewWidget.css';
import { set } from 'date-fns';

const TradingViewWidget2 = (props) => {
  const [scriptLoaded, setScriptLoaded] = useState(false);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://s3.tradingview.com/tv.js';
    // script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-technical-analysis.js';
    script.async = true;
    if (!scriptLoaded) {
      script.onload = () => {
        new window.TradingView.widget({ //whatis this? explain this line
          // // "autosize": true,
          "symbol": props.symbol,
          // // "symbol": "OMXHEX:NOKIA",
          "timezone": "Etc/UTC",
          "theme": "light",
          "style": "1",
          "locale": "en",
          "enable_publishing": false,
          "withdateranges": true,
          "range": "YTD",
          "hide_side_toolbar": false,
          "allow_symbol_change": true,
          "details": true,
          "hotlist": true,
          "calendar": true,
          "container_id": 'tradingview-widget-container1',
          "width": "100%",
          "height": "100%",

          // "container_id": "tradingview-widget-container1",
          // "width": "100%",
          // "height": "100%",
          // "autosize": true,
          // // "symbol": "AAPL",
          // "interval": "D",
          // "timezone": "exchange",
          // "theme": "light",
          // "style": "1",
          // "withdateranges": true,
          // "hide_side_toolbar": false,
          // "allow_symbol_change": true,
          // "save_image": false,
          // "studies": [
          // "ROC@tv-basicstudies",
          // "StochasticRSI@tv-basicstudies",
          // "MASimple@tv-basicstudies"
          // ],
          "show_popup_button": true,
          "popup_width": "1000",
          "popup_height": "650",
          // "support_host": "https://www.tradingview.com",
          // "locale": "en"

   



        });
      };

      // document.body.appendChild(script);
      document.getElementById('tradingview-widget-container1').appendChild(script);
    }
    setScriptLoaded(true);

    // if (scriptLoaded) {
    //   //get loaded script element and set min height to 700px
    //   const scriptElement = document.getElementById('tradingview-widget-container1');
    //   //get firt div element inside script element and set that height 700x

    //   if (scriptElement.firstElementChild) {
    //     // Get the first child element of the div
    //     const firstChild = scriptElement.firstElementChild;
    //     const firstChildDiv = scriptElement.querySelector('div');
    //     console.log(firstChild);
    //     console.log(firstChildDiv);
      
    //     // Set the height of the first child element
    //     firstChild.style.height = '7px'; // Replace '100px' with your desired height
    //   }

    // }

    // return () => {
    //   document.body.removeChild(script);
    // };
  }, [scriptLoaded]);

  return (

  <div class="tradingview-widget-container">
  <div class="tradingview-widget-container__widget" id="tradingview-widget-container1"></div>


  <div class="tradingview-widget-copyright" ><a href="https://www.tradingview.com/" rel="noopener nofollow" target="_blank"><span class="blue-text">Track all markets on TradingView</span></a></div>
  </div>
  )
  return (
    <div style={{ minHeight: '700px' }}>
    <div className="tradingview-widget-container"  style={{ height: '100%', width: '100%', minHeight: '700px' }}>
      <div className="tradingview-widget-container__widget"  id="tradingview-widget-container1" style={{ height: 'calc(100% - 32px)', width: '100%', minHeight: '700px' }}>
    
      </div>
      <div className="tradingview-widget-copyright">
        <a href="https://www.tradingview.com/" rel="noopener nofollow" target="_blank">
          <span className="blue-text">Track all markets on TradingView</span>
        </a>
      </div>
    </div>
    </div>
  );
};

export default TradingViewWidget2;






































//ThIS IS THE WORKING CODE
// import React, { useEffect } from 'react';

// const TradingViewWidget = (props) => {
//   useEffect(() => {
//     const script = document.createElement('script');
//     script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-technical-analysis.js';
//     script.async = true;
//     script.innerHTML = `
//       {
//         "interval": "1m",
//         "width": 425,
//         "isTransparent": false,
//         "height": 450,
//         "symbol": "${props.symbol}",
//         "showIntervalTabs": true,
//         "displayMode": "single",
//         "locale": "en",
//         "colorTheme": "dark"
//       }
//     `;
    
//     document.querySelector('.tradingview-widget-container__widget').appendChild(script);
//   }, []);

//   return (
//     <div className="tradingview-widget-container">
//       <div className="tradingview-widget-container__widget"></div>
//       <div className="tradingview-widget-copyright">
//         <a href="https://www.tradingview.com/" rel="noopener nofollow" target="_blank">
//           <span className="blue-text">Track all markets on TradingView</span>
//         </a>
//       </div>
//     </div>
//   );
// };

// export default TradingViewWidget;

// // TradingViewWidget.jsx
// import React, { useEffect, useRef, memo } from 'react';

// function TradingViewWidget() {
//   const container = useRef();

//   useEffect(
//     () => {
//       const script = document.createElement("script");
//       script.src = "undefined";
//       script.type = "text/javascript";
//       script.async = true;
//       script.innerHTML = `
//         {
//           "autosize": true,
//           "symbol": "NASDAQ:AAPL",
//           "interval": "D",
//           "timezone": "Etc/UTC",
//           "theme": "dark",
//           "style": "1",
//           "locale": "en",
//           "enable_publishing": false,
//           "allow_symbol_change": true
//         }`;
//       container.current.appendChild(script);
//     },
//     []
//   );

//   return (
//     <div className="tradingview-widget-container" ref={container} style={{ height: "100%", width: "100%" }}>
//       <div className="tradingview-widget-container__widget" style={{ height: "calc(100% - 32px)", width: "100%" }}></div>
//       <div className="tradingview-widget-copyright"><a href="https://www.tradingview.com/" rel="noopener nofollow" target="_blank"><span className="blue-text">Track all markets on TradingView</span></a></div>
//     </div>
//   );
// }

// export default memo(TradingViewWidget);




// TradingViewWidget.jsx

// import React, { useEffect, useRef } from 'react';

// let tvScriptLoadingPromise;

// export default function TradingViewWidget() {
// const onLoadScriptRef = useRef();

// useEffect(
// () => {
//     onLoadScriptRef.current = createWidget;

//     if (!tvScriptLoadingPromise) {
//     tvScriptLoadingPromise = new Promise((resolve) => {
//         const script = document.createElement('script');
//         script.id = 'tradingview-widget-loading-script';
//         script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js';
//         script.type = 'text/javascript';
//         script.onload = resolve;

//         document.head.appendChild(script);
//     });
//     }

//     tvScriptLoadingPromise.then(() => onLoadScriptRef.current && onLoadScriptRef.current());

//     return () => onLoadScriptRef.current = null;

//     function createWidget() {
//     if (document.getElementById('technical-analysis-chart-demo') && 'TradingView' in window) {
//         new window.TradingView.widget({
//         container_id: "technical-analysis-chart-demo",
//         width: "100%",
//         height: "100%",
//         autosize: true,
//         symbol: "AAPL",
//         interval: "D",
//         timezone: "exchange",
//         theme: "light",
//         style: "1",
//         withdateranges: true,
//         hide_side_toolbar: false,
//         allow_symbol_change: true,
//         save_image: false,
//         studies: ["ROC@tv-basicstudies","StochasticRSI@tv-basicstudies","MASimple@tv-basicstudies"],
//         show_popup_button: true,
//         popup_width: "1000",
//         popup_height: "650",
//         support_host: "https://www.tradingview.com",
//         locale: "en"
//         });
//     }
//     }
// },
// []
// );

// return (
// <div className='tradingview-widget-container' style={{ height: "100%", width: "100%" }}>
//     <div id='technical-analysis-chart-demo' style={{ height: "calc(100% - 32px)", width: "100%" }} />
//     <div className="tradingview-widget-copyright">
//     <a href="https://www.tradingview.com/" rel="noopener nofollow" target="_blank"><span className="blue-text">Track all markets on TradingView</span></a>
//     </div>
// </div>
// );
// }


