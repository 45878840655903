import React, { useEffect, useCallback, useState} from "react";
// react library for routing
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import classnames from "classnames";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import Info from "components/Info/Info.js";
import LicensePayment from "views/vismapay/LicensePayment/LicensePayment"


import { PageWrapper } from "components/Page"
import { useAuthContext } from "contexts/AuthContext"
import routesAdmin from "routes_dashboard.js";
import { Container, Row, Col } from "stories/layout";
import { IconButton, Modal, Button } from "stories/components";

import { useStoreState, useStoreActions } from 'easy-peasy';

// App
import { httpGetDashboardTopNavigationButtons } from "services/dashboard"
import { httpGetPortfolios } from "services/portfolios"

const showTopNavigationToggleButton = false;

function Dashboard(props) {
  const { t } = useTranslation();
  const { myUser } = useAuthContext()
  const location = useLocation();
  const mainContentRef = React.useRef(null);
  const [router, setRouter] = useState();
  const [sidenavAlwaysOpen, setSidenavAlwaysOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(true);

  const portfolios = useStoreState((state) => state.portfolio?.portfolios);
  const portfoliosLoading = useStoreState((state) => state.portfolio?.loading);
  
  const myAccount = useStoreState((state) => state.account?.account);
  const myAccountLoading = useStoreState((state) => state.account?.loading);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // You can adjust the width threshold as needed
  
  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= 768); // Update the state when the window is resized
    }

    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  useEffect(() => {
    if (document?.documentElement) {
      document.documentElement.scrollTop = 0;
    }
    if (document?.scrollingElement) {
      document.scrollingElement.scrollTop = 0;
    }
    if (mainContentRef?.current) {
      mainContentRef.current.scrollTop = 0;
    }
  }, [location]);
  
  useEffect(() => {
    setRouter(routesAdmin(t))
  }, [myUser])
  
  // Top navigation
  const [topNavOpen, setTopNavOpen] = useState(true);
  const [topNavButtons, setTopNavButtons] = useState([])
  
  const getDashboardTopNavigationButtons = () => {
    httpGetDashboardTopNavigationButtons().then(res => {
      let links = []
      res?.data?.map(ln => {
        links.push({
          ...ln,
          name: t(ln?.name),
          isExternal: ln?.link_type === 4,
          href: ln?.link_type === 3 || ln?.link_type === 4 ? ln?.url : undefined,
          to: ln?.link_type === 2 ? ln?.url : undefined,
        })          
      })
      setTopNavButtons(links)
    })
  }
  
  useEffect(() => {
    getDashboardTopNavigationButtons()
  }, [])
  
  const toggleTopNav = () => {
    setTopNavOpen(s => !s)
  }
  
  //
  
  //
  
  const getRouter = () => {
    return routesAdmin;
  }
  
  const getRoutes = (_router) => {
    if (!_router) {
      return;
    }
    
    // Returns only right routes, by user permissions and layout
    return _router.filter((route, key) => {
      if (route?.layout === "/dashboard") {
        if (route?.permissions === 3) {
          if (myUser?.is_staff === true) {
            return true;
          }
        }
        else {
          return true;
        }
      }
      return false;
    })
  }
  
  const listRoutes = (_router) => {
    if (!_router) {
      return;
    }
    // Construct Route components
    const filteredRoutes = getRoutes(_router);
    
    return filteredRoutes.map((prop, key) => {
      if (!prop.component && !prop.collapse) {
        return null;
      }
      if (prop.collapse) {
        return listRoutes(prop.views);
      }
      if (prop.layout === "/dashboard") {
        if (prop?.permissions === 3) {
          if (myUser?.is_staff === true) {
            return (
              <Route
                exact={prop.exact ? prop.exact : undefined}
                path={prop.layout + prop.path}
                component={prop.component}
                key={key}
              />
            );
          }
        }
        else {
          return (
            <Route
              exact={prop.exact ? prop.exact : undefined}
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          );
        }
      } else {
        return null;
      }
    });
  };
  const getBrandText = (path) => {
    if (!router) {
      return "";
    }
    for (let i = 0; i < router.length; i++) {
      if (location.pathname.indexOf(router[i].layout + router[i].path) !== -1) {
        return router[i].name;
      }
    }
    return "Brand";
  };
  
  const getNavbarTheme = () => {
    return location.pathname.indexOf("admin/alternative-dashboard") === -1
      ? "light"
      : "dark";
  };
  
  // INFO: Create portfolio modal

  const [modalEditOpen, setModalEditOpen] = useState(false)
  
  useEffect(() => {
    if (!myAccount?.id) {
      return;
    }
    if (portfoliosLoading) {
      setModalEditOpen(false)
    }
    else {
      if (licensePaymentModalOpen === true) {
        setModalEditOpen(false)
      }
      else {
        setModalEditOpen(portfolios.length === 0 ? true : false)
      }
    }
  }, [portfolios, myAccount])

  
  const [modalEditId, setModalEditId] = useState()
  
  const toggleModalEdit = () => {
    setModalEditOpen(s => !s);
  }
  
  const openModalEdit = (id) => {
    setModalEditOpen(true)
    setModalEditId(id);
  }
  
  const modalEditResolve = () => {
    setModalEditOpen(false);
    setModalEditId(null);
    
  }
  
  const modalEditReject = () => {
    setModalEditOpen(false);
    setModalEditId(null);
  }

  // LicensePayment modal
  const [licensePaymentModalOpen, setLicensePaymentModalOpen] = useState(false)
  
  const toggleLicensePaymentModal = () => {
    setLicensePaymentModalOpen(s => !s);
  }
  
  const handleLicensePaymentReject = () => {
    
  }

  const sidebarStyle = isOpen
  ? { transform: 'translateX(0) ', transition: 'transform 0.3s ease' }
  : { transform: 'translateX(-100%) ', transition: 'transform 0.3s ease' };
  
  useEffect(() => {
    if (myAccountLoading) {
      setLicensePaymentModalOpen(false)
    }
    else if (myAccount?.id && myAccount?.id > 0) {
      if (myAccount?.license_is_active === false) {
        setModalEditOpen(false)
        setLicensePaymentModalOpen(true)
      }
    }
  }, [myAccount, myAccountLoading])

  
  if (router) {
    return (
      <>
          
          
      <div >
      
        <Sidebar
        routes={getRoutes(router)}
        logo={{
          innerLink: "/dashboard/frontpage",
          imgSrc: require("assets/images/logo_nav.png").default,
          imgAlt: "investime",
        }}
        myAccount={myAccount}
        alwaysOpen={sidenavAlwaysOpen}
        setAlwaysOpen={setSidenavAlwaysOpen}
        isOpen = {isOpen}
        setIsOpen = {setIsOpen}
        style={sidebarStyle}
        />
        </div>
        <div className={isOpen && !isMobile?'main-content':'main-content-closed'} ref={mainContentRef}>
          
          <AdminNavbar
            theme={"dark"}
            sidenavOpen={true}
            brandText={getBrandText(location.pathname)}
            myUser={myUser}
            topNavOpen={topNavOpen}
            toggleTopNav={toggleTopNav}
            topNavButtons={topNavButtons}
            isOpen = {isOpen}
            setIsOpen = {setIsOpen}
            style={!isOpen
              ? { position: 'sticky', top: 0, zIndex: 1000 }
              : {}}
          />
 
          { showTopNavigationToggleButton  && (
          <div style={{position:"absolute", right:"4px", top: topNavOpen ? "0px" : "-12px"}}>
            <IconButton color="secondary" size="lg" iconName={topNavOpen ? "times" : "angle-down"} onClick={toggleTopNav} />
          </div>
          )}

          <PageWrapper>
            <Switch>
              {listRoutes(router)}
              <Redirect from="*" to="/dashboard/frontpage" />
            </Switch>
            <Modal
              showHeader={false}
              isOpen={modalEditOpen}
              toggleModal={toggleModalEdit}
              size="modal-lg"
            >
              <Info
                objectId={modalEditId}
                handleResolve={modalEditResolve}
                handleReject={modalEditReject}
              />
            </Modal>
            <Modal
              isOpen={licensePaymentModalOpen}
              toggleModal={toggleLicensePaymentModal}
              size="modal-lg"
              showHeader={false}
            >
              <LicensePayment
                licenseId={myAccount?.license?.id}
                handleReject={handleLicensePaymentReject}
                hasActiveLicense={false}
              />
              
            </Modal>
          </PageWrapper>
          <AdminFooter />
        </div>

      </>
    );
  }
  return (
    <></>
  );
}

export default Dashboard;
