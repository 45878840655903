import React, { useRef }from 'react';
import PropTypes from 'prop-types';
import classnames from "classnames";
import { useTranslation } from 'react-i18next';
import {
  FormGroup,
  Input as BootstrapInput,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";

import { Button, Typography } from "stories/components"

import './ImageInput.css';

export const ImageInput = ({ id, className, label, handleChange, resetFile, required, selectedFile, children, ...rest }) => {
  const { t } = useTranslation();
  const fileInput = useRef(null)
  
  return (
    <div>
      {label && (
        <label htmlFor={id}>{`${label}${required ? '*' : ''}`}</label>
      )}
      <div style={{width: "100%", marginTop: 4}}>
        <input
          style={{display: "none"}}
          ref={fileInput}
          type="file"
          id="cardImage"
          onChange={handleChange}
        />
        <Button className="ButtonUpload" onClick={() => fileInput?.current?.click()}>
          {selectedFile?.name || t("Select image")}
        </Button>
        {selectedFile ? (
          <div style={{marginTop: 4}}>
            <Button onClick={resetFile}>
              Return original image
            </Button>
          </div>
        ): null}
      </div>
    </div>
  )
};

ImageInput.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
};

//export ImageInput;
