import React, { useState, useCallback, useEffect } from "react";
import { withFormik } from 'formik';
import * as Yup from "yup";
import moment from "moment"
import { useTranslation } from 'react-i18next';
import { useStoreState, useStoreActions } from 'easy-peasy';


import { Button, Typography, Link, Spinner, Modal } from "stories/components";
import { Container, Row, Col, FormContainer, Card, CardBody } from "stories/layout";
import { Input, SelectMulti, Toggle, Radio } from "stories/forms";
import { useNotification } from "stories/components/Notification"

import { httpGetPortfolio, httpCreatePortfolio, httpUpdatePortfolio, httpDeletePortfolio } from "services/portfolios"
import PortfolioSelectStore from "components/Forms/PortfolioSelect/PortfolioSelectStore"
import AccountSelect from "components/Forms/AccountSelect/AccountSelect"

import { httpGetUser, httpCreateUser, httpUpdateUser, httpInviteUser } from "services/users" 
import { useAuthContext } from "contexts/AuthContext"




import CurrencySelect from "components/Forms/CurrencySelect/CurrencySelect"
import CompanySelect from "components/Forms/CompanySelect/CompanySelect"
import CompanyEdit from "views/dashboard/CompanyEdit/CompanyEdit"


const defaultValues = {
  id: null,
  name: "",
}

const Role = (props) => {
  

  const {
     values,
     touched,
     setTouched,
     errors,
     setErrors,
     handleChange,
     handleBlur,
     handleSubmit,
     setFieldValue,
     isValid,
     isTouched,
     isSubmitting,
     validateForm,
     preSetValues
   } = props;
   
   const {
      t,
      history,
      objectId,
      companies,
      account,
   } = props;
   
   const getFormikInputProps = useCallback(
    name => ({
      name,
      value: values[name],
      error: Boolean(touched[name] && errors[name]),
      errorText: touched[name] ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange
    }),
    [values, errors, touched, handleBlur, handleChange]
  );

const { myUser } = useAuthContext()


  const getCompanies = useStoreActions((actions) => actions.companies?.getCompanies)
  const portfolio = useStoreState((state) => state.portfolio?.portfolio);


  
  const onSubmit = (e) => {
    e.preventDefault();
    validateForm().then(res => {
      if (Object.keys(res).length === 0) {
        handleSubmit()
      }
      else {
        setTouched(res);
        setErrors({
          ...res,
          general: t("Please check the form errors", "Tarkista lomakkeen virheet")
        })
      }
    })
  }
  
  const handleCancel = () => {
    props.handleReject()
  }
  
  const handleDelete = () => {
    httpDeletePortfolio(objectId).then(() => {
      props.handleResolve()
    })
  }

  const getCompanyName = useCallback((id) => {
    if (companies) {
        const filtered = companies.filter(obj => obj.id === id)
        if (filtered && filtered.length > 0) {
            return filtered[0].name
        }
    }
    return ""
    }, [portfolio, companies    ])



    // Modal: New/Edit company
  
    const [modalEditOpen, setModalEditOpen] = useState(false)
    const [modalEditId, setModalEditId] = useState()
    
    const toggleModalEdit = () => {
      setModalEditOpen(s => !s);
    }
    
    const openModalEdit = (id) => {
      setModalEditOpen(true)
      setModalEditId(id);
    }
    
    const modalEditResolve = () => {
      setModalEditOpen(false);
      setModalEditId(null);
      getCompanies()
    }
    
    const modalEditReject = () => {
      setModalEditOpen(false);
      setModalEditId(null);
    }

    const change_account = (e) => {
      setFieldValue("account", Number(e.target.value))
      console.log("account", e.target.value);

      const {t} = props;
      let data = {
        active_account: Number(e.target.value),
      };
      
      if (myUser?.id) {
        httpUpdateUser(myUser.id, data).then(res => {
          // props.notify({ title:t("Asiakkuus vaihdettu"), message:t("")})
          //reload page
          window.location.reload();
          // props.history.push("/dashboard/users/list");
        }, error => {
          if (error?.data?.detail) {
            setErrors({
              general: error?.data?.detail,
            })
          }

          else {
            setErrors({
              general: t("Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen.")
            })
          }
        })
      }
    }

  
  return (
    <>
      {myUser?.multi_account_user && 
      <>
        <Typography variant="h3" className="text-dark mb-1">{t("sidebar-role-business","Valitse asiakas")}</Typography>
        <AccountSelect 
         {...getFormikInputProps("account")} 
          label= ""
          value={values?.account}
          onChange={(e) => change_account(e)}
      
        />
        </>
      }
        {/* {JSON.stringify(values.account)} */}
        <Typography variant="h3" className="text-dark mb-1 mt-3">{t("sidebar-role-business","Valitse salkku")}</Typography>




            {/* <CompanySelect 
            {...getFormikInputProps("company")} 
            label= ""
            chose={false}
            
        /> */}

      <PortfolioSelectStore className="mt-0" value={props.portfolioID} />
      <Typography variant="small" className="mt-1">
        {t("role_portfolio_edit_title","Yritys") + ": " + getCompanyName(portfolio?.company)}
        </Typography>

      

      <Modal
      title={t("companies_list_company_title","Yritys")}
      isOpen={modalEditOpen}
      toggleModal={toggleModalEdit}
      
    >
      <CompanyEdit
        objectId={modalEditId}
        handleResolve={modalEditResolve}
        handleReject={modalEditReject}
        extraMargin={true}
      />
    </Modal>
    </>
        
    // </Col>
    // </Row>
    // </Container>
  );
}

const RoleFormik = withFormik({
    enableReinitialize: true,
    validateOnMount: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return {
          ...defaultValues
        }
      }
    },
    validationSchema: props => {
      const {t, account} = props;
      const required = t("The field is required");
      return Yup.object().shape({
        name: Yup.string().required(required).nullable(),
        // company: Yup.number().required(required).min(1, t("Valitse jokin yritys tai luo uusi")).nullable(),
        company: Yup.string().when('account_type', {
          is: (val) => val !== "private",
          then:  Yup.string().required(required).min(1, t("Valitse jokin yritys tai luo uusi")).nullable(),
        }),


      });
    },
    handleSubmit: (values, { setSubmitting, setErrors, props }) => {
      const {t, portfolio, account} = props;
      console.log("account", account);
      let data = {
        ...values,
        account: account?.id,
      };
      
      if (values?.id) {
        httpUpdatePortfolio(values.id, data).then(res => {
          setSubmitting(false);
          props.notify({ title:t("Portfolio"), message:t("Updated")})
          props.handleResolve()
          
        }, error => {
          setSubmitting(false);
          if (error?.data?.detail) {
            setErrors({
              general: error?.data?.detail,
            })
          }
          else {
            setErrors({
              general: t("Unknown error. Please try again later or contact support.")
            })
          }
        })
      }
      else {
        httpCreatePortfolio(data).then(res => {
          setSubmitting(false);
          props.notify({ title:t("Portfolio"), message:t("Created")})
          props.handleResolve()
          
        }, error => {
          setSubmitting(false);
          if (error?.data?.detail) {
            setErrors({
              general: error?.data?.detail,
            })
          }
          else if (JSON.stringify(error?.data).includes("PORTFOLIO_LICENSE_LIMIT")) {
            setErrors({
              general: t("PORTFOLIO_LICENSE_LIMIT")
            })
          }
          else {
            setErrors({
              general: t("Unknown error. Please try again later or contact support.")
            })
          }
        })
      }
      
      
    },
    displayName: "BasicForm"
  
})(Role)
    
    
const RoleView = ({history, objectId, modalOpen, ...rest}) => {
  // API requests here
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [preSetValues, setPreSetValues] = useState();
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  const account = useStoreState((state) => state.account?.account);

  const getCompanies = useStoreActions((actions) => actions.companies?.getCompanies)
  const companies = useStoreState((state) => state.companies?.companies)

  
  const getObject = (id) => {
    
    setLoading(true)
    
    httpGetPortfolio(id).then(res => {
      
      setPreSetValues({
        ...defaultValues,
        ...res?.data,
      });
    
    }).finally(response => {
      setLoading(false);
    })
    
  }
  useEffect(() => {
    getCompanies()
  }, [])
  
  useEffect(() => {
    if (objectId && parseInt(objectId) > -1) {
      getObject(objectId)
    }
    else {
      setPreSetValues({
        ...defaultValues,
        account_type: account?.type,
        account: account?.id,
        company: companies[0]?.id,
      });
    }
  }, [objectId, modalOpen, companies])
  
  
  if (loading) {
    return (
      <Container fluid>
        <Spinner />
      </Container>
    )
  }

  return (
    <RoleFormik 
      t={t} 
      history={history} 
      preSetValues={preSetValues} 
      notify={notify} 
      objectId={objectId}
      account={account}
      companies={companies}
      {...rest} 
    />
  )
  
}

export default RoleView;
