
import React, { useEffect } from 'react';

const TradingViewTopStories = ({symbol, width=300, height=300, color="light"}) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-financials.js';
    script.async = true;
    script.innerHTML = `
      {
        "symbol": "${symbol}",
        "width": "${width}",
        "height": "${height}",
        "locale": "en",
        "dateRange": "12M",
        "colorTheme": "${color}",
        "isTransparent": false,
        "autosize": false,
        "largeChartUrl": ""

      }
    `;



    //   });
    // };

    const container = document.getElementById('tradingview-widget-container32');
    container.appendChild(script);

    // return () => {
    //   // Cleanup script when component is unmounted
    //   container.removeChild(script);
    // };
  }, []); // Empty dependency array ensures useEffect runs only once during component mount

  return (
    <div>
    <div id="mini-symbol-overview-widget-container"  >
      <div className="tradingview-widget-container__widget" id="tradingview-widget-container32"></div>
      <div className="tradingview-widget-copyright">
        <a href="https://www.tradingview.com/" rel="noopener nofollow" target="_blank">
          {/* <span className="blue-text">Track all markets on TradingView</span> */}
        </a>
      </div>
    </div>
      
    </div>
  );
};

export default TradingViewTopStories;

