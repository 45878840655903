import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import { Input } from "stories/forms";
import { useStoreState  } from 'easy-peasy';
import { SelectMulti } from "stories/forms";
import { useAppContext }from "contexts/AppContext"
import Select from 'react-select';
import { sub } from "date-fns";
import {
    httpGetFiles,
  } from "services/files"


// import { on } from "gulp";


// const InvestmentTypeCateSelect = () => {
const FilesSelect = ({ label, name, setFileSelectValue=null, fileSelectValue, showAll,placeholder,reload, ...rest}) => {
    const { t } = useTranslation();

    const [files, setFiles] = useState([]);

    useEffect(() => {
            httpGetFiles().then((response) => {
                setFiles(response.data);
            }).catch((error) => {
                console.log(error);
            });
    }, [reload])   

    const handleSelectChange = (selectedOption) => {

        if (setFileSelectValue) {
            console.log("selectedOption", selectedOption)
        setFileSelectValue(selectedOption); // Set the selected value in the parent component

        //append the selected value to the array
        // setFileSelectValue([...fileSelectValue, selectedOption]);
        }
    };



    const getInputOptions = useCallback(() => {
        //if show all is true add all option
        let inputoptions = []
        if (showAll) {
            inputoptions = [{id: "", filename: t("Kaikki")}]
        }
        inputoptions = [...inputoptions, ...files]

        return inputoptions && inputoptions.map(obj => {
            return (
            {value:obj.id, label: obj.filename}
            )
        })
    }, [files])

    return (
        <>
        <Select 
        label={label || label === "" ? label : t("files_select_label", "Tiedostot")} 
        name={name} 
        options={getInputOptions()} 
        onChange={handleSelectChange}
        placeholder={placeholder?placeholder: t("file_select_placeholder", "Yhdistä ladattuihin tiedostoihin...")}
        value={fileSelectValue}
        // styles={{
        //     menuPortal: base => ({
        //       ...base,
        //       zIndex: 9999 // Set the desired z-index for the dropdown menu
        //     })
        //   }}
        {...rest} 
        />
        </>

  )
}

export default FilesSelect;


































// import React, { useEffect, useState, useCallback } from "react";
// import { useTranslation } from 'react-i18next';
// import { Input } from "stories/forms";
// import { useStoreState, useStoreActions } from 'easy-peasy';
// import {
//     httpGetFiles,
//   } from "services/files"

// const CurrencySelect = ({ label, name="currency", onChange,chose=true, value, ...rest }) => {
//   const { t } = useTranslation();
//   const companies = useStoreState((state) => state.companies?.companies)
//   const [files, setFiles] = useState([]);

//   useEffect(() => {
//     httpGetFiles().then((response) => {
//         console.log(response);
//         setFiles(response.data);
//     }).catch((error) => {
//         console.log(error);
//     });
//   }, [])
  
//   const getInputOptions = useCallback(() => {
//     return files && files.map(obj => {
//       return (
//         <option value={obj.id}>{obj.name}</option>
//       )
//     })
//   }, [files])
  
//   return (
//     <Input  label={label || label === "" ? label : t("company_select_label", "Yritys")} type="select" name={name} onChange={onChange} value={value} {...rest}>
//       {chose && <option value="" label={t("Valitse")}></option>}
//       {getInputOptions()}
//     </Input>
//   )
  
  
// }

// export default CurrencySelect;