import React, { useEffect, useState, useCallback } from "react";
import moment from "moment";
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Typography, Button, Link, IconButton, Modal,  } from "stories/components";


import { Container, Row, Col, Card, CardHeader, CardBody, List } from "stories/layout";
import { PageWrapper, PageHeader } from "components/Page"

import SubscriptionOptionsInfo from "components/Info/SubscriptionOptionsInfo.js"
import LicensePayment from "views/vismapay/LicensePayment/LicensePayment"



const UpdateUp = (props) => {
    const account = useStoreState((state) => state.account?.account);
    //use history to redirect to frontpage if no portfolio
    const history = useHistory();
    const { t } = useTranslation();

    const {redirectToFrontpage=false, closeParent, forceOpen=false} = props;

    // LICENSE and PAYMENT BEGIN
    const setSubscriptionFieldValue = (key, value) => {
        if (value) {
            setChosenLicenseId(value);
        }
    }

            
    const [chosenLicenseId, setChosenLicenseId] = useState()

    // Payment BEGIN
    const [licensePaymentModalOpen, setLicensePaymentModalOpen] = useState(false)

    const toggleLicensePaymentModal = () => {
    setLicensePaymentModalOpen(s => !s);
    }
    
    const openLicensePaymentModal = () => {
    setLicensePaymentModalOpen(true)
    }
    
    const resolveLicensePaymentModal = () => {
    setChosenLicenseId(null);
    setLicensePaymentModalOpen(false);
    }
    
    const rejectLicensePaymentModal = () => {
    setChosenLicenseId(null);
    setLicensePaymentModalOpen(false);
    redirectToFrontpage && history.push("/dashboard/frontpage")
    closeParent && closeParent()

    }
    
    useEffect(() => {
    if (chosenLicenseId && chosenLicenseId > 0) {
        openLicensePaymentModal()
    }
        
    }, [chosenLicenseId])

    // END
    

    // Subscription options start
    const [modalEditOpenSubscriptionOptions, setModalEditOpenSubscriptionOptions] = useState(false)
    const [modalEditIdSubscriptionOptions, setModalEditIdSubscriptionOptions] = useState()
    
    const toggleModalEditSubscriptionOptions = () => {
        setModalEditOpenSubscriptionOptions(s => !s);
    }
    
    const openModalEditSubscriptionOptions = (id) => {
        setModalEditOpenSubscriptionOptions(true)
        setModalEditIdSubscriptionOptions(id);
    }
    
    const modalEditResolveSubscriptionOptions = () => {
        setModalEditOpenSubscriptionOptions(false);
        setModalEditIdSubscriptionOptions(null);
    }
    
    const modalEditRejectSubscriptionOptions = () => {
        console.log("modalEditRejectSubscriptionOptions")
        setModalEditOpenSubscriptionOptions(false);
        setModalEditIdSubscriptionOptions(null);
        redirectToFrontpage && history.push("/dashboard/frontpage")
        closeParent && closeParent()
    }

    useEffect(() => {
        if (account?.license?.name == "Lite" || account?.license?.name == "Basic") {
          setModalEditOpenSubscriptionOptions(true)
        }
        else if (forceOpen == true) {
          setModalEditOpenSubscriptionOptions(true)
        } 
        else {
          setModalEditOpenSubscriptionOptions(false)
        }
    }, [account?.license])
    // END


    return (
        <>
    <Modal
        title={t("Derivative")}
        isOpen={modalEditOpenSubscriptionOptions}
        toggleModal={toggleModalEditSubscriptionOptions}
        size="modal-xl"
        showHeader={false}
        classNameBody="p-0 padding-0"
      >
        <SubscriptionOptionsInfo
          objectId={modalEditIdSubscriptionOptions}
          handleResolve={modalEditResolveSubscriptionOptions}
          handleReject={modalEditRejectSubscriptionOptions}
          custom_info_text= {t("subscription_options_info_text", "Tämä toiminto ei ole käytössä tilauksellanne")}
          setFieldValue={setSubscriptionFieldValue}
          currentLicenseId={account?.license?.id}
        />
      </Modal>
      <Modal
          isOpen={licensePaymentModalOpen}
          toggleModal={toggleLicensePaymentModal}
          size="modal-lg"
          showHeader={false}
        >
          <LicensePayment
            licenseId={chosenLicenseId}
            handleReject={rejectLicensePaymentModal}
            hasActiveLicense={true}
          />
          
        </Modal>
        </>
      );
    }
    
export default UpdateUp;
    