import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import { Input } from "stories/forms";
import { useAppContext } from "contexts/AppContext"

const OptionStateInput = ({label, name="state", onChange, value, allOption = true}) => {
  const { t } = useTranslation();
  const { enums } = useAppContext()
  
  /*
  const inputOptions = [
    {key: 2, value: t("Open")},
    {key: 3, value: t("Exercised")},
    {key: 4, value: t("Expired")},
  ]
  */
  
  const getInputOptions = useCallback(() => {
    const inputOptions = enums?.options?.option?.option_state;
    return inputOptions && inputOptions.map(obj => {
      return (
        <option value={obj.key}>{obj.value}</option>
      )
    })
  }, [enums])
  
  return (
    <Input label={label ? label : t("option_state_input_label", "State")} type="select" name={name} onChange={onChange} value={value}>
      { allOption && (<option value="">{t("all_option", "All")}</option>)}
      {getInputOptions()}
    </Input>
  )
  
  
}

export default OptionStateInput;
