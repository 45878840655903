import React, { useEffect, useCallback, useState } from "react";
import { withFormik } from 'formik';
import * as Yup from "yup";
import { useTranslation } from 'react-i18next';
import { useAuthContext } from "contexts/AuthContext";

// core components
import { Input } from "stories/forms";
import { Button, Link, Typography } from "stories/components"
import { Card, CardBody, CardHeader, Col, Container, Row } from "stories/layout"
import { useNotification } from "stories/components/Notification"

import { httpSendPasswordResetConfirmation } from "services/users"

const PasswordForgot = (props) => {

  const {
     values,
     touched,
     errors,
     handleChange,
     handleBlur,
     handleSubmit,
     isValid,
     isSubmitting,
     validateForm,
   } = props;
   
   const {
      history,
      t
   } = props;
   
   const getFormikInputProps = useCallback(
    name => ({
      name,
      value: values[name],
      error: Boolean(touched[name] && errors[name]),
      errorText: touched[name] ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange
    }),
    [values, errors, touched, handleBlur, handleChange]
  );
  
  const handleCancel = () => {
    history.push("/");
  }
  
  return (
    <>
      <Container className="mt-4 pb-5">
        <Row className="justify-content-center">
          <Col lg="6" md="7" className="">
            <Card>
              <CardHeader className=" pt-5 pb-1">
                
                <div className="text-center text-muted mb-4">
                  <Typography variant="h2" className="mb-2 ">{t("password_forgot_title","Unohtunut salasana")}</Typography>
                  <Typography variant="p"></Typography>
                </div>
              
              </CardHeader>
              <CardBody className=" px-lg-5 py-lg-5">
                
                <Row>
                  <Col className="mb-3">
                    <Input
                      {...getFormikInputProps("email")}
                      label={t("password_forgot_email","Sähköposti")}
                      type="email"
                      required
                    />
                  </Col>
                </Row>
                
                <div className="text-center text-muted my-4">
                  <Button variant="secondary" disabled={isSubmitting} onClick={() => validateForm().then(() => handleSubmit())}>{t("password_forgot_submit","Lähetä")}</Button>
                  { Boolean(errors["general"]) && <Typography className="text-danger">{errors["general"]}</Typography> }
                </div>
                
                <Row>
                  <Col className="mb-3 text-center">
                    <Typography variant="small"><Link className="underline-text" to="/auth/login">{t("password_forgot_back_to_login","Takaisin kirjautumiseen")}</Link></Typography>
                  </Col>
                </Row>
                
              </CardBody>
            </Card>

          </Col>
        </Row>
      </Container>
    </>
  );
}

const defaultValues = {
  email: '',
}

 const PasswordForgotFormik = withFormik({
    
    validateOnMount: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return defaultValues
      }
    },
    validationSchema: props => {
      const {t} = props;
      const required = t("required_field","Kenttä on pakollinen");
      
      return Yup.object().shape({
        email: Yup.string().email(t('validation_error_email','Tarkista sähköpostiosoitteen muoto')).required(required),
      });
    },
    handleSubmit: (values, { setSubmitting, setErrors, props }) => {
      const {t} = props;
      const data = {
        // ...values,
        email: values?.email,
      }
      httpSendPasswordResetConfirmation(data).then(res => {
        setSubmitting(false);
        if (res?.status == 200) {
          props.handleResolve()
        }
        else {
          if (res?.data?.code == "user_not_found"){
            setErrors({
              general: t(res?.data?.detail),
            })
          }
          else {
            setErrors({
            general: `${JSON.stringify(res?.data)}`,
          })
          }
          
        }
        
      }, error => {
        setSubmitting(false);
        setErrors({
          general: `${t('unknown_error','Virhe. Ota tarvittaessa yhteys ylläpitoon.')} ${JSON.stringify(error)}`,
        })
      })
    },
    displayName: "BasicForm"
  
  })(PasswordForgot)
    
    
const PasswordForgotView = ({match, history}) => {
  const { t } = useTranslation();
  const { notify } = useNotification();
  const [infoAlert, setInfoAlert] = useState();
  const { getCurrentLanguage } = useAuthContext(); 
  
  useEffect(() => {
    console.log("getCurrentLanguage", getCurrentLanguage());
  }, [])
  
  const handleModalConfirm = () => {
    setInfoAlert(null);
    history.push("/dashboard/users");
  }
  
  const handleResolve = () => {
    notify({ title:t("User","Käyttäjä"), message:t("password_forgot_sent_ok","Salasanan palautuslinkki on lähetetty sähköpostiisi")})
    handleModalConfirm();
  }
  
  return (
    <>
      <PasswordForgotFormik 
        history={history} 
        t={t} 
        handleResolve={handleResolve} 
        notify={notify} 
      />
      {infoAlert}
    </>
  )
}
  

export default PasswordForgotView;
