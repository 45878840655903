import React, { useState, useCallback, useEffect } from "react";
import { withFormik } from 'formik';
import * as Yup from "yup";
import moment from "moment"
import { useTranslation } from 'react-i18next';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { round ,roundScaleDecimals} from "services/helpers"

import { Button, Typography, Spinner, Popover,  } from "stories/components";
import { Container, Row, Col, FormContainer, Card, CardBody } from "stories/layout";
import { Input, SelectMulti, Toggle, Radio, DatePicker, Checkbox } from "stories/forms";
import { useNotification } from "stories/components/Notification"
import { httpGetSplits } from "services/splits"



import { 
  httpGetPrivateInvestimentFundData, 
  httpCreatePrivateInvestimentFundData, 
  httpUpdatePrivateInvestimentFundData, 
  httpDeletePrivateInvestimentFundData 
} from "services/private_investment_funds";

import CurrencySelect from "components/Forms/CurrencySelect/CurrencySelect"

const defaultValues = {
  id: null,
  name: "",
}

const PrivateInvestmentFundRateEdit = (props) => {
  
  const {
     values,
     touched,
     setTouched,
     errors,
     setErrors,
     handleChange,
     handleBlur,
     handleSubmit,
     setFieldValue,
     isValid,
     isTouched,
     isSubmitting,
     validateForm,
     preSetValues
   } = props;
   
   const {
      t,
      history,
      objectId,
      privateStockName,
      splits,
      account
   } = props;
   
   const getFormikInputProps = useCallback(
    name => ({
      name,
      value: values[name],
      error: Boolean(touched[name] && errors[name]),
      errorText: touched[name] ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange
    }),
    [values, errors, touched, handleBlur, handleChange]
  );
  
  const onSubmit = (e) => {
    e.preventDefault();
    validateForm().then(res => {
      if (Object.keys(res).length === 0) {
        handleSubmit()
      }
      else {
        setTouched(res);
        setErrors({
          ...res,
          general: t("Please check the form errors", "Tarkista lomakkeen virheet")
        })
      }
    })
  }

  useEffect(() => {
    let factor = 1
    if (splits.length > 0){
      splits.map((split) => {
        console.log(split.id)
        console.log(split.dividend, "dividend")
      })
      const filteredSplits = splits.filter((split) => {
        const splitDate = new Date(split.date_effective).setHours(0, 0, 0, 0);
        console.log(splitDate, "split")
        const stockDate = new Date(values?.date).setHours(0, 0, 0, 0);
        console.log(stockDate, "datepicker")
        if (splitDate > stockDate){
          factor =  factor * Number(split.dividend)
        }
        return splitDate > stockDate;
      });
    }
    setFieldValue("factor", factor)

  }, [values?.date])



  const handlePriceTodayPerspectiveChange = ({target}) => {
    setFieldValue("price_today_perspective", target.value, false)

    // let factor = 1
    // const filteredSplits = splits.filter((split) => {
    //   const splitDate = new Date(split.date_effective);
    //   const stockDate = new Date(values?.date);
    //   if (splitDate > stockDate){
    //     factor *= Number(split.dividend)
    //   }
    //   return splitDate > stockDate;
    // });
    
    setFieldValue("price", roundScaleDecimals((target.value * values?.factor), 4))
    // setFieldValue("factor", factor)

  }
  
  const handleCancel = () => {
    props.handleReject()
  }
  
  const handleDelete = () => {
    httpDeletePrivateInvestimentFundData(objectId).then(() => {
      props.handleResolve()
    })
  }
  
  return (
    <>
      <Row>
        <Col className="mb-3">
        { privateStockName }
        </Col>
      </Row>
      <Row>
        <Col className="mb-1">
          <DatePicker
            {...getFormikInputProps("date")}
            label={t("privateInvestmentFund_rate_edit", "Kurssi päivä")}
            className="form-control"
            selected={values?.date}
            name="date"
            onChange={(date) => setFieldValue("date", date)}
            showYearDropdown
            dateFormat="d.M.yyyy"
            value={values?.date}
          />
        </Col>
      </Row>
      { values?.date && splits && splits.length > 0 && values?.factor != 1 &&
        <Row className="row justify-content-end">
        <Col  className="mb-3">
          <Typography variant="small">{ t("privateInvestmentFund_edit_price_info_text_1","Kurssipäivän jälkeisten splittien kokonais suhdeluku on ")} {values?.factor}</Typography>
        </Col>
      </Row>
      }
      {
        splits.length > 0 && values?.factor != 1 &&
        <>
          <Row className="mt-3">
            <Col className="mb-1">
              <Input
                {...getFormikInputProps("price_today_perspective")}
                label={t("privateInvestmentFund_edit_splitted_price", "Splitattu hinta")}
                onChange={handlePriceTodayPerspectiveChange}
                type="number"
                min={0}
                step={"0.0001" }
                onWheel={(e) => e.target.blur()}
                placeholder={t("0.50")}
              />
            </Col>

          </Row>
          <Row className="row justify-content-end">
            <Col  className="mb-3">
              <Typography variant="small">{ t("privateInvestmentFund_edit_price_info_text_2","Mikäli kurssi päivän jälkeen on tullut splittejä ja olet käynyt hakemassa tänään kurssin joltain sivustolta, on se jo todennäköisesti splitattu. Syötä sivustolta hakemasi kurssi tähän ja järjestelmä peruuttaa splittauksen tallentamiesi splittien mukaan alla olevaan hinta-kenttään.")}</Typography>
            </Col>
          </Row>
        </>
      }

      <Row>
        <Col className="mb-1">
          <Input
            {...getFormikInputProps("price")}
            label={t("privateInvestmentFund_edit_price", "Hinta")}
            type="number"
            min={0}
            step={"0.0001" }
            onWheel={(e) => e.target.blur()}
            placeholder={t("1.00")}
          />
        </Col>
      </Row>
      <Row className="row justify-content-end">
        <Col  className="mb-3">
          <Typography variant="small">{ t("privateInvestmentFund_edit_price_info_text_3", "Tähän tulee antaa hinta joka osakkeella oli todellisuudessa ko päivänä. Esim jos meillä olisi sanomalehti tuolta ajalta ja siellä palsta osakkeiden päivän hinnoista.")}</Typography>
        </Col>
      </Row >
      {account?.show_relation_to_security && 
      <Row className="mb-4">
                  <Col  className="my-3">
                  <div style={{position: 'absolute', zIndex: "0 !important"}}>
                  <Checkbox 
                  id="WAC"
                  name="accounting_price_boolean"
                  checked={values?.accounting_price_boolean}
                  onChange={(e) => {setFieldValue("accounting_price_boolean",e.target.checked)}}
                  value="agree_terms"
                  required
                  errorText={touched["agree_terms"] ? errors["agree_terms"] : ""}
                  label={<>{t("privateInvestmentFund_edit_accounting",'Kirjanpitoarvo')}</>}
                  labelFirst={true}
                  className="zIndexZero"
                  />
                  </div>
      
                  </Col>
              </Row>
      }
      {account?.show_relation_to_security && values?.accounting_price_boolean &&
            <Row className="mb-3">
        <Col className="mb-1">
          <DatePicker
            {...getFormikInputProps("accounting_price_date")}
            label={t("privateInvestmentFund_accounting_date", "Kirjanpitoarvo (saakka)")}
            className="form-control"
            selected={values?.accounting_price_date}
            name="accounting_price_date"
            onChange={(date) => setFieldValue("accounting_price_date", date)}
            showYearDropdown
            dateFormat="d.M.yyyy"
            value={values?.accounting_price_date}
            />
        </Col>
      </Row>
      }

      <Button color="warning" id="info" type="button" size="sm" className="mb-3">
              <i className="fas fa-info-circle fa-lg"/>
            </Button>
            <Popover
              placement="top"
              target="info"
            >       
            <Container fluid style={{maxWidth:"300px"}} >
              <Row>
                <Col className="mb-3">
                  <Typography className="text-warning" variant="medium">
                    {t("privateInvestmentFund_edit_price_info_text_4","Huom! Mikäli lisäät kirjauksia historiaan varmista onko kurssi päivän jälkeen tullut splittejä ja onko käyttämäsi kurssin hinta jo splitattu. Todennäköisesti on. Hinta kenttään tulee antaa hinta, joka osakkeella oli ko päivänä, eli sitä ei saa olla splitattu päiväyksen jälkeisellä splitin suhdeluvulla.")}
                  </Typography>
                </Col>
              </Row>
              <Row>
                <Col className="mb-3">
                  <Typography className="text-warning" variant="medium">
                    {t("privateInvestmentFund_edit_price_info_text_5","Lisää mahdolliset uudet splitit järjestelmään ensin ja vasta sen jälkeen lisää kurssikirjaukset splittiä edeltävään aikaan.")}
                  </Typography>
                </Col>
              </Row>

            </Container>
            </Popover>
      <Row>
        <Col className="mb-3">
          <Button onClick={handleCancel} variant="default">{t("Close","Sulje")}</Button>
        </Col>
        { values?.id ? (
        <Col className="mb-3 text-center">
          <Button onClick={handleDelete} variant="danger">{t("Delete","Poista")}</Button>
        </Col>
        ) : null }
        <Col className="mb-3 text-right">

            <Button variant="secondary" disabled={isSubmitting} onClick={onSubmit}>
              {t("Save","Tallenna")}
            </Button>
          
          { Boolean(errors["general"]) && <Typography className="text-danger">{errors["general"]}</Typography> }
        </Col>
      </Row>
        
    </>
  );
}

const PrivateStockRateEditFormik = withFormik({
    enableReinitialize: true,
    validateOnMount: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return {
          ...defaultValues
        }
      }
    },
    validationSchema: props => {
      const {t} = props;
      const required = t("The field is required");
      return Yup.object().shape({
        date: Yup.string().required(required).nullable(),
      });
    },
    handleSubmit: (values, { setSubmitting, setErrors, props }) => {
      const {t, privateInvestmentFund} = props;
      
      const values_price = values?.price ? parseFloat(values?.price.toString().replace(",",".")) : 0;
      const values_updated_price = values?.price_today_perspective ? parseFloat(values?.price_today_perspective.toString().replace(",",".")) : null;
      let data = {
        ...values,
        price: values_price,
        private_investment_fund: privateInvestmentFund,
        updated_price: values_updated_price
      };
      const oldDate = data.date;
      const newDate = new Date(oldDate.getFullYear(), oldDate.getMonth(), oldDate.getDate(), 12, 0, 0);
      const formattedDate = newDate.toISOString().split('T')[0] +" 12:00:00Z";;
      data.date = formattedDate;

      const oldAccountingDate = data.accounting_price_date;
      if (oldAccountingDate){
      const newAccountingDate = new Date(oldAccountingDate.getFullYear(), oldAccountingDate.getMonth(), oldAccountingDate.getDate(), 12, 0, 0);
      const formattedAccountingDate = newAccountingDate.toISOString().split('T')[0] +" 12:00:00Z";;
      data.accounting_price_date = formattedAccountingDate;
      }
      if (data.accounting_price_boolean == false){
        data.accounting_price_date = null
      }
      if (data.accounting_price_date == null || data.accounting_price_date == "Invalid Date"){
        data.accounting_price_boolean = false
      }

      if (values?.id) {
        if (values?.factor == 1){
          data.updated_price = values_price
        }
        httpUpdatePrivateInvestimentFundData(values.id, data).then(res => {
          setSubmitting(false);
          props.notify({ title:t("Kurssi"), message:t("Updated")})
          props.handleResolve()
          
        }, error => {
          setSubmitting(false);
          if (error?.status === 400) {
            setErrors(error?.data)
          }
          else if (error?.data?.detail) {
            setErrors({
              general: error?.data?.detail,
            })
          }
          else if (error?.data?.general) {
            setErrors({
              general: error?.data?.detail,
            })
          }
          else {
            setErrors({
              general: t("Unknown error. Please try again later or contact support.")
            })
          }
        })
      }
      else {

        httpCreatePrivateInvestimentFundData(data).then(res => {
          setSubmitting(false);
          props.notify({ title:t("Kurssi"), message:t("Created")})
          props.handleResolve()
          
        }, error => {
          setSubmitting(false);
          if (error?.status === 400) {
            setErrors(error?.data)
          }
          else if (error?.data?.detail) {
            setErrors({
              general: error?.data?.detail,
            })
          }
          else if (error?.data?.general) {
            setErrors({
              general: error?.data?.detail,
            })
          }
          else {
            setErrors({
              general: t("Unknown error. Please try again later or contact support.")
            })
          }
        })
      }
      
      
    },
    displayName: "BasicForm"
  
})(PrivateInvestmentFundRateEdit)
    
    
const PrivateStockRateEditView = ({history, objectId,splits, modalOpen, privateStockName="", ...rest}) => {
  // API requests here
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [preSetValues, setPreSetValues] = useState();
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  const account = useStoreState((state) => state.account?.account);
  
  const getObject = (id) => {
    
    setLoading(true)
    
    httpGetPrivateInvestimentFundData(id).then(res => {
      
      setPreSetValues({
        ...defaultValues,
        ...res?.data,
        date: res?.data?.date ? moment(res?.data?.date).toDate() : null,
        price_today_perspective: res?.data?.updated_price,
        accounting_price_date: res?.data?.accounting_price_date ? moment(res?.data?.accounting_price_date).toDate() : null,


      });
    
    }).finally(response => {
      setLoading(false);
    })
    
  }
  

  
  useEffect(() => {
    if (objectId && parseInt(objectId) > -1) {
      getObject(objectId)
    }
  }, [objectId, modalOpen])
  
  
  if (loading) {
    return (
      <Container fluid>
        <Spinner />
      </Container>
    )
  }

  return (
    <PrivateStockRateEditFormik 
      t={t} 
      history={history} 
      preSetValues={preSetValues} 
      notify={notify} 
      objectId={objectId}
      account={account}
      privateStockName={privateStockName}
      splits= {splits}
      {...rest} 
    />
  )
  
}

export default PrivateStockRateEditView;
