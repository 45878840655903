import React, { useState, useCallback, useEffect } from "react";
import { withFormik } from 'formik';
import * as Yup from "yup";
import moment from "moment"
import { useTranslation } from 'react-i18next';
import { useStoreState, useStoreActions } from 'easy-peasy';

import { Button, Typography, Spinner } from "stories/components";
import { Container, Row, Col, FormContainer, Card, CardBody, CardHeader } from "stories/layout";
import { Input, SelectMulti, Toggle, Radio, Checkbox } from "stories/forms";
import { useNotification } from "stories/components/Notification"
import SectorSelect from "components/Forms/SectorSelect/SectorSelect"
import { useAppContext } from "contexts/AppContext"



// import { httpGetCompany, httpCreateCompany, httpUpdateCompany, httpDeleteCompany } from "services/companies"
import { httpCreateAccountSecurityRelation, httpUpdateAccountSecurityRelation, httpGetAccountSecurityRelation } from "services/accounts"

import CurrencySelect from "components/Forms/CurrencySelect/CurrencySelect"

const defaultValues = {
  id: null,
  name: "",
}

const AccountSecurityRelationEdit = (props) => {
  
  const {
     values,
     touched,
     setTouched,
     errors,
     setErrors,
     handleChange,
     handleBlur,
     handleSubmit,
     setFieldValue,
     isValid,
     isTouched,
     isSubmitting,
     validateForm,
     preSetValues
   } = props;
   
   const {
      t,
      history,
      objectId,
      extraMargin,
      showExtraField,
      setShowExtraField,
      account,
      setSelectedSector,
      selectedSector,
   } = props;
   
   const getFormikInputProps = useCallback(
    name => ({
      name,
      value: values[name],
      error: Boolean(touched[name] && errors[name]),
      errorText: touched[name] ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange
    }),
    [values, errors, touched, handleBlur, handleChange]
  );


  
  const onSubmit = (e) => {
    e.preventDefault();
    validateForm().then(res => {
      if (Object.keys(res).length === 0) {
        handleSubmit()
      }
      else {
        setTouched(res);
        setErrors({
          ...res,
          general: t("Please check the form errors", "Tarkista lomakkeen virheet")
        })
      }
    })
  }
  
  const handleCancel = () => {
    props.handleReject()
  }
  
//   const handleDelete = () => {
//     httpDeleteCompany(objectId).then(() => {
//         props.handleResolve()
//         props.notify({ title:t("Company"), message:t("Poistettu")})
//     })
//   }

  const handleExtraFieldChange = (event) => {
    setShowExtraField(event.target.checked)
  }

  const handleSectorChange = (name, value) => {
    setSelectedSector(s => ({
      ...s,
      [name]: value
    }))
  }

  const getSectorSelect = useCallback(() => {
    return (
      <SectorSelect
        {...getFormikInputProps("sector_select")}
        label=" "
        multiple={false}
        onChange={handleSectorChange}
        value={selectedSector?.sector_select}
        setFieldValue={handleSectorChange}
      />
    )
  }, [selectedSector])

  
  return (
    <div className={extraMargin?"my-5":null}>
      {/* <Row>
        <Col className="mb-3">
          <Input
            {...getFormikInputProps("name")}
            label={t("company_edit_name", "Nimi")}
            required
          />
        </Col>
      </Row>
      
      <Row>
        <Col className="mb-3">
          <Input 
            {...getFormikInputProps("business_identity_code")} 
            label={t("company_edit_identity_code", "Y-tunnus")}
            required
          />
        </Col>
      </Row>
      
      
      <Row>
        <Col className="mb-3">
          <Button onClick={handleCancel} variant="default">{t("Close","Sulje")}</Button>
        </Col>
        { values?.id ? (
        <Col className="mb-3 text-center">
         {  <Button onClick={handleDelete} disabled={false} variant="danger">{t("Delete","Poista")}</Button>}
        </Col>
        ) : null }
        <Col className="mb-3 text-right">

            <Button variant="secondary" disabled={isSubmitting} onClick={onSubmit}>
              {t("Save","Tallenna")}
            </Button>
          
          { Boolean(errors["general"]) && <Typography className="text-danger">{errors["general"]}</Typography> }
        </Col>
      </Row> */}



      {account.show_external_system_settings &&  (
        <>
        {  !showExtraField &&
        
              <div>
        <div className=" mb-3">
            <Checkbox 
              id={"showExtraField"}
              name="showExtraField"
              checked={showExtraField}
              onChange={(event) => handleExtraFieldChange(event)}
              value="showExtraField"
              className="zIndexZero"
              required
              label={<>{t("account_security_edit_extra_info", 'Lisäaseutkset')}</>}
            />
          </div>
      </div>
        }
        <>
        { (showExtraField ) && 
          <>
            <Card className="shadow-sm-strong  h-100 bg-secondary">
            <CardHeader className="border-bottom bg-secondary">
            <div className="">
                <div className=" mb-0 ">
                    <Checkbox 
                    id={"showExtraField"}
                    name="showExtraField"
                    checked={showExtraField}
                    onChange={(event) => handleExtraFieldChange(event)}
                    value="showExtraField"
                    className="zIndexZero"
                    required
                    label={<>{t("account_security_edit_extra_info", 'Lisäaseutkset')}</>}
                    />
                </div>
            </div>
            </CardHeader>
            <CardBody>

            

          {/* <Row className="mb-0">
              <Col sm={12} lg={4} className="d-flex align-items-center">
              <label className="mb-1 pr-1 header">{t("portfolio_edit_external_system_name_1", "Ulkoinen järjestelmä")}</label>
              </Col>
              <Col>
              <Input
                  name="increase_value"
                  {...getFormikInputProps("external_first_system_name")} 
                  label=""
              />
              </Col>
          </Row> */}

          <Row className="mb-1 mt-3">
              <Col sm={12} lg={4} className="d-flex align-items-center">
              <label className="mb-1 pr-1 header">{t("portfolio_edit_external_system_id_1", "Dimension")}</label>
              </Col>
              <Col >
              <Input
                  {...getFormikInputProps("dimension")} 
                  label=""
                  placeholder={t("dimension")}
              />
              </Col>
          </Row>
          <Row className="mb-1 mt-3">
              <Col sm={12} lg={4} className="d-flex align-items-center">
                <label className="mb-1 pr-1 header">{t("account_security_edit_sector", "Toimiala")}</label>
              </Col>
              <Col >
                {getSectorSelect()}
              </Col>
          </Row>


          {/* <Row className="mt-3">
              <Col sm={12} lg={4} className="d-flex align-items-center">
              <label className="mb-1 pr-1 header">{t("portfolio_edit_external_system_1_export", "Vie tiedot")}</label>
              </Col>
              <Col>
                <Checkbox 
                  id={"export_external_first_system"}
                  {...getFormikInputProps("export_external_first_system")}
                  value= {values?.export_external_first_system}
                  checked={values?.export_external_first_system}
                  className="zIndexZero"
                  required
                  label={<>{t("portfolio_edit_extra_info", '')}</>}
                />
                </Col>
          </Row> */}
          <Row className="row justify-content-end mt-3">
            <Col lg={8} md={12} className="mb-2">
              {/* <Typography variant="small">{t("portfolio_edit_external_system_name_1_info","Ulkoisen järjestelmän nimi ja id")}</Typography> */}
            </Col>
          </Row>
          <Row>


        <Col className="mb-0 text-right">

            <Button variant="default" disabled={isSubmitting} onClick={onSubmit}>
              {t("Save","Tallenna")}
            </Button>
          
          { Boolean(errors["general"]) && <Typography className="text-danger">{errors["general"]}</Typography> }
        </Col>
      </Row> 
      </CardBody>

</Card>
          </>
        }
        </>
        </>
        )
      }
        
    </div>
  );
}

const AccountSecurityRelationEditFormik = withFormik({
    enableReinitialize: true,
    validateOnMount: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return {
          ...defaultValues
        }
      }
    },
    validationSchema: props => {
      const {t} = props;
      const required = t("The field is required");
      const specialCharRegex = /^[a-zA-Z0-9\s]+$/; // Tämä sallii vain kirjaimet, numerot ja välilyönnit
      return Yup.object().shape({
        // external_first_system_name: Yup.string().required(required).nullable(),
        dimension: Yup.string()
          .matches(specialCharRegex, t("account_security_dimension","Dimension ei saa sisältää erikoismerkkejä"))
          .nullable(),
      });
    },
    handleSubmit: (values, { setSubmitting, setErrors,  props }) => {
      const {t, portfolio, account, related_model_type, selectedSector, related_model_id, setRelationId, relationId} = props;
      console.log("account", account);

      let data = {
        ...values,
        related_model_type: related_model_type,
        related_model_id: related_model_id,
        general_sector: selectedSector?.sector_select?.id,
        dimension: values?.dimension || null
        // account: account?.id,
      };
      
      if (values?.id || relationId) {
        httpUpdateAccountSecurityRelation(account.id, data).then(res => {
          setSubmitting(false);
          props.notify({ title:t(""), message:t("Updated")})
        //   props.handleResolve()
          
        }, error => {
          setSubmitting(false);
          if (error?.data?.detail || error?.data?.general) {
            setErrors({
              general: error?.data?.detail,
            })
          }
          else {
            setErrors({
              general: t("Unknown error. Please try again later or contact support.")
            })
          }
        })
      }
      else {
        httpCreateAccountSecurityRelation(account.id, data).then(res => {
          setSubmitting(false);
          props.notify({ title:t(""), message:t("Updated")})
          // props.handleResolve()
          setRelationId(res?.data?.message?.id)
        }, error => {
          setSubmitting(false);
          if (error?.data?.detail) {
            setErrors({
              general: error?.data?.detail,
            })
          }
          else {
            setErrors({
              general: t("Unknown error. Please try again later or contact support.")
            })
          }
        })
      }
      
      
    },
    displayName: "BasicForm"
  
})(AccountSecurityRelationEdit)
    
    
const AccountSecurityRelationEditView = ({history, objectId,related_model_type, related_model_id, modalOpen,extraMargin, ...rest}) => {
  // API requests here
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [preSetValues, setPreSetValues] = useState();
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  const account = useStoreState((state) => state.account?.account);
  const [showExtraField, setShowExtraField] = useState(false);
  const [selectedSector, setSelectedSector] = useState({sector_select: []});
  const { getSectorName } = useAppContext()
  const [relationId, setRelationId] = useState()



  
  const getObject = (id) => {
    
    setLoading(true)
    const params = {related_model_id: related_model_id, related_model_type: related_model_type}
    
    httpGetAccountSecurityRelation(account.id, params).then(res => {
      
      setPreSetValues({
        ...defaultValues,
        ...res?.data,
      });
      let sector = null
      if (res?.data?.private_sector){
        //add 1_ to private_sector id like 1_2322
        sector =`2_${res?.data?.private_sector}`
      }
      if (res?.data?.public_sector){
        sector =`1_${res?.data?.public_sector}`
      }
      setSelectedSector({
        sector_select: sector ? [{id: sector, label: getSectorName(sector), name:getSectorName(sector) }] : []
      })
    
    }).finally(response => {
      setLoading(false);
    })
    
  }
  
  
  useEffect(() => {
    if (objectId && parseInt(objectId) > -1) {
      getObject(objectId)
    }
  }, [objectId, modalOpen])
  
  
  if (loading) {
    return (
      <Container fluid>
        <Spinner />
      </Container>
    )
  }

  return (
    <AccountSecurityRelationEditFormik
      t={t} 
      history={history} 
      preSetValues={preSetValues} 
      notify={notify} 
      objectId={objectId}
      account={account}
      extraMargin={extraMargin}
      showExtraField={showExtraField}
      setShowExtraField={setShowExtraField}
      related_model_type={related_model_type}
        related_model_id={related_model_id}
      setSelectedSector={setSelectedSector}
      selectedSector={selectedSector}
      setRelationId={setRelationId}
      relationId={relationId}
      {...rest} 
    />
  )
  
}

export default AccountSecurityRelationEditView;
