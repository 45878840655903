// import http, { authHeader } from './index'
import http from './api'

const url = '/version/'

export const httpGetVersion = () => {
  console.log("httpGetVersion");
  return http({
    url: `${url}`,
    method: 'GET',
  })
}