import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import { Input } from "stories/forms";
import { useStoreState  } from 'easy-peasy';
import { SelectMulti } from "stories/forms";
import { Button, Typography, Spinner } from "stories/components";
import moment from "moment";



const BatchSelect = ({ label, name="batch_select",multiple=false,placeholder,disabled, showAll=false,batchRatio, onChange, setFieldValue,validateForm, batchs, showAllOption, value, ...rest}) => {
  const { t } = useTranslation();
  const portfolios = useStoreState((state) => state.portfolio?.portfolios);

  const getOriginalBalance = (id) => {
    //loop all batchRatio and find the one with the same id
    let balance = "--"
    batchRatio[0]?.id && batchRatio.map(obj => {
      if (obj.id === id) {
        balance = obj.original_batch_balance;
        balance = obj.batch_balance;
      }
    })
    return balance;
  }

  const getBalance = (id) => {
    //loop all batchRatio and find the one with the same id
    let balance = 0
    batchRatio[0]?.id && batchRatio.map(obj => {
      if (obj.id === id) {
        balance = obj.batch_balance;
      }
    })
    return balance;
  }
  
  const getInputOptions = useCallback(() => {
    return batchs && batchs.map(obj => {
      if(getBalance(obj.id) == 0 ){
        return
      }
      return  (
        
        <option key={obj.id} value={obj.id}>#{obj.id} -  {obj?.related_model.name} - {getOriginalBalance(obj.id)}kpl     {` (${ moment(obj.date_transaction).format('DD.MM.YYYY')})`}</option>
      )
    })
  }, [batchs, batchRatio])

  const batchExists = useCallback(() => {
    let count = 0
    batchs && batchs.map(obj => {
      if(getBalance(obj.id) > 0 ){
        count ++
      }
    })
    return count
  }, [batchs, batchRatio])


  const getSelectedOptions = useCallback(() => {
    if (multiple === false) {
      return [value];
    }
    return value && value.map(obj => {
      // console.log(obj)
      return {value: obj.id, label: obj.value}
    })
  }, [value])

  const getOptions = useCallback(() => {

    const filteredBatchs = batchs.filter(obj => getBalance(obj.id) > 0)

    return filteredBatchs && filteredBatchs.map(obj => {
      // return {value: obj.id, label: obj.name}

      const customLabel = `# ${obj.id}  - ${getOriginalBalance(obj.id)}kpl     (${ moment(obj.date_transaction).format('DD.MM.YYYY')})`
      return {value: obj.id, label: customLabel}
    }
    )

    // return batchs && batchs.map(obj => {
    //   // return {value: obj.id, label: obj.name}
      
    //   const customLabel = `#${obj.id} - ${obj?.related_model.name} - ${getOriginalBalance(obj.id)}kpl     (${ moment(obj.date_transaction).format('DD.MM.YYYY')})`
    //   return {value: obj.id, label: customLabel}
    // })
  }, [batchs, batchRatio])



  const getFirstInputOption = useCallback(() => {

    return (
      <option value="" label={batchExists() > 0?t("Valitse..."):t("Ei saatavilla")}></option>
    )
}, [batchs])


  return (
    <>
      
      {multiple
        ? <SelectMulti
            isMulti={true}
            label={label ? label : t("batch_select_labsel", "erät")}
            name={name}
            value={value}
            // setFieldValue={setFieldValue}
            setFieldValue={setFieldValue}
            defaultValues={getSelectedOptions()}
            options={getOptions()}
            showAllOption={showAllOption} 
            validateForm={validateForm}
            placeholder={placeholder}
            disabled={disabled}
            {...rest}
          />
        : <Input 
            label={label ? label : t("batch_select_label", "erät")} 
            type="select" 
            name={name} 
            onChange={onChange} 
            value={value} 
            {...rest}
            multiple={multiple}
            disabled={disabled}
          >
            {showAll ? <option value="" label={t("Kaikki")}></option>: null}
            {getFirstInputOption()}

            {getInputOptions()}
          </Input>
      }
    </>
  )


  
  
}

export default BatchSelect;


// isMulti={isMulti} 
// label={t("home_type", "Home Type")} 
// name={name} 
// value={value} 
// setFieldValue={setFieldValue} 
// defaultValues={getSelectedOptions()} 
// options={getOptions()} 
// validateForm={validateForm}