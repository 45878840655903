import http from './api'

export const httpGetPortfolios = (params) => {
  console.log("httpGetPortfolios");
  return http({
    url: `/portfolios/portfolios/` ,
    method: 'GET',
    params,
  })
}

export const httpGetPortfolio = (id) => {
  console.log("httpGetPortfolio");
  return http({
    url: `/portfolios/portfolios/${id}/` ,
    method: 'GET',
  })
}

export const httpCreatePortfolio = (data) => {
  console.log("httpCreatePortfolio");
  return http({
    url: `/portfolios/portfolios/` ,
    method: 'POST',
    data,
  })
}

export const httpUpdatePortfolio = (id, data) => {
  console.log("httpUpdatePortfolio");
  return http({
    url: `/portfolios/portfolios/${id}/` ,
    method: 'PATCH',
    data,
  })
}

export const httpDeletePortfolio = (id) => {
  console.log("httpDeletePortfolio");
  return http({
    url: `/portfolios/portfolios/${id}/` ,
    method: 'DELETE',
  })
}

// Specials

export const httpGetPortfolioStocksList = (id) => {
  console.log("httpGetPortfolioStocksList");
  return http({
    url: `/portfolios/portfolios/${id}/get_portfolio_stocks_list/` ,
    method: 'GET',
  })
}

export const httpGetPortfoliosUnmadeSplitTransactions = (params) => {
  console.log("httpGetPortfolios/UnmadeSplitTransactions");
  return http({
    url: `/portfolios/portfolios/get_portfolios_unmade_split_transactions/` ,
    method: 'GET',
    params,
  })
}

export const httpGetPortfoliosUnmadeDividendTransactions = (params) => {
  console.log("httpGetPortfolios/UnmadeSplitTransactions");
  return http({
    url: `/portfolios/portfolios/get_portfolios_unmade_dividend_transactions/` ,
    method: 'GET',
    params,
  })
}

export const httpGetPortfolioSummary = (params) => {
  console.log("httpGetPortfolioSummary");
  return http({
    url: `/transactions/get_portfolio_summary/` ,
    method: 'GET',
    params,
  })
}

export const httpGetPortfolioSummaryExcel = (params) => {
  console.log("httpGetPortfolioSummaryExcel");
  return http({
    url: `/transactions/get_portfolio_summary/` ,
    method: 'GET',
    responseType: 'arraybuffer',
    params
  })
}


export const httpGetPortfolioSummaryTable = (params) => {
  console.log("httpGetPortfolioSummaryTable");
  return http({
    url: `/portfolios/portfolio_summary_table/get_portfolio_summary_table/` ,
    method: 'GET',
    params,
  })
}