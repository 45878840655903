import React from "react";
import { useTranslation } from 'react-i18next';

// Stories
import { PageHeader, PageContainer } from "components/Page"

import { Col, Container, Row } from "stories/layout"

import {Link, Breadcrumb, BreadcrumbItem, Button, BreadcrumbsDynamic} from 'stories/components';
import { useAuthContext } from "contexts/AuthContext";
import { Markup } from 'interweave';



// App
import graph01 from "assets/images/graph_01.jpg"
import graph02 from "assets/images/graph_02.jpg"
import { Typography } from "stories/components";

const Help = (props) => {
  const { t } = useTranslation();
  const { myUser } = useAuthContext();


  //get crumb links from location state or return default links
  const getCrumbLinks = () => {
    const crumbLinks = [
      {
        name: t("help_header", "Ohjeet"),
          url: "/dashboard",  
        },
    ]
    //if location state include crumbLinks return them
    if (props.location?.state && props.location?.state?.crumbLinks) {
      props.location.state.crumbLinks.push({name: t("help_header","Ohjeet"), url: "/dashboard"})
      return props.location.state.crumbLinks
    }
    //else return default links
    return crumbLinks
  }

  const title = t("Tervetuloa palveluun, ") + (myUser?.first_name? myUser?.first_name : myUser?.username);

  
  return (
    <>
    <PageHeader title={t("help_header","Ohjeet")} />
    <BreadcrumbsDynamic links={getCrumbLinks()} />
    <PageContainer>

    <h1>{t("help_title","Ohjeita palvelun käyttöön liittyen")}</h1>

    <Row className="mb-5">
      <Col>
        
      <Typography>
      {t("help_info_text_1","Pyrimme rakentamaan palvelusta erittäin intuitiivisen ja helpon, niin joskus tulee tilanteita vastaan, että tarvitaan ohjeita palvelun käyttöön liittyen.")}
      </Typography>
      <Typography>
      {t("help_text_2","Pääset tutustumaan ohjeisiin alla olevan linkin kautta.")}
      </Typography>

      </Col>
    </Row>


    <Link href="https://www.investime.fi/tukiportaali/" target="_blank"><Button variant="secondary">{t("help_show_instructions",'Näytä ohjeet')}</Button></Link>
    </PageContainer>
{/*
    <Row>
        <Col className="mb-3">
          <img src={graph01} />
        </Col>
        <Col className="mb-3">
          <img src={graph02} />
        </Col>
  </Row>*/}
    </>
  );
}

export default Help;
