import Frontpage from "views/dashboard/frontpage/Frontpage";
import MyAccount from "views/dashboard/MyAccount/MyAccount";
import MyAccountPasswordChange from "views/dashboard/MyAccountPasswordChange/MyAccountPasswordChange";
import Settings from "views/dashboard/Settings/Settings";
import UsersList from "views/dashboard/UsersList/UsersList";
import UserEdit from "views/dashboard/UserEdit/UserEdit";
import NewFeatures from "views/dashboard/NewFeatures/NewFeatures";
import Help from "views/dashboard/Help/Help";

// App views
import TransactionsView from "views/app/TransactionsView/TransactionsView";
import Options from "views/app/Options/Options";
import PortfoliosList from "views/app/PortfoliosList/PortfoliosList";
import MyAccounts from "views/dashboard/MyAccounts/MyAccounts";
import PortfolioSummary from "views/app/PortfolioSummary/PortfolioSummary";
import PrivateInvestmentFundsList from "views/app/PrivateInvestmentFundsList/PrivateInvestmentFundsList";
import PrivateInvestmentFundsList1 from "views/app/PrivateInvestmentFundsList/PrivateInvestmentFundsList1";
import PrivateInvestmentFundRatesList from "views/app/PrivateInvestmentFundRatesList/PrivateInvestmentFundRatesList";
import RealEstateRatesList from "views/app/PrivateInvestmentFundRatesList/RealEstateRatesList";
import RealEstateList from "views/app/PrivateInvestmentFundsList/RealEstateList";
import PrivateStocksList from "views/app/PrivateStocksList/PrivateStocksList";
import StocksList from "views/app/StocksList/StocksList";
import StockRatesList from "views/app/StockRatesList/StockRatesList";
import Diary from "views/app/Diary/Diary";
import Reports from "views/app/Reports/Reports";
import IncomeReports from "views/app/IncomeReports/IncomeReports";
import InvestmentFundsList from "views/app/InvestmentFundsList/InvestmentFundsList";
import InvestmentFundRatesList from "views/app/InvestmentFundRatesList/InvestmentFundRatesList";
import TaxReport from "views/app/TaxReport/TaxReport";
import PortfolioAi from "views/app/PortfolioAi/PortfolioAi";
import Documents from "views/app/Documents/Documents";

// VismaPay
// import LicensePayment from "views/vismapay/LicensePayment/LicensePayment";

const Routes = (t) => {
  
  // Routes visible in left navbar
  const navRoutes = [
    {
      path: "/frontpage",
      name: t("routes_dashboard_frontpage", "Etusivu"),
      icon: "fas fa-home text-primary",
      component: Frontpage,
      layout: "/dashboard",
    },
    {

      // path: "/portfolios",
      collapse:true,
      alwaysOpen: false,
      name: t("routes_dashboard_portfolios", "Salkut"),
      icon: "fas fa-briefcase text-primary",
      // component: PortfoliosList,
      layout: "/dashboard",
      // exact: true,
      views: [
        {
          path: "/portfolios/summary",
          name: t("routes_dashboard_portfolio_summary", "Näytä salkku"),
          // icon: "fas fa-exchange-alt text-primary", // fire-alt
          component: PortfolioSummary,
          layout: "/dashboard",
          exact: true,
          limit: "portfolio_summary",
          
        },
        {
          path: "/portfolios-list",
          name: t("routes_dashboard_all_portfolios", "Kaikki salkut"),
          // icon: "fas fa-balance-scale-left text-primary",
          component: PortfoliosList,
          layout: "/dashboard",
          // exact: true,
        },
      ]
    },
    
    {
      collapse: true,
      alwaysOpen: false,
      name: t("routes_dashboard_transactions_collapse", "Tapahtumat"),
      icon: "fas fa-exchange-alt text-primary",
      state: "transactionsCollapse",
      layout: "/dashboard",
      views: [
        // {
        //   path: "/transactions/new_purchase",
        //   name: t("routes_dashboard_transactions_new_purchase", "Tee osto"),
        //   // icon: "fas fa-exchange-alt text-primary", // fire-alt
        //   component: TransactionsView,
        //   layout: "/dashboard",
        //   exact: true,
        // },
        // {
        //   path: "/transactions/new_sale",
        //   name: t("routes_dashboard_transactions_new_sale", "Tee myynti"),
        //   // icon: "fas fa-exchange-alt text-primary", // fire-alt
        //   component: TransactionsView,
        //   layout: "/dashboard",
        //   exact: true,
        // },
        // {
        //   path: "/transactions/new_exchange",
        //   name: t("routes_dashboard_transactions_new_exchange", "Tee vaihto"),
        //   // icon: "fas fa-exchange-alt text-primary", // fire-alt
        //   component: TransactionsView,
        //   layout: "/dashboard",
        //   exact: true,
        // },
        {
          path: "/transactions",
          name: t("routes_dashboard_transactions", "Ostot ja myynnit"),
          // icon: "fas fa-exchange-alt text-primary", // fire-alt
          component: TransactionsView,
          layout: "/dashboard",
          exact: true,
        },
        {
          path: "/options",
          name: t("routes_dashboard_derivatives", "Johdannaiset"),
          // icon: "fas fa-balance-scale-left text-primary",
          // miniName: t('routes_dashboard_options_mini', "Johd."),
          component: Options,
          layout: "/dashboard",
        },
        {
          path: "/diary",
          name: t("routes_dashboard_diary", "Päiväkirja"),
          // icon: "fas fa-book text-primary",
          component: Diary,
          layout: "/dashboard",
        },
      ]
    }, 
    {
      collapse: true,
      alwaysOpen: false,
      name: t("routes_dashboard_reports_collapse", "Raportit"),
      icon: "fas fa-file-alt text-primary",
      state: "reportsCollapse",
      layout: "/dashboard",
      views: [
        {
          path: "/profit",
          name: t("routes_dashboard_profit", "Tuottoraportti"),
          //icon: "fas fa-seedling text-primary",
          component: IncomeReports,
          layout: "/dashboard",
        },
        {
          path: "/tax-report",
          name: t("routes_dashboard_tax", "Veroilmoitus"),
          //icon: "far fa-file-alt text-primary",
          component: TaxReport,
          layout: "/dashboard",
        },
      ],
    },
    // {
    //   path: "/diary",
    //   name: t("routes_dashboard_diary", "Päiväkirja"),
    //   icon: "fas fa-book text-primary",
    //   component: Diary,
    //   layout: "/dashboard",
    // },
    {
      path: "/private_investment_funds",
      name: t("routes_dashboard_securities", "Arvopaperit"),
      icon: "fas fa-cubes text-primary",
      component: PrivateInvestmentFundsList,
      layout: "/dashboard",
      exact:  true,
    },
    {
    path: "/documents",
      name: t("routes_dashboard_documents", "Tiedostot"),
      icon: "fas fa-folder text-primary",
      component: Documents,
      layout: "/dashboard",
      exact:  true,
      exclude_licenses: ['Lite'],
      // staff: true

    },
    {

      path: "/real_estate",
      name: t("routes_dashboard_renting", "Vuokrakohteet"),
      // icon: "fas fa-sync-alt text-primary",
      icon: "fas fa-euro-sign text-primary",
      component: RealEstateList,
      layout: "/dashboard",
      exact:  true,
      staff: true
    },
    // {
    //   collapse: true,
    //   alwaysOpen: false,
    //   name: t("routes_dashboard_securities_collapse", "Arvopaperit"),
    //   icon: "fas fa-cubes text-primary",
    //   state: "securitiesCollapse",
    //   layout: "/dashboard",
    //   views: [
    //     {
    //       path: "/stocks",
    //       name: t("routes_dashboard_portfolio_public_stocks", "Noteeratut osakkeet (NASDAQ)"),
    //       component: StocksList,
    //       layout: "/dashboard",
    //       exact: true,
    //     },
    //     {
    //       path: "/private_investment_funds/listed_stock",
    //       name: t("routes_dashboard_custom_public_stocks", "Noteeratut osakkeet"),
    //       component: PrivateInvestmentFundsList1,
    //       layout: "/dashboard",
    //       exact: true,
    //     },
    //     {
    //       path: "/private_investment_funds/stocks",
    //       name: t("routes_dashboard_custom_private_stocks", "Noteeraamaattomat osakkeet"),
    //       component: PrivateInvestmentFundsList1,
    //       layout: "/dashboard",
    //       exact: true,
    //     },
    //     {
    //       path: "/private_investment_funds/subsidiary_stock",
    //       name: t("routes_dashboard_custom_subsidiary_stocks", "Tytäryhtiö osakkeet"),
    //       component: PrivateInvestmentFundsList1,
    //       layout: "/dashboard",
    //       exact: true,
    //       feature: "subsidiary_stock",
    //     },
    //     {
    //       path: "/private_investment_funds/asset_stock",
    //       name: t("routes_dashboard_custom_asset_stocks", "Käyttöomaisuus osakkeet"),
    //       component: PrivateInvestmentFundsList1,
    //       layout: "/dashboard",
    //       exact: true,
    //       feature: "asset_stock",
    //     },
    //     {
    //       path: "/investment_funds",
    //       name: t("routes_dashboard_investment_funds_listed", "Rahastot (NASDAQ)"),
    //       component: InvestmentFundsList,
    //       layout: "/dashboard",
    //       exact: true,
    //     },
    //     {
    //       path: "/private_investment_funds/funds",
    //       name: t("routes_dashboard_custom_funds", "Rahastot"),
    //       component: PrivateInvestmentFundsList1,
    //       layout: "/dashboard",
    //       exact: true,
    //     },
    //     {
    //       path: "/private_investment_funds/loans",
    //       name: t("routes_dashboard_custom_loans", "Lainat"),
    //       component: PrivateInvestmentFundsList1,
    //       layout: "/dashboard",
    //       exact: true,
    //     },
    //     /*
    //     {
    //       path: "/private_investment_funds/index",
    //       name: t("routes_dashboard_custom_indexes", "Indexit"),
    //       component: PrivateInvestmentFundsList,
    //       layout: "/dashboard",
    //       exact: true,
    //     },
    //     */
    //     {
    //       path: "/private_investment_funds/properties",
    //       name: t("routes_dashboard_custom_properties", "Vuokrakohteet"),
    //       component: PrivateInvestmentFundsList,
    //       layout: "/dashboard",
    //       exact: true,
    //     },
    //     {
    //       path: "/private_investment_funds/other",
    //       name: t("routes_dashboard_custom_other", "Muut"),
    //       component: PrivateInvestmentFundsList,
    //       layout: "/dashboard",
    //       exact: true,
    //     },
    //   ],
    // },
    /*
    {
      collapse: true,
      alwaysOpen: false,
      name: t("routes_dashboard_securities_collapse", "Arvopaperit"),
      icon: "fas fa-cubes text-primary",
      state: "securitiesCollapse",
      layout: "/dashboard",
      views: [
        {
          path: "/stocks",
          name: t("routes_dashboard_portfolio_public_stocks", "Noteeratut osakkeet (NASDAQ)"),
          component: StocksList,
          layout: "/dashboard",
          exact: true,
        },
        {
          path: "/private_investment_funds/stocks",
          name: t("routes_dashboard_custom_private_stocks", "Noteeraamaattomat osakkeet (Custom)"),
          component: PrivateInvestmentFundsList,
          layout: "/dashboard",
          exact: true,
        },
        {
          path: "/private_investment_funds/listed_stock",
          name: t("routes_dashboard_custom_public_stocks", "Noteeratut osakkeet (Custom)"),
          component: PrivateInvestmentFundsList,
          layout: "/dashboard",
          exact: true,
        },
        {
          path: "/private_investment_funds/subsidiary_stock",
          name: t("routes_dashboard_custom_subsidiary_stocks", "Tytäryhtiö osakkeet (Custom)"),
          component: PrivateInvestmentFundsList,
          layout: "/dashboard",
          exact: true,
          feature: "subsidiary_stock",
        },
        {
          path: "/private_investment_funds/asset_stock",
          name: t("routes_dashboard_custom_asset_stocks", "Käyttöomaisuus osakkeet (Custom)"),
          component: PrivateInvestmentFundsList,
          layout: "/dashboard",
          exact: true,
          feature: "asset_stock",
        },
        {
          path: "/investment_funds",
          name: t("routes_dashboard_investment_funds_listed", "Rahastot (Investime)"),
          component: InvestmentFundsList,
          layout: "/dashboard",
          exact: true,
        },
        {
          path: "/private_investment_funds/funds",
          name: t("routes_dashboard_custom_funds", "Rahastot (Custom)"),
          component: PrivateInvestmentFundsList,
          layout: "/dashboard",
          exact: true,
        },
        {
          path: "/private_investment_funds/loans",
          name: t("routes_dashboard_custom_loans", "Lainat"),
          component: PrivateInvestmentFundsList,
          layout: "/dashboard",
          exact: true,
        },
        {
          path: "/private_investment_funds/index",
          name: t("routes_dashboard_custom_indexes", "Indexit"),
          component: PrivateInvestmentFundsList,
          layout: "/dashboard",
          exact: true,
        },
        {
          path: "/private_investment_funds/properties",
          name: t("routes_dashboard_custom_properties", "Vuokrakohteet"),
          component: PrivateInvestmentFundsList,
          layout: "/dashboard",
          exact: true,
        },
        {
          path: "/private_investment_funds/other",
          name: t("routes_dashboard_custom_other", "Muut"),
          component: PrivateInvestmentFundsList,
          layout: "/dashboard",
          exact: true,
        },
      ],
    },
    */
    
    /*
    {
      path: "/profit",
      name: t("routes_dashboard_profit", "Tuottoraportti"),
      icon: "fas fa-seedling text-primary",
      component: IncomeReports,
      layout: "/dashboard",
    },
    {
      path: "/tax-report",
      name: t("routes_dashboard_tax", "Veroilmoitus"),
      icon: "far fa-file-alt text-primary",
      component: TaxReport,
      layout: "/dashboard",
    },
    */
    
    /*
    {
      collapse: true,
      alwaysOpen: true,
      name: t("routes_dashboard_portfolio_stocks", "Osakkeet"),
      icon: "fas fa-cubes text-primary",
      state: "stocksCollapse",
      layout: "/dashboard",
      views: [
        {
          path: "/stocks",
          name: t("routes_dashboard_portfolio_public_stocks", "Noteeratut osakkeet (NASDAQ)"),
          miniName: "PO",
          component: StocksList,
          layout: "/dashboard",
          exact: true,
        },
        // {
        //   path: "/private_stocks",
        //   name: t("routes_dashboard_portfolio_private_stocks", "Ulkopuoliset osakkeet"),
        //   miniName: "UO",
        //   component: PrivateStocksList,
        //   layout: "/dashboard",
        //   exact: true,
        // },
      ],
    },
    {
      collapse: true,
      alwaysOpen: true,
      name: t("routes_dashboard_investment_funds_collapse", "Rahastot"),
      icon: "fas fa-coins",
      state: "stocksCollapse",
      layout: "/dashboard",
      views: [
        {
          path: "/investment_funds",
          name: t("routes_dashboard_investment_funds_listed", "Listatut rahastot"),
          miniName: "Ra",
          component: InvestmentFundsList,
          layout: "/dashboard",
          exact: true,
        },
        // {
        //   path: "/private_investment_funds",
        //   name: t("routes_dashboard_portfolio_private_investment_funds", "Ulkopuoliset rahastot"),
        //   miniName: "UR",
        //   component: PrivateInvestmentFundsList,
        //   layout: "/dashboard",
        //   exact: true,
        // },

      ],
    },
    {
      collapse: true,
      alwaysOpen: true,
      name: t("routes_dashboard_custom_assets", "Omat arvopaperit"),
      icon: "fas fa-user-tag",
      state: "stocksCollapse",
      layout: "/dashboard",
      views: [

        {
          path: "/private_investment_funds/stocks",
          name: t("routes_dashboard_custom_private_stocks", "Noteeraamaattomat osakkeet"),
          miniName: "Os",
          component: PrivateInvestmentFundsList,
          layout: "/dashboard",
          exact: true,
        },
        {
          path: "/private_investment_funds/listed_stock",
          name: t("routes_dashboard_custom_public_stocks", "Noteeratut osakkeet"),
          miniName: "NO",
          component: PrivateInvestmentFundsList,
          layout: "/dashboard",
          exact: true,
        },
        {
          path: "/private_investment_funds/subsidiary_stock",
          name: t("routes_dashboard_custom_subsidiary_stocks", "Tytäryhtiö osakkeet"),
          miniName: "TO",
          component: PrivateInvestmentFundsList,
          layout: "/dashboard",
          exact: true,
          feature: "subsidiary_stock",
        },
        {
          path: "/private_investment_funds/asset_stock",
          name: t("routes_dashboard_custom_asset_stocks", "Käyttöomaisuus osakkeet"),
          miniName: "KO",
          component: PrivateInvestmentFundsList,
          layout: "/dashboard",
          exact: true,
          feature: "asset_stock",
          
        },

        {
          path: "/private_investment_funds/funds",
          name: t("routes_dashboard_custom_funds", "Rahastot"),
          miniName: "Ra",
          component: PrivateInvestmentFundsList,
          layout: "/dashboard",
          exact: true,
        },
        {
          path: "/private_investment_funds/loans",
          name: t("routes_dashboard_custom_loans", "Lainat"),
          miniName: "La",
          component: PrivateInvestmentFundsList,
          layout: "/dashboard",
          exact: true,
        },
        {
          path: "/private_investment_funds/index",
          name: t("routes_dashboard_custom_indexes", "Indexit"),
          miniName: "In",
          component: PrivateInvestmentFundsList,
          layout: "/dashboard",
          exact: true,
        },
        {
          path: "/private_investment_funds/other",
          name: t("routes_dashboard_custom_other", "Muut"),
          miniName: "Mu",
          component: PrivateInvestmentFundsList,
          layout: "/dashboard",
          exact: true,
        },
        // {
        //   path: "/private_stocks",
        //   name: t("routes_dashboard_portfolio_private_stocks", "Ulkopuoliset osakkeet"),
        //   miniName: "UO",
        //   component: PrivateStocksList,
        //   layout: "/dashboard",
        //   exact: true,
        // },

      ],
    },
    
    */
    
    /*
    {
      path: "/stocks",
      name: "Osakkeet",
      icon: "fas fa-cubes text-primary",
      component: StocksList,
      layout: "/dashboard",
    },
    */
    // {
    //   collapse: true,
    //   alwaysOpen: true,
    //   //hidden
    //   hidden: true,
    //   name: t("routes_dashboard_rates", "Kurssit"),
    //   icon: "fas fa-chart-line text-primary",
    //   state: "ratesCollapse",
    //   layout: "/dashboard",
    //   views: [
    //     {
    //       path: "/rates",
    //       miniName: "OR",
    //       name: t("routes_dashboard_stock_rates", "Osakekurssit"),
    //       component: StockRatesList,
    //       layout: "/dashboard",
    //     },
    //     {
    //       path: "/private_investment_funds_rates",
    //       miniName: "RR",
    //       name: t("routes_dashboard_investment_fund_rates", "Rahastokurssit"),
    //       component: PrivateInvestmentFundRatesList,
    //       layout: "/dashboard",
    //     },
    //   ],
    // },
    
    /*
    {
      path: "/options",
      name: t("routes_dashboard_derivatives", "Johdannaiset"),
      icon: "fas fa-balance-scale-left text-primary",
      component: Options,
      layout: "/dashboard",
    },
    */
    
    // {
    //   path: "/reports",
    //   name: t("routes_dashboard_reports", "Raportit"),
    //   icon: "fas fa-file text-primary",
    //   component: Reports,
    //   layout: "/dashboard",
    // },

    {
      path: "/accounts",
      name: t("routes_dashboard_accounts", "Asiakkaat"),
      icon: "fas fa-building text-primary",
      component: MyAccounts,
      layout: "/dashboard",
      exact:  true,
    },
    {
      path: "/new_features",
      name: t("routes_dashboard_new_features", "Uutta"),
      icon: "fas fa-plus-square",
      component: NewFeatures,
      layout: "/dashboard",
    },
    {
      path: "/help",
      name: t("routes_dashboard_help", "Ohjeet"),
      icon: "fas fa-info-circle",
      component: Help,
      layout: "/dashboard",
    },
    {
      path: "/other",
      name: t("routes_dashboard_settings", "Tili ja asetukset"),
      icon: "fas fa-cog text-primary",
      component: Settings,
      layout: "/dashboard",
    },
    /*
    {
      path: "/users/list",
      name: "Käyttäjähallinta",
      icon: "ni ni-shop text-primary",
      component: UsersList,
      layout: "/dashboard",
    },
    {
      path: "/suppliers/list",
      name: "Tavarantoimittajat",
      icon: "fas fa-people-carry text-primary",
      component: SuppliersList,
      layout: "/dashboard",
    },
    {
      hr: true,
    },
    {
      path: "/myaccount",
      name: "Omat tiedot",
      icon: "fas fa-user text-primary",
      component: MyAccount,
      layout: "/dashboard",
      exact: true,
    },
    */
  ];

  // all other usable routes
  const hiddenRoutes = [
    {
      path: "/users/new",
      name: "UserNew",
      icon: "ni ni-single-02 text-primary",
      component: UserEdit,
      layout: "/dashboard",
      invisible: true,
      // permissions: 3,
    },
    {
      path: "/users/edit/:id/",
      name: "UserEdit",
      icon: "ni ni-single-02 text-primary",
      component: UserEdit,
      layout: "/dashboard",
      invisible: true,
      // permissions: 3,
    },
    {
      path: "/myaccount/password",
      name: "Omat tiedot",
      icon: "fas fa-user text-primary",
      component: MyAccountPasswordChange,
      layout: "/dashboard",
      invisible: true,
    },
    {
      path: "/private_investment_funds/rates",
      name: "Rates",
      icon: "fas fa-user text-primary",
      component: PrivateInvestmentFundRatesList,
      layout: "/dashboard",
      invisible: true,
    },
    {
      path: "/real_estate/rates",
      name: "Rates",
      icon: "fas fa-user text-primary",
      component: RealEstateRatesList,
      layout: "/dashboard",
      invisible: true,
    },
    {
      path: "/private_stocks/rates",
      name: "Rates",
      icon: "fas fa-user text-primary",
      component: StockRatesList,
      layout: "/dashboard",
      invisible: true,
    },
    // {
    //   path: "/private_investmend_funds/rate",
    //   name: "Rates",
    //   icon: "fas fa-user text-primary",
    //   component: StockRatesList,
    //   layout: "/dashboard",
    //   invisible: true,
    // },
    {
      path: "/investment_funds/rates",
      name: "Rates",
      icon: "fas fa-user text-primary",
      component: InvestmentFundRatesList,
      layout: "/dashboard",
      invisible: true,
    },
    {
      path: "/private_investment_funds/investment_funds/rates",
      name: "Rates",
      icon: "fas fa-user text-primary",
      component: InvestmentFundRatesList,
      layout: "/dashboard",
      invisible: true,
    },
    {
      path: "/stocks/rates",
      name: "Rates",
      icon: "fas fa-user text-primary",
      component: StockRatesList,
      layout: "/dashboard",
      invisible: true,
    },
    {
      path: "/private_investment_funds/stocks/rates",
      name: "Rates",
      icon: "fas fa-user text-primary",
      component: StockRatesList,
      layout: "/dashboard",
      invisible: true,
    },
    {
      path: "/portfolios/summary",
      name: t("routes_dashboard_portfolio_summary", "Salkkuyhteenveto"),
      icon: "fas fa-briefcase text-primary",
      component: PortfolioSummary,
      layout: "/dashboard",
      invisible: true,
    },
    /*
    {
      path: "/account/license_payment",
      name: t("routes_dashboard_account_license_payment", "Lisenssin maksu"),
      icon: "fas fa-briefcase text-primary",
      component: LicensePayment,
      layout: "/dashboard",
      invisible: true,
    },
    */

  ]

  const routes = [
    ...navRoutes,
    ...hiddenRoutes,
  ]
  
  return routes;
}

export default Routes;

/*
Example: Collapse

{
  collapse: true,
  name: "Tuotteet",
  icon: "fas fa-box-open text-primary",
  state: "productsCollapse",
  layout: "/dashboard",
  views: [
    {
      path: "/products/new",
      name: "Luo tuote",
      miniName: "PN",
      component: ProductEdit,
      layout: "/dashboard",
    },
    {
      path: "/products/list",
      name: "Tuotteet",
      miniName: "A",
      component: ProductsList,
      layout: "/dashboard",
    },
  ],
},

*/
