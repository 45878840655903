import React, { useState, useCallback, useEffect } from "react";
import { withFormik } from 'formik';
import * as Yup from "yup";
import moment from "moment"
import { useTranslation } from 'react-i18next';
import { useStoreState, useStoreActions } from 'easy-peasy';

import { Button, Typography, Spinner, Modal, Popover } from "stories/components";
import { Container, Row, Col, FormContainer, Card, CardBody } from "stories/layout";
import { Input, SelectMulti, Toggle, Radio } from "stories/forms";
import { useNotification } from "stories/components/Notification"
import { useAppContext } from "contexts/AppContext"
import { round,  roundScaleDecimals, thousandFormat } from "services/helpers"
import { TableAuto } from "stories/tables"
import { getDate } from "services/datetime"

import { 
  httpGetTransaction, 
  httpCreateTransaction, 
  httpUpdateTransaction, 
  httpDeleteTransaction, 
  httpGetStockDateBalance, 
  httpCreatePrivateStockTransaction, 
  httpUpdatePrivateStockTransaction,
  httpDeletePrivateStockTransaction,
  httpGetPrivateStockDateBalance,
  httpGetPrivateStockTransaction,
  httpGetPrivateStockTransactions,
  httpArrayCreateExchangeTransaction, 
  httpGetCurrentBatchBalanceRatio

} from "services/transactions"






const defaultValues = {
//   id: null,
//   name: "",
}

const SplitTransactionEdit = (props) => {
  const { getPortfolioName, getUserTypeName, getTransactionType } = useAppContext()

  
  const {
     values,
     touched,
     setTouched,
     errors,
     setErrors,
     handleChange,
     handleBlur,
     handleSubmit,
     setFieldValue,
     isValid,
     isTouched,
     isSubmitting,
     validateForm,
     preSetValues
   } = props;
   
   const {
      t,
      history,
      object,
      dataObject,
      dataObjectArray,
      exchangeValues,
      sell_out_batches
   } = props;
   
   const getFormikInputProps = useCallback(
    name => ({
      name,
      value: values[name],
      error: Boolean(touched[name] && errors[name]),
      errorText: touched[name] ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange
    }),
    [values, errors, touched, handleBlur, handleChange]
  );
  
  const onSubmit = (e) => {
    //convert data to list




    e.preventDefault();
    validateForm().then(res => {
      if (Object.keys(res).length === 0) {
        handleSubmit()
      }
      else {
        //setTouched(res);
        setErrors({
          ...res,
          general: t("Please check the form errors", "Tarkista lomakkeen virheet")
        })
      }
    })
  }
  
  const handleCancel = () => {
    props.handleReject()
  }

  const [data, setData] = useState()

  useEffect(() => { 
    if (object) {
      const dataObject = object.reduce((acc, curr, index) => {
        const id = curr?.batch_id ? curr?.batch_id : curr?.id;
        const key = "rowId_"  + curr?.related_model_type+ "_" + curr?.security?.value + "_" + id + "_" + curr?.index ;
        const value = {
          id: id,
          new_quantity: roundScaleDecimals(curr?.new_quantity,6),
          portfolio: curr?.portfolio,
          original_batch_balance: curr?.original_batch_balance,
          old_original_batch_balance: curr?.original_batch_balance,
          price: curr?.price,
          value_total: curr?.value_total,
          batch_balance: curr?.batch_balance,
          new_purchase_cost: curr?.new_calculated_purchase_cost,
          new_commission_total: curr?.new_calculated_commission_total,
          commission_total: curr?.calculated_commission_total,
          related_model_type: curr?.related_model_type,
          related_model_id: curr?.security?.value,
          percentage: curr?.percentage,
          sum_new_quantity: curr?.sum_new_quantity,
          stock_market: curr?.stock_market,
          index: curr?.index,
          sell_out_batches: curr?.sell_out_batches,
          
          // portfolio: curr?.batch?.portfolio,
          // id: id,
          // new_quantity: Number(curr?.batch?.batch_balance)* Number(curr?.splits?.dividend),
        };
        return { ...acc, [key]: value };
      }, {});
      setData(dataObject);
      if (data) {
        const data1 = Object.values(dataObject).map(item => {
          return {
            ...item,
            id: item.id,
            new_quantity: item.new_quantity,
            new_purchase_cost: item.new_purchase_cost,
            new_commission_total: item.new_calculated_commission_total,
            sell_out_batches: item?.sell_out_batches,

          }
        })
        setFieldValue("data", data1)
      }
      // setFieldValue("data", dataObject)
      
    }
  }, [object]);

  useEffect(() => {
    if (data) {
      const data1 = Object.values(data).map(item => {
        return {
          ...item,
          id: item.id,
          new_quantity: item.new_quantity,
          new_purchase_cost: item.new_purchase_cost,
          new_commission_total: item.new_commission_total,
        }
      })
      setFieldValue("data", data1)
    }
    // setFieldValue("data", data)

  }, [data])
  




  const headers = [
    // { label: t("exchange_transaction_create_portfolio", "Salkku"), key: "portfolio"},
    { label: t("exchange_transaction_create_batch", "Erä id"), key: "batch"},
    { label: t("exchange_transaction_create_instrument_name", "Instrumentti"), key: "instrument_name"},
    // { label: t("exchange_transaction_create_type", "Tyyppi"), key: "transaction_type"},
    { label: t("exchange_transaction_create_batch_purchase_date", "Hankintapäivä"), key: "batch_purchase_date"},
    { label: t("exchange_transaction_create_batch_balance", "Määrä"), key: "batch_balance"},

    // { label: t("exchange_transaction_create_batch_price", "hinta kpl"), key: "price"},
    { label: t("exchange_transaction_create_batch_commission_total", "Kulut"), key: "commission_total"},
    { label: t("exchange_transaction_create_batch_purchase_cost", "Hankintah."), key: "purchase_cost"},
    { label: t("exchange_transaction_create_batch_exchange_to", "Vaihdetaan"), key: "exchange_to"},


    // { label: t("exchange_transaction_create_split_id", "Split id"), key: "split_id"},
    // { label: t("exchange_transaction_create_split_dividend", "Kerroin"), key: "dividend"},
    // { label: t("exchange_transaction_create_split_date_effective", "SPLIT Päivä"), key: "date_effective"},
    { label: t("exchange_transaction_create_date_effective", "Vaihto päivä"), key: "date_transaction"},
    { label: t("exchange_transaction_create_new_purchase_cost", "Uusi Hankintah."), key: "new_purchase_cost"},
    { label: t("exchange_transaction_create_new_commission_total", "Uusi kulu"), key: "new_commission_total"},
    { label: t("exchange_transaction_create_new_quantity", "Uusi määrä"), key: "new_quantity"},

    { label: t("portfolios_list_actions", " "), key: "actions"},
  ];


  if(!sell_out_batches){
    const newHeader = { label: t("exchange_transaction_create_batch_sell", "Myynti ulos"), key: "sell_out_batches"}
    headers.splice(headers.length - 1, 0, newHeader);
  }
 

  
  // const handleDelete = () => {
  //   httpDeletePortfolio(object.id).then(() => {
  //     props.handleResolve()
  //   })
  // }

  const calculateNewValues = () => {

    let newBalance = object?.batch?.batch_balance * values?.split_dividend
    let newPrice = object?.batch?.price / values?.split_dividend
    setFieldValue("new_batch_balance", newBalance)
    setFieldValue("new_price", roundScaleDecimals(newPrice, 6))
  }

  const returnOrignalValue = (row) => {
    const id = row?.batch_id ? row?.batch_id : row?.id;
    setData(s => ({
        ...s,
        [`rowId_${row?.related_model_type}_${row?.security?.value}_${id}_${row?.index}`]: {
            ...s[`rowId_${row?.related_model_type}_${row?.security?.value}_${id}_${row?.index}`],
      
            "new_quantity":roundScaleDecimals( Number(row?.new_quantity), 6),
            "new_purchase_cost":row?.new_calculated_purchase_cost,
            "new_commission_total":row?.new_calculated_commission_total,
            "sell_out_batches":row?.sell_out_batches,
        }
    }))
}

  const handleInputChange = ({target}, rowId, related_model_type, securityId, index) => {
      setData(s => ({
          ...s,
          [`rowId_${related_model_type}_${securityId}_${rowId}_${index}`]: {
              ...s[`rowId_${related_model_type}_${securityId}_${rowId}_${index}`],
              [target.name]: target.value
          }
      }))
  }

  const getValue = (rowId, fieldName, related_model_type, securityId, index) => {

      if (data === undefined) return ""
      return data[`rowId_${related_model_type}_${securityId}_${rowId}_${index}`]?.[fieldName]
  }

  const getTableRows = useCallback((rows) => {
    let quantity_summary = 0
    let new_quantity_summary = 0
    let sell_out_summary = 0
    let new_purchase_cost_summary = 0
    let new_commission_total_summary = 0

    for (let i = 0; i < rows.length; i++) {
      quantity_summary += rows[i]?.batch_balance
      const quantity_value = getValue(rows[i]?.batch_id? rows[i]?.batch_id: rows[i]?.id, "new_quantity", rows[i]?.related_model_type, rows[i]?.security?.value, rows[i]?.index )
      new_quantity_summary += Number(quantity_value)
      new_purchase_cost_summary += Number(getValue(rows[i]?.batch_id? rows[i]?.batch_id: rows[i]?.id, "new_purchase_cost", rows[i]?.related_model_type, rows[i]?.security?.value, rows[i]?.index ))
      new_commission_total_summary += Number(getValue(rows[i]?.batch_id? rows[i]?.batch_id: rows[i]?.id, "new_commission_total", rows[i]?.related_model_type, rows[i]?.security?.value, rows[i]?.index ))
      sell_out_summary +=  Number(getValue(rows[i]?.batch_id? rows[i]?.batch_id: rows[i]?.id, "sell_out_batches", rows[i]?.related_model_type, rows[i]?.security?.value, rows[i]?.index ))
    }
    
    const summary_name = t("unmade_split_transactions_list_summary", "Yhteensä")

    rows.push({
      portfolio: summary_name,
      batch_balance: quantity_summary,
      new_quantity: new_quantity_summary,
      sell_out_batches: sell_out_summary,
      splits: {dividend: rows?.[0].splits?.dividend},
      batch:{related_model:{name: rows?.[0].batch?.related_model?.name}}
    })

    return rows && rows.map((row, index) => {
      let batch_balance = Number(row?.batch_balance)
      return {
        ...row,
        batch: row?.batch_id? row?.batch_id: row?.id,
        portfolio: row.portfolio==summary_name?<><Typography variant="h5" className="text-dark">{row.portfolio}</Typography></>: getPortfolioName(row?.portfolio),
        instrument_name:row?.related_model?.name,
        // batch_balance: row.portfolio==summary_name?quantity_summary:batch_balance,
        batch_balance: {
          content: thousandFormat(row.portfolio==summary_name?quantity_summary:batch_balance),
          className: "text-right"
        },
        price: row.portfolio==summary_name?null: roundScaleDecimals(row?.price, 6),
        // date_transaction: row?.date_transaction,
        date_transaction: getDate(row?.date_transaction),
        // commission_total: row?.calculated_commission_total,
        commission_total: row?.calculated_commission_total &&  {
          content: thousandFormat(row?.calculated_commission_total),
          className: "text-right"
        },
        // purchase_cost: row?.calculated_purchase_cost,
        purchase_cost: row?.calculated_purchase_cost &&  {
          content: thousandFormat(row?.calculated_purchase_cost),
          className: "text-right"
        },
        exchange_to: row?.security?.name,
        dividend: row?.splits?.dividend,
        split_id: row?.splits?.id,
        transaction_type: t("transactionTypeValue_2", getTransactionType(2).value), 

        date_effective: row.portfolio==summary_name?null: moment(row?.splits?.date_effective).format('DD.MM.YYYY'),
        batch_purchase_date:row.portfolio==summary_name?null: moment(row?.batch_purchase_date).format('DD.MM.YYYY'),
        new_purchase_cost: (
          <>
          <Input 
            name="new_purchase_cost" 
            onChange={(e) => handleInputChange(e, row?.batch_id? row?.batch_id: row?.id, row?.related_model_type, row?.security?.value, row?.index)} 
            value={row.portfolio==summary_name?roundScaleDecimals(new_purchase_cost_summary,6): getValue(row?.batch_id? row?.batch_id: row?.id, "new_purchase_cost", row?.related_model_type, row?.security?.value, row?.index)}
            type="number"
            step="0.01"
            onWheel={(e) => e.target.blur()}
            disabled={row.portfolio==summary_name?true:false}
            placeholder={t("unmade_split_transactions_list_new_quantity", t("Uusi hankintahinta"))}
            style={{ minWidth: '100px' }} 
          />
       

          </>
        ),
        new_commission_total: (
          <>
          <Input 
            name="new_commission_total" 
            onChange={(e) => handleInputChange(e, row?.batch_id? row?.batch_id: row?.id, row?.related_model_type, row?.security?.value, row?.index)} 
            value={row.portfolio==summary_name? roundScaleDecimals(new_commission_total_summary,6): getValue(row?.batch_id? row?.batch_id: row?.id, "new_commission_total", row?.related_model_type, row?.security?.value, row?.index)}
            type="number"
            step="0.01"
            onWheel={(e) => e.target.blur()}
            disabled={row.portfolio==summary_name?true:false}
            placeholder={t("unmade_split_transactions_list_new_quantity", t("Uusi hankintahinta"))}
            style={{ minWidth: '100px' }} 

          />
       

          </>
        ),
        new_quantity: (
          <>
          <Input 
            name="new_quantity" 
            onChange={(e) => handleInputChange(e, row?.batch_id? row?.batch_id: row?.id, row?.related_model_type, row?.security?.value,row?.index)} 
            value={row.portfolio==summary_name?new_quantity_summary: getValue(row?.batch_id? row?.batch_id: row?.id, "new_quantity", row?.related_model_type, row?.security?.value, row?.index)}
            type="number"
            step="0.01"
            onWheel={(e) => e.target.blur()}
            disabled={row.portfolio==summary_name?true:false}
            placeholder={t("unmade_split_transactions_list_new_quantity", t("Uusi määrä"))}
            style={{ minWidth: '100px' }} 

          />
       

          </>
        ),
        sell_out_batches: (
          <>
          <Input 
            name="sell_out_batches" 
            onChange={(e) => handleInputChange(e, row?.batch_id? row?.batch_id: row?.id, row?.related_model_type, row?.security?.value,row?.index)} 
            value={row.portfolio==summary_name?sell_out_summary: getValue(row?.batch_id? row?.batch_id: row?.id, "sell_out_batches", row?.related_model_type, row?.security?.value, row?.index)}
            type="number"
            step="0.01"
            onWheel={(e) => e.target.blur()}
            disabled={row.portfolio==summary_name?true:false}
            placeholder={t("unmade_split_transactions_list_new_quantity", t("Määrä"))}
            style={{ minWidth: '100px' }} 

          />
       

          </>
        ),
        actions: row.portfolio!==summary_name && (
          <div className="d-flex justify-content-end">

            <Button className="btn-sm " onClick={() => returnOrignalValue(row)} color="secondary" >{t("Palauta")}</Button>
          </div>
        ),
      }
    })
  }, [object, data])


  const getPortfolioTables = useCallback(() => {
    const data1 = object;
    const organizedData = {};
    if (data1 === undefined) return null;


    // Iterate over the original data crouping by...
    for (const item of data1) {
      const key = item.related_model_type + item.security?.value + item?.index
      // const portfolioId = item.portfolio;
      
      if (organizedData[key]) {
        organizedData[key].push(item);
      } else {
        organizedData[key] = [item];
      }
    }

    const organizedDataArray = Object.values(organizedData);
    
    return organizedDataArray.map((portfolioData, index) => {
      return( <TableAuto
      showRowNumber={false}
      showId={false}
      checkboxes={false}
      headers={headers}
      headerColor={"light"}
      rows={getTableRows(portfolioData)}
      // loading={loading}
      pagination={false}
      // paginationPrevious={paginationPrevious}
      // paginationNext={paginationNext}
      // paginationOffset={filters?.offset}
      // paginationLimit={filters?.limit}
      // paginationCount={count}
      // setFilters={setFilters}
      className="mb-5"
    />)
   
    })
  }, [object, data])

  const getErrorMessages = (errorsData) => {
    return errorsData && errorsData.map((error, index) => {
      return (
        <><Typography key={index} className="text-danger">{error?.new_quantity}</Typography></>
      )
    })
  }


  
  return (
    <>
      <Row className="my-4 mb-3">
        <Col xs={12} md={6} className="ml-auto pb-4">
          <div className="d-flex justify-content-start mb-2">
          <Typography variant="h2">{t("exchange_process_title", "Käsittele vaihto")}</Typography>
          </div>
          </Col>
          <Col xs={12} md={6} className="ml-auto text-right">
            <Button color="warning" id="info" type="button" size="sm">
              <i className="fas fa-info-circle fa-lg"/>
            </Button>
            <Popover
              placement="top"
              target="info"
            >       
              <Typography className="text-warning mb-1" variant="medium">

                  {t("exchange_create_info_text","HUOM! Tee tarvittavat kirjaukset, jotka ajoittuvat aikaan ennnen vaihdon voimaantuloa, ennenkuin hyväksyt muutokset.")}
              </Typography>  
            </Popover>
        </Col>
      </Row>

      {object && getPortfolioTables()}

      <Row className="mt-4">
        <Col className="mb-3">
          <Button onClick={handleCancel} variant="primary">{t("Close","Sulje")}</Button>
        </Col>
        { values?.id ? (
        <Col className="mb-3 text-center">
        {/*  <Button onClick={handleDelete} disabled={true} variant="danger">{t("Delete","Poista")}</Button>*/}
        </Col>
        ) : null }
        <Col className="mb-3 text-right">


            <Button variant="secondary" disabled={isSubmitting} onClick={onSubmit}>
              {t("exchange_create_submit","Hyväksy muutos ehdotukset")}
            </Button>
          
          { Boolean(errors["general"]) && <Typography className="text-danger">{errors["general"]}</Typography> }
          { Boolean(errors["data"])  && errors['general'] && <>{getErrorMessages(errors['data'])}</> }
        </Col>
      </Row>
    </>
  );
}

const SplitTransactionEditFormik = withFormik({
    enableReinitialize: true,
    validateOnMount: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return {
          ...defaultValues
        }
      }
    },
    validationSchema: props => {
      const {t, dataObjectArray} = props;
      const required = t("The field is required");
      return Yup.object().shape({
        data: Yup.array()
        
      });
      return Yup.object().shape({
        data: Yup.array().of(
          Yup.object().shape({
            id: Yup.number().required(`${t("The field")} ${t("id")} ${t("is required")}`),
            new_quantity: Yup.number().min(0.00000000000001,(value) => {
              console.log(value)
              const data = dataObjectArray
              const [, index] = value.path.match(/data\[(\d+)\]/);
              const item = data[index];
              return `${t(`Erän`)} ${item.id} ${t("uusi määrä on oltava enemmän kuin 0")}`;
            }).required((value) => {
              console.log(value)
              const data = dataObjectArray
              const [, index] = value.path.match(/data\[(\d+)\]/);
              const item = data[index];
              return `${t(`Erän`)} ${item.id} ${t("uusi määrä on pakollinen")}`;
            }),
          })
        )
      });
    },





    // validationSchema: props => {
    //   const { t } = props;
    //   const required = t('The field is required');
      
    //   // Define the Yup schema for each object in the dictionary
    //   const objectSchema = Yup.object().shape({
    //     id: Yup.number().required(),
    //     new_quantity: Yup.number().min(1).required(),
    //   });
      
    //   // Define the Yup schema for the entire dictionary
    //   const dictionarySchema = Yup.object().shape({
    //     data: Yup.object().shape({
    //       [Yup.string()]: objectSchema,
    //     }),
    //   });
      
    //   return dictionarySchema;
    // },
    

    // validationSchema: props => {
    //   const {t} = props;
    //   const required = t("The field is required");
    //   return Yup.object().shape({
    //     data: Yup.object().shape({
    //   });
    // },

    // validationSchema: props => {
    //   const { t } = props;
    //   const required = t("The field is required");
    
    //   return Yup.object().shape({
    //     data: Yup.object().shape({
    //       "rowId_*": Yup.object().shape({
    //         id: Yup.number().required(required),
    //         new_quantity: Yup.number().min(1, "New quantity must be greater than 0").required(required),
    //       }),
    //     }),
    //   });
    // },

    // validationSchema = Yup.object().shape({
    //   items: Yup.array().of(
    //     Yup.object().shape({
    //       id: Yup.number().required(),
    //       name: Yup.string().required(),
    //       quantity: Yup.number().min(1).required(),
    //     })
    //   )
    // });

    // validationSchema: (props) => {
    //   const { t } = props;
    //   const required = t('The field is required');
  
    //   // Define the Yup schema for the data dictionary
    //   const dataSchema = Yup.object().shape({
    //     [Yup.string()]: Yup.object().shape({
    //       id: Yup.number().required(),
    //       new_quantity: Yup.number().required(),
    //     }),
    //   });
    
    //   // Define the custom validation function for the data dictionary
    //   const validateData = (data) => {
    //     console.log(data)
      
    //     let errors = {};
    //     Object.entries(data).forEach(([key, value]) => {
    //       console.log("value", value)
    //       //check if key is not include "row" string
    //       if (!key.includes('row')) {
    //         return;
    //       }




    //       if (value.new_quantity === 0) {
    //         errors = { ...errors, [key]: 'New quantity cannot be zero' };
    //       }
    //     });
    //     return errors;
    //   };
  
    //   // Return the final schema with the custom validation function
    //   return Yup.object().shape({
    //     data: dataSchema.test('validate-data', required, validateData),
    //   });
    // },




    handleSubmit: (values, { setSubmitting, setErrors, props }) => {
      const {t, portfolio, account, object, sell_out_batches, fromInvestmentType, fromRelatedModelId} = props;
      console.log("account", account);

      
      let data = {
        data: values?.data?.map((item) => {
          const original_batch_balance = roundScaleDecimals(item?.new_quantity/ item?.batch_balance * item?.original_batch_balance, 6);
          let price = 0
          if (item?.new_quantity != 0 && item?.new_quantity != null){
            // price = roundScaleDecimals(item?.price / ( item?.new_quantity/ item?.batch_balance  ),6);
            if(item?.new_purchase_cost != 0 && item?.new_purchase_cost != null){
              price = roundScaleDecimals(item?.new_purchase_cost / item?.new_quantity ,6);
            }
          }
          console.log(original_batch_balance)
          return {
            // ...item,
            id: item?.id,
            batch_balance: item?.new_quantity,
            date_transaction: object[0].date_transaction,
            // original_batch_balance: original_batch_balance,
            original_batch_balance: item?.new_quantity, 
            old_original_batch_balance: item?.original_batch_balance,
            price: price,
            value_total: item?.value_total,
            related_model_type: item.related_model_type,  
            related_model_id: item.related_model_id,
            type: 2,
            batch_id: item.id,
            commission_total: item?.new_commission_total,
            quantity: item?.new_quantity,
            old_batch_balance: item?.batch_balance,
            price_total: item?.new_purchase_cost,
            fromInvestmentType: fromInvestmentType,
            percentage: item?.percentage,
            sum_new_quantity: item?.sum_new_quantity,
            portfolio:item?.portfolio,
            fromRelatedModelId: fromRelatedModelId,
            stock_market: item?.stock_market,
            index: item?.index,
            sell_out_batch: item?.sell_out_batches,
            sell_out_batches: sell_out_batches,
          }
        }),


          
        // commission_total: object?.batch?.commission_total,
        // currency_exchange_rate: object?.batch?.currency_exchange_rate,
        // currency_label: object?.batch?.currency_label,
        // price: values?.price,
        // purchase_cost: values?.price_total,
        // stock: values?.stock_private_obj?.id,
        // stock: values?.stock?.id,
        // stock_market: stockMarket,
        // fifo_used: values?.fifo_used? values?.fifo_used : false,
        // state: values?.state?.key,
        // date_settlement: values?.date_settlement? moment(values?.date_settlement).format() : undefined,
        // quantity: values?.new_batch_balance,
        // portfolio: object?.batch?.portfolio,
        // type: 20, // Split
        // date_transaction: object?.splits?.date_effective,
        // related_model_id: object?.batch?.related_model_id,
        // related_model_type: object?.batch?.related_model_type,
        // value_total: object?.batch?.value_total,
        // batch_id: object?.batch?.batch_id?object?.batch?.batch_id:object?.batch?.id,
        // batch_balance: values?.new_batch_balance,
        // stock_split: object?.splits?.id,
        // original_batch_balance: original_batch_balance,
        // price: values?.new_price,

      };;
      
      
      // if (values?.id) {
      //   // httpUpdatePortfolio(values.id, data).then(res => {
      //   //   setSubmitting(false);
      //   //   props.notify({ title:t("Portfolio"), message:t("Updated")})
      //   //   props.handleResolve()
          
      //   // }, error => {
      //   //   setSubmitting(false);
      //   //   if (error?.data?.detail) {
      //   //     setErrors({
      //   //       general: error?.data?.detail,
      //   //     })
      //   //   }
      //   //   else {
      //   //     setErrors({
      //   //       general: t("Unknown error. Please try again later or contact support.")
      //   //     })
      //   //   }
      //   // })
      // }
      
      httpArrayCreateExchangeTransaction(data).then(res => {
          setSubmitting(false);
          props.notify({ title:t("Vaihto kirjaus"), message:t("lisätty onnistuneesti")})
          props.handleResolve()
          
        }, error => {
          setSubmitting(false);
          if (error?.data?.detail || error?.data?.general) {
            setErrors({
              general: error?.data?.detail || error?.data?.general,
            })
          }
          else {
            setErrors({
              general: t("Unknown error. Please try again later or contact support.")
            })
          }
        })
      
      
    },
    displayName: "BasicForm"
  
})(SplitTransactionEdit)
    
    
const SplitTransactionEditView = ({history, exchangeValues, modalOpen, ...rest}) => {
  // API requests here

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [preSetValues, setPreSetValues] = useState();
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  const account = useStoreState((state) => state.account?.account);
  const { getPortfolioName, getUserTypeName } = useAppContext()

  
  // const getObject = (id) => {
    
  //   setLoading(true)
    
  //   httpGetPortfolio(id).then(res => {
      
  //     setPreSetValues({
  //       ...defaultValues,
  //       ...res?.data,
  //     });
    
  //   }).finally(response => {
  //     setLoading(false);
  //   })
    
  // } 


  const {batches, forms, date_transaction, investmentType, stock, sell_out_batches } = exchangeValues


  // Calculate the sum of all `batch_balance` values
  const sum_batcs_balances = batches.reduce((accumulator, currentValue) => {
    return accumulator + Number(currentValue.batch_balance);
  }, 0);
  let object1 = null

  let object = []

  for (let i = 0; i < forms.length; i++) {
    const sum_new_quantity = forms[i].sum_new_quantity
    const increase_value = forms[i].increase_value
    const increase_costs = forms[i].increase_costs
    const percentage = forms[i].percentage

    const row = batches && batches.map((batch) => {
      
      let commission_total1 = roundScaleDecimals((percentage * Number(batch.calculated_commission_total) / 100),6) 
      if (increase_costs != "" && increase_costs != null){
        const new_extra_costs = Number(batch.batch_balance) / sum_batcs_balances * Number(increase_costs) * -1
        commission_total1 = roundScaleDecimals(Number(commission_total1) + Number(new_extra_costs),6) 
      }

      let purchase_cost1 = roundScaleDecimals((percentage * Number(batch.calculated_purchase_cost) / 100),6) 
      if (increase_value != "" && increase_value != null){
         console.log(increase_value)
         const new_extra_value = Number(batch.batch_balance) / sum_batcs_balances * Number(increase_value) * -1
         purchase_cost1 = roundScaleDecimals(Number(purchase_cost1) + Number(new_extra_value),6) 
      }
      console.log(purchase_cost1)

      return { 
        ...batch, 
        new_quantity: Number(batch.batch_balance) / sum_batcs_balances * sum_new_quantity,
        ...forms[i],
        date_transaction: date_transaction,
        new_calculated_commission_total: commission_total1,  
        new_calculated_purchase_cost: purchase_cost1,
        index: i,
        sell_out_batches: percentage * Number(batch.batch_balance) / 100
        

      };
    })
    object = object.concat(row)


  }
  console.log(object)

  let dataArray = []
  let dataObject = {}
  if (object) {
    const dataObject = object.reduce((acc, curr, index) => {
      const id = curr?.batch_id ? curr?.batch_id : curr?.id;
      const key = "rowId_" + curr?.related_model_type+ "_" + curr?.security?.value + "_" + id + "_" + curr?.index;
      const value = {
        id: id,
        new_quantity: roundScaleDecimals(curr?.new_quantity,6),
        // new_quantity: roundScaleDecimals(curr?.batch?.batch_balance,6)* roundScaleDecimals(curr?.splits?.dividend,6),
        portfolio: curr?.portfolio,
        original_batch_balance: curr?.original_batch_balance,
        old_original_batch_balance: curr?.original_batch_balance,
        price: curr?.price,
        value_total: curr?.value_total,
        batch_balance: curr?.batch_balance,
        new_purchase_cost:roundScaleDecimals(curr?.new_calculated_purchase_cost,6) ,
        new_commission_total: curr?.new_calculated_commission_total,
        commission_total: curr?.calculated_commission_total,
        related_model_type: curr?.related_model_type,
        related_model_id: curr?.security?.value,
        percentage: curr?.percentage,
        sum_new_quantity: curr?.sum_new_quantity,
        stock_market: curr?.stock_market,
        index: curr?.index,
        sell_out_batches: curr?.sell_out_batches



    };
    return { ...acc, [key]: value };
  }, {});

  console.log(dataObject)

  //convert dic to array
  dataArray = Object.values(dataObject).map(item => {
    return {
      id: item.id,
      new_quantity: item.new_quantity,
      portfolio: item.portfolio,
      original_batch_balance: item?.original_batch_balance,
      old_original_batch_balance: item?.old_original_batch_balance,
      price: item?.price,
      value_total: item?.value_total,
      batch_balance: item?.batch_balance,
      new_purchase_cost: item?.new_purchase_cost,
      new_commission_total: item?.new_commission_total,
      commission_total: item?.commission_total,
      related_model_type: item?.related_model_type,
      related_model_id: item?.related_model_id,
      percentage: item?.percentage,
      sum_new_quantity: item?.sum_new_quantity,
      stock_market: item?.stock_market,
      index: item?.index,
      sell_out_batches: item?.sell_out_batches

    }
   })
  } 


  useEffect (() => {
    if (object) {
      setPreSetValues({
        ...defaultValues,
        // ...dataObject,
        data: dataArray,
        // data: dataObject,
      });
    }
  }, [exchangeValues])

  
  
  // useEffect(() => {
  //   if (objectId && parseInt(objectId) > -1) {
  //     getObject(objectId)
  //   }
  // }, [objectId, modalOpen])
  
  
  if (loading) {
    return (
      <Container fluid>
        <Spinner />
      </Container>
    )
  }

  return (
    <>
    <SplitTransactionEditFormik 
      t={t} 
      history={history} 
      preSetValues={preSetValues} 
      notify={notify} 
      object={object}
      account={account}
      dataObject={dataObject}
      dataObjectArray={dataArray}
      fromInvestmentType={investmentType}
      fromRelatedModelId={stock}
      sell_out_batches = {sell_out_batches}
      {...rest} 
    />
    </>
  )
  
}

export default SplitTransactionEditView;
