import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import { useStoreState, useStoreActions, useStoreRehydrated  } from 'easy-peasy';
import PortfolioSelect from "components/Forms/PortfolioSelect/PortfolioSelect"
import { httpGetTransactionsCount } from "services/transactions"
import { set } from "date-fns";
import { httpGetUser, httpCreateUser, httpUpdateUser, httpInviteUser } from "services/users" 
import { useAuthContext } from "contexts/AuthContext"


const PortfolioSelectStore = ({ label=" ", icon,iconColor, value, ...rest}) => {
  const { t } = useTranslation();
  
  const portfolios = useStoreState((state) => state.portfolio?.portfolios);
  const portfolio = useStoreState((state) => state.portfolio?.portfolio);
  const portfoliosLoading = useStoreState((state) => state.portfolio?.loading);
  const setPortfolio = useStoreActions((actions) => actions.portfolio.setPortfolio);
  const setCount = useStoreActions((actions) => actions.purchases.setCount);
  const count = useStoreState((state) => state.purchases?.count);
  const setPortfolioSummary = useStoreActions((actions) => actions.portfolioSummary.setPortfolioSummary);
  const setPortfolioSummaryPortfolios = useStoreActions((actions) => actions.portfolioSummary.setPortfolio);

  const { myUser } = useAuthContext()

  
  const setPurchases = useStoreActions((actions) => actions.purchases.setObjects);

  const onChangePortfolio = ({target}, child) => {
    if (target?.value > 0) {
      const filtered = portfolios.filter(o => o.id == target?.value)
      if (filtered && filtered.length > 0) {
        setPortfolio(filtered[0])
      }
    }
    if (target?.value === ""){
      setPortfolio("")
    }
    setPortfolioSummary([])
    setPortfolioSummaryPortfolios([])
    //set localstorage portfolio id
    // localStorage.setItem("portfolio", target?.value)


    let data = {
      active_portfolio: Number(target.value),
    };
    
    if (myUser?.id) {
      httpUpdateUser(myUser.id, data).then(res => {
        // props.notify({ title:t("Asiakkuus vaihdettu"), message:t("")})
        //reload page
        // window.location.reload();
        // props.history.push("/dashboard/users/list");
      }, error => {
        if (error?.data?.detail) {
          console.log(
            error?.data?.detail,
          )
        }

        else {
          console.log(
            t("Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen.")
          )
        }
      })
    }
  }

  useEffect(() => {
    const params = {portfolio: portfolio?.id, limit: 99999999, offset: 0}
    httpGetTransactionsCount(params).then(res => {
      // setPurchases(res?.data?.results)
      setCount(res?.data)
    })
  }, [portfolio,])
  
  return (
    <>
    <>

    </>
    <PortfolioSelect 
      label={label}
      onChange={onChangePortfolio} 
      value={value?value:portfolio?.id}
      icon={icon}
      iconColor={iconColor}
      {...rest}
      />
      </>
  )
  
  
}

export default PortfolioSelectStore;
