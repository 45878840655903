import React, { useEffect, useState, useCallback } from "react";
import moment from "moment";
import { useTranslation } from 'react-i18next';
import { useStoreState, useStoreActions } from 'easy-peasy';

import { Row, Col } from "stories/layout";
import { Input } from "stories/forms";
import { Card, Typography, Button, Link, IconButton, Modal, Spinner, BreadcrumbsDynamic } from "stories/components";
import { PageWrapper, PageHeader, PageContainer } from "components/Page"
import { TableAuto } from "stories/tables"
import { Pagination } from "stories/tables";


import PrivateStockEdit from "views/app/PrivateStockEdit/PrivateStockEdit"

const PrivateStocksList = ({match, history, location}) => {
  
  const { t } = useTranslation();
  const privateStocks = useStoreState((state) => state.privateStocks?.objects);
  const loading = useStoreState((state) => state.privateStocks?.loading);
  const getPrivateStocks = useStoreActions((actions) => actions.privateStocks.getObjects);
  const count = useStoreState((state) => state.privateStocks?.count);

  const [filters, setFilters] = useState({
    limit: 30,
    offset:0,
  })
  
  useEffect(() => {
    const params = {...filters}
    delete params.limit
    getPrivateStocks(params)
  }, [])
  
  const handleChange = ({target}) => {
    setFilters({
      ...filters,
      [target.name]: target.value,
    })
  }
  
  const headers = [
    { label: t("stocks_list_name", "Osakkeen nimi"), key: "name"},
    { label: t("PrivateInvestmentFundsList_price", "Viimeisin päätöshinta"), key: "latest_price"},
    { label: t("PrivateInvestmentFundsList_currency_label", "Valuutta"), key: "currency_label"},
    { label: t("PrivateInvestmentFundsList_stock_date", "Viimeisin kirjaus pvm"), key: "stock_date"},
    { label: t("stocks_list_stocks", "Osakkeita"), key: "stocks"},
    // { label: t("PrivateInvestmentFundsList_datas_count", "Kirjauksia yht"), key: "datas_count"},
    { label: t("portfolios_list_actions", " "), key: "actions"},
  ];
  
  const paginationPrevious = () => {
    let offset = filters?.offset - filters?.limit;
    if (offset < 0) {
      offset = 0;
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  const paginationNext = () => {
    let offset = filters?.offset + filters?.limit;
    if (offset > filters?.count) {
      return
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }

  const getCrumbLinks = () => {
    const crumbLinks = [
      {name: t("Etusivu"), url: "/dashboard/"},
      {name: t("Osakkeet"), url: "/dashboard/"},
      {name: t("PrivateStocksList_title","Ulkopuoliset osakkeet"), url: "/dashboard/private_stocks"},
    ]

    //if location state include crumbLinks return them
    if (location?.state && location?.state?.crumbLinks) {
      const crumbLinks = [...location.state.crumbLinks] 
      crumbLinks.push({name: t("PrivateStocksList_title","Ulkopuoliset osakkeet"), url: "/dashboard/private_stocks"})
      return crumbLinks
    }
    //else return default links
    return crumbLinks
  }
  
  const twoDigits = (number) => {
    number = Number(number)
    return number && number.toFixed(2)
  }

  const getTableRows = useCallback(() => {
    return privateStocks && privateStocks.map((row, index) => {
      return {
        ...row,
        name: (
          <Link to={{pathname:"/dashboard/private_stocks/rates/rate_list", search: "?stock_market=PRIVATE", state: {crumbLinks:getCrumbLinks(), stockId:row.id, stockName: row?.name, stockCurrency:row?.currency_label}}}>
          <h4 className=" text-dark m-0">
          {row?.name || "-"}
          </h4>
          </Link>
        ),
        stock_date: row.latest_data?.[0] ? moment(row.latest_data?.[0].stock_date).format('DD.MM.YYYY') : "-",
        latest_price: twoDigits(row.latest_data?.[0]?.updated_price) || "-",
        actions: (
        <div className="d-flex justify-content-end">
        
        <Link to={{pathname:"/dashboard/private_stocks/rates/rate_list", search: "?stock_market=PRIVATE", state: {crumbLinks:getCrumbLinks(), stockId:row.id, stockName: row?.name, stockCurrency:row?.currency_label}}}>
          <Button className="btn-sm" color="secondary">
            <span className="fas fa-chart-line"></span> {t("")}
          </Button>
        </Link>
        <Button className="btn-sm ml-3" color="secondary" onClick={() => openModalEdit(row?.id)}>{t("Muokkaa")}</Button>
        </div>
        ),
      }
    })
  }, [privateStocks])
  
  // Modal: New/Edit
  
  const [modalEditOpen, setModalEditOpen] = useState(false)
  const [modalEditId, setModalEditId] = useState()
  
  const toggleModalEdit = () => {
    setModalEditOpen(s => !s);
  }
  
  const openModalEdit = (id) => {
    setModalEditOpen(true)
    setModalEditId(id);
  }
  
  const modalEditResolve = () => {
    setModalEditOpen(false);
    setModalEditId(null);
    getPrivateStocks(filters)
  }
  
  const modalEditReject = () => {
    setModalEditOpen(false);
    setModalEditId(null);
  }
  
  
  return (
    <>
      <PageHeader title={t("PrivateStocksList_title","Ulkopuoliset osakkeet")} />
      <BreadcrumbsDynamic links={getCrumbLinks()} />
      
      <PageContainer>

      <div className="py-3 pl-0 d-flex">
        <Button color="secondary" onClick={() => openModalEdit(null)}>{t("PrivateStocksList_create","Uusi ulkopuolinen osake")}</Button>
        
        {  !loading && (
          <div className="d-flex align-items-center ml-auto">
            <div className="mx-2" variant="h4">{t("PrivateInvestmentFundsList_count", "Näytä")}</div>
            <Pagination 
              paginationOffset={filters?.offset}
              paginationLimit={filters?.limit}
              paginationCount={count}
              setFilters={setFilters}
              showPaginationCurrentPage={false}
              showPaginationNextAndPrevious={false}
              showVerticalBorders={false}
            />
            <div className="mx-2" variant="h4">{t("riviä")}</div>

          </div>
        )}
      </div>
        
      <TableAuto
        showRowNumber={true}
        showId={true}
        checkboxes={false}
        headers={headers}
        headerColor={"light"}
        rows={getTableRows()}
        loading={loading}
        pagination={true}
        paginationPrevious={paginationPrevious}
        paginationNext={paginationNext}
        paginationOffset={filters?.offset}
        paginationLimit={filters?.limit}
        paginationCount={count}
        setFilters={setFilters}
      />
      </PageContainer>
      
      <Modal
          title={t("Private stock")}
          isOpen={modalEditOpen}
          toggleModal={toggleModalEdit}
        >
        <PrivateStockEdit
          objectId={modalEditId}
          handleResolve={modalEditResolve}
          handleReject={modalEditReject}
        />
      </Modal>
        
    </>
  );
}

export default PrivateStocksList;
