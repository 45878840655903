import Register from "views/auth/Register"
import Login from "views/auth/Login"
import CookieError from "views/auth/CookieError"
import PasswordForgot from "views/auth/PasswordForgot"
import PasswordReset from "views/auth/PasswordReset"
import Invitation from "views/auth/Invitation"
import Activation from "views/auth/Activation"

const routes = [
  {
    path: "/register",
    name: "Rekisteröidy",
    component: Register,
    layout: "/auth",
  },
  {
    path: "/login",
    name: "Kirjaudu",
    component: Login,
    layout: "/auth",
  },
  {
    path: "/password/forgot",
    name: "Unohtunut salasana",
    component: PasswordForgot,
    layout: "/auth",
  },
  {
    path: "/reset_password",
    name: "Resetoi salasana",
    component: PasswordReset,
    layout: "/auth",
  },
  {
    path: "/invitation",
    name: "Kutsu",
    component: Invitation,
    layout: "/auth",
  },
  {
    path: "/activation",
    name: "Aktivointi",
    component: Activation,
    layout: "/auth",
  },
  /*
  {
    
    collapse: true,
    name: "Auth",
    icon: "ni ni-ungroup text-orange",
    state: "authCollapse",
    views: [
      {
        path: "/login",
        name: "Login",
        miniName: "L",
        component: Login,
        layout: "/auth",
      },
      {
        path: "/cookies",
        name: "CookieError",
        miniName: "C",
        component: CookieError,
        layout: "/auth",
      },
    ]
  }
  */
];

export default routes;