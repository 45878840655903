import http from './api'

const url = '/files/'

export const httpUploadFiles = (data) => {
    console.log("httpUploadFiles");
    return http({
        url: `${url}files/` ,
        method: 'POST',
        data,
    })
}

export const httpGetFiles = (params) => {
    console.log("httpGetFiles");
    return http({
        url: `${url}files/`,
        method: 'GET',
        params,
    })
}

export const httpDeleteFile = (id, params) => {
    console.log("httpDeleteFile");
    return http({
        url: `${url}files/${id}/`,
        method: 'DELETE',
        params,
    })
}

// export const httpDownloadFile = (data) => {
//     console.log("httpDownloadFile");
//     return http({
//         url: `${url}download-files/`,
//         method: 'GET',
//         data,
//     })
// }

export const httpDownloadFile = (id, params) => {
    console.log("httpDownloadFile");
    return http({
      url: `${url}download-files/${id}/`,
      method: 'GET',
      responseType: 'blob',
      params
    })
  }

export const httpGetFile = (data) => {
    console.log("httpGetFile");
    return http({
        url: `${url}files/`,
        method: 'GET',
        data,
    })
}

export const httpUpdateFile = (id, data) => {
    console.log("httpUpdateFile");
    console.log(data);
    return http({
        url: `${url}files/${id}/`,
        method: 'PATCH',
        data,
    })
}