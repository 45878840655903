import React, { createContext, useContext, useEffect, useState, useCallback } from "react";
import { withRouter } from 'react-router-dom';
import PropTypes from "prop-types";
import appConfig from "services/config";
import { httpGetMyUser } from "services/users";
import { httpGetMyAccountUser } from "services/account_users";
import { useStoreState, useStoreActions } from 'easy-peasy';
import i18n from "services/i18n"
import { Spinner } from "stories/components"
import { Container, Row, Col } from "stories/layout";

export const AuthContext = createContext({});

const AuthProviderComponent = ({ history, location, children }) => {
  const [loading, setLoading] = useState(true);
  const [loadingMyUser, setLoadingMyUser] = useState(false);
  const [loadingIsAuthenticated, setLoadingIsAuthenticated] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [accountUserType, setAccountUserType] = useState();

  const [myUser, setMyUser] = useState({});
  const getPortfolios = useStoreActions((actions) => actions.portfolio.getPortfolios);
  const getUnmadeSplitTransactions = useStoreActions((actions) => actions.unmadeSplitTransactions.getUnmadeSplitTransactions);
  const getUnmadeDividendTransactions = useStoreActions((actions) => actions.unmadeDividendTransactions.getUnmadeDividendTransactions);
  const getAccount = useStoreActions((actions) => actions.account.getAccount);
  
  const login = (accessToken, refreshToken) => {
    console.log("AuthContext:login");
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("refreshToken", refreshToken);
    getMyUser();
  }
  
  const removeTokens = () => {
    console.log("AuthContext:removeTokens");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
  }
  
  const logout = () => {
    console.log("AuthContext:logout");
    removeTokens();
    window.location.reload();
  }
  
  const getCurrentLanguage = () => {
    return localStorage.getItem("language", "fi");
  }
  
  const setLanguage = (newLanguage) => {
    i18n.changeLanguage(newLanguage)
    localStorage.setItem("language", newLanguage);
  }
  
  const getMyUser = (params) => {
    return new Promise((resolve, reject) => {
      // getAccount()
      // getPortfolios()
      // getUnmadeSplitTransactions()
      
      if (appConfig?.TEST_NO_BACKEND === true) {
        setIsAuthenticated(true);
        setLoading(false);
        return resolve({id:1})
      }
      
      if (location?.pathname.startsWith("/auth/invitation")) {
        setLoading(false);
        reject()
        // return;
      }
      
      if (localStorage.getItem("accessToken") === null) {
        setIsAuthenticated(false);
        setLoading(false);
        reject()
      }
      else {
        setLoading(true);
        setLoadingMyUser(true)

        httpGetMyUser().then(response => {
          if (response && response.status === 200) {
            getAccount()
            getPortfolios()
            // getUnmadeSplitTransactions()

            setMyUser(response?.data);
            //reload profile picture even if it is not changed

            setIsAuthenticated(true);


            // Set client language
            const myUserLanguage = response?.data?.language ? response?.data?.language : "fi";
            setLanguage(myUserLanguage);
            resolve(response?.data)
          }
          else {
            reject()
          }
        }, error => {
          console.log("");
          
          if (error && error?.status === 400) {
            alert(JSON.stringify(error?.data));
            if (error?.data && 'account_user' in error?.data) {
              logout()
            }
          }
          
          setIsAuthenticated(false);
          setLoadingMyUser(false)
          reject()
        }).finally(res => {
          setLoading(false);
          setLoadingMyUser(false)

        })
        setLoading(true);
        httpGetMyAccountUser().then(response => {
          if (response && response.status === 200) {
            if (response?.data && response?.data.length > 0) {
              if (response?.data?.[0].user_type === "admin") {
                setIsAdmin(true)
              }
              setAccountUserType(response?.data?.[0].user_type)
            }
            
          }
        }, error => {
          setIsAdmin(false);
          reject()
        }
        ).finally(res => {
          setLoading(false);
        })

      }
    })
  }
  
  useEffect(() => {
    getMyUser()
  }, [])
  
  if (loading ) {
    return (
     
          <Container className="d-flex align-items-center justify-content-center" style={{ height: "100vh" }}>
          <Spinner animation="border" role="status" className="text-secondary"/>
          </Container>

    )
  }
  
  return (
      <AuthContext.Provider
        value={{
          getMyUser,
          isAuthenticated,
          isAdmin,
          loading,
          logout,
          myUser,
          setLanguage,
          getCurrentLanguage,
          removeTokens,
          loadingMyUser,
          accountUserType,

        }}
      >
        {children}
      </AuthContext.Provider>
    );
};
export const AuthProvider = withRouter(AuthProviderComponent);

AuthContext.defaultProps = {};
AuthContext.propTypes = {
  children: PropTypes.node.isRequired
};

export const useAuthContext = () =>
  useContext(AuthContext);
  
/*
# USAGE

import { useAuthContext } from "contexts/AuthContext"
const MyComponent = (props) => {
  const { myUser } = useAuthContext()
}
*/
