import React, { useEffect, useState, useCallback } from "react";
import moment from "moment";
import { useTranslation } from 'react-i18next';
import { useStoreState, useStoreActions } from 'easy-peasy';

import { Row, Col } from "stories/layout";
import { Input } from "stories/forms";
import { Card, Typography, Button, Link, IconButton, Modal, Spinner } from "stories/components";
import { PageHeader, PageContainer } from "components/Page"
import { TableAuto } from "stories/tables"

import PortfolioEdit from "views/app/PortfolioEdit/PortfolioEdit"
import UserInvitate from "views/dashboard/UserInvitate/UserInvitate"
import CompanyEdit from "views/dashboard/CompanyEdit/CompanyEdit"

import { httpGetAccountUsers, } from "services/account_users"
import { httpInviteUser} from "services/users"
// import from appcontext
import { useAppContext } from "contexts/AppContext"
import { useAuthContext } from "contexts/AuthContext"
import { useNotification } from "stories/components/Notification"
import { httpGetCompanies } from "services/companies";

import InvestmentTypeSelectSub from "components/Forms/InvestmentTypeSelectSub/InvestmentTypeSelectSub"
import CurrencySelect from "components/Forms/CurrencySelect/CurrencySelect"
import { getDate } from "services/datetime"

import { round, roundScaleDecimals } from "services/helpers"





//use  noDefault={true}appcontext to get user data



const ImportChecktTransactions = ({match, history, checkTransactions, setCheckTransactions, basicDataRows, setBasicDataRows }) => {
  
  const { t } = useTranslation();
  const { getTransactionType } = useAppContext()
  
  const [noSplitFound, setNoSplitFound] = useState(checkTransactions?.no_split_found)
  const [allSplits, setAllSplits] = useState(checkTransactions?.all_splits)
  const [batchSalesOver, setBatchSalesOver] = useState(checkTransactions?.batch_sales_over)
  const [machingSplitFound, setMachingSplitFound] = useState(checkTransactions?.maching_splits_found)
  const [noMachingSplitFound, setNoMachingSplitFound] = useState(checkTransactions?.no_maching_splits_found)
  // const loading = useStoreState((state) => state.portfolio?.loading);
  const portfoliosCount = useStoreState((state) => state.portfolio?.count);
  const getPortfolios = useStoreActions((actions) => actions.portfolio.getPortfolios);
  const { getPortfolioName, getUserTypeName,  getSecurityType} = useAppContext()
  const [loading, setLoading] = useState(false)
  const { notify } = useNotification();
  const { isAdmin } = useAuthContext()


  const [filters, setFilters] = useState({
    limit: 30,
    offset:0,
  })

  
  // Table
//   const [rows, setRows] = useState({
//     count: 0,
//     results: [],
//   })
  const getHeadersBatchSalesOver = (param) => {
    const headers = [
      { label: t("import_batch_sales_over_batch", "Osto erän id"), key: "batch"},
      { label: t("import_batch_sales_over_name", "Arvopaperi"), key: "security"},
      { label: t("import_batch_sales_over_event_number", "Tapahtuma num."), key: "inv1_event_number"},
      { label: t("import_batch_sales_over_original_batch_balance", "Erän koko"), key: "batch_size"},
      { label: t("import_batch_sales_over_all_sales", "Kaikki myynnit"), key: "all_sales"},
      { label: t("import_batch_sales_over_split_boolean", "Splittattu"), key: "Split_happened"},
      { label: t("import_batch_sales_over_next_sale_size", "Myynti (split)"), key: "next_sale_size"},
      { label: t("import_batch_sales_over_current_transaction", "Splitattu kirjaus"), key: "current_transaction"},
      { label: t("import_batch_sales_over_split_id", "Split id"), key: "split_id"},
      { label: t("import_batch_sales_over_date_effective", "Split pvm"), key: "date_effective"},
    ];
    return headers
  }


  
  const getHeadersNoSplits = (param) => {
    const headers = [
      { label: t("import_no_splits_event_number", "Tapahtuma num."), key: "inv1_event_number"},
      { label: t("import_no_splits_batch_balance", "Tyyppi"), key: "type"},
      { label: t("import_no_splits_batch", "Osto erän id"), key: "batch"},
        { label: t("import_no_splits_name", "Arvopaperi"), key: "security"},
        { label: t("import_no_splits_date", "PVM"), key: "date"},
        { label: t("import_no_splits_splitted_quantity", "Splitattu määrä"), key: "Splmaara"},
        { label: t("import_no_splits_quantity", "alkp. määrä"), key: "quantity"},
    ];
    return headers
  }

  const getHeadersMachingSplits = (param) => {
    const headers = [
      { label: t("import_maching_splits_event_number", "Tapahtuma num."), key: "inv1_event_number"},
      { label: t("import_maching_splits_split_id", "Split ID"), key: "split_id"},
      { label: t("import_maching_splits_batch", "Osto erän id"), key: "batch"},
      { label: t("import_maching_splits_split_boolean", "Splittaus"), key: "splitted"},
      { label: t("import_maching_splits_type", "Tyyppi"), key: "type"},
      { label: t("import_maching_splits_name", "Arvopaperi"), key: "security"},
      { label: t("import_maching_splits_date", "PVM"), key: "date"},
      { label: t("import_maching_splits_quantity", "alkp. määrä"), key: "quantity"},
      { label: t("import_maching_splits_splitted_quantity", "Splitattu määrä"), key: "Splmaara"},
      { label: t("import_maching_splits_splid_dividend", "Split luku"), key: "split_dividend"},
      { label: t("import_maching_splits_date_effective", "Split pvm"), key: "split_date"},
      { label: t("import_maching_splits_tolerance", "Toleranssi*"), key: "tolerance"},
      { label: t("import_maching_splits_batch_balance", "Erän balance"), key: "batch_balance"},
      { label: t("import_maching_splits_original_batch_balance", "Erän alkp. koko"), key: "orignal_batch_balance"},
    ];
    return headers
  }

  
  const handleChange = ({target}) => {
    setFilters({
      ...filters,
      [target.name]: target.value,
    })
  }
  
  const paginationPrevious = () => {
    let offset = filters?.offset - filters?.limit;
    if (offset < 0) {
      offset = 0;
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  const paginationNext = () => {
    let offset = filters?.offset + filters?.limit;
    if (offset > filters?.count) {
      return
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }

  const handleInvestmentTypeChange = (index) => {
    return (e) => {
        const { value } = e.target;
        const newRows = [...basicDataRows];
        newRows[index].security_type = value;
        setBasicDataRows(newRows);
    }
    }

  const handleSizeInputChange = (index) => {
    return (e) => {
        const { value } = e.target;
        const newRows = [...basicDataRows];
        newRows[index].size = value;
        setBasicDataRows(newRows);
    }
  }
  const handleCurrencySelectChange = (index) => {
    return (e) => {
        const { value } = e.target;
        const newRows = [...basicDataRows];
        newRows[index].currency_label = value;
        setBasicDataRows(newRows);
    }
  }

  const getDataFromAllSplits = (security, splitId, inv1_event_number, searchedData) => {
    const split_transactions = allSplits?.[security]?.[splitId]
    //filter split transaction by inv1_event_number
    const searchedValue = split_transactions?.filter((item) => item?.inv1_event_number === inv1_event_number)?.[0]?.[searchedData]
    return searchedValue
  }

  const getTableRowsBatchSalesOver = useCallback(() => {
    const new_rows = []

    if (batchSalesOver) {
      let new_row = {}

      for (const key in batchSalesOver) {
        const value = batchSalesOver[key];
        new_row.security = key
        value && value.forEach((row, index) => {
          new_row.Split_happened = row?.Split_happened
          new_row.batch = row?.batch
          new_row.batch_size = row?.batch_size
          new_row.next_sale_size = row?.next_sale_size
          new_row.date_effective = row?.date_effective
          new_row.split_id = row?.split_id
          new_row.current_transaction = row?.current_transaction
          new_row.all_sales = row?.all_sales
        })  
        new_rows.push(new_row)
      }
      
    }
    


    return new_rows.map((item, index) => {
      return {
        ...item,
        Split_happened: item?.Split_happened ? t("yes", "Kyllä") : t("no", "Ei"),
        actions: (
          <div className="d-flex justify-content-end">
            <Button className="btn-sm" color="secondary" disabled={isAdmin === false} onClick={() => openModalEdit(item?.id)}>
              {t("edit_button","Muokkaa")}
            </Button>
          </div>
        ),
      };
    });
  }, [batchSalesOver])



  
  const getTableRowsNoSplits = useCallback(() => {
    return noSplitFound && noSplitFound.map((row, index) => {
      const transactionTypeValue = getTransactionType(row?.type)?.value
      let transactionTypeCell;
      if (transactionTypeValue === "Buy") {
        transactionTypeCell = (<span className=" ">{t("transactionTypeValue_2", transactionTypeValue)}</span>)
      }
      else if (transactionTypeValue === "Sell") {
        transactionTypeCell = (<span className=" ">{t("transactionTypeValue_3", transactionTypeValue)}</span>)
      }
      else if (transactionTypeValue === "Split") {
        transactionTypeCell = (<span className="">{t("transactionTypeValue_20", transactionTypeValue)}</span>)
      }
      else {
        transactionTypeCell = (<span className="">{t("transactionTypeValue_41", transactionTypeValue)}</span>)
      }
      return {
        ...row,
        type: transactionTypeCell,
        date: getDate(row?.date),
        actions: (
          <div className="d-flex justify-content-end">
            <Button className="btn-sm" color="secondary" disabled={isAdmin === false} onClick={() => openModalEdit(row?.id)}>{t("edit_button","Muokkaa")}</Button>
          </div>
        ),
      }
    })
  }, [noSplitFound])

  const getTableRowsMachingSplits = useCallback((trx_splits) => {
    const new_rows = [];
    

  
    trx_splits && trx_splits.forEach((row) => {
      const mach_splits = row?.maching_splits;
      const rejected_splits = row?.splits_after_trx;

      const no_maching_splits_found = noMachingSplitFound?.filter((item) => item?.inv1_event_number === row?.inv1_event_number)?.[0]?.splits_after_trx

      const transactionTypeValue = getTransactionType(row?.type)?.value
      let transactionTypeCell;
      if (transactionTypeValue === "Buy") {
        transactionTypeCell = (<span className=" ">{t("transactionTypeValue_2", transactionTypeValue)}</span>)
      }
      else if (transactionTypeValue === "Sell") {
        transactionTypeCell = (<span className=" ">{t("transactionTypeValue_3", transactionTypeValue)}</span>)
      }
      else if (transactionTypeValue === "Split") {
        transactionTypeCell = (<span className="">{t("transactionTypeValue_20", transactionTypeValue)}</span>)
      }
      else {
        transactionTypeCell = (<span className="">{t("transactionTypeValue_41", transactionTypeValue)}</span>)
      }



  
      mach_splits && mach_splits?.forEach((split) => {
        new_rows.push({
          ...row,
          date: getDate(row?.date),
          type: transactionTypeCell,
          tolerance: roundScaleDecimals(row?.tolerance,2),
          splitted: (<span className="text-success">{t('Kyllä')}</span>),
          split_id: split?.id,
          split_dividend: split?.dividend,
          split_date: getDate(split?.date_effective),
          batch_balance: roundScaleDecimals(getDataFromAllSplits(row?.security, split?.id, row?.inv1_event_number,"batch_balance"),8),
          orignal_batch_balance: roundScaleDecimals(getDataFromAllSplits(row?.security, split?.id, row?.inv1_event_number,"original_batch_balance"),8), 
        });
      });

      //filter rejected splits if item id is not in maching splits
      rejected_splits && rejected_splits?.forEach((split) => {
        if (!mach_splits?.find((item) => item?.id === split?.id)) {
          new_rows.push({
            splitted: <span className={!mach_splits ?"text-danger":"text-secondary"}>{!mach_splits?t('Hylätty'): t('Ohitettu')}</span>,
            date: getDate(row?.date),
            inv1_event_number: row?.inv1_event_number,
            batch: row?.batch,
            security: !mach_splits && row?.security,
            tolerance: roundScaleDecimals(row?.tolerance,2),
            type: !mach_splits && transactionTypeCell,
            Splmaara: !mach_splits && row?.Splmaara,
            quantity: !mach_splits && row?.quantity,
            // type: transactionTypeCell,
            // batch: <span className="text-danger">{t('Ohitettu')}</span>,
            // security: <span className="text-danger">{t('splitti')}</span>,
            // date: row?.date,
            // Splmaara: <span className="text-danger">{t('Ohitettu Splitti')}</span>,            
            split_id: split?.id,
            split_dividend: split?.dividend,
            split_date: getDate(split?.date_effective),
            batch_balance: mach_splits ? null:roundScaleDecimals(row?.batch_balance,8),
            orignal_batch_balance: mach_splits ? null:roundScaleDecimals(row?.original_batch_balance,8),
          });
        }
      });
      new_rows.push({
        splitted: <span  style={{color:'rgba(0,0,0,0' }}>{t('Empty row')}</span>,
      });
    });

    return new_rows.map((item, index) => {
      return {
        ...item,
        actions: (
          <div className="d-flex justify-content-end">
            <Button className="btn-sm" color="secondary" disabled={isAdmin === false} onClick={() => openModalEdit(item?.id)}>
              {t("edit_button","Muokkaa")}
            </Button>
          </div>
        ),
      };
    });
  }, [machingSplitFound, noMachingSplitFound]);
  

  
  // Modal: New/Edit company
  
  const [modalEditOpen, setModalEditOpen] = useState(false)
  const [modalEditId, setModalEditId] = useState()
  
  const toggleModalEdit = () => {
    setModalEditOpen(s => !s);
  }
  
  const openModalEdit = (id) => {
    setModalEditOpen(true)
    setModalEditId(id);
  }
  
  const modalEditResolve = () => {
    setModalEditOpen(false);
    setModalEditId(null);
  }
  
  const modalEditReject = () => {
    setModalEditOpen(false);
    setModalEditId(null);
  }
  
  return (
    <>

        <>
        {noSplitFound && noSplitFound.length > 0 ? (
          <div className="d-flex justify-content-between mb-2">
          <div className="p-2">
          <Typography variant="h3" className="text-dark mb-2" title={t("Splittiä ei löydetty")}>{t("Splittiä ei löydetty")}</Typography>
          <Typography  variant="medium" bold className="mb-3 text-warning">{t("Erän tai erään liittyvän myynnin jälkeen on tapahtunut splitti tai splittejä, mutta niitä ei löydetty Investimesta. Luo ensin tarvittavat splitit ja aloita tuonti uudestaan.")} </Typography>
          <TableAuto
            showRowNumber={true}
            showId={false}
            checkboxes={false}
            headers={getHeadersNoSplits()}
            headerColor={"light"}
            rows={getTableRowsNoSplits()}
            loading={loading}
            pagination={false}
            paginationPrevious={paginationPrevious}
            paginationNext={paginationNext}
            paginationOffset={filters?.offset}
            paginationLimit={filters?.limit}
            paginationCount={null}
            setFilters={setFilters}
          />
          </div>
          </div>
          ):(

            <Typography variant="h3" className="text-dark mb-2" >{t("")}</Typography>

            )
          }
        </>
        
        {machingSplitFound && machingSplitFound.length > 0 ? (
          <>
          <Typography variant="h3" className="text-dark mb-2 mt-5">{t("Kirjaukseen liittyvät splitit ja todennetut splittaukset")}</Typography>
          <Typography  variant="medium" className="mb-3 text-warning">{t("Kirjauksen jälkeiseltä ajalta on etsitty kaikki splitit. Tämän jälkeen on yritetty löytää oikea kombinaatio spliteistä joiden kertoimella alkuperäisestä määrästä saadaan splitattu määrä. Sallittu toleranssi on lähtökohtaisesti 0,01 ja vastaavuuden löytämiseksi kasvatetaan toleranssia aina 0,99 saakka. Mikäli vastaavuutta ei ole vieläkään löytynyt katsotaan splittauksen epäonnistuneen.")} </Typography>

          <TableAuto
            showRowNumber={false}
            showId={false}
            checkboxes={false}
            headers={getHeadersMachingSplits()}
            headerColor={"light"}
            rows={getTableRowsMachingSplits(machingSplitFound)}
            loading={loading}
            pagination={false}
            paginationPrevious={paginationPrevious}
            paginationNext={paginationNext}
            paginationOffset={filters?.offset}
            paginationLimit={filters?.limit}
            paginationCount={null}
            setFilters={setFilters}
          />
          </>
          ):(
            <div className="d-flex justify-content-start mb-4 mt-3">
              <div className="mr-2" >
                <i className="fas fa-check-circle text-success"></i>
              </div>
              <div >
                <Typography variant="h3" className="text-dark " >{t("Kirjauksista ei löydetty yhtään splittaantumista.")}</Typography>
              </div>
            </div>
          )
        }
        {noMachingSplitFound && noMachingSplitFound.length > 0 ? (
          <>
          <Typography variant="h3" className="text-dark mb-2 mt-5">{t("Kirjaukseen liittyvät splitit ja epäonnistuneet splittaukset")}</Typography>
          {(!machingSplitFound || machingSplitFound.length == 0) &&
            <Typography  variant="medium" className="mb-3 text-warning">{t("Kirjauksen jälkeiseltä ajalta on etsitty kaikki splitit. Tämän jälkeen on yritetty löytää oikea kombinaatio spliteistä joiden kertoimella alkuperäisestä määrästä saadaan splitattu määrä. Sallittu toleranssi on lähtökohtaisesti 0,01 ja vastaavuuden löytämiseksi kasvatetaan toleranssia aina 0,99 saakka. Mikäli vastaavuutta ei ole vieläkään löytynyt katsotaan splittauksen epäonnistuneen.")} </Typography>
          }
          {JSON.stringify(noMachingSplitFound)}
          <TableAuto
            showRowNumber={false}
            showId={false}
            checkboxes={false}
            headers={getHeadersMachingSplits()}
            headerColor={"light"}
            rows={getTableRowsMachingSplits(noMachingSplitFound)}
            loading={loading}
            pagination={false}
            paginationPrevious={paginationPrevious}
            paginationNext={paginationNext}
            paginationOffset={filters?.offset}
            paginationLimit={filters?.limit}
            paginationCount={null}
            setFilters={setFilters}
          />
          </>
          ):(
            <div>
            {/*<Typography variant="h3" className="text-dark mb-2 mt-5" >{t("Mikäli splittejä löydettiin kirjauksen jälkeiseltä ajalta, yksikään niistä ei epäonnistunut.")}</Typography>*/}
            <Typography variant="h3" className="text-dark mb-2 mt-5" >{t("")}</Typography>
            </div>
          )
        }
        {batchSalesOver && Object.keys(batchSalesOver).length > 0 ? (
          <>
          <Typography variant="h3" className="text-dark mb-4 mt-5">{t("Myynti ylittää erän koon.")}</Typography>
            <Typography  variant="medium" className="mb-3 text-warning">{t("Mikäli ei ole tapahtunut splittejä ilmoitetaan vain ylitys. Mikäli splittejä suoritettu näytetään splittaus jossa ylitys tapahtuu")} </Typography>
          <TableAuto
            showRowNumber={false}
            showId={false}
            checkboxes={false}
            headers={getHeadersBatchSalesOver()}
            headerColor={"light"}
            rows={getTableRowsBatchSalesOver()}
            loading={loading}
            pagination={false}
            paginationPrevious={paginationPrevious}
            paginationNext={paginationNext}
            paginationOffset={filters?.offset}
            paginationLimit={filters?.limit}
            paginationCount={null}
            setFilters={setFilters}
          />
          </>
          ):(
            <div className="d-flex justify-content-start mb-5 mt-5">
              <div className="mr-2" >
                <i className="fas fa-check-circle text-success"></i>
              </div>
              <div >
                <Typography variant="h3" className="text-dark " >{t("Erien kokonais-myynnit eivät ylitä erien saldoja. (Mikäli splittauksia on tapahtunut tarkistetaan tilanne jokaisen myynnin jälkeen erikseen.)")}</Typography>
                <Typography variant="h5" className="text-dark mb-0 mt-0" >{t("Kun splittauksia ei ole löydetty on erästä myynti saattanyt hetkellisesti olla miinukselle mutta saldo on lopulta korjauttu >= 0")}</Typography>
              </div>
            </div>
          )
        }
        
      
        <Modal
          title={t("companies_list_company_title","Yritys")}
          isOpen={modalEditOpen}
          toggleModal={toggleModalEdit}
        >
          <CompanyEdit
            objectId={modalEditId}
            handleResolve={modalEditResolve}
            handleReject={modalEditReject}
          />
        </Modal>
    </>
  );
}

export default ImportChecktTransactions;