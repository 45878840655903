import React from 'react';
import PropTypes from 'prop-types';


export const ListRow = ({ icon, iconColor, children, ...rest }) => {
  
  return (
    <div className="d-flex justify-content-start align-items-center mb-2">
      { icon && (
      <div className="mr-4"><div className={`avatar rounded-circle bg-${iconColor} w-32 h-32`}><i className={`fas fa-${icon}`}></i></div></div>
      )}
      <div className="mr-5" style={{minWidth: "160px"}}>
        {children}
      </div>
    </div>
  )
};

ListRow.propTypes = {
  
};


ListRow.defaultProps = {
  iconColor: 'default',
};

