import React, { useCallback, useEffect, useState} from "react";
import moment from "moment";
import { useTranslation } from 'react-i18next';
import queryString from 'query-string'

import { Container, Row, Col, Card, CardHeader, CardBody, List } from "stories/layout";
import { Typography, Button, Link, Breadcrumb, BreadcrumbItem } from "stories/components";

const PaymentCancelled = ({history, match, location}) => {
  
  const { t } = useTranslation();
  
  /*
  const [id, setId] = useState()
  
  useEffect(() => {
    if (id) {
      
    }
  }, [id])
  
  useEffect(() => {
    if (match && match?.params?.object_id) {
      setId(parseInt(match?.params?.object_id))
    }
  }, [match])

  useEffect(() => {
    const keys = queryString.parse(location?.search)
    if (keys?.object_id)
    {
      setId(parseInt(keys?.object_id))
    }

  }, [location]);
  */
  
  return (
    <>
      <div className="d-flex justify-content-center align-items-center flex-column my-5">
        <div>
          <Typography variant="h2" color="danger">{t("Payment cancelled or failed")}</Typography>
        </div>
        <div>
          <Link to={`/dashboard/frontpage`}>{t("Go to dashboard")}</Link>
        </div>
      </div>
    </>
  );
}

export default PaymentCancelled;
