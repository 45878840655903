import React, { useEffect, useState, useCallback } from "react";
import moment from "moment";
import { useTranslation } from 'react-i18next';
import { useStoreState, useStoreActions } from 'easy-peasy';

import { Row, Col } from "stories/layout";
import { Input } from "stories/forms";
import { Card, Typography, Button, Link, IconButton, Modal, Spinner } from "stories/components";
import { PageHeader, PageContainer } from "components/Page"
import { TableAuto } from "stories/tables"

import PortfolioEdit from "views/app/PortfolioEdit/PortfolioEdit"
import UserInvitate from "views/dashboard/UserInvitate/UserInvitate"
import UserEdit from "views/dashboard/UserEdit/UserEdit"
import SubscriptionOptionsInfo from "components/Info/SubscriptionOptionsInfo.js"

import { httpGetAccountUsers, } from "services/account_users"
import { httpInviteUser} from "services/users"
// import from appcontext
import { useAppContext } from "contexts/AppContext"
import { useAuthContext } from "contexts/AuthContext"
import { useNotification } from "stories/components/Notification"
import PortfolioSelect from "components/Forms/PortfolioSelect/PortfolioSelect";
import PortfoliosList from "views/app/PortfoliosList/PortfoliosList";
import UpdateUp from "views/vismapay/UpdateUp/UpdateUp"




//use appcontext to get user data



const UsersList = ({match, history, setCrumbLinks}) => {
  
  const { t } = useTranslation();
  const portfolios = useStoreState((state) => state.portfolio?.portfolios);
  // const loading = useStoreState((state) => state.portfolio?.loading);
  const portfoliosCount = useStoreState((state) => state.portfolio?.count);
  const getPortfolios = useStoreActions((actions) => actions.portfolio.getPortfolios);
  const { getPortfolioName, getUserTypeName } = useAppContext()
  const [loading, setLoading] = useState(false)
  const { notify } = useNotification();
  const { isAdmin } = useAuthContext()
  const account = useStoreState((state) => state.account?.account);
  

  useEffect(() => {
    setCrumbLinks([
      {
        name: t("Etusivu"),
        url: "/dashboard",  
        },
      {name: t("Other"), url: "/dashboard/other/default_settings"},
      {name: t("account_users", "Käyttäjät"), url: "/dashboard/other/account_users"},
    ])
    }, [])

  const [filters, setFilters] = useState({
    limit: 30,
    offset:0,
  })

  
  // Table
  const [rows, setRows] = useState({
    count: 0,
    results: [],
  })

  
  const getUsers = () => {
    setLoading(true)
    httpGetAccountUsers(filters).then(res => {
      let data = res?.data
      data.full_name = res?.data?.user?.full_name
      setRows({
        count: res?.data.length,
        results: data,
      })
      setLoading(false)
    })
  }
  
  useEffect(() => {
    getUsers()
  }, [filters])
  
  const getHeaders = (param) => {

  const headers = [
    { label: t("Nimi"), key: "name"},
    { label: t("email","Sähköposti"), key: "email"},
    { label: t("Liittynyt"), key: "user_activated_date"},
    { label: t("Kutsuttu"), key: "invated_date"},
    { label: t("Salkut"), key: "portfolios"},
    { label: t("Rooli"), key: "user_type"},
    { label: "", key: "actions"},
  ];

  if (param === "activated"){
    //remove label "kutsuttu" 

    headers.splice(3, 1)
  }
  if (param === "invitated"){
    //remove label "liittynyt" and "name"
    headers.splice(0, 1)
    headers.splice(1, 1)  
  }

  return headers
  }

  
  const handleChange = ({target}) => {
    setFilters({
      ...filters,
      [target.name]: target.value,
    })
  }
  
  const paginationPrevious = () => {
    let offset = filters?.offset - filters?.limit;
    if (offset < 0) {
      offset = 0;
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  const paginationNext = () => {
    let offset = filters?.offset + filters?.limit;
    if (offset > filters?.count) {
      return
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  // const headers = [
  //   { label: t("portfolios_list_name", "Salkku"), key: "name"},
  //   { label: t("portfolios_list_currency_label", "Valuutta"), key: "currency_label"},
  //   // { label: t("portfolios_list_value", "Markkina-arvo"), key: "value"},
  //   // { label: t("portfolios_list_expenses", "Hankintameno"), key: "expenses"},
  //   // { label: t("portfolios_list_profit", "Tuotto"), key: "profit"},
  //   // { label: t("portfolios_list_change", "Muutos"), key: "change"},
  //   // { label: t("portfolios_list_change_eur", "Muutos EUR"), key: "change_eur"},
  //   // { label: t("portfolios_list_other_profit_expenses", "Muut tuotot ja kulut"), key: "other_profit_expenses"},
  //   { label: t("portfolios_list_actions", " "), key: "actions"},
  // ];

  const getAccountUserkPortfolios = ((portfolios, user_type) => {
    let string = ""
    if (portfolios.length === portfoliosCount && user_type === "admin" ) {
        string = t("portfolios_list_all", "Kaikki")
    }
    else{
      portfolios && portfolios.map((portfolio, index) => {
        const portfolio_name = getPortfolioName(portfolio.portfolio)
        string += portfolio_name + (index === portfolios.length -1? "" : ", ")
      })
    }
    return string
    // return portfolios && portfolios.map((portfolio, index) => {
    //   return (
    //     <>{portfolios.portfolio}</>

    //   )
  })

  const sendInvitation = (id) => {
    httpInviteUser(id).then(res => {
      notify({ title:t("Kutsu"), message:t("Kutsu lähetetty")})
      getUsers()
    }, error => {
      notify({ title:t("Kutsu"), message:t("lähetys epäonnistui")})

    }
    )



  }
  
  const getTableRows = useCallback((invitated) => {
    let newRows = []


      //loop rows and remowe not activated
    rows.results.map((row, index) => {
      if (invitated === "invitated" && row?.user?.activated === null) {
        newRows.push(row)
      }
      if (invitated === "activated" && row?.user?.activated !== null) {
        newRows.push(row)
      }

    })
    

      
    



    return newRows && newRows.map((row, index) => {
      if (invitated === "invitated" && row?.user?.activated === null && false) {
        return null
      }
      else {

        let data = {}
        data.id = row?.id ? row.id: "" 
        data.name = row?.user?.full_name ? row?.user?.full_name : "-"
        data.email = row?.user?.username ? row?.user?.username : "-"
        data.user_activated_date = row?.user?.user_activated_date? moment(row?.user?.user_activated_date).format('DD.MM.YYYY') : "-"
        
        data.invated_date = row?.user?.invated_date? moment(row?.user?.invated_date).format('DD.MM.YYYY')  : "-"
        
        
        
        data.user_type = row?.user_type ? getUserTypeName(row?.user_type): "-"
        data.portfolios = getAccountUserkPortfolios(row?.account_user_portfolios, row?.user_type)
        
        //update data.invitated_date if invitation is less than 7 days
        if (row?.user?.invated_date) {
          const invitated_date = moment(row?.user?.invated_date)
          const now_date = moment()
          const diff = now_date.diff(invitated_date, 'days')
          if (diff < 7) {
            data.invated_date = moment(row?.user?.invated_date).format('DD.MM.YYYY' ) + t("user_list_invitation_active"," (voimassa)") 
          }
        }

        
        
        return {
          ...data,
          actions: (
            <div className="d-flex justify-content-end">
            {invitated === "invitated" 
            ? <Button className="btn-sm" disabled={isAdmin === false} color="secondary" onClick={() => sendInvitation(row?.user.id, "invitated_again")}>{t("invitate_user_again","Lähetä kutsu uudelleen")}</Button>
            : <Button className="btn-sm" disabled={isAdmin === false} color="secondary" onClick={() => openModalEdit(row?.id, "edit")}>{t("Muokkaa")}</Button>} 
            </div>
            ),
          }
      }
    })
  }, [rows])
  
  // Modal: New/Edit portfolio
  
  const [modalEditOpen, setModalEditOpen] = useState(false)
  const [modalEditId, setModalEditId] = useState()
  const [modalRenderComponent, setModalRenderComponent] = useState()
  
  const toggleModalEdit = () => {
    setModalEditOpen(s => !s);
  }
  
  const openModalEdit = (id, component) => {

    setModalRenderComponent(component)
 
    setModalEditOpen(true)
    setModalEditId(id);
  }
  
  const modalEditResolve = () => {
    setModalEditOpen(false);
    setModalEditId(null);
    getPortfolios()
    getUsers()
  }
  
  const modalEditReject = () => {
    setModalEditOpen(false);
    setModalEditId(null);
  }

  // Modal: open/edit Subscription

  const [modalSubscriptionEditOpen, setModalSubscriptionEditOpen] = useState(false)
  const [modalSubscriptionEditId, setModalSubscriptionEditId] = useState()
  
  const toggleModalSubscriptionEdit = () => {
    setModalSubscriptionEditOpen(s => !s);
  }
  
  const openModalSubscriptionEdit = (id) => {
    setModalSubscriptionEditOpen(true)
    setModalSubscriptionEditId(id);
  }
  
  const modalSubscriptionEditResolve = () => {
    setModalSubscriptionEditOpen(false);
    setModalSubscriptionEditId(null);
  }
  
  const modalSubscriptionEditReject = () => {
    setModalSubscriptionEditOpen(false);
    setModalSubscriptionEditId(null);
  }


 // Modal: open/edit Subscription

 const [UpdateUpOpen, setUpdateUpOpen] = useState(false)
 
 const callToOpenUpdateUp = (id) => {
   setUpdateUpOpen(true)
 }
 

 // // Update subscription BEGIN

 const openUpdateUp = useCallback(() => {
   return (<>{ UpdateUpOpen && <UpdateUp redirectToFrontpage={false} closeParent={() => setUpdateUpOpen(false)}/>}</>)
 }, [UpdateUpOpen])

 // END
  
  return (
    <>

        {openUpdateUp()}

        <div className="d-flex justify-content-between mb-2">
          <div className="p-2">
          {rows.count >= account?.license?.limit_users && isAdmin?(
          <Button color="secondary" disabled={isAdmin === false} onClick={() =>  callToOpenUpdateUp(null)}>{t("invite_user","Uusi käyttäjä")}</Button>
          ):(
            <Button color="secondary" disabled={isAdmin === false} onClick={() => openModalEdit(null, "invitate")}>{t("invite_user","Uusi käyttäjä")}</Button>
          )}
          </div>
          
          <div className="p-2">
            
          </div>
        </div>

        
        <TableAuto
          showRowNumber={false}
          showId={true}
          checkboxes={false}
          headers={getHeaders("activated")}
          headerColor={"light"}
          rows={getTableRows("activated")}
          loading={loading}
          pagination={true}
          paginationPrevious={paginationPrevious}
          paginationNext={paginationNext}
          paginationOffset={filters?.offset}
          paginationLimit={filters?.limit}
          paginationCount={null}
          setFilters={setFilters}
        />
        
        <Modal
          title={t("portfolios_list_portfolio_title","Salkku")}
          isOpen={modalEditOpen}
          toggleModal={toggleModalEdit}
        >
          <PortfolioEdit
            objectId={modalEditId}
            handleResolve={modalEditResolve}
            handleReject={modalEditReject}
          />
        </Modal>

        <PageHeader title={t("Kutsuttu")}></PageHeader>

        <TableAuto
        showRowNumber={false}
        showId={true}
        checkboxes={false}
        headers={getHeaders("invitated")}
        headerColor={"light"}
        rows={getTableRows("invitated")}
        loading={loading}
        pagination={true}
        paginationPrevious={paginationPrevious}
        paginationNext={paginationNext}
        paginationOffset={filters?.offset}
        paginationLimit={filters?.limit}
        paginationCount={null} //users count WARNING THIS IS NULL RIGHT NOW
        setFilters={setFilters}
      />
      
      <Modal
        title={modalRenderComponent=== "invitate" ?t("send_invitation","Lähetä kutsu"): t("edit","Muokkaa")}
        isOpen={modalEditOpen}
        toggleModal={toggleModalEdit}
      >
        {modalRenderComponent === "invitate"
        ? <UserInvitate
          objectId={modalEditId}
          handleResolve={modalEditResolve}
          handleReject={modalEditReject}
          />
        : <UserEdit
          objectId={modalEditId}
          handleResolve={modalEditResolve}
          handleReject={modalEditReject}
          invitated_again={modalRenderComponent}
          />
      }
      </Modal>

      <Modal
        isOpen={modalSubscriptionEditOpen}
        toggleModal={toggleModalSubscriptionEdit}
        size="modal-xl"
        showHeader={false}
        classNameBody="p-0 padding-0"
      >
        <SubscriptionOptionsInfo
          objectId={modalSubscriptionEditId}
          handleResolve={modalSubscriptionEditResolve}
          handleReject={modalSubscriptionEditReject}
          custom_info_text= {t("subscription_users_info_text", "Käyttäjien ensimmäismäärä tilauksellanne on täyttynyt")}
        />
      </Modal>
    </>
  );
}

export default UsersList;
