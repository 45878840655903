import React, { useEffect, useState, useCallback } from "react";
import moment, { updateLocale } from "moment";
import { useTranslation } from 'react-i18next';
import { useStoreState, useStoreActions } from 'easy-peasy';

import { PageHeader, PageContainer } from "components/Page"
import { TableAuto } from "stories/tables"

import { useAppContext } from "contexts/AppContext"
import { useAuthContext } from "contexts/AuthContext"
import { useNotification } from "stories/components/Notification"


import { httpGetMyAccountPayments } from "services/accounts";
import FileDownloadButton from "components/Buttons/FileDownloadButton/FileDownloadButton"




//use appcontext to get user data



const Receipts = ({match, history, setCrumbLinks}) => {
  
  const { t } = useTranslation();
  const { getLicenseName } = useAppContext()
  const [loading, setLoading] = useState(false)

  const [payments, setPayments] = useState([])

  useEffect(() => {
    setCrumbLinks([
      {
        name: t("Etusivu"),
        url: "/dashboard",  
        },
      {name: t("Other"), url: "/dashboard/other/default_settings"},
      {name: t("account_receipts", "Maksukuitit"), url: "/dashboard/other/account_receipts"},
    ])

    //get account payments
    setLoading(true)
    httpGetMyAccountPayments()
    .then((response) => {
        setPayments(response?.data)
      console.log(response)
        setLoading(false)
    })
    .catch((error) => {
      console.log(error)
        setLoading(false)
    })


    }, [])

  const [filters, setFilters] = useState({
    limit: 30,
    offset:0,
  })

  
  // Table
  const [rows, setRows] = useState({
    count: 0,
    results: [],
  })


  
  const getHeaders = (param) => {
  const headers = [
    //   { label: t("receipts_created", "Luotu"), key: "created"},
    //   { label: t("receipts_paid", "Maksettu"), key: "paid"},
      { label: t("receipts_paid_datetime", "Maksu pvm."), key: "paid_datetime"},
      { label: t("receipts_time_period", "Ajanjakso"), key: "time_period"},
    //   { label: t("receipts_visma_order", "Visma"), key: "visma_order"},
    //   { label: t("receipts_receipt_pdf", "Pdf"), key: "receipt_pdf"},
      { label: t("receipts_license", "Palvelupaketti"), key: "license"},

    { label: t("receipts_actions", " "), key: "actions"},
  ];
  return headers
  }

  
  const handleChange = ({target}) => {
    setFilters({
      ...filters,
      [target.name]: target.value,
    })
  }
  
  const paginationPrevious = () => {
    let offset = filters?.offset - filters?.limit;
    if (offset < 0) {
      offset = 0;
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  const paginationNext = () => {
    let offset = filters?.offset + filters?.limit;
    if (offset > filters?.count) {
      return
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  

  
  const getTableRows = useCallback(() => {
    return payments && payments.map((row, index) => {
    const paid_datetime = row?.paid_datetime ? moment(row?.paid_datetime).format("DD.MM.YYYY") : ""
    // add 30 days to paid_datetime
    
    const time_period_end = moment(row?.paid_datetime).add(30, 'days').format("DD.MM.YYYY")

      return {
        ...row,
        // created: moment(row?.created).format("DD.MM.YYYY"),
        paid: row?.paid ? "Kyllä" : "Ei",
        paid_datetime: row?.paid_datetime ? paid_datetime: t("receipts_not_paid", "Ei maksettu"),
        license: getLicenseName(row?.license),
        identity_code: row?.business_identity_code,
        time_period: row?.paid_datetime ? paid_datetime + " - " + time_period_end: "",
        portfolios: "",
        actions: (
          <div className="d-flex justify-content-end">
            {row?.receipt_pdf && (
                <FileDownloadButton  showPreview={true} fileId={row?.id} fileName={row?.receipt_pdf} fileModel="AccountLicensePayment" />
            )    
            }
          </div>
        ),
      }
    })
  }, [payments])


  return (
    <>
        <div className="my-3">
            <PageHeader  title={t("account_receipts", "Maksukuitit")} />
        </div>

        
        <TableAuto
          showRowNumber={false}
          showId={true}
          checkboxes={false}
          headers={getHeaders()}
          headerColor={"light"}
          rows={getTableRows()}
          loading={loading}
          pagination={true}
          paginationPrevious={paginationPrevious}
          paginationNext={paginationNext}
          paginationOffset={filters?.offset}
          paginationLimit={filters?.limit}
          paginationCount={null}
          setFilters={setFilters}
        />
        



      


    </>
  );
}

export default Receipts;
