import React, { useCallback, useState, useEffect } from "react";
import { withFormik } from 'formik';
import * as Yup from "yup";
import { useTranslation } from 'react-i18next';

// core components
import { AlertModal, Button, Link, Typography } from "stories/components"
import { Card, CardBody, CardHeader, Col, Container, Row } from "stories/layout"
import { useNotification } from "stories/components/Notification"
import { cleanEmail } from "services/helpers"

import { useAuthContext } from "contexts/AuthContext"
import { httpGetLicenses } from "services/licenses" 
import logo from "assets/images/logoiv.png";


import RegisterPrivateAndBusinessForm from "components/Forms/RegisterForm/RegisterPrivateAndBusinessForm"
import Flags from "components/Icons/Flags";
// import  RegisterPrivate  from "components/Forms/RegisterPrivate/RegisterPrivate"

const RegisterView = ({match, history}) => {
  // API requests here
  
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [licenses, setLicenses] = useState();
  const { removeTokens } = useAuthContext()
  
  const getLicenses = () => {
    setLoading(true)
    httpGetLicenses().then(res => {
      setLicenses(res?.data);
    }).finally(response => {
      setLoading(false);
    })
  }
  
  /*
  useEffect(() => {
    if (match && match?.params?.id) {
      
    }
  }, [match])
  */
  
  useEffect(() => {
    removeTokens();
    getLicenses();
  }, [])
  
  return (
    <>
      <div className="d-flex  align-center flex-wrap">
        <div className="d-none d-sm-block">
          <Flags />
    </div>
  </div>

  <div className="d-none d-sm-block">

        <Container>
          <Row className="justify-content-center">
            <Col xl="10" lg="11" md="12" className="my-0 pb-2">
              { loading ? (
                <></>
                ) : (
                  <RegisterPrivateAndBusinessForm
                  history={history}
                  type="business"
                  licenses={licenses}
                  checkbox="sm1"
                  />
                  )}
            </Col>

          </Row>
        </Container>
  </div>
      <div className="d-block d-sm-none h-100">
        <div className="d-flex h-100">
          { loading ? (
            <></>
            ) : (
              <RegisterPrivateAndBusinessForm
              history={history}
              type="business"
              licenses={licenses}
              checkbox="xs2"
              />
              )}
          </div>
      </div>

    </>
  )
}
  

export default RegisterView;
