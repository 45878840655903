import { action, thunk, persist } from 'easy-peasy';
import { httpGetLicenses } from "services/licenses"

const model = {
  loading: false,
  setLoading: action((state, payload) => {
    state.loading = payload
  }),
  licenses: [],
  setLicenses: action((state, payload) => {
    state.licenses = payload
  }),
  getLicenses: thunk(async (actions, payload) => {
    actions.setLoading(true)
    httpGetLicenses().then(response => {
      if (response?.data) {
        actions.setLicenses(response?.data)
      }
    }, error => {
      
    }).finally(() => {
      actions.setLoading(false)
    })
    
  }),
};

export default model;