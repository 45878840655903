import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import { Row, Col, Container } from "stories/layout";


import TradingViewWidget from "components/TradingViewWidget/TradingViewWidget"
import TradingViewWidgetMini from "components/TradingViewWidget/TradingViewWidgetMini"
import TradingViewWidgetTechnicalAnalysis from "components/TradingViewWidget/TradingViewWidgetTechnicalAnalysis"
import TradingViewWidgetSingleTicker from "components/TradingViewWidget/TradingViewWidgetSingleTicker"
import TradingViewTopStories from "components/TradingViewWidget/TradingViewTopStories"
import HelpText from "components/Info/HelpText.js"
import { Typography } from "stories/components";




const Technicalanalysis = (props,{match, history,}) => {
  
  const {data} = props
  let symbol = data?.symbol
  let stock_market = data?.stock_market
  const { t } = useTranslation();

  if (stock_market == "XHEL") {
    stock_market = "OMXHEX"
    }
    if (stock_market == "XSTO") {
        stock_market = "OMXSTO"
    }
    if (stock_market == "XNAS") {
        stock_market = "NASDAQ"
    }
    if (stock_market == "XNYS") {
      stock_market = "NYSE"
    }
    if (stock_market == "XCSE") {
      stock_market = "OMXCOP"
    }

    //if symbol include space replace with _ for tradingview
    if (symbol?.includes(" ")) {
        symbol = symbol?.replace(" ", "_")
    }

  return (
    <div className=""  >
      <Row >
        <Col lg="4" md="6" className="mt-sm-4 mt-md-0">

          <TradingViewWidgetMini symbol= {`${stock_market}:${symbol}`} width="100%" height={370} color="light"/>
        </Col>
        <Col lg="4" md="6" className="mt-sm-4 mt-md-0">
          <TradingViewWidgetTechnicalAnalysis symbol= {`${stock_market}:${symbol}`} width="100%" height={370} color="light"/>
        </Col>
        <Col lg="4" md="12" className="mt-sm-4 mt-lg-0">
          
          <TradingViewTopStories symbol= {`${stock_market}:${symbol}`} width="100%" height={370} color="light"/>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col style={{ minHeight: "600px" }}>
          <div className="mb-1" style={{ display: "flex", justifyContent: "flex-end" }} >
            <HelpText  contentKey="technical_analysis" parentElementId="technical_analysis_help_text" showButton={true}  />
          </div>
          <TradingViewWidget symbol= {`${stock_market}:${symbol}`} />
        </Col>
      </Row>

    </div>
  );
}

export default Technicalanalysis;