import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useNotification } from "stories/components/Notification"

import { Typography, Button, Popover, Modal } from "stories/components";
import {
    httpDownloadFile
  } from "services/files"

  import {
    httpDownloadReceiptPdf
  } from "services/accounts"

import { set } from 'date-fns';
// import DocViewer from "react-doc-viewer";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";


const FileDownloadButton = ({ fileId, fileName, params, className, popoverTitle, variant, showPreview=false, fileModel="File"}) => {
  const [fileUrl, setFileUrl] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [docs, setDocs] = useState([]);
  const { notify } = useNotification();
  const { t } = useTranslation();

  useEffect(() => {
          // Assuming `fileExtension` contains the file extension (e.g., "xlsx", "png", etc.)
          let fileExtension = fileName.split('.').pop().toLowerCase();
          let fileType1 = 'application/octet-stream'; // default MIME type
      
          // Determine the MIME type based on the file extension
          if (fileExtension === 'xlsx') {
            fileType1 = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            setFileType(null)
          } else if (fileExtension === 'pdf') {
            fileType1 = 'application/pdf';
            setFileType(fileType1)
          }
          else if (fileExtension === 'png') {
            fileType1 = 'image/png';
            setFileType(fileType1)
          } else if (fileExtension === 'jpg' || fileExtension === 'jpeg') {
            fileType1 = 'image/jpeg';
            setFileType(fileType1)
          } // Add more conditions for other file types as needed
          //ms word file
          else if (fileExtension === 'docx') {
            fileType1 = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
            // setFileType(fileType1)
            setFileType(null)


          }
          else if (fileExtension === 'txt') {
            fileType1 = 'text/plain';
            setFileType(fileType1)
          }
          setDocs([])
          
  }, [fileName, fileId])
 

  const handlePreview = () => {
    openModal()
    if (docs && docs.length > 0) 
    {
      return;
    }
          // Assuming `fileExtension` contains the file extension (e.g., "xlsx", "png", etc.)

          // let a = document.createElement("a");
          // document.body.appendChild(a);

          // a.style = "display: none";

          let fileExtension = fileName.split('.').pop().toLowerCase();
          let fileTypePre = 'application/octet-stream'; // default MIME type
          console.log("fileExtension", fileExtension)
      
          // Determine the MIME type based on the file extension
          if (fileExtension === 'xlsx') {
            fileTypePre = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
          } else if (fileExtension === 'pdf') {
            fileTypePre = 'application/pdf';
          }
          else if (fileExtension === 'png') {
            fileTypePre = 'image/png';
          } else if (fileExtension === 'jpg' || fileExtension === 'jpeg') {
            fileTypePre = 'image/jpeg';
          } // Add more conditions for other file types as needed
          //ms word file
          else if (fileExtension === 'docx') {
            fileTypePre = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
            // fileTypePre = 'image/gif';
          }
          else if (fileExtension === 'txt') {
            fileTypePre = 'text/plain';
          }
    if (fileModel == "File"){
      
      httpDownloadFile(fileId, params)
      .then((response) => {
        // Check if the response is successful
        if (response) {
          let file = new Blob([response.data], {type: fileTypePre});
          let fileURL = URL.createObjectURL(file);
          setFileUrl(fileURL);
          // setDocs([{uri:fileURL}])
          setDocs([{ uri: fileURL, fileType: fileTypePre, fileName: " "}]);
          // setDocs([{ uri: fileURL, fileName: fileName}]);


           // Create a FileReader to convert Blob to Data URL
          //  let reader = new FileReader();
          //  reader.onloadend = () => {
          //      let fileURL = reader.result;
          //      setFileUrl(fileURL);
          //     //  let fileURL = `data:${fileType};base64,${reader.result.split(',')[1]}`;
          //     //  setFileUrl(fileURL);
          //     //  setDocs([{ uri: fileURL, fileType: fileType }]);
          //      setDocs([{ uri: fileURL, fileType: fileTypePre, fileName: fileName}]);
          //     //  setDocs([{ uri: fileURL}]);
          //  };
          //  reader.readAsDataURL(file);
          // a.href = fileURL;

        }
        // Handle error response
        else{

          throw new Error('Network response was not ok.');
        }
      })
      .catch((error) => {
        // Handle any errors
        console.error('There was an error!', error);
      });
    }

    if (fileModel == "AccountLicensePayment"){
      params = {
        payment_id: fileId
      }
      httpDownloadReceiptPdf(fileId, params)
      .then((response) => {
        // Check if the response is successful
        if (response) {
          let file = new Blob([response.data], {type: fileTypePre});
          let fileURL = URL.createObjectURL(file);
          setFileUrl(fileURL);
          // setDocs([{uri:fileURL}])
          setDocs([{ uri: fileURL, fileType: fileTypePre, fileName: " "}]);
          // setDocs([{ uri: fileURL, fileName: fileName}]);


           // Create a FileReader to convert Blob to Data URL
          //  let reader = new FileReader();
          //  reader.onloadend = () => {
          //      let fileURL = reader.result;
          //      setFileUrl(fileURL);
          //     //  let fileURL = `data:${fileType};base64,${reader.result.split(',')[1]}`;
          //     //  setFileUrl(fileURL);
          //     //  setDocs([{ uri: fileURL, fileType: fileType }]);
          //      setDocs([{ uri: fileURL, fileType: fileTypePre, fileName: fileName}]);
          //     //  setDocs([{ uri: fileURL}]);
          //  };
          //  reader.readAsDataURL(file);
          // a.href = fileURL;

        }
        // Handle error response
        else{

          throw new Error('Network response was not ok.');
        }
      })
      .catch((error) => {
        // Handle any errors
        console.error('There was an error!', error);
      });
    }
   
  };

  const handleDownload = async () => {

      try {
          // const response = await axios.get(`http://localhost:8000/api/files/download-files/${fileId}/`, {
          //   responseType: 'blob', // important for downloading files
          // });
    
    
        // let fileName = "investime_portfolio_summary_export.png";
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
    
        // Assuming `fileExtension` contains the file extension (e.g., "xlsx", "png", etc.)
        let fileExtension = fileName.split('.').pop().toLowerCase();
        let fileType = 'application/octet-stream'; // default MIME type
    
        // Determine the MIME type based on the file extension
        if (fileExtension === 'xlsx') {
          fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        } else if (fileExtension === 'pdf') {
          fileType = 'application/pdf';
        }
        else if (fileExtension === 'png') {
          fileType = 'image/png';
        } else if (fileExtension === 'jpg' || fileExtension === 'jpeg') {
          fileType = 'image/jpeg';
        } // Add more conditions for other file types as needed
        //ms word file
        else if (fileExtension === 'docx') {
          fileType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        }
          
          // setLoadingExport(true)
          notify({ title:t("Ladataan tiedostoa..."), icon: "fas fa-file-export"})
          
        if (fileModel == "File"){
            httpDownloadFile(fileId).then(response => {
              let file = new Blob([response.data], {type: fileType});
              let fileURL = window.URL.createObjectURL(file);
              a.href = fileURL;
              a.download = fileName;
              a.click();
            })
        }
        if (fileModel == "AccountLicensePayment"){
          params = {
            payment_id: fileId
          }
          httpDownloadReceiptPdf(fileId, params).then(response => {
            let file = new Blob([response.data], {type: fileType});
            let fileURL = window.URL.createObjectURL(file);
            a.href = fileURL;
            a.download = fileName;
            a.click();
          })
      }

      } catch (error) {
        // setError('Error downloading file.');
        console.error('There was an error!', error);
        notify({ title:t("Lataus epäonnistui"), icon: "fas fa-file-export"})


      }
    };


    const [modalOpen, setModalOpen] = useState(false)
    
    const toggleModal = () => {
        setModalOpen(s => !s);
    }
    
    const openModal = (id) => {
        setModalOpen(true)
    }
    



    
    const togglePopover = () => {
      setIsPopoverOpen(!isPopoverOpen);
    };
  
    const handleClose = () => {
      setIsPopoverOpen(false);
    };

  const getDocsViewer = useCallback(() => {
      return (
        <DocViewer 
        pluginRenderers={DocViewerRenderers} 
        documents={docs}
        // initialActiveDocument={docs[1]}
        style={{maxHeight: "650px"}}
        // prefetchMethod="GET"
        /> 
      )
  }, [docs])


  const get_prview_button = useCallback(() => {
    return(
      <>
    {showPreview    && fileType &&
      <>
      <Button id={`preview-${fileId}`}   className={className?className:"text-white font-weight-light rounded-circle custom-xs-button-input  b-0"}  style={{position:"relative", zIndex: 0,}}  variant={variant?variant:"lighter"}  onClick={handlePreview}> <i className="fas fa-eye text-primary" /></Button>
      {/* <Popover
          placement="left"
          target={`preview-${fileId}`}
          // trigger="click"
          trigger="legacy"
          // onClick={togglePopover}
          // onClick={() => setFileUrl(null)}
          >       
          <div className="text-dark mb-1" variant="medium" style={{height: "450px", width:"750px"}}>
                {getDocsViewer()}
          </div>
      </Popover> */}
      </> 

    }
    </>
    )
  }, [showPreview, fileType, fileName])

  return (
    <div className="d-flex">
      {fileUrl && false? (
        // Show the preview if a preview URL is available
        <>
        {/* <Button id={`preview-${fileId}`}  disabled={fileType && !fileType.includes('image')} className={className?className:"text-white font-weight-light rounded-circle custom-xs-button-input  b-0"}  style={{position:"relative", zIndex: 9999,}}  variant={variant?variant:"lighter"} onClick={() => setFileUrl(null)}>
           
        <img src={fileUrl} alt="File Preview" style={{zIndex:9999, height: "150px", width:"150px",cursor: "pointer",borderRadius:"10%", border: "2px solid #8898aa"}} onClick={() => setFileUrl(null)}/>
           </Button> */}


        {/* <DocViewer pluginRenderers={DocViewerRenderers} documents={[{uri:fileUrl}]}/> */}
        </>
      ) : (
        // Show the download button if no preview URL is available
        <>
        {/* {showPreview   && fileType && fileType.includes('image') && */}
        {get_prview_button()}

        </>
        // <button onClick={handlePreview}>Preview File</button>
        // <></>
      )}
      {/* <Button onClick={handleDownload}>Download File</Button> */}

      <Button id={`downloadFile-${fileId}`} className={className?className:"text-white font-weight-light rounded-circle custom-xs-button-input  b-0"}  style={{position:"relative", zIndex: 0}}  variant={variant?variant:"lighter"} onClick={handleDownload} >
        {/* <i className="fas fa-trash-alt text-danger bg-white fa-xl"  /> */}
        <i className="fas fa-file-download text-primary" />
      </Button>
            <Popover
          placement="left"
          target={`downloadFile-${fileId}`}
          >       
          <Typography className="text-dark mb-1" variant="medium">
              {popoverTitle? popoverTitle: t("file_download_download",'Lataa')}
          </Typography>  
      </Popover>

      <Modal
                title={fileName}
                isOpen={modalOpen}
                toggleModal={toggleModal}
                size="modal-xl"
                // showHeader={false}
                // classNameBody="p-0 padding-0"
            >
                  {getDocsViewer()}


            </Modal>
    </div>
  );
};

export default FileDownloadButton;






// const FileDownloader = () => {
//   const [fileId, setFileId] = useState('');
//   const [error, setError] = useState('');
  
//   const handleInputChange = (event) => {
//     setFileId(event.target.value);
//   };

//   const handleDownload = async () => {
    
//     try {
//       // const response = await axios.get(`http://localhost:8000/api/files/download-files/${fileId}/`, {
//       //   responseType: 'blob', // important for downloading files
//       // });


//       let fileName = "investime_portfolio_summary_export.png";
//       let a = document.createElement("a");
//       document.body.appendChild(a);
//       a.style = "display: none";

//           // Assuming `fileExtension` contains the file extension (e.g., "xlsx", "png", etc.)
//     let fileExtension = fileName.split('.').pop().toLowerCase();
//     let fileType = 'application/octet-stream'; // default MIME type

//     // Determine the MIME type based on the file extension
//     if (fileExtension === 'xlsx') {
//       fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
//     } else if (fileExtension === 'png') {
//       fileType = 'image/png';
//     } else if (fileExtension === 'jpg' || fileExtension === 'jpeg') {
//       fileType = 'image/jpeg';
//     } // Add more conditions for other file types as needed
      
//       // setLoadingExport(true)
//       // notify({ title:t("Viedään Exceliin..."), icon: "fas fa-file-export"})
//       httpDownloadFile(fileId).then(response => {
//         let file = new Blob([response.data], {type: fileType});
//         let fileURL = window.URL.createObjectURL(file);
//         a.href = fileURL;
//         a.download = fileName;
//         a.click();
//       })
//     } catch (error) {
//       setError('Error downloading file.');
//     }
//   };

//   return (
//     <div>
//       <input type="text" value={fileId} onChange={handleInputChange} placeholder="Enter File ID" />
//       <button onClick={handleDownload}>Download File</button>
//       {error && <p>{error}</p>}
//     </div>
//   );
// };

// export default FileDownloader;




// import React, { useState } from 'react';
// import {
//   httpDownloadFile
// } from "services/files";





// import React from 'react';
// import {
//     httpDownloadFile
//   } from "services/files"

// const FileDownloadButton = ({ fileId, fileName, params }) => {
//   const handleDownload = () => {
//     httpDownloadFile(fileId, params)
//       .then((response) => {
//         // Check if the response is successful
//         if (response.ok) {
//           // Convert response to blob
//           return response.blob();
//         }
//         // Handle error response
//         throw new Error('Network response was not ok.');
//       })
//       .then((blob) => {
//         // Create URL object from the blob
//         const url = window.URL.createObjectURL(blob);
//         // Create an anchor element
//         const a = document.createElement('a');
//         // Set the anchor's href attribute to the URL
//         a.href = url;
//         // Set the anchor's download attribute to the file name
//         a.download = fileName;
//         // Append the anchor element to the body
//         document.body.appendChild(a);
//         // Click the anchor to trigger the download
//         a.click();
//         // Remove the anchor element from the body
//         document.body.removeChild(a);
//         // Revoke the URL object to free up resources
//         window.URL.revokeObjectURL(url);
//       })
//       .catch((error) => {
//         // Handle any errors
//         console.error('There was an error!', error);
//       });
//   };

//   return (

//     <button onClick={handleDownload}>Download File</button>
//   );
// };

// export default FileDownloadButton;



// const handleExcelExport = () => {
//     // many portfolios selected
//     const put_together = filters?.selected_portfolios?.length > 1 ? true : false

//     const params = {
//       ...filters,
//     }
    
  //   let fileName = "investime_portfolio_summary_export.xlsx";
  //   let a = document.createElement("a");
  //   document.body.appendChild(a);
  //   a.style = "display: none";
    
  //   setLoadingExport(true)
  //   notify({ title:t("Viedään Exceliin..."), icon: "fas fa-file-export"})
  //   httpGetPortfolioSummaryExcel(params).then(response => {
  //     let file = new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
  //     let fileURL = window.URL.createObjectURL(file);
  //     a.href = fileURL;
  //     a.download = fileName;
  //     a.click();
  //   })
  //   .finally(() => {
  //     setLoadingExport(false)
  //   })
  // }