import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import './Link.css';

export const Link = ({ children, href=null, className, isExternal, ...rest }) => {
  const classNameCommon = `cursor_pointer ${className ? className : ""}`;
  
  let externalProps = {};
  if (isExternal) {
    externalProps["noreferer"] = "noreferer";
    externalProps["target"] = "_blank";
  }
  
  if (href) {
    return (
      <a 
        className={`${classNameCommon}`}
        href={href}
        {...externalProps}
        {...rest}
      >
      {children}
      </a>
    )
  }
  else {
    return (
      <RouterLink 
        className={`${classNameCommon}`}
        {...rest}
      >
      {children}
      </RouterLink>
    )
  }
};

Link.propTypes = {
  // External link
  href: function(props, propName, componentName) {
    if ((props['isExternal'] === true && (props[propName] == undefined || typeof(props[propName]) !== 'string'))) {
        return new Error('Please provide a href!');
    }
  },
  isExternal: PropTypes.bool,
  // Router link
  to: function(props, propName, componentName) {
    if ((props['isExternal'] === false && (props[propName] == undefined || typeof(props[propName]) !== 'string'))) {
        return new Error('Please provide a to!');
    }
  },
};


Link.defaultProps = {
  isExternal: false,
};

