import React, { useState, useCallback, useEffect } from "react";
import { withFormik } from 'formik';
import * as Yup from "yup";
import moment from "moment"
import { useTranslation } from 'react-i18next';
import { useStoreState, useStoreActions } from 'easy-peasy';

import { Button, Typography, Spinner, Modal, Link } from "stories/components";
import { Container, Row, Col, FormContainer, Card, CardBody } from "stories/layout";
import { Input, SelectMulti, Toggle, Radio, DatePicker, Checkbox } from "stories/forms";
import { useNotification } from "stories/components/Notification"
import CurrencySelect from "components/Forms/CurrencySelect/CurrencySelect"
import SectorSelect from "components/Forms/SectorSelect/SectorSelect"
import SectorEdit from "views/app/SectorEdit/SectorEdit"
import RealEstateDataInput from "./RealEstateDataInput";

import InvestmentTypeSelectSub from "components/Forms/InvestmentTypeSelectSub/InvestmentTypeSelectSub"

import { useAppContext } from "contexts/AppContext"
import SplitCheckbox from "components/Forms/SplitCheckbox/SplitCheckbox"

import { httpGetTransactions, httpGetPrivateStockTransactions, httpGetTransactionsExcel, httpUploadTransactionsExcel } from "services/transactions"
import { httpGetDividends } from "services/dividends"
import { round, roundScaleDecimals, thousandFormat } from "services/helpers"

import PrivateInvestmentFundDividendEdit from "views/app/PrivateInvestmentFundDividendEdit/RealEstateDividendEdit"






import { 
  httpGetPrivateInvestmentFund, 
  httpCreatePrivateInvestmentFund, 
  httpUpdatePrivateInvestmentFund, 
  httpDeletePrivateInvestmentFund 
} from "services/private_investment_funds"
import { set } from "date-fns";
import { tr } from "date-fns/locale";

const defaultValues = {
  id: null,
  name: "",
}

const PrivateInvestmentFundEdit = (props) => {

  
  const {
     values,
     setValues,
     touched,
     setTouched,
     errors,
     setErrors,
     handleChange,
     handleBlur,
     handleSubmit,
     setFieldValue,
     isTouched,
     isSubmitting,
     validateForm,
     preSetValues
   } = props;
   
   const {
      t,
      history,
      objectId,
      selectedSector,
      setSelectedSector,
      transactions,
      dividends,
      getDividends,
   } = props;

   
   const getFormikInputProps = useCallback(
    name => ({
      name,
      value: values[name],
      error: Boolean(touched[name] && errors[name]),
      errorText: touched[name] ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange
    }),
    [values, errors, touched, handleBlur, handleChange]
  );
  
  const account = useStoreState((state) => state.account?.account);
  const getSectors = useStoreActions((actions) => actions.sectors.getSectors);




    // Modal: New/Edit Sector

    const [modalEditOpen, setModalEditOpen] = useState(false)
    const [modalEditId, setModalEditId] = useState()
    
    const toggleModalEdit = () => {
      setModalEditOpen(s => !s);
    }
    
    const openModalEdit = (id) => {
      setModalEditOpen(true)
      setModalEditId(id);
    }
    
    const modalEditResolve = () => {
      setModalEditOpen(false);
      setModalEditId(null);
      getSectors()
    }
    
    const modalEditReject = () => {
      setModalEditOpen(false);
      setModalEditId(null);
    }

  

  useEffect(() => {
    if (transactions) {
      setFieldValue("purchase_cost", transactions?.purchase_cost)
      setFieldValue("commission_total", transactions?.commission_total)

    }
  }, [transactions])

  const onSubmit = (e) => {
    e.preventDefault();
    validateForm().then(res => {
      if (Object.keys(res).length === 0) {
        handleSubmit()
      }
      else {
        setTouched(res);
        setErrors({
          ...res,
          general: t("Please check the form errors", "Tarkista lomakkeen virheet")
        })
      }
    })
  }
  
  const handleCancel = () => {
    props.handleReject()
  }
  
  const handleDelete = () => {
    const result = window.confirm(t("are_you_sure_window_confirm","Oletko varma?"));
    if (result) {
      httpDeletePrivateInvestmentFund(objectId).then(() => {
        props.handleResolve()
      })
    }
  }


  const handleSectorChange = (name, value) => {
    setSelectedSector(s => ({
      ...s,
      [name]: value
    }))
  }
  

       // Modal: New/Edit Dividend
  
       const [modalEditDividendOpen, setModalEditDividendOpen] = useState(false)
       const [modalEditDividendId, setModalEditDividendId] = useState()
       const [investmentType, setInvestmentType] = useState("")
       const [tickerSymbol, setTickerSymbol] = useState('')
       const [filters, setFilters] = useState({})
       const [showExtraField, setShowExtraField] = useState(false)
   
       
       const toggleModalEditDividend = () => {
           setModalEditDividendOpen(s => !s);
       }
       
       const openModalEditDividend = (id, related_model_type, ticker_symbol) => {
           setModalEditDividendOpen(true)
           setModalEditDividendId(id);
           setInvestmentType(related_model_type)
           setTickerSymbol(ticker_symbol)
       
       }
       
       const modalEditDividendResolve = () => {
           console.log("modalEditDividendResolve")
           setModalEditDividendOpen(false);
           setModalEditDividendId(null);
            getDividends()
          //  handleCancel()
           
           // getTransactions()
       }
       
       const modalEditDividendReject = () => {
           setModalEditDividendOpen(false);
           setModalEditDividendId(null);
          //  handleCancel()
       }

  const getSectorSelect = useCallback(() => {
    return (
      <SectorSelect
        {...getFormikInputProps("sector_select")}
        label={`${t("PrivateInvestmentFundEdit_sector", "Kategoria")} ` }
        multiple={false}
        onChange={handleSectorChange}
        value={selectedSector?.sector_select}
        setFieldValue={handleSectorChange}
      />
    )
  }, [selectedSector])

  return (
    <>
    {/* {JSON.stringify(transactions)} */}
    <Row className="mt-2 mb-2 border-bottom">
        <Col xs={12} md={6} className="ml-auto pb-3 pl-3 d-flex align-items-center">
            <Typography variant="h2"  >{t("purchase_order_edit_title", "Kohteen tiedot")}</Typography>

        </Col>
        <Col xs={12} md={6} className="ml-auto pb-3">
        {/*  <PortfolioSelectStore icon="fas fa-suitcase" iconColor="text-secondary" disabled={true}/> */}
        </Col>
    </Row>

    {values?.type =="own_loans" ?(
      <>
        <Row>
          <Col className="mb-3">
            <Input
              {...getFormikInputProps("name")}
              label={values?.type =="index" ?t("PrivateInvestmentFundEdit_name", "Osoite"): t("PrivateInvestmentFundEdit_name", "Nimi")}
              // required
            />
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <Input
              {...getFormikInputProps("symbol")}
              label={values?.type=="index"?t("PrivateInvestmentFundEdit_symbol", "Postinumero"): t("PrivateInvestmentFundEdit_symbol", "Tunnus / rekisterinumero")}
            />
          </Col>
        </Row>
      </>
    ):(

      <>


      <Row  className="pb-3 pt-2 ">
        <Col xs={12} md={12} lg={4} className="border-right px-4 pt-0">
      <Row>
        <Col className="mb-3">
          <Input
            {...getFormikInputProps("name")}
            label={values?.type =="index" ?t("PrivateInvestmentFundEdit_name", "Osoite"): t("PrivateInvestmentFundEdit_name", "Nimi")}
            // required
          />
        </Col>
      </Row>
      <Row>
        <Col className="mb-3">
          <Input
            {...getFormikInputProps("symbol")}
            label={values?.type=="index"?t("PrivateInvestmentFundEdit_symbol", "Postinumero"): t("PrivateInvestmentFundEdit_symbol", "Tunnus / rekisterinumero")}
          />
        </Col>
      </Row>
      {
        values?.type == "index" &&
      <Row>
        <Col className="mb-3">
          <Input
            {...getFormikInputProps("stock_market")}
            label={` ${t("PrivateInvestmentFundEdit_stock_market", "Kaupunki")} ` }
            />
        </Col>
      </Row>
          }
      <Row>
        <Col className="mb-3">
          <Input
            {...getFormikInputProps("ownership_percentage")}
            label={` ${t("PrivateInvestmentFundEdit_ownership_percentage", "Omistus osuus")} ` }
            placeholder="100"
            addonTypeClass="append" 
            iconColor={"bg-light"} 
            inputText={t("  %  ")}

          />
        </Col>
      </Row>
      {/* <Row>
        <Col className="mb-3">
          <InvestmentTypeSelectSub 
          {...getFormikInputProps("type")}

          required
          />

        </Col>
      </Row> */}

      {/* <Row className="mb-3">
    
          <Col>
            <div  calssName="text-blue" onClick={() => openModalEdit()} style={{ cursor: 'pointer' }}>
            <Typography variant="small">  {t("sector_create_here","Luo kategoria tästä")}</Typography>
            </div>
          </Col>
        </Row> */}

      

      {/* <Row>
        <Col className="mb-3">
          <Input
            {...getFormikInputProps("size")}
            label={t("PrivateInvestmentFundEdit_size", "Arvopaperin koko")}
            type="number"
          />
        </Col>
      </Row> */}

      
      
      {/* <Row>
        <Col className="mb-3">
          <CurrencySelect 
            {...getFormikInputProps("currency_label")} 
            label={t("PrivateInvestmentFundEdit_currency", "Valuutta")}
            // required
            disabled={objectId? true: false}

          />
          { !objectId &&
            <Typography variant="small">{t("Valuutta tietoa ei voi muokata myöhemmin")} </Typography>
          }
          
        </Col>
      </Row> */}



      
      

      <Row className="mt-5">
            <Col className="mb-0">
                <label className="mb-1 pr-1 header-bold">{t("purchase_information", "Vuokraajan tiedot")}</label>
            </Col>
        </Row>
        <Row className="mt-3">
            <Col className="mb-0">
              <Input
                {...getFormikInputProps("renter_name")}
                placeholder={t("renter_name", "Vuokralaisen nimi")}
              />
            </Col>
        </Row>
        <Row className="mt-3">
            <Col className="mb-0">
              <Input
                {...getFormikInputProps("renter_identifier")}
                placeholder={t("renter_name", "Vuokralaisen henkilö tai Y-tunnus")}
              />
            </Col>
        </Row>
        <Row className="mt-3">
            <Col className="mb-0">
              <Input
                {...getFormikInputProps("renter_email")}
                placeholder={t("renter_email", "sähköposti")}
              />
            </Col>
        </Row>
        <Row className="mt-3">
            <Col className="mb-0">
              <Input
                {...getFormikInputProps("renter_number")}
                placeholder={t("renter_number", "Puhelin")}
              />
            </Col>
        </Row>
        <Row className="mt-3">
            <Col className="mb-0">
              <Input
                {...getFormikInputProps("renter_rent")}
                placeholder={t("renter_rent", "Vuokra / kk")}
              />
            </Col>
        </Row>
        <Row className="mt-3">
            <Col className="mb-0">
              <Input
                {...getFormikInputProps("renter_rent_square_percent")}
                placeholder={t("renter_rent", "Vuokrattu pinta-ala")}
                addonTypeClass="append" iconColor={"bg-light"} inputText={t(" % ")}
              />
            </Col>
        </Row>
        <Row className="mt-3">
            <Col className="mb-0">
    
            <DatePicker
                selected={values?.renter_start_date}
                name="renter_start_date"
                onChange={(date) => setFieldValue("renter_start_date", date)}
                showYearDropdown
                dateFormat="d.M.yyyy"
                value={values?.renter_start_date}
                customInput={<Input addonTypeClass="append" iconColor={"bg-light"} inputText={t("Alkaen")}/>}
                placeholderText={t("Vuokrattu")}
              />
            </Col>
        </Row>
        <Row className="mt-3">
            <Col className="mb-0">
              <Input
                {...getFormikInputProps("renter_notes")}
                placeholder={t("renter_notes", "Lisätiedot")}
              />
            </Col>
        </Row>
        <Row>
            <Col className="my-3 mt-4">
                <label className="mb-1 pr-1 header-bold ">{t("purchase_information", "Liitteet")}</label>
            </Col>
        </Row>
        <Row className="mt-3">
            <Col className="mb-0">
              <Input
                {...getFormikInputProps("renter_notes")}
                placeholder={t("renter_notes", "Lataa koneelta")}
                addonTypeClass="append" iconColor={"bg-light"} inputText={<i className="fas fa-file-alt"></i>}
              />
            <div className="d-flex">
                <Typography variant="small"  className= "text-gray pr-1 ml-auto" >PDF, Excel, Png (max 15mt)</Typography>

              </div>
            </Col>
        </Row>
        <Row className="mt-3">
            <Col className="mb-0">
              <Input
                {...getFormikInputProps("document_rent_agreement")}
                placeholder={t("document_rent_agreement", "Vuokrasopimus")}
              />
            </Col>
        </Row>
        <Row className="mt-3">
            <Col className="mb-0">
              <Input
                {...getFormikInputProps("document_loan")}
                placeholder={t("document_loan", "Pankkilaina")}
              />
            </Col>
        </Row>

    </Col>
    <Col xs="12" lg="4" className="px-4 pt-0">
        <Row>
            <Col className="mb-3">
                <label className="mb-1 pr-1 header-bold text-success">{t("purchase_information", "Säännölliset tulot")}</label>
            </Col>

            <Col>
            <Button className="text-white font-weight-light rounded-circle custom-xs-button bg-secondary b-0"  variant="lighter"  size="sm" onClick={() => openModalEditDividend(null, "XHEL", null)}>
                <i className="fas fa-plus "  />
                </Button>
            </Col>
        </Row>

        {
          dividends && dividends?.incomes.map((dividend, index) => {
            return (
              <div key={index}>
              <Row>
                  <Col className="mb-0">
                  <label className="mb-0 pr-1   ">{dividend.name}</label>
                  </Col>
              </Row>
              <Row className="mb-3">
                  <Col className="mb-0 success-placeholder">

                    <Input
                    className="text-success"
                      {...getFormikInputProps(`income_rent${index}`)}
                      placeholder={t("income_rent", "000.00")}
                      value={roundScaleDecimals(dividend.rate,2)}
                      disabled={true}
                    />
                  </Col>
                  <Col className="mb-0 d-flex justify-content-end align-items-center">
                  <div className="d-flex align-items-center" >
                    <RealEstateDataInput values={values} setFieldValue={setFieldValue} name="incomes" index={index} />

                  </div>
                  </Col>
              </Row>
              </div>
            )
          }
          )
        }
        {/* <Row>
            <Col className="mb-0">
            <label className="mb-0 pr-1   ">{t("purchase_information", "Vuokratulo alv")}</label>
            </Col>
        </Row>
        <Row className="mb-3">
            <Col className="mb-0 success-placeholder">

              <Input
              className="text-success"
                {...getFormikInputProps("income_rent")}
                placeholder={t("income_rent", "000.00")}
              />
            </Col>
            <Col className="mb-0 d-flex justify-content-end align-items-center">
            <div className="d-flex align-items-center" >
                <Typography variant="small"  className= "ml-auto mr-2" >{t("purchase_information", "Kirjaa tulo automaattisesti")}</Typography>
                
                  <Checkbox 
                    type="checkbox"
                    id={"rent_income"}
                    name="fifo_used"
                    checked={values?.fifo_used}
                    onChange={(e) => {setFieldValue("fifo_used",e.target.checked)}}
                    value="agree_terms"
                    label = {<Typography className="text-warning"></Typography>}
                  />
            </div>
            </Col>
        </Row>
        <Row>
            <Col className="mb-0 ">
            <label className="mb-0 pr-1  ">{t("purchase_information", "Muu tulo")}</label>
            </Col>
        </Row>
        <Row className="mt-0">
            <Col className="mb-0 success-placeholder">

              <Input
                className="text-success"
                {...getFormikInputProps("income_rent_other")}
                placeholder={t("income_rent", "000.00")}
              />
            </Col>
            <Col className="mb-0 d-flex justify-content-end align-items-center">
            <div className="d-flex align-items-center" >
                <Typography variant="small"  className= "ml-auto mr-2" >{t("purchase_information", "Kirjaa tulo automaattisesti")}</Typography>
                
                  <Checkbox 
                    type="checkbox"
                    id={"rent_income_other"}
                    name="rent_income_other"
                    checked={values?.rent_income_other}
                    onChange={(e) => {setFieldValue("rent_income_other",e.target.checked)}}
                    value="agree_terms"
                    label = {<Typography className="text-warning"></Typography>}
                  />
            </div>
            </Col>
        </Row> */}
        <Row>
            <Col className=" mb-3">
                <label className="mb-1 pr-1 header-bold text-danger">{t("purchase_information", "Säännölliset menot")}</label>
            </Col>
            <Col>
            <Button className="text-white font-weight-light rounded-circle custom-xs-button bg-secondary b-0"  variant="lighter"  size="sm" onClick={() => openModalEditDividend(null, "XHEL", null)}>
                <i className="fas fa-plus "  />
                </Button>
            </Col>
        </Row>

        {
          dividends && dividends?.expenses.map((dividend, index) => {
            return (
              <div key={index}>
              <Row>
                  <Col className="mb-0">
                  <label className="mb-0 pr-1   ">{dividend.name}</label>
                  </Col>
              </Row>
              <Row className="mb-3">
                  <Col className="mb-0 success-placeholder">

                    <Input
                    className="text-danger"
                      {...getFormikInputProps(`income_rent${index}`)}
                      placeholder={t("income_rent", "000.00")}
                      value={roundScaleDecimals(dividend.rate,2)}
                      disabled={true}
                    />
                  </Col>
                  <Col className="mb-0 d-flex justify-content-end align-items-center">
                  <div className="d-flex align-items-center" >
                    <RealEstateDataInput values={values} setFieldValue={setFieldValue} name="expenses" index={index} />

                  </div>
                  </Col>
              </Row>
              </div>
            )
          }
          )
        }




        {/* <Row>
            <Col className="mb-0">
            <label className="mb-0 pr-1  ">{t("purchase_information", "Yhtiövastike")}</label>
            </Col>
        </Row>
        <Row className="mt-0">
            <Col className="mb-0 danger-placeholder">

              <Input
                className="text-danger"
                {...getFormikInputProps("expenses_consideration")}
                placeholder={t("expenses_consideration", "000.00")}
              />
            </Col>
            <Col className="mb-0 d-flex justify-content-end align-items-center">
            <div className="d-flex align-items-center" >
                <Typography variant="small"  className= "ml-auto mr-2" >{t("purchase_information", "Kirjaa meno automaattisesti")}</Typography>
                
                  <Checkbox 
                    type="checkbox"
                    id={"expenses_consideration_check"}
                    name="expenses_consideration_check"
                    checked={values?.expenses_consideration_check}
                    onChange={(e) => {setFieldValue("expenses_consideration_check",e.target.checked)}}
                    value="agree_terms"
                    label = {<Typography className="text-warning"></Typography>}
                  />
            </div>
            </Col>
        </Row>
        <Row>
            <Col className="mt-3">
            <label className="mb-0 pr-1  ">{t("purchase_information", "Rahoitusvastike")}</label>
            </Col>
        </Row>
        <Row className="mt-0">
            <Col className="mb-0 danger-placeholder">

              <Input
                className="text-danger"
                {...getFormikInputProps("expenses_financial_consideration")}
                placeholder={t("expenses_financial_consideration", "000.00")}
              />
            </Col>
            <Col className="mb-0 d-flex justify-content-end align-items-center">
            <div className="d-flex align-items-center" >
                <Typography variant="small"  className= "ml-auto mr-2" >{t("purchase_information", "Kirjaa meno automaattisesti")}</Typography>
                
                  <Checkbox 
                    type="checkbox"
                    id={"expenses_financial_consideration_check"}
                    name="expenses_financial_consideration_check"
                    checked={values?.expenses_financial_consideration_check}
                    onChange={(e) => {setFieldValue("expenses_financial_consideration_check",e.target.checked)}}
                    value="agree_terms"
                    label = {<Typography className="text-warning"></Typography>}
                  />
            </div>
            </Col>
        </Row>
        <Row>
            <Col className="mt-3">
            <label className="mb-0 pr-1  ">{t("purchase_information", "Vakuutus")}</label>
            </Col>
        </Row>
        <Row className="mt-0">
            <Col className="mb-0 danger-placeholder">

              <Input
                className="text-danger"
                {...getFormikInputProps("expenses_insurances")}
                placeholder={t("expenses_insurances", "000.00")}
              />
            </Col>
            <Col className="mb-0 d-flex justify-content-end align-items-center">
            <div className="d-flex align-items-center" >
                <Typography variant="small"  className= "ml-auto mr-2" >{t("purchase_information", "Kirjaa meno automaattisesti")}</Typography>
                
                  <Checkbox 
                    type="checkbox"
                    id={"expenses_insurances_check"}
                    name="expenses_insurances_check"
                    checked={values?.expenses_insurances_check}
                    onChange={(e) => {setFieldValue("expenses_insurances_check",e.target.checked)}}
                    value="agree_terms"
                    label = {<Typography className="text-warning"></Typography>}
                  />
            </div>
            </Col>
        </Row>
      

        <Row>
            <Col className="mt-3">
            <label className="mb-0 pr-1  ">{t("purchase_information", "Vesimaksu")}</label>
            </Col>
        </Row>
        <Row className="mt-0">
            <Col className="mb-0 danger-placeholder">

              <Input
                className="text-danger"
                {...getFormikInputProps("expenses_water_charge")}
                placeholder={t("expenses_water_charge", "000.00")}
              />
            </Col>
            <Col className="mb-0 d-flex justify-content-end align-items-center">
            <div className="d-flex align-items-center" >
                <Typography variant="small"  className= "ml-auto mr-2" >{t("purchase_information", "Kirjaa meno automaattisesti")}</Typography>
                
                  <Checkbox 
                    type="checkbox"
                    id={"expenses_water_charge_check"}
                    name="expenses_water_charge_check"
                    checked={values?.expenses_water_charge_check}
                    onChange={(e) => {setFieldValue("expenses_water_charge_check",e.target.checked)}}
                    value="agree_terms"
                    label = {<Typography className="text-warning"></Typography>}
                  />
            </div>
            </Col>
        </Row>

        <Row>
            <Col className="mt-3">
            <label className="mb-0 pr-1  ">{t("purchase_information", "Sähkömaksu")}</label>
            </Col>
        </Row>
        <Row className="mt-0">
            <Col className="mb-0 danger-placeholder">

              <Input
                className="text-danger"
                {...getFormikInputProps("expenses_electric_charge")}
                placeholder={t("expenses_electric_charge", "000.00")}
              />
            </Col>
            <Col className="mb-0 d-flex justify-content-end align-items-center">
            <div className="d-flex align-items-center" >
                <Typography variant="small"  className= "ml-auto mr-2" >{t("purchase_information", "Kirjaa meno automaattisesti")}</Typography>
                
                  <Checkbox 
                    type="checkbox"
                    id={"expenses_electric_charge_check"}
                    name="expenses_electric_charge_check"
                    checked={values?.expenses_electric_charge_check}
                    onChange={(e) => {setFieldValue("expenses_electric_charge_check",e.target.checked)}}
                    value="agree_terms"
                    label = {<Typography className="text-warning"></Typography>}
                  />
            </div>
            </Col>
        </Row>

        <Row>
            <Col className="mt-3">
            <label className="mb-0 pr-1  ">{t("purchase_information", "Muut kulut")}</label>
            </Col>
        </Row>
        <Row className="mt-0">
            <Col className="mb-0 danger-placeholder">

              <Input
                className="text-danger"
                {...getFormikInputProps("expenses_other_charge")}
                placeholder={t("expenses_other_charge", "000.00")}
              />
            </Col>
            <Col className="mb-0 d-flex justify-content-end align-items-center">
            <div className="d-flex align-items-center" >
                <Typography variant="small"  className= "ml-auto mr-2" >{t("purchase_information", "Kirjaa meno automaattisesti")}</Typography>
                
                  <Checkbox 
                    type="checkbox"
                    id={"expenses_other_charge_check"}
                    name="expenses_other_charge_check"
                    checked={values?.expenses_other_charge_check}
                    onChange={(e) => {setFieldValue("expenses_other_charge_check",e.target.checked)}}
                    value="agree_terms"
                    label = {<Typography className="text-warning"></Typography>}
                  />
            </div>
            </Col>
        </Row> */}
        <Row>
            <Col className="mt-5">
                <label className="mb-1 pr-1 header-bold text-danger ">{t("purchase_information", "Kohteen hankkimiskulut")}</label>
            </Col>
        </Row>
        <Row>
            <Col className="mt-3">
            <label className="mb-0 pr-1  ">{t("purchase_information", "Hankintahinta")}</label>
            </Col>
        </Row>
        <Row className="mt-0">
            <Col className="mb-0 danger-placeholder">

              <Input
                className="text-danger"
                {...getFormikInputProps("purchase_cost")}
                placeholder={t("purchase_cost", "000.00")}
                disabled={true}
              />
            </Col>
            <Col className="mb-0 d-flex justify-content-end align-items-center">
            <div className="d-flex align-items-center" >
                <Typography variant="small"  className= "ml-auto mr-2" >{t("purchase_information", "Kirjaa meno automaattisesti")}</Typography>
                
                  <Checkbox 
                    type="checkbox"
                    id={"purchase_cost_check"}
                    name="purchase_cost_check"
                    checked={values?.purchase_cost_check}
                    onChange={(e) => {setFieldValue("purchase_cost_check",e.target.checked)}}
                    value="agree_terms"
                    label = {<Typography className="text-warning"></Typography>}
                  />
            </div>
            </Col>
        </Row>
        <Row>
            <Col className="mt-3">
            <label className="mb-0 pr-1  ">{t("purchase_information", "Varainsiirtovero")}</label>
            </Col>
        </Row>
        <Row className="mt-0">
            <Col className="mb-0 danger-placeholder">

              <Input
                className="text-danger"
                {...getFormikInputProps("commission_total")}
                placeholder={t("purchase_transfer_tax", "000.00")}
                disabled={true}
              />
            </Col>
            <Col className="mb-0 d-flex justify-content-end align-items-center">
            <div className="d-flex align-items-center" >
                <Typography variant="small"  className= "ml-auto mr-2" >{t("purchase_information", "Kirjaa meno automaattisesti")}</Typography>
                
                  <Checkbox 
                    type="checkbox"
                    id={"purchase_transfer_tax_check"}
                    name="purchase_transfer_tax_check"
                    checked={values?.purchase_transfer_tax_check}
                    onChange={(e) => {setFieldValue("purchase_transfer_tax_check",e.target.checked)}}
                    value="agree_terms"
                    label = {<Typography className="text-warning"></Typography>}
                  />
            </div>
            </Col>
        </Row>
        <Row>
            <Col className="mt-3">
            <label className="mb-0 pr-1  ">{t("purchase_information", "Lainan määrä")}</label>
            </Col>
        </Row>
        <Row className="mt-0">
            <Col className="mb-0 danger-placeholder">

              <Input
                className="text-danger"
                {...getFormikInputProps("purchase_loan")}
                placeholder={t("purchase_loan", "000.00")}
              />
            </Col>
            <Col className="mb-0 d-flex justify-content-end align-items-center">
            <div className="d-flex align-items-center" >
                <Typography variant="small"  className= "ml-auto mr-2" >{t("purchase_information", "Kirjaa meno automaattisesti")}</Typography>
                
                  <Checkbox 
                    type="checkbox"
                    id={"purchase_loan_check"}
                    name="purchase_loan_check"
                    checked={values?.purchase_loan_check}
                    onChange={(e) => {setFieldValue("purchase_loan_check",e.target.checked)}}
                    value="agree_terms"
                    label = {<Typography className="text-warning"></Typography>}
                  />
            </div>
            </Col>
        </Row>
        <Row>
            <Col className="mt-3">
            <label className="mb-0 pr-1  ">{t("purchase_information", "Korkojen osuus")}</label>
            </Col>
        </Row>
        <Row className="mt-0">
            <Col className="mb-0 danger-placeholder">

              <Input
                className="text-danger"
                {...getFormikInputProps("purchase_loan_interest")}
                placeholder={t("purchase_loan_interest", "000.00")}
              />
            </Col>
            <Col className="mb-0 d-flex justify-content-end align-items-center">
            <div className="d-flex align-items-center" >
                <Typography variant="small"  className= "ml-auto mr-2" >{t("purchase_information", "Kirjaa meno automaattisesti")}</Typography>
                
                  <Checkbox 
                    type="checkbox"
                    id={"purchase_loan_interest_check"}
                    name="purchase_loan_interest_check"
                    checked={values?.purchase_loan_interest_check}
                    onChange={(e) => {setFieldValue("purchase_loan_interest_check",e.target.checked)}}
                    value="agree_terms"
                    label = {<Typography className="text-warning"></Typography>}
                  />
            </div>
            </Col>
        </Row>
        <Row>
            <Col className="mt-3">
            <label className="mb-0 pr-1  ">{t("purchase_information", "Korkojen tarkistuspäivä")}</label>
            </Col>
        </Row>
        <Row className="mt-0">
            <Col className="mb-0">

            <DatePicker
                selected={values?.purchase_interest_rate_check_date}
                name="purchase_interest_rate_check_date"
                onChange={(date) => setFieldValue("purchase_interest_rate_check_date", date)}
                showYearDropdown
                dateFormat="d.M.yyyy"
                value={values?.purchase_interest_rate_check_date}
                customInput={<Input addonTypeClass="append" iconColor={"bg-light"} inputText={t("Pvm")}/>}
                placeholderText={t("1.6.2024")}
              />
            </Col>
            <Col className="mb-0 d-flex justify-content-end align-items-center">
            <div className="d-flex align-items-center" >
                <Typography variant="small"  className= "ml-auto mr-2" >{t("purchase_information", "Muistuta tarkastuspäivästä")}</Typography>
                
                  <Checkbox 
                    type="checkbox"
                    id={"purchase_interest_rate_check_date_check"}
                    name="purchase_interest_rate_check_date_check"
                    checked={values?.purchase_interest_rate_check_date_check}
                    onChange={(e) => {setFieldValue("purchase_interest_rate_check_date_check",e.target.checked)}}
                    value="agree_terms"
                    label = {<Typography className="text-warning"></Typography>}
                  />
            </div>
            </Col>
        </Row>
        <Row>
            <Col className="mt-3">
            <label className="mb-0 pr-1  ">{t("purchase_information", "Laina-aika")}</label>
            </Col>
        </Row>
        <Row className="mt-0">
            <Col className="mb-0">

              <Input
                {...getFormikInputProps("purchase_loan_time")}
                placeholder={t("purchase_loan_time", "000.00")}
              />
            </Col>
            <Col className="mb-0 d-flex justify-content-end align-items-center">
            <div className="d-flex align-items-center" >
                <Typography variant="small"  className= "ml-auto mr-2" >{t("purchase_information", "Kirjaa meno automaattisesti")}</Typography>
                
                  <Checkbox 
                    type="checkbox"
                    id={"purchase_loan_time_check"}
                    name="purchase_loan_time_check"
                    checked={values?.purchase_loan_time_check}
                    onChange={(e) => {setFieldValue("purchase_loan_time_check",e.target.checked)}}
                    value="agree_terms"
                    label = {<Typography className="text-warning"></Typography>}
                  />
            </div>
            </Col>
        </Row>

        <Row className="mt-4">
        {/* <Col className="mb-3">
          <Button onClick={handleCancel} variant="default">{t("Close","Sulje")}</Button>
        </Col> */}
        { values?.id ? (
        <Col className="mb-3 text-center">
          {/*<Button onClick={handleDelete} variant="danger">{t("Delete","Poista")}</Button>*/}
        </Col>
        ) : null }
        <Col className="mb-3 text-right">

            <Button variant="secondary" disabled={isSubmitting} onClick={onSubmit}>
              {t("Save","Tallenna")}
            </Button>
          
          { Boolean(errors["general"]) && <Typography className="text-danger">{errors["general"]}</Typography> }
        </Col>
      </Row>
     

    </Col>
    </Row>
    </>
        )}
 

      <Modal
      title={t("sector_title","Toimiala")}
      isOpen={modalEditOpen}
      toggleModal={toggleModalEdit}
      
    >
      <SectorEdit
        objectId={modalEditId}
        handleResolve={modalEditResolve}
        handleReject={modalEditReject}
        extraMargin={true}
      />
    </Modal>

    <Modal
                title={ `${t("real_estate_new_record_dividend","")} (${values?.name} |  ${values?.symbol}) `}
                isOpen={modalEditDividendOpen}
                toggleModal={toggleModalEditDividend}
                // size="modal-xl"
                // showHeader={false}
                // classNameBody="p-0 padding-0"
            >
                <PrivateInvestmentFundDividendEdit
                // objectId={modalEditDividendId}
                handleResolve={modalEditDividendResolve}
                handleReject={modalEditDividendReject}
                related_model_type={30}
                related_model_id={values?.id}
                defaultStockMarket={"XHEL"}
                defaultInvestmentType={10}
                setFilters={setFilters}
                // transaction_count = {count}
                // portfoliosSummary={portfoliosSummary}
                />
            </Modal>
        
    </>
  );
}

const PrivateInvestmentFundEditFormik = withFormik({
    enableReinitialize: true,
    validateOnMount: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return {
          ...defaultValues
        }
      }
    },
    validationSchema: props => {
      const {t} = props;
      const required = t("The field is required");
      return Yup.object().shape({
        name: Yup.string().required(required).nullable(),
        // type: Yup.string().required(required).nullable(),
        symbol: Yup.string().required(required).nullable(),
        // sector: Yup.string().required(required).nullable(),
        // stock_market: Yup.string().required(required).nullable(),
      });
    },
    handleSubmit: (values, { setSubmitting, setErrors, props }) => {
      const {t, portfolio, account, selectedSector} = props;
      
      const values_size = values?.size ? parseFloat(values?.size.toString().replace(",",".")) : 0;
      
      let data = {
        ...values,
        account: account?.id,
        size: values_size,
        general_sector: selectedSector?.sector_select?.id,
        private_sector: null,
        public_sector: null,
        type: "index"
      };
      
      if (values?.id) {
        httpUpdatePrivateInvestmentFund(values.id, data).then(res => {
          setSubmitting(false);
          props.notify({ title:t("Private investment fund", "Tiedot"), message:t("Updated")})
        //   props.handleResolve()
          
        }, error => {
          setSubmitting(false);
          if (error?.data?.detail) {
            setErrors({
              general: error?.data?.detail,
            })
          }
          else if (error?.data) {
            setErrors({
              general: t(error?.data),
            })
          }
          else {
            setErrors({
              general: t("Unknown error. Please try again later or contact support.")
            })
          }
        })
      }
      else {
        httpCreatePrivateInvestmentFund(data).then(res => {
          setSubmitting(false);
          props.notify({ title:t("Private investment fund", "Arvopaperi"), message:t("Created")})
          props.handleResolve()
          
        }, error => {
          setSubmitting(false);
          if (error?.data?.detail) {
            setErrors({
              general: error?.data?.detail,
            })
          }
          else if (error?.data) {
            setErrors({
              general: t(error?.data),
            })
          }
          else {
            setErrors({
              general: t("Unknown error. Please try again later or contact support.")
            })
          }
        })
      }
      
      
    },
    displayName: "BasicForm"
  
})(PrivateInvestmentFundEdit)
    
    
const PrivateInvestmentFundEditView = ({history, objectId, modalOpen, ...rest}) => {
  // API requests here
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [preSetValues, setPreSetValues] = useState();
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  const account = useStoreState((state) => state.account?.account);
  const [selectedSector, setSelectedSector] = useState({sector_select: []});
  const { getSectorName } = useAppContext()
  const [transactions, setTransactions] = useState([]);
  const [dividends, setDividends] = useState({
    incomes: [],
    expenses: [],
  });
  
  
  const portfolio = useStoreState((state) => state.portfolio?.portfolio);

  
  const getObject = (id) => {
    
    
    setLoading(true)
    
    httpGetPrivateInvestmentFund(id).then(res => {
      
      setPreSetValues({
        ...defaultValues,
        ...res?.data,
      });
      let sector = null
      if (res?.data?.private_sector){
        //add 1_ to private_sector id like 1_2322
        sector =`2_${res?.data?.private_sector}`
      }
      if (res?.data?.public_sector){
        sector =`1_${res?.data?.public_sector}`
      }

      setSelectedSector({
        sector_select: sector ? [{id: sector, label: getSectorName(sector), name:getSectorName(sector) }] : []
      })
      console.log("sector", sector, getSectorName(sector))

    
    }).finally(response => {
      setLoading(false);
    })
    
    
  }

  const getDividends = () => {

    const params = {
      portfolio: portfolio?.id,
      related_model_type: 30,
      related_model_id: objectId,
      private_investment_fund: objectId


    }
    httpGetDividends(params).then(res => {
      if (res?.data){
        let grouped_dividends = {
          incomes: [],
          expenses: [],
        }
        res?.data.map((dividend) => {
          if (dividend?.rate <0) {
            grouped_dividends.expenses.push(dividend)
          }
          else {
            grouped_dividends.incomes.push(dividend)
          }
        })


        setDividends(grouped_dividends)
      }

      })
  }

  useEffect(() => {
    const params = {
      portfolio: portfolio?.id,
      related_model_type: 30,
      related_model_id: objectId,
      private_investment_fund: objectId


    }


    httpGetTransactions(params).then(res => {
      if (res?.data){
        let grouped_transactions = {
          dividends: [],
            buys: [],
            sells: [],
            transfers: [],
            expenses: [],
            incomes: [],
            purchase_cost: 0,
            commission_total: 0,
          }

        res?.data.map((transaction) => {
          if (transaction?.type == 30 || transaction?.type == 32) {
            grouped_transactions.dividends.push(transaction)
          }
          if (transaction?.type == 2) {
            grouped_transactions.buys.push(transaction)
            grouped_transactions.purchase_cost += Number(transaction?.price_total)
            grouped_transactions.commission_total += Number(transaction?.commission_total)
          }
          if (transaction?.type == 3) {
            grouped_transactions.sells.push(transaction)
          }
        })

          
          setTransactions(grouped_transactions)
      }

    })
    getDividends(params)
    // httpGetDividends(params).then(res => {
    //   if (res?.data){
    //     let grouped_dividends = {
    //       incomes: [],
    //       expenses: [],
    //     }
    //     res?.data.map((dividend) => {
    //       if (dividend?.rate <0) {
    //         grouped_dividends.expenses.push(dividend)
    //       }
    //       else {
    //         grouped_dividends.incomes.push(dividend)
    //       }
    //     })


    //     setDividends(grouped_dividends)
    //   }

    //   })
    
  }, [preSetValues])
  
  
  useEffect(() => {
    console.log("objectId", objectId)
    if (objectId && parseInt(objectId) > -1) {
      getObject(objectId)
    }
  }, [objectId, modalOpen])
  
  
  if (loading) {
    return (
      <Container fluid>
        <Spinner />
      </Container>
    )
  }

  return (
    <PrivateInvestmentFundEditFormik 
      t={t} 
      history={history} 
      preSetValues={preSetValues} 
      notify={notify} 
      objectId={objectId}
      account={account}
      selectedSector={selectedSector}
      setSelectedSector={setSelectedSector}
      transactions={transactions}
      dividends={dividends}
      getDividends={getDividends}
      {...rest} 
    />
  )
  
}

export default PrivateInvestmentFundEditView;
