


import React, { useState, useCallback, useEffect } from "react";
import { withFormik } from 'formik';
import * as Yup from "yup";
import moment from "moment"
import { useTranslation } from 'react-i18next';
import { useStoreState, useStoreActions } from 'easy-peasy';

// Storybook
import { Button, Typography, Spinner } from "stories/components";
import { Container, Row, Col, FormContainer, Card, CardBody } from "stories/layout";
import { Input, SelectMulti, Toggle, Radio, DatePicker, Checkbox } from "stories/forms";

import { useNotification } from "stories/components/Notification"

// App
import { round ,roundScaleDecimals} from "services/helpers"

import OptionRightInput from "components/Forms/OptionRightInput/OptionRightInput"
import OptionAssetTypeInput from "components/Forms/OptionAssetTypeInput/OptionAssetTypeInput"
import OptionStateInput from "components/Forms/OptionStateInput/OptionStateInput"
import StockSelect from "components/Forms/StockSelect/StockSelect"
import StockPrivateSelect from "components/Forms/StockPrivateSelect/StockPrivateSelect"
import CommissionSelect from "components/Forms/CommissionSelect/CommissionSelect"
import PortfolioSelectStore from "components/Forms/PortfolioSelect/PortfolioSelectStore"
import StockMarketSelect from "components/Forms/StockMarketSelect/StockMarketSelect"
import BatchSelect from "components/Forms/BatchSelect/BatchSelect"
import InvestmentTypeSelect from "components/Forms/InvestmentTypeSelect/InvestmentTypeSelect"

import CurrencySelect from "components/Forms/CurrencySelect/CurrencySelect"
import { httpGetStockData } from "services/stocks"

import UploadFile from "components/Forms/UploadFile/UploadFile"
import TransactionsFileHandling from "components/Forms/TransactionsFileHandling/TransactionsFileHandling"

import {
  httpUploadFiles,
} from "services/files"

import { httpGetPrivateStockDatas } from "services/private_stocks"
import { 
  httpGetTransaction, 
  httpGetTransactions, 
  httpCreateTransaction, 
  httpUpdateTransaction, 
  httpDeleteTransaction, 
  httpGetStockDateBalance, 
  httpCreatePrivateStockTransaction, 
  httpUpdatePrivateStockTransaction,
  httpDeletePrivateStockTransaction,
  httpGetPrivateStockDateBalance,
  httpGetPrivateStockTransaction,
  httpGetPrivateStockTransactions,
  httpGetCurrentBatchBalanceRatio
} from "services/transactions"

import { 
  httpGetPrivateInvestimentFundDatas
} from "services/private_investment_funds";

import { httpGetCurrencies } from "services/currencies"
import { startOfDay } from "date-fns";

const defaultValues = {
  id: null,
  // commission_total: "0",
  currency: "EUR",
  // price: "0",
  // price_total: "0",
  // quantity: "0",
  // date_transaction: moment().format("YYYY-MM-DD"),
  date_transaction: new Date(),
  date_settlement: new Date(),
  // date_settlement: moment().format("YYYY-MM-DD"),
}

const PurchaseEdit = (props) => {
  
  const {
     values,
     touched,
     errors,
     handleChange,
     handleBlur,
     handleSubmit,
     setFieldValue,
     setFieldError,
     setFieldTouched,
     isValid,
     isTouched,
     isSubmitting,
     validateForm,
     preSetValues,
     objectId,
     setValues,
     setErrors,
      setTouched,
   } = props;
   
   const {
      t,
      history,
      currencyDate,
      setCurrencyDate,
      stocks,
      stockMarket,
      setStockMarket,
      setInvestmentType,
      investmentType,
      setFilters,
      totalValue,
      setTotalValue,
      totalValueEur,
      setTotalValueEur,
      stocksLoading,
      setCurrentStock,
      setBatchs,
      batchs,
      portfolio,
      batchRatio,
      setBatchRatio,
      setFiles,
      files,
      handleFileUpload,
      trxFiles,
      setTrxFiles,
      account,
 } = props;
   
 const getFormikInputProps = useCallback(
    name => ({
      name,
      value: values[name],
      error: Boolean(touched[name] && errors[name]),
      errorText: touched[name] ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange
    }),
    [values, errors, touched, handleBlur, handleChange]
  );
  
  const [stockDatePrice, setStockDatePrice] = useState();
  const [stockDatePriceLoading, setStockDatePriceLoading] = useState(false);
  const [batch , setBatch] = useState(null);
  const [isSelectOpen,setIsSelectOpen] = useState(false);
  
  const [stockTickerSymbol, setStockTickerSymbol] = useState(null);
  
  const getBasicDataObjects = useStoreActions((actions) => actions.stocks.getObjects);
  const getPrivateBasicDataObjects = useStoreActions((actions) => actions.privateStocks.getObjects);
  const privateStocks = useStoreState((state) => state.privateStocks?.objects);
  
  const onSubmit = (e) => {
    e.preventDefault();
    validateForm().then(res => {
      if (Object.keys(res).length === 0) {
        handleSubmit()
      }
      else {
        setTouched(res);
        if (stockMarket === "PRIVATE") {

          setErrors({
            ...res,
            stock_private_obj: res?.stock_private_obj?.id ? res?.stock_private_obj?.id : t("Kenttä on pakollinen"),
            general: t("Please check the form errors", "Tarkista lomakkeen virheet, Osake")
          })
        }
        else {
          setErrors({
            ...res,
            stock: res?.stock?.id ? res?.stock?.id : t("Kenttä on pakollinen"),
            general: t("Please check the form errors", "Tarkista lomakkeen virheet")
          })
        }
      }
    })
  }



  //----------------------------UPDATE BASICDATA when stock market change----------------------------------------------------
  useEffect(() => {
    if (investmentType) {
      console.log("PRIVATE basic_data")
      // getPrivateBasicDataObjects()
      getBasicDataObjects({stock_market:stockMarket, related_model_type: investmentType})
    }
    else {
    getBasicDataObjects({stock_market:stockMarket})
    }
    // if (values)
    // setFieldValue('stock', null)
  }, [stockMarket, investmentType])
  
  useEffect(() => {
    setFieldValue("batch_select", null)
    setBatchs([])
    setBatch(null)
    if (!objectId){
      setFieldValue("stock", null)
    }
    setStockTickerSymbol(null)

  }, [portfolio])
  


  //----------------------------UPDATE totalValue STATE----------------------------------------------------
  useEffect(() => {
    const price_total = parseFloat(values?.price_total)
    const commission_total = parseFloat(values?.commission_total ? values?.commission_total : 0)



    setTotalValue(round(price_total - commission_total, 2))
    
  }, [values?.price_total, values?.commission_total])
  
  /* Optional: if you want to calc price reversed
  useEffect(() => {
    const price_total = parseFloat(values?.price_total)
    const quantity = parseInt(values?.quantity)
    let calculation = 0
    if (price_total && price_total > 0 && quantity && quantity > 0) {
      calculation = price_total / quantity
    }
    if (calculation && calculation != parseFloat(values?.price)) {
      setFieldValue("price", calculation)
    }
  }, [values?.price_total, values?.quantity])
  */
  

  //----------------------------UPDATE TOTAL PRICE Automatically ----------------------------------------------------


  const handleCalculatePriceTotal = () => {
    const quantity = parseInt(values?.quantity)
    const price = parseFloat(values?.price)
    let calculation = 0
    if (price && quantity && price > 0 && quantity > 0) {
      calculation = price*quantity;
    }
    if (calculation && calculation !== parseFloat(values?.price_total)) {
      setFieldValue("price_total", parseFloat(round(calculation, 2).toFixed(2)))
    }
  }

  useEffect(() => {
    handleCalculatePriceTotal()
  }, [values?.price, values?.quantity])
  


  //----------------------------UPDATE STOCK PRICE  {values.stock.id}----------------------------------------------------

  const findObject = (id) => {
    let filteredObjects = null
    if (stockMarket === "PRIVATE") {
      filteredObjects = privateStocks && privateStocks.filter(o => o.id === id)
    }
    else {
      filteredObjects = stocks && stocks.filter(o => o.id === id)
    }
    if (filteredObjects && filteredObjects.length > 0) {
      return filteredObjects[0]
    }
    return null
  }

  useEffect(() => {
    if ((values?.stock && values?.date_transaction)|| (values?.stock_private_obj && values?.date_transaction)) {
      let stockObject = findObject( stockMarket === "PRIVATE"? values?.stock_private_obj :values?.stock?.id)


      let ticker_symbol = undefined; // "ACG1V";
      if (stockObject) {
        ticker_symbol = stockObject?.ticker_symbol
        setStockTickerSymbol(stockObject?.ticker_symbol)
        // setFieldValue("stock", stockObject)
      }
      else {
        setStockTickerSymbol(null)
      }
      const stock_date = undefined; // moment("2021-12-28").toISOString().split('T')[0] +" 00:00"; // "2021-12-27";
      let params = {
        offset:0, 
        limit:1, 
        // stock_date: "2021-12-26", // this exact filter not working
        // ticker_symbol: ticker_symbol,
      }
      
      // Stock date

      if (stockMarket === "PRIVATE") {
        // params["stock_date__gte"] = moment.utc(values?.date_transaction).startOf('day').toDate()
        // console.log(params["stock_date__gte"])
        // params["stock_date__lte"] = moment(values?.date_transaction).add("days",1).toISOString().split('T')[0] +" 23:59"; 
        
        // params["private_stock"] = values?.stock_private_obj?.id

        // httpGetPrivateStockDatas(params).then(res => {
        //   console.log("httpGetPrivateStockData res",res)
        //   if (res?.data?.count > 0) {
        //     setStockDatePrice(parseFloat(round(res?.data?.results[0]?.price, 2).toFixed(2)))
        //   }
        //   else {
        //     setStockDatePrice(null)
        //   }
        // })
      }
      else {
        params["stock_date__gte"] = moment(values?.date_transaction).toISOString().split('T')[0] +" 00:00:00Z"; 
        params["stock_date__lte"] = moment(values?.date_transaction).toISOString().split('T')[0] +" 23:59"; 

        if (investmentType == "10" && ticker_symbol){
          params["ticker_symbol"] = ticker_symbol;
          setStockDatePriceLoading(true)
          httpGetStockData(params).then(res => {
            if (res?.data?.count > 0) {
              setStockDatePrice(parseFloat(round(res?.data?.results[0]?.ending_price,2).toFixed(2)))
              setStockDatePriceLoading(false)
              
            }
            else {
              setStockDatePrice(null)
              setStockDatePriceLoading(false)
              
            }
          })
        }
        if (investmentType == "20"){
          params["private_stock"] = values?.stock?.id;

          setStockDatePriceLoading(true)
          httpGetPrivateStockDatas(params).then(res => {
            if (res?.data?.count > 0) {
              setStockDatePrice(parseFloat(round(res?.data?.results[0]?.price,2).toFixed(2)))
              setStockDatePriceLoading(false)
              
            }
            else {
              setStockDatePrice(null)
              setStockDatePriceLoading(false)
              
            }
          })
        }
        if (investmentType == "30"){
          params["private_investment_fund"] = values?.stock?.id;
          params["date__gte"] = moment(values?.date_transaction).toISOString().split('T')[0] +" 00:00:00Z"; 
          params["date__lte"] = moment(values?.date_transaction).toISOString().split('T')[0] +" 23:59"; 
          setStockDatePriceLoading(true)
          httpGetPrivateInvestimentFundDatas(params).then(res => {
            if (res?.data?.count > 0) {
              setStockDatePrice(parseFloat(round(res?.data?.results[0]?.price,2).toFixed(2)))
              setStockDatePriceLoading(false)
              
            }
            else {
              setStockDatePrice(null)
              setStockDatePriceLoading(false)
              
            }
          })
        }


        // let batchsParams = {
          
        //   stock_market: investmentType != 10? null: stockMarket,
        //   related_model_id: values?.stock?.id,
        //   related_model_type: investmentType,
        //   portfolio: portfolio?.id,  
        //   ordering: "date_transaction",
        // }

        // httpGetTransactions(batchsParams).then(res => {
        //   //filter res.data.results by batch_balance > 0

        //   //
        //   if (res?.data?.length > 0) {
        //     const filteredResults = res?.data?.filter(result => result.batch_balance > 0);
        //     setBatchs(filteredResults)
        //     setBatch(filteredResults[0])
        //     setFieldValue("batch_select", null)
        //   }
        //   else {
        //     setBatchs([])
        //     setBatch(null)
        //     setFieldValue("batch_select", null)

        //   }

        //   // setBatchs(res?.data?.results)
        //   // setCount(res?.data?.count)
  
        //   //   setLoading(false)
          
        // })

        // batchsParams["batch_select"] = values?.batch_select?.id
        // httpGetCurrentBatchBalanceRatio(batchsParams).then(res => {
        //   setBatchRatio(res?.data)
        // })

      }
    }
  }, [values?.stock, values?.stock_private_obj, values?.date_transaction])

  useEffect(() => {

    if (values?.stock){

    let batchsParams = {
          
      stock_market: investmentType != 10? null: stockMarket,
      related_model_id: values?.stock?.id,
      related_model_type: investmentType,
      portfolio: portfolio?.id,  
      ordering: "date_transaction",
      type:2
    }

    httpGetTransactions(batchsParams).then(res => {
      //filter res.data.results by batch_balance > 0

      //
      if (res?.data?.length > 0) {
        const filteredResults = res?.data?.filter(result => result.batch_balance > 0);
        setBatchs(filteredResults)
        setBatch(filteredResults[0])

        !objectId && setFieldValue("batch_select", null)
      }
      else {
        setBatchs([])
        setBatch(null)
        setFieldValue("batch_select", null)

      }

      // setBatchs(res?.data?.results)
      // setCount(res?.data?.count)

      //   setLoading(false)
      
    })

    batchsParams["batch_select"] = values?.batch_select?.id
    httpGetCurrentBatchBalanceRatio(batchsParams).then(res => {
      setBatchRatio(res?.data)
    })
  }


  }, [values?.stock, values?.stock_private_obj])

  // useEffect(() => {
  //   let batchsParams = {
          
  //     stock_market: stockMarket,
  //     related_model_id: values?.stock?.id,
  //     related_model_type: investmentType,
  //     portfolio: portfolio?.id,  
  //     ordering: "date_transaction",
  //     batch_select: values?.batch_select
  //   }
  //   if (values?.batch_select !== null) {
  //   httpGetCurrentBatchBalanceRatio(batchsParams).then(res => {
  //     console.log("httpGetCurrentBatchBalanceRatio res",res)
  //     if (res?.data) {
  //       setBatchRatio(res?.data?.ratio)
  //     }
  //     else{
  //       setBatchRatio(1)
  //     }
  //   })

  //   }
  // }, [values?.batch_select])



  useEffect(() => {
    if (values?.stock || values.stock_private_obj) {
      let stockObject = findObject(values?.stock?.id)
      if (stockObject) {
        if (stockMarket === "PRIVATE") {
          setFieldValue("stock_private_obj", {...values.stock_private_obj, label: stockObject?.name})
        }
        else {
        setFieldValue("stock", {...values.stock, label: stockObject?.name})
        
        }
      }
    }
  }, [stocks])
  

  //------------------------UPDATE COMISSION TOTAL-----------------------------------------------
  useEffect(() => {
    const price_total = parseFloat(values?.price_total)
    const commission = parseInt(values?.commission)
    if (price_total && price_total > 0 && commission && commission > 0) {
      if (commission === 2) {
        setFieldValue("commission_total", 0)
      }
      else if (commission === 3) {
        const commission_total = 8.0
        setFieldValue("commission_total", round(commission_total,2))
      }
      else if (commission === 4) {
        const commission_total = round(8.0 + (0.025*price_total), 2)
        setFieldValue("commission_total", round(commission_total,2))
      }
      
    }
    else {
      // setFieldValue("commission_total", 0)
    }
  }, [values?.price_total, values?.commission])
  


  // ------------------UPDATE CURRENCY DATE-----------------------------------
  useEffect(() => {
    if (values?.currency && values?.date_transaction) {
      
      if (values?.currency === "EUR") {
        setCurrencyDate(1.0)
      }
      else {
        let params = {
          offset:0, 
          limit:1, 
        }
        params["Date"] = moment(values?.date_transaction).format("YYYY-MM-DD")
        
        httpGetCurrencies(params).then(res => {
          if (res?.data?.count > 0) {
            setCurrencyDate(res?.data?.results[0][values?.currency])
          }
          else {
            setCurrencyDate(1.0)
          }
        })
      }
    }
    else {
      setCurrencyDate(1.0)
    }
  }, [values?.currency, values?.date_transaction])
  


  // ------------------UPDATE TOTAL VALUE EUR-----------------------------------
  useEffect(() => {
    if (currencyDate && totalValue ) {
      setTotalValueEur(round(totalValue/currencyDate, 2))
    }
    else {
      setTotalValueEur(totalValue)
    }
      
      
  }, [currencyDate, totalValue])
  

  // -------------------------SET PRICE automatically if in field have no value already--------------------------------------------
  useEffect(() => {
    // if (!values.price) {
    //   handleUseStockDatePrice()
    // }
    handleUseStockDatePrice()
  }, [stockDatePrice])
            



  // ------------------fUCTIONS TO USE-----------------------------------

  const handleCancel = () => {
    props.handleReject()
  }
  
  const handleConfirm = () => {
    /*
    httpConfirmPurchaseOrderShipping(values?.id).then(res => {
      handleSubmit()
    })
    */
  }

  const handleStockMarketChange = ({target}) => {
    setStockMarket(target.value)
    setFieldValue("stock", null)
    setFieldValue("stock_private_obj", null)
    setStockTickerSymbol(null)
    setStockDatePrice(null)
    setBatchs([])
    setBatch(null)
    setFieldValue("batch_select", null)
    setBatchRatio([])


    if (target.value === "PRIVATE") {
      
      setErrors({
        ...errors,
        stock_private_obj: t("Kenttä on pakollinen"),
      })
    }
    else {
      setErrors({
        ...errors,
        stock: t("Kenttä on pakollinen"),
      })
    }

  }

  const handleBatchChange = ({target}) => {
    setBatch(target.value)
    setFieldValue("batch_select", target.value)

  }


  const handleInvestmentTypeChange = ({target}) => {
    if (target.value === "10") {
      setStockMarket("XHEL")
      // setFilters(s => ({
      //   ...s,
      //   related_model_type: "10",
      // }))
      setInvestmentType(target.value)   
      setFieldValue("stock", null)
      setFieldValue("stock_private_obj", null)
      setStockTickerSymbol(null)
      setStockDatePrice(null)
      setFieldValue("batch_select", null)

      setBatchs([])
      setBatch(null)
      setBatchRatio([])

    }
    else {
      setStockMarket(null)
      setInvestmentType(target.value)
      // setFilters(s => ({
      //   ...s,
      //   // related_model_type: target.value,
      //   // stock_market: null,
      // }))
      setFieldValue("stock", null)
      setFieldValue("stock_private_obj", null)
      setFieldValue("batch_select", null)
      setStockTickerSymbol(null)
      setStockDatePrice(null)
      setBatchs([])
      setBatch(null)
      setBatchRatio([])
    }
  } 

  const getBatchObj = useCallback ((id) => {
    //loop all batchRatio and find the one with the same id
    console.log("batchRatio", batchRatio)
    let balance = null;
    if (batchRatio?.[0]?.id)  {
      console.log("batch------")
      batchRatio && batchRatio.map(obj => {
      if (obj.id == id) {
        balance = obj.batch_balance;
      }
    })  
    }
    // console.log(ratio)
    return balance;
  }, [batchRatio])
  
  const getBatchesBalance = useCallback ((id) => {
    //loop all batchRatio and find the one with the same id
    // console.log("batchRatio", batchRatio)
    let sumBalance = 0;
    if (batchRatio?.[0]?.batch_balance && batchs[0]?.id)  {
      batchs.map(obj => {
         let currentBalance = getBatchObj(obj.id) 
        sumBalance = sumBalance + currentBalance
      })
    }
    //convert to 2 decimals
    sumBalance = Number(sumBalance.toFixed(0))
    return sumBalance;
  }, [batchRatio, batchs])

  const getDefaultValues = useCallback(() => {
    // if (location?.state?.stockId && !values?.private_stock && !values?.stock==="stock_market_changed") {
    //   return {value: location.state?.stockId, label: location.state?.stockName}
    // }
    if (values?.stock_market === "PRIVATE") {
    return {value: values?.private_stock, label: values?.private_stock_name }
    }
    return {value: values?.stock, label: values?.stock_name }
  }, [values])

  
  const handleUseStockDatePrice = () => {
    if (stockDatePrice && !objectId) {
      setFieldValue("price", Number(stockDatePrice.toFixed(2)))
    }
  }


  
  const handleDelete = () => {

    if (stockMarket === "PRIVATE") {
      console.log("delete split")
    }
    else {
      httpDeleteTransaction(values?.id).then(res => {
        props.notify({ title:t("Split"), message:t("Poistettu")})
        // props.handleResolve()
        if (investmentType == "10"){
          setFilters(s => ({
              ...s,
              stock_market: null,
              related_model_type: null,
            }))
        }
        else {
            setFilters(s => ({

                ...s,
                stock_market: null,
                related_model_type: null,
              }))
        }
        props.handleResolve()
      }, error => {
        console.log("httpCreateStockTransaction error", error);
        props.notify({ title:t("Split"), message:t("Poisto epäonnistui")})

        if (error?.status === 400) {
          setErrors(error?.data)
        }
        else {
          setErrors({
            general: t("Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen.")
          })
        }
        
      })
    }
  }

  const onChangeBatch = ({target}, child) => {
    if (target?.value > 0) {
      const filtered = batchs.filter(o => o.id == target?.value)
      if (filtered && filtered.length > 0) {
        setBatch(filtered[0])
      }
    }
    if (target?.value === ""){
      setBatch("")
    }
  }

  const getBatch = useCallback((id) => {
    // return batchs.find(o => o.id === id)
    id = Number(id)
    return batchs.find(o => o.id === id)
  }, [values.batch_select])


  


  const getStockSelect = useCallback(() => {
    if (stockMarket === "PRIVATE") {
      return (
        <StockPrivateSelect
          {...getFormikInputProps("stock_private_obj")}
          label={t("sale_order_edit_asset_stock", "Osake (yksityinen)")}
          setFieldValue={setFieldValue}
          setValueToSelect={true}
          ratesView={false}
          setFieldValueReturnId={false}

        />
      )
    }
    else {
      return (
        <StockSelect
          {...getFormikInputProps("stock")}
          label={t("purchase_order_edit_asset_stock", "Arvopaperi")}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          loading={stocksLoading}
          setValues={setValues}
          setFieldValueReturnId={false}
          ratesView={false}
          setValueToSelect={true} 
          disabled={objectId?true:false}
          setIsSelectOpen={setIsSelectOpen}
          isSelectOpen={isSelectOpen}
        />
      )
    }
  }, [investmentType, stockMarket, values?.stock, values.stock_private_obj, errors?.stock, errors?.stock_private_obj, errors,])





  
  return (
    <>
    <div className="px-3 pt-2">

      {/*JSON.stringify(values)}
      {"---------------------"}
      {JSON.stringify(errors)*/}

      <Row className="mt-2 mb-2 border-bottom">
        <Col xs={12} md={6} className="ml-auto pb-3 pl-3 d-flex align-items-center">

          {objectId ? (
            <Typography variant="h2"  >{t("split_edit_title", "Split tapahtumaa ei voi muokata, toistaiseksi!")}</Typography>
          ) : (
            <Typography variant="h2">{t("purchase_order_add_title", "Lisää myyntitapahtuma")}</Typography>
          )}
        </Col>
        <Col xs={12} md={6} className="ml-auto pb-3">
        <PortfolioSelectStore icon="fas fa-suitcase" iconColor="text-secondary" disabled={true}/>
        </Col>
      </Row>
      <Row  className="py-3">
        <Col xs={12} md={6} className="border-right px-4 pt-0">
          <InvestmentTypeSelect name="related_model_type" onChange={handleInvestmentTypeChange} value={investmentType} disabled={objectId?true:false} showAll={false}/>

          {
            investmentType === 10 || investmentType === "10" ? (
            <Row className="mt-3">
              <Col>
                <StockMarketSelect name="stock_market" onChange={handleStockMarketChange} value={stockMarket} disabled={objectId?true:false} showPrivate={false} showAll={false} icon=""/>
              </Col>
            </Row>
            ) : null
          }
          <Row>
            <Col className="my-3">
             
            { getStockSelect() }


            { stockTickerSymbol ? (
              <Typography variant="small">{t("Kaupankäyntitunnus")}: {stockTickerSymbol}</Typography>
              
            ) : null }
            </Col>
          </Row>

          {/* <Row>
            <Col className="my-3">
              <div style={{position: 'absolute', zIndex: "0 !important"}}>
              <Checkbox 
              id="fifo"
              name="fifo_used"
              checked={values?.fifo_used}
              onChange={(e) => {setFieldValue("fifo_used",e.target.checked)}}
              value="agree_terms"
              required
              errorText={touched["agree_terms"] ? errors["agree_terms"] : ""}
              label={<>{t('FIFO käytössä')}</>}
              labelFirst={true}
              className="zIndexZero"
              />
              </div>
            </Col>
          </Row> */}
    
          


          {/* <Row className="row justify-content-end">
            <Col lg={8} md={12} className="mb-1 text-right">
              {stockMarket === "PRIVATE" ? (
                <Typography className="text-secondary" variant="small">
                {values.stock_private_obj === undefined || 
                  values.stock_private_obj === null || 
                  stockDatePrice ? null : t("Kurssin päätöshintaa Myyntipäivälle ei ole saatavilla ")}
                </Typography>

                ):(
                <Typography className="text-secondary" variant="small">
                {values.stock === undefined || 
                  values.stock === null || 
                  stockDatePrice ? null : t("Kurssin päätöshintaa myyntipäivälle ei ole saatavilla ")}
                </Typography>

              )}  
              </Col>
          </Row> */}
          
          <Row className="mb-3">
            <Col sm={12} lg={4} className="d-flex align-items-center">
              <label className="mb-1 pr-1 header">{t("split_edit_date_transaction", "Kirjauspäivä")}</label>
            </Col>
            <Col className="mb-1">
              <DatePicker
                className="form-control"
                selected={values?.date_transaction}
                name="date_transaction"
                onChange={(date) => setFieldValue("date_transaction", date)}
                showYearDropdown
                dateFormat="d.M.yyyy HH:mm"
                placeholderText="15.1.2000 12:00"
                value={values?.date_transaction}
                disabled={objectId? true: false}
                showTimeSelect
                timeIntervals={1}
                timeCaption="Time"
                customInput={<Input addonTypeClass="append" iconColor={"bg-light"} inputText={t(moment(values?.date_transaction).format("dddd"))}/>}

              />

              {/*
              <Input
                {...getFormikInputProps("date_transaction")}
                label={t("sale_edit_date_transaction", "Myyntipäivä")}
                type="date"
              />
              */}
            
            </Col>
          </Row>
          {/* <Row className="row justify-content-end">
            <Col lg={8} md={12} className="mb-3 d-flex">
            <Typography variant="small">{moment(values?.date_transaction).format("dddd")}</Typography>
            <Typography variant="small" className="ml-auto text-warning" >{t("syötä myös kellon aika")}</Typography>
            </Col>
          </Row> */}
          
          <Row className="mb-3">
            <Col sm={12} lg={4} className="d-flex align-items-center">
              <label className="mb-0 pr-1 header">{t("settlement_date", "Selvityspäivä")}</label>
            </Col>
            <Col className="mb-1">
              <DatePicker
                className="form-control"
                selected={values?.date_settlement}
                name="date_settlement"
                onChange={(date) => setFieldValue("date_settlement", date)}
                showYearDropdown
                dateFormat="d.M.yyyy"
                value={values?.date_settlement}
                customInput={<Input addonTypeClass="append" iconColor={"bg-light"} inputText={t(moment(values?.date_settlement).format("dddd"))}/>}
                disabled={objectId? true: false}
              />
              {/*
                <Input
                  {...getFormikInputProps("date_settlement")}
                  label={t("purchase_edit_date_settlement", "Selvityspäivä")}
                  type="date"
                />
              */}
              
            </Col>
          </Row>
          {/* <Row className="row justify-content-end">
            <Col lg={8} md={12} className="mb-3">
            <Typography variant="small">{moment(values?.date_settlement).format("dddd")}</Typography>
            </Col>
          </Row> */}
          
          <Row>
            <Col sm={12} lg={4} className="d-flex align-items-center">
              <label className="mb-0 pr-1 header">{t("broker", "Välittäjä")}</label>
            </Col>
            <Col className="mb-0">
              <Input
                {...getFormikInputProps("broker_name")}
                placeholder={t("broker", "Välittäjä")}
                icon="fas fa-user"
              />
            </Col>
          </Row>
          {/* <Row className="row justify-content-start mt-5">
            <Col lg={12} md={12} className="mb-1 text-left">
                <Typography className="text-red" variant="medium">
                  {t("HUOM! Muista tehdä kirjaukset aina kronologisessa järjestyksessä.")}
                </Typography>
                {objectId &&  
                  <Typography className="text-red" variant="medium">
                  {t("Mikäli tarve poistaa virheellinen kirjaus salkusta, tulee myös virheellistä kirjausta uudemmat samaa osaketta koskevat kirjaukset poistaa. Tämän jälkeen kaikki poistetut kirjaukset tulee luoda uudelleen.")}

                  </Typography>
                }
              </Col>
          </Row> */}

        </Col>
        <Col className="px-4 pt-0">
          <Row>
            <Col className="mb-0">
               <label className="mb-1 pr-1 header-bold">{t("purchase_information", "Maksutiedot")}</label>
            </Col>
          </Row>
          {/* <Row >
            <Col sm={12} lg={4} className="d-flex align-items-center">
              <label className="mb-0 pr-1 header">{t("purchase_order_edit_currency", "Valuutta")}</label>
            </Col>
            <Col className="mb-1">
              <CurrencySelect {...getFormikInputProps("currency")} label="" />
              
              
            </Col>
          </Row> */}
          {/* <Row className="row justify-content-end">
            <Col lg={8} md={12} className="mb-3">
              <Typography variant="small">{t("Valuuttakurssi myyntipäivänä")}: {currencyDate ? currencyDate : t("Ei saatavilla")}</Typography>
            </Col>
          </Row> */}
          

          {/* <Row className="mb-3">
            <Col sm={12} lg={4} className="d-flex align-items-center">
              <label className="mb-1 pr-1 header">{t("purchase_order_edit_commission", "Palkkio")}</label>
            </Col>
            <Col className="mb-0">
              <CommissionSelect
                {...getFormikInputProps("commission")}
                allOption={false}
                label=""
                disabled= {objectId? true : false}

              />
            </Col>
          </Row>
           */}
          <Row className="mb-3">
          
            <Col sm={12} lg={4} className="d-flex align-items-center">
              <label className="mb-1 pr-1 header">{t("transaction_edit_quantity", "Kappalemäärä")}</label>
            </Col>
            <Col sm={12} lg={4} >
              <Input
                {...getFormikInputProps("quantity")}
                label=""
                type="number"
                min={0}
                onWheel={(e) => e.target.blur()}
                placeholder={t("0")}
                disabled= {objectId? true : false}
              />
            </Col>
            {values.batch_select && !values.fifo_used &&(
              <Col sm={12} lg={4} className="d-flex align-items-center">
              <Typography variant="small">{getBatchObj(values.batch_select) }{t(" saatavilla")}</Typography>
              </Col>
            )}
            {values.fifo_used && values.stock && (
              <Col sm={12} lg={4} className="d-flex align-items-center">
              <Typography variant="small">{getBatchesBalance()}{t(" saatavilla")}</Typography>
              
              </Col>
            )}
            
          </Row>
          
          <Row className="mb-1">
            <Col sm={12} lg={4} className="d-flex align-items-center">
              <label className="mb-1 pr-1 header">{t("transaction_edit_price", "Kappalehinta")}</label>
            </Col>
            <Col sm={12} lg={4} >
              <Input
                {...getFormikInputProps("price")}
                label=""
                type="number"
                step="0.01"
                min={0}
                onWheel={(e) => e.target.blur()}
                placeholder={t("0")}
                disabled= {objectId? true : false}

              />

            </Col>
          </Row>

              <Row className="row justify-content-end">
                <Col lg={8} md={12} className="mb-3">
                  <Typography variant="small">{t("split_edt_endin_price_text_1", "Split-päivän päätöshinta")}:  {stockDatePriceLoading ? <Spinner animation="border" size="xs" className="spinner-height-15"/> : stockDatePrice ? stockDatePrice : t("Ei saatavilla")}</Typography>
                </Col>
              </Row>
                
            {/*
                <Row>
                <Col className="mb-3">
                <Button size="sm" onClick={handleUseStockDatePrice} variant="default">{t("Käytä")}</Button>
                <Button size="sm" onClick={handleCalculatePriceTotal} variant="default">{t("Laske")}</Button>
              </Col>
            </Row>
          */}
          
          
          {/* <Row className="mb-3">
            <Col sm={12} lg={4} className="d-flex align-items-center">
              <label className="mb-1 pr-1 header">{t("purchase_order_edit_price_total", "Erän hinta")}</label>
            </Col>
            <Col sm={12} lg={4} className="mb-0">
              <Input
                {...getFormikInputProps("price_total")}
                label=""
                type="number"
                step="0.01"
                min={0}
                // style={{ backgroundColor: "green", color: "white" }}
                onWheel={(e) => e.target.blur()}
                placeholder={t("0")}
                disabled= {objectId? true : false}

              />
            </Col>
          </Row> */}
          
          {/* <Row className="mb-3">
            <Col sm={12} lg={4} className="d-flex align-items-center">
              <label className="mb-1 pr-1 header">{t("purchase_order_edit_commission_total", "Kulut")}</label>
            </Col>
            <Col sm={12} lg={4} >
              <Input
                {...getFormikInputProps("commission_total")}
                label=""
                type="number"
                step="0.01"
                min={0}
                // style={{ backgroundColor: "red", color: "white" }}
                onWheel={(e) => e.target.blur()}
                placeholder={t("0")}
                disabled= {objectId? true : false}

              />
            </Col>
          </Row> */}
          {/* <Row className="row justify-content-end">
            <Col lg={8} md={12} className="mb-3">
             <Typography variant="small">{t("Yhteensä")} {totalValue ? totalValue : 0.0} ({values?.currency})</Typography>
            </Col>
          </Row> */}
          


          
          <Row className="mb-3">
            <Col sm={12} lg={4} className="d-flex align-items-center">
              <label className="mb-1 pr-1 header">{t("transaction_edit_comments", "Kommentit")}</label>
            </Col>
            <Col >
              <Input
                {...getFormikInputProps("notes")}
                label=""
                placeholder={t("transaction_edit_comments", "Kommentit")}
                type="textarea"
              />
            </Col>
          </Row>
          {account && account?.license?.name !== "Lite" && (
            <>
          <Row className="mb-3">
            <Col sm={12} lg={4} className="d-flex ">
              <label className="mb-0 mt-3 pr-1 header">{t("attachment_file", "Liite")}</label>
            </Col>
            <Col className="mb-0">
              <UploadFile apiUrl="https://your-server.com/upload" setFiles={setFiles} files={files} padding={15} />
            </Col>
            
          </Row>
            <Row className="mb-3">
              <Col sm={12} lg={4} className="d-flex ">
              </Col>
              <Col className="mb-0">
                <TransactionsFileHandling setFiles={setTrxFiles} files={trxFiles} transaction={values} setFieldValue={setFieldValue} values={values}/>
                {/* {JSON.stringify(values?.connect_files)} */}
              </Col>
              
            </Row>
          </>
          )}
        </Col>

      </Row>
      
      <Row>
        <Col>{/*JSON.stringify(values)*/}</Col>
      </Row>
      
      <Row className="bg-lighter pt-3  rounded-bottom">
        <Col className="mb-3">
          <Button onClick={handleCancel} variant="primary">{t("Sulje")}</Button>
        </Col>
        { values?.id > 0 ? (
        <Col className="mb-3 text-center">
          <Button name="transactionEdit_delete" onClick={handleDelete} variant="danger">{t("Poista")}</Button>
        </Col>
        ) : null}
        <Col className="mb-3 text-right">
          { !preSetValues?.state_delivery && (
            <Button variant="secondary" disabled={isSubmitting} onClick={onSubmit}>
              { t("Kirjaa ja tallenna") }
            </Button>
          )}
          
          { Boolean(errors["general"]) && <Typography className="text-danger">{errors["general"]}</Typography> }
        </Col>
      </Row>
        </div>
    </>
  );
}

const PurchaseEditFormik = withFormik({
    enableReinitialize: true,
    validateOnMount: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return {
          ...defaultValues
        }
      }
    },
    validationSchema: props => {  
      const {t, stockMarket} = props;

      const required = t("Kenttä on pakollinen");
      if (stockMarket === "PRIVATE") {
        return Yup.object().shape({
          //checht that stock are object with id
          stock_private_obj: Yup.object().shape({
            id: Yup.number().nullable().required(required),
          }).nullable().required(required),
          quantity: Yup.number().required(required).nullable(),
          price : Yup.string().required(required).nullable(),
        });
      }
      else {

        return Yup.object().shape({
          //checht that stock are object with id
          stock: Yup.object().shape({
            id: Yup.number().nullable().required(required),
          }).nullable().required(required),
        //   quantity: Yup.number().min(0.0000000000001, t('Arvon tulee olla enemmän kuin 0')).required(required).nullable(),
          price: Yup.string().test(
            "not-0",
            t("Value cannot be '0'"),
            (value) => value !== "0"
          )
          // value_total: Yup.number().notOneOf([0], t("Arvo ei voi olla 0")).required(required).nullable()
        });
      }
    },
    handleSubmit: (values, { setSubmitting, setErrors, props }) => {
      const {t, currencyDate, portfolio, investmentType, setFilters, stockMarket, totalValue, handleFileUpload, files} = props;

      let data = {}

      if (stockMarket === "PRIVATE"){
        data = {
          ...values,
          commission_total: -values?.commission_total || 0,
          currency_exchange_rate: currencyDate,
          currency_label: values?.currency,
          portfolio: portfolio?.id,
          price: values?.price,
          price_total: values?.price_total,
          stock: values?.stock_private_obj?.id,
          type: 3, // Sell
          date_settlement: values?.date_settlement? moment(values?.date_settlement).format() : undefined,
          date_transaction: values?.date_transaction ? moment(values?.date_transaction).format() : undefined,
          stock_market_label: stockMarket,
          // state: values?.state?.key,
          value_total: totalValue,
        };
      }         
      else {
      data = {
        ...values,
        commission_total: -values?.commission_total || 0,
        currency_exchange_rate: currencyDate,
        currency_label: values?.currency,
        portfolio: portfolio?.id,
        price: values?.price,
        price_total: values?.price_total,
        // stock: values?.stock_private_obj?.id,
        stock: values?.stock?.id,
        type: 20, // Split
        date_settlement: values?.date_settlement? moment(values?.date_settlement).format() : undefined,
        date_transaction: values?.date_transaction ? moment(values?.date_transaction).format() : undefined,
        stock_market: stockMarket,
        related_model_id: values?.stock?.id,
        related_model_type: investmentType,
        fifo_used: values?.fifo_used? values?.fifo_used : false,
        // state: values?.state?.key,
        value_total: totalValue,
      };

      }
      if (investmentType != "10"){
        data.ticker_symbol = null
        data.stock_market = null
      }
      if (values?.id) {
        if (stockMarket === "PRIVATE") {
          setSubmitting(true)
          delete data.original_batch_balance
          delete data.batch_balance
          delete data.value_total
          delete data.stock_market
          delete data.price_total
          delete data.commission_total
          delete data.value_total
          delete data.price
          delete data.stock_market
          delete data.fifo_used
          delete data.wac_date_lte
          delete data.wac_date_gte
          delete data.wac_ids
          delete data.currency_exchange_rate
          delete data.currency_label
          delete data.date_settlement
          delete data.date_transaction
          httpUpdatePrivateStockTransaction (values?.id, data).then(res => {
            props.notify({ title:t("Kirjaus"), message:t("Päivitetty onnistuneesti")})

            props.handleResolve()
          }, error => {
            props.notify({ title:t("Kirjaus"), message:t("Päivitetys epäonnistui")})

            console.log("httpCreateStockTransaction error", error);
            if (error?.status === 400) {
              setErrors(error?.data)
            }
            else {
              setErrors({
                general: t("Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen.")
              })
            }
            
          }).finally(() => {
            setSubmitting(false)
          })
        }
        else {
          setSubmitting(true)
          //remove stock_market from data
          delete data.batch_balance
          delete data.date_transaction
          delete data.original_batch_balance
          delete data.value_total
          delete data.commission_total
          delete data.price_total
          delete data.price
          
          const update_params = {
            notes: values?.notes,
            broker_name: values?.broker_name,
            connect_files: values?.connect_files,

          }

          httpUpdateTransaction(values?.id,update_params).then(res => {
            if(res?.data?.id){
              handleFileUpload([res?.data?.id], files)
            }
            props.notify({ title:t("Kirjaus"), message:t("Päivitetty")})
            if (investmentType == "10"){
                setFilters(s => ({
                    ...s,
                    // stock_market: stockMarket,
                    // related_model_type: investmentType,
                    stock_market: null,
                    related_model_type: null,
                  }))
            }
            else {
                setFilters(s => ({

                    ...s,
                    // stock_market: null,
                    // related_model_type: investmentType,
                    stock_market: null,
                    related_model_type: null,

                  }))
            }
            props.handleResolve()
          }, error => {
            props.notify({ title:t("Kirjaus"), message:t("Päivitetys epäonnistui")})
            console.log("httpCreateStockTransaction error", error);
            if (error?.status === 400) {
              setErrors(error?.data)
            }
            else {
              setErrors({
                general: t("Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen.")
              })
            }
            
          }).finally(() => {
            setSubmitting(false)
          })
        }
      }
      else {
        if (stockMarket === "PRIVATE") {
          setSubmitting(true)
          httpCreatePrivateStockTransaction(data).then(res => {
            props.notify({ title:t("Kirjaus"), message:t("Lisätty onnistuneesti")})

            props.handleResolve()
          }, error => {
            console.log("httpCreateStockTransaction error", error);
            if (error?.status === 400) {
              setErrors(error?.data)
            }
            else {
              setErrors({
                general: t("Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen.")
              })
            }
            
          }).finally(() => {
            setSubmitting(false)
          })
  
        }
        else {
          console.log(data.date_transaction)
          setSubmitting(true)
          // const oldDate = new Date(data.date_transaction);
          // const newDate = new Date(oldDate.getFullYear(), oldDate.getMonth(), oldDate.getDate(), 12, 0, 0);
          // const formattedDate = newDate.toISOString().split('T')[0] +" 12:00:00Z";;
          // data.date_transaction = formattedDate;

          httpCreateTransaction(data).then(res => {

            if(res?.data?.id){
              handleFileUpload([res?.data?.id], files)
            }
            props.notify({ title:t("Kirjaus"), message:t("Lisätty onnistuneesti")})
            if (investmentType == "10"){
                setFilters(s => ({
                    ...s,
                    // stock_market: stockMarket,
                    // related_model_type: investmentType,
                    stock_market: null,
                    related_model_type: null,
                  }))
            }
            else {
                setFilters(s => ({

                    ...s,
                    // stock_market: null,
                    // related_model_type: investmentType,
                    stock_market: null,
                    related_model_type: null,
                  }))
            }
            props.handleResolve()
          }, error => {
            console.log("httpCreateStockTransaction error", error);
            if (error?.status === 400) {
              setErrors(error?.data)
            }
            else {
              setErrors({
                general: t("Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen.")
              })
            }
            
          }).finally(() => {
            setSubmitting(false)
          })
        }

      }

    },
    displayName: "BasicForm"
  
})(PurchaseEdit)
    
    
const PurchaseEditView = ({history, objectId,defaultStockMarket,defaultInvestmentType="10", ticker_symbol, setFilters, modalOpen, ...rest}) => {
  // API requests here
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [preSetValues, setPreSetValues] = useState(defaultValues);
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  const [totalValue, setTotalValue] = useState(0);
  const [totalValueEur, setTotalValueEur] = useState(0);
  const [stockMarket, setStockMarket] = useState(defaultStockMarket? defaultStockMarket : "XHEL");
  const [investmentType, setInvestmentType] = useState(defaultInvestmentType? defaultInvestmentType : "10");

  const [currencyDate, setCurrencyDate] = useState(1.0);
  const [currentStock, setCurrentStock] = useState(null);
  const [batchs, setBatchs] = useState([]);
  const [batchRatio, setBatchRatio] = useState(1);
  const account = useStoreState((state) => state.account?.account);

  
  // Store
  const options = useStoreState((state) => state.options?.options);
  const addOption = useStoreActions((actions) => actions.options.addOption);
  const stocks = useStoreState((state) => state.stocks?.objects);
  const stocksLoading = useStoreState((state) => state.stocks?.loading);
  const portfolio = useStoreState((state) => state.portfolio?.portfolio);
  const getBasicDataObjects = useStoreActions((actions) => actions.stocks.getObjects);
  const getPrivateBasicDataObjects = useStoreActions((actions) => actions.privateStocks.getObjects);
  const privateStocks = useStoreState((state) => state.privateStocks?.objects);
  const [files, setFiles] = useState([]);
  const [trxFiles, setTrxFiles] = useState([]);


  
  useEffect(() => {
    if (portfolio) {
      setPreSetValues(s => ({
        ...s,
        currency: portfolio?.currency_label
      }))
    }
  }, [portfolio])

  const findObject = useCallback ((id) => {
    // const filteredObjects = stocks && stocks.filter(o => o.id === id)
    // if (filteredObjects && filteredObjects.length > 0) {
    //   return filteredObjects[0]
    // }
    // return null

    let filteredObjects = null
    if (stockMarket === "PRIVATE") {
      filteredObjects = privateStocks && privateStocks.filter(o => o.id === id)
    }
    else {
      filteredObjects = stocks && stocks.filter(o => o.id === id)
    }
    if (filteredObjects && filteredObjects.length > 0) {
      return filteredObjects[0]
    }
    return null
  }, [stocks, stocksLoading])


  const handleFileUpload = (transactions) => {
    if (!files?.length) return
    const formData = new FormData();
    files.forEach(file => formData.append('file', file))
    // formData.append('files', files);
    formData.append('account', account?.id);
    formData.append('transactions', transactions)
  
    formData.append('portfolio', portfolio.id);
    // setLoadingSendExcel(true)
    httpUploadFiles(formData).then(res => {
      if (res?.data){
  
        console.log(res?.data)
      }
      setFiles([])
  
      // setLoadingSendExcel(false)
  
    }, error => {
      // setErrorText("Tapahtumien tuonti epäonnistui")
      if(error?.data?.message) {
        // setErrorMessage(t(error?.data?.message))
      }
      if(error?.data?.general) {
        //replace : with . to avoid rendering issues
        const general = error?.data?.general?.toString()
        const formattedError = general.replace(/:/g, '.')
        // setErrorMessage(t(formattedError))
      }
  
    }).finally(() => {
      // setLoadingSendExcel(false)
  
      // setLoadingImport(false)
    })
  
  
  };

  const getObject = (id) => {
    setLoading(true)

    if (stockMarket === "PRIVATE") {
      httpGetPrivateStockTransaction(id).then(res => {
        setStockMarket(res?.data?.stock_market_label)
  
        getPrivateBasicDataObjects({stock_market:res?.data?.stock_market_label})
        setPreSetValues({
          ...defaultValues,
          ...res.data,
          date_transaction: res?.data?.date_transaction ? moment(res?.data?.date_transaction).toDate() : new Date(),
          date_settlement: res?.data?.date_settlement ? moment(res?.data?.date_settlement).toDate() : new Date(),
          stock_private_obj:{id: res?.data?.stock, value:res?.data?.stock, label: findObject(res?.data?.stock)?.name},
  
          // price: round(res?.data?.price, 2) * -1,
          // value_total : round(res?.data?.value_total, 2) ,
          // price_total : round(res?.data?.price_total, 2) ,
          // commission_total : round(res?.data?.commission_total, 2) ,
          price: roundScaleDecimals(res?.data?.price, 6) * -1,
          value_total : roundScaleDecimals(res?.data?.value_total, 6) ,
          price_total : roundScaleDecimals(res?.data?.price_total, 6) ,
          commission_total : roundScaleDecimals(res?.data?.commission_total, 6) ,
        });
      
      }).finally(response => {
        setLoading(false);
      })
    }
    else{
      httpGetTransaction(id).then(res => {
        setStockMarket(res?.data?.stock_market)
        setTrxFiles(res?.data?.files)
  
        getBasicDataObjects({stock_market:res?.data?.stock_market, related_model_type: investmentType})

        setPreSetValues({
          ...defaultValues,
          ...res.data,
          date_transaction: res?.data?.date_transaction ? moment(res?.data?.date_transaction).toDate() : new Date(),
          date_settlement: res?.data?.date_settlement ? moment(res?.data?.date_settlement).toDate() : new Date(),
          stock:{id: res?.data?.related_model_id, value:res?.data?.related_model_id, label: findObject(res?.data?.related_model_id)?.name},
          quantity: res?.data?.batch_balance,
  
          price: roundScaleDecimals(res?.data?.price, 6) * -1,
          value_total : roundScaleDecimals(res?.data?.value_total, 6) ,
          price_total : roundScaleDecimals(res?.data?.price_total, 6) ,
          commission_total : roundScaleDecimals(res?.data?.commission_total, 6) ,
          ticker_symbol : ticker_symbol,
          

        });
      
      }).finally(response => {
        setLoading(false);
      })
    }


    
  }
  
  useEffect(() => {
    if (objectId && parseInt(objectId) > -1) {
      getObject(objectId)
    }
  }, [objectId, modalOpen])
  
  if (loading) {
    return (
      <Container fluid className="d-flex justify-content-center align-items-center">
        <Spinner className="mt-3" />
      </Container>
    )
  }

  return (
    <>

    <PurchaseEditFormik 
      t={t}
      history={history}
      preSetValues={preSetValues}
      notify={notify}
      objectId={objectId}
      addOption={addOption}
      currencyDate={currencyDate}
      setCurrencyDate={setCurrencyDate}
      portfolio={portfolio}
      stocks={stocks}
      stockMarket={stockMarket}
      setStockMarket={setStockMarket}
      totalValue={totalValue}
      setTotalValue={setTotalValue}
      totalValueEur={totalValueEur}
      setTotalValueEur={setTotalValueEur}
      stocksLoading={stocksLoading}
      currentStock={currentStock}
      findeObject={findObject}
      setInvestmentType={setInvestmentType}
      investmentType={investmentType}
      setFilters={setFilters}
      setBatchs = {setBatchs}
      batchs = {batchs}
      batchRatio = {batchRatio}
      setBatchRatio = {setBatchRatio}
      setFiles={setFiles}
      files={files}
      trxFiles={trxFiles}
      setTrxFiles={setTrxFiles}
      handleFileUpload={handleFileUpload}
      account={account}

      {...rest} 
    />
    </>
  )
  

}
  

export default PurchaseEditView;
