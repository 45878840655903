import React, { useEffect, useCallback, useState } from "react";
import { withFormik } from 'formik';
import * as Yup from "yup";
import queryString from 'query-string'
import { useTranslation } from 'react-i18next';

// core components
import { Input } from "stories/forms";
import { Button, Typography, Link } from "stories/components"
import { Card, CardBody, CardHeader, Col, Container, Row } from "stories/layout"
import { useNotification } from "stories/components/Notification"
import { useAuthContext } from "contexts/AuthContext";
import { httpChangePassword } from "services/users";
import { httpActivateUser } from "services/users";
import { httpSendActivationEmail } from "services/users"

import { logoutWhenTokenExp } from "services/logoutTokenExp";


import logo from "assets/images/logo_nav.png"

const Invitation = (props) => {

  const {
     values,
     touched,
     errors,
     handleChange,
     handleBlur,
     handleSubmit,
     isValid,
     isSubmitting,
     setPreSetValues,
     setErrors,
     setSubmitting,
     setValues,
     setTouched,
     validateForm,
   } = props;
   
   const {
      history,
      t,
      emailProps,
   } = props;
   
   console.log(values)
   console.log(errors)

  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"

   const getFormikInputProps = useCallback(
    name => ({
      name,
      value: values[name],
      error: Boolean(touched[name] && errors[name]),
      errorText: touched[name] ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange
    }),
    [values, errors, touched, handleBlur, handleChange]
  );

  const handleResendActivationEmail = (emailAdress) => {
    setSubmitting(true)
    httpSendActivationEmail(emailAdress).then(res => {
      notify({ title:t('Activation'), message:t('Pin-code sent successfully to your email')})
      history.push({
        pathname: '/auth/activation',
        state: { email: emailAdress}
      })
      setValues({
        ...values,
        pin_code: "",
      })
      setTouched({
        ...touched,
        pin_code: false,
      })
      setErrors({})
      setSubmitting(false)
    }).catch(err => {
        setSubmitting(false)
        // notify({ title:t('Activation'), message:t('Pin-code sent failed')})
        
    })
        
  }
  
  const handleCancel = () => {
    history.push("/");
  }

  const getForm = () => {
    return (
        <>

        <Row>
          <Col className="mb-3">
            <Input
              {...getFormikInputProps("pin_code")}
              placeholder={t('activation_pin_code','123456')}
              required
            />
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <Typography variant="small" className="mb-2 px-2">{t("activation_check_email_for_code",'Jos et saanut koodia tarkista roskaposti. ')} <Link onClick={() => handleResendActivationEmail(values?.email)} className="underline-text" >{t("activation_send_code_again",'Lähetä koodi uudelleen.')}</Link></Typography>
          </Col>
        </Row>

                                  
        <Button disabled={isSubmitting} variant="secondary" onClick={() => validateForm().then(() => handleSubmit())}>{t("activation_confirm",'Vahvista')}</Button>
        <div className="text-start text-muted mb-4">
          {/* { Boolean(errors["general"]) && <Typography className="text-danger">{errors["general"]}</Typography> } */}
        </div>
        { Boolean(errors["general"]) && (
            <div className="text-center text-muted mb-2">
              <Typography className="text-danger">{errors["general"]}</Typography>
            </div>
          )}

        { errors?.['pin_code_expired_or_not_correct'] ? (
            <div className="text-start mb-4">
              <Button variant="secondary" disabled={isSubmitting}  onClick={() => handleResendActivationEmail(values?.email)}>{t('login_resend_activation_email_button', 'Resend activation email')}</Button>
            </div>
          ) : null}
        </>
        

    )
  }
  
  return (
    <>
     <div className="p-2">
      </div>

        {/* {JSON.stringify(values?.email)} */}
        {emailProps && getForm()}
        {
          !emailProps && (
            <Container className="mt-4 pb-5">
        <Row className="justify-content-center">
          <Col lg="6" md="7" className="">
            <Card variant="secondary">
              <CardHeader className="bg-transparent pt-5 pb-1">
                
                <div className="text-center text-muted mb-4">
                  <Typography variant="h2" className="mb-2 px-2">{t('activation_set_pin_code','Anna sähköpostiin lähetetty koodi ja vahvista tilisi')}</Typography>
                  <Typography variant="p"></Typography>
                </div>
              
              </CardHeader>
              <CardBody className="px-lg-5 py-lg-5">
                <Row>
                  <Col className="mb-1">
                    <Input
                      {...getFormikInputProps("pin_code")}
                      placeholder={t('activation_pin_code','123456')}
                      required
                    />
                  </Col>
                </Row>

                                          
                <div className="text-center text-muted mt-3 mb-4">
                  <Button disabled={isSubmitting} onClick={() => validateForm().then(() => handleSubmit())}>{t('Vahvista')}</Button>
                  { Boolean(errors["general"]) && <Typography className="text-danger">{errors["general"]}</Typography> }
                </div>
                { Boolean(errors["general"]) && (
                    <div className="text-center text-muted mb-2">
                      <Typography className="text-danger">{errors["general"]}</Typography>
                    </div>
                  )}

                { errors?.['pin_code_expired_or_not_correct'] ? (
                    <div className="text-center mb-4">
                      <Button variant="secondary" disabled={isSubmitting}  onClick={() => handleResendActivationEmail(values?.email)}>{t('login_resend_activation_email_button', 'Resend activation email')}</Button>
                    </div>
                  ) : null}
                
              </CardBody>
            </Card>
            {/* {getForm()} */}
          </Col>
        </Row>
      </Container>
        )}

    </>
  );
}

const defaultValues = {
  id: null,

}

 const InvitationFormik = withFormik({
    
    validateOnMount: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        console.log(preSetValues)
        return preSetValues;
      } else {
        return defaultValues
      }
    },
    validationSchema: props => {
      const {t} = props;
      const required = t("required_field","Kenttä on pakollinen");
      
      return Yup.object().shape({
        //pin_code have to be 6 integers
        pin_code: Yup.string().required(required).matches(/^[0-9]{6}$/, t("pin_code_must_be_6_digits","PIN-koodin tulee olla 6 numeroa")),

      });
    },
    handleSubmit: (values, { setSubmitting, setErrors, props }) => {
      const {t, emailProps, setActiveStep} = props;
      let data = {
        pin_code: values.pin_code,
        email: values.email,
      };
      
      httpActivateUser(data).then(res => {
        setSubmitting(false);
        if (res?.status === 200) {

          // props.notify({ title:t("activation_title","Aktivointi"), message:t("activation_success","suoritettu onnistuneesti"), type:"success" });
          if (res?.data?.access && res?.data?.refresh){
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            // If we set accessToken, we need to set refreshToken also
            localStorage.setItem("refreshToken", res.data.refresh);
            localStorage.setItem("accessToken", res.data.access);

            logoutWhenTokenExp();
          }
          if(!emailProps){
            window.location.href = "/"
          }
          else{
            setActiveStep(4)
           }
        }

        
        
      }, error => {
        console.log(error.status)
        setSubmitting(false);
        
        //All keys in error.data
        const errorDataKey = Object.keys(error?.data);
        //default
        let errorCodeKey = errorDataKey?.[0];
        //default errorCode string to translator
        let errorCodeData = error?.data?.[errorCodeKey];
        //if error.data include errorCode then use that instead of default (errorCodeKey, errorCodeData). Also exclude errorCode key from errorDataKey list
        if (errorDataKey.length >= 2 && errorDataKey.includes('errorCode') ) {
          const errorCodeIndex = errorDataKey.indexOf('errorCode');
          errorCodeKey = errorDataKey[errorCodeIndex];
          errorCodeData = error?.data?.[errorCodeKey];
          //Exclude errorCode key from errorDataKey list
          errorDataKey.splice(errorDataKey.indexOf('errorCode'), 1);
          //For special cases, if render depends on errorCode
          setErrors({[errorCodeData]: t(errorCodeData)});
      
        }
        console.log(errorDataKey)
        console.log(errorCodeKey)
        console.log(errorCodeData)
        let errorData = error?.data[errorDataKey?.[0]];
        const valuesKeys = Object.keys(values);
        console.log(valuesKeys)
        const usualErrorkeys = ['detail', 'message', 'non_field_errors'];


        if (Array.isArray(errorData)) {
          errorData = errorData[0];
        }

        if (!errorData || errorData === '') {
          setErrors({general: t('general_error','Virhe. Ota tarvittaessa yhteyttä asiakaspalveluun'),[errorCodeData]: t(errorCodeData)})
        }
        else if (errorDataKey.length > 0 && valuesKeys.includes(errorDataKey?.[0])) {
          //set new setErrors with include previous errors
            console.log(errorDataKey, errorData, errorCodeData, errorCodeKey)
          setErrors({[errorDataKey?.[0]]: t(errorCodeData,errorData),[errorCodeData]: t(errorCodeData)});
          

        }
        else if (errorDataKey.length > 0 && usualErrorkeys.includes(errorDataKey?.[0])) {
          setErrors({general: t(errorCodeData,errorData),[errorCodeData]: t(errorCodeData)});

        } 
        // else if dataKey is exist set general error
        else if (errorDataKey.length > 0) {
          setErrors({general: t(errorCodeData,errorData),[errorCodeData]: t(errorCodeData)});
        }
        else {
            setErrors({
              general: `${t('general_error','Virhe. Ota tarvittaessa yhteys ylläpitoon.')} ${JSON.stringify(error)}`,[errorCodeData]: t(errorCodeData),
          })
        }
      })
    },
    displayName: "BasicForm"
  
  })(Invitation)
    
    
const ActivationView = ({location, match, history, emailProps, setActiveStep}) => {
  // API requests here
  
  const { t } = useTranslation();
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  const { setLanguage, getMyUser } = useAuthContext();
  
  let preSetValues = { }
  const [loading, setLoading] = useState(false);



  
  useEffect(() => {
    // setLanguage("fi");
  }, [])
  
  useEffect(() => {
    
    const parsed = queryString.parse(location?.search);
    if (parsed?.token) {
      console.log(parsed.token)
      localStorage.removeItem("refreshToken");
      localStorage.setItem("accessToken", parsed?.token);
    }
  },[location])
  
  // useEffect(() => {
    //   getMyUser();
    // },[])
    
    
    

    //how to get all params from url





   //get history state
   const historyState = history?.location?.state;
   console.log(historyState)

   const parsed = queryString.parse(location?.search);

   //loop all params and if key start with "amp;" remove it
   for (const [key, value] of Object.entries(parsed)) {
     if (key.startsWith("amp;")) {
       const newKey = key.replace("amp;", "");
       parsed[newKey] = value;
       delete parsed[key];
     }
   }



    let email = "";

    let pin_code = "";

    if (parsed?.email) {
      email = parsed.email ;
      console.log(email)
    }
    else if (historyState?.email ) {
        email = historyState.email;
        console.log(email)
    }
    else if (emailProps) {
        email = emailProps;
        console.log(email)
    }
    if (historyState?.pin_code){
        pin_code = historyState.pin_code ;
        console.log(pin_code)
    }
    else if (parsed?.pin_code) {
        pin_code = parsed.pin_code;
        console.log(email)
    }








    preSetValues = {
      ...defaultValues,
      email: email,
      pin_code: pin_code,

    };



  if (loading) {
    return (
      <Container fluid>

      </Container>
    )
  }
  return (
    <>
      {/* {JSON.stringify(emailProps)} */}
      <InvitationFormik history={history} t={t} notify={notify} preSetValues={preSetValues}  emailProps={emailProps} setActiveStep={setActiveStep} />
    </>
  )
}
  

export default ActivationView;
