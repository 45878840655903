import React, { useEffect, useState, useCallback } from "react";

import { useTranslation } from 'react-i18next';

// Stories
import { PageHeader, PageContainer } from "components/Page"

import { Col, Container, Row } from "stories/layout"

import {Link, Breadcrumb, BreadcrumbItem, Button, BreadcrumbsDynamic} from 'stories/components';
import { useAuthContext } from "contexts/AuthContext";
import { Markup } from 'interweave';
import DiaryTransactions from "views/app/DiaryTransactions/DiaryTransactions"




// App
import graph01 from "assets/images/graph_01.jpg"
import graph02 from "assets/images/graph_02.jpg"
import { Typography } from "stories/components";

const Diary = (props) => {
  const { t } = useTranslation();
  const { myUser } = useAuthContext();

    //Export button from diaryTransactions
    const [exportButton, setExportButton] = useState(null);

  //get crumb links from location state or return default links
  const getCrumbLinks = () => {
    const crumbLinks = [
      {
        name: t("Päiväkirja"),
          url: "/dashboard",  
        },
    ]
    //if location state include crumbLinks return them
    if (props.location?.state && props.location?.state?.crumbLinks) {
      props.location.state.crumbLinks.push({name: t("Päiväkirja"), url: "/dashboard"})
      return props.location.state.crumbLinks
    }
    //else return default links
    return crumbLinks
  }

  const title = t("Tervetuloa palveluun, ") + (myUser?.first_name? myUser?.first_name : myUser?.username);

  
  return (
    <>
    <Row className="d-flex  flex-wrap  ">
        <Col>
          <PageHeader title={t("Päiväkirja")} />
          <BreadcrumbsDynamic links={getCrumbLinks()} />
        </Col>
        <Col md="12" xs="12" lg="8" className="d-flex flex-column justify-content-end  mr-xs-auto align-self-end mb-3 " style={{minWidth: "50px"}}>
          <div className="ml-auto">
            {exportButton}
          </div>
        </Col>
    </Row>

    
      <DiaryTransactions  setExportButton={setExportButton}/>
      <Typography  variant="small" className="mt-5 text-gray">
      * {t("diary_batch_exchange_date","Erän vaihtopäivämäärä")}
    </Typography>



  
{/*
    <Row>
        <Col className="mb-3">
          <img src={graph01} />
        </Col>
        <Col className="mb-3">
          <img src={graph02} />
        </Col>
  </Row>*/}
    </>
  );
}

export default Diary;