import { action, thunk, persist } from 'easy-peasy';


const model = {
    loading: false,
    setLoading: action((state, payload) => {
      state.loading = payload
    }),
    portfolio: [],
    setPortfolio: action((state, payload) => {
      state.portfolio = payload
    }),
    // My portfolios list
    portfolioSummary: [],
    setPortfolioSummary: action((state, payload) => {
      if (payload) {
        state.portfolioSummary = payload
      }
    }),
    setTotal_cost: action((state, payload) => {
      if (payload) {
        state.portfolios_total_purchase_cost = payload
      }
    }),

    // Selected portfolio
  
  };
  
  export default model;