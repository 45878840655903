import React from 'react';
import {  Popover, Button } from "stories/components";
import { Markup } from 'interweave';

import { useStoreState, useStoreActions } from 'easy-peasy'
import { he } from 'date-fns/locale';





const HelpText = ({ contentKey, parentElementId,markupClassName, placement="top", target="info",popoverOpen=true, showButton=true }) => {
    const helpTexts = useStoreState((state) => state.helptexts?.helptexts);

    //If helpTexts is not defined or the key is not defined or the content is empty, return null
    if (helpTexts[contentKey] === undefined || helpTexts[contentKey]?.content === null || helpTexts[contentKey]?.content === "")   {
        return null;
    }

    return (
        <>
            {showButton &&
                <Button className="text-dark font-weight-light rounded-circle custom-xs-button bg-light b-0"  variant="lighter" id={parentElementId} size="sm">
                <i className="fas fa-question "  />
                </Button>
            }
            {popoverOpen ?(
                <Popover
                    placement="top"
                    target={parentElementId}
                    >       
                <div style={{maxWidth:"300px"}}>
                <Markup  content={helpTexts[contentKey]?.["content"]} />
                {/* {helpTexts[contentKey]} */}
                </div>
                </Popover>
            ):(
                <Markup  content={helpTexts[contentKey]?.["content"]} className={markupClassName && markupClassName} />
            )
        }
        </>
    )
}

export default HelpText;