import http from './api'

const url = '/private_sectors'


export const httpGetAllSectors = (params) => {
    console.log("httpGetAllSectors");
    return http({
      url: `/private_investment_funds/private_investment_funds/` ,
      method: 'GET',
      params,
    })
  }


  
  export const httpGetSectors = (params) => {
    return http({
      url: `/private_investment_funds${url}/` ,
      method: 'GET',
      params
    })
  }
  
  export const httpGetSector = (id) => {
    return http({
      url: `/private_investment_funds${url}/${id}/` ,
      method: 'GET',
    })
  }
  
  export const httpCreateSector  = (data) => {
    return http({
      url: `/private_investment_funds${url}/` ,
      method: 'POST',
      data
    })
  }
  
  export const httpUpdateSector  = (id, data) => {
    return http({
      url: `v${url}/${id}/` ,
      method: 'PATCH',
      data
    })
  }
  
  export const httpDeleteSector  = (id) => {
    return http({
      url: `/private_investment_funds${url}/${id}/` ,
      method: 'DELETE',
    })
  }