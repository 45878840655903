import http from './api'
import privateApi from './privateApi'

export const httpGetBasicData = (params) => {
  console.log("httpGetBasicData");
  return http({
    url: `/basic_data/` ,
    method: 'GET',
    params,
  })
}

export const httpGetExtendBasicData = (params) => {
  console.log("httpGetExtendBasicData");
  return http({
    url: `/extend_basic_data/` ,
    method: 'GET',
    params,
  })
}

export const httpGetStockData = (params) => {
  console.log("httpGetStockData");
  return http({
    url: `/stock_data/` ,
    method: 'GET',
    params,
  })
}

export const httpUpdateStockData = (id, data) => {
  console.log("httpUpdateStockData");
  return http({
    url: `/stock_data/${id}/` ,
    method: 'PATCH',
    data
  })
}

export const httpGetSupportedStockMarkets = (params) => {
  console.log("httpSupportedStockMarkets");
  return http({
    url: `/basic_data/supported_stock_markets/` ,
    method: 'GET',
    params,
  })
}

export const httpLoadHistoryData = (data) => {
  return privateApi({
    url: `/history_data_for_stock/` ,
    method: 'POST',
    data
  })
}