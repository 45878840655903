import { action, thunk, persist } from 'easy-peasy';
import { httpGetPrivateStocks } from "services/private_stocks";

const model = {
  loading: false,
  setLoading: action((state, payload) => {
    state.loading = payload
  }),
  objects: [
    /*
    {id: 1, name: "Osake A"},
    {id: 2, name: "Osake B"},
    {id: 3, name: "Osake C"},
    */
  ],
  setObjects: action((state, payload) => {
    if (payload) {
      state.objects = [
        ...payload,
      ]
    }
  }),
  addObject: action((state, payload) => {
    state.objects = [
      ...state.objects,
      payload,
    ]
  }),
  getObjects: thunk(async (actions, payload) => {
    actions.setLoading(true)
    const params = {
      ordering: 'name',
      offset: 0,
      limit: 1000,
      ...payload,
    }
    httpGetPrivateStocks(params).then(response => {
      actions.setLoading(false)
      actions.setObjects(response?.data?.results)
      actions.setCount(response?.count)
    }, error => {
      
    }).finally(() => {
      actions.setLoading(false)
    })
    
  }),
  count: 0,
  setCount: action((state, payload) => {
    state.count = payload
  }),
  
};

export default model;