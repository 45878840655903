import {useEffect, React, useState, useCallback} from "react";
import { useTranslation } from 'react-i18next';
// import useref
import { useRef } from 'react';

// Stories
import { PageHeader, PageContainer } from "components/Page"

import { Col, Container, Row, Card, CardBody, CardHeader } from "stories/layout"

import {Link,Modal, Breadcrumb, BreadcrumbItem, Button, BreadcrumbsDynamic, Spinner} from 'stories/components';
import { useAuthContext } from "contexts/AuthContext";
import { Markup } from 'interweave';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { httpGetAllPortfoliosSummary} from "services/transactions"


import PortfoliosSummaryChart from "views/app/PortfoliosSummaryChart/PortfoliosSummaryChart"
import PortfolioCarousel from "views/app/PortfolioCarousel/PortfolioCarousel"

import { round, roundScaleDecimals } from "services/helpers"  



// App
import graph01 from "assets/images/graph_01.jpg"
import graph02 from "assets/images/graph_02.jpg"
import { Typography } from "stories/components";
import { is } from "date-fns/locale";



const SubscriptionOptionsInfo = (props) => {
    const { t } = useTranslation();
    const { isAuthenticated, user, token } = useAuthContext();

    const email = 'investime@investime.fi';
    const mailtoLink = `mailto:${email}`;
    
    const licenses = useStoreState((state) => state.licenses?.licenses);
    const free_license = licenses?.find(license => license?.name === 'Lite' || license?.name === 'Free')
    const basic_license = licenses?.find(license => license?.name === 'Basic')
    const enterprice_license = licenses?.find(license => license?.name === 'Enterprise')
    // make pro_license
    const pro_license = licenses?.find(license => license?.name === 'Pro')

    const imageUrl = 'https://static.vismapay.com/pay_banners/row.png';

    const {
        handleReject,
        handleResolve,
        isSubmitting,
        custom_info_text,
        setFieldValue,
        currentLicenseId,
        preChoosen
      } = props;


    const chooseLicense = (license) => { 
        setFieldValue('license', license?.id);
        handleResolve();    
    }



   useEffect(() => {
    const targetSectionId = preChoosen;
    const targetSection = document.getElementById(targetSectionId);
 
    // Scroll to the target section
    if (targetSection && window.innerWidth < 992) {
       targetSection.scrollIntoView({ behavior: 'smooth' });
    }
 }, [preChoosen]);

    return (
        <Row className="">
          <Col>
            <Card className={`shadow-sm-strong  h-100  `}>
              <CardHeader className="">
                {isAuthenticated ? (
                  <Typography variant="h2" className="text-dark">{t('subscription_options_info_header', 'Päivitä tilaus, jotta saat kaiken irti Investime palvelustamme')}</Typography>
                ) : (
                  <Typography variant="h2" className="text-dark">{t('subscription_options_info_header_not_registered', 'Valitse palvelupaketti')}</Typography>
                )
                }
              </CardHeader>
              <CardBody>
                  <Row className="mb-2">
                      <Col>
                          <Typography variant="h4" className="text-dark">{custom_info_text}</Typography>
                          { 
                          !isAuthenticated && <Typography variant="h5" className="text-dark mb-2">{t('subscription_options_info_not_registered_top', 'Palvelupaketin voi päivittää myöhemmin ylöspäin.')}</Typography>
                          }
                      </Col>
                  </Row>
   
                  <Row className="mb-5">
                  <Col xs="12" sm="6" lg="3" classname="mb-3"  id="Lite">

                    <div className={`shadow-sm-strong h-lg-100 d-flex flex-column mb-4 ${preChoosen === 'Lite' ? 'border border-success border-2' : ''}`}>

                        <div className="p-3 mb-2 text-white bg-gray">
                            <Typography variant="h4" className="text-center text-dark">
                                {t('subscription_options_info_free_header', 'LITE')}
                            </Typography>
                            <Typography variant="h5" className="text-center text-dark">
                                {t('subscription_options_info_free_description', 'vain yksityiseen käyttöön')}
                            </Typography>
                            <Typography variant="h1" className="text-center text-dark">
                              {t('subscription_options_info_free_price', '0')}
                              <span  style={{fontSize: '0.6em'}}>€</span>
                            </Typography>
                            <Typography variant="small" className="text-center text-dark">
                                {t("subscription_monthly","Kuukaudessa") } 
                                
                            </Typography>
                        </div>
                        <div >
                            <div className="px-3 pr-4 py-2 "><Typography variant="small"><i className="pr-2 fas fa-check-circle fa-lg text-success"></i>{t('subscription_options_info_users', 'Käyttäjien määrä')}, max. {free_license?.limit_users}</Typography></div>
                            <div className="px-3 pr-4 py-2 "><Typography variant="small"><i className="pr-2 fas fa-check-circle fa-lg text-success"></i>{t('subscription_options_info_portfolios', 'Salkkujen lukumäärä')}, max. {free_license?.limit_portfolios}</Typography></div>
                            <div className="px-3 pr-4 py-2 "><Typography variant="small"><i className="pr-2 fas fa-check-circle fa-lg text-success "></i>{t('subscription_options_info_transactions', 'Kirjaukset (osto ja myynti)')}, max. {free_license?.limit_transactions} </Typography></div>
                            <div className="px-3 pr-4 py-2 "><Typography variant="small"><i className="pr-2 fas fa-check-circle fa-lg text-success"></i>{t('subscription_options_info_privateStocks', 'Ulkopuoliset osakkeet ja rahastot')}</Typography></div>
                            <div className="px-3 pr-4 py-2 "><Typography variant="small"><i className="pr-2 fas fa-check-circle fa-lg text-success"></i>{t('subscription_options_info_EDS', 'Vaihdot, annit, osingot ja splitit')}</Typography></div>
                            <div className="px-3 pr-4 py-2 "><Typography variant="small"><i className="pr-2 fas fa-check-circle fa-lg text-success"></i>{t('subscription_options_info_XHEL', 'Nasdaq Helsinki')}</Typography></div>
                            <div className="px-3 pr-4 py-2 "><Typography variant="small"><i className="pr-2 fas fa-ban fa-lg text-danger"></i>{t('subscription_options_info_XSTO', 'Nasdaq Tukholma')}</Typography></div>
                            <div className="px-3 pr-4 py-2 "><Typography variant="small"><i className="pr-2 fas fa-ban fa-lg text-danger"></i>{t('subscription_options_info_XCSE', 'Nasdaq Kööpenhamina')}</Typography></div>
                            <div className="px-3 pr-4 py-2 "><Typography variant="small"><i className="pr-2 fas fa-ban fa-lg text-danger"></i>{t('subscription_options_info_XICE', 'Nasdaq Islanti')}</Typography></div>
                            <div className="px-3 pr-4 py-2 "><Typography variant="small"><i className="pr-2 fas fa-ban fa-lg text-danger"></i>{t('subscription_options_info_tax_form_9A', '9A-veroilmoitus')}</Typography></div>
                            <div className="px-3 pr-4 py-2 "><Typography variant="small"><i className="pr-2 fas fa-ban fa-lg text-danger"></i>{t('subscription_options_info_profit_report', 'Tuottoraportti')}</Typography></div>
                            <div className="px-3 pr-4 py-2 "><Typography variant="small"><i className="pr-2 fas fa-ban fa-lg text-danger"></i>{t('subscription_options_info_options', 'Johdannaiset')}</Typography></div>
                            <div className="px-3 pr-4 py-2 "><Typography variant="small"><i className="pr-2 fas fa-ban fa-lg text-danger"></i>{t('subscription_options_info_APIs', 'Rajapinnat')}</Typography></div>
                            <div className="px-3 pr-4 py-2 "><Typography variant="small"><i className="pr-2 fas fa-ban fa-lg text-danger"></i>{t('subscription_options_info_ownDB', 'Oma tietokanta')}</Typography></div>
                            {/* <div className="px-3 pr-4 py-2 "><Typography variant="small"><i className="pr-2 fas fa-check-circle fa-lg text-success"></i>{t('subscription_options_info_exchange', 'Vaihdot ja annit')}</Typography></div> */}
                            {/* <div className="px-3 pr-4 py-2 "><Typography variant="small"><i className="pr-2 fas fa-check-circle fa-lg text-success"></i>{t('subscription_options_info_privateFunds', 'Ulkopuoliset rahastot')}</Typography></div> */}
                  
                        </div>
                        {/*
                          !isAuthenticated &&
                        <div className="d-flex justify-content-center py-2 mt-auto mb-2">
                        <Button variant="secondary" onClick={()=> chooseLicense(free_license)} className="px-5">{t('Valitse')}</Button>
                        </div>
                        */}
                        <div className="d-flex justify-content-center py-2 mt-auto mb-2">
                            <Button disabled={currentLicenseId >= free_license?.id} variant="secondary" onClick={()=> chooseLicense(free_license)} className="px-5">{t('Valitse')}</Button>
                        </div>
                      </div>

                    
                  </Col>
                  <Col xs="12" sm="6" lg="3"  classname="mb-3"  id="Basic">
                    <div className={`shadow-sm-strong  d-sm-flex flex-column mb-4 h-lg-100 ${preChoosen === 'Basic' ? 'border border-success border-2' : ''}`}>
                        <div className="p-3 mb-2  bg-secondary">
                            <Typography variant="h4" className="text-center text-dark">
                                {t('subscription_options_info_bascid_header', 'BASIC')}
                            </Typography>
                            <Typography variant="h5" className="text-dark text-center text-dark">
                                {t('subscription_options_info_basic_description', 'Suosituin piensijoittajan paketti')}
                            </Typography>
                            <Typography variant="h1" className="text-center text-dark">
                                {roundScaleDecimals(basic_license?.price,2) }
                                <span  style={{fontSize: '0.6em'}}>€</span>
                                
                            </Typography>
                            <Typography variant="small" className="text-center text-dark">
                                {t("subscription_monthly","Kuukaudessa") } (alv {basic_license?.price_vat_percent}%)
                                
                            </Typography>
                        </div>
                        <div className="d-flex flex-column">
                        <div className="px-3 pr-4 py-2 "><Typography variant="small"><i className="pr-2 fas fa-check-circle fa-lg text-success"></i>{t('subscription_options_info_users', 'Käyttäjien määrä')}, max. {basic_license?.limit_users}</Typography></div>
                          <div className="px-3 pr-4 py-2 "><Typography variant="small"><i className="pr-2 fas fa-check-circle fa-lg text-success"></i>{t('subscription_options_info_portfolios', 'Salkkujen lukumäärä')}, max. {basic_license?.limit_portfolios}</Typography></div>
                          <div className="px-3 pr-4 py-2 "><Typography variant="small"><i className="pr-2 fas fa-check-circle fa-lg text-success "></i>{t('subscription_options_info_transactions', 'Kirjaukset (osto ja myynti)')}, max. {basic_license?.limit_transactions} </Typography></div>
                          <div className="px-3 pr-4 py-2 "><Typography variant="small"><i className="pr-2 fas fa-check-circle fa-lg text-success"></i>{t('subscription_options_info_privateStocks', 'Ulkopuoliset osakkeet ja rahastot')}</Typography></div>
                          <div className="px-3 pr-4 py-2 "><Typography variant="small"><i className="pr-2 fas fa-check-circle fa-lg text-success"></i>{t('subscription_options_info_EDS', 'Vaihdot, annit, osingot ja splitit')}</Typography></div>
                          <div className="px-3 pr-4 py-2 "><Typography variant="small"><i className="pr-2 fas fa-check-circle fa-lg text-success"></i>{t('subscription_options_info_XHEL', 'Nasdaq Helsinki')}</Typography></div>
                          <div className="px-3 pr-4 py-2 "><Typography variant="small"><i className="pr-2 fas fa-check-circle fa-lg text-success"></i>{t('subscription_options_info_XSTO', 'Nasdaq Tukholma')}</Typography></div>
                          <div className="px-3 pr-4 py-2 "><Typography variant="small"><i className="pr-2 fas fa-check-circle fa-lg text-success"></i>{t('subscription_options_info_XCSE', 'Nasdaq Kööpenhamina')}</Typography></div>
                          <div className="px-3 pr-4 py-2 "><Typography variant="small"><i className="pr-2 fas fa-check-circle fa-lg text-success"></i>{t('subscription_options_info_XICE', 'Nasdaq Islanti')}</Typography></div>
                          <div className="px-3 pr-4 py-2 "><Typography variant="small"><i className="pr-2 fas fa-check-circle fa-lg text-success"></i>{t('subscription_options_info_tax_form_9A', '9A-veroilmoitus')}</Typography></div>
                          <div className="px-3 pr-4 py-2 "><Typography variant="small"><i className="pr-2 fas fa-ban fa-lg text-danger"></i>{t('subscription_options_info_profit_report', 'Tuottoraportti')}</Typography></div>
                          <div className="px-3 pr-4 py-2 "><Typography variant="small"><i className="pr-2 fas fa-ban fa-lg text-danger"></i>{t('subscription_options_info_options', 'Johdannaiset')}</Typography></div>
                          <div className="px-3 pr-4 py-2 "><Typography variant="small"><i className="pr-2 fas fa-ban fa-lg text-danger"></i>{t('subscription_options_info_APIs', 'Rajapinnat')}</Typography></div>
                          <div className="px-3 pr-4 py-2 "><Typography variant="small"><i className="pr-2 fas fa-ban fa-lg text-danger"></i>{t('subscription_options_info_ownDB', 'Oma tietokanta')}</Typography></div>
                          {/* <div className="px-3 pr-4 py-2 "><Typography variant="small"><i className="pr-2 fas fa-check-circle fa-lg text-success "></i>{t('subscription_options_info_transactions', 'Osto ja myyntikirjaukset')} </Typography></div>
                          <div className="px-3 pr-4 py-2 "><Typography variant="small"><i className="pr-2 fas fa-check-circle fa-lg text-success"></i>{t('subscription_options_info_exchange', 'Vaihdot ja annit')}</Typography></div>
                          <div className="px-3 pr-4 py-2 "><Typography variant="small"><i className="pr-2 fas fa-check-circle fa-lg text-success"></i>{t('subscription_options_info_privateStocks', 'Ulkopuoliset Osakkeet')}</Typography></div>
                          <div className="px-3 pr-4 py-2 "><Typography variant="small"><i className="pr-2 fas fa-check-circle fa-lg text-success"></i>{t('subscription_options_info_privateFunds', 'Ulkopuoliset rahastot')}</Typography></div>
                          <div className="px-3 pr-4 py-2 "><Typography variant="small"><i className="pr-2 fas fa-check-circle fa-lg text-success"></i>{t('subscription_options_info_users', 'Käyttäjien määrä')}, max. {basic_license?.limit_users}</Typography></div>
                          <div className="px-3 pr-4 py-2 "><Typography variant="small"><i className="pr-2 fas fa-check-circle fa-lg text-success"></i>{t('subscription_options_info_portfolios', 'Salkkujen lukumäärä')}, max. {basic_license?.limit_portfolios}</Typography></div>
                          <div className="px-3 pr-4 py-2 "><Typography variant="small"><i className="pr-2 fas fa-check-circle fa-lg text-success"></i>{t('subscription_options_info_options', 'Johdannaiset')}</Typography></div>
                          <div className="px-3 pr-4 py-2 "><Typography variant="small"><i className="pr-2 fas fa-check-circle fa-lg text-success"></i>{t('subscription_options_info_dividend', 'Osinko kirjaukset (tulossa)')}</Typography></div>
                          <div className="px-3 pr-4 py-2 "><Typography variant="small"><i className="pr-2 fas fa-check-circle fa-lg text-success"></i>{t('subscription_options_info_tax_form_9A', '9A-veroilmoitus (tulossa)')}</Typography></div>
                          <div className="px-3 pr-4 py-2 "><Typography variant="small"><i className="pr-2 fas fa-ban fa-lg text-danger"></i>{t('subscription_options_info_APIs', 'Rajapinnat')}</Typography></div>
                          <div className="px-3 pr-4 py-2 "><Typography variant="small"><i className="pr-2 fas fa-ban fa-lg text-danger"></i>{t('subscription_options_info_ownDB', 'Oma tietokanta')}</Typography></div> */}
            
                        </div>
                        {/*
                          !isAuthenticated &&
                        <div className="d-flex justify-content-center py-2 mt-auto mb-2">
                          <Button variant="secondary" onClick={()=> chooseLicense(basic_license)} className="px-5">{t('Valitse')}</Button>
                        </div>
                        */}
                        <div className="d-flex justify-content-center py-2 mt-auto mb-2">
                          <Button disabled={currentLicenseId >= basic_license?.id} variant="secondary" onClick={()=> chooseLicense(basic_license)} className="px-5">{t('Valitse')}</Button>
                        </div>
                    </div>
                        

                  </Col>
                  <Col xs="12" sm="6" lg="3"  classname="mb-3"  id="Pro">
                    <div className={`shadow-sm-strong h-lg-100 d-flex flex-column mb-4 ${preChoosen === 'Pro' ? 'border border-success border-2' : ''}`}>
                        <div className="p-3 mb-2  bg-info">
                            <Typography variant="h4" className="text-center text-dark">
                                {t('subscription_options_info_pro_header', 'PRO')}
                            </Typography>
                            <Typography variant="h5" className="text-dark text-center text-dark">
                                {t('subscription_options_info_pro_description', 'Sijoitusalan yrityksille')}
                            </Typography>
                            <Typography variant="h1" className="text-center text-dark">
                            {roundScaleDecimals(pro_license?.price / (1 + (pro_license?.price_vat_percent / 100.0)),2)}
                                <span  style={{fontSize: '0.6em'}}>€</span>
                                
                            </Typography>
                            <Typography variant="small" className="text-center text-dark">
                                {t("subscription_monthly","Kuukaudessa") } (alv 0%)
                                
                            </Typography>
                        </div>
                        <div>
                        <div className="px-3 pr-4 py-2 "><Typography variant="small"><i className="pr-2 fas fa-check-circle fa-lg text-success"></i>{t('subscription_options_info_users', 'Käyttäjien määrä')}, max. {pro_license?.limit_users}</Typography></div>

                        <div className="px-3 pr-4 py-2 "><Typography variant="small"><i className="pr-2 fas fa-check-circle fa-lg text-success"></i>{t('subscription_options_info_portfolios', 'Salkkujen lukumäärä')}, max. {pro_license?.limit_portfolios}</Typography></div>
                          <div className="px-3 pr-4 py-2 "><Typography variant="small"><i className="pr-2 fas fa-check-circle fa-lg text-success "></i>{t('subscription_options_info_transactions', 'Kirjaukset (osto ja myynti)')}, max. {pro_license?.limit_transactions} </Typography></div>
                          <div className="px-3 pr-4 py-2 "><Typography variant="small"><i className="pr-2 fas fa-check-circle fa-lg text-success"></i>{t('subscription_options_info_privateStocks', 'Ulkopuoliset osakkeet ja rahastot')}</Typography></div>
                          <div className="px-3 pr-4 py-2 "><Typography variant="small"><i className="pr-2 fas fa-check-circle fa-lg text-success"></i>{t('subscription_options_info_EDS', 'Vaihdot, annit, osingot ja splitit')}</Typography></div>
                          <div className="px-3 pr-4 py-2 "><Typography variant="small"><i className="pr-2 fas fa-check-circle fa-lg text-success"></i>{t('subscription_options_info_XHEL', 'Nasdaq Helsinki')}</Typography></div>
                          <div className="px-3 pr-4 py-2 "><Typography variant="small"><i className="pr-2 fas fa-check-circle fa-lg text-success"></i>{t('subscription_options_info_XSTO', 'Nasdaq Tukholma')}</Typography></div>
                          <div className="px-3 pr-4 py-2 "><Typography variant="small"><i className="pr-2 fas fa-check-circle fa-lg text-success"></i>{t('subscription_options_info_XCSE', 'Nasdaq Kööpenhamina')}</Typography></div>
                          <div className="px-3 pr-4 py-2 "><Typography variant="small"><i className="pr-2 fas fa-check-circle fa-lg text-success"></i>{t('subscription_options_info_XICE', 'Nasdaq Islanti')}</Typography></div>
                          <div className="px-3 pr-4 py-2 "><Typography variant="small"><i className="pr-2 fas fa-check-circle fa-lg text-success"></i>{t('subscription_options_info_tax_form_9A', '9A-veroilmoitus')}</Typography></div>
                          <div className="px-3 pr-4 py-2 "><Typography variant="small"><i className="pr-2 fas fa-check-circle fa-lg text-success"></i>{t('subscription_options_info_profit_report', 'Tuottoraportti')}</Typography></div>
                          <div className="px-3 pr-4 py-2 "><Typography variant="small"><i className="pr-2 fas fa-ban fa-lg text-danger"></i>{t('subscription_options_info_options', 'Johdannaiset')}</Typography></div>
                          <div className="px-3 pr-4 py-2 "><Typography variant="small"><i className="pr-2 fas fa-ban fa-lg text-danger"></i>{t('subscription_options_info_APIs', 'Rajapinnat')}</Typography></div>
                          <div className="px-3 pr-4 py-2 "><Typography variant="small"><i className="pr-2 fas fa-ban fa-lg text-danger"></i>{t('subscription_options_info_ownDB', 'Oma tietokanta')}</Typography></div>
                        </div>
                        {/*
                          !isAuthenticated &&
                        <div className="d-flex justify-content-center py-2 mb-2 mt-auto">
                          <Button variant="secondary"  onClick={()=> chooseLicense(pro_license)} className="px-5">{t('Valitse')}</Button>
                        </div>
                        */}
                            <div className="d-flex justify-content-center py-2 mb-2 mt-auto">
                              <Button disabled={currentLicenseId >= pro_license?.id} variant="secondary"  onClick={()=> chooseLicense(pro_license)} className="px-5">{t('Valitse')}</Button>
                            </div>
                        </div>
                  </Col>
                  <Col xs="12" sm="6" lg="3"  classname="mb-3" id="Enterprise">
                      <div className={`shadow-sm-strong h-lg-100 d-flex flex-column mb-4 ${preChoosen === 'Enterprise' ? 'border border-success border-2' : ''}`}>
                        <div className="p-3 mb-2 text-white" style={{backgroundColor: '#0077b6'}}>
                            <Typography variant="h4" className="text-center text-dark">
                                {t('subscription_options_info_enterprice_header', 'ENTERPRISE')}
                            </Typography>
                            <Typography variant="h5" className="text-center text-dark">
                                {t('subscription_options_info_enterprice_description', 'Sijoitusalan toimijoille')}
                            </Typography>
                            <Typography variant="h1" className="text-center text-dark">
                                {t('subscription_options_info_enterprice_price', 'Kysy lisää')}
                            </Typography>
                            <Typography variant="small" className="text-center text-transparent" style={{opacity:"0"}}>
                                {t("-") } 
                            </Typography>
                        </div>
                        <div>
                        <div className="px-3 pr-4 py-2 "><Typography variant="small"><i className="pr-2 fas fa-check-circle fa-lg text-success"></i>{t('subscription_options_info_users', 'Käyttäjien määrä')}, {t("rajoittamaton")}</Typography></div>
                          <div className="px-3 pr-4 py-2 "><Typography variant="small"><i className="pr-2 fas fa-check-circle fa-lg text-success"></i>{t('subscription_options_info_portfolios', 'Salkkujen lukumäärä')}, {t("rajoittamaton")}</Typography></div>
                          <div className="px-3 pr-4 py-2 "><Typography variant="small"><i className="pr-2 fas fa-check-circle fa-lg text-success "></i>{t('subscription_options_info_transactions', 'Kirjaukset (osto ja myynti)')}, {t("rajoittamaton")} </Typography></div>
                          <div className="px-3 pr-4 py-2 "><Typography variant="small"><i className="pr-2 fas fa-check-circle fa-lg text-success"></i>{t('subscription_options_info_privateStocks', 'Ulkopuoliset osakkeet ja rahastot')}</Typography></div>
                          <div className="px-3 pr-4 py-2 "><Typography variant="small"><i className="pr-2 fas fa-check-circle fa-lg text-success"></i>{t('subscription_options_info_EDS', 'Vaihdot, annit, osingot ja splitit')}</Typography></div>
                          <div className="px-3 pr-4 py-2 "><Typography variant="small"><i className="pr-2 fas fa-check-circle fa-lg text-success"></i>{t('subscription_options_info_XHEL', 'Nasdaq Helsinki')}</Typography></div>
                          <div className="px-3 pr-4 py-2 "><Typography variant="small"><i className="pr-2 fas fa-check-circle fa-lg text-success"></i>{t('subscription_options_info_XSTO', 'Nasdaq Tukholma')}</Typography></div>
                          <div className="px-3 pr-4 py-2 "><Typography variant="small"><i className="pr-2 fas fa-check-circle fa-lg text-success"></i>{t('subscription_options_info_XCSE', 'Nasdaq Kööpenhamina')}</Typography></div>
                          <div className="px-3 pr-4 py-2 "><Typography variant="small"><i className="pr-2 fas fa-check-circle fa-lg text-success"></i>{t('subscription_options_info_XICE', 'Nasdaq Islanti')}</Typography></div>
                          <div className="px-3 pr-4 py-2 "><Typography variant="small"><i className="pr-2 fas fa-check-circle fa-lg text-success"></i>{t('subscription_options_info_tax_form_9A', '9A-veroilmoitus')}</Typography></div>
                          <div className="px-3 pr-4 py-2 "><Typography variant="small"><i className="pr-2 fas fa-check-circle fa-lg text-success"></i>{t('subscription_options_info_profit_report', 'Tuottoraportti')}</Typography></div>
                          <div className="px-3 pr-4 py-2 "><Typography variant="small"><i className="pr-2 fas fa-check-circle fa-lg text-success"></i>{t('subscription_options_info_options', 'Johdannaiset')}</Typography></div>
                          <div className="px-3 pr-4 py-2 "><Typography variant="small"><i className="pr-2 fas fa-check-circle fa-lg text-success"></i>{t('subscription_options_info_APIs', 'Rajapinnat')}</Typography></div>
                          <div className="px-3 pr-4 py-2 "><Typography variant="small"><i className="pr-2 fas fa-check-circle fa-lg text-success"></i>{t('subscription_options_info_ownDB', 'Oma tietokanta')}</Typography></div>

                        </div>
                        {/*
                          !isAuthenticated &&
                          <div className="d-flex justify-content-center py-2 mb-2 mt-auto">
                            <a href={mailtoLink}>
                              <Button variant="light"   className="px-5 text-dark">{t('Ota yhteyttä')}</Button>
                            </a>
                          </div>
                        */}
                          <div className="d-flex justify-content-center py-2 mb-2 mt-auto">
                            <a href={mailtoLink}>
                              <Button variant="light"   className="px-5 text-dark">{t("contact_us",'Ota yhteyttä')}</Button>
                            </a>
                          </div>
                      </div>

                  </Col>
                </Row>

                {isAuthenticated ? (
                    <Row className="my-3 mb-4">
                        <Col>
                            <div className="bg-default p-3" rounded>
                                <Typography variant="h4" bold className="text-center" >{t("subscription_options_info_downgrade","Jos haluat peruuttaa tai siirtyä alemmalle tasolle, niin ota yhteyttä tukeemme.")}</Typography>
                            </div>
                        </Col>
                    </Row>
                ) : null }
                {isAuthenticated &&
                  <Row>
                      <Col className="mb-3">
                    <Button onClick={handleReject} variant="default">{t("Close","Sulje")}</Button>
                      </Col>
                  
                  {/*
                  {isAuthenticated ? (

                        <Col className="mb-3 text-right">
                            <Button variant="secondary" >
                                <Link href="https://www.investime.fi/ota-yhteytta/" target="_blank" className="text-dark">{t("subscription_options_info_change_subcription","Vaihda tilausta")}</Link>
                            </Button>
                        </Col>
                    ) : null }
                  */}
                    
                  </Row>
                }

                <Row>
                  <Col>
                    <div>
                      <img src={imageUrl} alt="Visma Banner" className="w-100"/>
                    </div>
                  </Col>
                </Row>

              </CardBody>
            </Card>
          </Col>
        </Row>
    )
}

export default SubscriptionOptionsInfo;
