//Create simple react Component

import React, { useState, useCallback, useEffect } from "react";
import { Button, Typography, Link, Spinner, Modal } from "stories/components";

import { useTranslation } from 'react-i18next';
import { Container, Row, Col, FormContainer, Card, CardBody } from "stories/layout";
import PortfolioSelectStore from "components/Forms/PortfolioSelect/PortfolioSelectStore"
import { Input, SelectMulti, Toggle, Radio, Checkbox } from "stories/forms";
import { useStoreState, useStoreActions } from 'easy-peasy';



import PurchaseEdit from "views/app/PurchaseEdit/PurchaseEdit"
import PrivateInvestmentFundDividendEdit from "views/app/PrivateInvestmentFundDividendEdit/RealEstateDividendEdit"
import RealEstateEdit from "views/app/PrivateInvestmentFundEdit/RealEstateEdit"

import PrivateInvestmentFundSplitEdit from "views/app/PrivateInvestmentFundSplitEdit/PrivateInvestmentFundSplitEdit"
import PrivateInvestmentFundRateEdit from "views/app/PrivateInvestmentFundRateEdit/PrivateInvestmentFundRateEdit"
import AccountSecurityRelationEditV2 from "views/app/AccountSecurityRelationEdit/AccountSecurityRelationEditV2"
import AccountingPriceUpdate from "views/app/AccountingPriceUpdate/AccountingPriceUpdate"
import AccountSecurityRelationEdit from "views/app/AccountSecurityRelationEdit/AccountSecurityRelationEdit"
import PrivateInvestmentFundEdit from "views/app/PrivateInvestmentFundEdit/PrivateInvestmentFundEdit"

import { httpGetSplits } from "services/splits"
import TradingViewWidget from "components/TradingViewWidget/TradingViewWidget"
import TradingViewWidgetSymbolOverview from "components/TradingViewWidget/TradingViewWidgetMini"
import TradingViewWidgetTechnicalAnalysis from "components/TradingViewWidget/TradingViewWidgetTechnicalAnalysis"




const NewRecord = (props) => {
    const { t } = useTranslation()
    const [splits, setSplits] = useState([])
    const account = useStoreState((state) => state.account?.account);

    let symbol = props?.objectId?.symbol
    let stock_market = props?.objectId?.stock_market

    if (stock_market == "XHEL") {
        stock_market = "OMXHEX"
    }
    if (stock_market == "XSTO") {
        stock_market = "OMXSTO"
    }

    //if symbol include space replace with _ for tradingview
    if (symbol?.includes(" ")) {
        symbol = symbol?.replace(" ", "_")
    }



    const value_id = props?.objectId?.id

    let related_model_type = null
    let type = null
    let related_model_id = null


    if (props?.objectId?.id) {
        related_model_type = value_id?.split(",")[0]
        type = value_id?.split(",")[1]
        related_model_id = value_id?.split(",")[2]
    }

    const handleCancel = () => {
        props.handleReject()
    }


    const getSplits = () => {
        let params = {
        //   ...filters,
          ordering: "-date_effective",
          related_model_type: 30,
          related_model_id: related_model_id,
        }
        if(params?.related_model_id != null && params?.related_model_id != "" ) {
    
          httpGetSplits(params).then(res => {
            setSplits(res?.data)
            console.log(res?.data)
          })
        }
      }

    useEffect(() => {
        getSplits()
    }, [])
    

     // Modal: New/Edit Dividend
  
    const [modalEditDividendOpen, setModalEditDividendOpen] = useState(false)
    const [modalEditDividendId, setModalEditDividendId] = useState()
    const [investmentType, setInvestmentType] = useState("")
    const [tickerSymbol, setTickerSymbol] = useState('')
    const [filters, setFilters] = useState({})
    const [showExtraField, setShowExtraField] = useState(false)

    
    const toggleModalEditDividend = () => {
        setModalEditDividendOpen(s => !s);
    }
    
    const openModalEditDividend = (id, related_model_type, ticker_symbol) => {
        setModalEditDividendOpen(true)
        setModalEditDividendId(id);
        setInvestmentType(related_model_type)
        setTickerSymbol(ticker_symbol)
    
    }
    
    const modalEditDividendResolve = () => {
        console.log("modalEditDividendResolve")
        setModalEditDividendOpen(false);
        setModalEditDividendId(null);
        handleCancel()
        
        // getTransactions()
    }
    
    const modalEditDividendReject = () => {
        setModalEditDividendOpen(false);
        setModalEditDividendId(null);
        handleCancel()
    }
    
     // Modal: New/Edit Split
  
    const [modalEditSplitOpen, setModalEditSplitOpen] = useState(false)
    const [modalEditSplitId, setModalEditSplitId] = useState()
    
    const toggleModalEditSplit = () => {
        setModalEditSplitOpen(s => !s);
    }
    
    const openModalEditSplit = (id, related_model_type, ticker_symbol) => {
        setModalEditSplitOpen(true)
        setModalEditSplitId(id);
        setInvestmentType(related_model_type)
        setTickerSymbol(ticker_symbol)
    
    }
    
    const modalEditSplitResolve = () => {
        console.log("modalEditSplitResolve")
        setModalEditSplitOpen(false);
        setModalEditSplitId(null);
        handleCancel()
        
        // getTransactions()
    }
    
    const modalEditSplitReject = () => {
        setModalEditSplitOpen(false);
        setModalEditSplitId(null);
        handleCancel()
    }

    // Modal: New/Edit Rate

    const [modalEditRateOpen, setModalEditRateOpen] = useState(false)
    const [modalEditRateId, setModalEditRateId] = useState()
    
    const toggleModalEditRate = () => {
        setModalEditRateOpen(s => !s);
    }
    
    const openModalEditRate = (id, related_model_type, ticker_symbol) => {
        setModalEditRateOpen(true)
        setModalEditRateId(id);
        setInvestmentType(related_model_type)
        setTickerSymbol(ticker_symbol)
    
    }
    
    const modalEditRateResolve = () => {
        console.log("modalEditRateResolve")
        setModalEditRateOpen(false);
        setModalEditRateId(null);
        handleCancel()
        
        // getTransactions()
    }
    
    const modalEditRateReject = () => {
        setModalEditRateOpen(false);
        setModalEditRateId(null);
        handleCancel()
    }




      // Modal: update accounting price

      const [modalEditAccountingPrice, setModalEditAccountingPrice] = useState(false)
      const [modalEditAccountingPriceId, setModalEditAccountingPriceId] = useState()
      
      const toggleModalEditAccounting = () => {
          setModalEditAccountingPrice(s => !s);
      }
      
      const openModalEditAccounting = (id, related_model_type, ticker_symbol) => {
          setModalEditAccountingPrice(true)
          setModalEditAccountingPriceId(id);
          setInvestmentType(related_model_type)
          setTickerSymbol(ticker_symbol)
      
      }
      
      const modalEditAccountingResolve = () => {
          console.log("modalEditAccountingResolve")
          setModalEditAccountingPrice(false);
          setModalEditAccountingPriceId(null);
          handleCancel()
          
          // getTransactions()
      }
      
      const modalEditAccountingReject = () => {
          setModalEditAccountingPrice(false);
          setModalEditAccountingPriceId(null);
          handleCancel()
      }




    const handleExtraFieldChange = (event) => {
        setShowExtraField(event.target.checked)
      }

    // PrivateInvestmentFundEdit

     const [modalEditFundOpen, setModalEditFundOpen] = useState(false)
     const [modalEditFundId, setModalEditFundId] = useState()
     
     const toggleModalEditFund = () => {
       setModalEditFundOpen(s => !s);
     }
     
     const openModalEditFund = (id) => {
       setModalEditFundOpen(true)
       setModalEditFundId(id);
     }
     
     const modalEditFundResolve = () => {
       setModalEditFundOpen(false);
       setModalEditFundId(null);
     }
     
     const modalEditFundReject = () => {
       setModalEditFundOpen(false);
       setModalEditFundId(null);
     }


    // SecurityAccountRelationEdit

    const [modalEditSecurityAccountOpen, setModalEditSecurityAccountOpen] = useState(false)
    const [modalEditSecurityAccountId, setModalEditSecurityAccountId] = useState()
    
    const toggleModalEditSecurityAccount = () => {
        setModalEditSecurityAccountOpen(s => !s);
    }
    
    const openModalEditSecurityAccount = (id) => {
        setModalEditSecurityAccountOpen(true)
        setModalEditSecurityAccountId(id);
    }
    
    const modalEditSecurityAccountResolve = () => {
        setModalEditSecurityAccountOpen(false);
        setModalEditSecurityAccountId(null);
    }
    
    const modalEditSecurityAccountReject = () => {
        setModalEditSecurityAccountOpen(false);
        setModalEditSecurityAccountId(null);
    }


    return (
        <Container>
            {/* {JSON.stringify(splits)} */}
            <Row className="border-bottom py-2">
                <Col className="mt-1">
                <h2  className="text-dark" >{ `${t("New record","Toiminnot")} (${props?.objectId?.name} ) `}</h2>

                </Col>

            </Row>
            {/* {JSON.stringify(props?.objectId)} */}
            {/* <TradingViewWidget symbol= {`${stock_market}:${symbol}`} /> */}

           
    
            <Row>
                <Col className="py-2">
                {
                    related_model_type < 40 &&
                    <>
                    <div className="rounded bg-secondary mb-3" style={{ cursor: 'pointer' }}>
                        <div
                            // to={{pathname:"/dashboard/transactions", state: {newDividend:true}}}
                            // onClick={() => props.handleResolve()}
                            onClick={() => openModalEditDividend(null, "XHEL", null)}
                        >
                        <Typography variant="h3" className="text-white p-3 text-center">{t("real_estate_new_record_dividend","Kirjaa uusi kulu/tuotto")}</Typography>
                        </div>
                    </div>
                    {/* <div className="rounded bg-secondary mb-3">
                        <Link
                            to={{pathname:"/dashboard/private_investment_funds/listed_stock", state: {newSecurity:true}}}
                            onClick={() => props.handleResolve()}
                        >
                        <Typography variant="h3" className="text-white p-3 text-center">{t("New Exchange","Lisää arvopaperi")}</Typography>
                        </Link>
                    </div> */}
                    </>
                }
    
                {related_model_type == 30 &&
                    <>
                        <div className="rounded bg-secondary mb-3"  style={{ cursor: 'pointer' }}>
                            <div
                                onClick={() => openModalEditRate(null, "XHEL", null)}

                            >
                            <Typography variant="h3" className="text-white p-3 text-center">{t("real_estate_new_record_rate","Kirjaa uusi markkina-arvo")}</Typography>
                            </div>
                        </div>
     
             
                    </>
                }

                {related_model_type == 30 ?(
                    <>
                    <div className="rounded bg-secondary mb-3" style={{ cursor: 'pointer' }}>
                        <Typography variant="h3" className="text-white p-3 text-center"  >{t("real_estate_new_record_rent_contract","Lisää vuokrasopimus")}</Typography>
                    </div>
                    <div className="rounded bg-secondary mb-3" style={{ cursor: 'pointer' }}>
                        <Typography variant="h3" className="text-white p-3 text-center"  >{t("real_estate_new_record_rent_contract","Lisää tosite")}</Typography>
                    </div>
                    <div className="rounded bg-secondary mb-3" style={{ cursor: 'pointer' }}>
                        <Typography variant="h3" className="text-white p-3 text-center"  onClick={() => openModalEditFund(related_model_id)}>{t("real_estate_new_record_edit","Muokkaa")}</Typography>
                    </div>

                    </>
                
                ):(
                    
                    <div className="rounded bg-secondary mb-3" style={{ cursor: 'pointer' }}>
                        <Typography variant="h3" className="text-white p-3 text-center"  onClick={() => openModalEditSecurityAccount(related_model_id)}>{t("real_estate_new_record_edit","Muokkaa")}</Typography>
                    </div>
                )
                }
                </Col>
            </Row>
            {/* {
                account?.show_relation_to_security &&
            <AccountSecurityRelationEdit objectId={account.id}  related_model_type={related_model_type} related_model_id={related_model_id} />
            } */}



            <Row>
                <Col className="mb-3">
                <Button onClick={handleCancel} variant="default">{t("Cancel","Peruuta")}</Button>
                </Col>

            </Row>


            <Modal
                title={ `${t("real_estate_new_record_dividend","")} (${props?.objectId?.name} |  ${props?.objectId?.symbol}) `}
                isOpen={modalEditDividendOpen}
                toggleModal={toggleModalEditDividend}
                // size="modal-xl"
                // showHeader={false}
                // classNameBody="p-0 padding-0"
            >
                <PrivateInvestmentFundDividendEdit
                // objectId={modalEditDividendId}
                handleResolve={modalEditDividendResolve}
                handleReject={modalEditDividendReject}
                related_model_type={related_model_type}
                related_model_id={related_model_id}
                defaultStockMarket={"XHEL"}
                defaultInvestmentType={10}
                setFilters={setFilters}
                // transaction_count = {count}
                // portfoliosSummary={portfoliosSummary}
                />
            </Modal>

            <Modal
                title={ `${t("real_estate_new_record_split","SPLIT")} (${props?.objectId?.name} |  ${props?.objectId?.symbol}) `}
                isOpen={modalEditSplitOpen}
                toggleModal={toggleModalEditSplit}
                // size="modal-xl"
                // showHeader={false}
                // classNameBody="p-0 padding-0"
            >
                <PrivateInvestmentFundSplitEdit
                // objectId={modalEditSplitId}
                handleResolve={modalEditSplitResolve}
                handleReject={modalEditSplitReject}
                related_model_type={related_model_type}
                privateInvestmentFund={related_model_id}
                defaultStockMarket={"XHEL"}
                defaultInvestmentType={10}
                setFilters={setFilters}
                // transaction_count = {count}
                // portfoliosSummary={portfoliosSummary}
                />
            </Modal>
            <Modal
                title={ `${t("real_estate_new_record_rate","Uusi hinta")} (${props?.objectId?.name} |  ${props?.objectId?.symbol}) `}
                isOpen={modalEditRateOpen}
                toggleModal={toggleModalEditRate}
                // size="modal-xl"
                // showHeader={false}
                // classNameBody="p-0 padding-0"
            >
                <PrivateInvestmentFundRateEdit
                // objectId={modalEditRateId}
                handleResolve={modalEditRateResolve}
                handleReject={modalEditRateReject}
                related_model_type={related_model_type}
                privateInvestmentFund={related_model_id}
                defaultStockMarket={"XHEL"}
                // defaultInvestmentType={10}
                // setFilters={setFilters}
                splits= {splits}
                // transaction_count = {count}
                // portfoliosSummary={portfoliosSummary}
                />
            </Modal>

            <Modal
                title={t("PrivateInvestmentFundEdit_title", "Oma arvopaperi")}
                isOpen={modalEditFundOpen}
                toggleModal={toggleModalEditFund}
                >
                <RealEstateEdit
                objectId={modalEditFundId}
                handleResolve={modalEditFundResolve}
                handleReject={modalEditFundReject}
                />
            </Modal>

            <Modal
                title={ `${t("real_estate_new_record_security_account","Muokkaa")} (${props?.objectId?.name} |  ${props?.objectId?.symbol}) `}
                isOpen={modalEditSecurityAccountOpen}
                toggleModal={toggleModalEditSecurityAccount}
                // size="modal-xl"
                // showHeader={false}
                // classNameBody="p-0 padding-0"
            >
                <AccountSecurityRelationEditV2 
                objectId={account.id}  
                account={account}
                related_model_type={related_model_type} 
                related_model_id={related_model_id} 
                security={props?.objectId}
                handleResolve={modalEditSecurityAccountResolve}
                handleReject={modalEditSecurityAccountReject}
                />

            </Modal>

            <Modal
                title={ `${t("real_estate_new_record_accounting_price_update","Päivitä kirjanpitoarvo")} (${props?.objectId?.name} |  ${props?.objectId?.symbol}) `}
                isOpen={modalEditAccountingPrice}
                toggleModal={toggleModalEditAccounting}
                // size="modal-xl"
                // showHeader={false}
                // classNameBody="p-0 padding-0"
            >
                <AccountingPriceUpdate
                objectId={account.id}  
                account={account}
                related_model_type={related_model_type} 
                related_model_id={related_model_id} 
                security={props?.objectId}
                handleResolve={modalEditAccountingResolve}
                handleReject={modalEditAccountingReject}
                />

            </Modal>


        </Container>
    )
}

export default NewRecord;
