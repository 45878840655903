import { action, thunk, persist } from 'easy-peasy';
import { httpGetHelptexts } from "services/helptexts"

const model = {
  loading: false,
  setLoading: action((state, payload) => {
    state.loading = payload
  }),
  helptexts: [],
  setHelptexts: action((state, payload) => {
    state.helptexts = payload
  }),
  count: 0,
  setCount: action((state, payload) => {
    state.count = payload
  }),
  getHelptexts: thunk(async (actions, payload) => {
    actions.setLoading(true)
    httpGetHelptexts().then(response => {
      if (response?.data) {
        //convert to object
        let obj = {}
        response.data?.helptexts.forEach(element => {
          obj[element.key] = element
        });
        actions.setHelptexts(obj)
        // actions.setHelptexts(response?.data)
      }
    }, error => {
      console.log(error)
    }).finally(() => {
      actions.setLoading(false)
    })
    
  }),
};

export default model;