import React, { useEffect, useState, useCallback } from "react";
import moment from "moment";
import { useTranslation } from 'react-i18next';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useLocation } from "react-router-dom";
import queryString from 'query-string'
import { Route, Switch, Redirect } from "react-router-dom";
import { generatePath } from "react-router";



// Stories
import { Row, Col } from "stories/layout";
import { Input } from "stories/forms";
import { Card, Typography, Button, Link, IconButton, Modal, Spinner, BreadcrumbsDynamic } from "stories/components";
import { PageHeader, PageContainer } from "components/Page"
import {  Pagination } from "stories/tables";

import { TableAuto } from "stories/tables"
import PageTabs from "components/PageTabs/PageTabs"
import PrivateInvestmentFundRatesChart from "views/app/PrivateInvestmentFundRatesChart/PrivateInvestmentFundRatesChart"
import PrivateStockSplitsList from "views/app/PrivateStockSplitsList/PrivateStockSplitsList"
import { httpGetPrivateStockDatas } from "services/private_stocks"

import { httpGetDividends } from "services/dividends"

import PrivateInvestmentFundDividendsList from "views/app/PrivateInvestmentFundDividendsList/PrivateInvestmentFundDividendsList"
import Technicalanalysis from "views/app/Technicalanalysis/Technicalanalysis"


// App
import StockMarketSelect from "components/Forms/StockMarketSelect/StockMarketSelect"
import StockSelect from "components/Forms/StockSelect/StockSelect"
import StockPrivateSelect from "components/Forms/StockPrivateSelect/StockPrivateSelect"
import CurrencySelect from "components/Forms/CurrencySelect/CurrencySelect" 

import { httpGetStockData } from "services/stocks"
import { getDate } from "services/datetime"
import PrivateStockRatesList from "views/app/PrivateStockRatesList/PrivateStockRatesList"
import PublicStockRatesList from "views/app/PublicStockRatesList/PublicStockRatesList"


const StockRatesList = ({match, history}) => {
  
  const { t } = useTranslation();
  const location = useLocation();
  const stocks = useStoreState((state) => state.stocks?.objects);
  const [loading, setLoading] = useState(false)
  const getBasicDataObjects = useStoreActions((actions) => actions.stocks.getObjects);
  const [addDividend, setAddDividend] = useState()


  const [objectsToChart, setObjectsToChart] = useState()
  const [addStockPrice, setAddStockPrice] = useState()
  const [addSplit, setAddSplit] = useState()
  const [dividends, setDividends] = useState([])

  const [filters, setFilters] = useState({
    limit: 30,
    offset:0,
    stock_market: "XHEL",
    ordering: "-stock_date",
  })


  

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    if (parsed?.stock_market) {
      getBasicDataObjects({stock_market: parsed?.stock_market})
      setFilters(s => ({
        ...s,
        stock_market: parsed?.stock_market,
      }))
    }
    if (location.state?.stockId){
      if (location.pathname.includes("private_stock") ){

        setFilters(s => ({
          ...s,
          private_stock: location?.state?.stockId,
          private_stock_name: location?.state?.stockName ,
          stockCurrency: location?.state?.stockCurrency
        }))
      }
      else{
        setFilters(s => ({
          ...s,
          stock: location?.state?.stockId,
          stock_name: location?.state?.stockName,
          stockCurrency: location?.state?.stockCurrency,
          passed_nasdaq_id: location?.state?.nasdaq_id,
          stock_market: location?.state?.stock_market,
          symbol: location?.state?.symbol,

        }))
      }
    }
//add useffect tricker that if page is reloaded, it will get the data from the url

  }, [location])


 

 
  
  const handleChange = ({target}, child) => {
    setFilters(s => ({
      ...s,
      [target?.name]: target.value
    }))
  }
  

  
  const handleStockMarketChange = ({target}) => {
    // target = target
    setFilters(s => ({
      ...s,
      stock_market: target.value,
      private_stock: "",
      private_stock_name: "", 
      stock: "",
      stock_name: "",
    }))
    getBasicDataObjects({stock_market: target.value})
  }

  const getDividends = () => {
    let params = {
      ...filters,
      ordering: "-ex_date",
      related_model_type: 10,
      related_model_id: filters?.stock
    }
    if(params?.related_model_id != null && params?.related_model_id != "" ) {

      httpGetDividends(params).then(res => {
        setDividends(res?.data?.results)
        console.log(res?.data)
      })
    }
  }
  
  const setFieldValue = (name, value) => {
    
    if (typeof value === 'object') {
      setFilters(s => ({
        ...s,
        [name]: value?.id > 0 ? value?.id : undefined,
        offset: 0,
      }))
    }
    else {
      setFilters(s => ({
        ...s,
        [name]: value,
        offset: 0,
      }))
    }
  }
  
  const resolveStockPrivate = (params) => {
    if (params?.name) {
      setFieldValue("private_stock_name", params?.name)
    }

  }

  const resolveStock = (params) => {
    if (params?.name) {
      setFieldValue("stock_name", params?.name)
    }

  }

  const twoDigits = (number) => {
    number = Number(number)
    return number && number.toFixed(2)
  }

  // useEffect(() => {


  //   const params = {...filters}
  //   delete params.limit
  //   getBasicDataObjects(params)
  //   console.log(params)
  //   // setFilters(s => ({
  //   //   ...s,
  //   //   private_stock: null,
  //   //   private_stock_name: null, 
  //   //   stock: null,
  //   //   stock_name: null,

  //   // }))
   
  // }, [filters.stock_market])
  const getCrumbLinks = () => {
    const crumbLinks = [
      {name: t("Etusivu"), url: "/dashboard/"},
      {name:  location.pathname.includes("private_stock") ?  t("PrivateStocksList_title","Ulkopuoliset osakkeet"):t("StocksList_title","Arvopaperit") , url: location.pathname.includes("private_stock") ? "/dashboard/private_stocks" : "/dashboard/private_investment_funds"},

      // {name: t("stock_rates_list_title","Kurssit"), url: "/dashboard/private_stocks/rates?stock_market=PRIVATE", optional: true},
      
      {name: location.pathname.includes("private_stock") ? filters.private_stock_name: filters.stock_name , url:location.pathname.includes("private_stock") ? "/dashboard/private_stocks": "/dashboard/stocks", optional: true},
    ]

    //if location state include crumbLinks return them
    if (location?.state && location?.state?.crumbLinks) {
      //made new const and deapcopy location.state.crumbLinks into it
      const crumbLinks = [...location.state.crumbLinks]
      // crumbLinks.push({name:  t("stock_rates_list_title","Kurssit"), url: "/dashboard/private_stocks/rates?stock_market=PRIVATE", optional: true})
      crumbLinks.push({name: location.pathname.includes("private_stock") ? filters.private_stock_name: filters.stock_name , url:location.pathname.includes("private_stock") ? "/dashboard/private_stocks": "/dashboard/stocks", optional: true})
      return crumbLinks
    }
    //else return default links
    return crumbLinks
  }
  
  const getFormikInputProps = useCallback(
    name => ({
      name,
      value: filters[name],
      onChange: handleChange
    }),
    [filters, handleChange]
  );


  
  const getDefaultValues = useCallback(() => {
    if (location?.state?.stockId && !filters?.private_stock && !filters?.stock==="stock_market_changed") {
      return {value: location.state?.stockId, label: location.state?.stockName}
    }
    if (filters?.stock_market === "PRIVATE") {
    return {value: filters?.private_stock, label: filters?.private_stock_name }
    }
    return {value: filters?.stock, label: filters?.stock_name, stockCurrency: filters?.stockCurrency }
  }, [filters])

  // const getObjectsToChart = useCallback(() => {


  //     let params = {
  //       ...filters,
  //     }
  //     console.log("getStockRates", params)
  //     httpGetPrivateStockDatas(params).then(res => {
  //       console.log("getStockRates", res?.data?.results)
  //       return (res?.data?.results)
  //     })
  // }, [filters])

  const findStockObject = (id) => {
    const filteredObjects = stocks && stocks.filter(o => o.id === id)
    if (filteredObjects && filteredObjects.length > 0) {
      return filteredObjects[0]
    }
    return null
  }

  //OBJECTS TO CHART
  useEffect(() => {
    let params = {
      ...filters,
    }

    if (filters?.stock_market === "PRIVATE") {
    httpGetPrivateStockDatas(params).then(res => {
      setObjectsToChart(res?.data?.results)
    })}
    else {
      const stockObject = findStockObject(filters?.stock)
      let nasdaq_id = undefined; // "";
      if (stockObject) {
        nasdaq_id = stockObject?.nasdaq_id
      }
      else{
        nasdaq_id = filters?.passed_nasdaq_id
      }

      const stock_date = undefined; // moment("2021-12-28").toISOString().split('T')[0] +" 00:00"; // "2021-12-27";
      let paramsToStockData = {
        ...filters,
        nasdaq_id: nasdaq_id,
        stock_date__gte: "1970-01-01"
      }
      if (filters?.stock && filters.stock_name && paramsToStockData.nasdaq_id){

        httpGetStockData(paramsToStockData).then(res => {
          setObjectsToChart(res?.data?.results)
        }
        )
      }
    }

  }, [filters, stocks])


  const sortedObjects = (objectsToSort) => {
    objectsToSort?.sort((a, b) => {
      return new Date(a?.date) - new Date(b?.date);
    })
    return {sorted: objectsToSort, latest_price: (objectsToSort?.[0]?.updated_ending_price || objectsToSort?.[0]?.updated_price)}
  }

  // PAGE TABS  
  const tabs = [
    {
      key: "rate_list",
      label: t("rate_list", "Kurssit"),
    },
    {
      key: "dividends",
      label: t("rate_dividends", "Osingot"),
    },
    {
      key: "splits",
      label: t("splits", "Splitit"),
    },
    {
      key: "rate_chart",
      label: t("rate_chart", "Tekninen analyysi"),
    },
  ]
  //remove split-tab if nasdaq view.
  if (filters?.stock_market != "PRIVATE"){
    tabs.splice(2, 1)
  }

  //VIEW  BIG COMPONENTS 
  const getStockSelect = () => {
    if (filters?.stock_market === "PRIVATE") {
      return (
        <StockPrivateSelect
          {...getFormikInputProps("private_stock")}
          label={t("stock_rates_list_private_stock", "Osake (Stock)")}
          setFieldValue={setFieldValue}
          handleResolve={resolveStockPrivate}   
          value= {getDefaultValues()}
          defaultValues={getDefaultValues()}
          ratesView={true}

        />
      )
    }
    else {
      return (
        <StockSelect
          {...getFormikInputProps("stock")}
          label={t("stock_rates_list_stock", "Osake (Stock)")}
          setFieldValue={setFieldValue}
          handleResolve={resolveStock}
          showAll={false}
          value= {getDefaultValues()}
          defaultValues={getDefaultValues()}
          setFieldValueReturnId={true}
          ratesView={true}
        />
      )
    }
  }

  const listView = (props) => {
    return(
      <>
      { filters?.stock_market === "PRIVATE" ? (
        <PrivateStockRatesList 
          filters={filters} 
          setFilters={setFilters} 
          setObjectsToChart={setObjectsToChart}
          setAddStockPrice={setAddStockPrice}


        />
      ) : (
        <PublicStockRatesList 
          filters={filters} 
          setFilters={setFilters}
          setObjectsToChart={setObjectsToChart}
          objectsToChart={objectsToChart}
        />
      )}


      </>
    )
  }
  
  return (
    <>
      {/* {JSON.stringify(filters)}
      {JSON.stringify(findStockObject(filters?.stock))} */}
      <PageHeader title={filters?.stock_name || t("stock_rates_list_title","Kurssit")} />
      <div className="d-flex">

       <BreadcrumbsDynamic links={getCrumbLinks()} />
       <div className="ml-auto">
       </div>
      </div>
      <div className="d-flex">
        <PageTabs match={match} location={location} tabs={tabs} />
        <div className="ml-auto">
          {location.pathname.includes("rate_list") ? (
            addStockPrice
            ):(
              <>
      
              </>
              )
            }
            {location.pathname.includes("splits") ? (
              addSplit
              ):(
                <>
                </>
                )
              }
            {location.pathname.includes("dividends") ? (
              addDividend
              ):(
                <>
                </>
                )
            }
        </div>
      </div>
      
      <PageContainer>
      
        <div className="row p-2 mb-2">
        
          {/* <div className="col-sm-6 col-md-4 col-lg-2">
            <StockMarketSelect onChange={handleStockMarketChange} value={filters?.stock_market} showPrivate={true} />
          </div> */}
          {/* <div className="col-sm-6 col-md-4 col-lg-2">
            {getStockSelect()}

          </div> */}
          <div className="col-sm-6 col-md-4 ml-auto align-items-center ">
          {location.pathname.includes("rate_list") || location.pathname.includes("splits")  ? (
            <div className="d-flex align-items-center justify-content-end">
            <div className="mx-2" variant="h4">{t("PrivateInvestmentFundsList_count", "Näytä")}</div>
            <Pagination 
            paginationOffset={filters?.offset}
            paginationLimit={filters?.limit}
           
            setFilters={setFilters}
            showPaginationCurrentPage={false}
            showPaginationNextAndPrevious={false}
            showVerticalBorders={false}
            disabled={false}
            />
            <div className="mx-2" variant="h4">{t("Riviä")}</div>

            <div className="ml-4">
            <CurrencySelect 
              label={""}
              disabled={true}
              value={filters?.stockCurrency?filters?.stockCurrency:""}
              noDefault={true}
            />
            </div>
            </div>
            
            ):(

              <div className="d-flex align-items-center justify-content-end">
                {/* <Typography bold className="p-1"> {twoDigits(sortedObjects(objectsToChart)?.latest_price)}</Typography>

                <CurrencySelect 
                  label={""}
                  disabled={true}
                  value={filters?.stockCurrency?filters?.stockCurrency:""}
                  noDefault={true}

                /> */}
              </div>
            )

          }

          </div>
          
        </div>
        <Switch>
          <Route
            path={
              generatePath(match.path + "/:tab", {
                tab: "rate_list"
              })
            }
            render={props => listView(props)}

          />
          {loading ? (
            <div className="d-flex justify-content-center">
              <Spinner animation="border" role="status">

              </Spinner>
            </div>
          ) : (

            <Route
            path={
              generatePath(match.path + "/:tab", {
                tab: "rate_chart"
              })
            }
            // render={props => <PrivateInvestmentFundRatesChart {...props} objects={objectsToChart} xAxes="stock_date" yAxes={filters.stock_market === "PRIVATE"? "updated_price": "updated_ending_price"}/>}
            render={props => <Technicalanalysis  data={filters}/>}
            />
          )

          }
          <Route
            path={
              generatePath(match.path + "/:tab", {
                tab: "splits"
              })
            }
            render={props =>  <PrivateStockSplitsList  {...props} filters={filters} setFilters={setFilters} setObjectsToChart={setObjectsToChart} setAddSplit={setAddSplit} />}

          />

          <Route
            path={
              generatePath(match.path + "/:tab", {
                tab: "dividends"
              })
            }
            render={props =>  <PrivateInvestmentFundDividendsList  {...props} getRates={null} related_model_type={10} setObjectsToChart={null} filters={filters} getSplitsParent={getDividends} setFilters={setFilters} setAddSplit={setAddDividend} />}

          />

        </Switch>
         

  
      </PageContainer>
      
        
    </>
  );
}

export default StockRatesList;
