import React, { useEffect, useState, useCallback } from "react";
import moment from "moment";
import { useTranslation } from 'react-i18next';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useAuthContext } from "contexts/AuthContext"
import { useAppContext } from "contexts/AppContext"



import { Row, Col } from "stories/layout";
import { Input } from "stories/forms";
import { Card, Typography, Button, Link, IconButton, Modal, Spinner, BreadcrumbsDynamic } from "stories/components";
import { PageHeader, PageContainer } from "components/Page"
import { TableAuto } from "stories/tables"

// import PortfolioEdit from "views/app/PortfolioEdit/PortfolioEdit"
import SubscriptionOptionsInfo from "components/Info/SubscriptionOptionsInfo.js"

import UpdateUp from "views/vismapay/UpdateUp/UpdateUp"



const PortfoliosList = ({match, history, location}) => {
  
  const { t } = useTranslation();
  const portfolios = useStoreState((state) => state.account?.accounts);
  const setPortfolio = useStoreActions((actions) => actions.portfolio.setPortfolio);

  const loading = useStoreState((state) => state.portfolio?.loading);
  const count = useStoreState((state) => state.portfolio?.count);
  const getPortfolios = useStoreActions((actions) => actions.portfolio.getPortfolios);
  const account = useStoreState((state) => state.account?.account);

  const { getCompanyInstance } = useAppContext()

  const { isAdmin } = useAuthContext()
  

  const getCrumbLinks = () => {
    const crumbLinks = [
      {name: t("Etusivu"), url: "/dashboard/"},
      {name: t("accounts_list_title","Asiakkaat"), url: "/dashboard/portfolios"},
    ]

    //if location state include crumbLinks return them
    if (location?.state && location?.state?.crumbLinks) {
      location.state.crumbLinks.push({name: t("accounts_list_title","Asiakkaat"), url: "/dashboard/accounts"})
      return location.state.crumbLinks
    }
    //else return default links
    return crumbLinks
  }
  
  const [filters, setFilters] = useState({
    limit: 30,
    offset:0,
  })
  
  const handleChange = ({target}) => {
    setFilters({
      ...filters,
      [target.name]: target.value,
    })
  }
  
  const paginationPrevious = () => {
    let offset = filters?.offset - filters?.limit;
    if (offset < 0) {
      offset = 0;
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  const paginationNext = () => {
    let offset = filters?.offset + filters?.limit;
    if (offset > filters?.count) {
      return
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  const headers = [
    { label: t("accounts_list_name", "Asiakas"), key: "name"},
    { label: t("accounts_list_created", "Luotu"), key: "created"},
    // { label: t("accounts_list_currency_label", "Valuutta"), key: "currency_label"},
    // { label: t("accounts_list_company", "Yritys"), key: "company"},

    // { label: t("accounts_list_value", "Markkina-arvo"), key: "value"},
    // { label: t("accounts_list_expenses", "Hankintameno"), key: "expenses"},
    // { label: t("accounts_list_profit", "Tuotto"), key: "profit"},
    // { label: t("accounts_list_change", "Muutos"), key: "change"},
    // { label: t("accounts_list_change_eur", "Muutos EUR"), key: "change_eur"},
    // { label: t("accounts_list_other_profit_expenses", "Muut tuotot ja kulut"), key: "other_profit_expenses"},
    { label: t("accounts_list_actions", " "), key: "actions"},
  ];

  const passPortfolio  = (id) => {
    // get query params id
      console.log("id", id)
      const filtered = portfolios.filter(o => o.id == id)
      if (filtered && filtered.length > 0) {
        // setPortfolio(filtered[0])
      }
  }




  
  
  
  const getTableRows = useCallback(() => {
    return portfolios && portfolios.map((row, index) => {
      return {
        ...row,
        name: row?.name || "-", 
        created: moment(row?.created).format("DD.MM.YYYY"),
        company: getCompanyInstance(row?.company)?.name || "-",
      
        actions: (
          <div className="d-flex justify-content-end">

            {/* <Button className="btn-sm ml-3" color="secondary" disabled={isAdmin === false} onClick={() => openModalEdit(row?.id)}>{t("Muokkaa")}</Button> */}
          </div>
        ),
      }
    })
  }, [portfolios])
  
  // Modal: New/Edit portfolio
  
  const [modalEditOpen, setModalEditOpen] = useState(false)
  const [modalEditId, setModalEditId] = useState()
  
  const toggleModalEdit = () => {
    setModalEditOpen(s => !s);
  }
  
  const openModalEdit = (id) => {
    setModalEditOpen(true)
    setModalEditId(id);
  }
  
  const modalEditResolve = () => {
    setModalEditOpen(false);
    setModalEditId(null);
    getPortfolios()
  }
  
  const modalEditReject = () => {
    setModalEditOpen(false);
    setModalEditId(null);
  }

    // Modal: open/edit Subscription
  
    const [modalSubscriptionEditOpen, setModalSubscriptionEditOpen] = useState(false)
    const [modalSubscriptionEditId, setModalSubscriptionEditId] = useState()
    
    const toggleModalSubscriptionEdit = () => {
      setModalSubscriptionEditOpen(s => !s);
    }


    
    const openModalSubscriptionEdit = (id) => {
      setModalSubscriptionEditOpen(true)
      setModalSubscriptionEditId(id);
    }
    
    const modalSubscriptionEditResolve = () => {
      setModalSubscriptionEditOpen(false);
      setModalSubscriptionEditId(null);
    }
    
    const modalSubscriptionEditReject = () => {
      setModalSubscriptionEditOpen(false);
      setModalSubscriptionEditId(null);
    }


          //---------------------------- Update subscription BEGIN---------------------------------

  const openUpdateUp = useCallback(() => {
    return (<>{ <UpdateUp redirectToFrontpage={false} forceOpen={true}/>}</>)
  }, [])

  // Update subscription END
  
  return (
    <>

        <PageHeader title={t("accoutns_list_title","Asiakkaat")} />
        <BreadcrumbsDynamic links={getCrumbLinks()} />
        {modalSubscriptionEditOpen && <UpdateUp redirectToFrontpage={false} forceOpen={true} closeParent={() => setModalSubscriptionEditOpen(false)}/>}
      <PageContainer>
      
        <div className="d-flex justify-content-between mb-2">
          <div className="p-2">

            {/* <Button color="secondary" disabled={isAdmin === false} onClick={() => openModalEdit(null)}>{t("accounts_list_create","Uusi asiakas")}</Button> */}
          </div>
          
          <div className="p-2">
            
          </div>
        </div>

        
        <TableAuto
          showRowNumber={false}
          showId={true}
          checkboxes={false}
          headers={headers}
          headerColor={"light"}
          rows={getTableRows()}
          loading={loading}
          pagination={true}
          paginationPrevious={paginationPrevious}
          paginationNext={paginationNext}
          paginationOffset={filters?.offset}
          paginationLimit={filters?.limit}
          paginationCount={count}
          setFilters={setFilters}
          name="accounts_table"
        />
        
        {/* <Modal
          title={t("portfolios_list_portfolio_title","Salkku")}
          isOpen={modalEditOpen}
          toggleModal={toggleModalEdit}
        >
          <PortfolioEdit
            objectId={modalEditId}
            handleResolve={modalEditResolve}
            handleReject={modalEditReject}
          />
        </Modal> */}

        {/* <Modal
          isOpen={modalSubscriptionEditOpen}
          toggleModal={toggleModalSubscriptionEdit}
          size="modal-xl"
          showHeader={false}
          classNameBody="p-0 padding-0"
        >
          <SubscriptionOptionsInfo
            objectId={modalSubscriptionEditId}
            handleResolve={modalSubscriptionEditResolve}
            handleReject={modalSubscriptionEditReject}
            custom_info_text= {t("subscription_portfolios_info_text", "Salkkujen ensimmäismäärä tilauksellanne on täyttynyt")}
          />
        </Modal> */}
      </PageContainer>
    </>
  );
}

export default PortfoliosList;
