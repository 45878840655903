import React, { useEffect, useState, useCallback } from "react";
import moment from "moment";
import { useTranslation } from 'react-i18next';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useAuthContext } from "contexts/AuthContext"

// Stories
import { Row, Col } from "stories/layout";
import { Input } from "stories/forms";
import { Card, Typography, Button, Link, IconButton, Modal, Spinner } from "stories/components";
import { PageHeader, PageContainer } from "components/Page"
import { TableAuto } from "stories/tables"

// App
import StockMarketSelect from "components/Forms/StockMarketSelect/StockMarketSelect"
import StockSelect from "components/Forms/StockSelect/StockSelect"
import StockPrivateSelect from "components/Forms/StockPrivateSelect/StockPrivateSelect"
import { httpGetSplits } from "services/splits"
import { httpGetPrivateInvestimentFundDatas } from "services/private_investment_funds"

import { getDate } from "services/datetime"
import PrivateInvestmentFundSplitEdit from "views/app/PrivateInvestmentFundSplitEdit/PrivateInvestmentFundSplitEdit"
import { round } from "services/helpers"

const PrivateInvestmentFundSplitsList = ({filters, setFilters,getSplitsParent, setObjectsToChart, setAddSplit}) => {
  
  const { t } = useTranslation();

  const { accountUserType } = useAuthContext()

  const [loading, setLoading] = useState(false)
  const [objects, setObjects] = useState()
  const [count, setCount] = useState()
  
  const getSplits = () => {
    let params = {
      ...filters,
      ordering: "-date_effective",
      related_model_type: 30,
      related_model_id: filters?.private_investment_fund
    }
    if(params?.related_model_id != null || params?.related_model_id != "" ) {

        setLoading(true)
        httpGetSplits(params).then(res => {
        setObjects(res?.data?.results)
        setCount(res?.data?.count)
        setLoading(false)
        })
    }
  }
  const getRates = () => {
    let params = {
      ...filters,
    }
    if (!filters?.private_investment_fund || filters?.private_investment_fund == "") {
      return;
    }
    
    httpGetPrivateInvestimentFundDatas(params).then(res => {
      setObjectsToChart(res?.data?.results)
      setCount(res?.data?.count)
    })
  }

  
  useEffect(() => {
    getSplits()
  }, [filters])
  

  
  const headers = [
    { label: t("splits_list_name", "Name"), key: "name"},
    { label: t("splits_list_date_effective", "Päivä"), key: "date_effective"},
    { label: t("splits_list_dividend", "Split suhdeluku"), key: "dividend"},
    { label: t("splits_list_actions", " "), key: "actions"},

  ];
  
  const paginationPrevious = () => {
    let offset = filters?.offset - filters?.limit;
    if (offset < 0) {
      offset = 0;
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  const paginationNext = () => {
    let offset = filters?.offset + filters?.limit;
    if (offset > filters?.count) {
      return
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  const getTableRows = useCallback(() => {
    const name = filters?.private_investment_fund_name;
    return objects && objects.map((row, index) => {
      return {
        ...row,
        name: name,
        date_effective: moment(row.date_effective).format('DD.MM.YYYY'),
        
        price: round(row?.price,2),
        actions: (
          <div className="d-flex justify-content-end">
            <Button className="btn-sm" color="secondary" disabled={accountUserType==="read_only"} onClick={() => openModalEdit(row?.id)}>{t("Muokkaa")}</Button>
          </div>
        ),
      }
    })
  }, [objects])
  
  // Modal: New/Edit
  
  const [modalEditOpen, setModalEditOpen] = useState(false)
  const [modalEditId, setModalEditId] = useState()
  
  const toggleModalEdit = () => {
    setModalEditOpen(s => !s);
  }
  
  const openModalEdit = (id) => {
    setModalEditOpen(true)
    setModalEditId(id);
  }
  
  const modalEditResolve = () => {
    setModalEditOpen(false);
    setModalEditId(null);
    getSplits()
    getSplitsParent()
    getRates()
  }
  
  const modalEditReject = () => {
    setModalEditOpen(false);
    setModalEditId(null);
  }



  useEffect(() => {
    setAddSplit(      <div className=" d-flex justify-content-end align-bottom">
    <Button color="secondary" onClick={() => openModalEdit(null)}>{t("Uusi split")}</Button>
  </div>)

  return () => {
    setAddSplit(null)
  }; 

  }, [])

  
  return (
    <>
    
      
      <TableAuto
        showRowNumber={true}
        showId={true}
        checkboxes={false}
        headers={headers}
        headerColor={"light"}
        rows={getTableRows()}
        loading={loading}
        pagination={true}
        paginationPrevious={paginationPrevious}
        paginationNext={paginationNext}
        paginationOffset={filters?.offset}
        paginationLimit={filters?.limit}
        paginationCount={count}
        setFilters={setFilters}
      />
      
      <Modal
          title={t("Split")}
          isOpen={modalEditOpen}
          toggleModal={toggleModalEdit}
        >
        <PrivateInvestmentFundSplitEdit
          privateInvestmentFund={filters?.private_investment_fund}
          privateInvestmentFundName={filters?.private_investment_fund_name}
          objectId={modalEditId}
          handleResolve={modalEditResolve}
          handleReject={modalEditReject}
        />
      </Modal>

    </>
  );
}

export default PrivateInvestmentFundSplitsList;
