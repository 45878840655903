import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import { Input } from "stories/forms";
import { useStoreState, useStoreActions } from 'easy-peasy';


const AccountSelect = ({ label, name="accounts", onChange,chose=false, value, ...rest }) => {
  const { t } = useTranslation();
  const accounts = useStoreState((state) => state.account?.accounts);


  
  const getInputOptions = useCallback(() => {
    return accounts && accounts.map(obj => {
      return (
        <option value={obj.id}>{obj.name}</option>
      )
    })
  }, [accounts])



  
  return (
    <Input  label={label || label === "" ? label : t("company_select_label", "Yritys")} type="select" name={name} onChange={onChange} value={value} {...rest}>
      {chose && <option value="" label={t("Valitse")}></option>}
      {getInputOptions()}
    </Input>
  )
  
  
}

export default AccountSelect;