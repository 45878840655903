import React from 'react';
import PropTypes from 'prop-types';
import { Col as BootstrapCol } from "reactstrap";
import './Col.css';

export const Col = ({ children, ...rest }) => {
  
  return (
    <BootstrapCol
      {...rest}
    >
    {children}
    </BootstrapCol>
  )
};

Col.propTypes = {
  
};


Col.defaultProps = {
  
};

