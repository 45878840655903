import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import { Input } from "stories/forms";
import { useAppContext }from "contexts/AppContext"
import { useStoreState, useStoreActions } from 'easy-peasy';






const UserTypeSelect = ({ label, name="currency", onChange, value, setFieldValue,validateForm, ...rest }) => {
  const { t } = useTranslation();
  const { enums } = useAppContext()

  const userTypes = enums?.accounts?.account_user?.user_type
  const portfolios = useStoreState((state) => state.portfolio?.portfolios);

  
  useEffect(() => {
    if (value === undefined || value === "") {
        setFieldValue("user_type", userTypes?.[0].key)
        }
    }, [enums])

  //check if value changed and value are "admin" then setFieldValue to "admin"
  useEffect(() => {
    if (value === "admin") {
      setFieldValue("account_user_portfolios", [...portfolios.map((portfolio) => {return {id: portfolio.id, value: portfolio.name}})])
      validateForm()
    }
  }, [value])


  const getInputOptions = useCallback(() => {
    return userTypes && userTypes.map(obj => {
      return (
        <option value={obj.key}>{obj.value}</option>
      )
    })
  }, [enums])
  
  return (
    <Input 
      label={label ? label : t("currency_select_label", "Tyyppi")} 
      type="select" 
      name={name} 
      onChange={onChange} 
      value={value} 
      {...rest}
    >
      {/*<option value=""></option>*/}
      {getInputOptions()}
    </Input>
  )
  
  
}

export default UserTypeSelect;