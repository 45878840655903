import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import { Input } from "stories/forms";
import { useAppContext } from "contexts/AppContext"
import { useStoreState, useStoreActions } from 'easy-peasy';
import { httpGetOptions } from 'services/options'



const OptionSecuritySelect = ({label, name="option_security",payload,asset_type, onChange, value, allOption = true}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    httpGetOptions(payload).then(res => {
        console.log("getOptions:res",res);
        //loop and get only unique values based on asset_name
        let uniqueOptions = [];
        let unique = {};
        res?.data?.forEach(obj => {
          
          if(!unique[obj.asset_name]){
            unique[obj.asset_name] = true;
            uniqueOptions.push(obj);
          }
        })
        setOptions(uniqueOptions);
      }, error => {
        console.log("login:error",error);
      }).finally(() => {
      })
  }, [payload])
  
  const getInputOptions = useCallback(() => {

    return options && options.map(obj => {
      return (
        <option value={obj.asset_name}>{obj.asset_name}</option>
      )
    })
  }, [options])
  
  return (
    <Input label={label ? label : t("options_security_label", "Arvopaperi")} type="select" name={name} onChange={onChange} value={value}>
      { allOption && (
        <option value="">{t("Kaikki")}</option>
      )}
      {getInputOptions()}
    </Input>
  )
  
  
}


export default OptionSecuritySelect;