import http from './api'

export const httpGetPrivateStocks = (params) => {
  console.log("httpGetPrivateStocks");
  return http({
    url: `/private_stocks/private_stocks/` ,
    method: 'GET',
    params,
  })
}

export const httpGetPrivateStock = (id) => {
  console.log("httpGetPrivateStock");
  return http({
    url: `/private_stocks/private_stocks/${id}/` ,
    method: 'GET',
  })
}

export const httpCreatePrivateStock = (data) => {
  console.log("httpCreatePrivateStock");
  return http({
    url: `/private_stocks/private_stocks/` ,
    method: 'POST',
    data
  })
}

export const httpUpdatePrivateStock = (id, data) => {
  console.log("httpUpdatePrivateStock");
  return http({
    url: `/private_stocks/private_stocks/${id}/` ,
    method: 'PATCH',
    data
  })
}

export const httpDeletePrivateStock = (id) => {
  console.log("httpDeletePrivateStock");
  return http({
    url: `/private_stocks/private_stocks/${id}/` ,
    method: 'DELETE',
  })
}


// Rates

export const httpGetPrivateStockDatas = (params) => {
  console.log("httpGetPrivateStockDatas");
  return http({
    url: `/private_stocks/private_stock_datas/` ,
    method: 'GET',
    params,
  })
}

export const httpGetPrivateStockData = (id) => {
  console.log("httpGetPrivateStockData");
  return http({
    url: `/private_stocks/private_stock_datas/${id}/` ,
    method: 'GET',
  })
}

export const httpCreatePrivateStockData = (data) => {
  console.log("httpCreatePrivateStockData");
  return http({
    url: `/private_stocks/private_stock_datas/` ,
    method: 'POST',
    data,
  })
}

export const httpUpdatePrivateStockData = (id, data) => {
  console.log("httpUpdatePrivateStockData");
  return http({
    url: `/private_stocks/private_stock_datas/${id}/` ,
    method: 'PATCH',
    data
  })
}

export const httpDeletePrivateStockData = (id) => {
  console.log("httpDeletePrivateStockData");
  return http({
    url: `/private_stocks/private_stock_datas/${id}/` ,
    method: 'DELETE',
  })
}
