import http from './api'

const url = '/companies'

export const httpGetCompanies = (params) => {
  return http({
    url: `${url}/` ,
    method: 'GET',
    params
  })
}

export const httpGetCompany = (id) => {
  return http({
    url: `${url}/${id}/` ,
    method: 'GET',
  })
}

export const httpCreateCompany  = (data) => {
  return http({
    url: `${url}/` ,
    method: 'POST',
    data
  })
}

export const httpUpdateCompany  = (id, data) => {
  return http({
    url: `${url}/${id}/` ,
    method: 'PATCH',
    data
  })
}

export const httpDeleteCompany  = (id) => {
  return http({
    url: `${url}/${id}/` ,
    method: 'DELETE',
  })
}