import React from "react";
import { Container} from "stories/layout";

const PageContainer = ({ children, className, bgColor="bg-white" }) => {
  
  return (
    <div fluid className={`page_container ${bgColor} p-2 ${className}`}>
    {children}
    </div>
  )
}

export default PageContainer;