import React, { useState, useCallback, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { Button, Typography} from "stories/components";
import { Container, Row, Col, FormContainer, Card, CardBody } from "stories/layout";
import { Input, SelectMulti, Toggle, Radio, DatePicker, Checkbox } from "stories/forms";

import { useNotification } from "stories/components/Notification"

import { httpGetAllSecurities, httpUpdateAccountingPrice } from "services/private_investment_funds"
import { set } from 'date-fns';


const AccountingPriceUpdate = (props) => {
    const { t } = useTranslation();
    const { notify } = useNotification();

    const {
        isSubmitting,
        handleReject,
        related_model_id,
        handleResolve,
      } = props;

      const getLastDayOfPreviousMonth = () => {
        const now = new Date();
        return new Date(now.getFullYear(), now.getMonth(), 0);
    }
    
    const [values, setValues] = useState({date: getLastDayOfPreviousMonth(), update_all: false})
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState({})
    const [response, setResponse] = useState(null)


    const setFieldValue = (key, value) => {
        setValues({...values, [key]: value})
        setErrors({...values, [key]: null})
    }
    
    const updateAccountingPrice = () => {
        const oldDate = values.date;
        const newDate = new Date(oldDate.getFullYear(), oldDate.getMonth(), oldDate.getDate(), 12, 0, 0);
        const formattedDate = newDate.toISOString().split('T')[0] 
        let params = {
            accounting_date__lte: formattedDate,
            id: related_model_id,
            update_all: values.update_all
        }
        setLoading(true)
        
        httpUpdateAccountingPrice(null,params).then(response => {

            setLoading(false)
            setResponse(response.data)
            if(response.data?.count_updated != 0 && response.data?.count_updated == response.data?.security_count){
                handleResolve()
            }
            if (  response?.data?.count_updated != response?.data?.security_count){
                const message = `${t("accounting_price_update_security_sum",'Arvopapereita yht. ')}${response?.data?.security_count}${t("accounting_price_update_pcs",'kpl joista ')}${response?.data?.count_updated}${t("accounting_price_update_updated",'kpl päivitettiin kirjanpitoarvo')}`
                notify({ title:t("accounting_price_update_accounting_updated","Kirjanpitoarvojen päivitys"), message:message})
                // response?.count_updated != response?.security_count
            }

        }).catch(error => {
            setLoading(false)
            setErrors({...errors, "general": t(error.data)})
            console.log("error", error.data)
        })
    }



    return (
        <div>
        {/* <h1>{t('Accounting Price Update')}</h1> */}
        {/* {JSON.stringify(values)}
{     JSON.stringify(errors)} */}
        <Row>
            <Col className="mb-1">
            <DatePicker
                name="date"
                label={t("accounting_price_udpate_date", "Viimeisin kurssi voimassa (saakka)")}
                className="form-control"
                selected={values?.date || getLastDayOfPreviousMonth}
                // put default date last mont last day

                onChange={(date) => setFieldValue("date", date)}
                showYearDropdown
                dateFormat="d.M.yyyy"
                value={values?.date || getLastDayOfPreviousMonth}
            />
            </Col>
        </Row>
        <Row className="mb-4">
                  <Col  className="my-3">
                  <div style={{position: 'absolute', zIndex: "0 !important"}}>
                  <Checkbox 
                  id="WAC"
                  name="update_all"
                  checked={values?.update_all}
                  onChange={(e) => {setFieldValue("update_all",e.target.checked)}}
                  value="agree_terms"
                  required
                //   errorText={touched["agree_terms"] ? errors["agree_terms"] : ""}
                  label={<>{t("accounting_price_update_all_at_ones",'Päivitä kaikkiin arvopapereihin keralla')}</>}
                  labelFirst={true}
                  className="zIndexZero"
                  />
                  </div>
      
                  </Col>
        </Row>


        <Row>
            <Col className="mb-3">
                <Button onClick={handleReject} variant="default">{t("Close","Sulje")}</Button>
            </Col>

            <Col className="mb-3 text-right">

                <Button variant="secondary" disabled={isSubmitting} onClick={() => updateAccountingPrice()} >
                {t("Save","Tallenna")}
                </Button>
            
                { Boolean(errors["general"]) && <Typography className="text-danger">{errors["general"]}</Typography> }
                { response?.count_updated == 0 && <Typography className="text-danger">{t("accounting_price_update_no_price_instances_text","Yhtään mahdollista kurssia ei löytynyt, luo ensin kurssikirjaukset")}</Typography> }
                {/* { response?.count_updated != response?.security_count  && <Typography className="text-secondary mr-2">{t('Arvopapereita yht.')}{response?.security_count}{t(' joista ')}{response?.count_updated}{t(' päivitettiin kirjanpitoarvo')}</Typography> } */}
            </Col>
        </Row>
        </div>
    );
    }

export default AccountingPriceUpdate;