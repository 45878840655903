import { action, thunk, persist } from 'easy-peasy';
import { httpGetNewFeatures } from "services/new_features"

const model = {
  loading: false,
  setLoading: action((state, payload) => {
    state.loading = payload
  }),
  newFeatures: [],
  setNewFeatures: action((state, payload) => {
    state.newFeatures = payload
  }),
  count: 0,
  setCount: action((state, payload) => {
    state.count = payload
  }),
  getNewFeatures: thunk(async (actions, payload) => {
    actions.setLoading(true)
    httpGetNewFeatures().then(response => {
      if (response?.data) {
        actions.setNewFeatures(response?.data)
        actions.setCount(response?.data.length) 
      }
    }, error => {
      console.log(error)
    }).finally(() => {
      actions.setLoading(false)
    })
    
  }),
};

export default model;