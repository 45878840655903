import React, {useState, useEffect, useCallback} from "react";
import { useTranslation } from 'react-i18next';
// react library for routing
import { useLocation, NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library that concatenates classes
import classnames from "classnames";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
// react library that creates nice scrollbar on windows devices
import PerfectScrollbar from "react-perfect-scrollbar";

import { useStoreState, useStoreActions, useStoreRehydrated  } from 'easy-peasy';
import { Badge, Typography, Button } from "stories/components";

import { useAuthContext } from "contexts/AuthContext"

import PortfolioSelectStore from "components/Forms/PortfolioSelect/PortfolioSelectStore"

import Role from "components/Role/Role"
import { httpGetVersion } from "services/version"

import QuickActions from "views/app/QuickActions/QuickActions"
import { IconButton, Modal } from "stories/components";

//import css file for sidebar this same folder
import "./Sidebar.css"

// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
} from "reactstrap";
import { is } from "date-fns/locale";

function Sidebar({ routes, logo, myAccount, alwaysOpen, setAlwaysOpen, isOpen, setIsOpen,style }) {
  
  const { t } = useTranslation();
  const [version, setVersion] = React.useState(null);
  const { logout } = useAuthContext()
  const [state, setState] = React.useState({});
  const location = useLocation();
  const { myUser, getMyUser, loading, accountUserType } = useAuthContext();
  const myUserLoading = loading
  const newFeatures = useStoreState((state) => state.newFeatures?.newFeatures);
  const newFeaturesLoading = useStoreState((state) => state.newFeatures?.loading);
  const portfolios = useStoreState((state) => state.portfolio?.portfolios);
  const portfolio = useStoreState((state) => state.portfolio?.portfolio);
  const setPortfolio = useStoreActions((actions) => actions.portfolio.setPortfolio);

  // const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    const default_portfolio = myUser?.active_portfolio
    //if default portfolio is in portfolios set it
    if (default_portfolio && portfolios.some(portfolio => portfolio.id === default_portfolio)) {
      setPortfolio(portfolios.find(portfolio => portfolio.id === default_portfolio))
    }
  }, [portfolios, myUser])


  
  // Mobile menu
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // You can adjust the width threshold as needed
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const portfolioID = localStorage.getItem("portfolio")
  
  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= 768); // Update the state when the window is resized
    }

    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  const handleToggleMobileMenu = () => {
    setMobileMenuOpen(s => !s);
  }
  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };
  
  useEffect(() => {
    if (mobileMenuOpen) {
      setAlwaysOpen(true)
    }
    else {
      setAlwaysOpen(false)
    }
    
  }, [mobileMenuOpen])
  
  // END mobile menu

  React.useEffect(() => {
    setState(getCollapseStates(routes));
    httpGetVersion().then((response) => {
      setVersion(response.data.version)
    })
    

    // eslint-disable-next-line
  }, []);
  
  React.useEffect(() => {
    console.log("navState", state)
  }, [state]);
  
  
  const handleLogout = () => {
    logout();
  }
  
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {

    //split routeName and location.pathname by "/"
    const routeNameArray = routeName.split("/");
    const locationArray = location.pathname.split("/");

    //if routeName are a subroute of location.pathname
    return routeNameArray.every((item, index) => item === locationArray[index])
      ? "active"
      : "";

  };
  
  // this creates the intial state of this component based on the collapse routes
  // that it gets through routes
  const getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: getCollapseInitialState(prop.views),
          ...getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.js - route /admin/regular-forms
  const getCollapseInitialState = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (location.pathname.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  };
  
  const isUnreadedFeatures = (newFeatures) => {
    //map all newFeatures and check if any of them is unreaded
    if (newFeatures?.new_features){

      const unreadedFeatures = newFeatures?.new_features.map((feature) => {
        //if feature is created after myUser.new_features_readed return true
        if ((new Date(feature.created) > new Date(myUser.new_features_readed)) || myUser.new_features_readed === null) {
          return true
        }
        //if feature is updated after myUser.new_features_readed return true
        else if ((new Date(feature.updated) > new Date(myUser.new_features_readed)) || myUser.new_features_readed === null) {
          return true
        }
        return false
      })
      return unreadedFeatures.includes(true)
    } 
  }

  const get_portfolio_select = useCallback(() => {
    return (
      <>
      <PortfolioSelectStore useDefault={true}  />
      </>
      )
  }, [myUser?.active_portfolio, loading])


  // this function creates the links and collapses that appear in the sidebar (left menu)
  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      if(prop.invisible) return null;

      //check if user has access to feature by license
      if (prop?.feature && !myAccount?.license?.features.some(feature => feature.name === prop?.feature)) {
        return null;
      }

      if (prop?.staff && myUser?.is_staff === false && prop?.staff == true) {
        return null;
      }

      if (prop?.limit == "portfolio_summary" && portfolios?.length === 0){
        return null;
      }  

      if (prop?.path == "/accounts" && myUser?.multi_account_user === false){
        return null;
      }
      //loop all prop.exlude_lisenses and check if myAccount.license.name is in it
      if (prop?.exclude_licenses && prop?.exclude_licenses.includes(myAccount?.license?.name)) {
        return null;
      }



      if (prop.redirect) {
        return null;
      }
      if (prop.hr)
      {
        return (
          <hr className="my-3" />
        )
      }
      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !state[prop.state];
        
        return (
          <NavItem key={key} className="sk_navitem_collapse">
            { isMobile ? null : (
             <NavLink className="sk_navitem_collapse_navlink" onClick={(e) => {
              e.preventDefault();
              if (alwaysOpen === false) {
                setState(st);
              }
              else {
                // do nothing
              }
            }}>
            
            <div className="d-flex justify-content-between">
              <div>
                {prop.icon ? (
                  <span className="sk_collapse_icon">
                    <i className={`sk_icon ${prop.icon}`} />&nbsp;
                  </span>
                ) : null }
                <span className="nav-link-text">{prop.name}</span>
              </div>
              <div>
                { alwaysOpen === true || state[prop.state] ? (
                  <i className="fas fa-chevron-down"></i>
                ) : (
                  <i className="fas fa-chevron-right"></i>
                )}
              </div>
            </div>
          </NavLink>
            )}
            <Collapse isOpen={alwaysOpen === true || state[prop.state]}>
              <Nav className="sk_nav_collpase_children nav-sm flex-column">
                {createLinks(prop.views)}
              </Nav>
            </Collapse>
          </NavItem>
        );
      }
      return (
        <NavItem 
          className={`
            sk_navitem
            ${activeRoute(prop.layout + prop.path)}
          `} 
          key={key}
        >
          <NavLink
            to={prop.layout + prop.path}
            activeClassName=""
            tag={NavLinkRRD}
            onClick={closeMobileMenu}
          >
            <>
            {prop.icon !== undefined ? (
              <>
                <i className={`sk_icon ${prop.icon}`} />
                <span className="nav-link-text">{prop.name}</span>
                
              </>
            ) : prop.miniName !== undefined ? (
              <>
                {/* <span className="sidenav-mini-icon"> {prop.miniName} </span> */}
                <span className="sidenav-normal"> {prop.name} </span>
              </>
            ) : (
              prop.name
            )}
            {prop.name === t("Uutta") && isUnreadedFeatures(newFeatures) && <i className="fas fa-exclamation-circle " style={{color: "red", marginLeft:"auto", minWidth: "0.5rem"}}  />}

            </>
          </NavLink>
        </NavItem>
      );
    });
  };

  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    };
  }

  const handleOpen = () => {
    setIsOpen(!isOpen)
    // setAlwaysOpen(!alwaysOpen)
  }

  const scrollBarInner = (
    <div className="scrollbar-inner">
        {/* <IconButton color="secondary" size="lg" iconName={isOpen ? "times" : "fa fa-bars"}  /> */}
        {/* <div className="mt-2 ml-2">
        <Button onClick={handleOpen} rounded size="sm"><i className={isOpen ? "fas fa-times" : "fa fa-bars"}></i></Button>
        </div> */}
                            <Button color="secondary" onClick={() => setIsOpen(false)} rounded size="sm"
                className=""
                style={{ 
                  position: 'absolute',
                  left: '3px',
                  top: '3px',
                  borderTopRightRadius: '50%', 
                  borderBottomRightRadius: '50%', 
                  borderTopLeftRadius: '50%', 
                  borderBottomLeftRadius: '50%',
                  shadow: '0px 0px 5px 0px rgba(0,0,0,0) !important',
                }}>
                  <i className={isOpen ? "fas fa-times fa-xl" : "fa fa-bars fa-xl"}></i>
              </Button>
      {isOpen && 
      <div className="sidenav-header d-flex align-items-center mt-2">
        {logo ? (
          <NavbarBrand {...navbarBrandProps}>
            <img
              alt={logo.imgAlt}
              // className="navbar-brand-img"
              src={logo.imgSrc}
              />

                  
          </NavbarBrand>
        ) : null}
        
      </div>
      }

      <div className="navbar-inner">
        <Collapse navbar isOpen={true}>
          <Nav navbar>
            {isOpen && 
            <NavItem>
              <div className="pl-4 pr-4">
                {
                  myAccount.type==="business" ? (
                    <>

                      <Role />
                      {/* <PortfolioSelectStore /> */}
                      {/* {JSON.stringify(portfolio)} */}
                    </>

                  ) : (
                    <>
                    <Typography variant="h3" className="text-dark mb-3">{t("side-bar-role-free","Valitse salkku")}</Typography>
                      {get_portfolio_select()}
                    </>
                  )
                }
              </div>
            </NavItem>
                }
            {isOpen && 
            <>
            {
              //if accountUserType is in "basic" or "admin" show "Uutta" link
              (accountUserType === "basic" || accountUserType === "admin") && (
                
                <NavItem>
              <div className="p-2">
                <QuickActions />
              </div>
            </NavItem>
              )
            }
            </>
            }
            {createLinks(routes)}
            <NavItem>
              <NavLink
                className="nav-link"
                activeClassName=""
                onClick={handleLogout}
              >
                <>
                  <i className="ni ni-button-power text-primary" />
                  <span className="nav-link-text">{t("sidebar_logout", "Kirjaudu ulos")}</span>
                </>
              </NavLink>
            </NavItem>
            <NavItem >
            <NavLink
                className="nav-link"
                activeClassName=""
              >
                <>
                  <i className="" />
                  <span className="nav-link-text text-small p-0"><Typography className="text-small">{t("web_app_version","Sovellusversio")} {version}</Typography></span>
                </>
              </NavLink>

            </NavItem>
          </Nav>
          
        </Collapse>
      </div>
    </div>
  );
  {/*
  return(
    <>
    <div style={{ position: 'relative', zIndex: 100000 }}>
    <div className="text-success" style={{ position: 'fixed', top: 20, left: 23 }} onClick={() => setMobileOpen(!mobileOpen)} >
    dd
    {mobileOpen && (
      <>
      open mobile
      </>
    )}
    </div>
    </div>

    </>
  )
    */}
    
  if (isMobile) {
    return (
      <div className="sk_mobile_menu_parent bg-secondary">
        <div className="d-flex justify-content-between">
          <div className="p-2 sk_mobile_logo">
            <img
              alt={logo.imgAlt}
              className="navbar-brand-img"
              src={logo.imgSrc}
            />
          </div>
          <div className="p-2 sk_mobile_hamburger">
            <Button onClick={handleToggleMobileMenu}><i className="fa fa-bars"></i></Button>
          </div>
        </div>
        
        { mobileMenuOpen ? (
        <Navbar 
          className="sk_mobile_menu"
        >
        {createLinks(routes)}
        </Navbar>
        ) : null }

      </div>
    )
  }
  else {
    
    return (
      <div  >

      <Navbar
        className={`
        sk_desktop_menu
        sidenav
        navbar-vertical
        navbar-expand-xs
        bg-secondary 
        fixed-left
        navbar-fixed
        ${isOpen ? 'sidebar-open' : 'sidebar-closed'}
        `}
        // style={isOpen
        //   ? { transform: 'translateX(0) ', transition: 'transform 0.3s ease' }
        //   : { transform: 'translateX(-100%) ', transition: 'transform 0.3s ease' }}
        style={style}
        >
      <>
        {navigator.platform.indexOf("Win") > -1 ? (
          <PerfectScrollbar>{scrollBarInner}</PerfectScrollbar>
        ) : (
          scrollBarInner
        )}

      </>
      </Navbar>
          </div>
    );
  }
}

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  // logo
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
  style: PropTypes.object,
};

export default Sidebar;
