import React from "react";
// react library for routing
import { Link } from "react-router-dom";
// reactstrap components
import {
  NavbarBrand,
  Navbar,
} from "reactstrap";

import {
  Container,
} from "stories/layout"

function AdminNavbar() {
  return (
    <>
      <Navbar
        className="navbar-horizontal navbar-main navbar-dark navbar-transparent"
        expand="lg"
        id="navbar-main"
      >
        <Container>
          <NavbarBrand to="/" tag={Link} className="mx-auto">
            {/*
            <img
              alt="..."
              src={require("assets/images/logo.png").default}
            />
            */}
          </NavbarBrand>
          
        </Container>
      </Navbar>
    </>
  );
}

export default AdminNavbar;
