import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import { Input } from "stories/forms";
import { useAppContext }from "contexts/AppContext"

const CurrencySelect = ({ label, name="currency", onChange, value, ...rest }) => {
  const { t } = useTranslation();
  const { enums } = useAppContext()
  
  const getInputOptions = useCallback(() => {
    const split_scope = enums?.splits?.stock_split?.split_scope
    return split_scope && split_scope.map(obj => {
      return (
        <option value={obj.key}>{t(`SplitScopeValue_${obj.key}`, obj.value)}</option>
      )
    })
  }, [enums])
  
  return (
    <Input  label={label || label === "" ? label : t("split_scope_select_label", "Splitin vaikutus")} type="select" name={name} onChange={onChange} value={value} {...rest}>
      {/*<option value=""></option>*/}
      {getInputOptions()}
    </Input>
  )
  
  
}

export default CurrencySelect;
