import http from './api'

const url = '/dividends'

export const httpGetDividends = (params) => {
  return http({
    url: `${url}/` ,
    method: 'GET',
    params
  })
}

export const httpGetDividend = (id) => {
  return http({
    url: `${url}/${id}/` ,
    method: 'GET',
  })
}

export const httpCreateDividend = (data) => {
  return http({
    url: `${url}/` ,
    method: 'POST',
    data
  })
}

export const httpUpdateDividend = (id, data) => {
  return http({
    url: `${url}/${id}/` ,
    method: 'PATCH',
    data
  })
}

export const httpDeleteDividend = (id, params) => {
  return http({
    url: `${url}/${id}/` ,
    method: 'DELETE',
    params
  })
}