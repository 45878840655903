import React, { useState, useCallback, useEffect } from "react";
import moment from "moment";
import { generatePath } from "react-router";
import { Route, Switch, Redirect } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useStoreState, useStoreActions } from 'easy-peasy';


import { Row, Col } from "stories/layout";
import { Card, Typography, Button, Link, IconButton, BreadcrumbsDynamic } from "stories/components";
import { PageWrapper, PageHeader } from "components/Page"
import PageTabs from "components/PageTabs/PageTabs"
import { PageContainer } from "components/Page"

import UsersList from "views/dashboard/UsersList/UsersList"
import CompaniesList from "views/dashboard/CompaniesList/CompaniesList"

import MyUser from "views/dashboard/MyUser/MyUser"
import AccountSettings from "views/dashboard/AccountSettings/AccountSettings"
import Subscription from "views/dashboard/Subscription/Subscription"
import AccountLog from "views/dashboard/AccountLog/AccountLog"
import Receipts from "views/dashboard/Receipts/Receipts"
import { set } from "date-fns";

const Settings = ({match, location, history, props}) => {
  
  const { t } = useTranslation();
  const [crumbLinks, setCrumbLinks] = useState([])
  const account = useStoreState((state) => state.account?.account);


  //get crumb links from location state or return default links
  const getCrumbLinks =  () => {

    //if location state include crumbLinks return them
    if (location?.state && location?.state?.crumbLinks) {
      return location.state.crumbLinks
    }
    //else return default links
    return crumbLinks
  }
  

  const [tabs, setTabs] = useState([
  // {
  //   key: "default_settings",
  //   label: t("default_settings", "Perusasetukset"),
    
  // },
  {
    key: "user_account",
    label: t("user_account", "Oma tili"),
    
  },
  {
    key: "account_subscription",
    label: t("account_subscription", "Tilaus ja sopimus"),
    
  },
  {
    key: "account_users",
    label: t("account_users", "Käyttäjät"),
    
  },
  {
    key: "account_receipts",
    label: t("account_receipts", "Maksukuitit"),
    
  },

  {
    key: "account_companies",
    label: t("account_companies", "Yritykset"),
    
  },
  {
    key: "account_log",
    label: t("account_log", "Loki"),
    
  }
  ])

  useEffect(() => {
    console.log(account)
    if (account?.license?.name === "Lite") {
      //filter tabs key is not "account_companies"
      console.log("filter tabs")
      setTabs(tabs.filter(tab => tab.key !== "account_receipts"))
    }
  }, [account])


  
  useEffect(() => {
    if  (String(location?.pathname) === match.path){
      history.push(generatePath(match.path + "/:tab", {
        tab: tabs[0]?.key
      }))
    }
  }, [match])


  
  return (
    <>
      <PageHeader title={t("web_settings_title","Tili ja asetukset")}></PageHeader>
      <BreadcrumbsDynamic links={getCrumbLinks()} />

      <PageTabs match={match} location={location} tabs={tabs} />
      
      <PageContainer>
        <Switch>
        {/*

          <Route
            path={
              generatePath(match.path + "/:tab", {
                tab: "default_settings"
              })
             
            }
            
            render={props => <AccountSettings {...props} setCrumbLinks={setCrumbLinks} productId={match?.params?.productId} />}
          />
          */}

          <Route
          path={
            generatePath(match.path + "/:tab", {
              tab: "user_account"
            })
          }
          exact
          render={props => <MyUser {...props} setCrumbLinks={setCrumbLinks} productId={match?.params?.productId} />}
        />
        <Route
          path={
            generatePath(match.path + "/:tab", {
              tab: "account_subscription"
            })
          }
          exact
          render={props => <Subscription {...props} setCrumbLinks={setCrumbLinks} productId={match?.params?.productId} />}
        />
        <Route
          path={
            generatePath(match.path + "/:tab", {
              tab: "account_receipts"
            })
          }
          exact
          render={props => <Receipts {...props} setCrumbLinks={setCrumbLinks} productId={match?.params?.productId} />}
        />
        <Route
          path={
            generatePath(match.path + "/:tab", {
              tab: "account_users"
            })
          }
          exact
          render={props => <UsersList {...props} setCrumbLinks={setCrumbLinks} productId={match?.params?.productId} />}
        />

        <Route
          path={
            generatePath(match.path + "/:tab", {
              tab: "account_companies"
            })
          }
          exact
          render={props => <CompaniesList {...props} setCrumbLinks={setCrumbLinks} productId={match?.params?.productId} />}
        />
        <Route
          path={
            generatePath(match.path + "/:tab", {
              tab: "account_log"
            })
          }
          exact
          render={props => <AccountLog {...props} setCrumbLinks={setCrumbLinks} productId={match?.params?.productId} />}
        />
        </Switch>
      </PageContainer>
      
    </>
  );
}

export default Settings;
