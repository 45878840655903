import React, { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';

import { useStoreState, useStoreActions } from 'easy-peasy';


import { Input, SelectMulti, Toggle, Radio, DatePicker } from "stories/forms";

import { Button, Typography, Spinner, Link,  } from "stories/components";
import { useTranslation } from 'react-i18next';
import { set } from 'date-fns';
import {
    httpUploadFiles,
  } from "services/files"


const UploadFile = ({setFiles, files, uploadDirectly=false,setResData, updatePage, padding }) => {
  const account = useStoreState((state) => state.account?.account);
  const { t } = useTranslation();

    // const [files, setFiles] = useState([]);
    const [rejected, setRejected] = useState([])
    const [errorMessage, setErrorMessage] = useState("")

    const onDrop = useCallback((acceptedFiles, rejectedFiles ) => {
        if (acceptedFiles?.length) {
            setFiles(previousFiles => [
              ...previousFiles,
              ...acceptedFiles.map(file =>
                Object.assign(file, { preview: URL.createObjectURL(file) })
              )
            ])
            if (uploadDirectly){
                handleFileUpload(acceptedFiles, account)
            }
            setRejected([])
          }
        if (rejectedFiles?.length) {
            setRejected(rejectedFiles)
        }
    }, []);

    useEffect(() => {
        // Revoke the data uris to avoid memory leaks
        return () => files.forEach(file => URL.revokeObjectURL(file.preview))
      }, [files])



    const removeFile = file => {
        setFiles(currentFiles => currentFiles.filter(item => item !== file));
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: {
            'image/*': [],
            'application/pdf': [],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
            // '/vnd.ms-excel': [],s
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
            'text/*': [],

          },
        //   maxSize: 1024 * 1000,
        // set maxsize to 15mb
        maxSize: 1024 * 1024 * 15,
        //set maxsize to 15kt
        // maxSize: 1024 * 15,
          onDrop
    });

    const handleFileUpload = (acceptedFiles, account) => {
        if (!acceptedFiles?.length) return

        const formData = new FormData();
        acceptedFiles.forEach(file => formData.append('file', file))
        // formData.append('files', files);
        // formData.append('account',117);
        // formData.append('transactions', transactions)
      
        // formData.append('portfolio', portfolio.id);
        // setLoadingSendExcel(true)
        httpUploadFiles(formData).then(res => {
          if (res?.data){
            if(setResData){
                setResData(res?.data)
            }
            console.log(res?.data)
          }
          setFiles([])
          
          //check status is not 200
          if (res?.status !== 201) {
            setErrorMessage("Tiedoston lataus epäonnistui")
            // setErrorText("Tapahtumien tuonti epäonnistui")
          }
          else{
            setErrorMessage(null)
          }

        //   if(updatePage){
        //     updatePage()
        //   }
      
          // setLoadingSendExcel(false)
      
        }, error => {

          // setErrorText("Tapahtumien tuonti epäonnistui")
          if(error?.data?.message) {
            // setErrorMessage(t(error?.data?.message))
          }
          if(error) {
            //replace : with . to avoid rendering issues
          }
          setErrorMessage(t("rejected_files","Tiedosto ei kelpaa"))
      
        }).finally(() => {
          // setLoadingSendExcel(false)
      
          // setLoadingImport(false)
        })
      
      
    };

    return (
        <div>
            <div {...getRootProps()} style={{ border: '1.5px dashed gray', padding: padding,}}>
                <input {...getInputProps()} />
                {
                    isDragActive ?
                    <Typography>{t("drop_file","Pudota tiedosto tähän ...")}</Typography> :
                    <div className="d-flex align-items-center " >
                    <Typography className="mr-auto">{t("drag_file","Drag 'n' drop some files here, or click to select files")}</Typography>
                    <i className="fas fa-file-upload text-primary fa-2x" />
                    </div>
                    //show rejected reason
                }
            </div>
            <div className="d-flex">
                {rejected && rejected.length > 0 &&
                    <Typography variant="small" className="text-danger">{t("rejected_files","Tiedosto ei kelpaa")}:</Typography>
                }
                {errorMessage &&
                    <Typography variant="small" className="text-danger">{t("rejected_files","Tiedosto ei kelpaa")}:</Typography>
                }
                <Typography variant="small" style={{fontWeight:"normal"}} className={"pr-1 ml-auto"}> <span > docx, jpg, pdf, png, xlsx, (max. 15mt)</span></Typography>
            </div>
            {!uploadDirectly &&
            <ul className="pl-0 mb-0 mt-1">
                {files.map((file, index) => (
                    <li key={index} className="ml-0 mb-2" style={{listStyleType: 'none' }}>
                        <Input
                            name="index"
                            label=""
                            min={0}
                            addonTypeClass="append"
                            
                            inputText={
                                <Button className="text-white font-weight-light rounded-circle custom-xs-button-input  b-0"  variant="lighter" onClick={() => removeFile(file)} >
                                <i className="fas fa-times-circle text-danger bg-white fa-xl"  />
                                </Button>
                        
                            }
                            value={file.path}
                        
                            />
                          {/* <img
                            src={file.preview}
                            alt={file.name}
                            width={100}
                            height={100}
                            onLoad={() => {
                                URL.revokeObjectURL(file.preview)
                            }}
                            className='h-full w-full object-contain rounded-md'
                        /> */}
                        {/* <img src={file.preview} alt={file.path} className="img-fluid" style={{width:"200px", height:"200px"}}/>
                            {JSON.stringify(file)} */}
                        {/* {file.path} - {file.size} bytes */}
                        {/* <button onClick={() => removeFile(file)} style={{ marginLeft: '10px' }}>X</button> */}
                    </li>
                ))}
            </ul>
            }
            {/*<button onClick={handleUpload}>Upload Files</button>*/}
        </div>
    );
};

export default UploadFile;






// const FileUploadComponent = ({ apiUrl }) => {
//     const [files, setFiles] = useState([]);

//     const onDrop = useCallback(acceptedFiles => {
//         // Append files to the existing files array
//         setFiles(prevFiles => [...prevFiles, ...acceptedFiles]);
//     }, []);

//     const handleUpload = async () => {
//         const formData = new FormData();
//         files.forEach(file => {
//             formData.append('files', file);
//         });

//         try {
//             const response = await axios.post(apiUrl, formData, {
//                 headers: {
//                     'Content-Type': 'multipart/form-data'
//                 }
//             });
//             console.log('File uploaded successfully', response.data);
//         } catch (error) {
//             console.error('Error uploading file:', error);
//         }
//     };

//     const { getRootProps, getInputProps, isDragActive } = useDropzone({
//         onDrop,
//         accept: 'image/jpeg, image/png, application/pdf, application/vnd.ms-excel',
//         maxSize: 15000000 // 15 MB
//     });

//     return (
//         <div>
//             <div {...getRootProps()} style={{ border: '2px dashed gray', padding: 20, marginBottom: 20 }}>
//                 <input {...getInputProps()} />
//                 {
//                     isDragActive ?
//                     <p>Drop the files here ...</p> :
//                     <p>Drag 'n' drop some files here, or click to select files</p>
//                 }
//             </div>
//             <ul>
//                 {files.map(file => (
//                     <li key={file.path}>
//                         {file.path} - {file.size} bytes
//                     </li>
//                 ))}
//             </ul>
//             <button onClick={handleUpload}>Upload Files</button>
//         </div>
//     );
// };

// export default FileUploadComponent;
