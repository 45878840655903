import React, { useEffect, useState, useCallback } from "react";

import { Typography } from "stories/components";
import { round, thousandFormat } from "services/helpers"

const ValueColor = ({ value, children, ...rest }) => {
  
  const getColorClass = useCallback(() => {
    if (value >= 0) {
      return "text-success"
    }
    else {
      return "text-danger"
    }
  }, [value])
  
  return (
    <span
      className={getColorClass()}
      {...rest}
    >
    {value ? thousandFormat(value) : ""}
    </span>
  )
};

export default ValueColor

