import React from 'react';
import PropTypes from 'prop-types';
import { Button as BootstrapButton } from "reactstrap";
import { Badge } from "./Badge"
import './button.css';

export const Button = ({ variant, badgeColor, badgeValue, children,disabled, className, ...rest }) => {
  
  let classNameCommon = `${className ? className : ""} ${variant && variant === "link" ? "px-0 py-1" : ""} btn-bold`;
  
  return (
    <BootstrapButton
      className={`${classNameCommon}`}
      type="button"
      color={variant}
      disabled={disabled}
      {...rest}
    >
      {children}
      { badgeValue && (<Badge style={{marginLeft: 0, top: "-24px", right: "0px"}} color={badgeColor} type="floating">{badgeValue}</Badge>)}
    </BootstrapButton>
  );
};

Button.propTypes = {
  badgeColor: PropTypes.oneOf(['primary','secondary','default','info','success','danger','warning', 'light']),
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['sm', 'normal', 'lg']),
  variant: PropTypes.oneOf(['primary','secondary','default','info','success','danger','warning','link', 'light', 'lighter']),
};

Button.defaultProps = {
  badgeColor: "default",
  badgeValue: null,
  onClick: undefined,
  size: 'normal',
  variant: 'primary',
};
