console.log("ENV.REACT_APP_ENV", process?.env?.REACT_APP_ENV);

let language = ""
let isProduction = false;

let apiUrl = `http://localhost:8000/${language}api`;
let privateApiUrl = `http://localhost:8000/${language}private_api`;

if (process?.env?.REACT_APP_ENV === "prod") {
  apiUrl = `https://app.investime.fi/${language}api`;
  isProduction = true;
}
else if (process?.env?.REACT_APP_ENV === "virala") {
  apiUrl = `https://virala.investime.fi/${language}api`;
  isProduction = true;
}
else if (process?.env?.REACT_APP_ENV === "batofin") {
  apiUrl = `https://batofin.investime.fi/${language}api`;
  isProduction = true;
}
else if (process?.env?.REACT_APP_ENV === "dev") {
  apiUrl = `https://dev.investime.fi/${language}api`;
}
else if (process?.env?.REACT_APP_ENV === "jk_lenovo") {
  apiUrl = `http://localhost:8000/${language}api`;
}
else if (process?.env?.REACT_APP_ENV === "moltres") {
  apiUrl = `http://127.0.0.1:8000/${language}api`;
}
else if (process?.env?.REACT_APP_ENV === "hardcode") {
  apiUrl = `http://127.0.0.1:8001/${language}api`;
}

const config = {
  apiUrl: apiUrl,
  TEST_NO_BACKEND: false,
  isProduction: isProduction,
  privateApiUrl: privateApiUrl,
}

export default config;