import React, { useEffect, useState, useCallback } from "react";
import moment from "moment";
import { useTranslation } from 'react-i18next';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useLocation } from "react-router-dom";
import queryString from 'query-string'
import { generatePath } from "react-router";
import { Route, Switch, Redirect } from "react-router-dom";
import LineChart from "stories/components/LineChart";




// Stories
import { Row, Col } from "stories/layout";
import { Input } from "stories/forms";
import { Card, Typography, Button, Link, IconButton, Modal, Spinner, BreadcrumbsDynamic } from "stories/components";
import { PageHeader, PageContainer } from "components/Page"
import { TableAuto } from "stories/tables"
import PageTabs from "components/PageTabs/PageTabs"

// App
import PrivateInvestmentFundSelect from "components/Forms/PrivateInvestmentFundSelect/PrivateInvestmentFundSelect"
import { httpGetPrivateInvestimentFundDatas } from "services/private_investment_funds"
import { getDate } from "services/datetime"
import PrivateInvestmentFundRateEdit from "views/app/PrivateInvestmentFundRateEdit/PrivateInvestmentFundRateEdit"

const PrivateInvestmentFundRatesList = (props, {match, history,}) => {
  
  const { t } = useTranslation();
  const location = useLocation();
  const getPrivateInvestmentFunds = useStoreActions((actions) => actions.privateInvestmentFunds.getObjects);
  const [loading, setLoading] = useState(false)
  const [objects, setObjects] = useState()
  const [count, setCount] = useState()
  const {yAxes, xAxes} = props



  const getOptions = () => {
    //options for chartjs
    const options = {
      maintainAspectRatio: true,
      layout: {
        padding: {
          bottom: 10,
          top: -20
        }
      },
      elements: {
        point: {
          radius: 3
        }
      },
      scales: {
        x: 
          {
            type: 'time',
            time: {
              unit: 'day', // 'day', 'week', 'month', 'quarter', 'year'
              stepSize: 1,
            },

            grid: {
              drawBorder: true, // <-- this removes the border under the chart
              drawOnChartArea: true, // IDON'T KNOW WHAT THIS DOES
              display: true, // <-- this removes the grid lines
              lineWidth: 0 // <-- this removes vertical lines between bars
            },
            ticks: {
              // maxTicksLimit: 7, // 

              display: true, //this removed the labels on the x-axis
              stepSize: 1,
              source: 'data',
            },
          },
        y: 
          {
            beginAtZero: false,
            display: true,
          },
        
      },
      plugins: {
        tooltip: {
          enabled: true,

        },
        title: {
          display: true,
        },


      }

    };
    return options
  }


  return (
    <div className=""  >
    <div className="h-75" >
  <LineChart chartData={props.objects} xAxes={xAxes} yAxes={yAxes} defaultStartDaysNum="30" defaultEndDate={true} options={getOptions()} width="300" height="100" /> {/*TODO: give x-axis key parameters*/}
    </div>

    </div>
  );
}

export default PrivateInvestmentFundRatesList;


// <div className="d-flex flex-row align-items-end">
// <div className="p-2 pr-1">
//   <h4>{t("PrivateInvestmentFundRatesList_private_investment_fund", "Rahasto")}</h4>
  
