import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import { Input } from "stories/forms";
import { useAppContext }from "contexts/AppContext"

const SellProfitCalculationMethodSelect = ({ label, name="sell_profit_calculation_method", onChange, useOnlyList=false, value,noDefault=false, ...rest }) => {
  const { t } = useTranslation();
  const { enums } = useAppContext()
  
  const getInputOptions = useCallback(() => {
    const profit_methods = enums?.portfolios?.portfolio?.sell_profit_calculation_method
    return profit_methods && profit_methods.map(obj => {
      // check if useOnlyList is set and it is list with at least one item
      if(useOnlyList && useOnlyList.length > 0) {
        // check if current item is in useOnlyList list
        if(useOnlyList.indexOf(obj.key) < 0) {
          // if not, return null
          return null
        }
      }
      return (
        <option value={obj.key}>{t(obj.value)}</option>
      )
    })
  }, [enums, useOnlyList])
  
  return (
    <>

    <Input  label={label || label === "" ? label : t("currency_select_label", "Luovutusvoiton laskenta")} type="select" name={name} onChange={onChange} value={value} {...rest}>
      {noDefault && <option value="">--</option>}
      {getInputOptions()}
    </Input>
    </>
  )
  
  
}

export default SellProfitCalculationMethodSelect;