import { action, thunk, persist } from 'easy-peasy';
import { httpGetAllSectors } from "services/private_investment_funds"

const model = {
  loading: false,
  setLoading: action((state, payload) => {
    state.loading = payload
  }),
  count: 0,
  setCount: action((state, payload) => {
    state.count = payload
  }),
  // My sectors list
  sectors: [],
  setSectors: action((state, payload) => {
    if (payload) {
      state.sectors = payload
    }
  }),
  getSectors: thunk(async (actions, payload) => {
    actions.setLoading(true)
    httpGetAllSectors().then(response => {
      if (response.status === 200 && response?.data) {
        actions.setLoading(false)
        actions.setSectors(response?.data)
        if (response?.data.length > 0) {
          actions.setCount(response?.data.length)
        }
        else {
          actions.setCount(0)
        }
      }
    }, error => {
      
    }).finally(() => {
      actions.setLoading(false)
    })
    
  }),

};

export default model;