import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import { Input } from "stories/forms";

const inputOptions = [
  {key: 2, value: "Call option"},
  {key: 3, value: "Put option"},
]

const OptionRightInput = ({label, name="right", onChange, value, allOption}) => {
  const { t } = useTranslation();
  
  const getInputOptions = useCallback(() => {
    return inputOptions && inputOptions.map(obj => {
      return (
        <option value={obj.key}>{obj.value}</option>
      )
    })
  }, [inputOptions])
  
  return (
    <Input label={label ? label : t("Right")} type="select" name={name} onChange={onChange} value={value}>
      {allOption && (<option value="">{t("Kaikki")}</option>)}
      {getInputOptions()}
    </Input>
  )
  
  
}


export default OptionRightInput;