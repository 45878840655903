import React from 'react';
import PropTypes from 'prop-types';
import { Row as BootstrapRow } from "reactstrap";
import './Row.css';

export const Row = ({ children, ...rest }) => {
  
  return (
    <BootstrapRow
      {...rest}
    >
    {children}
    </BootstrapRow>
  )
};

Row.propTypes = {
  
};


Row.defaultProps = {
  
};

