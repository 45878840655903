import React, { useEffect, useState, useCallback } from "react";
import moment from "moment";
import { useTranslation } from 'react-i18next';
import { useStoreState, useStoreActions } from 'easy-peasy';

import { Row, Col } from "stories/layout";
import { Input } from "stories/forms";
import { Card, Typography, Button, Link, IconButton, Modal, Spinner } from "stories/components";
import { PageHeader, PageContainer } from "components/Page"
import { TableAuto } from "stories/tables"

import PortfolioEdit from "views/app/PortfolioEdit/PortfolioEdit"
import UserInvitate from "views/dashboard/UserInvitate/UserInvitate"
import CompanyEdit from "views/dashboard/CompanyEdit/CompanyEdit"

import { httpGetAccountUsers, } from "services/account_users"
import { httpInviteUser} from "services/users"
// import from appcontext
import { useAppContext } from "contexts/AppContext"
import { useAuthContext } from "contexts/AuthContext"
import { useNotification } from "stories/components/Notification"
import { httpGetCompanies } from "services/companies";

import InvestmentTypeSelectSub from "components/Forms/InvestmentTypeSelectSub/InvestmentTypeSelectSub"
import CurrencySelect from "components/Forms/CurrencySelect/CurrencySelect"




//use  noDefault={true}appcontext to get user data



const ImportFoundedBasicDataV2 = ({match, history, basicDataRows, setBasicDataRows}) => {
  
  const { t } = useTranslation();
  

  // const loading = useStoreState((state) => state.portfolio?.loading);
  const portfoliosCount = useStoreState((state) => state.portfolio?.count);
  const getPortfolios = useStoreActions((actions) => actions.portfolio.getPortfolios);
  const { getPortfolioName, getUserTypeName,  getSecurityType} = useAppContext()
  const [loading, setLoading] = useState(false)
  const { notify } = useNotification();
  const { isAdmin } = useAuthContext()


  const [filters, setFilters] = useState({
    limit: 30,
    offset:0,
  })

  
  // Table
//   const [rows, setRows] = useState({
//     count: 0,
//     results: [],
//   })


  
  const getHeaders = (param) => {
  const headers = [
      { label: t("import_founded_basic_data_name", "Excel Nimi"), key: "name"},
      { label: t("import_founded_basic_data_ticker_symbol", "Excel Symbol"), key: "ticker_symbol"},
      { label: t("import_founded_basic_data_connected_id", "Inv. ID"), key: "connected_id"},
      { label: t("import_founded_basic_data_connected_name", "Inv. Nimi"), key: "connected_name"},
      { label: t("import_founded_basic_data_connected_symbol", "Inv. symbol"), key: "connected_symbol"},
      { label: t("import_founded_basic_data_connected_currency", "Inv. Valuutta"), key: "connected_currency"},
      { label: t("import_founded_basic_data_connected_currency", "Inv. Stock market"), key: "connected_stock_market"},
      { label: t("import_founded_basic_data_connected_type", "Inv. kategoria"), key: "connected_type"},
  ];
  return headers
  }

  
  const handleChange = ({target}) => {
    setFilters({
      ...filters,
      [target.name]: target.value,
    })
  }
  
  const paginationPrevious = () => {
    let offset = filters?.offset - filters?.limit;
    if (offset < 0) {
      offset = 0;
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  const paginationNext = () => {
    let offset = filters?.offset + filters?.limit;
    if (offset > filters?.count) {
      return
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }

  const handleInvestmentTypeChange = (index) => {
    return (e) => {
        const { value } = e.target;
        const newRows = [...basicDataRows];
        newRows[index].security_type = value;
        setBasicDataRows(newRows);
    }
    }

  const handleSizeInputChange = (index) => {
    return (e) => {
        const { value } = e.target;
        const newRows = [...basicDataRows];
        newRows[index].size = value;
        setBasicDataRows(newRows);
    }
  }
  const handleCurrencySelectChange = (index) => {
    return (e) => {
        const { value } = e.target;
        const newRows = [...basicDataRows];
        newRows[index].currency_label = value;
        setBasicDataRows(newRows);
    }
  }




  
  const getTableRows = useCallback(() => {
    return basicDataRows && basicDataRows.map((row, index) => {
      return {
        ...row,
        connected_type: getSecurityType(row?.connected_type)?.value,

        actions: (
          <div className="d-flex justify-content-end">
            <Button className="btn-sm" color="secondary" disabled={isAdmin === false} onClick={() => openModalEdit(row?.id)}>{t("edit_button","Muokkaa")}</Button>
          </div>
        ),
      }
    })
  }, [basicDataRows])
  

  
  // Modal: New/Edit company
  
  const [modalEditOpen, setModalEditOpen] = useState(false)
  const [modalEditId, setModalEditId] = useState()
  
  const toggleModalEdit = () => {
    setModalEditOpen(s => !s);
  }
  
  const openModalEdit = (id) => {
    setModalEditOpen(true)
    setModalEditId(id);
  }
  
  const modalEditResolve = () => {
    setModalEditOpen(false);
    setModalEditId(null);
  }
  
  const modalEditReject = () => {
    setModalEditOpen(false);
    setModalEditId(null);
  }
  
  return (
    <>
        <div className="d-flex justify-content-between mb-2">

          
          <div className="p-2">
            
          </div>
        </div>

        <PageHeader className="text-dark mb-0" title={t("import_founded_basic_data_connected_securities","Yhdistetyt arvopaperit")}></PageHeader>
        <Typography variant="small" className="mb-3">{t("")} </Typography>
        <TableAuto
          showRowNumber={true}
          showId={false}
          checkboxes={false}
          headers={getHeaders()}
          headerColor={"light"}
          rows={getTableRows()}
          loading={loading}
          pagination={false}
          paginationPrevious={paginationPrevious}
          paginationNext={paginationNext}
          paginationOffset={filters?.offset}
          paginationLimit={filters?.limit}
          paginationCount={null}
          setFilters={setFilters}
        />
        
      
        <Modal
          title={t("companies_list_company_title","Yritys")}
          isOpen={modalEditOpen}
          toggleModal={toggleModalEdit}
        >
          <CompanyEdit
            objectId={modalEditId}
            handleResolve={modalEditResolve}
            handleReject={modalEditReject}
          />
        </Modal>
    </>
  );
}

export default ImportFoundedBasicDataV2;