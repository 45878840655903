import React, { useCallback, useEffect, useState} from "react";
import moment from "moment";
import { useTranslation } from 'react-i18next';
import { useStoreState, useStoreActions } from 'easy-peasy';

import { 
  Button, 
  Link,
  Typography, 
} from "stories/components";
import { PageWrapper, PageHeader } from "components/Page"
import { Col, Row } from "stories/layout"
import { Checkbox } from "stories/forms";
import { httpSubscribeAccountLicense } from "services/accounts"
import { useAuthContext } from "contexts/AuthContext"
import { round, roundScaleDecimals } from "services/helpers"  

import vismaPayLogo from "assets/images/visma pay_logo_värillinen_96h.png";

const LicensePayment = ({history, handleReject, licenseId, hasActiveLicense=false}) => {
  
  const { t } = useTranslation();
  const { isAuthenticated, logout } = useAuthContext()
  
  const licenses = useStoreState((state) => state.licenses?.licenses);
  const [chosenLicense, setChosenLicense] = useState()
  
  useEffect(() => {
    if (licenses && licenseId) {
        const findLicense = licenses?.find(license => license?.id === licenseId)
        setChosenLicense(findLicense)
    }
    
  }, [licenses, licenseId])

  
  
  const imageUrl = 'https://static.vismapay.com/pay_banners/row.png';

  const [loading, setLoading] = useState(false)
  const [terms, setTerms] = useState(false)
  const handleChange = (e) => {
    setTerms(e?.target?.checked)
  }
  
  const handleSubscribe = () => {
    setLoading(true)
    const data = {
        license_id: chosenLicense.id
    }
    httpSubscribeAccountLicense(data).then(res => {
      const token = res?.data?.token;
      window.location.href = `https://www.vismapay.com/pbwapi/token/${token}`
    })
  }
  
  const handleLogout = () => {
    logout();
  }
  
  if (!chosenLicense) {
      return (
        <>Loading...</>
      )
  }
  
  return (
    <div className="sk_views_license_payment px-4">
        
        <div className="d-flex justify-content-center align-items-center flex-column">
      
          <Row className="mb-3">
            <Col>
              <span className="text-success" style={{fontSize: "48px"}}>
                <i className="fas fa-thumbs-up fa-2xl"></i>
              </span>
            </Col>
          </Row>
          <Row>
            <Col>
              <PageHeader title={t("license_payment_title","Viimeistele tilaus suorittamalla maksutiedot.")}></PageHeader>
            </Col>
          </Row>
          
        </div>
        
        <div className="d-flex justify-content-start align-items-start flex-column mb-4">
            <div className="pb-2">{t("license_payment_order_information","Tilauksen tiedot")}</div>
            <div className="pb-2">{t("license_payment_access_right","Käyttöoikeus (jatkuva)")}</div>
            <div className="pb-2">{chosenLicense?.name} {t("license_payment_order","tilaus")} {roundScaleDecimals(chosenLicense?.price / (1 + (chosenLicense?.price_vat_percent / 100.0)),2)} € /{t("sk_month_short","kk")} (+{t("sk_vat_short","alv")} {chosenLicense?.price_vat_percent}%)</div>
        </div>
        
        <div className="d-flex justify-content-between">
          <div>
            <Checkbox 
              id="license_payment_terms"
              name="terms"
              checked={terms}
              onChange={handleChange}
              value="agree_terms"
              required
              label={<>{t("license_payment_accept_terms",'Hyväksy palvelun')} <Link href="https://www.investime.fi/legal/" target="_blank">{t('käyttöehdot')}</Link></>}
            />
          </div>
          
          <div>
            <Button variant="secondary" disabled={terms === false || loading === true} onClick={handleSubscribe}>{t("license_payment_submit",'Siirry maksupalveluun')}</Button>
          </div>
        </div>
        <div className="d-flex justify-content-end align-items-center my-3">
          <div>
              { hasActiveLicense ? (
                <Button variant="default" onClick={handleReject}>{t('Peruuta')}</Button>
              ) : (
                <Button variant="default" onClick={handleLogout}>{t("log_out",'Kirjaudu ulos')}</Button>
              )}
          </div>
        </div>
        
        <div>
            <img src={imageUrl} alt="Visma Banner" className="w-100"/>
        </div>
        
        {/*
        <div>
          <img src="https://static.vismapay.com/pay_banners/row.png" alt="visma payment options" />
        </div>
        */}
        
        
    </div>
  );
}

export default LicensePayment;
