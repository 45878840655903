import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import { Input } from "stories/forms";
import { useAppContext }from "contexts/AppContext"
import { SelectMulti } from "stories/forms";
import { useStoreState, useStoreActions } from 'easy-peasy';
import { httpGetTags, httpCreateTag, httpUpdateTag, httpDeleteTag } from "services/tags"
 

const SectorSelect = ({ label, name="tag",multiple=false,placeholder,disabled, showAll=false, onChange, setFieldValue,validateForm, showAllOption, value, ...rest}) => {
  const { t } = useTranslation();
  // const { enums } = useAppContext()
  // const sectors_enums = enums?.private_investment_funds?.private_investment_fund?.sector
  // const [sectors, setSectors] = useState()
//   const sectors = useStoreState((state) => state.sectors?.sectors);
  const sectors = useStoreState((state) => state.tags?.tags);

//   const [sectors, setSectors] = useState([]);

//   useEffect(() => {
//     httpGetTags("sector").then((response) => {
//         setSectors(response.data);
//     });
//     }, []);


  let privateSectors = []
  let publicSectors = []

// useEffect(() => {
//     //convert enums to key to id and value to name prop
//     if(sectors_enums) {
//       const sectors = sectors_enums.map(obj => {
//         return {id: obj.key, name: obj.value}
//       })
//       setSectors(sectors)
//     }
//     }, [sectors_enums])



  const getInputOptions = useCallback(() => {
    //sort sectors by name

    //get all sectors wcich id start with 2
    // let filteredSectors2 = sectors && sectors.filter(obj => obj.id.toString().startsWith('2'));
    // let filteredSectors1 = sectors && sectors.filter(obj => obj.id.toString().startsWith('1'));

    // //put them together first 2 then 1
    // let sortedSectors12 = sectors && [...filteredSectors2, ...filteredSectors1]

    

    
    return sectors && sectors.map(obj => {
      console.log("obj", obj)
      return (

        <option key={obj.id} value={obj.id}>{obj.name} </option>
      )
    })
  }, [sectors, value])


  const getSelectedOptions = useCallback(() => {
    if (multiple === false) {
      return [value];
    }
    return value && value.map(obj => {
      return {value: obj.id, label: obj.value}
    })
  }, [value,])

  const getOptions = useCallback(() => {

    //get all sectors wcich id start with 2
    // let filteredSectors2 = sectors && sectors.filter(obj => obj.id.toString().startsWith('2'));
    // //add item to filteredSectors2 to index 0 place
    // if (filteredSectors2 && filteredSectors2.length > 0) {
    //   filteredSectors2 = [{id: "2", name: t("private_sector", "OMAT"),color: '#0052CC', isDisabled: true}, ...filteredSectors2] 
    // }


    // let filteredSectors1 = sectors && sectors.filter(obj => obj.id.toString().startsWith('1'));
    // filteredSectors1 = [{id: "1", name: t("public_sector", "VALMIIT"), color: '#0052CC', isDisabled: true}, ...filteredSectors1] 


    // //put them together first 2 then 1
    // let sortedSectors12 = sectors && [...filteredSectors2, ...filteredSectors1]

    return sectors && sectors.map(obj => {
      return {value: obj.id, label: t(`sector_select_${obj.id}`,obj.name), color: obj.color, isDisabled: obj.isDisabled}
    })
  }, [sectors])


  const getSelect = useCallback(() => {

    return(
        <SelectMulti
        isMulti={multiple}
        label={label ? label : t("portfolio_select_label", "Toimiala")}
        name={name}
        value={value}
        setFieldValue={setFieldValue}
        defaultValues={getSelectedOptions()}
        options={getOptions()}
        showAllOption={showAllOption} 
        validateForm={validateForm}
        placeholder={placeholder}
        disabled={disabled}
        focused={true}
        {...rest}
        />
    )

  }, [sectors, value, multiple, showAllOption, validateForm, placeholder, disabled, rest])

  return (
    <>
      {

      getSelect()
      }
     
    </>
  )
  
  
}

export default SectorSelect;
