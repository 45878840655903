//Create simple react Component

import React, { useState, useCallback, useEffect } from "react";
import { Button, Typography, Link, Spinner, Modal } from "stories/components";
import { useStoreState, useStoreActions } from 'easy-peasy';


import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col, FormContainer, Card, CardBody } from "stories/layout";
import PortfolioSelectStore from "components/Forms/PortfolioSelect/PortfolioSelectStore"

import PurchaseEdit from "views/app/PurchaseEdit/PurchaseEdit"
import SaleEdit from "views/app/SaleEdit/SaleEdit"
import PrivateInvestmentFundEdit from "views/app/PrivateInvestmentFundEdit/PrivateInvestmentFundEdit"




const NewEvent = (props) => {
    const { t } = useTranslation();
    const count = useStoreState((state) => state.purchases?.count);
    const getUnmadeDividendTransactions = useStoreActions((actions) => actions.unmadeDividendTransactions.getUnmadeDividendTransactions);
    const getUnmadeSplitTransactions = useStoreActions((actions) => actions.unmadeSplitTransactions.getUnmadeSplitTransactions);

    // const handleCancel = () => {
    //     props.handleReject()
    // }
    const [filters, setFilters] = useState({})

    // Modal: New/Edit Purchase
    const [modalEditPurchaseOpen, setModalEditPurchaseOpen] = useState(false)
    const [modalEditPurchaseId, setModalEditPurchaseId] = useState()
    const history = useHistory();

    const toggleModalEditPurchase = () => {
        setModalEditPurchaseOpen(s => !s);
    }
    
    const openModalEditPurchase = (id, related_model_type, ticker_symbol) => {
        setModalEditPurchaseOpen(true)
        setModalEditPurchaseId(id);
        // setInvestmentType(related_model_type)
        // setTickerSymbol(ticker_symbol)
    }
    
    const modalEditPurchaseResolve = () => {
        console.log("modalEditPurchaseResolve")
        setModalEditPurchaseOpen(false);
        setModalEditPurchaseId(null);
        getUnmadeDividendTransactions()
        getUnmadeSplitTransactions()
        history.push('/dashboard/transactions')
    }
    
    const modalEditPurchaseReject = () => {
        setModalEditPurchaseOpen(false);
        setModalEditPurchaseId(null);
    }

    // Modal: New Sale
    const [modalEditSaleOpen, setModalEditSaleOpen] = useState(false)
    const [modalEditSaleId, setModalEditSaleId] = useState()

    const toggleModalEditSale = () => {
        setModalEditSaleOpen(s => !s);
    }
    
    const openModalEditSale = (id, related_model_type, ticker_symbol) => {
        setModalEditSaleOpen(true)
        setModalEditSaleId(id);
        // setInvestmentType(related_model_type)
        // setTickerSymbol(ticker_symbol)
    }
    
    const modalEditSaleResolve = () => {
        console.log("modalEditSaleResolve")
        setModalEditSaleOpen(false);
        setModalEditSaleId(null);
        getUnmadeDividendTransactions()
        getUnmadeSplitTransactions()
        history.push('/dashboard/transactions')
    }
    
    const modalEditSaleReject = () => {
        setModalEditSaleOpen(false);
        setModalEditSaleId(null);
    }
    
    // Modal: New/Edit
    
    const [modalEditOpen, setModalEditOpen] = useState(false)
    const [modalEditId, setModalEditId] = useState()
    
    const toggleModalEdit = () => {
        setModalEditOpen(s => !s);
    }
    
    const openModalEdit = (id) => {
        setModalEditOpen(true)
        setModalEditId(id);
    }
    
    const modalEditResolve = () => {
        setModalEditOpen(false);
        setModalEditId(null);
        history.push('/dashboard/private_investment_funds')

        // getPrivateInvestmentFunds(filters)
    }
    
    const modalEditReject = () => {
        setModalEditOpen(false);
        setModalEditId(null);
    }


    return (
        <Container>

            <Row>
                <Col className="py-2">
                    <Typography variant="h3" className="text-dark mb-3">{t("quick_action_header","Pikatoiminnot")}</Typography>
                    <Row>
                        <Col>
                            <Button size="sm" variant="default" className="" onClick={() => openModalEditPurchase(null, "XHEL", null)}>
                                <Typography variant="h3" className="text-dark text-center font-weight-normal"> {t("quick_action_new_purchase"," osto")} <i className="fas fa-plus fa-sm "></i></Typography>
                            </Button>
                            

                            <Button size="sm" variant="default" className="" onClick={() => openModalEditSale(null, "XHEL", null)}>
                                <Typography variant="h3" className="text-dark text-center font-weight-normal">{t("quick_action_new_sale"," myynti")} <i className="fas fa-plus fa-sm "></i> </Typography>
                            </Button>
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        {/* <Col>
                            <Button size="sm" variant="default" className="" onClick={() => openModalEdit(null, "XHEL", null)}>
                                <Typography variant="h3" className="text-dark text-center font-weight-normal">{t("New dividend"," osinko")} <i className="fas fa-plus fa-sm "></i> </Typography>
                            </Button>
                        </Col> */}
                        <Col>
                            <Button size="sm" variant="default" className="" onClick={() => openModalEdit(null, "XHEL", null)}>
                                <Typography variant="h3" className="text-dark text-center font-weight-normal">{t("quick_action_new_security"," arvopaperi")} <i className="fas fa-plus fa-sm "></i> </Typography>
                            </Button>
                        </Col>
                    </Row>


                    {/* <div className="rounded bg-success mb-3">
                        <Link
                            to={{pathname:"/dashboard/transactions", state: {newSale:true}}}
                            onClick={() => props.handleResolve()}
                        >
                        <Typography variant="h3" className="text-white p-3 text-center">{t("New sale","Kirjaa uusi myynti")}</Typography>
                        </Link>
                    </div>
                    <div className="rounded bg-info mb-3">
                        <Link
                            to={{pathname:"/dashboard/transactions", state: {newExchange:true}}}
                            onClick={() => props.handleResolve()}
                        >
                        <Typography variant="h3" className="text-white p-3 text-center">{t("New Exchange","Kirjaa uusi vaihto")}</Typography>
                        </Link>
                    </div>
                    <div className="rounded bg-secondary mb-3">
                        <Link
                            to={{pathname:"/dashboard/private_investment_funds/listed_stock", state: {newSecurity:true}}}
                            onClick={() => props.handleResolve()}
                        >
                        <Typography variant="h3" className="text-white p-3 text-center">{t("New Exchange","Lisää arvopaperi")}</Typography>
                        </Link>
                    </div> */}

                
                </Col>
            </Row>
            {/* <Row>
                <Col className="mb-3">
                <Button onClick={ modalEditPurchaseReject} variant="default">{t("Cancel","Peruuta")}</Button>
                </Col>

            </Row> */}


            <Modal
                // title={modalEditPurchaseId > 0 ? t("Muokkaa ostokirjausta") : t("Uusi ostokirjaus")}
                isOpen={modalEditPurchaseOpen}
                toggleModal={toggleModalEditPurchase}
                size="modal-xl"
                showHeader={false}
                classNameBody="p-0 padding-0"
            >
                <PurchaseEdit
                objectId={modalEditPurchaseId}
                handleResolve={modalEditPurchaseResolve}
                handleReject={modalEditPurchaseReject}
                defaultStockMarket={"XHEL"}
                defaultInvestmentType={10}
                setFilters={setFilters}
                transaction_count = {count}

                // transaction_count = {count}
                // portfoliosSummary={portfoliosSummary}
                />
            </Modal>

            <Modal
                // title={modalEditPurchaseId > 0 ? t("Muokkaa ostokirjausta") : t("Uusi ostokirjaus")}
                isOpen={modalEditSaleOpen}
                toggleModal={toggleModalEditSale}
                size="modal-xl"
                showHeader={false}
                classNameBody="p-0 padding-0"
            >
                <SaleEdit
                objectId={modalEditSaleId}
                handleResolve={modalEditSaleResolve}
                handleReject={modalEditSaleReject}
                defaultStockMarket={"XHEL"}
                defaultInvestmentType={10}
                setFilters={setFilters}
                transaction_count = {count}

                // transaction_count = {count}
                // portfoliosSummary={portfoliosSummary}
                />
            </Modal>

            <Modal
                title={t("PrivateInvestmentFundEdit_title", "Oma arvopaperi")}
                isOpen={modalEditOpen}
                toggleModal={toggleModalEdit}
                >
                <PrivateInvestmentFundEdit
                objectId={modalEditId}
                handleResolve={modalEditResolve}
                handleReject={modalEditReject}
                />
            </Modal>
        </Container>
    )
}

export default NewEvent;
