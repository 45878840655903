import React, { useState, useCallback, useEffect } from "react";
import { generatePath } from "react-router";
import { Card, Typography, Button, Link, IconButton } from "stories/components";

const PageTabs = ({match, tabs, location}) => {
  //get current path
  const currentPath = match?.path;  
  //get current tab
  const currentTab = match?.params?.tab;
  



  const getTabs = () => {
    
    return tabs && tabs.map((tab, index) => {
      return (
        <li className="nav-item tab-item page_background">

          <Link 
            className={
              `nav-link 
              ${location?.pathname === generatePath(match.path + "/:tab", {tab: tab?.key}) ? "active": index === 0 && String(location?.pathname) === match.path ? "active" : ""} 
              py-3`
            }
            to={generatePath(match.path + "/:tab", {tab: tab?.key})}
          >
          {tab?.label}
          </Link>
        </li>
      )
    })
  }

  return (
    <ul className="nav nav-tabs">
      {getTabs()}
    </ul>
  )
}

export default PageTabs;
