import React, { useState, useCallback, useEffect } from "react";
import { Button, Typography, Link, Spinner, Modal, Popover } from "stories/components";

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col, FormContainer, Card, CardBody } from "stories/layout";


import FiImage from "assets/img/icons/flags/FI.png";
import SweImage from "assets/img/icons/flags/SWE.png";
import GbImage from "assets/img/icons/flags/GB2.png";

import { useAuthContext } from "contexts/AuthContext";
import { set } from "date-fns";


const Flags = (props) => {
    const { t } = useTranslation();
    const { setLanguage } = useAuthContext();

    const [isHovered, setIsHovered] = useState(false);
    const [isHovered2, setIsHovered2] = useState(false);
    const [isHovered3, setIsHovered3] = useState(false);

    const handleMouseEnter = () => {
      setIsHovered(true);
    };
    const handleMouseLeave = () => {
      setIsHovered(false);
    };
    const handleMouseEnter2 = () => {
      setIsHovered2(true);
    };
    const handleMouseLeave2 = () => {
      setIsHovered2(false);
    };
    const handleMouseEnter3 = () => {
      setIsHovered3(true);
    };
    const handleMouseLeave3 = () => {
      setIsHovered3(false);
    };
  
    const handleClick = (language) => {
      setLanguage(language);
    };


    return (
        <div className="d-flex justify-content-start">
        <div 
          className={`px-2 pb-0 ${isHovered ? '' : ''}`}
          onClick={() => handleClick("fi")}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          style={{
            cursor: 'pointer',  // Change cursor to hand
            transition: 'box-shadow 0.3s ease',  // Add a smooth transition for the shadow
            boxShadow: isHovered ? '0 4px 8px rgba(0, 0, 0, 0.2)' : 'none',  // Darker shadow on hover
          }}
        >
          <img
            alt="FI-flag"
            src={FiImage}
            className=""
            style={{
              maxWidth: '300px',  // Set your desired maximum width
              maxHeight: '300px',  // Set your desired maximum height

            }}
          />
        </div>
        <div 
          className={`px-2 pb-0 ${isHovered2 ? '' : ''}`}
          onClick={() => handleClick("en")}
          onMouseEnter={handleMouseEnter2}
          onMouseLeave={handleMouseLeave2}
          style={{
            cursor: 'pointer',  // Change cursor to hand
            transition: 'box-shadow 0.3s ease',  // Add a smooth transition for the shadow
            boxShadow: isHovered2 ? '0 4px 8px rgba(0, 0, 0, 0.2)' : 'none',  // Darker shadow on hover
          }}
        >
          <img
            id="GB-flag"
            alt="GB-flag"
            src={GbImage}
            className=""
            style={{
              maxWidth: '300px',  // Set your desired maximum width
              maxHeight: '300px',  // Set your desired maximum height
              // opacity: '0.4'
            }}
          />
          {/* <Popover
              placement="bottom"
              target="GB-flag"
            >       
              <Typography className="text-warning mb-1" variant="medium">

                  {t("Support is coming soon.")}
              </Typography>  
          </Popover> */}
        </div>
        <div 
          className={`px-2 pb-0 ${isHovered3 ? '' : ''}`}
          // onClick={handleClick("sv")}
          onMouseEnter={handleMouseEnter3}
          onMouseLeave={handleMouseLeave3}
          style={{
            cursor: 'pointer',  // Change cursor to hand
            transition: 'box-shadow 0.3s ease',  // Add a smooth transition for the shadow
            boxShadow: isHovered3 ? '0 4px 8px rgba(0, 0, 0, 0.2)' : 'none',  // Darker shadow on hover
          }}
        >
          <img
            id="swe-flag"
            alt="SWE-flag"
            src={SweImage}
            className=""
            style={{
              maxWidth: '300px',  // Set your desired maximum width
              maxHeight: '300px',  // Set your desired maximum height
              opacity: '0.4'
            }}
          />
          <Popover
              placement="bottom"
              target="swe-flag"
            >       
              <Typography className="text-warning mb-1" variant="medium">

                  {t("Support kommer snart.")}
              </Typography>  
          </Popover>
        </div>
      </div>
    )


}

export default Flags