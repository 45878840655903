import Axios from 'axios'
import appConfig from "./config"
import history from "services/history"
import jwt_decode from "jwt-decode";
import { logoutWhenTokenExp } from "services/logoutTokenExp";


let baseUrl = appConfig.apiUrl;

let axios = Axios.create({
  withCredentials: false,
  baseURL: baseUrl,
})

//request interceptor to add the auth token header to requests
axios.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      config.headers["Authorization"] = `jwt ${accessToken}`;
    }
    // Language
    const language = localStorage.getItem("language", "fi");
    config.headers["Accept-Language"] = language
    
    return config;
  },
  (error) => {
    // Promise.reject(error);
    return Promise.reject(error);
  }
);
//response interceptor to refresh token on receiving token expired error
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    const originalRequest = error.config;
    console.log(error)
    if (originalRequest?.url.includes('/api/token/refresh/') && error.response.status === 401) {
      console.log("39");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");



      // history.push("/auth/login")
      window.location.href = "/"
    }
    else {
      let refreshToken = localStorage.getItem("refreshToken");
      if (
        refreshToken &&
        error?.response?.status === 401 &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;
        
        const requestOptions = {
          url: `${baseUrl}/token/refresh/`,
          method: "POST",
          headers: {
            
          },
          data: { refresh: refreshToken }
        };
        return axios(requestOptions).then(res => {
          if (res.status === 200) {
            localStorage.setItem("accessToken", res.data.access);
            localStorage.setItem("refreshToken", res.data.refresh);

            logoutWhenTokenExp();

            console.log("Access and refres token refreshed!");
            return axios(originalRequest);
          }
          else if (res.status === 401 && requestOptions?.url.includes('/api/token/refresh/')) {
            console.log("REFRESH 401");
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            console.log("Kirjautuminen on vanhentunut!")
            // history.push("/auth/login")
            console.log("80");
            window.location.href = "/"
          }
        }, errorRefresh => {
          console.log("errorRefresh", errorRefresh)
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          if (errorRefresh?.status === 401 && errorRefresh?.config?.url.includes('/api/token/refresh/')) {
            console.log("80");
            window.location.href = "/"
          }
          
        });
      }
      else {
        const accessToken = localStorage.getItem("accessToken");
        if ((!refreshToken || refreshToken.length == 0) && accessToken && accessToken.length > 0) {
          console.log("91.No refreshToken, log user out")
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          window.location.href = "/"
        }
      }
    }
    // return error?.response;
    console.log(error.response)
    error = error.response
    return Promise.reject(error);
  }
);

/*
//functions to make api calls
const api = {
  signup: (body) => {
    return axios.post(`${baseUrl}/auth/signup`, body);
  },
  login: (body) => {
    return axios.post(`${baseUrl}/auth/login`, body);
  },
  refreshToken: (body) => {
    return axios.post(`${baseUrl}/auth/refresh_token`, body);
  },
  logout: (body) => {
    return axios.delete(`${baseUrl}/auth/logout`, body);
  },
  getProtected: () => {
    return axios.get(`${baseUrl}/protected_resource`);
  },
};
*/
axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

export default axios;