import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import { Input } from "stories/forms";
import { useAppContext }from "contexts/AppContext"

const ImportColumnSelect = ({ label, initialOptions, availableOptions, name, onChange, value,noDefault=true, ...rest }) => {
  const { t } = useTranslation();

  
  const getInputOptions = useCallback(() => {
    const getClassName = (obj) => {
        if (!obj.optional) {
          return "text-dark"
        }
    }
    const options = availableOptions

    return options && options.map(obj => {
      return (
        <option className={obj.optional?"text-dark":"text-dark"} value={obj.value}>{obj.label }</option>
      )
    })
  }, [availableOptions])

  const getInputs = useCallback(() => {
 
    return(
        <>
        <Input  label={label || label === "" ? label : t("currency_select_label", "Tyyppi")} type="select" name={name} onChange={onChange} value={value} {...rest}>
        {noDefault && <option value=" " >-</option>}
        {value && value != "" && value != " " && <option value={value}>{value}</option>}
        {getInputOptions()}
        
        </Input>
        </>
    )
    }, [availableOptions, getInputOptions, label, name, noDefault, onChange, rest, t, value])
    
  
  return (
           
    getInputs()
  )
  
  
}

export default ImportColumnSelect;
