import React from 'react';
import PropTypes from 'prop-types';
import './Spinner.css';

export const Spinner = ({ color, className, height }) => {
  return (
    <div name="loading_spinner" className={`spinner-border ${className} ${color}`} role="status">
      <span className="sr-only">Ladataan...</span>
    </div>
  );
};

Spinner.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf(['text-default','text-muted','text-primary','text-info','text-success','text-warning','text-danger','text-dark'])
};

Spinner.defaultProps = {
  className: "",
  color: "text-primary",
};
