import React, { useState, useCallback, useEffect } from "react";
import { withFormik } from 'formik';
import * as Yup from "yup";
import moment from "moment"
import { useTranslation } from 'react-i18next';

import {
  Button,
  Link,
  Typography,
  Spinner, 
  Breadcrumb,
  BreadcrumbItem,
} from "stories/components";
import { Container, Row, Col, FormContainer } from "stories/layout";
import { Checkbox, Input } from "stories/forms";
import { PageWrapper, PageHeader } from "components/Page"

import { useNotification } from "stories/components/Notification"
import { httpChangePassword } from "services/users";
import { useAuthContext }from "contexts/AuthContext"

const MyAccountPasswordChange = (props) => {
  
  const {
     values,
     touched,
     errors,
     handleChange,
     handleBlur,
     handleSubmit,
     setFieldValue,
     isValid,
     isTouched,
     isSubmitting,
     submitCount,
     validateForm
   } = props;
   
   const {
      history,
      t
   } = props;
   
   const getFormikInputProps = useCallback(
    name => ({
      name,
      value: values[name],
      error: Boolean((touched[name] || submitCount > 0) && errors[name]),
      errorText: (touched[name] || submitCount > 0) ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange
    }),
    [values, errors, touched, handleBlur, handleChange, submitCount]
  );
  
  const handleCancel = () => {
    history.push("/dashboard/myaccount");
  }
  
  return (
    <>
      <Row>
        <Col>
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("new_password")}
                label={t("Uusi salasana")}
                type="password"
              />
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("check_password")}
                label={t("Salasana uudelleen")}
                type="password"
              />
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <Button onClick={handleCancel} variant="secondary">{t("Peruuta")}</Button>
            </Col>
            <Col className="mb-3 text-center">
            {/* <Button onClick={handleDelete} variant="danger">Poista</Button> */}
            </Col>
            <Col className="mb-3 text-right">
              <Button variant="success" disabled={isSubmitting} onClick={() => validateForm().then(() => handleSubmit())}>{t("Aseta salasana")}</Button>
              { Boolean(errors["general"]) && <Typography className="text-danger">{errors["general"]}</Typography> }
            </Col>
          </Row>
      
        </Col>
        
        <Col></Col>
        <Col></Col>
        
      </Row>
      
      
    </>
  );
}

const defaultValues = {
  id: null,
}

 const MyAccountPasswordChangeFormik = withFormik({
    
    validateOnMount: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return defaultValues;
      }
    },
    validationSchema: props => {
      const {t} = props;
      const required = t("Kenttä on pakollinen");
      return Yup.object().shape({
        new_password: Yup.string().required(required).nullable(),
        check_password: Yup.string().required(required).nullable(),
      });
    },
    handleSubmit: (values, { setSubmitting, setErrors, props }) => {
      const {t} = props;
      let data = {
        data: {
          ...values,
        }
      };
      
      httpChangePassword("me", data).then(res => {
        setSubmitting(false);
        props.notify({ title:t("Salasana"), message:t("Päivitetty onnistuneesti")})
        props.history.push("/dashboard/myaccount")
        
      }, error => {
        setSubmitting(false);
        if (error?.data?.detail) {
          setErrors({
            general: error?.data?.detail,
          })
        }
        else {
          setErrors({
            general: t("Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen.")
          })
        }
      })
    
      
      
      
    },
    displayName: "BasicForm"
  
  })(MyAccountPasswordChange)
    
    
const MyAccountPasswordChangeView = ({match, history}) => {
  // API requests here
  const { t } = useTranslation();
  const { myUser, getMyUser } = useAuthContext()
  const [loading, setLoading] = useState(false);
  const [preSetValues, setPreSetValues] = useState(null);
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  
  if (loading) {
    return (
      <Container fluid>
        <Spinner />
      </Container>
    )
  }

  return (
    <>
      <Breadcrumb className="mb-3" listClassName="breadcrumb-links">
        <BreadcrumbItem>
          <Link to={`/dashboard/myaccount`}>
            {t('Omat tiedot')}
          </Link>
        </BreadcrumbItem>
        <BreadcrumbItem>
        {t('Vaihda salasana')}
        </BreadcrumbItem>
      </Breadcrumb>
      
      <div className="d-flex justify-content-between">
        <div>
          <PageHeader title={t("Vaihda salasana")}></PageHeader>
        </div>
      </div>
      <MyAccountPasswordChangeFormik history={history} preSetValues={preSetValues} notify={notify} />
    </>
  )
  

}
  

export default MyAccountPasswordChangeView;
