import React, { useCallback, useState, useEffect  } from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";
import { useTranslation } from 'react-i18next';


import { Button, IconButton, Link, Modal } from "stories/components"
import { useAuthContext }from "contexts/AuthContext"
import defaultAvatar from "assets/images/boss-1.png"

import NewEvent from "views/auth/NewEvent"

import "./AdminNavbar.css"

function AdminNavbar({ myUser, theme, sidenavOpen, topNavOpen, toggleTopNav, topNavButtons, isOpen, setIsOpen }) {
  const { t } = useTranslation();

  
  // function that on mobile devices makes the search open
  const openSearch = () => {
    document.body.classList.add("g-navbar-search-showing");
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-showing");
      document.body.classList.add("g-navbar-search-show");
    }, 150);
    setTimeout(function () {
      document.body.classList.add("g-navbar-search-shown");
    }, 300);
  };
  // function that on mobile devices makes the search close
  const closeSearch = () => {
    document.body.classList.remove("g-navbar-search-shown");
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-show");
      document.body.classList.add("g-navbar-search-hiding");
    }, 150);
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-hiding");
      document.body.classList.add("g-navbar-search-hidden");
    }, 300);
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-hidden");
    }, 500);
  };

  const [modalEditOpen, setModalEditOpen] = useState(false)
  const [modalEditId, setModalEditId] = useState()
  
  const toggleModalEdit = () => {
    setModalEditOpen(s => !s);
  }
  
  const openModalEdit = (id) => {
    setModalEditOpen(true)
    setModalEditId(id);
  }
  
  const modalEditResolve = () => {
    setModalEditOpen(false);
    setModalEditId(null);
  }
  
  const modalEditReject = () => {
    setModalEditOpen(false);
    setModalEditId(null);
  }

  const [showButton, setShowButton] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    setShowButton(currentScrollY < lastScrollY);
    setLastScrollY(currentScrollY);
  };

  // window.addEventListener('scroll', () => {
  //   const element = document.getElementById('your-element-id'); // Replace 'your-element-id' with the actual ID of your elementtop-button-bars
  //   const element1 = document.getElementById('top-button-bars'); // Replace 'your-element-id' with the actual ID of your elementtop-button-bars
    

  //   if (window.scrollY === 0) {
  //     element.style.display = 'none';
  //     // element1.style.display = 'block';
  //   } else {
  //     element.style.display = 'block';
  //     // element1.style.display = 'none';
  //   }
  // });

  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll);
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, [lastScrollY]);
  
  const renderTopNavButtons = useCallback(() => {
    return topNavButtons && topNavButtons.map((topNavButton, index) => {
      return (
        <div className="mr-2">
          <Link 
            {...topNavButton}
          >
            <Button color="default">
              {topNavButton?.name} <div className="avatar rounded-circle bg-primary w-32 h-32"><i className={`fas ${topNavButton?.icon_font_awesome}`}></i></div>
            </Button>
          </Link>
        </div>
      )
    })
  }, [topNavButtons])

  
  return (
    <>
      <Navbar
        className={classnames(
          "navbar-top navbar-expand border-bottom",
          { "navbar-dark bg-primary": theme === "dark" },
          { "navbar-light bg-secondary": theme === "light" },
          { "d-none": topNavOpen === false },
        )}
        style={!isOpen
          ? { position: 'sticky', top: 0, zIndex: 1000 }
          : {}}
    
      >
        <Container fluid className="">
          
          {renderTopNavButtons()}
          {/* <Link 
            //  to={{pathname:"/dashboard/transactions", state: {newPurchase}}}>
           onClick={() => openModalEdit()}
          >
            <Button color="default">
            <div className="mb-1">{t("Uusi tapahtuma")}</div>
             <div className=""><i className="fas fa-plus-circle fa-lg text-primary"></i></div>
            </Button>
          </Link> */}
            <Nav className="align-items-center mr-md-auto" navbar>
              {/* {isOpen && window.innerWidth >= 768 &&
              <Button color="secondary" onClick={() => setIsOpen(false)} rounded size="sm"
                className="pl-0 pr-2"
                style={{ 
                  position: 'fixed',
                  borderTopRightRadius: '25%', 
                  borderBottomRightRadius: '25%', 
                  borderTopLeftRadius: '0', 
                  borderBottomLeftRadius: '0'
                }}>
                  <i className={isOpen ? "fas fa-angle-left" : "fa fa-bars"}></i>
              </Button>
              } */}

              
            </Nav>
          <Collapse navbar isOpen={true}>
            <Nav className="align-items-center mr-md-auto" navbar>
              {showButton && !isOpen && (
                <Button
                  id="your-element-id"
                  color="secondary"
                  onClick={() => setIsOpen(true)}
                  rounded
                >
                  <i className="fas fa-bars"></i>
                </Button>
              )}

            </Nav>
            <Nav className="align-items-center ml-auto ml-md-0" navbar>
              <UncontrolledDropdown nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    <img
                      alt="..."
                      src={myUser?.profile_picture || defaultAvatar}
                    />
                  </span>
                  <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold text-white">
                      {myUser?.email}
                    </span>
                  </Media>
                </Media>
                {/*
                <DropdownToggle className="nav-link pr-0" color="" tag="a">
                  <Media className="align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
                      <img
                        alt="..."
                        src={myUser.profile_picture}
                       
                      />
                    </span>
                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                        {user.nickname}
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                
                <DropdownMenu right>
                  <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-overflow m-0">Welcome!</h6>
                  </DropdownItem>
                  <DropdownItem
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="ni ni-single-02" />
                    <span>My profile</span>
                  </DropdownItem>
                  <DropdownItem
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="ni ni-settings-gear-65" />
                    <span>Settings</span>
                  </DropdownItem>
                  <DropdownItem
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="ni ni-calendar-grid-58" />
                    <span>Activity</span>
                  </DropdownItem>
                  <DropdownItem
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="ni ni-support-16" />
                    <span>Support</span>
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="ni ni-user-run" />
                    <span>Logout</span>
                  </DropdownItem>
                </DropdownMenu>
                */}
              </UncontrolledDropdown>
              
              {/*
              <div className="ml-1">
                <IconButton color="secondary" size="lg" iconName={"times"} onClick={toggleTopNav} />
              </div>
              */}
              
            </Nav>
          </Collapse>
        </Container>
        <Modal
          showHeader={false}
          isOpen={modalEditOpen}
          toggleModal={toggleModalEdit}
          classNameBody="p-0 padding-0"

        >
          <NewEvent
            objectId={modalEditId}
            handleResolve={modalEditResolve}
            handleReject={modalEditReject}
          />
        </Modal>
      </Navbar>
    </>
  );
}

AdminNavbar.defaultProps = {
  // toggleSidenav: () => {},
  sidenavOpen: false,
  theme: "dark",
};
AdminNavbar.propTypes = {
  // toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(["dark", "light"]),
};

export default AdminNavbar;
