import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import { Input } from "stories/forms";
import { useStoreState  } from 'easy-peasy';
import { SelectMulti } from "stories/forms";
import { Row, Col } from "stories/layout";
import { useAppContext }from "contexts/AppContext"
import Select from 'react-select';
import { sub } from "date-fns";

// import { on } from "gulp";


// const InvestmentTypeCateSelect = () => {
const AllSecuritySelect = ({ label,async=true, name, options=[],loadingOptions, multiple=false,placeholder, optiodisabled,ratesView=true, showAll=false,onChange,setFieldValueReturnId=false,style, handleResolve, setFieldValue,validateForm, showAllOption, value, ...rest}) => {
  const { t } = useTranslation();

  const getInputOptions = useCallback(() => {
    let topOptions = []

    if (showAllOption) {
      topOptions.push({value: "select", label: t("Valitse...")})
    }

    const options_list = options && options.map(obj => {
        return {value:obj?.id, label:`${obj?.name} - ${obj?.symbol}:${obj?.stock_market? obj?.stock_market : ""}`}
      })

    return [
    ...topOptions,
    ...options_list
    ] 
  }, [options])

  const setLocalFieldValue = (name, obj) => {

    if (setFieldValueReturnId === true) {
      setFieldValue(name, obj?.id)
    }
    else {
      setFieldValue(name, obj)
    }
    
    if (handleResolve !== undefined) {
      handleResolve({ id: obj?.id, name: obj?.label ? obj?.label : obj?.name,  })
    }

  }


  const getSelectedOptions = useCallback(() => {
    if (multiple === false) {
      return [value];
    }
    return value && value.map(obj => {
      return {value: obj.id, label: obj.value}
    })
  }, [value])

  // const getOptions = useCallback(() => {

  //   return portfolios && portfolios.map(obj => {
  //     return {value: obj.id, label: obj.name}
  //   })
  // }, [portfolios])

    

    return (

      <SelectMulti
             async={async}
             isMulti={false}
             label={label ? label : t("all_security_select_label", "Hae")}
             name={name}
             value={value}
             setFieldValue={setLocalFieldValue}
             defaultValue={getSelectedOptions()}
             options={getInputOptions()}
             // showAllOption={showAllOption} 
             validateForm={validateForm}
             placeholder={placeholder}
             ratesView={ratesView}
             // disabled={disabled}
             multiple={multiple}
             //  style={style}
             {...rest}
             loading={loadingOptions}
             />




   )
 

  
  
}

export default AllSecuritySelect;