//Create simple react Component

import React, { useState, useCallback, useEffect } from "react";
import { Button, Typography, Link, Spinner, Modal } from "stories/components";

import { useTranslation } from 'react-i18next';
import { Container, Row, Col, FormContainer, Card, CardBody } from "stories/layout";
import PortfolioSelectStore from "components/Forms/PortfolioSelect/PortfolioSelectStore"

import PurchaseEdit from "views/app/PurchaseEdit/PurchaseEdit"



const NewEvent = (props) => {
    const { t } = useTranslation();

    const handleCancel = () => {
        props.handleReject()
    }

     // Modal: New/Edit Purchase
  
    const [modalEditPurchaseOpen, setModalEditPurchaseOpen] = useState(false)
    const [modalEditPurchaseId, setModalEditPurchaseId] = useState()
    const [investmentType, setInvestmentType] = useState("")
    const [tickerSymbol, setTickerSymbol] = useState('')
    const [filters, setFilters] = useState({})
    
    const toggleModalEditPurchase = () => {
        setModalEditPurchaseOpen(s => !s);
    }
    
    const openModalEditPurchase = (id, related_model_type, ticker_symbol) => {
        setModalEditPurchaseOpen(true)
        setModalEditPurchaseId(id);
        setInvestmentType(related_model_type)
        setTickerSymbol(ticker_symbol)
    
    }
    
    const modalEditPurchaseResolve = () => {
        console.log("modalEditPurchaseResolve")
        setModalEditPurchaseOpen(false);
        setModalEditPurchaseId(null);
        handleCancel()
        
        // getTransactions()
    }
    
    const modalEditPurchaseReject = () => {
        setModalEditPurchaseOpen(false);
        setModalEditPurchaseId(null);
        handleCancel()


    }
    
    return (
        <Container>
            <Row className="border-bottom py-2">
                <Col className="mt-1">
                <h2  className="text-dark" >{t("New event","Uusi tapahtuma")}</h2>
                </Col>
                <Col xs={12} md={6} className="ml-auto">
                    <PortfolioSelectStore icon="fas fa-suitcase" iconColor="text-secondary" disabled={true}/>
                </Col>
            </Row>
            <Row>
                <Col className="py-2">
    
                    <div className="rounded bg-danger mb-3">
                        <Link
                            // to={{pathname:"/dashboard/transactions", state: {newPurchase:true}}}
                            // onClick={() => props.handleResolve()}
                            onClick={() => openModalEditPurchase(null, "XHEL", null)}
                        >
                        <Typography variant="h3" className="text-white p-3 text-center">{t("New purchase","Kirjaa uusi osto")}</Typography>
                        </Link>
                    </div>
                    <div className="rounded bg-success mb-3">
                        <Link
                            to={{pathname:"/dashboard/transactions", state: {newSale:true}}}
                            onClick={() => props.handleResolve()}
                        >
                        <Typography variant="h3" className="text-white p-3 text-center">{t("New sale","Kirjaa uusi myynti")}</Typography>
                        </Link>
                    </div>
                    <div className="rounded bg-info mb-3">
                        <Link
                            to={{pathname:"/dashboard/transactions", state: {newExchange:true}}}
                            onClick={() => props.handleResolve()}
                        >
                        <Typography variant="h3" className="text-white p-3 text-center">{t("New Exchange","Kirjaa uusi vaihto")}</Typography>
                        </Link>
                    </div>
                    <div className="rounded bg-secondary mb-3">
                        <Link
                            to={{pathname:"/dashboard/private_investment_funds/listed_stock", state: {newSecurity:true}}}
                            onClick={() => props.handleResolve()}
                        >
                        <Typography variant="h3" className="text-white p-3 text-center">{t("New Exchange","Lisää arvopaperi")}</Typography>
                        </Link>
                    </div>

                
                </Col>
            </Row>
            <Row>
                <Col className="mb-3">
                <Button onClick={handleCancel} variant="default">{t("Cancel","Peruuta")}</Button>
                </Col>

            </Row>


            <Modal
                // title={modalEditPurchaseId > 0 ? t("Muokkaa ostokirjausta") : t("Uusi ostokirjaus")}
                isOpen={modalEditPurchaseOpen}
                toggleModal={toggleModalEditPurchase}
                size="modal-xl"
                showHeader={false}
                classNameBody="p-0 padding-0"
            >
                <PurchaseEdit
                objectId={modalEditPurchaseId}
                handleResolve={modalEditPurchaseResolve}
                handleReject={modalEditPurchaseReject}
                defaultStockMarket={"XHEL"}
                defaultInvestmentType={10}
                setFilters={setFilters}
                // transaction_count = {count}
                // portfoliosSummary={portfoliosSummary}
                />
            </Modal>
        </Container>
    )
}

export default NewEvent;
