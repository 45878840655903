import  { React, useState, useEffect } from 'react';

import { Line, Bar } from 'react-chartjs-2'
import 'chartjs-adapter-date-fns';
import {Chart as ChartJS} from 'chart.js/auto'
import { useTranslation } from 'react-i18next';
//import useref
import { useRef } from 'react';





const LineChart = ({yAxes="updated_price", xAxes="date", ...props}) =>  {
  // console.log(props.chartData)

  // let options = { ...props.options}
  // options.scales.x.time.unit = props.unit
  // if (props.options.scales.x.time.unit === 'day' && props.options.scales.x.time.max - props.options.scales.x.time.min > 30) {
  //     props.options.scales.x.time.unit = 'month'
  // }

  const { t } = useTranslation();

  const [isReady, setReady] = useState(false)
  const [testData, setTestData] =useState()
  const [defaultOptions, setDefaultOptions] =useState()
  const {width, height, dataset, options, chartData, defaultEndDate=false, defaultStartDaysNum="30"} = props


  function hasValues(value, values) {
    // assume that "data" is an array of objects containing the values for each day
    // console.log(value)
    for (let i = chartData?.length-1; i < chartData?.length; i--) {
        if (chartData[i]?.a[xAxes] === value && chartData[i].value !== 0) {
            return true;
        }
    }
    return false;
  }

  const chartRef = useRef(null);

  useEffect(() => {
    // console.log("chartRef", chartRef)
    if (chartRef.current) {
      const chartInstance = chartRef.current.chartInstance;
      chartInstance.data.datasets.forEach(dataset => {
          dataset.data = dataset.data.filter(data => {
              return chartData.some(d => {
                  return d.x === data.x;
              });
          });
      });
    }
  }, [chartRef]);
  
  
  const secondOp = {
    scales: {
      x: {
          type: 'time',
          time: {
              unit: 'day',
              displayFormats: {
                  day: 'MMM D'
              },

          },
          gridLines: {
              display: false
          },
          ticks: {

              stepSize: 1,
              callback: function(value, index, values) {
                  // check if the day has any values
                  if (hasValues(value)) {
                      return value;
                  }
                  // if the day does not have any values, return an empty string
                  return '';
              }
          },
          distribution: 'series'
      }
  }
  }




  // console.log(props.objects, yAxes, xAxes)
  useEffect(() => {



    setTestData( {
     // labels: [new Date('2022-11-1'), new Date('2022-11-2'), new Date('2022-11-3'),  new Date('2022-11-4')],
      datasets: [{
        label: 'Test dataset',
        data: [
          // {x:Date.parse('2022-11-1 00:00:01 GMT+0200'), y: 23},
          // {x:Date.parse('2022-11-2 00:00:00 GMT+0200'), y: 23},
          {x:Date.parse(new Date().setHours(0,0,0,)), y: 23},

        ],
        fill: false,
        borderColor: 'rgba(54, 162, 235,1)',
        backgroundColor: 'rgba(54, 162, 235, 0.7)',
        color: 'rgba(54, 162, 235, 0.3)',
        borderWidth: 2,
        borderRadius: 3,
      }]
    }
    )

    setDefaultOptions(
       {
        maintainAspectRatio: false,
        responsive: true,
        layout: {
          padding: {
            bottom: -20,
            left: 0,
            right: 0,
            top: -20
          }
        },
        scales: {
            x: {
                type: 'linear', 
                time: {
                  unit: 'day', // 'day', 'week', 'month', 'quarter', 'year'
                  stepSize: 1
                },

                grid: {
                  drawBorder: false, // <-- this removes the border under the chart
                  drawOnChartArea: true, // IDON'T KNOW WHAT THIS DOES
                  display: true, // <-- this removes the grid lines
                  lineWidth: 0 // <-- this removes vertical lines between bars
                },
                bounds: 'data',
                ticks: {
                  // maxTicksLimit: 7, // 

                  display: false, //this removed the labels on the x-axis
                  stepSize: 1,
                  source: 'data',
                  // callback: (value, index, values) => {
                  //   // check if the day has any 
                  //   console.log("value", value)
                  //   if (hasValues(value)) {
                  //     return value;
                  //   }
                  //   // if the day does not have any values, return an empty string
                  //   return '';
                  // }
                },
                distribution: 'series',


            },
            y: {
              beginAtZero: false,
              display: false,
              grid: {
                drawBorder:false,
                // lineWidth: 0,
                lineWidth: function (context) {
                  return context?.index === 0 ? 0 : 1; // <-- this removes the base line
                }
              }
            }
      },
        elements: {
          point: {
            radius: 0
          }
        },

        plugins: {
          onElementsCreated: function(chart) {
            // Your custom logic here
            // console.log("onElementsCreated", chart);
          },
          tooltip: {
            enabled: false,
          },
          afterUpdate: function (chart) {
            // Your custom logic here
            // console.log("afterUpdate", chart)
          },
          title: {
            display: true,
          },
          legend: {
              display: false,
              labels: {
                  // This more specific font property overrides the global property
                  
                  font: {
                      size: 16
                  }
              }
          },
        }
      }
    )
    setReady(true)
}, [])
    


    //return list of days between two dates
    const getDaysBetweenDates = (startDate, endDate) => {
        // console.log(startDate, endDate)
        startDate = new Date(startDate).setHours(0,0,0)
        endDate = new Date(endDate).setHours(0,0,0)

        const dates = []
        const theDate = new Date(startDate)
        while (theDate < endDate) {
            dates.push(new Date(theDate))
            theDate.setDate(theDate.getDate() + 1)
        }
        dates.push(new Date(endDate))

        //convert dates to timestamp and convert milliseconds to 0

        for (let i in dates){
          dates[i] = new Date(dates[i]).getTime()
          //convert four latest digits to 0
          dates[i] = dates[i] - (dates[i] % 1000)

        
        }




        // console.log(dates)
        return dates
        // labelTimeDate = new Date(labelTimeDate).setHours(0,0,0)
    }

    const getLabelAvarageValue = (propValues, labels) => {
        //Calculate avarage value on every label and return list [ {x: timestamp, y: value}, ...]
        
        //deep copy of propValues
        let values = JSON.parse(JSON.stringify(propValues))
        // console.log(values)

        //check if values includes key stock_date 
        // if (values[0]?.stock_date){
        //   for (let i in values){
        //   values[i].date = values[i].stock_date //TODO HOW TO KNOW AUTOMATICALLY WHICH KEY IS THE RIGHT DATE KEY
        //   }
        // }

  
        //convert "date" value to  timestamp
        for (let i in values){
          // console.log(values[i].date)
          values[i][xAxes] = new Date(values[i][xAxes]).getTime()
        }
  
        //Short values list based on Date, latest last...  [n-1]
        values.sort((x, y) => {
          return x[xAxes] - y[xAxes];
        })
  
        
        // loop and count avg per label.
        let startIndex = values.length -1
        const data = []
  
        for (let i = labels.length -1; i >= 0; i-- ){
         
          //how to get newLabel month
          let month = new Date(labels[i]).getMonth()
          //day
          let day = new Date(labels[i]).getDate()
          const newLabel = month + " " + day


          let sum = 0
          let count = 0
  
          if (values.length > 0) {
            for (let j = startIndex; j >=0; j--){

              
              // console.log(values[j][xAxes], labels[i])
              if (labels.length === 1){
                data.unshift({x: Date.parse(new Date(labels[i])), y:values[j][yAxes]})
                break;
              }

              else if(values[j][xAxes] < labels[i] ){
                startIndex = j
                if(count > 0){
                data.unshift({x:Date.parse(new Date(labels[i])), y:sum/count})
                }
                else{
                  data.unshift({x:Date.parse(new Date(labels[i])), y:sum})
                }
                // console.log("unsift")
                break;
              }
              else if (
                j === 0 && 
                values[j][xAxes] >= labels[i] && 
                // labels.length-1 === i && 
                values[j][xAxes] < labels[i] + (labels[labels.length -1]- labels[labels.length -2]) 
                ){
                //if first value is bigger than first label. 
                
                data.unshift({x: Date.parse(new Date(labels[i])), y:values[j][yAxes]})
                // console.log("unsift",)
                break;
              }
              // else if (j === 0 && values[j][xAxes] === labels[i]){
              //   data.unshift({x:Date.parse(new Date(labels[i])), y:values[j][yAxes]})
              //   console.log("unsift")
              //   break;
              // }
              else if (j ===0) {
                data.unshift({x: Date.parse(new Date(labels[i])), y:null})
                // console.log("unsift")
                break;
              }
              
              else{
                sum += parseFloat(values[j][yAxes])
                count += 1
              }
            }        

          } 
          else {
            data.unshift({x: Date.parse(new Date(labels[i])), y:null})
            // console.log("unsift")
          }  
          
          // console.log(startIndex)
          // console.log(data) 
        }
        return data
      }
    
    //crate new date variable four days before today
    
    const today = new Date()
    const fourDaysAgo = new Date(today)
    fourDaysAgo.setDate(fourDaysAgo.getDate() - 6)
    //if values chartData is empty, return empty list

    const getDataset = () => {

        let endDate1 = new Date()

       //loop all chartdata datest and update endDate to latest date
        if (defaultEndDate && chartData.length > 0 && new Date(chartData[chartData.length -1][xAxes]) !== endDate1){
          for (let i in chartData){
            chartData[i][xAxes] = new Date(chartData[i][xAxes]).getTime()
          }
    
          //Short chartData list based on Date, latest last...  [n-1]
          chartData.sort((x, y) => {
            return x[xAxes] - y[xAxes];
          })
          endDate1 = new Date(chartData[chartData.length -1][xAxes])
        }

        
        
        const daysAgo = new Date(endDate1)
        daysAgo.setDate(daysAgo.getDate() - defaultStartDaysNum)
        
        //create list of chartData [xAxes] values
        const chartDataLabels = []
        for (let i in chartData){
          if  (chartData[i][xAxes] >= daysAgo){
            chartDataLabels.push(chartData[i][xAxes])
          }
        }


        const labels = getDaysBetweenDates(daysAgo, endDate1)
        const data = getLabelAvarageValue(chartData, chartDataLabels)
        // console.log(data)
        const datasets = {
            datasets:[{
              label: "Nimi tähän",
              data: data,
              fill: false,
              tension: 0.1,
              // borderColor: 'rgba(253, 205, 63, 1)',
              // backgroundColor: 'rgba(253, 205, 63, 0.7)',
              // color: 'rgba(253, 205, 63, 0.3)',
              borderColor: 'rgba(253, 205, 63, 1)',
              backgroundColor: 'rgba(253, 205, 63, 0.7)',
              color: 'rgba(253, 205, 63, 0.3)',
              borderWidth: 2,
              borderRadius: 3,
            }]
          }
        return datasets
    }

    //recurively update defaultOptions with props.options only if key exists in defaultOptions
    const updateOptions = (defaultOptions, propsOptions) => {
      for (let key in propsOptions){
        if (defaultOptions.hasOwnProperty(key)){
          if (typeof propsOptions[key] === "object"){
            updateOptions(defaultOptions[key], propsOptions[key])
          }
          else{
            defaultOptions[key] = propsOptions[key]
          }
        }
      }

      return defaultOptions
    }


    const getOptions = () => {
      return updateOptions(defaultOptions, props.options)
    }


    if (!chartData || isReady === false || chartData.length === 0){

        return(
            <div>
               {t("")}
            </div>
        )
    }

    

    return(
 
            <Line 
              style={options?.responsive ? { width: "100% !important", height: "auto !important" } : null} 
              width={width} 
              height={height} 
              data={getDataset()} 
              options={getOptions()}
              onElementsCreated={(chart) => {
                // Your custom logic here
                // console.log(chart)
              }}
              ref={chartRef}
            />
        
   
    )
}

export default LineChart;