import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import { Input } from "stories/forms";
import { useStoreState  } from 'easy-peasy';
import { SelectMulti } from "stories/forms";

const PortfolioSelect = ({ label, name="portfolio_select",multiple=false,placeholder,disabled, showAll=false, onChange, setFieldValue,validateForm, showAllOption, value, ...rest}) => {
  const { t } = useTranslation();
  const portfolios = useStoreState((state) => state.portfolio?.portfolios);
  
  const getInputOptions = useCallback(() => {
    return portfolios && portfolios.map(obj => {
      return (

        <option key={obj.id} value={obj.id}>{obj.name} ({obj?.currency_label})</option>
      )
    })
  }, [portfolios, value])


  const getSelectedOptions = useCallback(() => {
    if (multiple === false) {
      return [value];
    }
    return value && value.map(obj => {
      return {value: obj.id, label: obj.value}
    })
  }, [value,])

  const getOptions = useCallback(() => {

    return portfolios && portfolios.map(obj => {
      return {value: obj.id, label: obj.name}
    })
  }, [portfolios])


  const getSelect = useCallback(() => {
    return(
      <>
      {multiple
        ? <SelectMulti
        isMulti={true}
        label={label ? label : t("portfolio_select_label", "Salkku")}
        name={name}
        value={value}
        setFieldValue={setFieldValue}
        defaultValues={getSelectedOptions()}
        options={getOptions()}
        showAllOption={showAllOption} 
        validateForm={validateForm}
        placeholder={placeholder}
        disabled={disabled}
        {...rest}
        />
        : <Input 
        label={label ? label : t("portfolio_select_label", "Salkku")} 
        type="select" 
        name={name} 
        onChange={onChange} 
        value={value?value:localStorage.getItem("portfolio")} 
        {...rest}
        multiple={multiple}
        disabled={disabled}
        >
        {showAll ? <option value="" label={t("Kaikki")}></option>: null}
        {getInputOptions()}
        </Input>
      }
      </>
      )
  }, [portfolios, value, multiple, showAllOption, validateForm, placeholder, disabled, rest])

  return (
    <>
       {/* {JSON.stringify(value)} */}
      <>
      {
        
        getSelect()
      }
      </>
     
    </>
  )


  
  
}

export default PortfolioSelect;


// isMulti={isMulti} 
// label={t("home_type", "Home Type")} 
// name={name} 
// value={value} 
// setFieldValue={setFieldValue} 
// defaultValues={getSelectedOptions()} 
// options={getOptions()} 
// validateForm={validateForm}