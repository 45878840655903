import React, { useState, useCallback, useEffect } from "react";
import { withFormik } from 'formik';
import * as Yup from "yup";
import moment from "moment"
import { useTranslation } from 'react-i18next';
import { useStoreState, useStoreActions } from 'easy-peasy';

import { Button, Typography, Spinner, Popover } from "stories/components";
import { Container, Row, Col, FormContainer, Card, CardBody } from "stories/layout";
import { Checkbox, Input, SelectMulti, Toggle, Radio } from "stories/forms";
import { useNotification } from "stories/components/Notification"
import { useAppContext } from "contexts/AppContext"
import { round,  roundScaleDecimals, thousandFormat } from "services/helpers"
import { TableAuto } from "stories/tables"
import SplitCheckbox from "components/Forms/SplitCheckbox/SplitCheckbox"

import { InputGroup, InputGroupAddon, InputGroupText, Input as ReacInput } from 'reactstrap';
import { 
  httpArrayCreateDividendTransaction
} from "services/transactions"
import { set } from "date-fns";
import { ro } from "date-fns/locale";




const defaultValues = {
//   id: null,
//   name: "",
}

const SplitTransactionEdit = (props) => {
  const { getPortfolioName, getUserTypeName, getTransactionType } = useAppContext()

  
  const {
     values,
     touched,
     setTouched,
     errors,
     setErrors,
     handleChange,
     handleBlur,
     handleSubmit,
     setFieldValue,
     isValid,
     isTouched,
     isSubmitting,
     validateForm,
     preSetValues
   } = props;
   
   const {
      t,
      history,
      object,
      dataObject,
      dataObjectArray,
      selectedPortfolios,
      setSelectedPortfolios,
      cutOffYear,
      setCutOffYear,
      usePurchaseCostAssumption,
      setUsePurchaseCostAssumption,
      purchaseCostAssumptionCutOfYear,
      setPurchaseCostAssumptionCutOfYear,
      purchaseCostAssumption,
      setPurchaseCostAssumption,

   } = props;
   
   const getFormikInputProps = useCallback(
    name => ({
      name,
      value: values[name],
      error: Boolean(touched[name] && errors[name]),
      errorText: touched[name] ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange
    }),
    [values, errors, touched, handleBlur, handleChange]
  );
  
  const onSubmit = (e) => {
    //convert data to list




    e.preventDefault();
    validateForm().then(res => {
      if (Object.keys(res).length === 0) {
        handleSubmit()
      }
      else {
        //setTouched(res);
        setErrors({
          ...res,
          general: t("Please check the form errors", "Tarkista lomakkeen virheet")
        })
      }
    })
  }
  
  const handleCancel = () => {
    props.handleReject()
  }

  const [data, setData] = useState()
  const [portfolioDataIndex, setPortfolioDataIndex] = useState({})

  useEffect(() => { 
    if (object) {
      const dataObject = object.reduce((acc, curr, index) => {

        const id = curr?.batch?.id;

        const key = "rowId_" + id ;
        const value = {
          id: id,
          // new_quantity: roundScaleDecimals((curr?.batch?.batch_balance* curr?.dividends?.rate),6),
          new_quantity:usePurchaseCostAssumption? roundScaleDecimals((curr?.batch?.batch_balance* portfolioDataIndex[curr?.batch?.portfolio]?.['used']) * (100- purchaseCostAssumption) / 100 , 4) : roundScaleDecimals((curr?.batch?.batch_balance* portfolioDataIndex[curr?.batch?.portfolio]?.['used']), 4) ,
          all_value:roundScaleDecimals((curr?.batch?.batch_balance* curr?.dividends?.rate), 6),
          tax:usePurchaseCostAssumption? roundScaleDecimals((curr?.batch?.batch_balance* curr?.dividends?.rate) * (purchaseCostAssumption/100), 6): 0,
          portfolio: curr?.batch?.portfolio,
          original_batch_balance: curr?.batch?.original_batch_balance,
          price: curr?.batch?.price,
          value_total: curr?.batch?.value_total,
          batch_balance: curr?.batch?.batch_balance,
          rate: roundScaleDecimals(curr?.dividends?.rate,4),
          
          // portfolio: curr?.batch?.portfolio,
          // id: id,
          // new_quantity: Number(curr?.batch?.batch_balance)* Number(curr?.splits?.dividend),
        };
        return { ...acc, [key]: value };
      }, {});
      setData(dataObject);
      if (data) {
        const data1 = Object.values(dataObject).map(item => {
          return {
            ...item,
            id: item.id,
            all_value: item.all_value,
            tax: item.tax,
            new_quantity: item.new_quantity,
            rate: item.rate,

          }
        })
        setFieldValue("data", data1)
      }
      // setFieldValue("data", dataObject)
      
    }
  }, [object, usePurchaseCostAssumption, purchaseCostAssumption, purchaseCostAssumptionCutOfYear, cutOffYear, portfolioDataIndex]);

  useEffect(() => {
    if (data) {
      const data1 = Object.values(data).map(item => {
        return {
          ...item,
          id: item.id,
          all_value: item.all_value,
          tax: item.tax,
          new_quantity: item.new_quantity,
          rate: item.rate,
        }
      })
      setFieldValue("data", data1)
    }
    // setFieldValue("data", data)

  }, [data])
  

  useEffect(() => {

    // setFieldValue("data", data)
    const data1 = object;
    const organizedData = {};
    if (data1 === undefined) return null;


    // Iterate over the original data crouping by the split id
    for (const item of data1) {
      const portfolioId = item.batch.portfolio;
      
      if (organizedData[portfolioId]) {
        organizedData[portfolioId].push(item);
      } else {
        organizedData[portfolioId] = [item];
      }
    }

    const organizedDataArray = Object.values(organizedData);
    let port_idx = {}
    //loop organizeddatarray
     for (let i = 0; i < organizedDataArray.length; i++) {
        console.log(organizedDataArray[i]?.[0])
        port_idx[organizedDataArray[i]?.[0]?.batch.portfolio] = {default: null, used: null}
        port_idx[organizedDataArray[i]?.[0]?.batch.portfolio]['default'] = roundScaleDecimals(organizedDataArray[i]?.[0]?.dividends?.rate, 4)
        port_idx[organizedDataArray[i]?.[0]?.batch.portfolio]['used'] = roundScaleDecimals(organizedDataArray[i]?.[0]?.dividends?.rate, 4)
    }
    setPortfolioDataIndex(port_idx)

  }, [])




  const headers = [
    { label: t("unmade_dividend_transactions_list_table_portfolio", "Salkku"), key: "portfolio"},
    { label: t("unmade_dividend_transactions_list_table_batch", "Erä id"), key: "batch"},
    { label: t("unmade_dividend_transactions_list_table_instrument_name", "Arvopaperi"), key: "instrument_name"},
    // { label: t("unmade_dividend_transactions_list_table_type", "Tyyppi"), key: "transaction_type"},
    { label: t("unmade_dividend_transactions_list_table_batch_purchase_date", "Hank. pvm"), key: "batch_purchase_date"},
    { label: t("unmade_dividend_transactions_list_table_batch_settlement_date", "Selv. pvm"), key: "batch_settlement_date"},
    { label: t("unmade_dividend_transactions_list_table_batch_balance", "Salkussa"), key: "batch_balance"},

    // { label: t("unmade_dividend_transactions_list_table_batch_price", "hinta kpl"), key: "price"},
    // { label: t("unmade_dividend_transactions_list_table_dividend_id", "Osin. id"), key: "dividend_id"},
    { label: t("unmade_dividend_transactions_list_table_ex_date", "Irtoamispäivä"), key: "ex_date"},
    { label: t("unmade_dividend_transactions_list_table_pay_date", "Maks. pvm"), key: "pay_date"},
    { label: t("unmade_dividend_transactions_list_table_dividend", "Osinko"), key: "rate"},
    { label: t("unmade_dividend_transactions_list_table_currency", "Val."), key: "currency_label"},
    { label: t("unmade_dividend_transactions_list_table_total_dividend", "Osinko Yht."), key: "all_value"},
    { label: t("unmade_dividend_transactions_list_table_tax", "Vero"), key: "tax"},
    { label: t("unmade_dividend_transactions_list_table_net", "Netto"), key: "new_quantity"},

  { label: t("portfolios_list_actions", " "), key: "actions"},
];
  
  // const handleDelete = () => {
  //   httpDeletePortfolio(object.id).then(() => {
  //     props.handleResolve()
  //   })
  // }

  const calculateNewValues = () => {

    let newBalance = object?.batch?.batch_balance * values?.split_dividend
    let newPrice = object?.batch?.price / values?.split_dividend
    setFieldValue("new_batch_balance", newBalance)
    setFieldValue("new_price", roundScaleDecimals(newPrice, 6))
  }

  const returnOrignalValue = (row) => {

    const id =  row?.batch?.id;

    setData(s => ({
        ...s,
        [`rowId_${id}`]: {
            ...s[`rowId_${id}`],
      
            "new_quantity":usePurchaseCostAssumption? roundScaleDecimals(Number(row?.batch?.batch_balance)* portfolioDataIndex[row?.batch?.portfolio]?.['used'] * (100- purchaseCostAssumption) / 100, 4) : roundScaleDecimals(Number(row?.batch?.batch_balance)* portfolioDataIndex[row?.batch?.portfolio]?.['used'], 4),
            "all_value": roundScaleDecimals(Number(row?.batch?.batch_balance)* Number(row?.dividends?.rate), 6),
            "tax":usePurchaseCostAssumption? roundScaleDecimals(Number(row?.batch?.batch_balance)* Number(row?.dividends?.rate) * (purchaseCostAssumption/100), 6): 0,
            "rate": row?.dividends?.rate,

          //new_quantity:usePurchaseCostAssumption? roundScaleDecimals((curr?.batch?.batch_balance* curr?.dividends?.rate) * (100- purchaseCostAssumption) / 100 , 6) : roundScaleDecimals((curr?.batch?.batch_balance* curr?.dividends?.rate), 6) ,

        }
    }))
}

const returnOrignalValueDividend = (portfolio_id) => {
  console.log("portfolio_id", portfolio_id)
  setPortfolioDataIndex(s => ({
    ...s,
    [portfolio_id]: {...s[portfolio_id], "used" :portfolioDataIndex[portfolio_id]?.['default']}
  }))
}



const handlePortfolioDividendChange = ({target}, portfolio_id) => {
  console.log("target.value", target.value)
  console.log(portfolio_id)
  setPortfolioDataIndex(s => ({
    ...s,
    [portfolio_id]: {...s[portfolio_id], "used" :target.value}
  }))
}



  const handleInputChange = ({target}, rowId) => {
      if (target.name == "rate") {
        const new_quantity = roundScaleDecimals(Number(getValue(rowId, "batch_balance")) * Number(target.value), 6)
        setData(s => ({
          ...s,
          [`rowId_${rowId}`]: {
              ...s[`rowId_${rowId}`],
              [target.name]: target.value,
              "all_value": roundScaleDecimals(Number(getValue(rowId, "batch_balance")) * Number(target.value), 6),
              "tax":usePurchaseCostAssumption? roundScaleDecimals(Number(getValue(rowId, "batch_balance")) * Number(target.value) * (purchaseCostAssumption) / 100, 6): 0,
              "new_quantity":usePurchaseCostAssumption? roundScaleDecimals(Number(getValue(rowId, "batch_balance")) * Number(target.value) * (100- purchaseCostAssumption) / 100, 4) : roundScaleDecimals(Number(getValue(rowId, "batch_balance")) * Number(target.value), 4),
            }
        }))
      }
      else {

      setData(s => ({
          ...s,
          [`rowId_${rowId}`]: {
              ...s[`rowId_${rowId}`],
              [target.name]: target.value
          }
      }))
    }

  }

  const getValue = (rowId, fieldName, index) => {

      if (data === undefined) return ""
      return data[`rowId_${rowId}`]?.[fieldName]
  }

  const getNewQuantity = useCallback((row, summary_name, new_quantity_summary) => {
    return (<Input 
    name="new_quantity" 
    onChange={(e) => handleInputChange(e,  row?.batch?.id,)} 
    value={row.portfolio==summary_name?roundScaleDecimals(new_quantity_summary,2): getValue( row?.batch?.id, "new_quantity")}

    type="number"
    step="0.01"
    onWheel={(e) => e.target.blur()}
    disabled={row.portfolio==summary_name?true:false}
    placeholder={t("unmade_split_transactions_list_netto", t("netto"))}
  />)
  }, [object, data, usePurchaseCostAssumption])


  const getTableRows = useCallback((rows) => {
    let quantity_summary = 0
    let new_quantity_summary = 0
    let all_value_summary = 0
    let tax_summary = 0
    let batch_balance_summary = 0


    for (let i = 0; i < rows.length; i++) {
      quantity_summary += rows[i]?.batch?.batch_balance
      const value = getValue(rows[i]?.batch?.id, "new_quantity")

      new_quantity_summary += Number(value)
      batch_balance_summary += Number(rows[i]?.batch?.batch_balance)

      all_value_summary += Number(rows[i]?.batch?.batch_balance* rows[i]?.dividends?.rate)
      tax_summary += Number(rows[i]?.batch?.batch_balance* rows[i]?.dividends?.rate) * (purchaseCostAssumption/100)
    }
    
    const summary_name = t("unmade_dividend_transactions_list_summary", "Yhteensä")

    rows.push({
      portfolio: summary_name,
      batch_balance: roundScaleDecimals(quantity_summary,2),
      new_quantity: roundScaleDecimals(new_quantity_summary, 6),
      splits: {dividend: rows?.[0].splits?.dividend},
      dividends: {rate: portfolioDataIndex[rows?.[0]?.batch?.portfolio]?.['used']},
      batch:{related_model:{name: rows?.[0].batch?.related_model?.name}, batch_balance: batch_balance_summary},
    })

    return rows && rows.map((row, index) => {
      let batch_balance = Number(row?.batch?.batch_balance)
      let row_rate = Number(row?.dividends?.rate)
      if (row.portfolio!=summary_name) {
        row_rate = portfolioDataIndex[row?.batch?.portfolio]?.['used']
      }

      return {
        ...row,

        batch: row?.batch?.id,
        // all_value: row.portfolio==summary_name?null: roundScaleDecimals(row?.batch?.all_value,6),
        // tax: row.portfolio==summary_name?null: thousandFormat(roundScaleDecimals((row?.batch?.batch_balance* row?.dividends?.rate) * (purchaseCostAssumption) / 100 , 2)),
        // tax: thousandFormat(roundScaleDecimals((row?.batch?.batch_balance* row?.dividends?.rate) - getValue(row?.batch?.batch_id? row?.batch?.batch_id: row?.batch?.id, "new_quantity"), 2)),
        all_value: {
          content: thousandFormat(roundScaleDecimals((row?.batch?.batch_balance*  row_rate), 2)) ,
          className: "text-right"
        },
        tax:usePurchaseCostAssumption? {
          content:thousandFormat(roundScaleDecimals((row?.batch?.batch_balance* row_rate) * (purchaseCostAssumption) / 100 , 2)) ,
          className: "text-right"
        }: null,

        portfolio: row.portfolio==summary_name?<><Typography variant="h5" className="text-dark">{row.portfolio}</Typography></>: getPortfolioName(row?.batch?.portfolio),
        instrument_name:row?.batch?.related_model?.name,
        batch_balance: {
          content: row.portfolio==summary_name?thousandFormat(roundScaleDecimals(quantity_summary,2)): thousandFormat(roundScaleDecimals(batch_balance,2)),
          className: "text-right"
        },

        price: row.portfolio==summary_name?null: thousandFormat(roundScaleDecimals(Math.abs(row?.batch?.price), 3)),
        rate: row.portfolio==summary_name?null:portfolioDataIndex[row?.batch?.portfolio]?.['used'],
          // roundScaleDecimals(row?.dividends?.rate,4),
        currency_label: row?.dividends?.currency_label,
        dividend_id: row?.dividends?.id,
        transaction_type: t("transactionTypeValue_2", getTransactionType(2).value), 

        ex_date: row.portfolio==summary_name?null: moment(row?.dividends?.ex_date).format('DD.MM.YYYY'),
        pay_date: row.portfolio==summary_name?null: moment(row?.dividends?.pay_date).format('DD.MM.YYYY'),
        batch_purchase_date:row.portfolio==summary_name?null: moment(row?.batch?.batch_purchase_date).format('DD.MM.YYYY'),
        batch_settlement_date:row.portfolio==summary_name?null: row?.batch?.batch_settlement_date ? moment(row?.batch?.batch_settlement_date).format('DD.MM.YYYY'): null,
        new_quantity: (
          <Input 
            name="new_quantity" 
            onChange={(e) => handleInputChange(e, row?.batch?.id,)} 
            value={row.portfolio==summary_name?roundScaleDecimals(new_quantity_summary,2): getValue(row?.batch?.id, "new_quantity")}

            // value={row.portfolio==summary_name?roundScaleDecimals(new_quantity_summary,2): getValue(row?.batch?.batch_id? row?.batch?.batch_id: row?.batch?.id, "new_quantity")}
            type="number"
            step="0.01"
            onWheel={(e) => e.target.blur()}
            disabled={row.portfolio==summary_name?true:false}
            placeholder={t("unmade_split_transactions_list_new_quantity", t("netto"))}
          />
        ),
        actions: row.portfolio!==summary_name && (
          <div className="d-flex justify-content-end">

            <Button className="btn-sm " onClick={() => returnOrignalValue(row)} color="secondary" >{t("Palauta")}</Button>
          </div>
        ),
      }
    })
  }, [object, data, usePurchaseCostAssumption, portfolioDataIndex])


  const handleCheckboxChange = (portfolioId) => {
    console.log("portfolioId", portfolioId)
    // if (selectedPortfolios.includes(portfolioId)) {
    //   setSelectedPortfolios(selectedPortfolios.filter((id) => id !== portfolioId))
    // } else {
    //   setSelectedPortfolios([...selectedPortfolios, portfolioId])
    // }
  };


  const getPortfolioTables = useCallback(() => {
    const data1 = object;
    const organizedData = {};
    if (data1 === undefined) return null;


    // Iterate over the original data crouping by the split id
    for (const item of data1) {
      const portfolioId = item.batch.portfolio;
      
      if (organizedData[portfolioId]) {
        organizedData[portfolioId].push(item);
      } else {
        organizedData[portfolioId] = [item];
      }
    }

    const organizedDataArray = Object.values(organizedData);

    //loop organizeddatarray
    // for (let i = 0; i < organizedDataArray.length; i++) {
    //   portfolioDataIndex[organizedDataArray[i]?.[0]?.batch.portfolio] = organizedDataArray[i]?.[0]?.dividends?.rate
    // }


    
    return organizedDataArray.map((portfolioData, index) => {


      return( 
        <>
         {/* {JSON.stringify(portfolioDataIndex)}
         {JSON.stringify(index)} */}
         {/* {JSON.stringify(object)} */}

         <Container fluid className="custom-no-padding bg-lighter mt-3 m-0 mr-0 px-0 mb-0" style={{paddingLeft:"0px !important", paddingRight: "0px !important"}}>
          <Row className=" m-0 p-0">
              
            <Col className="m-0 p-0">
              <div className="d-flex flex-wrap ">
                <div className="d-flex justify-content-start align-items-center py-2 pr-2 mr-3" style={{minWidth: '150px'}}>
                  <Typography variant="medium" className=" mr-2 text-dark">{t("unmade_dividend_transactions_list_dividend","Määrä")}</Typography>
                  <Input 
                    name="rate3" 
                    onChange={(e) => handlePortfolioDividendChange(e, portfolioData?.[0]?.batch.portfolio)} 

                    value={portfolioDataIndex[portfolioData?.[0]?.batch.portfolio]?.['used']}
                    // defaultValues={portfolioData?.[0]?.dividends?.rate}
                    type="number"
                    step="0.0001"
                    addonTypeClass="append"
                    iconColor={"bg-light"}
                    inputText={portfolioData?.[0]?.dividends?.currency_label}
                    onWheel={(e) => e.target.blur()}
                    // disabled={row.portfolio==summary_name?true:false}
                    placeholder={t("unmade_split_transactions_list_dividend", t("osinko"))}
                  />
                </div>
         

                <div className="d-flex justify-content-end align-items-center  py-2 pr-2 ml-auto" style={{minWidth: '150px'}}>

                      <Button className="btn-sm " onClick={() => returnOrignalValueDividend(portfolioData?.[0]?.batch.portfolio)} color="secondary" >{t("Palauta")}</Button>
                </div>

           
              </div>
            </Col>
          </Row>
          </Container>

        <TableAuto
        showRowNumber={false}
        showId={false}
        checkboxes={false}
        headers={headers}
        headerColor={"light"}
        rows={getTableRows(portfolioData)}
        // loading={loading}
        pagination={false}
        // paginationPrevious={paginationPrevious}
        // paginationNext={paginationNext}
        // paginationOffset={filters?.offset}
        // paginationLimit={filters?.limit}
        // paginationCount={count}
        // setFilters={setFilters}
        className="mb-2"
      />
      <div  className="mb-5">
        
        <SplitCheckbox portfolio={portfolioData?.[0]?.batch.portfolio} label={t("real_estae_dividend_transactions_group_create_pass_dividend", 'Ohita tämän salkun osalta')} selectedPortfolios={selectedPortfolios} setSelectedPortfolios={setSelectedPortfolios} />
      </div>
      </>
      )
   
    })
  }, [object, data, portfolioDataIndex])

  const getErrorMessages = (errorsData) => {
    return errorsData && errorsData.map((error, index) => {
      return (
        <><Typography key={index} className="text-danger">{error?.new_quantity}</Typography></>
      )
    })
  }


  
  return (
    <>
        {/* {JSON.stringify(data)} */}
        {/* {JSON.stringify(object)} */}
        <Row className="mt-4 mb-0">
        <Col xs={12} md={6} className="ml-auto pb-0">
          <div className="d-flex justify-content-start mb-2">
            <i className="fas fa-coins text-warning fa-2x mr-3" />
            <Typography variant="h2">{t("real_estae_dividend_transactions_group_create_title", "Käsittele kirjaukset")}</Typography>
          </div>
        </Col>
        <Col xs={12} md={6} className="ml-auto text-right">
          <Button color="warning" id="info" type="button" size="sm">
            <i className="fas fa-info-circle fa-lg"/>
          </Button>
          <Popover
            placement="top"
            target="info"
          >       
            <Typography className="text-warning mb-1" variant="medium">
                {t("real_estae_dividend_transactions_group_create_info_text", "HUOM! Tee tarvittavat kirjaukset, jotka ajoittuvat aikaan ennnen osingon voimaantuloa, ennenkuin hyväksyt muutokset.")}
            </Typography>  
          </Popover>
        </Col>
        </Row>
        <Container fluid className="custom-no-padding bg-secondary mt-3 m-0 mr-0 px-0 mb-2" style={{paddingLeft:"0px !important", paddingRight: "0px !important"}}>
        <Row className=" m-0 p-0">
            
          <Col className="m-0 p-0">
            <div className="d-flex flex-wrap ">
              <div className="d-flex justify-content-start align-items-center py-2 pr-2 mr-3" style={{minWidth: '150px'}}>
                <Typography variant="medium" className=" mr-2 text-dark">{t("unmade_dividend_transactions_list_use_tax","Käytä ennakonpidätystä")}</Typography>
                <Checkbox 
                id="fifo"
                name="fifo_used"
                checked={usePurchaseCostAssumption}

                onChange={(e) => {setUsePurchaseCostAssumption(e.target.checked)}}
                className="zIndexZero"
                />
              </div>
              {/* <div className="d-flex justify-content-start align-items-center  py-2 pr-2 mr-3" style={{minWidth: '150px'}}>
                <Typography variant="medium" className=" mr-2 text-dark">{t("Korotettu olettama raja")}</Typography>
                <Input label={t(" ")} name="cut_off_year" type="number" value={cutOffYear} onChange={(e) => setCutOffYear(e.target.value)}  style={{maxWidth: '60px'}}/>
                <Typography variant="medium" className=" ml-2  text-dark">{t("v.")}</Typography>
              </div> */}
              <div className="d-flex justify-content-start align-items-center  py-2 pr-2 mr-3" style={{minWidth: '150px'}}>
                <Typography variant="medium" className=" mr-2 text-dark">{t("unmade_dividend_transactions_list_tax","ennankonpidätys")}</Typography>
                <Input 
                  label={t(" ")} 
                  name="purchase_cost_assumption" 
                  type="number" 
                  addonTypeClass="append"
                  iconColor={"bg-light"}
                  inputText={" % "}
                  onWheel={(e) => e.target.blur()}
                  value={purchaseCostAssumption} 
                  onChange={(e) => setPurchaseCostAssumption(e.target.value)}  
                  style={{maxWidth: '60px'}}
                />
                {/* <Typography variant="medium" className=" ml-2  text-dark">{t("%")}</Typography> */}
              </div>

              {/* <div className="d-flex justify-content-start align-items-center  py-2 pr-2 mr-3" style={{minWidth: '150px'}}>
                <Typography variant="medium" className=" mr-2 text-dark">{t(`Olettama kun omistus vähintään ${cutOffYear} vuotta` )}</Typography>
                <Input label={t(" ")} name="cut_off_year_cut_off" type="number" value={purchaseCostAssumptionCutOfYear} onChange={(e) => setPurchaseCostAssumptionCutOfYear(e.target.value)}  style={{minWidth: "50px",maxWidth: '60px'}}/>
                <Typography variant="medium" className=" ml-2 text-dark">{t("%")}</Typography>
              </div> */}
            </div>
          </Col>
        </Row>
        </Container>

        {object && getPortfolioTables()}
              {/* {JSON.stringify(portfolioDataIndex)} */}

      <Row className="mt-4">
        <Col className="mb-3">
          <Button onClick={handleCancel} variant="primary">{t("Close","Sulje")}</Button>
        </Col>
        { values?.id ? (
        <Col className="mb-3 text-center">
         {/*  <Button onClick={handleDelete} disabled={true} variant="danger">{t("Delete","Poista")}</Button>*/}
        </Col>
        ) : null }
        <Col className="mb-3 text-right">


            <Button name="split_submit" variant="secondary" disabled={isSubmitting} onClick={onSubmit}>
              {t("real_estae_dividend_transactions_group_create_submit","Luo kirjaukset")}
            </Button>
          
          { Boolean(errors["general"]) && <Typography className="text-danger">{errors["general"]}</Typography> }
          { Boolean(errors["data"])  && errors['general'] && <>{getErrorMessages(errors['data'])}</> }
        </Col>
      </Row>


    </>
  );
}

const SplitTransactionEditFormik = withFormik({
    enableReinitialize: true,
    validateOnMount: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return {
          ...defaultValues
        }
      }
    },
    validationSchema: props => {
      const {t, dataObjectArray} = props;
      const required = t("The field is required");
      return Yup.object().shape({
        data: Yup.array().of(
          Yup.object().shape({
            id: Yup.number().required(`${t("The field")} ${t("id")} ${t("is required")}`),
            new_quantity: Yup.number().required((value) => {
              console.log(value)
              const data = dataObjectArray
              const [, index] = value.path.match(/data\[(\d+)\]/);
              const item = data[index];
              return `${t(`Erän`)} ${item.id} ${t("uusi määrä on pakollinen")}`;
            }),
          })
        )
      });
    },

    // validationSchema: props => {
    //   const { t } = props;
    //   const required = t('The field is required');
      
    //   // Define the Yup schema for each object in the dictionary
    //   const objectSchema = Yup.object().shape({
    //     id: Yup.number().required(),
    //     new_quantity: Yup.number().min(1).required(),
    //   });
      
    //   // Define the Yup schema for the entire dictionary
    //   const dictionarySchema = Yup.object().shape({
    //     data: Yup.object().shape({
    //       [Yup.string()]: objectSchema,
    //     }),
    //   });
      
    //   return dictionarySchema;
    // },
    

    // validationSchema: props => {
    //   const {t} = props;
    //   const required = t("The field is required");
    //   return Yup.object().shape({
    //     data: Yup.object().shape({
    //   });
    // },

    // validationSchema: props => {
    //   const { t } = props;
    //   const required = t("The field is required");
    
    //   return Yup.object().shape({
    //     data: Yup.object().shape({
    //       "rowId_*": Yup.object().shape({
    //         id: Yup.number().required(required),
    //         new_quantity: Yup.number().min(1, "New quantity must be greater than 0").required(required),
    //       }),
    //     }),
    //   });
    // },

    // validationSchema = Yup.object().shape({
    //   items: Yup.array().of(
    //     Yup.object().shape({
    //       id: Yup.number().required(),
    //       name: Yup.string().required(),
    //       quantity: Yup.number().min(1).required(),
    //     })
    //   )
    // });

    // validationSchema: (props) => {
    //   const { t } = props;
    //   const required = t('The field is required');
  
    //   // Define the Yup schema for the data dictionary
    //   const dataSchema = Yup.object().shape({
    //     [Yup.string()]: Yup.object().shape({
    //       id: Yup.number().required(),
    //       new_quantity: Yup.number().required(),
    //     }),
    //   });
    
    //   // Define the custom validation function for the data dictionary
    //   const validateData = (data) => {
    //     console.log(data)
      
    //     let errors = {};
    //     Object.entries(data).forEach(([key, value]) => {
    //       console.log("value", value)
    //       //check if key is not include "row" string
    //       if (!key.includes('row')) {
    //         return;
    //       }




    //       if (value.new_quantity === 0) {
    //         errors = { ...errors, [key]: 'New quantity cannot be zero' };
    //       }
    //     });
    //     return errors;
    //   };
  
    //   // Return the final schema with the custom validation function
    //   return Yup.object().shape({
    //     data: dataSchema.test('validate-data', required, validateData),
    //   });
    // },




    handleSubmit: (values, { setSubmitting, setErrors, props }) => {
      const {t, portfolio, account, object, selectedPortfolios} = props;
      console.log("account", account);

      
      let data = {
        data: values?.data?.map((item) => {

          let type =  null
          if (object[0].dividends?.type == 1){
            type = 30
          }
          else{
            type = 32
          }

          return {
            ...item,
            id: item?.id,
            // batch_balance: item?.new_quantity,
            batch_balance: 0,
            original_batch_balance: 0,
            quantity: item?.batch_balance,
            date_transaction: object[0].dividends?.pay_date,
            date_settlement: object[0].dividends?.pay_date,
            dividend_instance: object[0]?.dividends?.id,
            price: roundScaleDecimals(object[0]?.dividends?.rate,6),
            price_total: item?.new_quantity,
            value_total: item?.new_quantity,
            related_model_type: object[0]?.batch?.related_model_type,  
            related_model_id: object[0]?.batch?.related_model_id,
            type: type,
            batch_id: item.id,
          }
        }),

        
          
        // commission_total: object?.batch?.commission_total,
        // currency_exchange_rate: object?.batch?.currency_exchange_rate,
        // currency_label: object?.batch?.currency_label,
        // price: values?.price,
        // price_total: values?.price_total,
        // stock: values?.stock_private_obj?.id,
        // stock: values?.stock?.id,
        // stock_market: stockMarket,
        // fifo_used: values?.fifo_used? values?.fifo_used : false,
        // state: values?.state?.key,
        // date_settlement: values?.date_settlement? moment(values?.date_settlement).format() : undefined,
        // quantity: values?.new_batch_balance,
        // portfolio: object?.batch?.portfolio,
        // type: 20, // Split
        // date_transaction: object?.splits?.date_effective,
        // related_model_id: object?.batch?.related_model_id,
        // related_model_type: object?.batch?.related_model_type,
        // value_total: object?.batch?.value_total,
        // batch_id: object?.batch?.batch_id?object?.batch?.batch_id:object?.batch?.id,
        // batch_balance: values?.new_batch_balance,
        // stock_split: object?.splits?.id,
        // original_batch_balance: original_batch_balance,
        // price: values?.new_price,

      };
      data.selectedPortfolios= selectedPortfolios
      
      // if (values?.id) {
      //   // httpUpdatePortfolio(values.id, data).then(res => {
      //   //   setSubmitting(false);
      //   //   props.notify({ title:t("Portfolio"), message:t("Updated")})
      //   //   props.handleResolve()
          
      //   // }, error => {
      //   //   setSubmitting(false);
      //   //   if (error?.data?.detail) {
      //   //     setErrors({
      //   //       general: error?.data?.detail,
      //   //     })
      //   //   }
      //   //   else {
      //   //     setErrors({
      //   //       general: t("Unknown error. Please try again later or contact support.")
      //   //     })
      //   //   }
      //   // })
      // }
      
      httpArrayCreateDividendTransaction(data).then(res => {
          setSubmitting(false);
          props.notify({ title:t("real_estae_dividend_transactions_group_create_added", "Osinko kirjaukset"), message:t("lisätty onnistuneesti")})
          props.handleResolve()
          
        }, error => {
          setSubmitting(false);
          if (error?.data?.detail || error?.data?.general) {
            setErrors({
              general: error?.data?.detail || error?.data?.general,
            })
          }
          else {
            setErrors({
              general: t("Unknown error. Please try again later or contact support.")
            })
          }
        })
      
      
    },
    displayName: "BasicForm"
  
})(SplitTransactionEdit)
    
    
const SplitTransactionEditView = ({history, object, modalOpen, ...rest}) => {
  // API requests here

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [preSetValues, setPreSetValues] = useState();
  const [selectedPortfolios, setSelectedPortfolios] = useState([]);
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  const account = useStoreState((state) => state.account?.account);
  const { getPortfolioName, getUserTypeName } = useAppContext()
  const [usePurchaseCostAssumption, setUsePurchaseCostAssumption] = useState(false);
  const [cutOffYear, setCutOffYear] = useState(10);
  const [purchaseCostAssumptionCutOfYear, setPurchaseCostAssumptionCutOfYear] = useState(40);
  const [purchaseCostAssumption, setPurchaseCostAssumption] = useState(0);


  
  // const getObject = (id) => {
    
  //   setLoading(true)
    
  //   httpGetPortfolio(id).then(res => {
      
  //     setPreSetValues({
  //       ...defaultValues,
  //       ...res?.data,
  //     });
    
  //   }).finally(response => {
  //     setLoading(false);
  //   })
    
  // } 
  let dataArray = []
  let dataObject = {}
  if (object) {
    const dataObject = object.reduce((acc, curr, index) => {

    const id =  curr?.batch?.id;

    const key = "rowId_" + id ;
    const value = {
      id: id,
      new_quantity:usePurchaseCostAssumption? roundScaleDecimals((curr?.batch?.batch_balance* curr?.dividends?.rate) * (100- purchaseCostAssumption) / 100 , 6) : roundScaleDecimals((curr?.batch?.batch_balance* curr?.dividends?.rate), 4) ,
      all_value: roundScaleDecimals((curr?.batch?.batch_balance* curr?.dividends?.rate), 6) ,
      tax:usePurchaseCostAssumption? roundScaleDecimals((curr?.batch?.batch_balance* curr?.dividends?.rate) * (purchaseCostAssumption) / 100 , 6) : 0 ,
      portfolio: curr?.batch?.portfolio,
      original_batch_balance: curr?.batch?.original_batch_balance,
      price: curr?.batch?.price,
      value_total: curr?.batch?.value_total,
      batch_balance: curr?.batch?.batch_balance,
      rate: roundScaleDecimals(curr?.dividends?.rate,4),

    };
    return { ...acc, [key]: value };
  }, {});

  console.log(dataObject)

  //convert dic to array
  dataArray = Object.values(dataObject).map(item => {
    return {
      id: item.id,
      new_quantity: item.new_quantity,
      all_value: item.all_value,
      tax: item.tax,
      portfolio: item.portfolio,
      original_batch_balance: item?.original_batch_balance,
      price: item?.price,
      value_total: item?.value_total,
      batch_balance: item?.batch_balance,
      rate: item?.rate,


    }
   })
  } 


  useEffect (() => {
    if (object) {
      setPreSetValues({
        ...defaultValues,
        // ...dataObject,
        data: dataArray,
        // data: dataObject,
      });
    }
  }, [object])

  
  
  // useEffect(() => {
  //   if (objectId && parseInt(objectId) > -1) {
  //     getObject(objectId)
  //   }
  // }, [objectId, modalOpen])
  
  
  if (loading) {
    return (
      <Container fluid>
        <Spinner />
      </Container>
    )
  }

  return (

    <SplitTransactionEditFormik 
      t={t} 
      history={history} 
      preSetValues={preSetValues} 
      notify={notify} 
      object={object}
      account={account}
      dataObject={dataObject}
      dataObjectArray={dataArray}
      selectedPortfolios={selectedPortfolios}
      setSelectedPortfolios={setSelectedPortfolios}
      usePurchaseCostAssumption={usePurchaseCostAssumption}
      setUsePurchaseCostAssumption={setUsePurchaseCostAssumption}
      purchaseCostAssumptionCutOfYear={purchaseCostAssumptionCutOfYear}
      setPurchaseCostAssumptionCutOfYear={setPurchaseCostAssumptionCutOfYear}
      purchaseCostAssumption={purchaseCostAssumption}
      setPurchaseCostAssumption={setPurchaseCostAssumption}
      cutOffYear={cutOffYear}
      setCutOffYear={setCutOffYear}
      {...rest} 
    />
  )
  
}

export default SplitTransactionEditView;
