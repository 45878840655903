import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import { Input } from "stories/forms";
import { useAppContext }from "contexts/AppContext"

const TimeRangeSelect = ({ label, name="type", onChange, value, ...rest }) => {
  const { t } = useTranslation();
  
  const getInputOptions = useCallback(() => {
    // console.log(new Date(new Date().getFullYear(), new Date().getMonth(), 0).getDate())
    const current_month = new Date().getMonth() + 1
    // console.log(current_month)
    let dividend = current_month % 3 
    dividend = dividend== 0 ?3:dividend
    // console.log(dividend)
    const current_quarter_start = new Date().getMonth() + 1 - dividend

    const timeRanges = [
        {
          key: "month",
          value: t("month", "1 kk"),
          range: {
            start: new Date(
                new Date().getFullYear(), 
                new Date().getMonth()- 1, 
                new Date().getDate() > new Date(new Date().getFullYear(), new Date().getMonth(), 0).getDate()? new Date(new Date().getFullYear(), new Date().getMonth(), 0).getDate(): new Date().getDate()),
            end: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
          },
        },
        {
            key: "two_month",
            value: t("two_month", "2 kk"),
            range: {
              start: new Date(
                  new Date().getFullYear(), 
                  new Date().getMonth()- 2, 
                  new Date().getDate() > new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate()? new Date(new Date().getFullYear(), new Date().getMonth() +1, 0).getDate(): new Date().getDate()),
              end: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
            },
        },
        {
          key: "three_months",
          value: t("three_months", "3 kk"),
          range: {
            start: new Date(
                new Date().getFullYear(), 
                new Date().getMonth()- 3, 
                new Date().getDate() > new Date(new Date().getFullYear(), new Date().getMonth() + 2, 0).getDate()? new Date(new Date().getFullYear(), new Date().getMonth() +2, 0).getDate(): new Date().getDate()),
            end: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
          },
        },
        {
          key: "six_months",
          value: t("six_months", "6 kk"),
          range: {
            start: new Date(
                new Date().getFullYear(), 
                new Date().getMonth()- 6, 
                new Date().getDate() > new Date(new Date().getFullYear(), new Date().getMonth() + 5, 0).getDate()? new Date(new Date().getFullYear(), new Date().getMonth() +5, 0).getDate(): new Date().getDate()),
            end: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
          },
        },
        {
            key: "twelve_months",
            value: t("twelve_months", "12 kk"),
            range: {
              start: new Date(
                  new Date().getFullYear(), 
                  new Date().getMonth()- 12, 
                  new Date().getDate() > new Date(new Date().getFullYear(), new Date().getMonth() + 11, 0).getDate()? new Date(new Date().getFullYear(), new Date().getMonth() +11, 0).getDate(): new Date().getDate()),
              end: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
            },
          },
          {
            key: "current_month",
            value: t("current_month", "Kuluva kuukausi"),
            range: {
              start: new Date(
                  new Date().getFullYear(), 
                  new Date().getMonth(), 
                  1),
              end: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
            },
          },
          {
            key: "current_quarter",
            value: t("current_quarter", "Kuluva kvartaali"),
            range: {
              start: new Date(
                  new Date().getFullYear(), 
                  current_quarter_start, 
                  1),
              end: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
            },
          },
          {
            key: "current_year",
            value: t("current_year", "Kuluva vuosi"),
            range: {
              start: new Date(
                  new Date().getFullYear(), 
                  0, 
                  1),
              end: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
            },
          },
          {
            key: "last_month",
            value: t("last_month", "Viime kuukausi"),
            range: {
              start: new Date(
                  new Date().getFullYear(), 
                  new Date().getMonth() -1, 
                  1),
              end: new Date(new Date().getFullYear(), new Date().getMonth(), 0),
            },
          },
          {
            key: "last_quarter",
            value: t("last_quarter", "Viime kvartaali"),
            range: {
              start: new Date(
                new Date().getFullYear(), 
                current_quarter_start -3, 
                1),
              end: new Date(
                new Date().getFullYear(), 
                current_quarter_start, 
                0),
            },
          },
          {
            key: "last_year",
            value: t("last_year", "Viime vuosi"),
            range: {
              start: new Date(
                  new Date().getFullYear()-1, 
                  0, 
                  1),
              end: new Date(new Date().getFullYear(), 0, 0),
            },
          },
      ];
    return timeRanges.map(obj => {
      return (
        <option key={obj.key} value={JSON.stringify(obj)}>{obj.value}</option>

      )
    })
  }, [])
  
  return (
    <>

    <Input label={label ? label : t("time_range_select_label", "Ajanjakso")} type="select" name={name} onChange={onChange} value={value} {...rest}>
    
    <option value={JSON.stringify({value:"", range:undefined})} label={t("Valitse")}></option>
    {getInputOptions()}
    </Input>
    </>
  )
  
  
}

export default TimeRangeSelect;
