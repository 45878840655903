import React, { useState, useCallback, useEffect } from "react";
import { withFormik } from 'formik';
import * as Yup from "yup";
import moment from "moment"
import { useTranslation } from 'react-i18next';
import { useStoreState, useStoreActions } from 'easy-peasy';

import { Button, Typography, Spinner } from "stories/components";
import { Container, Row, Col, FormContainer, Card, CardBody } from "stories/layout";
import { Input, SelectMulti, Toggle, Radio } from "stories/forms";
import { useNotification } from "stories/components/Notification"

import { httpGetCompany, httpCreateCompany, httpUpdateCompany, httpDeleteCompany } from "services/companies"

import CurrencySelect from "components/Forms/CurrencySelect/CurrencySelect"

const defaultValues = {
  id: null,
  name: "",
}

const CompanyEdit = (props) => {
  
  const {
     values,
     touched,
     setTouched,
     errors,
     setErrors,
     handleChange,
     handleBlur,
     handleSubmit,
     setFieldValue,
     isValid,
     isTouched,
     isSubmitting,
     validateForm,
     preSetValues
   } = props;
   
   const {
      t,
      history,
      objectId,
      extraMargin,
   } = props;
   
   const getFormikInputProps = useCallback(
    name => ({
      name,
      value: values[name],
      error: Boolean(touched[name] && errors[name]),
      errorText: touched[name] ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange
    }),
    [values, errors, touched, handleBlur, handleChange]
  );
  
  const onSubmit = (e) => {
    e.preventDefault();
    validateForm().then(res => {
      if (Object.keys(res).length === 0) {
        handleSubmit()
      }
      else {
        setTouched(res);
        setErrors({
          ...res,
          general: t("Please check the form errors", "Tarkista lomakkeen virheet")
        })
      }
    })
  }
  
  const handleCancel = () => {
    props.handleReject()
  }
  
  const handleDelete = () => {
    httpDeleteCompany(objectId).then(() => {
        props.handleResolve()
        props.notify({ title:t("Company"), message:t("Poistettu")})
    })
  }
  
  return (
    <div className={extraMargin?"my-5":null}>
      <Row>
        <Col className="mb-3">
          <Input
            {...getFormikInputProps("name")}
            label={t("company_edit_name", "Nimi")}
            required
          />
        </Col>
      </Row>
      
      <Row>
        <Col className="mb-3">
          <Input 
            {...getFormikInputProps("business_identity_code")} 
            label={t("company_edit_identity_code", "Y-tunnus")}
            required
          />
        </Col>
      </Row>
      
      
      <Row>
        <Col className="mb-3">
          <Button onClick={handleCancel} variant="default">{t("Close","Sulje")}</Button>
        </Col>
        { values?.id ? (
        <Col className="mb-3 text-center">
         {  <Button onClick={handleDelete} disabled={false} variant="danger">{t("Delete","Poista")}</Button>}
        </Col>
        ) : null }
        <Col className="mb-3 text-right">

            <Button variant="secondary" disabled={isSubmitting} onClick={onSubmit}>
              {t("Save","Tallenna")}
            </Button>
          
          { Boolean(errors["general"]) && <Typography className="text-danger">{errors["general"]}</Typography> }
        </Col>
      </Row>
        
    </div>
  );
}

const CompanyEditFormik = withFormik({
    enableReinitialize: true,
    validateOnMount: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return {
          ...defaultValues
        }
      }
    },
    validationSchema: props => {
      const {t} = props;
      const required = t("The field is required");
      return Yup.object().shape({
        name: Yup.string().required(required).nullable(),
        business_identity_code: Yup.string().when('account_type', {
            is: (val) => val !== "private",
            then: Yup.string().matches(/^[0-9]{7}-[0-9]$/, t('Tarkista yrityksen y-tunnus')).required(required),
          }),
      });
    },
    handleSubmit: (values, { setSubmitting, setErrors, props }) => {
      const {t, portfolio, account} = props;
      console.log("account", account);
      let data = {
        ...values,
        account: account?.id,
      };
      
      if (values?.id) {
        httpUpdateCompany(values.id, data).then(res => {
          setSubmitting(false);
          props.notify({ title:t("Company"), message:t("Updated")})
          props.handleResolve()
          
        }, error => {
          setSubmitting(false);
          if (error?.data?.detail || error?.data?.general) {
            setErrors({
              general: error?.data?.detail,
            })
          }
          else {
            setErrors({
              general: t("Unknown error. Please try again later or contact support.")
            })
          }
        })
      }
      else {
        httpCreateCompany(data).then(res => {
          setSubmitting(false);
          props.notify({ title:t("Company"), message:t("Created")})
          props.handleResolve()
          
        }, error => {
          setSubmitting(false);
          if (error?.data?.detail) {
            setErrors({
              general: error?.data?.detail,
            })
          }
          else {
            setErrors({
              general: t("Unknown error. Please try again later or contact support.")
            })
          }
        })
      }
      
      
    },
    displayName: "BasicForm"
  
})(CompanyEdit)
    
    
const CompanyEditView = ({history, objectId, modalOpen,extraMargin, ...rest}) => {
  // API requests here
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [preSetValues, setPreSetValues] = useState();
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  const account = useStoreState((state) => state.account?.account);
  
  const getObject = (id) => {
    
    setLoading(true)
    
    httpGetCompany(id).then(res => {
      
      setPreSetValues({
        ...defaultValues,
        business_identity_code: res.business_identity_code,
        ...res?.data,
      });
    
    }).finally(response => {
      setLoading(false);
    })
    
  }
  
  
  useEffect(() => {
    if (objectId && parseInt(objectId) > -1) {
      getObject(objectId)
    }
  }, [objectId, modalOpen])
  
  
  if (loading) {
    return (
      <Container fluid>
        <Spinner />
      </Container>
    )
  }

  return (
    <CompanyEditFormik 
      t={t} 
      history={history} 
      preSetValues={preSetValues} 
      notify={notify} 
      objectId={objectId}
      account={account}
      extraMargin={extraMargin}
      {...rest} 
    />
  )
  
}

export default CompanyEditView;
