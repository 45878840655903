import React, { useState } from 'react';
import { Checkbox } from 'stories/forms'
import { useTranslation } from 'react-i18next';
import { Button, Typography, Spinner } from "stories/components";



const PortfolioCheckbox = ({ portfolio, selectedPortfolios, label, setSelectedPortfolios }) => {
  const [isChecked, setIsChecked] = useState(false);
  const { t } = useTranslation();

  const handleCheckboxChange = (event) => {
    const isChecked = event.target.checked;

    setIsChecked(isChecked);

    setSelectedPortfolios((prevSelectedPortfolios) => {
      if (isChecked) {
        return [...prevSelectedPortfolios, portfolio];
      } else {
        return prevSelectedPortfolios.filter((selectedPortfolio) => selectedPortfolio !== portfolio);
      }
    });
  };

  return (
    <div>
      <Checkbox
        type="checkbox"
        id={`portfolio_checkbox_${portfolio}`}
        checked={isChecked}
        onChange={handleCheckboxChange}
        label = {<Typography className="text-warning">{label}</Typography>}
      />
    </div>
  );
};

export default PortfolioCheckbox;