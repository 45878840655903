import http from './api'

const url = '/account_users'

export const httpGetAccountUsers = () => {
  return http({
    url: `${url}/` ,
    method: 'GET',
  })
}

export const httpGetMyAccountUser = () => {
  return http({
    url: `/my_account_user/` ,
    method: 'GET',
  })
}

export const httpGetAccountUser = (id) => {
  console.log("httpGetAccountUser");
  return http({
    url: `/account_users/${id}/` ,
    method: 'GET',
  })
}
export const httpDestroyAccountUser = (id) => {
  console.log("httpGetAccountUser");
  return http({
    url: `/account_users/${id}/` ,
    method: 'DELETE',
  })
}

export const httpUpdateAccountUser = (id, data) => {
  console.log("httpUpdateAccountUser");
  return http({
    url: `/account_users/${id}/` ,
    method: 'PATCH',
    data,
  })
}
