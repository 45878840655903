import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import { Input } from "stories/forms";
import { useAppContext }from "contexts/AppContext"
import { Container, Row, Col, FormContainer, Card, CardBody } from "stories/layout";
import { Button, Typography, Spinner, Modal, Link } from "stories/components";
import {  SelectMulti, Toggle, Radio, DatePicker, Checkbox } from "stories/forms";

import { round, roundScaleDecimals, thousandFormat } from "services/helpers"
import { set } from "date-fns";


const RealsEstateDataInput = ({  index, name, values, setFieldValue, ...rest }) => {
  const { t } = useTranslation();
  const { enums } = useAppContext()
  const [checked, setChecked] = useState(false)
  
    const field = `${name}${index}`
 
  
  return (
    <>

   
                      <Typography variant="small"  className= "ml-auto mr-2" >{t("purchase_information", "Kirjaa tulo automaattisesti")}</Typography>
                        <Checkbox 
                          type="checkbox"
                          id={field}
                          name={field}
                          checked={checked}
                          onChange={(e) => { setChecked(e.target.checked) }}
                          value="agree_terms"
                          label = {<Typography className="text-warning"></Typography>}
                        />
             
    </>
  )
  
  
}

export default RealsEstateDataInput;