import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import { SelectMulti } from "stories/forms";
import { useStoreState, useStoreActions } from 'easy-peasy';

const InvestmentFundSelect = ({ label, name="private_stock",ratesView, onChange, setFieldValue,defaultValues, value, handleResolve, setFilters }) => {
  const { t } = useTranslation();
//   const privateInvestmentFunds = useStoreState((state) => state.privateInvestmentFunds?.objects);
  const stocks = useStoreState((state) => state.stocks?.objects);  

  const setLocalFieldValue = (name, obj) => {
    setFieldValue(name, obj?.id)
    setFieldValue("currency", obj?.currency)
    handleResolve({ id: obj?.id, name: obj?.label ? obj?.label : obj?.name })
  }

  // useEffect(() => {

  //   // setLocalFieldValue("private_investment_fund", {label:"David", id:2}
  //   )
  // }, [])

  const getOptions = useCallback(() => {
    return stocks && stocks.map(stock => {
      return {value: stock?.id, label: stock?.name, currency:stock?.currency?stock?.currency:""}
    })
  }, [stocks])
  
  const getOptionLabel = useCallback(() => {
    return stocks && stocks.reduce((result, obj) => {
      if (obj?.id === value) {
        result = obj?.name
      }
      return result
    }, null)
  }, [stocks])
  
  /*
  useEffect(() => {
    if (stocks && stocks.length > 0 && (value === undefined || value === "")) {
      setLocalFieldValue(name, stocks[0])
    }
  }, [value, stocks])
  */
  

  const getDefaultValue = useCallback(() => {
    if (value) {
      //return [value]
      return [{id: value, value: value, label: getOptionLabel()}]
    }
    else if (defaultValues){
      return [defaultValues] ;
      // return undefined;
    }
    // else if (stocks && stocks.length > 0) {
    //   console.log(stocks)
    //   // return [{id: stocks[0]?.id, value: stocks[0]?.id, label: stocks[0]?.name}]
    //   setLocalFieldValue("private_investment_fund", [{id: stocks[0]?.id, value: stocks[0]?.id, label: stocks[0]?.name}])
    // }
    else {
      return undefined;
    }

  }, [stocks])
  
  return (
  <>
    <SelectMulti
      isMulti={false} 
      name={name} 
      value={value}
      defaultValues={getDefaultValue()}
      setFieldValue={setLocalFieldValue} 
      options={getOptions()} 
      ratesView={ratesView}
    />
  </>
  )

}

export default InvestmentFundSelect;
