
import React, { useEffect } from 'react';

const MiniSymbolOverviewWidget = ({symbol, width=300, height=300, color="light"}) => {
  useEffect(() => {
    const script = document.createElement('script');
    // script.type = 'text/javascript';
    script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-mini-symbol-overview.js';
    script.async = true;
    script.innerHTML = `
      {
        "symbol": "${symbol}",
        "width": "${width}",
        "height": "${height}",
        "locale": "fi",
        "dateRange": "12M",
        "colorTheme": "${color}",
        "isTransparent": false,
        "autosize": false,
        "largeChartUrl": ""
      }
    `;



    //   });
    // };

    const container = document.getElementById('tradingview-widget-container5');
    container.appendChild(script);

    // return () => {
    //   // Cleanup script when component is unmounted
    //   container.removeChild(script);
    // };
  }, []); // Empty dependency array ensures useEffect runs only once during component mount

  return (
    <div>
    <div id="mini-symbol-overview-widget-container"  >
      <div className="tradingview-widget-container__widget" id="tradingview-widget-container5"></div>
      <div className="tradingview-widget-copyright">
        <a href="https://www.tradingview.com/" rel="noopener nofollow" target="_blank">
          {/* <span className="blue-text">Track all markets on TradingView</span> */}
        </a>
      </div>
    </div>
      
    </div>
  );
};

export default MiniSymbolOverviewWidget;
















// import React, { useEffect } from 'react';
// import './TradingViewWidget.css';

// const TradingViewWidget2 = (props) => {
//   useEffect(() => {
//     const script = document.createElement('script');
//     // script.src = 'https://s3.tradingview.com/tv.js';
//     // script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-technical-analysis.js';
//     script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-mini-symbol-overview.js';

//     script.async = true;

//     script.onload = () => {
//       new window.TradingView.widgetMini({ //whatis this? explain this line
//         "autosize": true,
//         "symbol": props.symbol,
//         // "symbol": "OMXHEX:NOKIA",
//         "timezone": "Etc/UTC",
//         "theme": "dark",
//         "style": "1",
//         "locale": "en",
//         "enable_publishing": false,
//         "withdateranges": true,
//         "range": "YTD",
//         "hide_side_toolbar": false,
//         "allow_symbol_change": true,
//         "details": true,
//         "hotlist": true,
//         "calendar": true,
//         container_id: 'tradingview-widget-container-mini'
//       });
//     };

//     // document.body.appendChild(script);
//     document.getElementById('tradingview-widget-container-mini').appendChild(script);

//     return () => {
//       document.body.removeChild(script);
//     };
//   }, []);

//   return (
//     <div style={{ minHeight: '100px' }}>
//     <div className="tradingview-widget-container" id="tradingview-widget-container-mini" style={{ height: '100%', width: '100%', minHeight: '100px' }}>
//       <div className="tradingview-widget-container__widget" ></div>
//       <div className="tradingview-widget-copyright">
//         <a href="https://www.tradingview.com/" rel="noopener nofollow" target="_blank">
//           <span className="blue-text">Track all markets on TradingView</span>
//         </a>
//       </div>
//     </div>
//     </div>
//   );
// };

// export default TradingViewWidget2;


// import React, { useEffect } from 'react';

// const TradingViewWidget = (props) => {
//         const ede = "OMXHEX:NOKIA"
//         useEffect(() => {
//             const script = document.createElement('script');
//             // script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-technical-analysis.js';
//             script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-mini-symbol-overview.js';
            
//             script.async = true;
//             script.innerHTML = `
//                 {
//                     "interval": "1m",
//                     "width": 250,
//                     "isTransparent": false,
//                     "height": 260,
//                     // "symbol": "${ede}",
//                     "symbol": "OMXHEX:NOKIA",
//                     "showIntervalTabs": true,
//                     "displayMode": "single",
//                     "locale": "en",
//                     "colorTheme": "dark",
//                     "container_id": 'tradingview-widget-container3'
//                 }
//             `;
            
//             document.getElementById('tradingview-widget-container3').appendChild(script);
  
      
//     }, []);
  
//     return (
//       <div className="tradingview-widget-container">
//         <div className="tradingview-widget-container__widget" id="tradingview-widget-container3"></div>
//         <div className="tradingview-widget-copyright">
//           <a href="https://www.tradingview.com/" rel="noopener nofollow" target="_blank">
//             {/* <span className="blue-text">Track all markets on TradingView</span> */}
//           </a>
//         </div>
//       </div>
//     );
//   };
  
//   export default TradingViewWidget;








// import React, { useEffect } from 'react';
// // import './TradingViewWidget.css';

// const TradingViewWidget2 = (props) => {
//   useEffect(() => {
//     const script = document.createElement('script');
//     script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-mini-symbol-overview.js';
//     script.async = true;

//     script.onload = () => {
//       new window.TradingView.widget({
//         "autosize": true,
//         "symbol": props.symbol,
//         // "symbol": "OMXHEX:NOKIA",
//         "timezone": "Etc/UTC",
//         "theme": "dark",
//         "style": "1",
//         "locale": "en",
//         "enable_publishing": false,
//         "withdateranges": true,
//         "range": "YTD",
//         "hide_side_toolbar": false,
//         "allow_symbol_change": true,
//         "details": true,
//         "hotlist": true,
//         "calendar": true,
//         container_id: 'tradingview-widget-container'
//       });
//     };

//     document.body.appendChild(script);

//     return () => {
//       document.body.removeChild(script);
//     };
//   }, []);

//   return (
//     <div style={{ minHeight: '100px' }}>
//     <div className="tradingview-widget-container" id="tradingview-widget-container" style={{ height: '100%', width: '100%', minHeight: '100px' }}>
//       <div className="tradingview-widget-container__widget" style={{ height: 'calc(100% - 32px)', width: '100%', minHeight: '100px' }}></div>
//       <div className="tradingview-widget-copyright">
//         <a href="https://www.tradingview.com/" rel="noopener nofollow" target="_blank">
//           <span className="blue-text">Track all markets on TradingView</span>
//         </a>
//       </div>
//     </div>
//     </div>
//   );
// };

// export default TradingViewWidget2;


// import React, { useEffect } from 'react';


// const TradingViewMiniSymbolOverview = ({ symbol }) => {
//   useEffect(() => {
//     const script = document.createElement('script');
//     script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-mini-symbol-overview.js';
//     script.async = true;

//     script.onload = () => {
//       new window.TradingView.MiniSymbolOverview({
//         "container_id": "tradingview-mini-widget-container",
//         "symbols": [
//           {
//             "symbol": symbol || "FX:EURUSD", // Default symbol is FX:EURUSD
//           },
//         ],
//         "width": 350,
//         "height": 220,
//         "locale": "en",
//         "dateRange": "12M",
//         "colorTheme": "dark",
//         "isTransparent": false,
//         "autosize": false,
//         "largeChartUrl": ""
//       });
//     };

//     document.body.appendChild(script);

//     return () => {
//       document.body.removeChild(script);
//     };
//   }, []);

//   return (
//     <div style={{ minHeight: '100px' }}>

//     <div className="tradingview-widget-container" id="tradingview-mini-widget-container"  style={{ height: '100%', width: '100%', minHeight: '100px' }}>
//       <div className="tradingview-widget-container__widget" style={{ height: 'calc(100% - 32px)', width: '100%', minHeight: '100px' }}></div>
//       <div className="tradingview-widget-copyright">
//         <a href="https://www.tradingview.com/" rel="noopener nofollow" target="_blank">
//           <span className="blue-text">Track all markets on TradingView</span>
//         </a>
//       </div>
//     </div>
//     </div>
//   );
// };

// export default TradingViewMiniSymbolOverview;

