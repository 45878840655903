import http from './api'

const url = '/options'

export const httpGetOptions = (params) => {
  return http({
    url: `${url}/` ,
    method: 'GET',
    params
  })
}

export const httpGetOption = (id) => {
  return http({
    url: `${url}/${id}/` ,
    method: 'GET',
  })
}

export const httpCreateOption = (data) => {
  return http({
    url: `${url}/` ,
    method: 'POST',
    data
  })
}

export const httpUpdateOption = (id, data) => {
  return http({
    url: `${url}/${id}/` ,
    method: 'PATCH',
    data
  })
}

export const httpDeleteOption = (id) => {
  return http({
    url: `${url}/${id}/` ,
    method: 'DELETE',
  })
}

export const httpGetOptionsExcel = (params) => {
  return http({
    url: `${url}/export_excel/` ,
    method: 'GET',
    responseType: 'arraybuffer',
    params
  })
}
