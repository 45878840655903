import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import { Input } from "stories/forms";
import { useStoreState  } from 'easy-peasy';
import { SelectMulti } from "stories/forms";
import { useAppContext }from "contexts/AppContext"
import Select from 'react-select';
import { sub } from "date-fns";

// import { on } from "gulp";


// const CurrencyDateLogicSelect = () => {
const CurrencyDateLogicSelect = ({ label, name,multiple=false,placeholder,disabled,ratesView=true, showAll=false,onChange,setFieldValueReturnId=false, handleResolve, setFieldValue,validateForm, showAllOption, value, ...rest}) => {
  const { t } = useTranslation();

  
  const getInputOptions = useCallback(() => {

    let followedList = [{key: "latest", value:t("latest", "Tilannepäivä")},{key: "historical", value:t("historical","Historiallinen")}]
    return followedList && followedList.map(obj => {

      return (
        {value:obj.key, label: obj.value}
      )
    })

  }, [])

  const setLocalFieldValue = (name, obj) => {

    if (setFieldValueReturnId === true) {
      setFieldValue(name, obj?.id)
    }
    else {
      setFieldValue(name, obj)
    }
    
    if (handleResolve !== undefined) {
      handleResolve({ id: obj?.id, name: obj?.label ? obj?.label : obj?.name,  })
    }

  }


  const getSelectedOptions = useCallback(() => {
    if (multiple === false) {
      return [value];
    }
    return value && value.map(obj => {
      return {value: obj.id, label: obj.value}
    })
  }, [value])

  // const getOptions = useCallback(() => {

  //   return portfolios && portfolios.map(obj => {
  //     return {value: obj.id, label: obj.name}
  //   })
  // }, [portfolios])



  return (
    <>
     <SelectMulti
            isMulti={false}
            label={label ? label : t("currency_date_logic_select_label", "Valuuttakurssi hankintamenolle")}
            name={name}
            value={value}
            setFieldValue={setLocalFieldValue}
            defaultValue={getSelectedOptions()}
            options={getInputOptions()}
            showAllOption={showAllOption} 
            validateForm={validateForm}
            placeholder={placeholder}
            ratesView={ratesView}
            disabled={disabled}
            multiple={multiple}
            {...rest}
          />

        {/* <Select
                name="Test"
                options={getInputOptions()}
                isMulti={true}
        /> */}
    </>

  )


  
  
}

export default CurrencyDateLogicSelect;