import React, { useState, useCallback, useEffect } from "react";
import { withFormik } from 'formik';
import * as Yup from "yup";
import moment from "moment"
import { useTranslation } from 'react-i18next';
import { useStoreState, useStoreActions } from 'easy-peasy';

import { Button, Typography, Spinner, Modal } from "stories/components";
import { Container, Row, Col, FormContainer, Card, CardBody, CardHeader } from "stories/layout";
import { Input, SelectMulti, Toggle, Radio, Checkbox } from "stories/forms";
import { useNotification } from "stories/components/Notification"
import SectorSelect from "components/Forms/SectorSelect/SectorSelect"
import { useAppContext } from "contexts/AppContext"
import SectorEdit from "views/app/SectorEdit/SectorEdit"



// import { httpGetCompany, httpCreateCompany, httpUpdateCompany, httpDeleteCompany } from "services/companies"
import { httpCreateAccountSecurityRelation, httpUpdateAccountSecurityRelation, httpGetAccountSecurityRelation } from "services/accounts"

import CurrencySelect from "components/Forms/CurrencySelect/CurrencySelect"

const defaultValues = {
  id: null,
  name: "",
}

const AccountSecurityRelationEdit = (props) => {
  
  const {
     values,
     touched,
     setTouched,
     errors,
     setErrors,
     handleChange,
     handleBlur,
     handleSubmit,
     setFieldValue,
     isValid,
     isTouched,
     isSubmitting,
     validateForm,
     preSetValues
   } = props;
   
   const {
      t,
      history,
      objectId,
      extraMargin,
      showExtraField,
      setShowExtraField,
      account,
      setSelectedSector,
      selectedSector,
      security,
      related_model_id,
      related_model_type, 
   } = props;
   
   const getFormikInputProps = useCallback(
    name => ({
      name,
      value: values[name],
      error: Boolean(touched[name] && errors[name]),
      errorText: touched[name] ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange
    }),
    [values, errors, touched, handleBlur, handleChange]
  );

  const { getSectorName } = useAppContext()
  const getSectors = useStoreActions((actions) => actions.sectors.getSectors);




  // Modal: New/Edit Sector

  const [modalEditOpen, setModalEditOpen] = useState(false)
  const [modalEditId, setModalEditId] = useState()
  
  const toggleModalEdit = () => {
    setModalEditOpen(s => !s);
  }
  
  const openModalEdit = (id) => {
    setModalEditOpen(true)
    setModalEditId(id);
  }
  
  const modalEditResolve = () => {
    setModalEditOpen(false);
    setModalEditId(null);
    getSectors()
  }
  
  const modalEditReject = () => {
    setModalEditOpen(false);
    setModalEditId(null);
  }

  
  const onSubmit = (e) => {
    e.preventDefault();
    validateForm().then(res => {
      if (Object.keys(res).length === 0) {
        handleSubmit()
      }
      else {
        setTouched(res);
        setErrors({
          ...res,
          general: t("Please check the form errors", "Tarkista lomakkeen virheet")
        })
      }
    })
  }
  
  const handleCancel = () => {
    props.handleReject()
  }
  
//   const handleDelete = () => {
//     httpDeleteCompany(objectId).then(() => {
//         props.handleResolve()
//         props.notify({ title:t("Company"), message:t("Poistettu")})
//     })
//   }

  const handleExtraFieldChange = (event) => {
    setShowExtraField(event.target.checked)
  }

  const handleSectorChange = (name, value) => {
    setSelectedSector(s => ({
      ...s,
      [name]: value
    }))
  }

  const getSectorSelect = useCallback(() => {
    return (
      <SectorSelect
        {...getFormikInputProps("sector_select")}
        label=" "
        multiple={false}
        onChange={handleSectorChange}
        value={selectedSector?.sector_select}
        setFieldValue={handleSectorChange}
      />
    )
  }, [selectedSector])

  
  return (
    <div >
            <Row className="mb-1 mt-3">
              <Col sm={12} lg={4} className="d-flex align-items-center">
              <label className="mb-1 pr-1 header">{t("account_security_name", "Nimi")}</label>
              </Col>
              <Col >
              <Input
                  name= "name"
                  label=""
                  value={security?.name}
                  disabled={true}

              />
              </Col>
          </Row>         
           <Row className="mb-1 mt-3">
              <Col sm={12} lg={4} className="d-flex align-items-center">
              <label className="mb-1 pr-1 header">{t("account_security_symbol", "Tunnus")}</label>
              </Col>
              <Col >
              <Input
                  name = "symbol"
                  label=""
                  value={security?.ticker_symbol ? security?.ticker_symbol : security?.symbol}
                  disabled={true}

              />
              </Col>
          </Row>
          <Row className="mb-1 mt-3">
              <Col sm={12} lg={4} className="d-flex align-items-center">
              <label className="mb-1 pr-1 header">{t("account_security_stock_market", "Pörssi")}</label>
              </Col>
              <Col >
              <Input
                  name = "stock_market"
                  label=""
                  value={security?.stock_market}
                  disabled={true}

              />
              </Col>
          </Row>
            {security?.public_sector ? (
                <Row className="mb-1 mt-3">
                    <Col sm={12} lg={4} className="d-flex align-items-center">
                    <label className="mb-1 pr-1 header">{t("account_security_sector", "Toimiala")}</label>
                    </Col>
                    <Col >
                    <Input
                        name = "stock_market"
                        label=""
                        value={t(`sector_select_1_${security?.public_sector}`,getSectorName(`1_${security?.public_sector}`))}
                        disabled={true}
    
                    />
                    </Col>
                </Row>
            ):(
                <>
                    <Row className="mb-1 mt-3">
                        <Col sm={12} lg={4} className="d-flex align-items-center">
                            <label className="mb-1 pr-1 header">{t("account_security_edit_sector", "Toimiala")}</label>
                        </Col>
                        <Col >
                            {getSectorSelect()}
                        </Col>
                    </Row>
                    <Row className="mb-3">
                            <Col sm={12} lg={4} className="d-flex align-items-center">
                            </Col>
                            <Col>
                            <div  calssName="text-blue" onClick={() => openModalEdit()} style={{ cursor: 'pointer' }}>
                            <Typography variant="small">  {t("sector_create_here","Luo toimiala tästä")}</Typography>
                            </div>
                            </Col>
                        </Row>
                </>
            )}
            { security?.total_issue_custom && (

          <Row className="mb-1 mt-3">
              <Col sm={12} lg={4} className="d-flex align-items-center">
              <label className="mb-1 pr-1 header">{t("accoutn_security_edit_total_issue", "Arvopaperin koko")}</label>
              </Col>
              <Col >
              <Input
                  {...getFormikInputProps("total_issue")} 
                  label=""
                  type="number"

                  />
              </Col>
          </Row>
            )
}

          {  account?.show_relation_to_security &&
            <>
              <Row className="mb-1 mt-3">
                  <Col sm={12} lg={4} className="d-flex align-items-center">
                  <label className="mb-1 pr-1 header">{t("account_security_edit_dimensio", "Dimensio")}</label>
                  </Col>
                  <Col >
                  <Input
                      {...getFormikInputProps("dimension")} 
                      label=""
                      />
                  </Col>
              </Row>
              <Row className="mt-3">
              <Col sm={12} lg={4} className="d-flex align-items-center">
                  <label className="mb-1 pr-1 header">{t("account_security_edit_sector_custom_symbol", "Oma tunnus")}</label>
                  </Col>
              <Col >
              <Input
                  {...getFormikInputProps("custom_symbol")} 
                  label=""
                  />
              </Col>
              </Row>
            </>
          }



          {/* <Row className="mt-3">
              <Col sm={12} lg={4} className="d-flex align-items-center">
              <label className="mb-1 pr-1 header">{t("portfolio_edit_external_system_1_export", "Vie tiedot")}</label>
              </Col>
              <Col>
                <Checkbox 
                  id={"export_external_first_system"}
                  {...getFormikInputProps("export_external_first_system")}
                  value= {values?.export_external_first_system}
                  checked={values?.export_external_first_system}
                  className="zIndexZero"
                  required
                  label={<>{t("portfolio_edit_extra_info", '')}</>}
                />
                </Col>
          </Row> */}
          <Row className="row justify-content-end mt-3">
            <Col lg={8} md={12} className="mb-2">
              {/* <Typography variant="small">{t("portfolio_edit_external_system_name_1_info","Ulkoisen järjestelmän nimi ja id")}</Typography> */}
            </Col>
          </Row>
          <Row>

        <Col className="mb-3">
          <Button onClick={handleCancel} variant="default">{t("Close","Sulje")}</Button>
        </Col>
     
        <Col className="mb-3 text-right">

            <Button variant="secondary" disabled={isSubmitting} onClick={onSubmit}>
              {t("Save","Tallenna")}
            </Button>
          
          { Boolean(errors["general"]) && <Typography className="text-danger">{errors["general"]}</Typography> }
        </Col>
  
      </Row> 



      <Modal
        title={t("sector_title","Toimiala")}
        isOpen={modalEditOpen}
        toggleModal={toggleModalEdit}
        
        >
        <SectorEdit
            objectId={modalEditId}
            handleResolve={modalEditResolve}
            handleReject={modalEditReject}
            extraMargin={true}
        />
        </Modal>
        
    </div>
  );
}

const AccountSecurityRelationEditFormik = withFormik({
    enableReinitialize: true,
    validateOnMount: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return {
          ...defaultValues
        }
      }
    },
    validationSchema: props => {
      const {t} = props;
      const required = t("The field is required");
      const specialCharRegex = /^[a-zA-Z0-9\s]+$/; // Tämä sallii vain kirjaimet, numerot ja välilyönnit
      return Yup.object().shape({
        // external_first_system_name: Yup.string().required(required).nullable(),
        dimension: Yup.string()
          .matches(specialCharRegex, t("account_security_dimension","Dimension ei saa sisältää erikoismerkkejä"))
          .nullable(),
      });
    },
    handleSubmit: (values, { setSubmitting, handleResolve, setErrors,  props }) => {
      const {t, portfolio, account, related_model_type, selectedSector, related_model_id, setRelationId, relationId} = props;
      console.log("account", account);

      let data = {
        ...values,
        related_model_type: related_model_type,
        related_model_id: related_model_id,
        general_sector: selectedSector?.sector_select?.id,
        dimension: values?.dimension || null,
        custom_symbol: values?.custom_symbol || null,
        total_issue: values?.total_issue || null,
        // account: account?.id,
      };
      
      if (values?.id || relationId) {
        httpUpdateAccountSecurityRelation(account.id, data).then(res => {
          setSubmitting(false);
          props.notify({ title:t(""), message:t("Updated")})
          props.handleResolve()
          
        }, error => {
          setSubmitting(false);
          if (error?.data?.detail || error?.data?.general) {
            setErrors({
              general: error?.data?.detail,
            })
          }
          else {
            setErrors({
              general: t("Unknown error. Please try again later or contact support.")
            })
          }
        })
      }
      else {
        httpCreateAccountSecurityRelation(account.id, data).then(res => {
          setSubmitting(false);
          props.notify({ title:t(""), message:t("Updated")})
          setRelationId(res?.data?.message?.id)
          props.handleResolve()
        }, error => {
          setSubmitting(false);
          if (error?.data?.detail) {
            setErrors({
              general: error?.data?.detail,
            })
          }
          else {
            setErrors({
              general: t("Unknown error. Please try again later or contact support.")
            })
          }
        })
      }
      
      
    },
    displayName: "BasicForm"
  
})(AccountSecurityRelationEdit)
    
    
const AccountSecurityRelationEditView = ({history,handleResolve, handleReject, handleCancel, objectId, security, related_model_type, related_model_id, modalOpen,extraMargin, ...rest}) => {
  // API requests here
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [preSetValues, setPreSetValues] = useState();
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  const account = useStoreState((state) => state.account?.account);
  const [showExtraField, setShowExtraField] = useState(false);
  const [selectedSector, setSelectedSector] = useState({sector_select: []});
  const { getSectorName } = useAppContext()
  const [relationId, setRelationId] = useState()



  
  const getObject = (id) => {
    
    setLoading(true)
    const params = {related_model_id: related_model_id, related_model_type: related_model_type}
    
    httpGetAccountSecurityRelation(account.id, params).then(res => {
      
      setPreSetValues({
        ...defaultValues,
        ...res?.data,
      });
      let sector = null
      if (res?.data?.private_sector){
        //add 1_ to private_sector id like 1_2322
        sector =`2_${res?.data?.private_sector}`
      }
      if (res?.data?.public_sector){
        sector =`1_${res?.data?.public_sector}`
      }
      setSelectedSector({
        sector_select: sector ? [{id: sector, label: t(`sector_select_1_${sector}`,getSectorName(`1_${sector}`)), name:t(`sector_select_1_${sector}`,getSectorName(`1_${sector}`)) }] : []
      })
    
    }).finally(response => {
      setLoading(false);
    })
    
  }
  
  
  useEffect(() => {
    if (objectId && parseInt(objectId) > -1) {
      getObject(objectId)
    }
  }, [objectId, modalOpen])
  
  
  if (loading) {
    return (
      <Container fluid>
        <Spinner />
      </Container>
    )
  }

  return (
    <AccountSecurityRelationEditFormik
      t={t} 
      history={history} 
      preSetValues={preSetValues} 
      notify={notify} 
      objectId={objectId}
      account={account}
      extraMargin={extraMargin}
      showExtraField={showExtraField}
      setShowExtraField={setShowExtraField}
      related_model_type={related_model_type}
      related_model_id={related_model_id}
      setSelectedSector={setSelectedSector}
      selectedSector={selectedSector}
      setRelationId={setRelationId}
      relationId={relationId}
      security={security}
        handleResolve={handleResolve}
        handleReject={handleReject}
        handleCancel={handleCancel}
      {...rest} 
    />
  )
  
}

export default AccountSecurityRelationEditView;
