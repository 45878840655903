import jwt_decode from "jwt-decode";


//create function to check for token expiration
export const logoutWhenTokenExp = () => {

    const refreshToken = localStorage.getItem("refreshToken");
    const decoded = jwt_decode(refreshToken);
    const exp = decoded.exp;
    const iat = decoded.iat;
    
    const timeDiff = exp - iat;
    console.log("Time difference: ", timeDiff);

    setTimeout(() => {
        // console.log('This code will run after 1 second when refresh token expires');
        const refreshToken1 = localStorage.getItem("refreshToken");
        const decoded1 = jwt_decode(refreshToken1);
        const exp1 = decoded1.exp;

        // get current time
        const now = Date.now();
        const currentTime = Math.round(now / 1000);
        console.log("Current time: ", currentTime);

        //if current time is greater than expiration time, log user out
        if (currentTime > exp1) {
          console.log("Token expired!");
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          window.location.href = "/"
        }
        

        

      }, timeDiff * 1000 + 1000); 
};

