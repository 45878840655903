import { action, thunk, persist } from 'easy-peasy';
import { httpGetPortfoliosUnmadeDividendTransactions } from "services/portfolios"

const model = {
  loading: false,
  setLoading: action((state, payload) => {
    state.loading = payload
  }),
  count: 0,
  setCount: action((state, payload) => {
    state.count = payload
  }),
  // My portfolios list
  unmadeDividendTransactions: [],
  setUnmadeDividendTransactions: action((state, payload) => {
    if (payload) {
      state.unmadeDividendTransactions = payload
    }
  }),
  getUnmadeDividendTransactions: thunk(async (actions, payload) => {
    actions.setLoading(true)
    httpGetPortfoliosUnmadeDividendTransactions().then(response => {
      if (response.status === 200 && response?.data) {
        actions.setLoading(false)
        actions.setUnmadeDividendTransactions(response.data)
        if (response?.data.length > 0) {
          actions.setCount(response?.data.length)
        }
        else {
          actions.setCount(0)
        }
      }
    }, error => {
      
    }).finally(() => {
      actions.setLoading(false)
    })
    
  }),
  // Selected portfolio

};

export default model;