import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import { Input } from "stories/forms";
import { useAppContext }from "contexts/AppContext"

const CurrencySelect = ({ label, name="currency", onChange,add_nothing=false, useOnlyList=false, value,noDefault=false, ...rest }) => {
  const { t } = useTranslation();
  const { enums } = useAppContext()
  const nothing = {key: "nothing", value: t("currency_select_nothing", "Ei muunnoksia")}
  
  const getInputOptions = useCallback(() => {

    let currencies = enums?.transactions?.transaction?.currency_label
    //sort currencies by value
    // currencies = currencies?.sort((a, b) => a?.value.localeCompare(b?.value))
    currencies = currencies?.sort((a, b) => {
        if (a?.value === 'EUR') return -1;  // Move EUR to the front
        if (b?.value === 'EUR') return 1;   // Move EUR to the front
        return a?.value.localeCompare(b?.value);  // Regular sorting for the rest
    });
    if(add_nothing && currencies) {
      currencies = [...currencies, nothing]
    }

    return currencies && currencies.map(obj => {
      // check if useOnlyList is set and it is list with at least one item
      if(useOnlyList && useOnlyList.length > 0) {
        // check if current item is in useOnlyList list
        if(useOnlyList.indexOf(obj.key) < 0) {
          // if not, return null
          return null
        }
      }
      return (
        <option value={obj.key}>{obj.value}</option>
      )
    })
  }, [enums, useOnlyList])
  
  return (
    <>
    <Input  label={label || label === "" ? label : t("currency_select_label", "Tyyppi")} type="select" name={name} onChange={onChange} value={value} {...rest}>
      {noDefault && <option value="">--</option>}
      {getInputOptions()}
    </Input>
    </>
  )
  
  
}

export default CurrencySelect;
