import http from './api'

const url = '/licenses'

export const httpGetLicenses = (params) => {
  return http({
    url: `${url}/`,
    method: 'GET',
    params,
  })
}