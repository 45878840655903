import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { Table, THead, Pagination } from "stories/tables";
import { Checkbox } from "stories/forms";
import { Spinner } from "stories/components"
import { useTranslation } from 'react-i18next';


import './IncomeSummaryTable.css';
import { Typography } from 'stories/components';

export const IncomeSummaryTable = ({ 
    checkboxes = false,
    checked = [],
    setChecked,
    filters,
    headers,
    headerColor = "dark",
    headerTextColor, 
    rows_sell,
    rows_buy,
    rows_summary,
    rows_dividend,
    rows_other,
    className,
    loading,
    pagination = false,
    paginationPrevious,
    paginationNext,
    paginationOffset,
    paginationLimit,
    paginationCount,
    setFilters,
    showRowNumber = false,
    showId = false,
    tableSmall = true,
    tableStriped = true,
    showPaginationLimitSelect = true,
    showVerticalBorders = true,
    sortBackend = true,
    sorting,
    setSorting,
    showHeader,
    showBuy = true,
    showDividend = false,
    showOther = false,
    ...rest 
  }) => {
  const [checkedAll, setCheckedAll] = useState(false)
  const { t } = useTranslation();
  
    // Ordering
  const getCurrentOrdering = () => {
    if (sortBackend === true) {
      let orderingField = "";
      let directionAsc = true;
      let ordering = filters?.ordering;
      
      if (ordering) {
        if (ordering.includes("-")) {
          directionAsc = false;
        }
        orderingField = ordering.replace("-","")
      }
      
      return {
        field: orderingField,
        directionAsc: directionAsc,
      }
    }
    else {
      return {
        field: sorting?.sortingKey,
        directionAsc: !sorting?.sortingReverse,
      }
    }
  }
  
  const getSortingStatus = (headerObject) => {
    if (headerObject?.sortable === true) {
      const currentOrdering = getCurrentOrdering();
      if (currentOrdering.field === headerObject?.sortingKey) {
        if (currentOrdering.directionAsc) {
          return (
            <i className="fas fa-angle-up"></i>
          )
        }
        else {
          return (
            <i className="fas fa-angle-down"></i>
          )
        }
      }
    }
    return ""
  }
  
  const handleSorting = (header) => {
    let directionAsc = true;
    const currentOrdering = getCurrentOrdering();
    if (currentOrdering?.field === header.sortingKey) {
      directionAsc = !currentOrdering?.directionAsc
    }
    
    if (sortBackend === true) {
      let orderingFilter = header?.sortingKey;
      if (directionAsc === false) {
        orderingFilter = "-"+orderingFilter;
      }
      
      setFilters(s => ({
        ...s,
        ordering: orderingFilter,
      }))
    }
    else {
      setSorting({
        ...header,
        sortingKey: header?.sortingKey,
        sortingReverse: !directionAsc,
      })
    }
  }
  
  // Ordering END
  
  // Checkboxes
  
  // const handleChecked = (id) => (e) => {
    
  //   let _checked = checked;
  //   const index = _checked.indexOf(id);
    
  //   if (index > -1) {
  //     _checked.splice(index, 1);
  //   }
  //   else {
  //     _checked.push(id)
  //   }
    
  //   setChecked([
  //     ..._checked
  //   ])
    
  // }
  
  // const handleToggleCheckAll = () => (e) => {
    
  //   setCheckedAll(s => !s)
    
  //   if (e?.target?.checked === true) {
  //     let _checked = []
  //     rows && rows.map((row, index) => {
  //       const checkId = row?.checkId ? row?.checkId : null;
  //       if (checkId) {
  //         _checked.push(checkId)
  //       }
        
  //     })
      
  //     setChecked([
  //       ..._checked
  //     ])
  //   }
  //   else {
  //     setChecked([])
  //   }
  // }
  
  // const isChecked = (id) => {
  //   return checked.reduce((result, item) => {
  //     if (item === id) { result = true }
  //     return result
  //   }, false)
    
  // }
  
  // Checkboxes END
  
  const renderHeader = () => {
    return headers && headers.filter(item => {return item?.visible!==false}).map((header, index) => {
      if (header?.sortable) {
        return (
          <th key={index} className={showHeader}><a className={`table_header_link table_header_link_sortable ${showHeader}`} onClick={() => handleSorting(header)}>{header?.label} {getSortingStatus(header)}</a></th>
        )
      }
      else {
        return (
          <th key={index}>{header?.label}</th>
        )
      }
      
    })
  }
  
  const renderRows = (rows) => {
    if (!rows || rows.length === 0) {
      return (
        <tr><td colSpan={2+headers.length}>No results</td></tr>
      )
    }
    
    return rows && rows.map((row, index) => {
      /*
      className={`${tableStriped && index % 2 === 1 && "bg-secondary"}`}
      */
      
      const checkId = row?.checkId ? row?.checkId : index;
      // const checked = isChecked(checkId);
      
      if (row?.subtitle) {
        return (
          <tr key={index} className={row?.className}>

            <td>
            
            </td>
          </tr>
        )
      }
      else {
        return (
          <tr key={index} className={row?.className}>
            {showRowNumber && (
              <td>{index+1+ Number(paginationOffset)}</td>
            )}
            { showId && (
              <td>{row?.id}</td>
            )}
            { checkboxes && (
              <td>
                {row?.isSubtitle === true ? (
                  <>&nbsp;</>
                ) : (
                  <Checkbox 
                    id={`id_checkbox_${index}`}
                    name={`checkbox_${index}`}
                    checked={checked}
                    value={`checkbox_${index}`}
                  />
                )}
              </td>
            )}
            {headers && headers.filter(item => {return item?.visible!==false}).map((header, j) => (
              <td key={j} className={`${row[header?.key]?.className ? row[header?.key]?.className : ""}`}>{row[header?.key]?.content ? row[header?.key]?.content : row[header?.key]}</td>
            ))}
          </tr>
        )
      }
    })
  }

  const renderRows1 = () => {
    if (!rows_buy || rows_buy.length === 0) {
      return (
        <tr><td colSpan={2+headers.length}>No results</td></tr>
      )
    }
    
    return rows_buy && rows_buy.map((row, index) => {
      /*
      className={`${tableStriped && index % 2 === 1 && "bg-secondary"}`}
      */
      
      // const checkId = row?.checkId ? row?.checkId : index;
      // const checked = isChecked(checkId);
      
      if (row?.subtitle) {
        return (
          <tr key={index} className={row?.className}>
          
            <td>
            
            </td>
          </tr>
        )
      }
      else {
        return (
          <tr key={index} className={row?.className}>
            {showRowNumber && (
              <td>{index+1+ Number(paginationOffset)}</td>
            )}
            { showId && (
              <td>{row?.id}</td>
            )}
            { checkboxes && (
              <td>
                {row?.isSubtitle === true ? (
                  <>&nbsp;</>
                ) : (
                  <Checkbox 
                    id={`id_checkbox_${index}`}
                    name={`checkbox_${index}`}
                    checked={checked}
                    value={`checkbox_${index}`}
                  />
                )}
              </td>
            )}
            {headers && headers.filter(item => {return item?.visible!==false}).map((header, j) => (
              <td key={j} className={`${row[header?.key]?.className ? row[header?.key]?.className : ""}`}>{row[header?.key]?.content ? row[header?.key]?.content : row[header?.key]}</td>
            ))}
          </tr>
        )
      }
    })
  }
  
  return (
    <>
      <Table
        className={`table_auto ${tableSmall ? "table-sm" : ""} ${tableStriped ? "table-striped" : ""} ${className ? className : ""}`}
        showVerticalBorders={showVerticalBorders}
      >          

        <THead color={headerColor}>
          <tr className="w-100">
            {headers && headers.map((item, index) => {
              return (<th className="bg-secondary">{ index==0 && <Typography variant="h5" >{t("income_report_summary_table_realized","Realisoituneet")}</Typography>}</th>)
            })}
          </tr>
          <tr>
            { showRowNumber && (
              <th>
              #
              </th>
            )}
            { showId && (
              <th>
              ID
              </th>
            )}
            { checkboxes && (
              <th>
                <Checkbox 
                  id="checkbox_all"
                  name="checkbox_all"
                  value="checkbox_all"
                  checked={checkedAll} 
                />
              </th>
            )}
            { renderHeader()}
          </tr>
        </THead>
        <tbody name="realized">
          { loading ? (
            <tr><td colSpan={headers && headers.length +1} className="text-center"><Spinner className="mt-2"/></td> </tr>
          ) : 
            renderRows(rows_sell)
          }
          <div className="m-5">
          
          </div>
          

          

        </tbody> 
        {showBuy && (
        <THead color={headerColor}>
          <tr className="w-100">
            {headers && headers.map((item, index) => {
              return (<th className="bg-secondary">{ index==0 && <Typography variant="h5" >{t("income_report_summary_table_unrealized","Realisoitumattomat")}</Typography>}</th>)
            })}
          </tr>
          <tr>
            { showRowNumber && (
              <th>
              #
              </th>
            )}
            { showId && (
              <th>
              ID
              </th>
            )}
            { checkboxes && (
              <th>
                <Checkbox 
                  id="checkbox_all"
                  name="checkbox_all"
                  value="checkbox_all"
                  checked={checkedAll} 
                />
              </th>
            )}
            { renderHeader()}
          </tr>
        </THead>
        )}
        {showBuy && (

          <tbody name="unrealized">
          { loading ? (
            <tr><td colSpan={headers && headers.length +1} className="text-center"><Spinner className="mt-2"/></td> </tr>
          ) : 
            renderRows(rows_buy)
          }
          <div className="m-1">
          </div>
        </tbody>  
        )}
        <tbody className="mb-5">
          

          
        { loading ? (
          <tr></tr>
        ) : 
          renderRows(rows_summary)
        }
      </tbody>  




      <>
        {
          showOther && (
            <div className="m-5">
            </div>
          )
        }
      </>
      {showOther && (
        <THead color={headerColor} className="mt-5">
          <tr className="w-100">
            {headers && headers.map((item, index) => {
              return (<th className="bg-secondary">{ index==0 && <Typography variant="h5" >{t("income_report_summary_table_others","Muut kulut ja tuotot")}</Typography>}</th>)
            })}
          </tr>
          <tr>
            { showRowNumber && (
              <th>
              #
              </th>
            )}
            { showId && (
              <th>
              ID
              </th>
            )}
            { checkboxes && (
              <th>
                <Checkbox 
                  id="checkbox_all"
                  name="checkbox_all"
                  value="checkbox_all"
                  checked={checkedAll} 
                />
              </th>
            )}
            { renderHeader()}
          </tr>
        </THead>
        )}
        {showOther && (

          <tbody name="unrealized" >
          { loading ? (
            <tr><td colSpan={headers && headers.length +1} className="text-center"><Spinner className="mt-2"/></td> </tr>
          ) : 
            renderRows(rows_other)
          }
          <div className="m-3">
          </div>
        </tbody>  
        )}




      
      <>
        {
          showDividend && (
            <div className="m-5">
            </div>
          )
        }
      </>
      {showDividend && (
        <THead color={headerColor} className="mt-5">
          <tr className="w-100">
            {headers && headers.map((item, index) => {
              return (<th className="bg-secondary">{ index==0 && <Typography variant="h5" >{t("income_report_summary_table_dividends","Osingot")}</Typography>}</th>)
            })}
          </tr>
          <tr>
            { showRowNumber && (
              <th>
              #
              </th>
            )}
            { showId && (
              <th>
              ID
              </th>
            )}
            { checkboxes && (
              <th>
                <Checkbox 
                  id="checkbox_all"
                  name="checkbox_all"
                  value="checkbox_all"
                  checked={checkedAll} 
                />
              </th>
            )}
            { renderHeader()}
          </tr>
        </THead>
        )}
        {showDividend && (

          <tbody name="unrealized" >
          { loading ? (
            <tr><td colSpan={headers && headers.length +1} className="text-center"><Spinner className="mt-2"/></td> </tr>
          ) : 
            renderRows(rows_dividend)
          }
          <div className="m-3">
          </div>
        </tbody>  
        )}

      </Table>
      { pagination && !loading && (
        <Pagination 
          paginationPrevious={paginationPrevious}
          paginationNext={paginationNext}
          paginationOffset={paginationOffset}
          paginationLimit={paginationLimit}
          paginationCount={paginationCount}
          setFilters={setFilters}
        />
      )}
    </>
  )
};

IncomeSummaryTable.propTypes = {
  headers: PropTypes.array,
  headerColor: PropTypes.oneOf(['light','dark']),
  rows: PropTypes.array,
  checkboxes: PropTypes.bool,
  checked: PropTypes.array,
  pagination: PropTypes.bool,
  showId: PropTypes.bool,
  showPaginationLimitSelect: PropTypes.bool,
  showRowNumber: PropTypes.bool,
  showVerticalBorders: PropTypes.bool,
  tableSmall: PropTypes.bool,
  tableStriped: PropTypes.bool,
};

