import React from 'react';
import PropTypes from 'prop-types';
import { Container as BootstrapContainer } from "reactstrap";
import './Container.css';

export const Container = ({ children, ...rest }) => {
  
  return (
    <BootstrapContainer
      {...rest}
    >
    {children}
    </BootstrapContainer>
  )
};

Container.propTypes = {
  
};


Container.defaultProps = {
  
};

