import http from './api'

const url = '/tags'


export const httpGetAllTags = (params) => {
    console.log("httpGetAllTags");
    return http({
      url: `/private_investment_funds/private_investment_funds/` ,
      method: 'GET',
      params,
    })
  }


  
  export const httpGetTags = (params) => {
    return http({
      url: `/private_investment_funds${url}/` ,
      method: 'GET',
      params
    })
  }
  
  export const httpGetTag = (id) => {
    return http({
      url: `/private_investment_funds${url}/${id}/` ,
      method: 'GET',
    })
  }
  export const httpUpdateTag  = (id, data) => {
    return http({
      url: `/private_investment_funds${url}/${id}/` ,
      method: 'PATCH',
      data
    })
  }
  
  
  export const httpCreateTag  = (data) => {
    return http({
      url: `/private_investment_funds${url}/` ,
      method: 'POST',
      data
    })
  }


  export const httpDeleteTag  = (id) => {
    return http({
      url: `/private_investment_funds${url}/${id}/` ,
      method: 'DELETE',
    })
  }
  

  
