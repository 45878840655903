import React, { useState, useEffect } from 'react';

import RealEstateProfitChart from "views/app/PrivateInvestmentFundRatesList/RealEstateProfitChart";
import RealEstateRentProfitChart from "views/app/PrivateInvestmentFundRatesList/RealEstateRentProfitChart";
import { Container, Row, Col, FormContainer, Card, CardBody,CardHeader } from "stories/layout";


//create a component
const RealEstateCharts = () => {
  return (
    <Row>
        <Col>
      <Card className="shadow-sm-strong  h-100">
        <CardHeader className="text-bold">
         Asunnon arvo ja asuntolaina
        </CardHeader>
        <CardBody>
      <RealEstateProfitChart />
        </CardBody>

        </Card>
        </Col>
        <Col>
        <Card className="shadow-sm-strong  h-100">
        <CardHeader className="text-bold">
         Vuokratuotto ja kulut
        </CardHeader>
        <CardBody>
      <RealEstateRentProfitChart />
        </CardBody>
        </Card>
        </Col>
    </Row>
  );
};

export default RealEstateCharts;