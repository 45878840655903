
import React, { useEffect, useState } from 'react';


const TradingViewWidgetSingleTicker = ({stock_market, symbol,fixed=false, width=300,  color="light"}) => {
  if (stock_market == "XHEL") {
    stock_market = "OMXHEX"
    }
    if (stock_market == "XSTO") {
        stock_market = "OMXSTO"
    }
    if (stock_market == "XNAS") {
        stock_market = "NASDAQ"
    }

    //if symbol include space replace with _ for tradingview
    let new_symbol = `${stock_market}:${symbol}`
    if (fixed) {
      new_symbol = symbol
    }
    else if (symbol?.includes(" ")) {
      symbol = symbol?.replace(" ", "_")
    }
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    // script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-single-quote.js';
    script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-single-quote.js';
    script.async = true;
    script.innerHTML = `
      {
        "symbol": "${new_symbol}",
        "width": "${width}",
        "colorTheme": "light",
        "locale": "en"
      }
    `;

   

    const container = document.getElementById(symbol);
    container.appendChild(script);

  }, []); // Empty dependency array ensures useEffect runs only once during component mount






  return (
    <div className="hide_div">
    <div className="tradingview-widget-container">
      <div className="tradingview-widget-container__widget hide_div" id={symbol}> 
        <div><div className="hide_this">asdf</div></div>
      </div>
      <div className="tradingview-widget-copyright">
        <a href="https://www.tradingview.com/" rel="noopener nofollow" target="_blank">
          {/* <span className="blue-text">Track all markets on TradingView</span> */}
        </a>
      </div>
    </div>
      
    </div>
  );
};

export default TradingViewWidgetSingleTicker;



// <!-- TradingView Widget BEGIN -->
// <div class="tradingview-widget-container">
//   <div class="tradingview-widget-container__widget"></div>
//   <div class="tradingview-widget-copyright"><a href="https://www.tradingview.com/" rel="noopener nofollow" target="_blank"><span class="blue-text">Track all markets on TradingView</span></a></div>
//   <script type="text/javascript" src="https://s3.tradingview.com/external-embedding/embed-widget-tickers.js" async>
//   {
//   "symbols": [
//     {
//       "proName": "BITSTAMP:ETHUSD",
//       "title": "Ethereum"
//     }
//   ],
//   "isTransparent": false,
//   "showSymbolLogo": true,
//   "colorTheme": "dark",
//   "locale": "en"
// }
//   </script>
// </div>
// <!-- TradingView Widget END -->