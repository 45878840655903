import React, { useState, useCallback, useEffect } from "react";
import { withFormik } from 'formik';
import * as Yup from "yup";
import moment from "moment"
import { useTranslation } from 'react-i18next';
import { useStoreState, useStoreActions } from 'easy-peasy';

import { Button, Typography, Spinner, Modal, Link } from "stories/components";
import { Container, Row, Col, FormContainer, Card, CardBody } from "stories/layout";
import { Input, SelectMulti, Toggle, Radio } from "stories/forms";
import { useNotification } from "stories/components/Notification"
import CurrencySelect from "components/Forms/CurrencySelect/CurrencySelect"
import SectorSelect from "components/Forms/SectorSelect/SectorSelect"
import TagSelect from "components/Forms/TagSelect/TagSelect"
import SectorEdit from "views/app/SectorEdit/SectorEdit"
import TagEdit from "views/app/TagEdit/TagEdit"

import InvestmentTypeSelectSub from "components/Forms/InvestmentTypeSelectSub/InvestmentTypeSelectSub"

import { useAppContext } from "contexts/AppContext"


import { 
  httpGetPrivateInvestmentFund, 
  httpCreatePrivateInvestmentFund, 
  httpUpdatePrivateInvestmentFund, 
  httpDeletePrivateInvestmentFund 
} from "services/private_investment_funds"
import { set } from "date-fns";

const defaultValues = {
  id: null,
  name: "",
}

const PrivateInvestmentFundEdit = (props) => {

  
  const {
     values,
     setValues,
     touched,
     setTouched,
     errors,
     setErrors,
     handleChange,
     handleBlur,
     handleSubmit,
     setFieldValue,
     isTouched,
     isSubmitting,
     validateForm,
     preSetValues
   } = props;
   
   const {
      t,
      history,
      objectId,
      selectedSector,
      setSelectedSector
   } = props;

   
   const getFormikInputProps = useCallback(
    name => ({
      name,
      value: values[name],
      error: Boolean(touched[name] && errors[name]),
      errorText: touched[name] ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange
    }),
    [values, errors, touched, handleBlur, handleChange]
  );
  
  const account = useStoreState((state) => state.account?.account);
  const getSectors = useStoreActions((actions) => actions.sectors.getSectors);
  const getTags = useStoreActions((actions) => actions.tags.getTags);




    // Modal: New/Edit Sector

    const [modalEditOpen, setModalEditOpen] = useState(false)
    const [modalEditId, setModalEditId] = useState()
    
    const toggleModalEdit = () => {
      setModalEditOpen(s => !s);
    }
    
    const openModalEdit = (id) => {
      setModalEditOpen(true)
      setModalEditId(id);
    }
    
    const modalEditResolve = () => {
      setModalEditOpen(false);
      setModalEditId(null);
      getSectors()
    }
    
    const modalEditReject = () => {
      setModalEditOpen(false);
      setModalEditId(null);
    }

      // Modal: New/Edit Tag

      const [modalTagEditOpen, setModalTagEditOpen] = useState(false)
      const [modalTagEditId, setModalTagEditId] = useState()
      
      const toggleModalTagEdit = () => {
        setModalTagEditOpen(s => !s);
      }
      
      const openModalTagEdit = (id) => {
        setModalTagEditOpen(true)
        setModalTagEditId(id);
      }
      
      const modalTagEditResolve = () => {
        setModalTagEditOpen(false);
        setModalTagEditId(null);
        getTags()
      }
      
      const modalTagEditReject = () => {
        setModalTagEditOpen(false);
        setModalTagEditId(null);
      }
  

  


  const onSubmit = (e) => {
    e.preventDefault();
    validateForm().then(res => {
      if (Object.keys(res).length === 0) {
        handleSubmit()
      }
      else {
        setTouched(res);
        setErrors({
          ...res,
          general: t("Please check the form errors", "Tarkista lomakkeen virheet")
        })
      }
    })
  }
  
  const handleCancel = () => {
    props.handleReject()
  }
  
  const handleDelete = () => {
    const result = window.confirm(t("are_you_sure_window_confirm","Oletko varma?"));
    if (result) {
      httpDeletePrivateInvestmentFund(objectId).then(() => {
        props.handleResolve()
      })
    }
  }


  const handleSectorChange = (name, value) => {
    setSelectedSector(s => ({
      ...s,
      [name]: value
    }))
  }
  

  const getSectorSelect = useCallback(() => {
    return (
      <SectorSelect
        {...getFormikInputProps("sector_select")}
        label={`${t("sector_title", "Toimiala")}` }
        multiple={false}
        onChange={handleSectorChange}
        value={selectedSector?.sector_select}
        setFieldValue={handleSectorChange}
      />
    )
  }, [selectedSector])


  const getTagSelect = useCallback(() => {
    return (
      <TagSelect
        {...getFormikInputProps("tag")}
        label={`${t("tag_title", "Kategoria")}` }
        multiple={false}
        onChange={setFieldValue}
        value={values?.tag}
        setFieldValue={setFieldValue}
      />
    )
  }, [values?.tag])

  return (
    <>
    {/* {JSON.stringify(props)} */}
      <Row>
        <Col className="mb-3">
          <Input
            {...getFormikInputProps("name")}
            label={t("PrivateInvestmentFundEdit_name", "Nimi")}
            required
          />
        </Col>
      </Row>
      <Row>
        <Col className="mb-3">
          <Input
            {...getFormikInputProps("symbol")}
            label={` ${t("PrivateInvestmentFundEdit_symbol", "Symboli")} *`}
          />
        </Col>
      </Row>
      <Row>
        <Col className="mb-3">
          <Input
            {...getFormikInputProps("stock_market")}
            label={` ${t("PrivateInvestmentFundEdit_stock_market", "Pörssi")}` }
          />
        </Col>
      </Row>
      <Row>
        <Col className="mb-3">
          <InvestmentTypeSelectSub 
          label = {`${t("PrivateInvestmentFundEdit_type", "Tyyppi")} *`}
          {...getFormikInputProps("type")}

          required
          />

        </Col>
      </Row>
      <Row>
        <Col className="mb-1">
          {getSectorSelect()}
          {/* {JSON.stringify(selectedSector)} */}
          {/* <SectorSelect
            {...getFormikInputProps("sector_select")}
            label={`${t("PrivateInvestmentFundEdit_sector", "Sektori")} *` }
            multiple={false}
            onChange={handleSectorChange}
            value={selectedSector?.sector_select}
            setFieldValue={handleSectorChange}
          /> */}
        </Col>
      </Row>
      <Row className="mb-3">
    
          <Col>
            <div  calssName="text-blue" onClick={() => openModalEdit()} style={{ cursor: 'pointer' }}>
            <Typography variant="small">  {t("sector_create_here","Luo toimiala tästä")}</Typography>
            </div>
          </Col>
      </Row>

      <Row>
        <Col className="mb-1">
          {getTagSelect()}
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <div  calssName="text-blue" onClick={() => openModalTagEdit()} style={{ cursor: 'pointer' }}>
          <Typography variant="small">  {t("sector_create_here","Luo kategoria tästä")}</Typography>
          </div>
        </Col>
      </Row>


      

      <Row>
        <Col className="mb-3">
          <Input
            {...getFormikInputProps("size")}
            label={t("PrivateInvestmentFundEdit_size", "Arvopaperin koko")}
            type="number"
          />
        </Col>
      </Row>

      
      
      <Row>
        <Col className="mb-3">
          <CurrencySelect 
            {...getFormikInputProps("currency_label")} 
            label={t("PrivateInvestmentFundEdit_currency", "Valuutta")}
            required
            disabled={objectId? true: false}

          />
          { !objectId &&
            <Typography variant="small">{t("Valuutta tietoa ei voi muokata myöhemmin")} </Typography>
          }
          
        </Col>
      </Row>

      {  account?.show_relation_to_security &&
        <>
            <Row className="mb-3">

                <Col >
                <Input
                    {...getFormikInputProps("dimensio")} 
                    label={t("rivateInvestmentFundEdit_dimensio", "Dimensio")}
                    />
                </Col>
            </Row>
            {/* <Row className="mb-3">

                  <Col >
                  <Input
                      {...getFormikInputProps("custom_symbol")} 
                      label={t("rivateInvestmentFundEdit_custom_symbol", "Oma tunnus")}
                      />
                  </Col>
            </Row> */}
        </>
      }

      
      
      <Row>
        <Col className="mb-3">
          <Button onClick={handleCancel} variant="default">{t("Close","Sulje")}</Button>
        </Col>
        { values?.id ? (
        <Col className="mb-3 text-center">
          {/*<Button onClick={handleDelete} variant="danger">{t("Delete","Poista")}</Button>*/}
        </Col>
        ) : null }
        <Col className="mb-3 text-right">

            <Button variant="secondary" disabled={isSubmitting} onClick={onSubmit}>
              {t("Save","Tallenna")}
            </Button>
          
          { Boolean(errors["general"]) && <Typography className="text-danger">{errors["general"]}</Typography> }
        </Col>
      </Row>

      <Modal
      title={t("sector_title","Toimiala")}
      isOpen={modalEditOpen}
      toggleModal={toggleModalEdit}
      
    >
      <SectorEdit
        objectId={modalEditId}
        handleResolve={modalEditResolve}
        handleReject={modalEditReject}
        extraMargin={true}
      />

    </Modal>
    <Modal
      title={t("sector_title","Tagi")}
      isOpen={modalTagEditOpen}
      toggleModal={toggleModalTagEdit}
      
    >

            <TagEdit
        objectId={modalTagEditId}
        handleResolve={modalTagEditResolve}
        handleReject={modalTagEditReject}
        extraMargin={true}
      />

    </Modal>
        
    </>
  );
}

const PrivateInvestmentFundEditFormik = withFormik({
    enableReinitialize: true,
    validateOnMount: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return {
          ...defaultValues
        }
      }
    },
    validationSchema: props => {
      const {t} = props;
      const required = t("The field is required");
      return Yup.object().shape({
        name: Yup.string().required(required).nullable(),
        type: Yup.string().required(required).nullable(),
        symbol: Yup.string().required(required).nullable(),
        // sector: Yup.string().required(required).nullable(),
        // stock_market: Yup.string().required(required).nullable(),
      });
    },
    handleSubmit: (values, { setSubmitting, setErrors, props }) => {
      const {t, portfolio, account, selectedSector} = props;
      
      const values_size = values?.size ? parseFloat(values?.size.toString().replace(",",".")) : 0;
      
      let data = {
        ...values,
        account: account?.id,
        size: values_size,
        general_sector: selectedSector?.sector_select?.id,
        private_sector: null,
        public_sector: null,
        tag: values?.tag?.id,
      };
      
      if (values?.id) {
        httpUpdatePrivateInvestmentFund(values.id, data).then(res => {
          setSubmitting(false);
          props.notify({ title:t("Private investment fund", "Arvopapri"), message:t("Updated")})
          props.handleResolve()
          
        }, error => {
          setSubmitting(false);
          if (error?.data?.detail) {
            setErrors({
              general: error?.data?.detail,
            })
          }
          else if (error?.data) {
            setErrors({
              general: t(error?.data),
            })
          }
          else {
            setErrors({
              general: t("Unknown error. Please try again later or contact support.")
            })
          }
        })
      }
      else {
        httpCreatePrivateInvestmentFund(data).then(res => {
          setSubmitting(false);
          props.notify({ title:t("Private investment fund", "Arvopaperi"), message:t("Created")})
          props.handleResolve()
          
        }, error => {
          setSubmitting(false);
          if (error?.data?.detail) {
            setErrors({
              general: error?.data?.detail,
            })
          }
          else if (error?.data) {
            setErrors({
              general: t(error?.data),
            })
          }
          else {
            setErrors({
              general: t("Unknown error. Please try again later or contact support.")
            })
          }
        })
      }
      
      
    },
    displayName: "BasicForm"
  
})(PrivateInvestmentFundEdit)
    
    
const PrivateInvestmentFundEditView = ({history, objectId, modalOpen, ...rest}) => {
  // API requests here
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [preSetValues, setPreSetValues] = useState();
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  const account = useStoreState((state) => state.account?.account);
  const [selectedSector, setSelectedSector] = useState({sector_select: []});
  const { getSectorName } = useAppContext()
  const tags = useStoreState((state) => state.tags?.tags);

  
  const getObject = (id) => {
    
    
    setLoading(true)
    
    httpGetPrivateInvestmentFund(id).then(res => {
      const tag_id = res?.data?.tag
      const tag = tags?.find(obj => obj.id === tag_id)
      
      setPreSetValues({
        ...defaultValues,
        ...res?.data,
        tag: tag ? [{id: tag_id, label: tag?.name, name:tag?.name }] : []
      });
      let sector = null
      if (res?.data?.private_sector){
        //add 1_ to private_sector id like 1_2322
        sector =`2_${res?.data?.private_sector}`
      }
      if (res?.data?.public_sector){
        sector =`1_${res?.data?.public_sector}`
      }

      setSelectedSector({
        sector_select: sector ? [{id: sector, label: getSectorName(sector), name:getSectorName(sector) }] : []
      })
      console.log("sector", sector, getSectorName(sector))

    
    }).finally(response => {
      setLoading(false);
    })
    
    
  }
  
  
  useEffect(() => {
    if (objectId && parseInt(objectId) > -1) {
      getObject(objectId)
    }
  }, [objectId, modalOpen])
  
  
  if (loading) {
    return (
      <Container fluid>
        <Spinner />
      </Container>
    )
  }

  return (
    <PrivateInvestmentFundEditFormik 
      t={t} 
      history={history} 
      preSetValues={preSetValues} 
      notify={notify} 
      objectId={objectId}
      account={account}
      selectedSector={selectedSector}
      setSelectedSector={setSelectedSector}
      {...rest} 
    />
  )
  
}

export default PrivateInvestmentFundEditView;
