import React from "react";
// react library for routing
import { useLocation, Route, Switch, Redirect } from "react-router-dom";

// core components
import AuthNavbar from "components/Navbars/AuthNavbar.js";

import routes from "routes_auth.js";
import backgroundImage from "assets/images/auth_background.jpg";
import logo from "assets/images/logoiv.png";





function Auth() {
  const location = useLocation();
  const mainContentRef = React.useRef(null);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContentRef.current.scrollTop = 0;
    
    /*
    document.body.classList.add("bg-default");
    // Specify how to clean up after this effect:
    return function cleanup() {
      document.body.classList.remove("bg-default");
    };
    */
  });
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContentRef.current.scrollTop = 0;
  }, [location]);
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
    <div className="" style={{
      background: "url(" + backgroundImage + ")",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      height: "100vh",
      width: "100vw",
      position: "fixed",
      zIndex: -1


    }}>   </div>
      <div className="main-content-priima mt-0"ref={mainContentRef} style={{zIndex: 9999999}}>
        
        {/* <AuthNavbar /> */}
        {/* <div className="d-flex justify-content-center  mb-5" style={{ zIndex: 9999999}}>
        <img src={logo} style={{width: "400px"}} alt="logo_svg"/>
        </div> */}
        <Switch>
        {getRoutes(routes)}
        <Redirect from="*" to="/auth/login" />
        </Switch>
      </div>
        
    </>
  );
}

export default Auth;
