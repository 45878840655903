import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';

// Rekisteröidään tarvittavat Chart.js komponentit
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const HousingDataChart = () => {
  const [chartData, setChartData] = useState({
    datasets: []
  });

  useEffect(() => {
    // Tässä voisi olla vaikka datan latausta serveriltä
    const loadData = () => {
      return {
        labels: ["Tammi", "Helmi", "Maalis", "Huhti", "Touko", "Kesä", "Heinä", "Elo", "Syys", "Loka", "Marras", "Joulu"],
        datasets: [

          {
            label: 'Vuokratuotto',
            data: [1200, 1200, 0, 1200, 1250, 1300, 1300, 1300, 1350, 1350, 1400, 1400],
            borderColor: 'rgb(54, 162, 235)',
            backgroundColor: 'rgba(54, 162, 235, 0.5)',
            fill: false,
            tension: 0.4,
            pointRadius: 2  // Pienentää datapisteiden kokoa
          },
          {
            label: 'Kulut',
            data: [350, 350, 350, 900, 400, 400, 400, 400, 450, 450, 450, 450],
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
            fill: false,
            tension: 0.4,
            pointRadius: 2  // Pienentää datapisteiden kokoa
          }
        ]
      };
    };

    // Viivästys ennen datan asettamista (simuloi verkkopyyntöä tai laskentaa)
    setTimeout(() => {
      setChartData(loadData());
    }, 500);
  }, []);

  const options = {
    animation: {
      duration: 2000, // Koko animaation kesto (ms)
    },
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Vuokratuotto',
      },
    },
    scales: {
      x: {
        grid: {
          display: false // Piilottaa x-akselin ruudukon
        }
      },
      y: {
        grid: {
          display: false // Piilottaa y-akselin ruudukon
        }
      }
    }
  };

  return (
    <div>
      <Line data={chartData} options={options} />
    </div>
  );
};

export default HousingDataChart;

