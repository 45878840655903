import React, { useCallback, useState, useEffect } from "react";
import { withFormik } from 'formik';
import * as Yup from "yup";
import { useTranslation } from 'react-i18next';

// core components
import { Checkbox, Input } from "stories/forms";
import { AlertModal, Button, Link, Typography, Spinner, Modal,StepProgressBar } from "stories/components"
import { Card, CardBody, CardHeader, Col, Container, Row } from "stories/layout"
import { useNotification } from "stories/components/Notification"
import { cleanEmail } from "services/helpers"
import LicenseSelect from "components/Forms/LicenseSelect/LicenseSelect"
import Activation from "views/auth/Activation"
import AccountTypeInput from "components/Forms/AccountTypeInput/AccountTypeInput"
import SubscriptionOptionsInfo from "components/Info/SubscriptionOptionsInfo.js"
import registerStartImg from "assets/images/register-start1.png";
import registerChose from "assets/images/register-2.png";
import registerAktvointi from "assets/images/aktivointi.png";
import registerSuccess from "assets/images/register-success.png";
import logo from "assets/images/logoiv.png";


import Flags from "components/Icons/Flags";

import { round, roundScaleDecimals } from "services/helpers"  


import { httpRegisterUser } from "services/users"
import { getStickyFooterScrollbar } from "@fullcalendar/core";
import { useAuthContext } from "contexts/AuthContext";
import { set } from "date-fns";

const Register = (props) => {

  const {
     values,
     touched,
     errors,
     handleChange,
     handleBlur,
     handleSubmit,
     isValid,
     isSubmitting,
     validateForm,
     setFieldValue,
     setTouched,
     setErrors,
   } = props;
   const {
      history,
      t,
      type,
      postLoading,
      setPostLoading,
      licenses,
      selectedLicense,
      setSelectedLicense,
      accountType,
      setAccountType,
      activeStep,
      setActiveStep,
   } = props;

   useEffect (() => {
    validateForm()
  },[values]) 
   
   const getFormikInputProps = useCallback(
    name => ({
      name,
      value: values[name],
      error: Boolean(touched[name] && errors[name]),
      errorText: touched[name] ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange
    }),
    [values, errors, touched, handleBlur, handleChange]
  );
  const [state3, setState3] = useState(false);

  useEffect(() => {
    if (activeStep === 3) {
      setState3(true)
    } 
  }, [activeStep])

  const setLicense = (accountType) => {
    console.log("setLicense().licenses", licenses);
    console.log("setLicense().accountType", accountType);
    let foundObject = licenses && licenses.find(object => object?.type === accountType && object?.register_default === true);
    // console.log("foundObject", foundObject);
    if (foundObject) {
      setSelectedLicense(foundObject)
      setFieldValue("license", foundObject?.id)
    }
  }

  //create state for accoutn type
  useEffect(() => {
    setLicense(accountType)
  }, [])


  // useEffect(() => {
 
  //     // setFieldValue("account_type", selectedLicense?.type || "business")
  //     setAccountType(selectedLicense?.type || "business")
 
  // }, [values?.license])

  //Update selected license when license changes.
  useEffect(() => {
    let foundObject = licenses && licenses.find(object => object?.id == values?.license);
    if (foundObject) {
      setSelectedLicense(foundObject)
      setAccountType(foundObject?.type || "business")
      setFieldValue("account_type", foundObject?.type || "business")
    }
  }, [values.license])

  
  const handleCancel = () => {
    history.push("/");
  }

  const validateFormBeforeSubmit = (submitFunction) => {
    if (!submitFunction) {
      return;
    }
    validateForm().then((res) => { 
      if (Object.keys(res).length === 0) { 
        submitFunction();
      } else { 
        setTouched(res);
        setErrors(res);
      } 
    })
  }

  // Modal: open/edit Subscription

  const [modalSubscriptionEditOpen, setModalSubscriptionEditOpen] = useState(false)
  const [modalSubscriptionEditId, setModalSubscriptionEditId] = useState()
  
  const toggleModalSubscriptionEdit = () => {
    setModalSubscriptionEditOpen(s => !s);
  }
  
  const openModalSubscriptionEdit = (id) => {
    setModalSubscriptionEditOpen(true)
    setModalSubscriptionEditId(id);
  }
  
  const modalSubscriptionEditResolve = () => {
    setModalSubscriptionEditOpen(false);
    setModalSubscriptionEditId(null);
  }
  
  const modalSubscriptionEditReject = () => {
    setModalSubscriptionEditOpen(false);
    setModalSubscriptionEditId(null);
  }
  
  const nextButtonStepFirst = () => {
    // validateFormBeforeSubmit(handleSubmit)
    // if (Object.keys(errors).length === 0) {
    //   setActiveStep((prevStep) => prevStep + 1)
    // }
    // valideteform only fields name and email

    validateForm().then(response => {
      let fields = ["first_name", "last_name", "email", "password", "password2", "agree_terms"];
      //filter res and get only fields
      let resKeys = Object.keys(response);
      let resKeysFiltered = resKeys.filter((key) => fields.includes(key));
      let res = {};
      resKeysFiltered.forEach((key) => {
        res[key] = response[key];
      })

      if (Object.keys(res).length === 0) {
        setActiveStep((prevStep) => prevStep + 1)
      }
      else {
        setTouched(res);
        setErrors({
          ...res,
          general: t("Please check the form errors", "Tarkista lomakkeen virheet")
        })
      }
    })

    // validateForm(['first_name', 'last_name', 'email', 'password', 'password2', 'agree_terms']).then((res) => {
    //   if (Object.keys(res).length === 0) {
    //   setActiveStep((prevStep) => prevStep + 1)
    //   }
    // })

    // setActiveStep((prevStep) => prevStep + 1)
  }

  const nextButtonStepSecond = () => {
    // validateFormBeforeSubmit(handleSubmit).then((res) => {
    //   if (Object.keys(res).length === 0) {
    //     setActiveStep((prevStep) => prevStep + 1)
    //   }
    // })
    validateForm().then(response => {
      console.log("validateForm().res", response);
      let fields = ["first_name", "last_name", "email", "password", "password2", "agree_terms", "license", "account_type", "company_name", "business_identity_code"];
      //filter res and get only fields
      let resKeys = Object.keys(response);
      let resKeysFiltered = resKeys.filter((key) => fields.includes(key));
      let res = {};
      resKeysFiltered.forEach((key) => {
        res[key] = response[key];
      })

      if (Object.keys(res).length === 0) {
        
        if (!state3) {
          handleSubmit()
        }
        else {
          setActiveStep((prevStep) => prevStep + 1)
        }
      }
      else {
        setTouched(res);
        setErrors({
          ...res,
          general: t("Please check the form errors", "Tarkista lomakkeen virheet")
        })
      }
    })
  }



  
  return (
    <>
      <div className="d-none d-sm-block">
        <div className="d-flex justify-content-center  mb-3 " style={{ zIndex: 9999999}}>
         <img src={logo} style={{width: "400px"}} alt="logo_svg"/>
        </div>
      </div>

      <Card variant="secondary" >
        <CardBody className="px-lg-4 py-lg-3 d-flex flex-column text-primary">
        <div className="d-block d-sm-none">
          <div className="d-flex justify-content-center  mb-3 " style={{ zIndex: 9999999}}>
          <img src={logo} style={{width: "400px"}} alt="logo_svg"/>
          </div>
        </div>
          <Row>
            <Col xs="3" className="mb-3  justify-content-start">
                <StepProgressBar 
                steps={4} 
                showNextButton={false} 
                backToOtherComp={false} 
                showLine={false} 
                className="ml-0"
                backToOtherCompLink="/auth"
                ColorOnlyActiveStep={true}
                setGivenActiveStep={setActiveStep}
                givenActiveStep={activeStep}
                />
              
            </Col>
          </Row>
          {activeStep === 1 && (
            <Row>
              <Col lg="7" xs="12">
                <div className="text-muted pb-3 mb-3  mt-0 border-bottom">
                     <Typography variant="h2" className="mb-0">{t("Aloita")}</Typography>
                  <Typography variant="small" className="mb-0 text-dark">{t("Luo ilmainen tili")}</Typography>
                </div>
                <Row>
                  <Col md="6" xs="12" className="mb-3 mt-0">
                    <Input
                      {...getFormikInputProps("first_name")}
                      placeholder={t("Etunimi")}
                      required
                      disabled={state3}

                    />
                  </Col>
                  <Col className="mb-3">
                    <Input
                      {...getFormikInputProps("last_name")}
                      placeholder={t("Sukunimi")}
                      required
                      disabled={state3}

                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="mb-3">
                    <Input
                      {...getFormikInputProps("email")}
                      placeholder={t("Sähköposti")}
                      type="email"
                      required
                      disabled={state3}

                    />
                  </Col>
                </Row>
            
                <Row>
                  <Col className="mb-3">
                    <Input
                      {...getFormikInputProps("password")}
                      placeholder={t("Salasana")}
                      type="password"
                      required
                      disabled={state3}

                    />
                  </Col>
                </Row>
            
                <Row>
                  <Col className="pb-3">
                    <Input
                      {...getFormikInputProps("password2")}
                      placeholder={t("Salasana uudelleen")}
                      type="password"
                      required
                      disabled={state3}

                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" className=" mb-4">

                    <Checkbox 
                      id={props.checkbox}
                      name={props.checkbox}
                      checked={values?.agree_terms}
                      onChange={(e) => {setFieldValue("agree_terms",e.target.checked)}}
                      value={props.checkbox}
                      required
                      // zIndex={9999999}
                      errorText={touched["agree_terms"] ? errors["agree_terms"] : ""}
                      label={
                        <>{t("register_step_1_read_terms",'Olen lukenut ja hyväksyn palvelun')} <Link className="underline-text mt-3" href="https://www.investime.fi/legal/" target="_blank">{t('käyttöehdot')}</Link> {t("ja")} <Link className="underline-text mt-3" href="https://www.investime.fi/legal/" target="_blank">{t("order_terms",'tilausehdot')}</Link></>
                        }
                    />
                  </Col>
                  <Col>
                  </Col>
                </Row>
              
                
       
                  <Button variant="secondary " className="w-100" onClick={nextButtonStepFirst} >{t('Jatka')}</Button>
                <Row>
                  <Col className="my-3 text-start">
                    <Typography variant="small">{t('Onko sinulla jo tili')}? <Link className="underline-text" to="/auth">{t("register_login",'Kirjaudu sisään tästä')}</Link></Typography>
                  </Col>
                </Row>
                {/* <Typography variant="h5" className="mb-3 underline-text" onClick={() => openModalSubscriptionEdit(null)}>{t("Näytä tilauksien ominaisuudet")}</Typography> */}
                {/* <Button variant="light" className="px-5 text-dark" onClick={() => setActiveStep((prevStep) => prevStep + 1)} >{t('-')}</Button> */}

              </Col>

              <Col className="d-flex align-items-center justify-content-center">
              <div className="d-none d-sm-block">

                <img
                  alt="register-start"
                  src={registerStartImg}
                  className=""
                  style={{
                    maxWidth: '300px',  // Set your desired maximum width
                    maxHeight: '300px'  // Set your desired maximum height
                  }}
                />
                </div>
         
              </Col>
            </Row>
            )
          }

          {activeStep === 2 && (
            <Row>
              {/* {JSON.stringify(selectedLicense)} */}
              <Col lg="7" xs="12">
                <div className="text-muted pb-3 mb-3  mt-0 border-bottom">
                  <Typography variant="h2" className="mb-0">{t("Valitse palvelupaketti")}</Typography>
                  {/* <Typography variant="h5" className="mb-0 underline-text" onClick={() => openModalSubscriptionEdit(null)}>{t("Näytä tilauksien ominaisuudet")}</Typography> */}
                </div>
                <Row>
                  <Col className="mb-1">
                    <div className="d-flex justify-content- flex-wrap">
                      <div className="p-1 ">
                        <Button variant={`${selectedLicense?.name == "Lite" ? "secondary": "light"}`} className=" text-dark" onClick={() => openModalSubscriptionEdit("Lite")} >{t('Lite')}</Button>
                      </div>
                      <div className="p-1 ">
                        <Button variant={`${selectedLicense?.name == "Basic" ? "secondary": "light"}`} className=" text-dark" onClick={() => openModalSubscriptionEdit("Basic")} >Basic</Button>
                      </div>
                      <div className="p-1">
                        <Button variant={`${selectedLicense?.name == "Pro" ? "secondary": "light"}`} className=" text-dark" onClick={() => openModalSubscriptionEdit("Pro")} >{t('Pro')}</Button>
                      </div>
                      <div className="p-1">
                        <Button variant={`${selectedLicense?.name == "Enterprise" ? "secondary": "light"}`} className=" text-dark" onClick={() => openModalSubscriptionEdit("Enterprise")} >{t('Enterprise')}</Button>
                      </div>

                    </div>
                  </Col>
                </Row>
                <Row>
                        <Col className="mb-3 ">
                          {/*<Typography>{t("Kuukausihinta")} {selectedLicense?.price / (1 + (selectedLicense?.price_vat_percent / 100.0))} € (+{t("sk_vat_short","alv")} {selectedLicense?.price_vat_percent}%)</Typography>*/}
                          {
                            selectedLicense?.type == "private" ? (

                              <Typography className="pl-1" variant="small">{t("register_step_2_monthly_price","Kuukausihinta")} {roundScaleDecimals(selectedLicense?.price,2)} € ({t("sk_vat_short","alv")} {selectedLicense?.price_vat_percent}%)</Typography>
                            ):(
                              <Typography className="pl-1" variant="small">{t("register_step_2_monthly_price","Kuukausihinta")} {roundScaleDecimals(selectedLicense?.price / (1 + (selectedLicense?.price_vat_percent / 100.0)),2)} € ({t("sk_vat_short","alv")} {"0.0"}%)</Typography>
                            )
                          }
                        </Col>
                </Row>
                {/* <Row>
                  <Col className="mb-3">
                    <AccountTypeInput
                      {...getFormikInputProps("account_type")}
                      placeholder={t("Yrityskäyttäjä")}
                      setFieldValue={setFieldValue}
                    />
                  </Col>
                </Row>      */}
                {/* <Row>
                  <Col className="mb-3">
                    <LicenseSelect
                      {...getFormikInputProps("license")}
                      placeholder={t("Lisenssi")}
                      setFieldValue={setFieldValue}
                      label=""
                      accountType={values.account_type}
                      setSelectedLicense={setSelectedLicense}
                    disabled={state3}

                    />
                  </Col>
                </Row>         */}

                {accountType !== "private" && (
                    <div>
                      <Row>
                        <Col className="mb-3">
                          <Input
                            {...getFormikInputProps("company_name")}
                            placeholder={t("Yrityksen nimi")}
                            required
                            disabled={state3}

                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mb-3">
                          <Input
                            {...getFormikInputProps("business_identity_code")}
                            placeholder={t("Yrityksen y-tunnus")}
                            required
                            disabled={state3}

                          />
                        </Col>
                      </Row>

                      
                    </div>
                  )
                }
                <div className="d-flex justify-content-between mt-5">

                <Button variant="light" className="px-5 text-dark" onClick={() => setActiveStep((prevStep) => prevStep - 1)} >{t('Takaisin')}</Button>
                <Button variant="secondary " disabled={isSubmitting}  className="px-5" onClick={nextButtonStepSecond} >
                  {!postLoading? t('Jatka'):
                    <>
                      <div className="mx-5"></div>
                      <Spinner animation="border" size="xs" className="spinner-height-18 "/>
                    </>
                  }
                 </Button>
                </div>
                { Boolean(errors["general"]) && <Typography className="text-danger">{errors["general"]}</Typography> }
 
                <Row>
                  <Col className="my-3 text-start">
                    <Typography variant="small">{t('Onko sinulla jo tili')}? <Link className="underline-text" to="/auth">{t('Kirjaudu tästä')}</Link></Typography>
                  </Col>
                </Row>
              </Col>
              <Col className="d-flex align-items-center justify-content-center">
              <div className="d-none d-sm-block">

                <img
                  alt="register-chose"
                  src={registerChose}
                  className=""
                  style={{
                    maxWidth: '300px',  // Set your desired maximum width
                    maxHeight: '300px'  // Set your desired maximum height
                  }}
                  />
                </div>
              </Col>
            </Row>
          )}
          {
            activeStep === 3 && (
              <Row>
                <Col lg="7" xs="12">
                  <div className="text-muted pb-3 mb-3  mt-0 border-bottom">
                    <Typography variant="h2" className="mb-0">{t("register_step_4_activation","Aktivoi sähköposti")}</Typography>
                    <Typography variant="small" className="mb-0 text-dark">{t("register_step_4_give_email","Anna sähköpostiin ")}({values?.email}) {t("register_step_4_give_email_2"," lähetetty koodi ja vahvista tili")}</Typography>
                  </div>
                  <Row>
                    <Col className="mb-3">
                      <Activation  emailProps={values?.email} setActiveStep={setActiveStep}/>
                    </Col>
                  </Row>
                  <Row>
                  <Col className="my-3 text-start">
                    <Typography variant="small">{t('Onko sinulla jo tili')}? <Link className="underline-text" to="/auth">{t('Kirjaudu tästä')}</Link></Typography>
                  </Col>
                </Row>
                </Col>
                <Col className="d-flex align-items-center justify-content-center">
                <div className="d-none d-sm-block">

                  <img
                    alt="register-chose"
                    src={registerAktvointi}
                    className=""
                    style={{
                      maxWidth: '300px',  // Set your desired maximum width
                      maxHeight: '300px'  // Set your desired maximum height
                    }}
                  />
                  </div>
                  </Col>       
              </Row> 
            )        

          }
          {activeStep === 4 && (
              <Row>
                <Col lg="7" xs="12">
                  <div className="text-muted pb-2 mb-3 mt-0 border-bottom">
                  <div className="d-flex align-items-center mb-1 mt-1">
                     <i className="pr-2 fas fa-check-circle fa-2x text-success"></i>
                     <Typography variant="h2" className="mb-0"> {t("register_success","Onnittelut, tilisi on nyt luotu.")}</Typography>
                  </div>
                  </div>
                  <div className="text-muted mb-5 mt-4">
                    <Typography variant="small" className="mb-3 text-dark" onClick={() => openModalSubscriptionEdit(null)}>{t("Olet luonut onnistuneesti tilin Investime palveluun. Tarjoamme käyttöösi tukipalveluita palvelupaketin mukaisesti. Ethän epäröi ottaa yhteyttä tukeemme.")}</Typography>
                  </div>
                  <Row >
                    <Col className="mb-3">
                    <Button variant="secondary " className="w-100" onClick={() =>  window.location.href = "/"} >{t('Aloita käyttö')}</Button>
                    </Col>
                  </Row>
                </Col>
                <Col className="d-flex align-items-center justify-content-center">
                <div className="d-none d-sm-block">

                  <img
                    alt="register-chose"
                    src={registerSuccess}
                    className=""
                    style={{
                      maxWidth: '300px',  // Set your desired maximum width
                      maxHeight: '300px'  // Set your desired maximum height
                    }}
                  />
                  </div>
                </Col>       
              </Row> 
              
            )        
          }
        <div className="d-block d-sm-none">
          <div className="d-flex justify-content-center  mt-4 ">
            <Flags />
            </div>
        </div>



        </CardBody>
      </Card>
      <Modal
      isOpen={modalSubscriptionEditOpen}
      toggleModal={toggleModalSubscriptionEdit}
      size="modal-xl"
      showHeader={false}
      classNameBody="p-0 padding-0"
    >
      <SubscriptionOptionsInfo
        objectId={modalSubscriptionEditId}
        handleResolve={modalSubscriptionEditResolve}
        handleReject={modalSubscriptionEditReject}
        setFieldValue={setFieldValue}
        preChoosen={modalSubscriptionEditId}

        // custom_info_text= {t("subscription_portfolios_info_text", "Salkkujen ensimmäismäärä lisenssillänne on täyttynyt")}
      />
    </Modal>


    </>
  );
}

const defaultValues = {
  first_name: '',
  last_name: '',
  email: '',
  password: '',
  password2: '',
  agree_terms: false,
  account_type : "private",
  license: 0, // previous 'Free'
  company_name: '',
  business_identity_code: '',
}

 const RegisterFormik = withFormik({
    
    validateOnMount: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return defaultValues
      }
    },
    validationSchema: props => {
      const {t} = props;
      const required = t("Kenttä on pakollinen");
      
      return Yup.object().shape({
        
        first_name: Yup.string().max(200).required(required),
        last_name: Yup.string().max(200).required(required),
        email: Yup.string().email(t("register_check_email_validation",'Tarkista sähköpostiosoitteen muoto')).required(required),
        //check that email not contains spaces after or before
        // 'password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special'
        //password cant contain spaces


        password: Yup.string().required(required)
            .min(8, t('Password must be 8 characters long'))
            .matches(/^\S*$/, t('Whitespace is not allowed'))
            .matches(/[0-9]/, t('Password requires a number'))
            .matches(/[a-z]/, t('Password requires a lowercase letter'))
            .matches(/[A-Z]/, t('Password requires an uppercase letter'))
            .matches(/[^\w]/, t('Password requires a symbol')),
        password2: Yup.string().required(required).oneOf([Yup.ref('password'), null], t("register_password_not_match",'Salasanat eivät täsmää')),
        //license: Yup.string().required(required),
        license: Yup.number().required(required),
        account_type: Yup.string().required(required),

        //set company_name and business_identity_code as required if account_type is not private
        company_name: Yup.string().when('account_type', {
          is: (val) => val !== "private", 
          then: Yup.string().required(required)
        }),
        business_identity_code: Yup.string().when('account_type', {
          is: (val) => val !== "private",
          then: Yup.string().matches(/^[0-9]{7}-[0-9]$/, t("register_business_id_validation",'Tarkista yrityksen y-tunnus')).required(required),
        }),


        agree_terms: Yup.bool().test(
          'consent',
          t('Kenttä on pakollinen'),
          value => value === true
        )
      });
    },
    handleSubmit: (values, { setSubmitting, setErrors, props }) => {
      const {t, setPostLoading, setActiveStep, getLanguage} = props;
      const email = cleanEmail(values?.email);
      const language = getLanguage();
      const data = {
        ...values,
        send_activation_email: true,
        email: email,
        username: email,
        password: values?.password,
        business_identity_code: values?.business_identity_code,
        license: values?.license,
        company_name: values?.company_name,
        type: values?.account_type,
        language: language || "fi",
      }
      setPostLoading(true)
      httpRegisterUser(data).then(res => {
        setSubmitting(false);
        setPostLoading(false)
        setActiveStep((prevStep) => prevStep + 1)
        // props.handleResolve(values?.email, values?.password)
      }, error => {
        setSubmitting(false);
        setPostLoading(false)
        //All keys in error.data
        const errorDataKey = Object.keys(error?.data);
        //default
        let errorCodeKey = errorDataKey?.[0];
        //default errorCode string to translator
        let errorCodeData = error?.data?.[errorCodeKey];
        //if error.data include errorCode then use that instead of default (errorCodeKey, errorCodeData). Also exclude errorCode key from errorDataKey list
        if (errorDataKey.length >= 2 && errorDataKey.includes('errorCode') ) {
          const errorCodeIndex = errorDataKey.indexOf('errorCode');
          errorCodeKey = errorDataKey[errorCodeIndex];
          errorCodeData = error?.data?.[errorCodeKey];
          //Exclude errorCode key from errorDataKey list
          errorDataKey.splice(errorDataKey.indexOf('errorCode'), 1);
          //For special cases, if render depends on errorCode
          setErrors({[errorCodeData]: t(errorCodeData)});
      
        }
        console.log(errorDataKey)
        console.log(errorCodeKey)
        console.log(errorCodeData)
        let errorData = error?.data[errorDataKey?.[0]];
        const valuesKeys = Object.keys(values);
        const usualErrorkeys = ['detail', 'message', 'non_field_errors'];


        if (Array.isArray(errorData)) {
          errorData = errorData[0];
        }

        if (!errorData || errorData === '') {
          setErrors({general: t('general_error','Virhe. Ota tarvittaessa yhteyttä asiakaspalveluun'),[errorCodeData]: t(errorCodeData)})
        }
        else if (errorDataKey.length > 0 && valuesKeys.includes(errorDataKey?.[0])) {
          //set new setErrors with include previous errors
        
          setErrors({[errorDataKey?.[0]]: t(errorCodeData,errorData),[errorCodeData]: t(errorCodeData)});
          

        }
        else if (errorDataKey.length > 0 && usualErrorkeys.includes(errorDataKey?.[0])) {
          setErrors({general: t(errorCodeData,errorData),[errorCodeData]: t(errorCodeData)});

        } 
        // else if dataKey is exist set general error
        else if (errorDataKey.length > 0) {
          setErrors({general: t(errorCodeData,errorData),[errorCodeData]: t(errorCodeData)});
        }
        else {
            setErrors({
              general: `${t('general_error','Virhe. Ota tarvittaessa yhteys ylläpitoon.')} ${JSON.stringify(error)}`,[errorCodeData]: t(errorCodeData),
          })
        }
      })
    },
    displayName: "BasicForm"
  
  })(Register)

const RegisterPrivateAndBusinessForm = ({match, history, licenses, checkbox}) => {
  
  const { t } = useTranslation();
  
  const { getCurrentLanguage } = useAuthContext();

  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  const [infoAlert, setInfoAlert] = useState();
  const [postLoading, setPostLoading] = useState(false);
  const [selectedLicense, setSelectedLicense] = useState();
  const [accountType, setAccountType] = useState("business");
  const [activeStep, setActiveStep] = useState(1);

  
  const handleModalConfirm = (email, password) => {
    setInfoAlert(null);
    history.push({
      pathname: '/auth/activation',
      state: { email: email, password: password }
    })
  }
  
  const handleResolve = (email, password) => {
    setInfoAlert(
      <AlertModal
        style={{ display: "block", marginTop: "-100px" }}
        title={t("Tilin aktivointi")}
        onConfirm={() => handleModalConfirm(email, password)}
        onCancel={() => handleModalConfirm(email, password)}
        btnSize=""
      >
      {t("Viimeistele tunnuksen luonti aktivoimalla sähköpostiosoitteesi. Olemme lähettäneet vahvistuslinkin sähköpostiisi.")}
      </AlertModal>
    )
    
  }
  
  return (
    <>
      <RegisterFormik
        history={history} 
        handleResolve={handleResolve} 
        t={t}
        type="business"
        postLoading={postLoading}
        setPostLoading={setPostLoading}
        licenses={licenses}
        selectedLicense={selectedLicense}
        setSelectedLicense={setSelectedLicense}
        accountType={accountType}
        setAccountType={setAccountType}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        getLanguage={getCurrentLanguage}
        checkbox={checkbox}
      />

      {infoAlert}
    </>
  )
}

export default RegisterPrivateAndBusinessForm;
