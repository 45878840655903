import React, { useEffect, useState, useCallback } from "react";
import moment from "moment";
import { useTranslation } from 'react-i18next';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useAuthContext } from "contexts/AuthContext"


// Stories
import { Row, Col } from "stories/layout";
import { Input } from "stories/forms";
import { Card, Typography, Button, Link, IconButton, Modal, Spinner } from "stories/components";
import { PageHeader, PageContainer } from "components/Page"
import { TableAuto } from "stories/tables"

// App
import StockMarketSelect from "components/Forms/StockMarketSelect/StockMarketSelect"
import StockSelect from "components/Forms/StockSelect/StockSelect"
import StockPrivateSelect from "components/Forms/StockPrivateSelect/StockPrivateSelect"
import { httpGetDividends } from "services/dividends"
import { httpGetPrivateInvestimentFundDatas } from "services/private_investment_funds"

import { getDate } from "services/datetime"
import PrivateInvestmentFundDividendEdit from "views/app/PrivateInvestmentFundDividendEdit/PrivateInvestmentFundDividendEdit"
import { round } from "services/helpers"
import { roundScaleDecimals } from "services/helpers";

const PrivateInvestmentFundDividendsList = ({filters,related_model_type, setFilters,getSplitsParent, setObjectsToChart, setAddSplit}) => {
  
  const { accountUserType } = useAuthContext()

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false)
  const [objects, setObjects] = useState()
  const [count, setCount] = useState()
  
  const getSplits = () => {
    let params = {
      ...filters,
      ordering: "-date_effective",
      related_model_type: related_model_type,
      related_model_id: related_model_type==10?filters?.stock : filters?.private_investment_fund
    }
    if(params?.related_model_id != null || params?.related_model_id != "" ) {

        setLoading(true)
        httpGetDividends(params).then(res => {
        setObjects(res?.data?.results)
        setCount(res?.data?.count)
        setLoading(false)
        })
    }
  }
  const getRates = () => {
    let params = {
      ...filters,
    }
    if (!filters?.private_investment_fund || filters?.private_investment_fund == "") {
      return;
    }
    
    httpGetPrivateInvestimentFundDatas(params).then(res => {
      setObjectsToChart(res?.data?.results)
      setCount(res?.data?.count)
    })
  }

  
  useEffect(() => {
    getSplits()
  }, [filters])
  

  
  const headers = [
    { label: t("dividends_list_name", "Name"), key: "name"},
    // { label: t("dividends_list_agm_date", "Vuosi kok. pvm"), key: "agm_date"},
    { label: t("dividends_list_ex_date", "Irtoamis pvm"), key: "ex_date"},
    { label: t("dividends_list_ex_date", "Maksu pvm"), key: "pay_date"},
    { label: t("dividends_list_ratio", "Osinko"), key: "rate"},
    { label: t("dividends_list_ratio", "Valuutta"), key: "currency_label"},
    { label: t("dividends_list_actions", " "), key: "actions"},

  ];
  
  const paginationPrevious = () => {
    let offset = filters?.offset - filters?.limit;
    if (offset < 0) {
      offset = 0;
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  const paginationNext = () => {
    let offset = filters?.offset + filters?.limit;
    if (offset > filters?.count) {
      return
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  const getTableRows = useCallback(() => {
    const name = filters?.private_investment_fund_name;
    return objects && objects.map((row, index) => {
      return {
        ...row,
        name: name,
        ex_date: moment(row.ex_date).format('DD.MM.YYYY'),
        agm_date: row?.agm_date ? moment(row.agm_date).format('DD.MM.YYYY') : null,
        pay_date: moment(row.pay_date).format('DD.MM.YYYY'),
        price: round(row?.price,2),
        actions: (
          <div className="d-flex justify-content-end">
            <Button className="btn-sm" color="secondary" disabled={accountUserType==="read_only"} onClick={() => openModalEdit(row?.id)}>{t("Muokkaa")}</Button>
          </div>
        ),
      }
    })
  }, [objects])
  
  // Modal: New/Edit
  
  const [modalEditOpen, setModalEditOpen] = useState(false)
  const [modalEditId, setModalEditId] = useState()
  
  const toggleModalEdit = () => {
    setModalEditOpen(s => !s);
  }
  
  const openModalEdit = (id) => {
    setModalEditOpen(true)
    setModalEditId(id);
  }
  
  const modalEditResolve = () => {
    setModalEditOpen(false);
    setModalEditId(null);
    getSplits()
    getSplitsParent()
    getRates()
  }
  
  const modalEditReject = () => {
    setModalEditOpen(false);
    setModalEditId(null);
  }



  useEffect(() => {
    setAddSplit(      <div className=" d-flex justify-content-end align-bottom">
    <Button color="secondary" onClick={() => openModalEdit(null)}>{t("new_dividend_button","Uusi osinko")}</Button>
  </div>)

  return () => {
    setAddSplit(null)
  }; 

  }, [])

  
  return (
    <>
    {/* {JSON.stringify(filters)} */}
    
      
      <TableAuto
        showRowNumber={true}
        showId={true}
        checkboxes={false}
        headers={headers}
        headerColor={"light"}
        rows={getTableRows()}
        loading={loading}
        pagination={true}
        paginationPrevious={paginationPrevious}
        paginationNext={paginationNext}
        paginationOffset={filters?.offset}
        paginationLimit={filters?.limit}
        paginationCount={count}
        setFilters={setFilters}
      />
      
      <Modal
          title={t("Osinko")}
          isOpen={modalEditOpen}
          toggleModal={toggleModalEdit}
        >
        <PrivateInvestmentFundDividendEdit
          related_model_id={filters?.private_investment_fund || filters?.stock}
          related_model_type={related_model_type}
          privateInvestmentFundName={filters?.private_investment_fund_name || filters?.stock_name}
          objectId={modalEditId}
          handleResolve={modalEditResolve}
          handleReject={modalEditReject}
          currency_label={filters?.currency?.content || filters?.stockCurrency || filters?.currency_label}
        />
      </Modal>

    </>
  );
}

export default PrivateInvestmentFundDividendsList;
