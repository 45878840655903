import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Popover as BootstrapPopover, PopoverBody } from 'reactstrap';

import './Popover.css';

export const Popover = ({
  placement = 'top',
  target,
  trigger = 'click hover focus',
  className,
  children,
  ...rest
}) => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const ref = useRef(null);

  const handleMouseEnter = (event) => {
    const currentPopover = ref.current;
    if (currentPopover && !currentPopover.contains(event.relatedTarget)) {
      setPopoverOpen(true);
    }
  };

  const handleMouseLeave = (event) => {
    const currentPopover = ref.current;
    if (currentPopover && !currentPopover.contains(event.relatedTarget)) {
      setPopoverOpen(false);
    }
  };

  const toggle = (event) => {
    const currentPopover = ref.current;
    if (currentPopover && !currentPopover.contains(event.target)) {
      setPopoverOpen(!popoverOpen);
    }
  };

  let classNames = `${className ? className : ''}`;

  return (
    <div ref={ref}>
      <BootstrapPopover
        className={`${classNames}`}
        placement={placement}
        target={target}
        isOpen={popoverOpen}
        toggle={toggle}
        trigger={trigger}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        {...rest}
      >
        <PopoverBody>{children}</PopoverBody>
      </BootstrapPopover>
    </div>
  );
};

Popover.propTypes = {
  placement: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
};
// import React, {useState} from 'react';
// import PropTypes from 'prop-types';
// import { Popover as BootstrapPopover, PopoverBody, UncontrolledPopover } from "reactstrap";

// import "./Popover.css"

// export const Popover = ({ placement="top", target, trigger="click hover focus", className, children, ...rest }) => {
//   const [popoverOpen, setPopoverOpen] = useState(false);
//   const toggle = () => setPopoverOpen(!popoverOpen);
//   let classNames = `${className ? className : ""}`;
  
//   return (
//     <BootstrapPopover
//       className={`${classNames}`}
//       placement={placement}
//       target={target}
//       isOpen={popoverOpen}
//       toggle={toggle}
//       trigger={trigger}
//       {...rest}
//     >
//       <PopoverBody>
//       {children}
//       </PopoverBody>
//     </BootstrapPopover>
//   );
// };

// Popover.propTypes = {
//   placement: PropTypes.oneOf(['top','right','bottom','left']),
// };

/**
USAGE

<Button color="default" id="tooltip876279349" type="button">
  Popover on top
</Button>

<Popover
  placement="top"
  target="tooltip876279349"
>
Content
</Popover>
**/