import React, { useEffect, useState, useCallback } from "react";
import moment from "moment";
import { useTranslation } from 'react-i18next';
import { useStoreState, useStoreActions } from 'easy-peasy';

import { Row, Col } from "stories/layout";
import { Input } from "stories/forms";
import { Card, Typography, Button, Link, IconButton, Modal, Spinner } from "stories/components";
import { PageHeader, PageContainer } from "components/Page"
import { TableAuto } from "stories/tables"

import PortfolioEdit from "views/app/PortfolioEdit/PortfolioEdit"
import UserInvitate from "views/dashboard/UserInvitate/UserInvitate"
import CompanyEdit from "views/dashboard/CompanyEdit/CompanyEdit"

import { httpGetAccountUsers, } from "services/account_users"
import { httpInviteUser} from "services/users"
// import from appcontext
import { useAppContext } from "contexts/AppContext"
import { useAuthContext } from "contexts/AuthContext"
import { useNotification } from "stories/components/Notification"
import { httpGetCompanies } from "services/companies";



//use appcontext to get user data



const AccountLog = ({match, history, setCrumbLinks}) => {
  
  const { t } = useTranslation();


  // const loading = useStoreState((state) => state.portfolio?.loading);
  const portfoliosCount = useStoreState((state) => state.portfolio?.count);
  const getPortfolios = useStoreActions((actions) => actions.portfolio.getPortfolios);
  const { getPortfolioName, getUserTypeName } = useAppContext()
  const [loading, setLoading] = useState(false)
  const { notify } = useNotification();
  const { isAdmin } = useAuthContext()
  
  useEffect(() => {
    setCrumbLinks([
      {
        name: t("Etusivu"),
        url: "/dashboard",  
        },
      {name: t("Other"), url: "/dashboard/other/default_settings"},
      {name: t("account_log", "Loki"), url: "/dashboard/other/account_log"},
    ])
    }, [])



  
  // Modal: New/Edit company
  
  const [modalEditOpen, setModalEditOpen] = useState(false)
  const [modalEditId, setModalEditId] = useState()
  
  const toggleModalEdit = () => {
    setModalEditOpen(s => !s);
  }
  
  const openModalEdit = (id) => {
    setModalEditOpen(true)
    setModalEditId(id);
  }
  
  const modalEditResolve = () => {
    setModalEditOpen(false);
    setModalEditId(null);
  }
  
  const modalEditReject = () => {
    setModalEditOpen(false);
    setModalEditId(null);
  }
  
  return (
    <>

      
        <div className="d-flex justify-content-start mb-2">
          <div className="p-2">
          </div>
            {t("account_log_info_text_1","Tämä toiminto on vasta tulossa")}!
          <div className="p-2">
            
          </div>
        </div>

        

    </>
  );
}

export default AccountLog;