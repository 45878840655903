import { action, thunk, persist } from 'easy-peasy';
import { httpGetPortfolios } from "services/portfolios"

const model = {
  loading: false,
  setLoading: action((state, payload) => {
    state.loading = payload
  }),
  count: 0,
  setCount: action((state, payload) => {
    state.count = payload
  }),
  // My portfolios list
  portfolios: [],
  setPortfolios: action((state, payload) => {
    if (payload) {
      state.portfolios = payload
    }
  }),
  getPortfolios: thunk(async (actions, payload) => {
    actions.setLoading(true)
    httpGetPortfolios().then(response => {
      if (response.status === 200 && response?.data) {
        actions.setLoading(false)
        actions.setPortfolios(response?.data)
        if (response?.data.length > 0) {
          actions.setPortfolio(response?.data[0])
          actions.setCount(response?.data.length)
        }
        else {
          actions.setCount(0)
        }
      }
    }, error => {
      
    }).finally(() => {
      actions.setLoading(false)
    })
    
  }),
  // Selected portfolio
  portfolio: null,
  setPortfolio: action((state, payload) => {
    state.portfolio = payload
  }),
};

export default model;