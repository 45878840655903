import React, { useState, useCallback, useEffect } from "react";
import { withFormik } from 'formik';
import * as Yup from "yup";
import moment from "moment"
import { useTranslation } from 'react-i18next';
import { useStoreState, useStoreActions } from 'easy-peasy';

import { Button, Typography, Spinner, Popover } from "stories/components";
import { Container, Row, Col, FormContainer, Card, CardBody } from "stories/layout";
import { Input, SelectMulti, Toggle, Radio, DatePicker, Checkbox } from "stories/forms";
import { useNotification } from "stories/components/Notification"
import SplitScopeSelect from "components/Forms/SplitScopeSelect/SplitScopeSelect"




import { httpGetSplit, httpCreateSplit, httpUpdateSplit, httpDeleteSplit} from "services/splits"


import CurrencySelect from "components/Forms/CurrencySelect/CurrencySelect"

const defaultValues = {
  id: null,
  split_scope: "1",
}

const PrivateInvestmentFundSplitEdit = (props) => {
  
  const {
    values,
    touched,
    setTouched,
    errors,
    setErrors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    isValid,
    isTouched,
    isSubmitting,
    validateForm,
    preSetValues
  } = props;
  
  const {
    t,
    history,
    objectId,
    privateInvestmentFundName,
    getUnmadeSplitTransactions
   } = props;
   
   const getFormikInputProps = useCallback(
     name => ({
       name,
       value: values[name],
       error: Boolean(touched[name] && errors[name]),
      errorText: touched[name] ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange
    }),
    [values, errors, touched, handleBlur, handleChange]
  );
  
  const onSubmit = (e) => {
    e.preventDefault();
    validateForm().then(res => {
      if (Object.keys(res).length === 0) {
        handleSubmit()
      }
      else {
        setTouched(res);
        setErrors({
          ...res,
          general: t("Please check the form errors", "Tarkista lomakkeen virheet")
        })
      }
    })
  }
  
  const handleCancel = () => {
    props.handleReject()
  }
  
  const handleDelete = () => {
    httpDeleteSplit(objectId).then(() => {
      getUnmadeSplitTransactions()

      props.handleResolve()
    })
  }
  
  return (
    <>
      <Row>
        <Col className="mb-3">
        
            <Typography variant="h3">{privateInvestmentFundName }</Typography>
        
        
        </Col>
      </Row>
      <Row>
        <Col className="mb-3">
          <SplitScopeSelect
          {...getFormikInputProps("split_scope")}
          disabled= {objectId? true : false}

          />  
        </Col>
      </Row>
      <Row>
        <Col className="mb-3">
          <DatePicker
            {...getFormikInputProps("date_effective")}
            label={t("privateInvestmentFund_edit_split_date_effective", "Voimaan tulopäivä")}
            className="form-control"
            selected={values?.date_effective}
            name="date_effective"
            onChange={(date) => setFieldValue("date_effective", date)}
            showYearDropdown
            dateFormat="d.M.yyyy"
            value={values?.date_effective}
            disabled= {objectId? true : false}
            required
          />
        </Col>
      </Row>
      
      <Row>
        <Col className="mb-3">

          <Input
          {...getFormikInputProps("dividend")}
          label={t("privateInvestmentFund_edit_split_ratio", "Suhdeluku")}
          type="number"
          min={0}
          step={"0.01" }
          onWheel={(e) => e.target.blur()}
          placeholder={t("0")}
          disabled= {objectId? true : false}
        />
        </Col>
      </Row>
      {/*
        <Row className="mb-3">
          <Col className="mb-3">
          <div style={{position: 'absolute', zIndex: "0 !important"}}>

            <Checkbox 
              id="business"
              name="agree_terms"
              checked={values?.updated_security_ratios}
              onChange={(e) => {setFieldValue("updated_security_ratios",e.target.checked)}}
              value="agree_terms"
              required
              errorText={touched["agree_terms"] ? errors["agree_terms"] : ""}
              label= {t('Päivitä kurssikirjaukset')}
              className="zIndexZero"
            />
            </div>

          </Col>
        </Row>*/
      }

      <Row>
        <Col className="mb-3">

        <Button color="warning" id="info" type="button" size="sm">
              <i className="fas fa-info-circle fa-lg"/>
            </Button>
            <Popover
              placement="top"
              target="info"
            >       
              <Typography className="text-warning mb-1" variant="medium">
              {t("privateInvestmentFund_edit_split_info_text_1","Huom! Splitin luonti päivittää sitä edeltävät kurssikirjaukset jakamalla hinnan splitin suhdeluvulla. ")}

              </Typography>  
            </Popover>

        </Col>
      </Row>
      <Row>
        <Col className="mb-3">
          <Button onClick={handleCancel} variant="default">{t("Close","Sulje")}</Button>
        </Col>
        { values?.id ? (
        <Col className="mb-3 text-center">
          <Button onClick={handleDelete} variant="danger">{t("Delete","Poista")}</Button>
        </Col>
        ) : null }
        <Col className="mb-3 text-right">

            <Button variant="secondary" disabled={isSubmitting} onClick={onSubmit}>
              {t("Save","Tallenna")}
            </Button>
          
          { Boolean(errors["general"]) && <Typography className="text-danger">{errors["general"]}</Typography> }
        </Col>
        

      </Row>
        
    </>
  );
}

const PrivateInvestmentFundSplitEditFormik = withFormik({
    enableReinitialize: true,
    validateOnMount: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return {
          ...defaultValues
        }
      }
    },
    validationSchema: props => {
      const {t} = props;
      const required = t("The field is required");
      return Yup.object().shape({
        date_effective: Yup.string().required(required).nullable(),
        dividend: Yup.number().required(required).nullable(),
      });
    },
    handleSubmit: (values, { setSubmitting, setErrors,  props }) => {
      const {t, privateInvestmentFund, account} = props;
      
      const values_price = values?.price ? parseFloat(values?.price.toString().replace(",",".")) : 0;
      let data = {
        ...values,
        price: values_price,
        related_model_id: privateInvestmentFund,
        related_model_type: 30, //Private investmentFund
        account: account.id,
        type: 2,
        updated_security_ratios: true,
        split_scope: values?.split_scope
      };
      
      if (values?.id) {
        httpUpdateSplit(values.id, data).then(res => {
          setSubmitting(false);
          props.notify({ title:t("Split"), message:t("Updated")})
          props.getUnmadeSplitTransactions()

          props.handleResolve()
          
        }, error => {
          setSubmitting(false);
          if (error?.status === 400) {
            setErrors(error?.data)
          }
          if (error?.data?.detail) {
            setErrors({
              general: error?.data?.detail,
            })
          }
          else {
            setErrors({
              general: t("Unknown error. Please try again later or contact support.")
            })
          }
        })
      }
      else {
        const oldDate = data.date_effective;
        const newDate = new Date(oldDate.getFullYear(), oldDate.getMonth(), oldDate.getDate(), 12, 0, 0);
        const formattedDate = newDate.toISOString().split('T')[0] +" 12:00:00Z";;
        data.date_effective = formattedDate;
        httpCreateSplit(data).then(res => {
          setSubmitting(false);
          props.notify({ title:t("Split"), message:t("Created")})
          props.handleResolve()
          props.getUnmadeSplitTransactions()

          
        }, error => {
          setSubmitting(false);
          if (error?.status === 400) {
            setErrors(error?.data)
          }
          else if (error?.data?.detail) {
            setErrors({
              general: error?.data?.detail,
            })
          }
          else {
            setErrors({
              general: t("Unknown error. Please try again later or contact support.")
            })
          }
        })
      }
      
      
    },
    displayName: "BasicForm"
  
})(PrivateInvestmentFundSplitEdit)
    
    
const PrivateInvestmentFundSplitEditView = ({history, objectId, modalOpen, privateInvestmentFundName="", ...rest}) => {
  // API requests here
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [preSetValues, setPreSetValues] = useState();
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  const account = useStoreState((state) => state.account?.account);
  const getUnmadeSplitTransactions = useStoreActions((actions) => actions.unmadeSplitTransactions.getUnmadeSplitTransactions);
  
  const getObject = (id) => {
    
    setLoading(true)
    
    httpGetSplit(id).then(res => {
      
      setPreSetValues({
        ...defaultValues,
        ...res?.data,
        date_effective: res?.data?.date_effective ? moment(res?.data?.date_effective).toDate() : null,
      });
    
    }).finally(response => {
      setLoading(false);
    })
    
  }
  
  
  useEffect(() => {
    if (objectId && parseInt(objectId) > -1) {
      getObject(objectId)
    }
  }, [objectId, modalOpen])
  
  
  if (loading) {
    return (
      <Container fluid>
        <Spinner />
      </Container>
    )
  }

  return (
    <PrivateInvestmentFundSplitEditFormik 
      t={t} 
      history={history} 
      preSetValues={preSetValues} 
      notify={notify} 
      objectId={objectId}
      account={account}
      privateInvestmentFundName={privateInvestmentFundName}
      getUnmadeSplitTransactions={getUnmadeSplitTransactions}
      {...rest} 
    />
  )
  
}

export default PrivateInvestmentFundSplitEditView;
