import React, { useState, useCallback, useEffect } from "react";
import { withFormik } from 'formik';
import * as Yup from "yup";
import moment from "moment"
import { useTranslation } from 'react-i18next';
import { useStoreState, useStoreActions } from 'easy-peasy';

import { Button, Typography, Spinner } from "stories/components";
import { Container, Row, Col, FormContainer, Card, CardBody } from "stories/layout";
import { Input, SelectMulti, Toggle, Radio } from "stories/forms";
import { useNotification } from "stories/components/Notification"
import { useAppContext } from "contexts/AppContext"
import { round,  roundScaleDecimals } from "services/helpers"

import { 
  httpGetTransaction, 
  httpCreateTransaction, 
  httpUpdateTransaction, 
  httpDeleteTransaction, 
  httpGetStockDateBalance, 
  httpCreatePrivateStockTransaction, 
  httpUpdatePrivateStockTransaction,
  httpDeletePrivateStockTransaction,
  httpGetPrivateStockDateBalance,
  httpGetPrivateStockTransaction,
  httpGetPrivateStockTransactions,
} from "services/transactions"



// import { httpGetPortfolio, httpCreatePortfolio, httpUpdatePortfolio, httpDeletePortfolio } from "services/portfolios"

import CurrencySelect from "components/Forms/CurrencySelect/CurrencySelect"

const defaultValues = {
  id: null,
  name: "",
}

const SplitTransactionEdit = (props) => {
  
  const {
     values,
     touched,
     setTouched,
     errors,
     setErrors,
     handleChange,
     handleBlur,
     handleSubmit,
     setFieldValue,
     isValid,
     isTouched,
     isSubmitting,
     validateForm,
     preSetValues
   } = props;
   
   const {
      t,
      history,
      object
   } = props;
   
   const getFormikInputProps = useCallback(
    name => ({
      name,
      value: values[name],
      error: Boolean(touched[name] && errors[name]),
      errorText: touched[name] ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange
    }),
    [values, errors, touched, handleBlur, handleChange]
  );
  
  const onSubmit = (e) => {
    e.preventDefault();
    validateForm().then(res => {
      if (Object.keys(res).length === 0) {
        handleSubmit()
      }
      else {
        setTouched(res);
        setErrors({
          ...res,
          general: t("Please check the form errors", "Tarkista lomakkeen virheet")
        })
      }
    })
  }
  
  const handleCancel = () => {
    props.handleReject()
  }
  
  // const handleDelete = () => {
  //   httpDeletePortfolio(object.id).then(() => {
  //     props.handleResolve()
  //   })
  // }

  const calculateNewValues = () => {

    let newBalance = object?.batch?.batch_balance * values?.split_dividend
    let newPrice = object?.batch?.price / values?.split_dividend
    setFieldValue("new_batch_balance", newBalance)
    setFieldValue("new_price", roundScaleDecimals(newPrice, 6))
  }
  
  return (
    <>
      <Row className="my-4 mb-5">
      <Col xs={12} md={6} className="ml-auto pb-4">

          <Typography variant="h2">{t("purchase_order_add_title", "Lisää SPLIT kirjaus")}</Typography>
     
      </Col>
      <Col xs={12} md={6} className="ml-auto">
        <Input 
          {...getFormikInputProps("portfolio")}
          icon="fas fa-suitcase" 
          iconColor="text-secondary"
          disabled = {true}
        />
      </Col>
    </Row>
    <Row  className="py-3">
      <Col xs={12} md={6} className="border-right px-4 pt-0">
        <Row>
          <Col className="mb-3">
            <label className="mb-1 pr-1 header-bold">{t("change_proposal", "Muutos ehdotukset splitin suhdelukua hyödyntäen")}</label>
          </Col>
        </Row>
        <Row className="mb-1">
          <Col sm={12} lg={4} className="d-flex align-items-center">
            <label className="mb-1 pr-1 header">{t("split_transaction_edit_new_batch_balance", "Uusi erän koko")}</label>
          </Col>
          <Col  className="mb-0">
            <Input
              {...getFormikInputProps("new_batch_balance")}
              label=""
              disabled = {false}
              type="number"
              onWheel={(e) => e.target.blur()}
            />
          </Col>
        </Row>
        <Row className="mb-1">
          <Col sm={12} lg={4} className="d-flex align-items-center">
            <label className="mb-1 pr-1 header">{t("split_transaction_edit_new_batch_price", "Uusi kappalehinta")}</label>
          </Col>
          <Col  className="mb-0">
            <Input
              {...getFormikInputProps("new_price")}
              label=""
              disabled = {false}
              type="number"
              onWheel={(e) => e.target.blur()}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={12} lg={4} className="d-flex align-items-center">
          </Col>
          <Col  className="mb-0">
          <Button size="sm" variant="secondary" className="mt-2" onClick={() => calculateNewValues()} >{t("Muunna")}</Button>

          </Col>

        </Row>
        <Row className="row justify-content-start mt-5">
          <Col lg={12} md={12} className="mb-1 text-left">
          <Typography className="text-warning mb-1" variant="medium">
              {t("HUOM! Mikäli useampi split samalle osakkeelle suorita vanhimmasta uusimaan järjestyksessä. ")}
          </Typography>  
            <Typography className="text-warning" variant="medium">
               {t("Selvitä minkälaisia pyöristyssääntöjä splitin yhtteydessä mahdollisesti käytetään ja muunna tarvittaessa erän koko käsin vastaamaan oikeaa arvoa. Tarkista split kirjausten jälkeen, että osakkeen kokonais kappale määrä vastaa todellisuutta.")}
            </Typography>
     
          </Col>
      </Row>
      </Col>
      <Col>
        <Row>
          <Col className="mb-3">
            <label className="mb-1 pr-1 header-bold">{t("batch_information", "Erän tiedot nykyisellään")}</label>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col sm={12} lg={4} className="d-flex align-items-center">
            <label className="mb-1 pr-1 header">{t("split_transaction_edit_stock", "Osake")}</label>
          </Col>
          <Col  className="mb-0">
            <Input
              {...getFormikInputProps("stock")}
              label=""
              disabled = {true}
            />
          </Col>

        </Row>
        
        <Row className="mb-3">
          <Col sm={12} lg={4} className="d-flex align-items-center">
            <label className="mb-1 pr-1 header">{t("split_transaction_edit_batch_id", "Erän ID")}</label>
          </Col>
          <Col  className="mb-0">
            <Input
              {...getFormikInputProps("batch_id")}
              label=""
              disabled = {true}
            />
          </Col>

        </Row>
        <Row className="mb-3">
          <Col sm={12} lg={4} className="d-flex align-items-center">
            <label className="mb-1 pr-1 header">{t("split_transaction_edit_batch_balance", "Erän koko")}</label>
          </Col>
          <Col  className="mb-0">
            <Input
              {...getFormikInputProps("batch_balance")}
              label=""
              disabled = {true}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={12} lg={4} className="d-flex align-items-center">
            <label className="mb-1 pr-1 header">{t("split_transaction_edit_batch_price", "Kappalehinta")}</label>
          </Col>
          <Col  className="mb-0">
            <Input
              {...getFormikInputProps("price")}
              label=""
              disabled = {true}
            />
          </Col>
        </Row>

        <Row className="mb-3">
          <Col sm={12} lg={4} className="d-flex align-items-center">
            <label className="mb-1 pr-1 header">{t("split_transaction_edit_split_dividend", "Split-suhde")}</label>
          </Col>
          <Col  className="mb-0">
            <Input
              {...getFormikInputProps("split_dividend")}
              label=""
              disabled = {true}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={12} lg={4} className="d-flex align-items-center">
            <label className="mb-1 pr-1 header">{t("split_transaction_edit_split_date_effective", "Split päivä")}</label>
          </Col>
          <Col  className="mb-0">
            <Input
              {...getFormikInputProps("date_effective")}
              label=""
              disabled = {true}
            />
          </Col>
        </Row>
      </Col>
    </Row>
      
      <Row className="mt-4">
        <Col className="mb-3">
          <Button onClick={handleCancel} variant="primary">{t("Close","Sulje")}</Button>
        </Col>
        { values?.id ? (
        <Col className="mb-3 text-center">
         {/*  <Button onClick={handleDelete} disabled={true} variant="danger">{t("Delete","Poista")}</Button>*/}
        </Col>
        ) : null }
        <Col className="mb-3 text-right">

            <Button variant="secondary" disabled={isSubmitting} onClick={onSubmit}>
              {t("accept_change_proposal","Hyväksy muutos ehdotus")}
            </Button>
          
          { Boolean(errors["general"]) && <Typography className="text-danger">{errors["general"]}</Typography> }
        </Col>
      </Row>

    </>
  );
}

const SplitTransactionEditFormik = withFormik({
    enableReinitialize: true,
    validateOnMount: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return {
          ...defaultValues
        }
      }
    },
    validationSchema: props => {
      const {t} = props;
      const required = t("The field is required");
      return Yup.object().shape({
        new_batch_balance: Yup.number().required(required).nullable(),
        new_price: Yup.number().required(required).nullable(),
      });
    },
    handleSubmit: (values, { setSubmitting, setErrors, props }) => {
      const {t, portfolio, account, object} = props;
      console.log("account", account);

      
      const original_batch_balance = values?.new_batch_balance/ object?.batch?.batch_balance * object?.batch?.original_batch_balance;
      console.log(original_batch_balance)
      let data = {
        // commission_total: object?.batch?.commission_total,
        // currency_exchange_rate: object?.batch?.currency_exchange_rate,
        // currency_label: object?.batch?.currency_label,
        // price: values?.price,
        // price_total: values?.price_total,
        // stock: values?.stock_private_obj?.id,
        // stock: values?.stock?.id,
        // stock_market: stockMarket,
        // fifo_used: values?.fifo_used? values?.fifo_used : false,
        // state: values?.state?.key,
        // date_settlement: values?.date_settlement? moment(values?.date_settlement).format() : undefined,
        quantity: values?.new_batch_balance,
        portfolio: object?.batch?.portfolio,
        type: 20, // Split
        date_transaction: object?.splits?.date_effective,
        related_model_id: object?.batch?.related_model_id,
        related_model_type: object?.batch?.related_model_type,
        value_total: object?.batch?.value_total,
        batch_id: object?.batch?.batch_id?object?.batch?.batch_id:object?.batch?.id,
        batch_balance: values?.new_batch_balance,
        stock_split: object?.splits?.id,
        original_batch_balance: original_batch_balance,
        price: values?.new_price,

      };;
      
      
      if (values?.id) {
        // httpUpdatePortfolio(values.id, data).then(res => {
        //   setSubmitting(false);
        //   props.notify({ title:t("Portfolio"), message:t("Updated")})
        //   props.handleResolve()
          
        // }, error => {
        //   setSubmitting(false);
        //   if (error?.data?.detail) {
        //     setErrors({
        //       general: error?.data?.detail,
        //     })
        //   }
        //   else {
        //     setErrors({
        //       general: t("Unknown error. Please try again later or contact support.")
        //     })
        //   }
        // })
      }
      
        httpCreateTransaction(data).then(res => {
          setSubmitting(false);
          props.notify({ title:t("Split kirjaus"), message:t("lisätty onnistuneesti")})
          props.handleResolve()
          
        }, error => {
          setSubmitting(false);
          if (error?.data?.detail || error?.data?.general) {
            setErrors({
              general: error?.data?.detail || error?.data?.general,
            })
          }
          else {
            setErrors({
              general: t("Unknown error. Please try again later or contact support.")
            })
          }
        })
      
      
    },
    displayName: "BasicForm"
  
})(SplitTransactionEdit)
    
    
const SplitTransactionEditView = ({history, object, modalOpen, ...rest}) => {
  // API requests here
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [preSetValues, setPreSetValues] = useState();
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  const account = useStoreState((state) => state.account?.account);
const { getPortfolioName, getUserTypeName } = useAppContext()

  
  // const getObject = (id) => {
    
  //   setLoading(true)
    
  //   httpGetPortfolio(id).then(res => {
      
  //     setPreSetValues({
  //       ...defaultValues,
  //       ...res?.data,
  //     });
    
  //   }).finally(response => {
  //     setLoading(false);
  //   })
    
  // }
  useEffect (() => {
    if (object) {
      setPreSetValues({
        ...defaultValues,
        portfolio: `${getPortfolioName(object?.batch?.portfolio)}  (id: ${object?.batch?.portfolio})`,
        stock: object?.batch?.related_model?.name,
        batch_id: object?.batch?.batch_id? object?.batch?.batch_id: object?.batch?.id,
        batch_balance:  `${object?.batch?.batch_balance} ${t('kpl', 'kpl')}`,
        split_dividend: object?.splits?.dividend,
        date_effective: moment(object?.splits?.date_effective).format('DD.MM.YYYY'),
        price: roundScaleDecimals(object?.batch?.price, 6)
      });
    }
  }, [object])

  
  
  // useEffect(() => {
  //   if (objectId && parseInt(objectId) > -1) {
  //     getObject(objectId)
  //   }
  // }, [objectId, modalOpen])
  
  
  if (loading) {
    return (
      <Container fluid>
        <Spinner />
      </Container>
    )
  }

  return (
    <SplitTransactionEditFormik 
      t={t} 
      history={history} 
      preSetValues={preSetValues} 
      notify={notify} 
      object={object}
      account={account}
      {...rest} 
    />
  )
  
}

export default SplitTransactionEditView;
