import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import { SelectMulti } from "stories/forms";
import { useStoreState, useStoreActions } from 'easy-peasy';
import { flushToDom } from "@fullcalendar/core";
import { optionsForElement } from "dropzone";
import { setNestedObjectValues } from "formik";
import { set } from "date-fns";

const StockSelect = ({async=false,loading, label, name="stock", objects=null,updateFromStore=true, setValueToSelect,multiKey=false, ratesView, isMulti=false,  setFieldValue,returnValueObject=true, setFieldValueReturnId=false, value,errorText, showAll=false, handleResolve, ...rest }) => {
  const { t } = useTranslation();

  const stocks1 = useStoreState((state) => state.stocks?.objects);
  const [stocks, setStocks] = useState(stocks1)

  useEffect(() => {
    if (objects){
      setStocks(objects)
      }
  }, [objects])

  useEffect(() => {
    updateFromStore && setStocks(stocks1)
  }, [stocks1])

  
  const loadingStore = useStoreState((state) => state.stocks?.loading);
  
  const setLocalFieldValue = (name, obj) => {
    // setFieldValue(name, obj?.id)
    // handleResolve({ id: obj?.id, name: obj?.label ? obj?.label : obj?.name })

    if (setFieldValueReturnId === true) {
      setFieldValue(name, obj?.id)
      setFieldValue("stockCurrency", obj?.stockCurrency)
    }
    else {
      setFieldValue(name, obj)
    }
    
    if (handleResolve !== undefined) {
      handleResolve({ id: obj?.id, name: obj?.label ? obj?.label : obj?.name,  })
    }

  }
  
  const getOptionLabel = useCallback(() => {
    return stocks && stocks.reduce((result, stock) => {
      if (stock?.id === value) {
        result = stock?.name
      }
      return result
    }, null)
  }, [stocks])


  useEffect(() => {
    if (ratesView === true) {
      if (stocks && stocks.length > 0 && (value === undefined || value.value === undefined || value.value === "" || value === "")) {
        // console.log(stocks?.[0], name)
        if (loadingStore === false) {
          setLocalFieldValue(name, stocks[0]) 
          // setFieldValue("stockCurrency", obj?.stockCurrency)
          setFieldValue("stockCurrency", stocks[0].trading_currency)
        }
      }
    }
  }, [value, stocks, loadingStore])




  
  const getOptions = useCallback(() => {
    let topOptions = []
    if (showAll) {
      topOptions.push({value: 0, label: t("Kaikki")})
    }
    
    const options = stocks && stocks.map(stock => {
      return {value: stock.id, label: stock.name, stockCurrency: stock?.trading_currency}
    })


    return [
      ...topOptions,
      ...options
    ]
  }, [stocks])

  // const getOptions = useCallback(() => {
  //   return stocks && stocks.map(stock => {
  //     return {value: stock?.id, label: stock?.name}
  //   })
  // }, [stocks])
  const getSelectedOptions = useCallback(() => {
    if (isMulti === false) {
      return [value];

    }
  
    return value && value.map(stock => {
      return {id:stock?.id, value: stock.id, label: stock.name, }
    })
  }, [value])
  
  return (

    <SelectMulti 

      isMulti={isMulti} 
      label={label? label: t("stock_select_label", "Osake")} 
      name={name} 
      value={value} 
      setFieldValue={setLocalFieldValue} 
      defaultValues={getSelectedOptions()}
      options={getOptions()} 
      ratesView={ratesView}
      setValueToSelect={setValueToSelect}
      errorText={errorText?.id ? errorText?.id: errorText}
      loading={loadingStore}
      async={async}
      showDefaultAsync={true}

      {...rest}

    />
    )
  
  
}

export default StockSelect;
